import { calculatePrices, dinero } from '../../../libs/dinero.lib'
import { GoodsReturnHeader, GoodsReturnRow } from '../goods-return.model'

// Initial states

export const GOODS_RETURN_HEADER_INITIAL_STATE: Partial<GoodsReturnHeader> = {
  returnNumber: undefined,
  currency: 'EUR',
  date: new Date().toISOString(),
  shippingCost: 0,
  shippingCostWithTaxes: 0,
  shippingTaxRate: 0,
  shippingTaxes: 0,
  subTotal: 0,
  subTotalWithTaxes: 0,
  bodyTaxes: 0,
  totalReturnAmount: 0,
}

// Amounts

export function updateGoodsReturnHeaderAmounts(
  header: GoodsReturnHeader,
  rows: GoodsReturnRow[],
  priceTaxes = false
): GoodsReturnHeader {
  // Shipping prices
  const {
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
  } = updateShippingAmounts(header, priceTaxes)

  // Cost prices
  const {
    companyCostTaxRate,
    companyCostTaxes,
    companyCost,
    companyCostWithTaxes,
  } = updateCostAmounts(header, priceTaxes)

  // Footer amounts
  const subTotal = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalAmount)),
    dinero(0)
  )
  const subTotalWithTaxes = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalAmountWithTaxes)),
    dinero(0)
  )
  const bodyTaxes = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalTaxes)),
    dinero(0)
  )

  const totalReturnAmount = subTotalWithTaxes
    .add(dinero(shippingCostWithTaxes))
    .add(dinero(companyCostWithTaxes))

  return {
    ...header,
    subTotal: subTotal.toRoundedUnit(4),
    subTotalWithTaxes: subTotalWithTaxes.toRoundedUnit(4),
    bodyTaxes: bodyTaxes.toRoundedUnit(4),
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
    companyCostTaxRate,
    companyCostTaxes,
    companyCost,
    companyCostWithTaxes,
    totalReturnAmount: totalReturnAmount.toRoundedUnit(4),
  }
}

function updateCostAmounts(header: GoodsReturnHeader, priceTaxes = false) {
  const costPrices = calculatePrices({
    taxRate: header.companyCostTaxRate,
    price: priceTaxes ? undefined : header.companyCost,
    total: priceTaxes ? header.companyCostWithTaxes : undefined,
  })

  return {
    companyCostTaxRate: costPrices.taxRate,
    companyCost: costPrices.price.toUnit(),
    companyCostWithTaxes: costPrices.total.toUnit(),
    companyCostTaxes: costPrices.taxes.toUnit(),
  }
}

function updateShippingAmounts(header: GoodsReturnHeader, priceTaxes = false) {
  const shippingPrices = calculatePrices({
    taxRate: header.shippingTaxRate,
    price: priceTaxes ? undefined : header.shippingCost,
    total: priceTaxes ? header.shippingCostWithTaxes : undefined,
  })

  return {
    shippingTaxRate: shippingPrices.taxRate,
    shippingCost: shippingPrices.price.toUnit(),
    shippingCostWithTaxes: shippingPrices.total.toUnit(),
    shippingTaxes: shippingPrices.taxes.toUnit(),
  }
}
