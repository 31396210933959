import { Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { ChannelsService } from '../channels.service'
import { Channel } from '../channel.model'

@Pipe({
  name: 'channel',
})
export class ChannelPipe implements PipeTransform {
  constructor(private channelsService: ChannelsService) {}

  transform(channelId: string | undefined): Observable<Channel | undefined> {
    if (!channelId) {
      return of(undefined)
    }

    return this.channelsService.read$(channelId)
  }
}
