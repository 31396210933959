import { Injectable, OnDestroy } from '@angular/core'
import {
  ModalParams,
  ModalRef,
  ModalManager,
  DialogParams,
  DialogResponse,
} from '@evologi/shared/data-access-api'
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable, Subject, map } from 'rxjs'

import { DialogComponent } from '../components/dialog/dialog.component'
import { UiSize } from '../models/ui.model'

@Injectable({
  providedIn: 'root',
})
export class ModalService implements ModalManager, OnDestroy {
  private modals: ModalRef[] = []

  constructor(private modalService: BsModalService) {}

  // Lifecycle

  ngOnDestroy(): void {
    this.hideAll()
  }

  /**
   * Show modal
   * @param params
   * @returns the observable for show modal
   */
  show$<ComponentType, ResponseType = any>(
    params: ModalParams<ComponentType>,
  ): Observable<ResponseType | undefined> {
    // Modal configuration
    const modalId = this.generateId()
    const modalSubject = new Subject<ResponseType | undefined>()

    const modalOptions: ModalOptions<any> = {
      id: modalId,
      backdrop: 'static',
      class: params.modalClass,
      initialState: {
        ...(params.initialState || {}),
        modalId,
      },
    }

    // Show modal
    const modalRef: BsModalRef = this.modalService.show(
      params.component,
      modalOptions,
    )
    this.setZIndex()
    this.pushModal(modalRef, modalSubject)

    // Events management
    modalRef.onHidden?.subscribe(() => this.pop(modalId))

    return modalSubject
  }

  /**
   * Show dialog
   * @param params - the modal dialog params
   * @returns the observable for show a simple modal dialog
   */
  showDialog$(params: DialogParams, size: UiSize = 'md'): Observable<boolean> {
    return this.show$<DialogComponent, DialogResponse>({
      component: DialogComponent,
      initialState: params,
      modalClass: `modal-${size} modal-dialog-centered`,
    }).pipe(map((r) => r === 'CONFIRM'))
  }

  /**
   * Pop modal by ID
   */
  pop(modalId: string): void {
    const modal = this.modals.find((m) => m.ref.id === modalId)

    if (modal) {
      modal.subject.complete()
      this.popModal(modalId)
      this.setZIndex()
    }
  }

  /**
   * Hide modal by ID
   * @param modalId - the modal ID
   * @param response - the response of the modal
   */
  hide(modalId: string, response?: any): void {
    const modal = this.modals.find((m) => m.ref.id === modalId)

    if (modal) {
      modal.subject.next(response)
      modal.subject.complete()
      modal.ref.hide()
      this.popModal(modalId)
      this.setZIndex()
    }
  }

  /**
   * Hide all modals
   */
  hideAll(): void {
    this.modals.forEach((m) => m.ref.hide())
  }

  // Utilities

  private pushModal(ref: BsModalRef, subject: Subject<any | undefined>): void {
    this.modals.push({
      ref,
      subject,
    })
  }

  private popModal(id: string): void {
    this.modals = this.modals.filter((m) => m.ref.id !== id)
  }

  private generateId(): string {
    return 'modal-' + this.modals.length.toString()
  }

  private setZIndex() {
    let zIndex = 1040
    const elements = Array.from(document.getElementsByClassName('modal'))

    elements.reverse().forEach((element: any) => {
      element['style']['zIndex'] = zIndex
      zIndex -= 10
    })
  }
}
