import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { File, FileSearchParams } from './file.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'files'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class FilesService extends CrudService {
  protected model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a file by ID
   * @param fileId - The file ID
   * @returns The observable<File> for read the file
   */
  read$(fileId: string): Observable<File> {
    return this._read$<File>(fileId)
  }

  /**
   * Delete a file by ID
   * @param fileId - The file ID
   * @returns The observable<File> for delete the file
   */
  delete$(fileId: string): Observable<File> {
    return this._delete$<File>(fileId)
  }

  /**
   * Search files by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<File>> for search files
   */
  search$(
    params?: FileSearchParams,
    returnAll = false,
  ): Observable<Page<File>> {
    return this._search$<File>(params, returnAll)
  }

  /**
   * List files by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<File[]> for list files
   */
  list$(params?: FileSearchParams, returnAll = false): Observable<File[]> {
    return this._list$<File>(params, returnAll)
  }

  /**
   * Return upload url
   * @returns The string url for upload files
   */
  get uploadUrl(): string {
    return `${this.apiUrl}/upload`
  }
}
