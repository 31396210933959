import { FilterOperator, FilterAvailable } from '../models/filter.model'
import { WeekDay } from '../models/util.model'

export const FILTER_ALL_VALUE = 'ALL'

export const FILTER_TYPES: FilterAvailable[] = [
  {
    name: '>',
    operator: FilterOperator.moreThan,
    initialValue: undefined,
    placeholder: 'Maggiore di',
    translation: '> di <strong>%s</strong>',
  },
  {
    name: '<',
    operator: FilterOperator.lessThan,
    initialValue: undefined,
    placeholder: 'Minore di',
    translation: '< di <strong>%s</strong>',
  },
  {
    name: '>=',
    operator: FilterOperator.moreOrEqualThan,
    initialValue: undefined,
    placeholder: 'Maggiore o uguale di',
    translation: '>= di <strong>%s</strong>',
  },
  {
    name: '<=',
    operator: FilterOperator.lessOrEqualThan,
    initialValue: undefined,
    placeholder: 'Minore o uguale di',
    translation: '<= di <strong>%s</strong>',
  },
  {
    name: '=',
    operator: FilterOperator.equal,
    initialValue: undefined,
    placeholder: 'Uguale a',
    translation: 'uguale a <strong>%s</strong>',
  },
  {
    name: '!=',
    operator: FilterOperator.notEqual,
    initialValue: undefined,
    placeholder: 'Diverso da',
    translation: 'diverso da <strong>%s</strong>',
  },
  {
    name: 'è definito',
    operator: FilterOperator.exists,
    initialValue: undefined,
    translation: 'è definito',
  },
  {
    name: 'non è definito',
    operator: FilterOperator.notExists,
    initialValue: undefined,
    translation: 'non è definito',
  },
  {
    name: 'tra',
    operator: FilterOperator.between,
    initialValue: [],
    translation: 'tra <strong>%s</strong> e <strong>%s</strong>',
  },
  {
    name: 'prima del',
    operator: FilterOperator.beforeThan,
    initialValue: undefined,
    placeholder: 'Prima del',
    translation: 'prima del <strong>%s</strong>',
  },
  {
    name: 'prima del (compreso)',
    operator: FilterOperator.beforeOrEqualThan,
    initialValue: undefined,
    placeholder: 'Prima del (compreso)',
    translation: 'prima del <strong>%s</strong>',
  },
  {
    name: 'dopo del',
    operator: FilterOperator.afterThan,
    initialValue: undefined,
    placeholder: 'Dopo del',
    translation: 'dopo del <strong>%s</strong>',
  },
  {
    name: 'dopo del (compreso)',
    operator: FilterOperator.afterOrEqualThan,
    initialValue: undefined,
    placeholder: 'Dopo del (compreso)',
    translation: 'dopo del <strong>%s</strong>',
  },
  {
    name: 'contiene',
    operator: FilterOperator.contains,
    initialValue: undefined,
    placeholder: 'Contiene',
    translation: 'contiene <strong>%s</strong>',
  },
]

export const FILTER_BOOLEAN_VALUES: { value: any; label: string }[] = [
  {
    value: true,
    label: 'true',
  },
  {
    value: false,
    label: 'false',
  },
]

export const WEEK_DAYS = [
  {
    name: 'Lunedì',
    code: WeekDay.monday,
  },
  {
    name: 'Martedì',
    code: WeekDay.tuesday,
  },
  {
    name: 'Mercoledì',
    code: WeekDay.wednesday,
  },
  {
    name: 'Giovedì',
    code: WeekDay.thursday,
  },
  {
    name: 'Venerdì',
    code: WeekDay.friday,
  },
  {
    name: 'Sabato',
    code: WeekDay.saturday,
  },
  {
    name: 'Domenica',
    code: WeekDay.sunday,
  },
]
