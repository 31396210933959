import { cloneDeep, defaultsDeep } from 'lodash'
import { ProductFamilySettings, ProductScope } from '../product.model'
import { PRODUCT_FAMILY_INITIAL_STATE } from '../product.const'
import { ProductFamily } from '../../product-families/product-family.model'

// Init

export function initProductFamilySettings(
  family: Partial<ProductFamilySettings> = {}
): ProductFamilySettings {
  return defaultsDeep(cloneDeep(family), PRODUCT_FAMILY_INITIAL_STATE)
}

export function defineProductScopeFamily(
  productFamily: ProductFamilySettings | undefined,
  family: ProductFamily,
  scope: ProductScope
): ProductFamilySettings {
  if (!productFamily) {
    return initProductFamilySettings()
  }

  // Check catalog
  const currentCatalog = productFamily.complete.find(
    (cat) => cat.catalogCode === scope.catalogCode
  )
  if (!currentCatalog) {
    productFamily = {
      ...productFamily,
      complete: [
        ...productFamily.complete,
        {
          catalogCode: scope.catalogCode,
          locales: {
            default: 0,
            [scope.locale]: 0,
          },
        },
      ],
    }
  }

  return {
    ...productFamily,
    code: family.code,
    fields: family.fields ? [...family.fields] : [],
    attributes: family.attributes ? [...family.attributes] : [],
  }
}

export function checkProductAttributeMandatory(
  productFamily: ProductFamilySettings | undefined,
  attributeCode: string,
  scope: ProductScope
): boolean {
  const attribute = productFamily?.attributes.find(
    (attr) => attr.code === attributeCode
  )
  return !!attribute && attribute.requiredIn.includes(scope.catalogCode)
}

export function removeProductEmptyFamily(
  family?: ProductFamilySettings
): ProductFamilySettings | undefined {
  return !family || !family.code ? undefined : { ...family }
}
