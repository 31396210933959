import { cloneDeep, defaultsDeep } from 'lodash'
import { ProductHistoricStockData, ProductPageData } from '../product.model'
import { PRODUCT_HISTORIC_STOCK_DATA_INITIAL_STATE } from '../product.const'
import { Page } from '../../../models/util.model'

export function initProductHistoricData(
  historicData: Partial<ProductHistoricStockData> = {},
  warehouseId?: string
): ProductHistoricStockData {
  const newHistoricData = defaultsDeep(
    cloneDeep(historicData),
    PRODUCT_HISTORIC_STOCK_DATA_INITIAL_STATE
  )

  if (warehouseId) {
    newHistoricData.warehouseId = warehouseId
  }

  return newHistoricData
}

export function parseProductsPageHistoricData(
  page: Page<ProductPageData>,
  historicQuantities: Record<string, number>
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => ({
        ...p,
        historicQuantity: historicQuantities[p._id],
      })),
    ],
  }
}
