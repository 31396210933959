import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Automation, AutomationSearchParams } from './automation.model'
import { Page } from '../../models/util.model'

const MODEL = 'automations'
const VERSION = 'v3'
@Injectable({
  providedIn: 'root',
})
export class AutomationsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new automation
   * @param automation - The automation to create
   * @returns The observable<Automation> to create the automation
   */
  create$(automation: Automation): Observable<Automation> {
    return this._create$<Automation>(automation)
  }

  /**
   * Read an automation by ID
   * @param automationId - The automation ID
   * @returns The observable<Automation> for read the automation
   */
  read$(automationId: string): Observable<Automation> {
    return this._read$<Automation>(automationId)
  }

  /**
   * Update an automation by ID
   * @param automationId - The automation ID
   * @param automation - The automation body to update
   * @returns The observable<Automation> for update the automation
   */
  update$(
    automationId: string,
    automation: Automation,
  ): Observable<Automation> {
    return this._update$<Automation>(automationId, automation)
  }

  /**
   * Create or update an automation by ID
   * @param automationId - The automation ID
   * @param automation - The automation body to update
   * @returns The observable<Automation> for update the automation
   */
  upsert$(automation: Automation): Observable<Automation> {
    return this._upsert$<Automation>(automation, automation._id)
  }

  /**
   * Delete a automation by ID
   * @param automationId - The automation ID
   * @returns The observable<Automation> for delete the automation
   */
  delete$(automationId: string): Observable<Automation> {
    return this._delete$<Automation>(automationId)
  }

  /**
   * Search automations by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Automation>> for search automations
   */
  search$(
    params?: AutomationSearchParams,
    returnAll = false,
  ): Observable<Page<Automation>> {
    return this._search$<Automation>(params, returnAll)
  }

  /**
   * List automations by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Automation[]> for list automations
   */
  list$(
    params?: AutomationSearchParams,
    returnAll = false,
  ): Observable<Automation[]> {
    return this._list$<Automation>(params, returnAll)
  }

  /**
   * Test automation email
   * @param automationId - The automation ID to test
   * @param email - The recipient to send the email
   * @returns The observable<Automation> for test automation email
   */
  test$(automationId: string, email: string): Observable<Automation> {
    return this.http.post<Automation>(`${this.apiUrl}/${automationId}/test`, {
      email,
    })
  }
}
