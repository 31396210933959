import { Column, Row, Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { sortByKey } from '@evologi/shared/util-toolkit'
import { TenantSupplierOrdersProductionExport } from '../../tenants'
import { SupplierOrder, SupplierOrderRow } from '../supplier-order.model'
import { Product, ProductType, getProductAttributeValue } from '../../products'
import { Attribute, AttributeOption } from '../../attributes'
import { getCatalogValue } from '../../catalogs'

export function generateProductionExportXlsFile(
  settings: TenantSupplierOrdersProductionExport,
  supplierOrder: SupplierOrder,
  products: Product[],
  attributes: Attribute[]
): void {
  if (!settings.isActive) {
    return
  }

  // Create workbook
  const workbook = new Workbook()
  const name = settings.title || 'Export produzione'
  const worksheet = workbook.addWorksheet(name)

  // Attributes
  const xAttribute = attributes.find((a) => a._id === settings.xAttributeId)
  const yAttribute = attributes.find((a) => a._id === settings.yAttributeId)

  if (!xAttribute || !yAttribute) {
    return
  }

  // Columns
  const columns: Partial<Column>[] = [
    {
      header: 'Articolo',
      key: 'PRODUCT-NAME',
    },
    {
      header: yAttribute.name.default,
      key: yAttribute.code,
    },
    ...xAttribute.options.map((option) => ({
      header: option.label.default,
      key: `${xAttribute.code}-${option.value}`,
    })),
  ]
  worksheet.columns = columns

  // Title
  worksheet.spliceRows(1, 0, [])
  worksheet.mergeCells('A1:L1')
  worksheet.getCell('A1').value = `${name} - ${moment(
    supplierOrder.header.rifDate || supplierOrder.header.date
  ).format('DD/MM/YYYY')} - #${supplierOrder.header.orderNumber}`
  worksheet.getCell('A1').alignment = { horizontal: 'center' }

  // Header style
  setHeaderStyle(worksheet, 1)
  setHeaderStyle(worksheet, 2)

  // Rows
  if (products.length) {
    const parents = products.filter(
      (p) => p.productType === ProductType.virtual
    )
    const rows: Partial<Row>[] = parents
      .reduce<Partial<Row>[]>(
        (acc, parent) => [
          ...acc,
          ...parseParentRows(
            parent,
            products.filter((p) => p.parentId === parent._id),
            supplierOrder.rows,
            xAttribute,
            yAttribute
          ),
        ],
        []
      )
      .filter((row) => {
        const rowValues = Object.values(row)
          .slice(2)
          .filter((v) => !!v)
        return !!rowValues.length
      })

    worksheet.addRows(sortByKey(rows, 'PRODUCT-NAME'))
  }

  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      )
    )
}

function setHeaderStyle(worksheet: Worksheet, rowIndex: number) {
  worksheet.getRow(rowIndex).protection = {
    locked: true,
  }
  worksheet.getRow(rowIndex).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'BCBCBC' },
  }
  worksheet.getRow(rowIndex).font = {
    bold: true,
    size: 14,
  }
}

function parseParentRows(
  parent: Product,
  children: Product[],
  rows: SupplierOrderRow[],
  xAttribute: Attribute,
  yAttribute: Attribute
): Partial<Row>[] {
  return [...yAttribute.options, undefined].reduce<Partial<Row>[]>(
    (acc, option) => [
      ...acc,
      parseParentRow(parent, children, rows, xAttribute, yAttribute, option),
    ],
    []
  )
}

function parseParentRow(
  parent: Product,
  children: Product[],
  rows: SupplierOrderRow[],
  xAttribute: Attribute,
  yAttribute: Attribute,
  yAttributeOption: AttributeOption | undefined
): Record<string, any> {
  return {
    'PRODUCT-NAME': getCatalogValue(parent.name),
    [yAttribute.code]: yAttributeOption?.label.default,
    ...xAttribute.options.reduce<Record<string, any>>(
      (acc, option) => ({
        ...acc,
        ...parseChildrenCell(
          children,
          rows,
          xAttribute,
          option.value,
          yAttribute,
          yAttributeOption?.value
        ),
      }),
      {}
    ),
  }
}

function parseChildrenCell(
  children: Product[],
  rows: SupplierOrderRow[],
  xAttribute: Attribute,
  xValue: any,
  yAttribute: Attribute,
  yValue: any
): Record<string, any> {
  const child = findChildByAttributes(
    children,
    xAttribute,
    xValue,
    yAttribute,
    yValue
  )
  const key = `${xAttribute.code}-${xValue}`
  const row = rows.find((r) => r.product._id === child?._id)

  if (!row) {
    return {
      [key]: undefined,
    }
  }

  return { [key]: row.orderedQty }
}

function findChildByAttributes(
  children: Product[],
  xAttribute: Attribute,
  xValue: any,
  yAttribute: Attribute,
  yValue: any
): Product | undefined {
  return children.find((child) => {
    const xAttributeValue = getProductAttributeValue(child, xAttribute)
    const yAttributeValue = getProductAttributeValue(child, yAttribute)

    return xAttributeValue === xValue && yAttributeValue === yValue
  })
}
