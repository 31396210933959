import { InjectionToken } from '@angular/core'
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang-en'
import { LANG_IT_NAME, LANG_IT_TRANS } from './lang-it'

export const TRANSLATIONS = new InjectionToken('translations')

export const DICTIONARY = {
  [LANG_EN_NAME]: LANG_EN_TRANS,
  [LANG_IT_NAME]: LANG_IT_TRANS,
}
