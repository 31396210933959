import { StoredenProductField } from './storeden.model'

export const STOREDEN_FIELDS = [
  {
    code: StoredenProductField.title,
    name: 'title',
  },
  {
    code: StoredenProductField.description,
    name: 'description',
  },
  {
    code: StoredenProductField.tax_profile,
    name: 'tax_profile',
  },
  {
    code: StoredenProductField.price,
    name: 'price',
  },
  {
    code: StoredenProductField.final_price,
    name: 'final_price',
  },
  {
    code: StoredenProductField.package_width,
    name: 'package_width',
  },
  {
    code: StoredenProductField.package_height,
    name: 'package_height',
  },
  {
    code: StoredenProductField.package_length,
    name: 'package_length',
  },
  {
    code: StoredenProductField.weight,
    name: 'weight',
  },
  {
    code: StoredenProductField.shipping_cost,
    name: 'shipping_cost',
  },
  {
    code: StoredenProductField.dataEAN,
    name: 'data.ean13',
  },
  {
    code: StoredenProductField.dataMPN,
    name: 'data.mpn',
  },
  {
    code: StoredenProductField.dataunlimitedsell,
    name: 'data.unlimitedsell',
  },
  {
    code: StoredenProductField.dataminQuantity,
    name: 'data.minQuantity',
  },
  {
    code: StoredenProductField.datamaxQuantity,
    name: 'data.maxQuantity',
  },
  {
    code: StoredenProductField.datasteps,
    name: 'data.steps',
  },
  {
    code: StoredenProductField.seotitle,
    name: 'seo.title',
  },
  {
    code: StoredenProductField.seokeywords,
    name: 'seo.keywords',
  },
  {
    code: StoredenProductField.seodescription,
    name: 'seo.description',
  },
  {
    code: StoredenProductField.additional_tabs0,
    name: 'additional_tabs.0',
  },
  {
    code: StoredenProductField.additional_tabs1,
    name: 'additional_tabs.1',
  },
  {
    code: StoredenProductField.additional_tabs2,
    name: 'additional_tabs.2',
  },
  {
    code: StoredenProductField.additional_tabs3,
    name: 'additional_tabs.3',
  },
  {
    code: StoredenProductField.additional_tabs4,
    name: 'additional_tabs.4',
  },
  {
    code: StoredenProductField.status,
    name: 'status',
  },
]
