import { SelectionData } from '../../models/util.model'
import {
  LocationType,
  LocationUpdateSubject,
} from '../locations/location.model'

export const LOCATION_TYPES = [
  {
    code: LocationType.pick,
    name: 'Prelievo',
    icon: 'fad fa-hand-holding-box',
  },
  {
    code: LocationType.stock,
    name: 'Stock',
    icon: 'fad fa-box-up',
  },
  {
    code: LocationType.tote,
    name: 'Unità di carico',
    icon: 'fad fa-box-full',
  },
]

export const LOCATION_BULK_SUBJECTS: SelectionData<LocationUpdateSubject>[] = [
  {
    label: 'Tipologia',
    value: 'TYPE',
    icon: 'fad fa-inventory',
  },
  {
    label: 'Modello',
    value: 'MODEL',
    icon: 'fad fa-ruler-combined',
  },
  {
    label: 'Abilitazione prelievo',
    value: 'IS_PLACED',
    icon: 'fad fa-low-vision',
  },
  {
    label: 'Per invendibili',
    value: 'FOR_SCRAPS',
    icon: 'fad fa-fragile',
  },
]
