import { ActionReducer, MetaReducer, State } from '@ngrx/store'
import {
  localStorageSync,
  rehydrateApplicationState,
  Keys,
} from 'ngrx-store-localstorage'
import { cloneDeep } from 'lodash'

import { StoreState, STORE_CONFIGURATION, KEYS_TO_SYNC } from '../store.state'
import { PayloadAction, storageActionType } from './storage.actions'
import { inject } from '@angular/core'

function storeReducer(
  state: State<StoreState>,
  rehydratedState: any,
  key: string,
) {
  const newState: any = cloneDeep(state)
  newState[key] = rehydratedState[key]
  return newState
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  const conf = inject(STORE_CONFIGURATION)

  if (!conf.useLocalStorage) {
    return reducer
  }

  return (state: State<StoreState>, action: any) => {
    const keys = KEYS_TO_SYNC

    const isPayloadAction = 'payload' in action
    const payloadAction: PayloadAction = action as PayloadAction
    const stateKey = payloadAction.payload as string

    if (
      action.type === storageActionType &&
      isPayloadAction &&
      keys.includes(stateKey)
    ) {
      const rehydratedState = rehydrateApplicationState(
        [stateKey] as Keys,
        localStorage,
        (k) => k,
        true,
      )

      return storeReducer(state, rehydratedState, stateKey)
    }

    return localStorageSync({
      keys,
      rehydrate: true,
    })(reducer)(state, action)
  }
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
]
