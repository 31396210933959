import { Inject, Injectable, Optional } from '@angular/core'
import { ProductsRepository } from './products.repository'
import { ProductsService } from '../products.service'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../../models/notification.model'
import { Product, ProductType, ProductVariantsData } from '../product.model'
import { Observable, combineLatest, of, switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ProductVariantsRepository extends ProductsRepository {
  constructor(
    private productsService: ProductsService,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    override notificationManager?: NotificationManager,
  ) {
    super(notificationManager)
  }

  /**
   * Create product variant
   * @param variant - the variant to create
   * @param parentId - the parent ID to link
   * @returns the observable for create the variant
   */
  createVariant$(
    parentId: string,
    variant: Product,
  ): Observable<ProductVariantsData | undefined> {
    return this.productsService.create$(variant).pipe(
      switchMap((newVariant) =>
        this.productsService.linkChildren$(parentId, [newVariant._id]),
      ),
      switchMap((parent) => this.loadVariantsData$(parent)),
    )
  }

  /**
   * Assign variants to parent
   * @param variants - the variants to assign
   * @param parentId - the parent ID
   * @returns the observable for assign variants
   */
  assignVariants$(
    parentId: string,
    variants: Product[],
  ): Observable<ProductVariantsData | undefined> {
    const childrenIds: string[] = variants.map((v) => v._id)
    return this.productsService
      .linkChildren$(parentId, childrenIds)
      .pipe(switchMap((parent) => this.loadVariantsData$(parent)))
  }

  /**
   * Set parent to product
   * @param productId - the product ID
   * @param parentId - the parent ID
   * @returns the product updated
   */
  setParent$(
    productId: string,
    parentId: string,
  ): Observable<ProductVariantsData | undefined> {
    return this.productsService.linkChildren$(parentId, [productId]).pipe(
      switchMap((_) => this.productsService.read$(productId)),
      switchMap((product) => this.loadVariantsData$(product)),
    )
  }

  /**
   * Unset parent from product
   * @param productId - the product ID
   * @param parentId - the parent ID
   * @returns the product updated
   */
  unsetParent$(
    productId: string,
    parentId: string,
  ): Observable<ProductVariantsData | undefined> {
    return this.productsService.unlinkChildren$(parentId, [productId]).pipe(
      switchMap((_) => this.productsService.read$(productId)),
      switchMap((product) => this.loadVariantsData$(product)),
    )
  }

  /**
   * Load product variants
   * @param product - the product
   * @returns the observable for load product variants
   */
  loadVariantsData$(product: Product): Observable<ProductVariantsData> {
    if (product.productType !== ProductType.virtual && !product?.parentId) {
      return of({ product })
    }
    return combineLatest({
      product: of(product),
      parent: this._loadParent$(product),
      children: this._loadChildren$(product),
    })
  }

  /**
   * Load product parent
   * @param product - the product
   * @returns the observable for load product parent
   */
  private _loadParent$(product: Product): Observable<Product | undefined> {
    if (!product.parentId && product.productType !== ProductType.virtual) {
      return of(undefined)
    }

    return product.parentId
      ? this.productsService.read$(product.parentId)
      : of(undefined)
  }

  /**
   * Load product children
   * @param product - the product
   * @returns the observable for load product children
   */
  private _loadChildren$(product: Product): Observable<Product[] | undefined> {
    if (!product.parentId && product.productType !== ProductType.virtual) {
      return of(undefined)
    }

    if (product.parentId) {
      return this.productsService.list$({ parentId: product.parentId })
    }

    if (product.variantsManagement?.childrenIds?.length) {
      return this.productsService.list$({
        _id: product.variantsManagement.childrenIds,
      })
    }

    return of(undefined)
  }
}
