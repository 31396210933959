import moment from 'moment'
import { isDate } from '@evologi/shared/util-toolkit'
import { OrderField, OrderSearchParams, OrderSortField } from '../order.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { COUNTRY_EU_CODES } from '../../../consts/country.const'
import { pick } from 'lodash'

export function orderSortParams(
  params: OrderSearchParams,
  sort: QueryStringSort<OrderSortField>,
): OrderSearchParams {
  const searchParams: OrderSearchParams = {}

  switch (sort.field) {
    case 'estimatedVolume':
      searchParams.sort = 'estimatedVolume'
      searchParams.order = sort.order
      break
    case 'estimatedWeight':
      searchParams.sort = 'estimatedWeight'
      searchParams.order = sort.order
      break
    case 'groupId':
      searchParams.sort = 'groupId'
      searchParams.order = sort.order
      break
    case 'header.date':
      searchParams.sort = 'header.date'
      searchParams.order = sort.order
      break
    case 'header.orderNumber':
      searchParams.sort = 'header.orderNumber'
      searchParams.order = sort.order
      break
    case 'header.rifDate':
      searchParams.sort = 'header.rifDate'
      searchParams.order = sort.order
      break
    case 'header.rifOrder':
      searchParams.sort = 'header.rifOrder'
      searchParams.order = sort.order
      break
    case 'header.shippingDate':
      searchParams.sort = 'header.shippingDate'
      searchParams.order = sort.order
      break
    case 'packagesCount':
      searchParams.sort = 'packagesCount'
      searchParams.order = sort.order
      break
    case 'packingDate':
      searchParams.sort = 'packingDate'
      searchParams.order = sort.order
      break
    case 'productsCount':
      searchParams.sort = 'productsCount'
      searchParams.order = sort.order
      break
    case 'rowsCount':
      searchParams.sort = 'rowsCount'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function orderFilterParams(
  params: OrderSearchParams,
  filter: QueryStringFilter<OrderField>,
): OrderSearchParams {
  const searchParams: OrderSearchParams = {}

  // Check date object
  if (
    ['string', 'object'].includes(typeof filter.value) &&
    isDate(filter.value)
  ) {
    filter.value = moment(filter.value).utc().toISOString()
  }

  // Check field
  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'assignedWarehouseId':
      if (filter.value !== null) {
        searchParams.assignedWarehouseId = filter.value
      }
      break
    case 'carrierId':
      if (filter.value !== null) {
        searchParams.carrierId = filter.value
      }
      break
    case 'estimatedVolume':
      if (filter.operator === '=') {
        searchParams.estimatedVolume = filter.value
      } else if (filter.operator === '<') {
        searchParams['estimatedVolume:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['estimatedVolume:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['estimatedVolume:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['estimatedVolume:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['estimatedVolume:ne'] = filter.value
      }
      break
    case 'estimatedWeight':
      if (filter.operator === '=') {
        searchParams.estimatedWeight = filter.value
      } else if (filter.operator === '<') {
        searchParams['estimatedWeight:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['estimatedWeight:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['estimatedWeight:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['estimatedWeight:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['estimatedWeight:ne'] = filter.value
      }
      break
    case 'groupId':
      if (filter.value === '=') {
        searchParams.groupId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['groupId:ne'] = filter.value
      }
      break
    case 'header.billingAddress.countryCode':
      if (filter.value !== null) {
        searchParams['header.billingAddress.countryCode'] = filter.value
      }
      break
    case 'header.channel':
      if (filter.value !== null) {
        searchParams['header.channel'] = filter.value
      }
      break
    case 'header.currency':
      if (filter.value !== null) {
        searchParams['header.currency'] = filter.value
      }
      break
    case 'header.date':
      if (filter.operator === '=') {
        searchParams['header.date'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.date:ne'] = filter.value
      }
      break
    case 'header.externalRifOrder':
      if (filter.operator === '=') {
        searchParams['header.externalRifOrder'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.externalRifOrder:ne'] = filter.value
      }
      break
    case 'header.gift.isEnabled':
      searchParams['header.gift.isEnabled'] = filter.value
      break
    case 'header.importantNote':
      searchParams['header.importantNote'] = filter.value
      break
    case 'header.invoiceType':
      if (filter.value !== null) {
        searchParams['header.invoiceType'] = filter.value
      }
      break
    case 'header.orderNumber':
      if (filter.operator === '=') {
        searchParams['header.orderNumber'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.orderNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.orderNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.orderNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.orderNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.orderNumber:ne'] = filter.value
      }
      break
    case 'header.packingNotes':
      if (filter.operator === '=') {
        searchParams['header.packingNotes'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.packingNotes:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['header.packingNotes:ct'] = filter.value
      }
      break
    case 'header.paid':
      searchParams['header.paid'] = filter.value
      break
    case 'header.paymentType':
      if (filter.value !== null) {
        searchParams['header.paymentType'] = filter.value
      }
      break
    case 'header.requestInvoice':
      searchParams['header.requestInvoice'] = filter.value
      break
    case 'header.rifDate':
      if (filter.operator === '=') {
        searchParams['header.rifDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.rifDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.rifDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.rifDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.rifDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifDate:ne'] = filter.value
      }
      break
    case 'header.rifOrder':
      if (filter.operator === '=') {
        searchParams['header.rifOrder'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifOrder:ne'] = filter.value
      }
      break
    case 'header.shippingAddress.countryCode':
      if (filter.value === 'CEE') {
        searchParams['header.shippingAddress.countryCode'] = COUNTRY_EU_CODES
      } else if (filter.value === '!CEE') {
        searchParams['header.shippingAddress.countryCode:ne'] = COUNTRY_EU_CODES
      } else if (filter.value !== null) {
        searchParams['header.shippingAddress.countryCode'] = filter.value
      }
      break
    case 'header.shippingDate':
      if (filter.operator === '=') {
        searchParams['header.shippingDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.shippingDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.shippingDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.shippingDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.shippingDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.shippingDate:ne'] = filter.value
      }
      break
    case 'header.transport':
      if (filter.value !== null) {
        searchParams['header.transport'] = filter.value
      }
      break
    case 'packagesCount':
      if (filter.operator === '=') {
        searchParams['packagesCount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['packagesCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['packagesCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['packagesCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['packagesCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['packagesCount:ne'] = filter.value
      }
      break
    case 'packingDate':
      if (filter.operator === '=') {
        searchParams['packingDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['packingDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['packingDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['packingDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['packingDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['packingDate:ne'] = filter.value
      }
      break
    case 'pickingListId':
      if (typeof filter.value === 'boolean') {
        searchParams['pickingListId:ex'] = filter.value
      } else if (typeof filter.value === 'string') {
        searchParams['pickingListId'] = filter.value
      }
      break
    case 'productsCount':
      if (filter.operator === '=') {
        searchParams['productsCount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['productsCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['productsCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['productsCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['productsCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['productsCount:ne'] = filter.value
      }
      break
    case 'rowsCount':
      if (filter.operator === '=') {
        searchParams['rowsCount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['rowsCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['rowsCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['rowsCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['rowsCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['rowsCount:ne'] = filter.value
      }
      break
    case 'rows.product.tags':
      if (filter.value !== null) {
        searchParams['rows.product.tags'] = filter.value
      }
      break
    case 'splitted':
      searchParams['splitted'] = filter.value
      break
    case 'returned':
      searchParams['returned'] = filter.value
      break
    case 'duplicated':
      searchParams['duplicated'] = filter.value
      break
    case 'status':
      if (filter.value !== null) {
        searchParams['status'] = filter.value
      }
      break
    case 'tags':
      if (filter.value !== null) {
        searchParams['tags'] = filter.value
      }
      break
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams['warehouseId'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse search params
 */
export function parseOrdersFilterParamsKeys(
  params: OrderSearchParams,
): OrderSearchParams {
  return pick(params, ['_id', 'header.rifOrder'])
}
