export enum WeekDay {
  monday = 'MONDAY',
  tuesday = 'TUESDAY',
  wednesday = 'WEDNESDAY',
  thursday = 'THURSDAY',
  friday = 'FRIDAY',
  saturday = 'SATURDAY',
  sunday = 'SUNDAY',
}

export interface StatusHistory<T = string> {
  status: T
  date: string
  userId?: string
}

export interface Meta {
  [key: string]: string | undefined
}

export interface Locales<T = string> {
  default?: T
  [locale: string]: T | undefined
}

export interface CatalogLocales<T> {
  catalogCode: string
  locales: Locales<T>
}

export interface Price {
  value?: number
  currency?: string
}

export interface Comment {
  _id?: string
  replyTo?: string
  date: string
  userId: string
  text: string
}

export interface SelectionData<T> {
  label: string
  value: T
  description?: string
  logo?: string
  icon?: string
}

export interface Page<T> {
  totalCount: number
  data: T[]
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
