import { BillingAddress, ShippingAddress } from '../../models/address.model'
import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { Comment, StatusHistory } from '../../models/util.model'
import { Channel } from '../channels'
import { Country } from '../countries'
import { Attachment } from '../files'
import { Payment } from '../payments'
import { Reason } from '../reasons'

export interface GoodsReturn extends Entity {
  warehouseId: string
  header: GoodsReturnHeader
  status: GoodsReturnStatus
  statusHistory: StatusHistory[]
  comments?: Comment[]
  referenceOrder?: GoodsReturnReferenceOrder
  recoveryOrder?: GoodsReturnRecoveryOrder
  rows: GoodsReturnRow[]
  attachments?: Attachment[]
}

export interface GoodsReturnHeader {
  returnNumber: number
  goodsReturnRef?: string
  channelId?: string
  paymentId?: string
  reasonId?: string
  currency: string
  date: string
  arrivalDate?: string
  creditNotes?: string
  refundDate?: string
  refundAmount?: number
  refundNotes?: string
  billingAddress?: BillingAddress
  shippingAddress?: ShippingAddress
  shippingCost?: number
  shippingCostWithTaxes?: number
  shippingTaxes?: number
  shippingTaxRate?: number
  companyCost?: number
  companyCostWithTaxes?: number
  companyCostTaxes?: number
  companyCostTaxRate?: number
  subTotal: number
  subTotalWithTaxes: number
  bodyTaxes: number
  totalReturnAmount: number
}

export interface GoodsReturnOrder {
  _id: string
  number: number
  date: string
  refOrder?: string
  refDate?: string
}

export interface GoodsReturnReferenceOrder extends GoodsReturnOrder {
  type: GoodsReturnReferenceOrderType
}

export type GoodsReturnRecoveryOrder = GoodsReturnOrder

export type GoodsReturnReferenceOrderType = 'CUSTOMER' | 'SUPPLIER'

export interface GoodsReturnRow {
  _id: string
  orderedQty: number
  returnedQty: number
  scrappedQty: number
  product: GoodsReturnProduct
  unitPrice: number // prezzo unitario articolo IVA esclusa
  unitPriceWithTaxes: number // prezzo unitario articolo IVA inclusa
  productTaxRate: number // percentuale IVA
  discount: number // sconto di riga IVA esclusa
  discountWithTaxes: number // sconto di riga IVA inclusa
  totalTaxes: number // totale IVA di riga calcolato sulla base di dei prezzi dei prodotti * qta ret - sconto di riga
  totalAmount: number // totale di riga IVA esclusa scontato (returnedQty * unitPrice) - discount
  totalAmountWithTaxes: number // totale di riga IVA inclusa scontato (returnedQty * unitPriceWithTaxes) - discountWithTaxes
}

export interface GoodsReturnProduct {
  _id: string
  SKU: string
  supplierSKU?: string
  name?: string
}

export enum GoodsReturnStatus {
  returning = 'RETURNING',
  received = 'RECEIVED',
  closed = 'CLOSED',
  canceled = 'CANCELED',
  processing = 'PROCESSING',
}
export type GoodsReturnChangeStatusAction =
  | 'return'
  | 'receive'
  | 'close'
  | 'cancel'
export type GoodsReturnPermission =
  | 'editHeader'
  | 'addRows'
  | 'editRows'
  | 'editNotes'
  | 'saveReturn'
  | 'cancelReturn'

export type GoodsReturnViewType = 'GOODS_RETURNS_LISTING_MAIN'

export type GoodsReturnField =
  | '_id'
  | 'createdAt'
  | 'status'
  | 'warehouseId'
  | 'header.returnNumber'
  | 'header.channelId'
  | 'header.paymentId'
  | 'header.reasonId'
  | 'header.currency'
  | 'header.date'
  | 'header.arrivalDate'
  | 'header.creditNotes'
  | 'header.refundDate'
  | 'header.refundAmount'
  | 'header.refundNotes'
  | 'header.shippingAddress.countryCode'
  | 'header.totalReturnAmount'
  | 'referenceOrder.number'
  | 'referenceOrder.refDate'
  | 'referenceOrder.refOrder'
  | 'recoveryOrder.number'
  | 'recoveryOrder.refDate'
  | 'recoveryOrder.refOrder'
  | 'rows.product._id'
  | 'rows.product.SKU'
  | 'rowsCount'
  | 'productsOrderedCount'
  | 'productsReturnedCount'
  | 'attachments'

export type GoodsReturnSortField =
  | 'createdAt'
  | 'header.returnNumber'
  | 'header.date'
  | 'header.arrivalDate'
  | 'header.refundDate'

export interface GoodsReturnSearchParams
  extends QueryStringParams<GoodsReturnSortField> {
  status?: GoodsReturnStatus | GoodsReturnStatus[]
  warehouseId?: string | string[]
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  'header.returnNumber'?: number | number[]
  'header.returnNumber:ne'?: number
  'header.returnNumber:lt'?: number
  'header.returnNumber:le'?: number
  'header.returnNumber:gt'?: number
  'header.returnNumber:ge'?: number
  'header.channelId'?: string | string[]
  'header.channelId:ex'?: boolean
  'header.paymentId'?: string | string[]
  'header.paymentId:ex'?: boolean
  'header.reasonId'?: string | string[]
  'header.reasonId:ex'?: boolean
  'header.currency'?: string | string[]
  'header.date'?: string
  'header.date:ne'?: string
  'header.date:lt'?: string
  'header.date:le'?: string
  'header.date:gt'?: string
  'header.date:ge'?: string
  'header.arrivalDate'?: string
  'header.arrivalDate:ne'?: string
  'header.arrivalDate:lt'?: string
  'header.arrivalDate:le'?: string
  'header.arrivalDate:gt'?: string
  'header.arrivalDate:ge'?: string
  'header.creditNotes'?: string
  'header.creditNotes:ne'?: string
  'header.refundDate'?: string
  'header.refundDate:ne'?: string
  'header.refundDate:lt'?: string
  'header.refundDate:le'?: string
  'header.refundDate:gt'?: string
  'header.refundDate:ge'?: string
  'header.refundNotes'?: string
  'header.refundNotes:ne'?: string
  'header.shippingAddress.countryCode'?: string | string[]
  'header.shippingAddress.countryCode:ne'?: string | string[]
  'referenceOrder.number'?: number
  'referenceOrder.number:ne'?: number
  'referenceOrder.refDate'?: string
  'referenceOrder.refDate:ne'?: string
  'referenceOrder.refDate:lt'?: string
  'referenceOrder.refDate:le'?: string
  'referenceOrder.refDate:gt'?: string
  'referenceOrder.refDate:ge'?: string
  'referenceOrder.refOrder'?: string
  'referenceOrder.refOrder:ne'?: string
  'recoveryOrder.number'?: number
  'recoveryOrder.number:ne'?: number
  'recoveryOrder.refDate'?: string
  'recoveryOrder.refDate:ne'?: string
  'recoveryOrder.refDate:lt'?: string
  'recoveryOrder.refDate:le'?: string
  'recoveryOrder.refDate:gt'?: string
  'recoveryOrder.refDate:ge'?: string
  'recoveryOrder.refOrder'?: string
  'recoveryOrder.refOrder:ne'?: string
  'rows.product._id'?: string | string[]
  'rows.product.SKU'?: string | string[]
  q?: string
}

export interface GoodsReturnCounters {
  RETURNING: number
  RECEIVED: number
  CLOSED: number
  CANCELED: number
}

export type GoodsReturnNotificationCode =
  | 'GOODS_RETURN_INCOMPLETE'
  | 'GOODS_RETURN_SAVED'
  | 'GOODS_RETURN_DELETION'
  | 'GOODS_RETURN_DELETED'

export interface GoodsReturnNotificationOptions {
  code: GoodsReturnNotificationCode
  data?: GoodsReturnNotificationData
}

export interface GoodsReturnNotificationData {
  goodsReturn?: GoodsReturn
}

export interface GoodsReturnsListingPage {
  data?: GoodsReturn[]
  extData?: GoodsReturnsListingData
  totalCount?: number
}

export interface GoodsReturnListingKeys {
  channelIds?: string[]
  paymentIds?: string[]
  reasonIds?: string[]
  countryCodes?: string[]
}

export interface GoodsReturnsListingData {
  channels?: Channel[]
  payments?: Payment[]
  reasons?: Reason[]
  countries?: Country[]
}
