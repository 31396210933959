<!-- Body -->
<div class="modal-body text-center">
  <p>Hai effettuato delle modifiche.<br />Vuoi salvarle?</p>
  <button class="btn btn-default" type="button" (click)="closeModal()">
    Annulla
  </button>
  <button class="btn btn-danger ml-1" type="button" (click)="closeModal(true)">
    No
  </button>
  <button
    class="btn btn-primary ml-1"
    type="button"
    (click)="closeModal(true, true)"
  >
    Si
  </button>
</div>
