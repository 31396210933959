import { cloneDeep, defaultsDeep, merge } from 'lodash'

import {
  GOODS_RECEIVE_HEADER_INITIAL_STATE,
  updateGoodsReceiveHeaderAmounts,
} from './goods-receive-header.lib'
import {
  copyRowOrderedQty,
  createGoodsReceiveRows,
  createGoodsReceiveSupplierRows,
  updateGoodsReceiveRowAmounts,
  copyRowReceivedData,
  copyRowReceivedQty,
} from './goods-receive-rows.lib'
import {
  GoodsReceive,
  GoodsReceiveField,
  GoodsReceiveStatus,
  GoodsReceiveViewType,
} from '../goods-receive.model'
import { GOODS_RECEIVE_FIELDS } from '../goods-receive.const'
import { DeepPartial } from '../../../models/util.model'
import { ProductSelection } from '../../products/product.model'
import { SupplierOrderRowSelection } from '../../supplier-orders/supplier-order.model'
import { Tenant } from '../../tenants'
import { OrderTransport } from '../../orders'

// Initial states

const GOODS_RECEIVE_INITIAL_STATE: DeepPartial<GoodsReceive> = {
  status: GoodsReceiveStatus.drafted,
  header: GOODS_RECEIVE_HEADER_INITIAL_STATE,
  rows: [],
}

// Init

export function initGoodsReceive(
  goodsReceive: Partial<GoodsReceive> = {}
): GoodsReceive {
  return defaultsDeep(cloneDeep(goodsReceive), GOODS_RECEIVE_INITIAL_STATE)
}

export function setGoodsReceiveDefaults(
  goodsReceive: GoodsReceive,
  defaults: DeepPartial<GoodsReceive>
): GoodsReceive {
  return merge(cloneDeep(goodsReceive), defaults)
}

// Amounts

export function updateGoodsReceiveAmounts(
  goodsReceive: GoodsReceive
): GoodsReceive {
  const rows = goodsReceive.rows.map((row) => updateGoodsReceiveRowAmounts(row))
  const header = updateGoodsReceiveHeaderAmounts(goodsReceive.header, rows)

  return {
    ...goodsReceive,
    header,
    rows,
  }
}

// Rows

export function addGoodsReceiveProducts(
  goodsReceive: GoodsReceive,
  selection: ProductSelection[]
): GoodsReceive {
  return updateGoodsReceiveAmounts({
    ...goodsReceive,
    rows: [...goodsReceive.rows, ...createGoodsReceiveRows(selection)],
  })
}

export function addGoodsReceiveOrderRows(
  goodsReceive: GoodsReceive,
  selection: SupplierOrderRowSelection[]
): GoodsReceive {
  return updateGoodsReceiveAmounts({
    ...goodsReceive,
    rows: [...goodsReceive.rows, ...createGoodsReceiveSupplierRows(selection)],
  })
}

export function copyGoodsReceivedData(
  goodsReceive: GoodsReceive
): GoodsReceive {
  return updateGoodsReceiveAmounts({
    ...goodsReceive,
    rows: goodsReceive.rows.map((r) => copyRowReceivedData(r)),
  })
}

export function copyGoodsReceivedQty(goodsReceive: GoodsReceive): GoodsReceive {
  return updateGoodsReceiveAmounts({
    ...goodsReceive,
    rows: goodsReceive.rows.map((r) => copyRowReceivedQty(r)),
  })
}

export function copyGoodsOrderedQty(goodsReceive: GoodsReceive): GoodsReceive {
  return updateGoodsReceiveAmounts({
    ...goodsReceive,
    rows: goodsReceive.rows.map((r) => copyRowOrderedQty(r)),
  })
}

// Defaults

export function getGoodsReceiveDefaults(
  tenant: Tenant
): DeepPartial<GoodsReceive> {
  return {
    warehouseId: tenant.goodsReceive?.warehouseId,
    locationId: tenant.goodsReceive?.locationId,
    header: {
      paymentType: tenant.goodsReceive?.payment,
      transport: tenant.goodsReceive?.transport as OrderTransport,
      shippingCost: tenant.goodsReceive?.shippingCost,
      shippingTaxRate: tenant.goodsReceive?.shippingTaxes,
      currency: tenant.defaults?.currency,
    },
  }
}

export function getGoodsReceivesListingFields(
  viewType: GoodsReceiveViewType
): GoodsReceiveField[] {
  return GOODS_RECEIVE_FIELDS.filter((f) =>
    f.defaultViews?.includes(viewType)
  ).map((f) => f.field)
}
