import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies'
import {
  WebhookCollection,
  WebhookCollectionStatus,
  WebhookEvent,
} from './webhook.model'

export const WEBHOOK_SCOPE: PermissionsScope = 'integrations'

export const WEBHOOK_COLLECTIONS: SelectionData<WebhookCollection>[] = [
  {
    label: 'Ordini clienti',
    value: WebhookCollection.order,
    icon: 'fad fa-shopping-bag',
  },
  {
    label: 'Resi',
    value: WebhookCollection.goodsReturn,
    icon: 'fad fa-box-archive',
  },
]

interface WebhookCollectionStatusModel
  extends SelectionData<WebhookCollectionStatus> {
  collection: WebhookCollection
}

export const WEBHOOK_COLLECTION_STATUSES: WebhookCollectionStatusModel[] = [
  {
    label: 'Creato',
    value: WebhookCollectionStatus.created,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Aggiornato',
    value: WebhookCollectionStatus.updated,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Eliminato',
    value: WebhookCollectionStatus.deleted,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'In rientro',
    value: WebhookCollectionStatus.returning,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Ricevuto',
    value: WebhookCollectionStatus.received,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Chiuso',
    value: WebhookCollectionStatus.closed,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Annullato',
    value: WebhookCollectionStatus.canceled,
    collection: WebhookCollection.goodsReturn,
  },
  {
    label: 'Annullato',
    value: WebhookCollectionStatus.canceled,
    collection: WebhookCollection.order,
  },
  {
    label: 'Confermato',
    value: WebhookCollectionStatus.confirmed,
    collection: WebhookCollection.order,
  },
  {
    label: 'Creato',
    value: WebhookCollectionStatus.created,
    collection: WebhookCollection.order,
  },
  {
    label: 'Eliminato',
    value: WebhookCollectionStatus.deleted,
    collection: WebhookCollection.order,
  },
  {
    label: 'Consegnato',
    value: WebhookCollectionStatus.delivered,
    collection: WebhookCollection.order,
  },
  {
    label: 'Messo in dropship',
    value: WebhookCollectionStatus.dropshipped,
    collection: WebhookCollection.order,
  },
  {
    label: 'Ritirato',
    value: WebhookCollectionStatus.handed,
    collection: WebhookCollection.order,
  },
  {
    label: 'Imballato',
    value: WebhookCollectionStatus.packed,
    collection: WebhookCollection.order,
  },
  {
    label: 'Parcheggiato',
    value: WebhookCollectionStatus.parked,
    collection: WebhookCollection.order,
  },
  {
    label: 'In attesa',
    value: WebhookCollectionStatus.pending,
    collection: WebhookCollection.order,
  },
  {
    label: 'Processato',
    value: WebhookCollectionStatus.processed,
    collection: WebhookCollection.order,
  },
  {
    label: 'In lavorazione',
    value: WebhookCollectionStatus.processing,
    collection: WebhookCollection.order,
  },
  {
    label: 'Spedito',
    value: WebhookCollectionStatus.shipped,
    collection: WebhookCollection.order,
  },
  {
    label: 'Aggiornato',
    value: WebhookCollectionStatus.updated,
    collection: WebhookCollection.order,
  },
]

export const WEBHOOK_EVENTS: SelectionData<WebhookEvent>[] = [
  {
    label: 'Reso aggiornato',
    value: WebhookEvent.goodsReturnUpdated,
  },
  {
    label: 'Reso in rientro',
    value: WebhookEvent.goodsReturnReturning,
  },
  {
    label: 'Reso ricevuto',
    value: WebhookEvent.goodsReturnReceived,
  },
  {
    label: 'Reso eliminato',
    value: WebhookEvent.goodsReturnDeleted,
  },
  {
    label: 'Reso creato',
    value: WebhookEvent.goodsReturnCreated,
  },
  {
    label: 'Reso chiuso',
    value: WebhookEvent.goodsReturnClosed,
  },
  {
    label: 'Reso annullato',
    value: WebhookEvent.goodsReturnCanceled,
  },
  {
    label: 'Ordine cliente cancellato',
    value: WebhookEvent.orderCanceled,
  },
  {
    label: 'Ordine cliente confermato',
    value: WebhookEvent.orderConfirmed,
  },
  {
    label: 'Ordine cliente creato',
    value: WebhookEvent.orderCreated,
  },
  {
    label: 'Ordine cliente eliminato',
    value: WebhookEvent.orderDeleted,
  },
  {
    label: 'Ordine cliente consegnato',
    value: WebhookEvent.orderDelivered,
  },
  {
    label: 'Ordine cliente in dropship',
    value: WebhookEvent.orderDropshipped,
  },
  {
    label: 'Ordine cliente ritirato',
    value: WebhookEvent.orderHanded,
  },
  {
    label: 'Ordine cliente imballato',
    value: WebhookEvent.orderPacked,
  },
  {
    label: 'Ordine cliente parcheggiato',
    value: WebhookEvent.orderParked,
  },
  {
    label: 'Ordine cliente in attesa',
    value: WebhookEvent.orderPending,
  },
  {
    label: 'Ordine cliente processato',
    value: WebhookEvent.orderProcessed,
  },
  {
    label: 'Ordine cliente in lavorazione',
    value: WebhookEvent.orderProcessing,
  },
  {
    label: 'Ordine cliente spedito',
    value: WebhookEvent.orderShipped,
  },
  {
    label: 'Ordine cliente aggiornato',
    value: WebhookEvent.orderUpdated,
  },
]
