import { Meta } from '../models/util.model'

export function addMetaKey(meta: Meta = {}, metaKey: string): Meta {
  return {
    ...meta,
    [metaKey]: undefined,
  }
}

export function removeMetaKey(
  meta: Meta = {},
  metaKey: string
): Meta | undefined {
  delete meta[metaKey]
  return Object.keys(meta).length ? { ...meta } : undefined
}
