import { Pipe, PipeTransform } from '@angular/core'
import { get } from 'lodash'

@Pipe({
  name: 'reduce',
})
export class ReducePipe implements PipeTransform {
  transform(values: any[], key: string, initialValues = []): any {
    return values.reduce((acc, value) => {
      const val = get(value, key)
      return Array.isArray(val) ? [...acc, ...val] : [...acc, val]
    }, initialValues)
  }
}
