import { Inject, Injectable, Optional } from '@angular/core'
import { Warehouse, WarehousesService } from '../warehouses'
import { Product, ProductsService } from '../products'
import { Location, LocationsService } from '../locations'
import { User, UsersService } from '../users'
import {
  Replenishment,
  ReplenishmentField,
  ReplenishmentNotification,
  ReplenishmentSearchParams,
  ReplenishmentsListingData,
  ReplenishmnetsListingPage,
} from './replenishment.model'
import { EMPTY, Observable, combineLatest, map, of, switchMap } from 'rxjs'
import { ReplenishmentsService } from './replenishments.service'
import { Page } from '../../models/util.model'
import {
  parseRepPageKeys,
  parseReplenishmentNotification,
} from './replenishment.lib'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'

@Injectable({
  providedIn: 'root',
})
export class ReplenishmentsRepository {
  constructor(
    private replenishmentsService: ReplenishmentsService,
    private warehousesService: WarehousesService,
    private locationsService: LocationsService,
    private usersService: UsersService,
    private productsService: ProductsService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Search replenishments and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search replenishments and external data
   */
  searchReplenishments$(
    searchParams: ReplenishmentSearchParams,
    fields: ReplenishmentField[],
    listingData?: ReplenishmentsListingData,
  ): Observable<ReplenishmnetsListingPage> {
    return this.replenishmentsService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Notify a message about a replenishment event
   * @param notification - the replenishment notification
   */
  notify(notification: ReplenishmentNotification): void {
    this.notificationManager?.show(parseReplenishmentNotification(notification))
  }

  /**
   * Show dialog to confirm replenishment cancellation
   * @param replenishment - the replenishment to cancel
   * @returns the observable for show the dialog and confirm the cancellation
   */
  showCancelDialog$(replenishment: Replenishment): Observable<boolean> {
    if (!this.modalManager) {
      return EMPTY
    }

    const message =
      `Stai per annullare l'abbassamento <strong>#${replenishment.replenishmentNumber}</strong>.<br />` +
      `Procedere all'annullamento?`

    return this.modalManager.showDialog$({
      icon: 'warning',
      color: 'danger',
      message,
      size: 'lg',
    })
  }

  /**
   * Load page external data
   * @param page - the replenishments page
   * @param fields - the fields
   * @returns the observable for load external data
   */
  private loadExtData$(
    page: Page<Replenishment>,
    fields: ReplenishmentField[],
    listingData?: ReplenishmentsListingData,
  ): Observable<ReplenishmentsListingData> {
    const extDataKeys = parseRepPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('warehouseId') && extDataKeys.warehouseIds) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (fields.includes('userId') && extDataKeys.userIds) {
      obs$['users'] = this.usersService.store$(
        extDataKeys.userIds,
        listingData?.users || [],
      )
    }

    if (fields.includes('product._id') && extDataKeys.productIds) {
      obs$['products'] = this.productsService.store$(
        extDataKeys.productIds,
        listingData?.products || [],
      )
    }

    if (
      (fields.includes('target._id') || fields.includes('source._id')) &&
      extDataKeys.locationIds
    ) {
      obs$['locations'] = this.locationsService.store$(
        extDataKeys.locationIds,
        listingData?.locations || [],
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }
}
