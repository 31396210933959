import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators'
import { LocalesService } from '@evologi/shared/data-access-api'

import * as AuthActions from '../auth/auth.actions'
import * as LocalesActions from './locales.actions'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LocalesEffects {
  // Lifecycle effects

  tenantSavedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSavedSuccess),
      switchMap((action) => {
        const tenant = action.tenant
        const obs$ =
          tenant?.catalog?.locales && tenant.catalog.locales.length
            ? this.localesService.list$({
                tag: tenant.catalog.locales,
              })
            : of([])

        return obs$.pipe(
          map((locales) =>
            LocalesActions.setLocales({
              tenantId: tenant._id,
              locales,
            }),
          ),
        )
      }),
    )
  })

  destroyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOutSuccess),
      map(() => LocalesActions.destroyLocales()),
    )
  })

  // Crud effects

  getLocales$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocalesActions.loadLocales),
      switchMap((action) => {
        const obs$ = action.params
          ? this.localesService.list$(action.params)
          : this.localesService.list$(undefined, true)

        return obs$.pipe(
          map((locales) =>
            LocalesActions.setLocales({
              tenantId: action.tenantId,
              locales,
            }),
          ),
        )
      }),
    )
  })

  constructor(
    private actions$: Actions,
    private localesService: LocalesService,
  ) {}
}
