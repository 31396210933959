import { Pipe, PipeTransform } from '@angular/core'
import { intersection } from 'lodash'

@Pipe({
  name: 'intersection',
})
export class IntersectionPipe implements PipeTransform {
  transform(values: any[], valuesToIntersect: any[]): any[] {
    return intersection(values, valuesToIntersect)
  }
}
