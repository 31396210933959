import { omit } from 'lodash'
import { QueryStringParams } from '../models/query-string.model'

/**
 * Indicates if the params contain filters
 * @param params - the search params
 * @returns true if the params contain filters, false otherwise
 */
export function isQueryStringFiltered<T extends QueryStringParams>(
  params: T,
  omitFields?: string[]
): boolean {
  const filterParams = omit(params, [
    'limit',
    'offset',
    'sort',
    'fields',
    'order',
    ...(omitFields || []),
  ])
  return Object.keys(filterParams).length > 0
}
