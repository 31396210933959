import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export enum BordereauStatus {
  processing = 'PROCESSING',
  shipped = 'SHIPPED',
}

export interface Bordereau extends Entity {
  warehouseId: string
  carrierId?: string
  status: BordereauStatus
  number: number
  packagesCount: number
  orders: string[]
  date: Date
  sentToCarrierDate?: Date
}

export type BordereauSearchField =
  | '_id'
  | 'number'
  | 'date'
  | 'sentToCarrierDate'
  | 'carrierId'
  | 'status'
  | 'packagesCount'
export type BordereauSortField = 'number' | 'date' | 'sentToCarrierDate'

export interface BordereauSearchParams
  extends QueryStringParams<BordereauSortField> {
  number?: number
  'number:ne'?: number
  'number:lt'?: number
  'number:le'?: number
  'number:gt'?: number
  'number:ge'?: number
  date?: string
  'date:ne'?: string
  'date:lt'?: string
  'date:le'?: string
  'date:gt'?: string
  'date:ge'?: string
  sentToCarrierDate?: string
  'sentToCarrierDate:ne'?: string
  'sentToCarrierDate:lt'?: string
  'sentToCarrierDate:le'?: string
  'sentToCarrierDate:gt'?: string
  'sentToCarrierDate:ge'?: string
  warehouseId?: string[] | string
  carrierId?: string[] | string
  status?: BordereauStatus[] | BordereauStatus
  'orders:sz'?: number
  'orders:ns'?: number
  packagesCount?: number
  'packagesCount:ne'?: number
  'packagesCount:lt'?: number
  'packagesCount:le'?: number
  'packagesCount:gt'?: number
  'packagesCount:ge'?: number
}

export interface BordereauOrderSearchParams {
  limit?: number
  offset?: number
}
