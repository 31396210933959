<router-outlet></router-outlet>

<!-- Global loader -->
<ngx-spinner
  bdColor="rgba(0,0,0,0.5)"
  size="large"
  color="#fcbe1e"
  type="square-jelly-box"
  [fullScreen]="true"
>
</ngx-spinner>

<!-- Router loader -->
<ngx-loading-bar ref="page" [includeSpinner]="false"></ngx-loading-bar>
