import { Pipe, PipeTransform } from '@angular/core'
import { uniq } from 'lodash'
import { Observable, of } from 'rxjs'
import { ProductsService } from '../products.service'
import { Product } from '../product.model'

@Pipe({
  name: 'products',
})
export class ProductsPipe implements PipeTransform {
  constructor(private productsService: ProductsService) {}

  transform(productIds?: string[]): Observable<Product[] | undefined> {
    if (!productIds) {
      return of(undefined)
    }

    productIds = uniq(productIds)
    if (!productIds.length) {
      return of(undefined)
    }

    return this.productsService.list$({ _id: productIds }, true)
  }
}
