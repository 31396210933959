import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Webhook, WebhookSearchParams } from './webhook.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'webhooks'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class WebhooksService extends CrudService {
  protected model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new webhook
   * @param webhook - The webhook to create
   * @returns The observable<Webhook> to create the webhook
   */
  create$(webhook: Webhook): Observable<Webhook> {
    return this._create$<Webhook>(webhook)
  }

  /**
   * Read a webhook by ID
   * @param webhookId - The webhook ID
   * @returns The observable<Webhook> for read the webhook
   */
  read$(webhookId: string): Observable<Webhook> {
    return this._read$<Webhook>(webhookId)
  }

  /**
   * Update a webhook by ID
   * @param webhookId - The webhook ID
   * @param webhook - The webhook body to update
   * @returns The observable<Webhook> for update the webhook
   */
  update$(webhookId: string, webhook: Webhook): Observable<Webhook> {
    return this._update$<Webhook>(webhookId, webhook)
  }

  /**
   * Create or update a webhook by ID
   * @param webhookId - The webhook ID
   * @param webhook - The webhook body to update
   * @returns The observable<Webhook> for update the webhook
   */
  upsert$(webhook: Webhook): Observable<Webhook> {
    return this._upsert$<Webhook>(webhook, webhook._id)
  }

  /**
   * Delete a webhook by ID
   * @param webhookId - The webhook ID
   * @returns The observable<Webhook> for delete the webhook
   */
  delete$(webhookId: string): Observable<Webhook> {
    return this._delete$<Webhook>(webhookId)
  }

  /**
   * Search webhooks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Webhook>> for search webhooks
   */
  search$(
    params?: WebhookSearchParams,
    returnAll = false,
  ): Observable<Page<Webhook>> {
    return this._search$<Webhook>(params, returnAll)
  }

  /**
   * List webhooks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Webhook[]> for list webhooks
   */
  list$(
    params?: WebhookSearchParams,
    returnAll = false,
  ): Observable<Webhook[]> {
    return this._list$<Webhook>(params, returnAll)
  }
}
