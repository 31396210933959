import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'abcClass',
})
export class AbcClassPipe implements PipeTransform {
  transform(abcClass: string | undefined): string {
    if (!abcClass) {
      return 'transparent'
    }

    const abcIndex = abcClass.trim().toUpperCase()

    if (abcIndex.startsWith('A')) {
      return 'red'
    } else if (abcIndex.startsWith('B')) {
      return 'orange'
    } else if (abcIndex.startsWith('C')) {
      return 'blue'
    }

    return 'transparent'
  }
}
