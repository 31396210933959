<ng-container *ngVar="user$ | ngrxPush as user">
  <ng-container *ngVar="tenant$ | ngrxPush as tenant">
    <!-- User -->
    <ng-container *ngIf="user; else noUser">
      <!-- Authenticated -->
      <ng-container *ngIf="tenant; else noTenant">
        <h1 class="title text-left">
          Hai effettuato l'accesso ad <em>order<strong>pod</strong></em>
        </h1>
        <button
          class="btn btn-outline-secondary btn-choice btn-block btn-lg"
          type="button"
          [routerLink]="['/home']"
        >
          <i class="icon fad fa-store-alt"></i>
          <div class="data text-left">
            <h4 class="label">{{ tenant.name }}</h4>
          </div>
        </button>
      </ng-container>

      <!-- Not authenticated -->
      <ng-template #noTenant>
        <h5 class="title">Scegli il tenant su cui lavorare</h5>
        <div class="tenants-wrapper">
          <button
            *ngFor="let tenant of user.tenants"
            class="btn btn-outline-primary btn-block btn-choice btn-xl mb-3"
            (click)="selectTenant(tenant._id)"
          >
            <i class="icon fad fa-store-alt"></i>
            <div class="data text-left">
              <h4 class="label">{{ tenant.name }}</h4>
            </div>
          </button>
        </div>
        <a
          class="link-back text-secondary"
          data-test="link-back"
          [routerLink]="['/auth/login']"
        >
          Torna al login
        </a>
      </ng-template>
    </ng-container>

    <!-- No user -->
    <ng-template #noUser>
      <h1 class="title">
        Sembra tu sia uscito da <em>order<strong>pod</strong></em>
      </h1>
      <button
        class="btn btn-outline-secondary btn-choice btn-block btn-lg"
        type="button"
        [routerLink]="['/auth/login']"
      >
        <i class="icon fad fa-portal-enter"></i>
        <div class="data text-left">
          <h4 class="label">Torna al login</h4>
        </div>
      </button>
    </ng-template>
  </ng-container>
</ng-container>
