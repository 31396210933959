import { cloneDeep, defaultsDeep } from 'lodash'
import { Category } from './category.model'

// Initial states

const CATEGORY_INITIAL_STATE: Partial<Category> = {
  name: {},
}

// Init

export function initCategory(category: Partial<Category> = {}): Category {
  return defaultsDeep(cloneDeep(category), CATEGORY_INITIAL_STATE)
}

// Parser

export function parseCategoryTree(
  categories: Category[],
  categoriesSelected: string[],
  disabled = false,
) {
  const root = categories.find((c) => !c.parentId)
  return categories
    .filter((c) => c._id !== root?._id)
    .map((cat) =>
      parseCategory(
        cat,
        cat.parentId === root?._id,
        false,
        categoriesSelected.includes(cat._id),
        disabled,
      ),
    )
}

export function parseCategory(
  cat: Category,
  isRoot: boolean,
  isOpened: boolean,
  isSelected: boolean,
  isDisabled: boolean,
) {
  return {
    ...cat,
    id: cat._id,
    parent: isRoot ? '#' : cat.parentId,
    text: cat.name.default,
    type: isRoot ? 'root' : 'leaf',
    state: {
      opened: isOpened,
      draggable: !isRoot,
      selected: isSelected,
      disabled: isDisabled,
    },
    a_attr: { title: cat.name.default },
  }
}
