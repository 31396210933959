import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  ChannelNotificationCode,
  ChannelNotificationData,
  ChannelNotificationOptions,
} from '../channel.model'

export class ChannelNotification implements Notification {
  public code: ChannelNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: ChannelNotificationOptions) {
    const { type, dialog } = parseChannelNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: ChannelNotificationOptions): ChannelNotification {
    if (opts instanceof ChannelNotification) {
      return opts
    } else {
      return new ChannelNotification(opts)
    }
  }
}

/**
 * Parse channel notification to a message
 * @param notification - the channel notification
 * @returns the notification data
 */
export function parseChannelNotification(
  code: ChannelNotificationCode,
  data?: ChannelNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'CHANNEL_SAVED':
      dialog = {
        message: 'Channel saved successfully',
        color: 'success',
      }
      break
    case 'CHANNEL_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'CHANNEL_DELETION': {
      const channelName = data?.channel?.name
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete channel',
        message:
          'You are about to delete the channel <strong>%s</strong>. Proceed with deletion?',
        messageParams: channelName ? [channelName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'CHANNEL_DELETED':
      dialog = {
        message: 'Channel deleted successfully',
        color: 'success',
      }
      break
    case 'CHANNEL_CONNECTED':
      dialog = {
        message: 'Channel connected to external channel',
        color: 'success',
      }
      break
    case 'CHANNEL_DISCONNECTED':
      dialog = {
        message: 'Channel disconnected from external channel',
        color: 'success',
      }
      break
    case 'STOREDEN_MISSING_MAPPINGS':
      dialog = {
        message: 'Storeden missing fields',
        color: 'danger',
      }
      break
    case 'SYNC_STARTED':
      dialog = {
        message: 'Channel sync started',
        color: 'info',
      }
      break
  }

  return { dialog, type }
}
