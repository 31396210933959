import { uniq } from 'lodash'
import {
  PackingList,
  PackingListAction,
  PackingListOrder,
  PackingListOrderStatus,
  PackingListType,
} from '../packing-list.model'
import { Product } from '../../products'
import { Order } from '../../orders'

/**
 * Get printed orders of a packing list
 * @param packingList - The packing list
 * @returns the quantity of printed orders of a packing list
 */
export function getPackingOrdersPrinted(packingList: PackingList): number {
  return packingList.orders.filter(
    (order) => order.status === PackingListOrderStatus.printed
  ).length
}

/**
 * Indicates if a packing order is packed or not
 * @param packingList - The packing list
 * @param orderId - The order ID
 * @returns the boolean that indicates if the order is packed
 */
export function isPackingOrderPacked(
  packingList: PackingList,
  orderId: string
): boolean {
  const packOrder = packingList.orders.find((o) => o._id === orderId)
  return packOrder?.status === PackingListOrderStatus.packed
}

/**
 * Check if all actions of an packing list order is packed
 * @param packingList - the packing list to check
 * @param orderId - the order ID
 * @returns the boolean that indicates if the order is packed
 */
export function isPackignOrderActionsPacked(
  packingList: PackingList,
  orderId: string
): boolean {
  const orderActions = packingList.actions.filter(
    (action) => action.orderId === orderId
  )

  if (!orderActions.length) {
    return false
  }

  return orderActions.reduce(
    (packed, action) =>
      packed ? action.qtyPacked === action.qtyToPack : packed,
    true
  )
}

/**
 * Check if an order is completely packable
 * @param packingList - The packing list
 * @param orderId - The order ID
 * @returns the boolean that indicates if the order is packable
 */
export function isPackingOrderPackable(
  packingList: PackingList,
  orderId: string
): boolean {
  // An order can entirely packable only on a pack type
  if (packingList.type === PackingListType.sort) {
    return false
  }

  const packOrder = packingList.orders.find((o) => o._id === orderId)

  if (!packOrder) {
    return false
  }

  return (
    packOrder.status === PackingListOrderStatus.ready ||
    packOrder.status === PackingListOrderStatus.processing ||
    packOrder.status === PackingListOrderStatus.packed
  )
}

/**
 * Indicates if a packing order is waiting to be picked
 * @param packingList - The packing list
 * @param orderId - The order ID
 * @returns the boolean that indicates if the order is waiting to be picked
 */
export function isPackingOrderWaiting(
  packingList: PackingList,
  orderId: string
): boolean {
  const packOrder = packingList.orders.find((o) => o._id === orderId)
  return packOrder?.status === PackingListOrderStatus.waiting
}

/**
 * Indicates if a packing order is the last to pack
 * @param packingList - The packing list
 * @param orderId - The order ID
 * @returns the boolean that indicates if the order is the last to pack
 */
export function isPackingOrderLast(
  packingList: PackingList,
  orderId: string
): boolean {
  const packableOrders = getPackingOrdersPackable(packingList).filter(
    (o) => o._id !== orderId
  )
  return !packableOrders.length
}

/**
 * Return a list of orders packable of the packing list
 * @param packingList - the packing-list
 * @returns the list of packable orders
 */
export function getPackingOrdersPackable(
  packingList: PackingList
): PackingListOrder[] {
  return packingList.orders.filter(
    (ord) =>
      ord.status !== PackingListOrderStatus.printed &&
      ord.status !== PackingListOrderStatus.canceled
  )
}

/**
 * Return a list of actions packable of the packing list
 * @param packingList - the packing-list
 * @param orderId - the order ID
 * @returns the list of packable actions
 */
export function getPackingOrderActionsPackable(
  packingList: PackingList,
  orderId: string
): PackingListAction[] {
  return packingList.actions.filter(
    (action) =>
      action.orderId === orderId && action.qtyPacked < action.qtyToPack
  )
}

/**
 * Get the order to pack
 * @param packingList - the packing-list
 * @param currentOrderId - the current order processing to skip
 * @returns the packing-list order to pack
 */
export function getPackingOrderToPack(
  packingList: PackingList
): PackingListOrder | undefined {
  // Orders packable
  const ordersToPack = getPackingOrdersPackable(packingList)

  // If no orders
  if (!ordersToPack.length) {
    return undefined
  }

  // If only one orders
  if (ordersToPack.length === 1) {
    return ordersToPack[0]
  }

  return (
    ordersToPack.find((o) => o.status === PackingListOrderStatus.processing) ||
    ordersToPack.find((o) => o.status === PackingListOrderStatus.packed)
  )
}

/**
 * Get packing next order
 * @param packingList - the packing-list
 * @param currentOrderId - the current order ID
 * @returns the packing-list order
 */
export function getPackingNextOrder(
  packingList: PackingList,
  currentOrderId?: string
): PackingListOrder {
  // Orders packable
  const ordersToPack = getPackingOrdersPackable(packingList)

  const currentIndex = currentOrderId
    ? ordersToPack.findIndex((ord) => ord._id === currentOrderId)
    : -1
  return ordersToPack[currentIndex + 1] || ordersToPack[0]
}

/**
 * Get the action to pack
 * @param packingList - the packing-list
 * @param orderId - the order ID
 * @returns the action to pack of an order
 */
export function getPackingActionToPack(
  packingList: PackingList,
  orderId: string
): PackingListAction | undefined {
  const actionsToPack = getPackingOrderActionsPackable(packingList, orderId)

  // If no actions
  if (!actionsToPack.length) {
    return packingList.actions.find((a) => a.orderId === orderId)
  }

  // If only one action
  if (actionsToPack.length === 1) {
    return actionsToPack[0]
  }

  return (
    actionsToPack.find((action) => action.qtyPacked > 0) || actionsToPack[0]
  )
}

/**
 * Get packing order and its products tags
 * @param order - the order to pack
 * @param products - the products to pack
 * @returns the array of tags
 */
export function getPackingOrderTags(
  order: Order,
  products: Product[]
): string[] {
  return uniq([
    ...(order.tags || []),
    ...products.reduce((acc, p) => [...acc, ...(p.tags || [])], [] as string[]),
  ])
}
