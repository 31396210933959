import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  Observable,
  map,
  switchMap,
  filter,
  tap,
  withLatestFrom,
  take,
} from 'rxjs'
import {
  Customer,
  CustomerSearchField,
  CustomerSearchParams,
  CustomersListingPage,
} from '../customer.model'
import { CustomersService } from '../customers.service'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { CustomersRepository } from '../customers.repository'
import { CustomerNotification } from '../libs/customer-notification.lib'
import { Country } from '../../countries'

interface CustomersListingState {
  filters?: CustomerSearchParams
  fields?: CustomerSearchField[]
  data?: Customer[]
  extData?: CustomersListingData
  totalCount?: number
  notification?: CustomerNotification
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

export interface CustomersListingData {
  countries?: Country[]
}

const CUSTOMERS_STORE_INITIAL_STATE: CustomersListingState = {
  toReload: false,
  isLoading: false,
  isInitialized: false,
  extData: {},
}

@Injectable()
export class CustomersListingUseCase extends ComponentStore<CustomersListingState> {
  constructor(
    private customersService: CustomersService,
    private customersRepository: CustomersRepository,
  ) {
    super(CUSTOMERS_STORE_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<CustomersListingState> = this.select(
    (state) => state,
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectFilters$: Observable<CustomerSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {}, ['_id'])),
  )

  readonly selectCustomers$: Observable<Customer[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectExtData$: Observable<CustomersListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectCountries$: Observable<Country[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.countries))

  readonly selectPage$: Observable<Page<Customer>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectCustomers$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectNotification$ = this.select((state) => state.notification)

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<CustomerSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        switchMap(([_, state]) =>
          this.customersRepository
            .searchCustomers$(
              state.filters || {},
              state.fields || [],
              state.extData,
            )
            .pipe(
              tapResponse(
                (pageData) => pageData && this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<CustomerSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectState$),
        map(([params, state]) => ({
          params: {
            ...params,
            limit: state.totalCount,
          },
          fields: state.fields || [],
          extData: state.extData,
        })),
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap(({ params, fields, extData }) =>
          this.customersRepository
            .searchCustomers$(params, fields, extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly delete$ = this.effect((customer$: Observable<Customer>) => {
    return customer$.pipe(
      switchMap((customer) =>
        this.customersRepository
          .alert$(
            CustomerNotification.from({
              code: 'CUSTOMER_DELETION',
              data: { customer },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.customersService.delete$(customer._id).pipe(
                tapResponse(
                  () => this.setCustomerDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  // Reducers

  readonly setFilters = this.updater(
    (state, filters?: CustomerSearchParams): CustomersListingState => {
      return {
        fields: state.fields,
        filters,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
        extData: state.extData,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: CustomersListingPage): CustomersListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): CustomersListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): CustomersListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setCustomers = this.updater(
    (state, data: Customer[]): CustomersListingState => {
      return {
        ...state,
        data,
      }
    },
  )

  readonly setCustomerDeleted = this.updater((state): CustomersListingState => {
    return {
      ...state,
      notification: CustomerNotification.from({ code: 'CUSTOMER_DELETED' }),
      toReload: true,
    }
  })

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): CustomersListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: CustomerSearchField[]): CustomersListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    },
  )
}
