import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { GoodsReceiveStatus } from '../goods-receives/goods-receive.model'
import { OrderStatus } from '../orders/order.model'

export interface Automation extends Entity {
  name: string
  event: AutomationEvent
  action: AutomationAction
  changeStatus?: AutomationChangeStatus
  emailSettings?: AutomationEmail
  isActive: boolean
}

export enum AutomationEvent {
  change_status = 'CHANGE_STATUS',
}

export enum AutomationAction {
  submit_email = 'SUBMIT_EMAIL',
}

export interface AutomationChangeStatus {
  collection: AutomationCollection
  status: OrderStatus | GoodsReceiveStatus
  channelId?: string
}

export enum AutomationCollection {
  orders = 'ORDERS',
  goodsReceives = 'GOODS_RECEIVES',
}

export interface AutomationEmail {
  smtp: {
    host: string
    port: string
    username: string
    password: string
    secure?: boolean
    requireTLS?: boolean
  }
  from: string
  to?: string
  cc?: string
  bcc?: string
  replyTo?: string
  subject: string
  text?: string
  html?: string
  json?: object
}

export enum OrderPlaceHolder {
  number = 'ORDER_NUMBER',
  date = 'ORDER_DATE',
  ref = 'ORDER_REF',
  refDate = 'ORDER_REF_DATE',
  billingAddress = 'ORDER_BILLING_ADDRESS',
  billingAddressName = 'ORDER_BILLING_ADDRESS_NAME',
  billingAddressEmail = 'ORDER_BILLING_ADDRESS_EMAIL',
  billingAddressPhone = 'ORDER_BILLING_ADDRESS_PHONE',
  shippingAddress = 'ORDER_SHIPPING_ADDRESS',
  shippingAddressName = 'ORDER_SHIPPING_ADDRESS_NAME',
  shippingAddressEmail = 'ORDER_SHIPPING_ADDRESS_EMAIL',
  shippingAddressPhone = 'ORDER_SHIPPING_ADDRESS_PHONE',
}

export enum PickupPointPlaceHolder {
  name = 'PICKUP_POINT_NAME',
  address = 'PICKUP_POINT_ADDRESS',
  openings = 'PICKUP_POINT_OPENINGS',
  notes = 'PICKUP_POINT_NOTES',
}

export enum GoodsReceivePlaceHolder {
  number = 'GOODS_RECEIVE_NUMBER',
  date = 'GOODS_RECEIVE_DATE',
  ref = 'GOODS_RECEIVE_REF',
  refDate = 'GOODS_RECEIVE_REF_DATE',
}

export type AutomationSearchField =
  | '_id'
  | 'name'
  | 'event'
  | 'action'
  | 'isActive'
export type AutomationSortField = 'name'

export interface AutomationSearchParams
  extends QueryStringParams<AutomationSortField> {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  event?: AutomationEvent
  action?: AutomationAction
  isActive?: boolean
}

export type AutomationsTab = 'general' | 'event' | 'action'
