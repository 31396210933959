import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'

import * as AuthActions from '../auth/auth.actions'
import * as CacheActions from './cache.actions'

@Injectable({
  providedIn: 'root',
})
export class CacheEffects {
  // Lifecycle effects

  destroyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOutSuccess),
      map(() => CacheActions.destroyCache()),
    )
  })

  constructor(private actions$: Actions) {}
}
