import { Component, Input, Output, EventEmitter } from '@angular/core'

// Modal reference
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'op-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss'],
})
export class ModalAlertComponent {
  @Input() title?: string
  @Input() titleParams?: Array<string | number>
  @Input() text?: string
  @Input() confirmLabel?: string
  @Input() cancelLabel?: string
  @Input() element: any
  @Input() showCloseButton?: boolean
  @Input() public btnClass = ''
  @Output() readonly action = new EventEmitter()

  constructor(public modal: BsModalRef) {}

  confirm() {
    this.modal.hide()
    this.action.emit({ action: 'confirm', element: this.element })
  }
}
