import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { paginateArray } from '@evologi/shared/util-toolkit'

import { Manufacturer, ManufacturerSearchParams } from './manufacturer.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'manufacturers'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ManufacturersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new manufacturer
   * @param manufacturer - The manufacturer to create
   * @returns The observable<Manufacturer> to create the manufacturer
   */
  create$(manufacturer: Manufacturer): Observable<Manufacturer> {
    return this._create$<Manufacturer>(manufacturer)
  }

  /**
   * Read a manufacturer by ID
   * @param manufacturerId - The manufacturer ID
   * @returns The observable<Manufacturer> for read the manufacturer
   */
  read$(manufacturerId: string): Observable<Manufacturer> {
    return this._read$<Manufacturer>(manufacturerId)
  }

  /**
   * Update a manufacturer by ID
   * @param manufacturerId - The manufacturer ID
   * @param manufacturer - The manufacturer body to update
   * @returns The observable<Manufacturer> for update the manufacturer
   */
  update$(
    manufacturerId: string,
    manufacturer: Manufacturer,
  ): Observable<Manufacturer> {
    return this._update$<Manufacturer>(manufacturerId, manufacturer)
  }

  /**
   * Create or update a manufacturer by ID
   * @param manufacturerId - The manufacturer ID
   * @param manufacturer - The manufacturer body to update
   * @returns The observable<Manufacturer> for update the manufacturer
   */
  upsert$(manufacturer: Manufacturer): Observable<Manufacturer> {
    return this._upsert$<Manufacturer>(manufacturer, manufacturer._id)
  }

  /**
   * Delete a manufacturer by ID
   * @param manufacturerId - The manufacturer ID
   * @returns The observable<Manufacturer> for delete the manufacturer
   */
  delete$(manufacturerId: string): Observable<Manufacturer> {
    return this._delete$<Manufacturer>(manufacturerId)
  }

  /**
   * Search manufacturers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Manufacturer>> for search manufacturers
   */
  search$(
    params?: ManufacturerSearchParams,
    returnAll = false,
  ): Observable<Page<Manufacturer>> {
    return this._search$<Manufacturer>(params, returnAll)
  }

  /**
   * List manufacturers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Manufacturer[]> for list manufacturers
   */
  list$(
    params?: ManufacturerSearchParams,
    returnAll = false,
  ): Observable<Manufacturer[]> {
    return this._list$<Manufacturer>(params, returnAll)
  }

  /**
   * Count products of each manufacturer
   * @param manufacturerIds - The manufacturer IDs to count
   * @returns The observable<{ _id: string, count: number }[]> for count product manufacturers
   */
  countProducts$(
    manufacturerIds: string[],
  ): Observable<{ _id: string; count: number }[]> {
    const observables$: Observable<{ _id: string; count: number }[]>[] = []

    for (const page of paginateArray(manufacturerIds, this.limit)) {
      observables$.push(
        this.http.post<{ _id: string; count: number }[]>(
          `${this.apiUrl}/products/count`,
          {
            manufacturerIds: manufacturerIds.slice(page.start, page.end),
          },
        ),
      )
    }

    return combineLatest(observables$).pipe(
      map((response) => response.reduce((acc, b) => acc.concat(b), [])),
    )
  }

  /**
   * Generate manufacturers store
   * @param manufacturerIds - the manufacturer IDs to load
   * @param manufacturers - the manufacturers already loaded
   * @returns the Observable<Manufacturer[]> as store
   */
  store$(
    manufacturerIds: string[],
    manufacturers: Manufacturer[],
  ): Observable<Manufacturer[]> {
    manufacturerIds = uniq(manufacturerIds)
    manufacturerIds = difference(
      manufacturerIds,
      manufacturers.map((u) => u._id),
    )

    if (manufacturerIds.length === 0) {
      return of(manufacturers)
    }

    return this.list$({ _id: manufacturerIds }).pipe(
      map((supls) => [...manufacturers, ...supls]),
    )
  }
}
