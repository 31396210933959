import { HttpErrorResponse } from '@angular/common/http'
import {
  PackingList,
  PackingListField,
  PackingListSearchParams,
  PackingListsListingData,
  PackingListsListingPage,
} from '../packing-list.model'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { PackingListsRepository } from '../packing-lists.repository'
import { Observable, filter, map, switchMap, tap, withLatestFrom } from 'rxjs'
import { Page } from '../../../models/util.model'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { User } from '../../users'
import { PickingList } from '../../picking-lists'
import { Warehouse } from '../../warehouses'

interface PackingListsListingState extends PackingListsListingPage {
  fields?: PackingListField[]
  filters?: PackingListSearchParams
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
  canExport: boolean
}

const PACKING_LISTS_INITIAL_STATE: PackingListsListingState = {
  isLoading: false,
  isInitialized: false,
  toReload: false,
  canExport: false,
}

@Injectable()
export class PackingListsListingUseCase extends ComponentStore<PackingListsListingState> {
  constructor(private packingListsRepository: PackingListsRepository) {
    super(PACKING_LISTS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<PackingListsListingState> = this.select(
    (state) => state,
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectFilters$: Observable<PackingListSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {})),
  )

  readonly selectCanExport$: Observable<boolean> = this.select(
    (state) => state.canExport,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectIsInitialized?: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectPackingLists$: Observable<PackingList[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectFields$: Observable<PackingListField[] | undefined> =
    this.select((state) => state.fields)

  readonly selectExtData$: Observable<PackingListsListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectPickingLists$: Observable<PickingList[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.pickingLists))

  readonly selectWarehouses$: Observable<Warehouse[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.warehouses))

  readonly selectUsers$: Observable<User[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.users))

  readonly selectPage$: Observable<Page<PackingList>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectPackingLists$,
          this.selectTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true },
        ),
      ),
    )

  /**
   * EFFECTS
   */

  readonly search$ = this.effect(
    (searchParams$: Observable<PackingListSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        switchMap(([_, state]) =>
          this.packingListsRepository
            .searchPackingLists$(
              this.parseStateFilterParams(state),
              state.fields || [],
              state.extData,
            )
            .pipe(
              tapResponse(
                (pageData) => {
                  this.setPage(pageData)
                },
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<PackingListSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        map(([params, state]) => ({
          params: { ...params, limit: state.totalCount },
          fields: state.fields,
          extData: state.extData,
        })),
        switchMap(({ params, fields, extData }) =>
          this.packingListsRepository
            .searchPackingLists$(params, fields ?? [], extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): PackingListsListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: PackingListsListingPage): PackingListsListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setCanExport = this.updater(
    (state, canExport: boolean): PackingListsListingState => ({
      ...state,
      canExport: canExport,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): PackingListsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isLoading: false,
        toReload: false,
      }
    },
  )

  readonly setReload = this.updater(
    (state, toReload: boolean): PackingListsListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: PackingListField[]): PackingListsListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setFilters = this.updater(
    (state, filters?: PackingListSearchParams): PackingListsListingState => {
      return {
        ...state,
        filters,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly resetState = this.updater(
    (_): PackingListsListingState => PACKING_LISTS_INITIAL_STATE,
  )

  readonly resetError = this.updater((state): PackingListsListingState => {
    return {
      ...state,
      error: undefined,
    }
  })

  readonly resetFilters = this.updater((state): PackingListsListingState => {
    return {
      ...state,
      filters: undefined,
      error: undefined,
      toReload: true,
    }
  })

  /**
   * Utilities
   */

  private parseStateFilterParams(
    state: PackingListsListingState,
  ): PackingListSearchParams {
    return state.filters || {}
  }
}
