import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Role, RoleSearchParams, RoleUpdateBody } from './role.model'
import { Page } from '../../models/util.model'
import { Permissions } from '../policies/policy.model'
import { difference, uniq } from 'lodash'

const MODEL = 'roles'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class RolesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a role by ID
   * @param roleId - The role ID
   * @returns The observable<Role> for read the role
   */
  read$(roleId: string): Observable<Role> {
    return this._read$<Role>(roleId)
  }

  /**
   * Create or update a role by ID
   * @param role - The role body to update
   * @param roleId - The role ID
   * @returns The observable<Role> for update the role
   */
  upsert$(role: RoleUpdateBody, roleId?: string): Observable<Role> {
    return this._upsert$<Role, RoleUpdateBody>(role, roleId)
  }

  /**
   * Delete a role by ID
   * @param roleId - The role ID
   * @returns The observable<Role> for delete the role
   */
  delete$(roleId: string, force?: boolean): Observable<Role> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        force,
      },
    }
    return this._delete$<Role>(roleId, options)
  }

  /**
   * Search roles by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Role>> for search roles
   */
  search$(
    params?: RoleSearchParams,
    returnAll = false,
  ): Observable<Page<Role>> {
    return this._search$<Role>(params, returnAll)
  }

  /**
   * List roles by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Role[]> for list policies
   */
  list$(params?: RoleSearchParams, returnAll = false): Observable<Role[]> {
    return this._list$<Role>(params, returnAll)
  }

  /**
   * Assign a role to users
   * @param roleId - The role id
   * @param users - The user ids
   * @returns The observable<void> for assign the role
   */
  assign$(roleId: string, users: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${roleId}/assign`, { users })
  }

  /**
   * Remove a role from users
   * @param roleId - The role id
   * @param users - The user ids
   * @returns The observable<void> for remove the role
   */
  remove$(roleId: string, users: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${roleId}/remove`, { users })
  }

  /**
   * Get all permissions of a role by its ID
   * @param roleId - the role id
   * @returns The observable<Permissions> to get role permissions
   */
  getPermissions$(roleId: string): Observable<Permissions> {
    return this.http.get<Permissions>(`${this.apiUrl}/${roleId}/permissions`)
  }

  /**
   * Set permissions to a role by its ID
   * @param roleId - the role id
   * @returns The observable<Permissions> to set role permissions
   */
  setPermissions$(
    roleId: string,
    permissions: Permissions,
  ): Observable<Permissions> {
    return this.http.post<Permissions>(
      `${this.apiUrl}/${roleId}/permissions`,
      permissions,
    )
  }

  /**
   * Generate roles store
   * @param roleIds - the role IDs to load
   * @param roles - the roles already loaded
   * @returns the Observable<Role[]> as store
   */
  store$(roleIds: string[], roles: Role[] = []): Observable<Role[]> {
    roleIds = uniq(roleIds)
    roleIds = difference(
      roleIds,
      roles.map((r) => r._id),
    )

    if (roleIds.length === 0) {
      return of(roles)
    }

    return this.list$({ _id: roleIds }).pipe(map((rls) => [...roles, ...rls]))
  }
}
