import { Pipe, PipeTransform } from '@angular/core'

import { countOrderRowsSimpleProducts } from '../libs/order-rows.lib'
import {
  getOrderPackagesVolume,
  getOrderPackagesWeight,
} from '../libs/order-packages.lib'
import { getOrderUserIdByStatus } from '../libs/order.lib'
import { Order, OrderStatus } from '../order.model'

@Pipe({
  name: 'order',
})
export class OrderPipe implements PipeTransform {
  transform(
    order: Order,
    kind:
      | 'products-count'
      | 'packages-total-volume'
      | 'packages-total-weight'
      | 'packed-by'
      | 'processed-by'
      | 'address-valid'
      | 'order-products-tags'
  ): number | string {
    switch (kind) {
      case 'products-count':
        return countOrderRowsSimpleProducts(order.rows)
      case 'packages-total-volume':
        return getOrderPackagesVolume(order.packages || [])
      case 'packages-total-weight':
        return getOrderPackagesWeight(order.packages || [])
      case 'packed-by':
        return getOrderUserIdByStatus(order, OrderStatus.packed) || ''
      case 'processed-by':
        return getOrderUserIdByStatus(order, OrderStatus.processed) || ''
      default:
        return ''
    }
  }
}
