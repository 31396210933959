import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Channel, ChannelSearchParams } from './channel.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'channels'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ChannelsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new channel
   * @param channel - The channel to create
   * @returns The observable<Channel> to create the channel
   */
  create$(channel: Channel): Observable<Channel> {
    return this._create$<Channel>(channel)
  }

  /**
   * Read a channel by ID
   * @param channelId - The channel ID
   * @returns The observable<Channel> for read the channel
   */
  read$(channelId: string): Observable<Channel> {
    return this._read$<Channel>(channelId)
  }

  /**
   * Update a channel by ID
   * @param channelId - The channel ID
   * @param channel - The channel body to update
   * @returns The observable<Channel> for update the channel
   */
  update$(channelId: string, channel: Channel): Observable<Channel> {
    return this._update$<Channel>(channelId, channel)
  }

  /**
   * Create or update a channel by ID
   * @param channelId - The channel ID
   * @param channel - The channel body to update
   * @returns The observable<Channel> for update the channel
   */
  upsert$(channel: Channel): Observable<Channel> {
    return this._upsert$<Channel>(channel, channel._id)
  }

  /**
   * Delete a channel by ID
   * @param channelId - The channel ID
   * @returns The observable<Channel> for delete the channel
   */
  delete$(channelId: string): Observable<Channel> {
    return this._delete$<Channel>(channelId)
  }

  /**
   * Search channels by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Channel>> for search channels
   */
  search$(
    params?: ChannelSearchParams,
    returnAll = false,
  ): Observable<Page<Channel>> {
    return this._search$<Channel>(params, returnAll)
  }

  /**
   * List channels by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Channel[]> for list channels
   */
  list$(
    params?: ChannelSearchParams,
    returnAll = false,
  ): Observable<Channel[]> {
    return this._list$<Channel>(params, returnAll)
  }

  /**
   * Connect channel to external source
   * @param channelId - The channel ID
   * @param warehouseId - The warehouse ID
   * @returns The observable<void> for connect channel
   */
  connect$(channelId: string): Observable<Channel> {
    return this.http.post<Channel>(`${this.apiUrl}/${channelId}/connect`, {})
  }

  /**
   * Disconnect channel from external source
   * @param channelId - the channel ID
   * @returns the observable<void> for disconnect a channel
   */
  disconnect$(channelId: string): Observable<Channel> {
    return this.http.post<Channel>(`${this.apiUrl}/${channelId}/disconnect`, {})
  }

  /**
   * Recover channel orders
   * @param channelId - The channel ID
   * @param since - The recover start date
   * @returns The observable<void> for recover channel orders
   */
  recoverOrders$(channelId: string, since: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${channelId}/recover-orders`, {
      since,
    })
  }

  /**
   * Generate channels store
   * @param channelIds - the channel IDs to load
   * @param channels - the channels already loaded
   * @returns the Observable<Channel[]> as store
   */
  store$(channelIds: string[], channels: Channel[]): Observable<Channel[]> {
    channelIds = uniq(channelIds)
    channelIds = difference(
      channelIds,
      channels.map((u) => u._id),
    )

    if (channelIds.length === 0) {
      return of(channels)
    }

    return this.list$({ _id: channelIds }).pipe(
      map((chnls) => [...channels, ...chnls]),
    )
  }
}
