import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import {
  AttributeGroup,
  AttributeGroupSearchParams,
} from './attribute-group.model'
import { Page } from '../../models/util.model'
import { SDK_SETTINGS } from '../../consts/config.const'

const MODEL = 'attributes-groups'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class AttributeGroupsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new group
   * @param group - The group to create
   * @returns The observable<AttributeGroup> to create the group
   */
  create$(group: AttributeGroup): Observable<AttributeGroup> {
    return this._create$<AttributeGroup>(group)
  }

  /**
   * Read a group by ID
   * @param groupId - The group ID
   * @returns The observable<AttributeGroup> for read the group
   */
  read$(groupId: string): Observable<AttributeGroup> {
    return this._read$<AttributeGroup>(groupId)
  }

  /**
   * Update a group by ID
   * @param groupId - The group ID
   * @param group - The group body to update
   * @returns The observable<AttributeGroup> for update the group
   */
  update$(groupId: string, group: AttributeGroup): Observable<AttributeGroup> {
    return this._update$<AttributeGroup>(groupId, group)
  }

  /**
   * Create or update a group by ID
   * @param groupId - The group ID
   * @param group - The group body to update
   * @returns The observable<AttributeGroup> for update the group
   */
  upsert$(group: AttributeGroup): Observable<AttributeGroup> {
    return this._upsert$<AttributeGroup>(group, group._id)
  }

  /**
   * Delete a group by ID
   * @param groupId - The group ID
   * @returns The observable<AttributeGroup> for delete the group
   */
  delete$(groupId: string): Observable<AttributeGroup> {
    return this._delete$<AttributeGroup>(groupId)
  }

  /**
   * Search groups by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<AttributeGroup>> for search groups
   */
  search$(
    params?: AttributeGroupSearchParams,
    returnAll = false,
  ): Observable<Page<AttributeGroup>> {
    return this._search$<AttributeGroup>(params, returnAll)
  }

  /**
   * List groups by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<AttributeGroup[]> for list groups
   */
  list$(
    params?: AttributeGroupSearchParams,
    returnAll = false,
  ): Observable<AttributeGroup[]> {
    return this._list$<AttributeGroup>(params, returnAll)
  }

  /**
   * Find a group by params
   * @param params - The search params
   * @returns the observable<AttributeGroup> for find a catalog
   */
  readOne$(params?: AttributeGroupSearchParams): Observable<AttributeGroup> {
    return this._readOne$<AttributeGroup>(params)
  }
}
