import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Task, TaskSearchParams } from './task.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'tasks'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class TasksService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Search tasks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Task>> for search tasks
   */
  search$(
    params?: TaskSearchParams,
    returnAll = false,
  ): Observable<Page<Task>> {
    return this._search$<Task>(params, returnAll)
  }

  /**
   * List tasks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Task[]> for list ttasksags
   */
  list$(params?: TaskSearchParams, returnAll = false): Observable<Task[]> {
    return this._list$<Task>(params, returnAll)
  }

  /**
   * Download the task attachment
   * @param taskId - The task ID
   * @param attachmentId - The attachment ID
   * @returns the observable<Blob> for download the task attachment
   */
  downloadAttachment$(taskId: string, attachmentId: string): Observable<Blob> {
    return this.http.get(
      `${this.apiUrl}/${taskId}/attachments/${attachmentId}/blob`,
      {
        responseType: 'blob',
      },
    )
  }
}
