import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { User } from '../users/user.model'
import { Tenant } from '../tenants/tenant.model'
import { Permissions } from '../policies'

const MODEL = 'auth'

// Common interfaces
interface ITokenResponse {
  token: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // API url and custom token
  private apiUrl: string
  public token?: string

  constructor(
    @Inject(SDK_CONFIGURATION) private config: SDKConfiguration,
    private http: HttpClient,
  ) {
    this.apiUrl = `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${MODEL}`
  }

  /**
   * Authenticate user by username and password and returns REFRESH_TOKEN
   * @param username - The user username
   * @param password - The user password
   * @param force - Force flag for delete the session already present
   * @param code - 2FA code
   * @returns The observable<ITokenResponse> to authenticate the user
   */
  authenticate$(
    username: string,
    password: string,
    options?: {
      force?: boolean
      code?: string
      type?: 'APP' | 'WEB'
    },
  ): Observable<ITokenResponse> {
    const { force, code, type } = options || {}
    return this.http.post<ITokenResponse>(`${this.apiUrl}/authenticate`, {
      username,
      password,
      force,
      code,
      type: type || this.config.client,
    })
  }

  /**
   * Refresh the user token and gains access to the indicated tenant and returns ACCESS_TOKEN
   * @param tenantId - The tenant ID
   * @returns The observable<ITokenResponse> to perform requests
   */
  refresh$(tenantId: string): Observable<ITokenResponse> {
    return this.http.post<ITokenResponse>(`${this.apiUrl}/refresh`, {
      tenantId,
    })
  }

  /**
   * Get current user authenticated
   * @returns The observable<User> to read the current user
   */
  whoAmI$(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user`)
  }

  /**
   * Get current tenant authenticated
   * @returns The observable<Tenant> to read the current tenant
   */
  tenant$(): Observable<Tenant> {
    return this.http.get<Tenant>(`${this.apiUrl}/tenant`)
  }

  /**
   * Get current user permissions
   * @returns The observable<Permissions> to read user permissions
   */
  permissions$(): Observable<Permissions> {
    return this.http.get<Permissions>(`${this.apiUrl}/permissions`)
  }

  /**
   * Logout the current user
   * @returns The observable<void> to logout
   */
  logout$(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/logout`, {})
  }

  /**
   * Change password of the current user
   * @param oldPassword - The current password
   * @param newPassword - The new password
   * @returns The observable<void> to update the password
   */
  changePassword$(oldPassword: string, newPassword: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/change-password`, {
      oldPassword,
      newPassword,
    })
  }

  /**
   * Reset password of the current user
   * @param email - The user email
   * @returns The observable<void> to reset the password
   */
  forgotPassword$(username: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/forgot-password`, { username })
  }

  /**
   * Enable 2FA of the current user
   * @returns The observable<void> to enable the 2FA
   */
  enable2fa$(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/2fa-enable`, {})
  }

  /**
   * Disable 2FA of the current user
   * @returns The observable<void> to disable the 2FA
   */
  disable2fa$(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/2fa-disable`, {})
  }

  /**
   * Update the profile of current user
   * @param profile - The user profile to update
   * @returns The observable<User> to update the profile
   */
  updateProfile$(profile: Partial<User>): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/user`, profile)
  }

  /**
   * Invite a user to the current tenant
   * @param username - The username of the user to invite
   * @returns The observable<void> to invite the user
   */
  invite$(username: string): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/invite`, {
      username,
    })
  }
}
