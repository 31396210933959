import { Inject, Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http'
import { switchMap, take } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('refresh')) {
      return next.handle(request)
    }

    return this.authManager.getAccessToken$().pipe(
      take(1),
      switchMap((accessToken) => {
        if (accessToken) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        }

        return next.handle(request)
      }),
    )
  }
}
