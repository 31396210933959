import { Pipe, PipeTransform } from '@angular/core'
import {
  Address,
  BillingAddress,
  ShippingAddress,
} from '../../../models/address.model'
import { Carrier } from '../carrier.model'
import { isCarrierAddressValid } from '../carrier.lib'

@Pipe({
  name: 'carrierAddress',
})
export class CarrierAddressPipe implements PipeTransform {
  transform(
    carrier?: Carrier,
    address?: ShippingAddress | BillingAddress | Address
  ): boolean {
    if (!carrier || !address) {
      return true
    }

    return isCarrierAddressValid(carrier, address)
  }
}
