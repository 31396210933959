import { Pipe, PipeTransform } from '@angular/core'
import { Warehouse } from '../warehouse.model'

@Pipe({
  name: 'warehouseAddress',
  pure: false,
})
export class WarehouseAddressPipe implements PipeTransform {
  transform(
    warehouse: Warehouse,
    configuration?: {
      includeName?: boolean
      includeEmail?: boolean
      flat?: boolean
    }
  ): string {
    const address = warehouse.address
    const addressStack: string[] = []

    if (configuration?.includeName && address.name) {
      addressStack.push(address.name)
    }

    if (address.address1) {
      addressStack.push(address.address1)
    }

    if (address.address2) {
      addressStack.push(address.address2)
    }

    // City address line
    const cityRow = []
    let addressRow = ''

    if (address.postalCode) {
      cityRow.push(address.postalCode)
    }
    if (address.city) {
      cityRow.push(address.city)
    }
    if (cityRow.length > 0) {
      addressRow = cityRow.join(' - ')
    }
    if (address.province && address.province !== '') {
      addressRow += ' (' + address.province + ')'
    }
    if (addressRow !== '') {
      addressStack.push(addressRow)
    }

    if (address.countryCode) {
      addressStack.push(address.countryCode)
    }

    if (warehouse.phone) {
      addressStack.push(
        `${configuration?.flat ? 'Tel.' : '<strong>&#9743;</strong>'} ${
          warehouse.phone
        }`
      )
    }

    if (configuration?.includeEmail && warehouse.email) {
      addressStack.push(
        `${configuration?.flat ? 'Email' : '<strong>&#64;</strong>'} ${
          warehouse.email
        }`
      )
    }

    return configuration?.flat
      ? addressStack.join(' - ')
      : addressStack.join('<br />')
  }
}
