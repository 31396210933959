import { cloneDeep, defaultsDeep } from 'lodash'
import { Classification } from './classification.model'

// Initial states

const CLASSIFICATION_INITIAL_STATE: Partial<Classification> = {
  slug: undefined,
  name: {},
}

// Reducers

export function initClassification(
  classification: Partial<Classification> = {}
): Classification {
  return defaultsDeep(cloneDeep(classification), CLASSIFICATION_INITIAL_STATE)
}

// Parser

export function parseClassificationTree(
  categories: Classification[],
  categoriesSelected: string[]
) {
  return categories.map((cat) => ({
    ...cat,
    id: cat._id,
    parent: cat.parentId ? cat.parentId : '#',
    text: cat.name.default,
    type: !cat.parentId ? 'root' : 'leaf',
    state: {
      opened: !cat.parentId,
      draggable: !!cat.parentId,
      selected: categoriesSelected.includes(cat._id),
    },
  }))
}
