import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'

// Modal reference
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'op-modal-closure',
  templateUrl: './modal-closure.component.html',
  styleUrls: ['./modal-closure.component.scss'],
})
export class ModalClosureComponent implements OnInit, OnDestroy {
  @Output() readonly action = new EventEmitter()

  // Models
  public title?: string
  public element: any

  constructor(public modal: BsModalRef) {}

  ngOnInit() {
    this.setZIndex()
  }

  ngOnDestroy() {
    this.setZIndex()
  }

  setZIndex() {
    let zIndex = 1040
    const elements = Array.from(document.getElementsByClassName('modal'))

    elements.reverse().forEach((element: any) => {
      element['style']['zIndex'] = zIndex
      zIndex -= 10
    })
  }

  closeModal(_parent = false, _save = false) {
    this.modal.hide()
    this.action.emit({ parent: _parent, save: _save })
  }
}
