import { Pipe, PipeTransform } from '@angular/core'
import { get, isArray, isEqual } from 'lodash'

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform<T = any>(
    items: T[] | undefined,
    operator: 'EXISTS' | 'INCLUDES' | 'NOT_INCLUDES' | 'EQUALS' | 'NOT_EQUALS',
    field?: any,
    values?: any | any[],
  ): T[] {
    if (!items || !Array.isArray(items)) {
      return []
    }

    return items.filter((item) => {
      const itemValue = field ? get(item, field) : item

      // Values not defined
      if (values === undefined && operator === 'EXISTS') {
        return itemValue !== undefined
      }

      // Array values to includes
      if (isArray(values)) {
        return operator === 'INCLUDES'
          ? values.includes(itemValue)
          : !values.includes(itemValue)
      }

      if (isArray(itemValue)) {
        return operator === 'INCLUDES'
          ? itemValue.includes(values)
          : !itemValue.includes(values)
      }

      return operator === 'EQUALS'
        ? isEqual(itemValue, values)
        : !isEqual(itemValue, values)
    })
  }
}
