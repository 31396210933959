import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import { ProductFamiliesTab } from './product-family.model'

export const PRODUCT_FAMILY_SCOPE: PermissionsScope = 'products'

export const PRODUCT_FAMILIES_TABS: SelectionData<ProductFamiliesTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'configuration',
    label: 'Configurazione',
  },
]
