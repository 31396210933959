import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import {
  Channel,
  ChannelData,
  ChannelExtData,
  ChannelExtPayment,
  ChannelKind,
  ChannelNotificationOptions,
} from './channel.model'
import { ChannelNotification } from './libs/channel-notification.lib'
import { EMPTY, Observable, combineLatest, map, of, switchMap, tap } from 'rxjs'
import { ChannelsService } from './channels.service'
import { initChannel, setChannelTenantSettings } from './libs/channel.lib'
import { Tenant } from '../tenants'
import { StoredenService } from '../storeden'
import { CHANNEL_KINDS } from './channel.const'

@Injectable({
  providedIn: 'root',
})
export class ChannelsRepository {
  constructor(
    private channelsService: ChannelsService,
    private storedenService: StoredenService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Show channels alert
   * @param options - the channel notification or notification options
   * @returns the observable for show the alert about channel
   */
  alert$(
    opts: ChannelNotification | ChannelNotificationOptions,
  ): Observable<boolean> {
    const notification = ChannelNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a channel event
   * @param notification - the channel notification
   */
  notify(opts: ChannelNotification | ChannelNotificationOptions): void {
    const notification = ChannelNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }

  /**
   * Load channel with external data
   * @param channelId - the channel ID
   * @param tenant - the current tenant
   * @returns the observable for load all channel data
   */
  loadChannelData$(channelId: string, tenant: Tenant): Observable<ChannelData> {
    return this.channelsService.read$(channelId).pipe(
      map((channel) => initChannel(channel)),
      map((channel) => setChannelTenantSettings(channel, tenant)),
      switchMap((channel) =>
        this.loadChannelExtData$(channel, tenant).pipe(
          map((channelData) => ({
            channel,
            ...channelData,
          })),
        ),
      ),
    )
  }

  /**
   * Load channel external data
   * @param channel - the channel
   * @returns the observable for load the channel external data
   */
  loadChannelExtData$(
    channel: Channel,
    tenant: Tenant,
  ): Observable<ChannelExtData> {
    return combineLatest({
      storedenPayments: this.loadChannelPayments$(channel),
      shippyProIntegration: of(tenant).pipe(
        map(
          (tenant) =>
            !!tenant?.shippyproSettings && !!tenant.shippyproSettings.apiKey,
        ),
      ),
    })
  }

  /**
   * Load channel authentication data
   * @param channel - the channel
   * @returns the observable for load the channel authentication data
   */
  loadChannelAuthData$(channel: Channel): Observable<any> {
    const { kind, customSettings } = channel

    if (
      kind !== ChannelKind.storeden ||
      !customSettings?.key ||
      !customSettings.exchange
    ) {
      return of(undefined)
    }

    // Storeden keys
    const { key, exchange } = customSettings

    return this.storedenService
      .getStore$(key, exchange)
      .pipe(
        switchMap((store) =>
          this.storedenService
            .getStoreLanguages$(key, exchange)
            .pipe(map((langInfo) => ({ ...store, locale: langInfo }))),
        ),
      )
  }

  /**
   * Connect a channel to external integration
   * @param channel - the channel to connect
   * @returns the observable for connect the channel
   */
  connectChannel$(channel: Channel): Observable<Channel> {
    return this.channelsService
      .upsert$(channel)
      .pipe(switchMap((channel) => this.channelsService.connect$(channel._id)))
  }

  /**
   * Disconnect a channel from external integration
   * @param channel - the channel to disconnect
   * @returns the observable for disconnect the channel
   */
  disconnectChannel$(channel: Channel): Observable<Channel> {
    return this.channelsService
      .upsert$(channel)
      .pipe(
        switchMap((channel) => this.channelsService.disconnect$(channel._id)),
      )
  }

  /**
   * Load channel payments
   * @param channel - the channel
   * @returns the observable for load external payments
   */
  loadChannelPayments$(
    channel: Channel,
  ): Observable<ChannelExtPayment[] | undefined> {
    if (
      channel.kind !== ChannelKind.storeden ||
      !channel.customSettings?.key ||
      !channel.customSettings.exchange
    ) {
      return of(undefined)
    }

    const kind = CHANNEL_KINDS.find((knd) => knd.value === channel.kind)
    const { key, exchange } = channel.customSettings

    return this.storedenService.getStorePayments$(key, exchange).pipe(
      map((customPayments: any[]) => [
        ...(kind?.payments ? kind.payments : []),
        ...customPayments.map((pay: any) => ({
          code: pay.id,
          name: pay.label,
        })),
      ]),
    )
  }
}
