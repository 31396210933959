import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Consts
import { TRANSLATIONS, DICTIONARY } from './consts/translation'

// Pipes
import { TranslationPipe } from './translation.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [TranslationPipe],
  exports: [TranslationPipe],
})
export class SharedUtilTranslationModule {
  static forRoot(): ModuleWithProviders<SharedUtilTranslationModule> {
    return {
      ngModule: SharedUtilTranslationModule,
      providers: [{ provide: TRANSLATIONS, useValue: DICTIONARY }],
    }
  }
}
