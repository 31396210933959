import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Warehouse,
  WarehouseArea,
  WarehouseAreaCreationParams,
  WarehouseAreaSettings,
  WarehouseGraph,
  WarehouseInvitation,
  WarehouseSearchParams,
  WarehouseTotes,
} from './warehouse.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'warehouses'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class WarehousesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new warehouse
   * @param warehouse - The warehouse to create
   * @returns The observable<Warehouse> to create the warehouse
   */
  create$(warehouse: Warehouse): Observable<Warehouse> {
    return this._create$<Warehouse>(warehouse)
  }

  /**
   * Read a warehouse by ID
   * @param warehouseId - The warehouse ID
   * @returns The observable<Warehouse> for read the warehouse
   */
  read$(warehouseId: string): Observable<Warehouse> {
    return this._read$<Warehouse>(warehouseId)
  }

  /**
   * Update a warehouse by ID
   * @param warehouseId - The warehouse ID
   * @param warehouse - The warehouse body to update
   * @returns The observable<Warehouse> for update the warehouse
   */
  update$(warehouseId: string, warehouse: Warehouse): Observable<Warehouse> {
    return this._update$<Warehouse>(warehouseId, warehouse)
  }

  /**
   * Create or update a warehouse by ID
   * @param warehouseId - The warehouse ID
   * @param warehouse - The warehouse body to update
   * @returns The observable<Warehouse> for update the warehouse
   */
  upsert$(warehouse: Warehouse): Observable<Warehouse> {
    return this._upsert$<Warehouse>(warehouse, warehouse._id)
  }

  /**
   * Delete a warehouse by ID
   * @param warehouseId - The warehouse ID
   * @returns The observable<Warehouse> for delete the warehouse
   */
  delete$(warehouseId: string): Observable<Warehouse> {
    return this._delete$<Warehouse>(warehouseId)
  }

  /**
   * Search warehouses by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Warehouse>> for search warehouses
   */
  search$(
    params?: WarehouseSearchParams,
    returnAll = false,
  ): Observable<Page<Warehouse>> {
    return this._search$<Warehouse>(params, returnAll)
  }

  /**
   * List warehouses by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Warehouse[]> for list warehouses
   */
  list$(
    params?: WarehouseSearchParams,
    returnAll = false,
  ): Observable<Warehouse[]> {
    return this._list$<Warehouse>(params, returnAll)
  }

  /**
   * Get warehouse graph
   * @param warehouseId - The warehouse ID
   * @returns the observable<WarehouseGraph> for get the warehouse graph
   */
  getGraph$(warehouseId: string): Observable<WarehouseGraph> {
    return this.http.get<WarehouseGraph>(`${this.apiUrl}/${warehouseId}/graph`)
  }

  /**
   * Get invitations
   * @param warehouseId - the warehouse ID
   * @returns the observable<WarehouseTenant[]> for get the warehouse invitations
   */
  getInvitiations$(warehouseId: string): Observable<WarehouseInvitation[]> {
    return this.http.get<WarehouseInvitation[]>(
      `${this.apiUrl}/${warehouseId}/invitations`,
    )
  }

  /**
   * Add totes to warehouse
   * @param warehouseId - The warehouse ID
   * @param totesParams - The totes creation params
   * @returns the observable<void> for totes creation
   */
  updateTotes$(
    warehouseId: string,
    totesParams: WarehouseTotes,
  ): Observable<WarehouseTotes> {
    return this.http.put<WarehouseTotes>(
      `${this.apiUrl}/${warehouseId}/totes`,
      totesParams,
    )
  }

  /**
   * Create warehouse area
   * @param warehouseId - The warehouse ID
   * @param areaParams - The area creation params
   * @returns the observable<Area> for area creation
   */
  createArea$(
    warehouseId: string,
    area: WarehouseAreaCreationParams,
  ): Observable<WarehouseArea> {
    return this.http.post<WarehouseArea>(
      `${this.apiUrl}/${warehouseId}/areas`,
      area,
    )
  }

  /**
   * Update warehouse area
   * @param warehouseId - The warehouse ID
   * @param areaId - The area ID
   * @param settings - The area settings
   * @returns the observable<Location>for update warehouse area settings
   */
  updateArea$(
    warehouseId: string,
    areaId: string,
    settings: WarehouseAreaSettings,
  ): Observable<WarehouseArea> {
    return this.http.put<WarehouseArea>(
      `${this.apiUrl}/${warehouseId}/areas/${areaId}`,
      settings,
    )
  }

  /**
   * Delete warehouse area
   * @param warehouseId - The warehouse ID
   * @param areaId - The area ID
   * @returns the observable<Location> for area deletion
   */
  deleteArea$(warehouseId: string, areaId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/${warehouseId}/areas/${areaId}`,
    )
  }

  /**
   * Invite tenant to warehouse
   * @param warehouseId - the warehouse ID
   * @param tenantId - the tenant ID
   * @returns the observable<void> for tenant invitation
   */
  inviteTenant$(warehouseId: string, tenantId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${warehouseId}/invite`, {
      tenantId,
    })
  }

  /**
   * Reject tenant from warehouse
   * @param warehouseId - the warehouse ID
   * @param tenantId - the tenant ID
   * @returns the observable<void> for tenant ejection
   */
  ejectTenant$(warehouseId: string, tenantId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${warehouseId}/eject`, {
      tenantId,
    })
  }

  /**
   * Generate warehouses store
   * @param warehouseIds - the warehouse IDs to load
   * @param warehouses - the warehouses already loaded
   * @returns the Observable<Warehouse[]> as store
   */
  store$(
    warehouseIds: string[],
    warehouses: Warehouse[],
  ): Observable<Warehouse[]> {
    warehouseIds = uniq(warehouseIds)
    warehouseIds = difference(
      warehouseIds,
      warehouses.map((w) => w._id),
    )

    if (warehouseIds.length === 0) {
      return of(warehouses)
    }

    return this.list$({ _id: warehouseIds }).pipe(
      map((wrhs) => [...warehouses, ...wrhs]),
    )
  }
}
