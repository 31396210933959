import { InjectionToken } from '@angular/core'

export interface SDKConfiguration {
  apiUrl: string
  client: 'APP' | 'WEB'
}

export const SDK_CONFIGURATION = new InjectionToken<SDKConfiguration>(
  'SDKConfiguration'
)

export interface Environment {
  production: boolean
  localhost: boolean
  apiUrl: string
  ngswEnabled: boolean
  privateKey: string
  sentryDsn: string
}

export const ENVIRONMENT = new InjectionToken<Environment>('Environment')
