<op-modal [title]="title">
  <!-- Info -->
  <modal-info>
    <div
      *ngIf="documentType"
      class="badge-double badge-double-lg border border-top-0 border-primary rounded-bottom"
    >
      {{ documentType }}
      <span *ngIf="documentRef" class="badge badge-primary border-0 rounded-0">
        {{ documentRef }}
      </span>
    </div>
  </modal-info>

  <!-- Content -->
  <modal-content>
    <div class="history-container">
      <div class="history">
        <div class="change" *ngFor="let change of history">
          <div
            class="status badge badge-{{
              change.status | lowercase
            }} text-uppercase"
          >
            <span>{{ status | find: 'code' : change.status : 'name' }}</span>
          </div>
          <div class="user">
            <h6 class="mb-0">
              <strong>{{
                users | find: '_id' : change.userId : 'name|surname' : ' '
              }}</strong>
            </h6>
            <small>{{ change.date | date: 'medium' }}</small>
          </div>
        </div>
      </div>
    </div>
  </modal-content>

  <!-- Actions -->
  <modal-actions-right>
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="modal.hide()"
    >
      Chiudi
    </button>
  </modal-actions-right>
</op-modal>
