import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  RoleNotificationCode,
  RoleNotificationData,
  RoleNotificationOptions,
} from '../role.model'

export class RoleNotification implements Notification {
  public code: RoleNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: RoleNotificationOptions) {
    const { type, dialog } = parseRoleNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: RoleNotificationOptions): RoleNotification {
    if (opts instanceof RoleNotification) {
      return opts
    } else {
      return new RoleNotification(opts)
    }
  }
}

/**
 * Parse user notification to a message
 * @param notification - the user notification
 * @returns the notification data
 */
export function parseRoleNotification(
  code: RoleNotificationCode,
  data?: RoleNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'ROLE_DELETION': {
      const roleName = data?.role?.label || ''
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete role',
        message:
          'You are about to delete the role <strong>%s</strong>. Proceed with deletion?',
        messageParams: [roleName],
        size: 'lg',
      }
      break
    }
    case 'ROLE_DELETION_CONFIRMATION': {
      const roleName = data?.role?.label || ''
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete role',
        message:
          'The role <strong>%s</strong> is currently associated to users. Proceed anyway with deletion?',
        messageParams: [roleName],
        size: 'lg',
      }
      break
    }
    case 'ROLE_DELETED':
      dialog = {
        message: 'Role deleted successfully',
        color: 'success',
      }
      break
  }

  return { dialog, type }
}
