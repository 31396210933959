import { pick } from 'lodash'
import { updateOrderAmounts } from './order.lib'
import { decOrderRows, addOrderRows, updateOrderRowQty } from './order-rows.lib'
import { mergeOrderHeaderInfo } from './order-header.lib'
import { Order } from '../order.model'

export function initOrderToSplit(order: Order): Order {
  return {
    ...order,
    rows: [...order.rows.map((row) => ({ ...row, splitQty: 0 }))],
  }
}

export function splitOrder(
  fromOrder: Order,
  toOrder: Order,
  rowId: string,
  qty: number
): { firstOrder: Order; landingOrder: Order } {
  // Take row to split
  const splittedRow = updateOrderRowQty(
    fromOrder.rows.find((rw) => rw._id === rowId)!,
    qty
  )

  // Split first order
  const firstOrder = updateOrderAmounts({
    ...fromOrder,
    rows: decOrderRows(fromOrder.rows, rowId, qty),
  })

  // Update landing order
  const landingOrder = updateOrderAmounts({
    ...toOrder,
    ...pick(firstOrder, [
      'warehouseId',
      'assignedWarehouseId',
      'carrierId',
      'shipmentOptions',
    ]),
    header: mergeOrderHeaderInfo(firstOrder.header, toOrder.header),
    rows: addOrderRows(toOrder.rows, [splittedRow]),
  })

  return {
    firstOrder,
    landingOrder,
  }
}

export function concatOrder(order: Order, secondOrder: Order): Order {
  return updateOrderAmounts({
    ...order,
    rows: addOrderRows(order.rows, secondOrder.rows),
  })
}
