<op-modal
  [title]="title"
  [btnClass]="btnClass"
  [showCloseButton]="showCloseButton"
  [cancelLabel]="cancelLabel"
>
  <!-- Content -->
  <modal-content>
    <p *ngIf="text" [innerHtml]="text | translate: titleParams"></p>
  </modal-content>

  <!-- Actions -->
  <modal-actions-right *ngIf="!showCloseButton">
    <button
      class="btn btn-danger {{ btnClass }}"
      type="button"
      (click)="confirm()"
    >
      {{ confirmLabel ?? 'Conferma' }}
    </button>
  </modal-actions-right>
</op-modal>
