import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ClientType, ClientVersion } from './version.model'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'

const MODEL = 'versions'
const VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  private _client?: ClientType
  private apiUrl: string

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    private http: HttpClient,
  ) {
    this._client = config.client === 'WEB' ? 'web' : 'app'
    this.apiUrl = `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`
  }

  /**
   * Get current client
   */
  get client(): ClientType | undefined {
    return this._client
  }

  /**
   * Get version of the current application saved
   * @returns the observable for get che current version
   */
  getCurrentVersion$(): Observable<ClientVersion> {
    return this.http.get<ClientVersion>(`${this.apiUrl}/${this._client}`)
  }

  /**
   * Get version of the client
   * @param client - the client type
   * @returns the obsevable for load the client version
   */
  geClientVersion$(client: ClientType): Observable<ClientVersion> {
    return this.http.get<ClientVersion>(`${this.apiUrl}/${client}`)
  }
}
