import { Pipe, PipeTransform } from '@angular/core'

import { Product } from '../product.model'
import {
  getProductDiscountActivePrice,
  getProductDiscountPrice,
  getProductListingPrice,
  getProductPurchasePrice,
  getProductPurchasingPrice,
  getProductSellingPrice,
} from '../libs/price.lib'
import {
  getProductLocationQty,
  getProductPackagesQtyLabel,
  getProductQty,
} from '../libs/quantities.lib'

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  transform(
    product: Product | undefined,
    kind:
      | 'price-purchase'
      | 'price-listing'
      | 'price-discount'
      | 'price-discount-active'
      | 'price-purchasing'
      | 'price-selling'
      | 'qty-on-hand'
      | 'qty-available'
      | 'qty-incoming'
      | 'location-qty-on-hand'
      | 'location-qty-reserved'
      | 'location-qty-picked'
      | 'location-qty-scrapped'
      | 'packing-qty-label',
    options?: {
      warehouseId?: string
      locationId?: string
      orderedQty?: number
    }
  ): number | string {
    if (!product) {
      return ''
    }

    switch (kind) {
      case 'price-purchase':
        return getProductPurchasePrice(product)
      case 'price-listing':
        return getProductListingPrice(product)
      case 'price-discount':
        return getProductDiscountPrice(product)
      case 'price-discount-active':
        return getProductDiscountActivePrice(product)
      case 'price-purchasing':
        return getProductPurchasingPrice(product)
      case 'price-selling':
        return getProductSellingPrice(product)
      case 'qty-on-hand':
        return getProductQty(product, 'onHandQty', options?.warehouseId)
      case 'qty-available':
        return getProductQty(product, 'availableQty', options?.warehouseId)
      case 'qty-incoming':
        return getProductQty(product, 'incomingQty', options?.warehouseId)
      case 'location-qty-on-hand':
        return getProductLocationQty(product, 'onHandQty', options!.locationId!)
      case 'location-qty-reserved':
        return getProductLocationQty(
          product,
          'reservedQty',
          options!.locationId!
        )
      case 'location-qty-picked':
        return getProductLocationQty(product, 'pickedQty', options!.locationId!)
      case 'location-qty-scrapped':
        return getProductLocationQty(
          product,
          'scrappedQty',
          options!.locationId!
        )
      case 'packing-qty-label':
        return (
          getProductPackagesQtyLabel(product, options?.orderedQty || 1) || ''
        )
      default:
        return ''
    }
  }
}
