import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  ProductFamily,
  ProductFamilySearchParams,
} from './product-family.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'families'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ProductFamiliesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new family
   * @param family - The family to create
   * @returns The observable<ProductFamily> to create the family
   */
  create$(family: ProductFamily): Observable<ProductFamily> {
    return this._create$<ProductFamily>(family)
  }

  /**
   * Read a family by ID
   * @param familyId - The family ID
   * @returns The observable<ProductFamily> for read the family
   */
  read$(familyId: string): Observable<ProductFamily> {
    return this._read$<ProductFamily>(familyId)
  }

  /**
   * Update a family by ID
   * @param familyId - The family ID
   * @param family - The family body to update
   * @returns The observable<ProductFamily> for update the family
   */
  update$(familyId: string, family: ProductFamily): Observable<ProductFamily> {
    return this._update$<ProductFamily>(familyId, family)
  }

  /**
   * Create or update a family by ID
   * @param familyId - The family ID
   * @param family - The family body to update
   * @returns The observable<ProductFamily> for update the family
   */
  upsert$(family: ProductFamily): Observable<ProductFamily> {
    return this._upsert$<ProductFamily>(family, family._id)
  }

  /**
   * Delete a family by ID
   * @param familyId - The family ID
   * @returns The observable<ProductFamily> for delete the family
   */
  delete$(familyId: string): Observable<ProductFamily> {
    return this._delete$<ProductFamily>(familyId)
  }

  /**
   * Search families by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<ProductFamily>> for search families
   */
  search$(
    params?: ProductFamilySearchParams,
    returnAll = false,
  ): Observable<Page<ProductFamily>> {
    return this._search$<ProductFamily>(params, returnAll)
  }

  /**
   * List families by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<ProductFamily[]> for list families
   */
  list$(
    params?: ProductFamilySearchParams,
    returnAll = false,
  ): Observable<ProductFamily[]> {
    return this._list$<ProductFamily>(params, returnAll)
  }

  /**
   * Find a family by params
   * @param params - The search params
   * @returns the observable<ProductFamily> for find a catalog
   */
  readOne$(params?: ProductFamilySearchParams): Observable<ProductFamily> {
    return this._readOne$<ProductFamily>(params)
  }

  /**
   * Generate families store
   * @param familyKeys - the family keys to load
   * @param families - the families already loaded
   * @returns the Observable<ProductFamily[]> as store
   */
  store$(
    familyKeys: string[],
    families: ProductFamily[],
    key: '_id' | 'code' = '_id',
  ): Observable<ProductFamily[]> {
    familyKeys = uniq(familyKeys)
    familyKeys = difference(
      familyKeys,
      families.map((u) => u[key]),
    )

    if (familyKeys.length === 0) {
      return of(families)
    }

    return this.list$({ [key]: familyKeys }).pipe(
      map((famls) => [...families, ...famls]),
    )
  }
}
