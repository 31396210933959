/**
 * Storage class
 */
/* eslint-disable */
export class Storage {
  get(key: string): any {}
  set(key: string, value: any): any {}
  remove(key: string): any {}
}
/* eslint-enable */

/**
 * SessionStorage class used for internal use only
 */

export class SessionStorage extends Storage {}

/**
 * LocalStorage class used for custom use
 */
export class LocalStorage extends Storage {}

/**
 * CookieStorage class
 */
export class CookieStorage extends Storage {}
