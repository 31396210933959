import { Inject, Injectable, Optional } from '@angular/core'
import {
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs'
import { sortBy, uniq } from 'lodash'

import { ProductsService } from '../products.service'
import { ProductFamiliesService, ProductFamily } from '../../product-families'
import { Channel, ChannelsService } from '../../channels'
import { Attribute } from '../../attributes'
import { Supplier, SuppliersService } from '../../suppliers'
import { File, FilesService } from '../../files'
import {
  Product,
  ProductData,
  ProductFilesData,
  ProductNotification,
  ProductNotificationData,
  ProductScope,
} from '../product.model'
import { checkProductScope } from '../libs/scope.lib'
import { Tenant } from '../../tenants'
import { checkProductNotification } from '../libs/notification.lib'
import { User, UsersService } from '../../users'
import { getProductUserIds } from '../libs/product.lib'
import { Catalog } from '../../catalogs'
import { ProductsRepository } from './products.repository'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../../models/notification.model'

@Injectable({
  providedIn: 'root',
})
export class ProductDetailRepository extends ProductsRepository {
  // Stores
  private channels: Channel[] = []
  private suppliers: Supplier[] = []

  constructor(
    private productsService: ProductsService,
    private productFamiliesService: ProductFamiliesService,
    private channelsService: ChannelsService,
    private suppliersService: SuppliersService,
    private usersService: UsersService,
    private filesService: FilesService,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    override notificationManager?: NotificationManager,
  ) {
    super(notificationManager)
  }

  /**
   * Load product data
   * @param productId - the product ID to load
   * @param scope - the product scope
   * @returns the observable for load the product
   */
  readProduct$(
    productId: string,
    scope: ProductScope,
    attributes?: Attribute[],
    catalogs?: Catalog[],
    treeId?: string,
  ): Observable<ProductData> {
    return this.productsService
      .read$(productId)
      .pipe(
        switchMap((product) =>
          this._loadExtData$(product, scope, attributes, catalogs, treeId),
        ),
      )
  }

  /**
   * Save product
   * @param product - the product to save
   * @returns the observable for save the product
   */
  saveProduct$(
    product: Product,
    tenant: Tenant,
  ): Observable<ProductNotificationData> {
    return this._checkNotifications$(product, tenant).pipe(
      switchMap((notification) =>
        this.productsService.update$(product._id, product).pipe(
          map((product) => ({
            product,
            notification: notification || { kind: 'PRODUCT_SAVED' },
          })),
        ),
      ),
    )
  }

  /**
   * Attach files to a product
   * @param productId - the product ID
   * @param files - the files to attach
   * @returns the observable for attach files to a product
   */
  attachFiles$(productId: string, files: File[]): Observable<ProductFilesData> {
    return this.productsService
      .attachFiles$(
        productId,
        files.map((f) => f._id),
      )
      .pipe(
        switchMap((product) =>
          this._loadFiles$(product).pipe(map((files) => ({ product, files }))),
        ),
      )
  }

  /**
   * Detach file from a product
   * @param productId - the product ID
   * @param fileId - the file ID
   * @returns the observable for detach a file from a product
   */
  detachFile$(productId: string, fileId: string): Observable<ProductFilesData> {
    return this.productsService.detachFile$(productId, fileId).pipe(
      switchMap(() => this.filesService.delete$(fileId)),
      switchMap(() => this.productsService.read$(productId)),
      switchMap((product) =>
        this._loadFiles$(product).pipe(map((files) => ({ product, files }))),
      ),
    )
  }

  /**
   * Load product channels
   * @param product - the product
   * @returns the observable for load product channels
   */
  loadChannels$(product: Product): Observable<Channel[] | undefined> {
    if (!product.externalSKUs?.length) {
      return of(undefined)
    }

    const channelIds = product.externalSKUs
      .filter((s) => !!s.channelId)
      .map((s) => s.channelId!)
    return this.channelsService
      .store$(channelIds, this.channels)
      .pipe(tap((channels) => (this.channels = channels)))
  }

  /**
   * Load product suppliers
   * @param product - the product
   * @returns the observable for load product suppliers
   */
  loadSuppliers$(product: Product): Observable<Supplier[] | undefined> {
    if (!product.suppliers) {
      return of(undefined)
    }

    const supplierIds = product.suppliers
      .filter((s) => !!s.supplierId)
      .map((s) => s.supplierId!)
    return this.suppliersService
      .store$(supplierIds, this.suppliers)
      .pipe(tap((suppliers) => (this.suppliers = suppliers)))
  }

  /**
   * Load product data
   * @param product - the product
   * @return the observable for load product data
   */
  private _loadExtData$(
    product: Product,
    scope: ProductScope,
    attributes?: Attribute[],
    catalogs?: Catalog[],
    treeId?: string,
  ): Observable<ProductData> {
    return this._loadFamily$(product).pipe(
      switchMap((family) =>
        combineLatest({
          product: of(checkProductScope(product, scope, family, attributes)),
          catalog: of(catalogs?.find((c) => c.code === scope.catalogCode)),
          categoryRoot: of(treeId),
          family: of(family),
          files: this._loadFiles$(product),
          channels: this.loadChannels$(product),
          suppliers: this.loadSuppliers$(product),
          users: this._loadUsers$(product),
          kits: this._loadKits$(product),
        }),
      ),
    )
  }

  /**
   * Load product files
   * @param product - the product
   * @returns the observable for load product files
   */
  private _loadFiles$(product: Product): Observable<File[] | undefined> {
    if (!product.files?.length) {
      return of(undefined)
    }

    const fileIds = uniq(product.files.map((file) => file._id))
    return this.filesService
      .list$({ _id: fileIds.join(',') })
      .pipe(map((files) => sortBy(files, (i) => fileIds.indexOf(i._id))))
  }

  /**
   * Load product kits
   * @param product - the product kits
   * @returns the observable for load product kits
   */
  private _loadKits$(product: Product): Observable<Product[] | undefined> {
    if (!product.kitIds?.length) {
      return of(undefined)
    }

    return this.productsService.list$({ _id: product.kitIds })
  }

  /**
   * Load product users
   * @param product - the product
   * @returns the observable for load product users
   */
  private _loadUsers$(product: Product): Observable<User[] | undefined> {
    const userIds = getProductUserIds(product)
    if (!userIds.length) {
      return of(undefined)
    }

    return this.usersService.list$({ _id: userIds })
  }

  /**
   * Load product family if assigned
   * @param product the product
   * @returns the observable for load the product family
   */
  private _loadFamily$(
    product: Product,
  ): Observable<ProductFamily | undefined> {
    if (!product.family?.code) {
      return of(undefined)
    }

    return this.productFamiliesService.readOne$({ code: product.family.code })
  }

  /**
   * Check product notification and throw the error
   * @param product the product to parse
   * @param tenant the tenant
   * @returns the observable for check the notifications
   */
  private _checkNotifications$(
    product: Product,
    tenant: Tenant,
  ): Observable<ProductNotification | undefined> {
    return of(checkProductNotification(product, tenant)).pipe(
      switchMap((notification) => {
        // Throw error if is
        if (notification?.level === 'error') {
          throwError(() => notification)
        }
        return of(notification)
      }),
    )
  }
}
