import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  PickingList,
  PickingListField,
  PickingListSearchParams,
  PickingListsListingData,
  PickingListsListingPage,
} from '../picking-list.model'
import { HttpErrorResponse } from '@angular/common/http'
import { PickingListsRepository } from '../picking-lists.repository'
import {
  Observable,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { User } from '../../users'
import { Page } from '../../../models/util.model'

interface PickingListsListingState extends PickingListsListingPage {
  searchString?: string
  selected?: PickingList[]
  fields: PickingListField[]
  filters?: PickingListSearchParams
  searchKeys?: PickingListSearchParams
  customFilters?: PickingListSearchParams
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const PICKING_LISTS_INITIAL_STATE: PickingListsListingState = {
  fields: ['name'],
  isLoading: false,
  isInitialized: false,
  toReload: false,
}

@Injectable()
export class PickingListsListingUseCase extends ComponentStore<PickingListsListingState> {
  constructor(private pickingListsRepository: PickingListsRepository) {
    super(PICKING_LISTS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<PickingListsListingState> = this.select(
    (state) => state
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized
  )

  readonly selectSearchString$: Observable<string | undefined> = this.select(
    (state) => state.searchString
  )

  readonly selectFilters$: Observable<PickingListSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectSearchFilters$: Observable<
    PickingListSearchParams | undefined
  > = this.selectFilters$.pipe(
    mergeMap((filters) =>
      this.selectSearchString$.pipe(
        map((q) => ({ ...(q ? { q } : {}), ...(filters ? filters : {}) }))
      )
    )
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error
  )

  readonly selectIsFiltered$: Observable<boolean> =
    this.selectSearchFilters$.pipe(
      filter((filters) => !!filters),
      map((filters) => isQueryStringFiltered(filters || {}))
    )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload
  )

  readonly selectIsInitialized?: Observable<boolean> = this.select(
    (state) => state.isInitialized
  )

  readonly selectCustomFilters$: Observable<
    PickingListSearchParams | undefined
  > = this.select((state) => state.customFilters)

  readonly selectPickingLists$: Observable<PickingList[]> = this.select(
    (state) => state.data || []
  )

  readonly selectPickingListsSelected$: Observable<PickingList[] | undefined> =
    this.select((state) => state.selected)

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0
  )

  readonly selectFields$: Observable<PickingListField[] | undefined> =
    this.select((state) => state.fields)

  readonly selectExtData$: Observable<PickingListsListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectUsers$: Observable<User[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.users))

  readonly selectPage$: Observable<Page<PickingList>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectPickingLists$,
          this.selectTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true }
        )
      )
    )

  /**
   * EFFECTS
   */

  readonly search$ = this.effect(
    (searchParams$: Observable<PickingListSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        delay(1),
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        switchMap(([_, state]) =>
          this.pickingListsRepository
            .searchPickingLists$(
              this.parseStateFilterParams(state),
              state.fields || [],
              state.extData
            )
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error)
              )
            )
        )
      )
    }
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<PickingListSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        map(([params, state]) => ({
          params: { ...params, limit: state.totalCount },
          fields: state.fields || [],
          extData: state.extData,
        })),
        tap((searchParams) => this.setFilters(searchParams.params)),
        switchMap(({ params, fields, extData }) =>
          this.pickingListsRepository
            .searchPickingLists$(params, fields, extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error)
              )
            )
        )
      )
    }
  )

  readonly reload$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectState$),
      filter(([_, state]) => !!state.fields),
      switchMap(([_, state]) =>
        this.pickingListsRepository
          .searchPickingLists$(
            this.parseStateFilterParams(state),
            state.fields || [],
            state.extData
          )
          .pipe(
            tapResponse(
              (pageData) => this.setPage(pageData),
              (error: HttpErrorResponse) => this.setError(error)
            )
          )
      )
    )
  })

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): PickingListsListingState => {
      return {
        ...state,
        isLoading,
      }
    }
  )

  readonly setSearchString = this.updater(
    (state, searchString: string): PickingListsListingState => {
      return {
        ...state,
        searchString,
        toReload: true,
      }
    }
  )

  readonly setPickingListsSelected = this.updater(
    (
      state,
      goodsReceives: PickingList[] | undefined
    ): PickingListsListingState => {
      return {
        ...state,
        selected: goodsReceives,
      }
    }
  )

  readonly setPage = this.updater(
    (state, page: PickingListsListingPage): PickingListsListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    }
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): PickingListsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isLoading: false,
        toReload: false,
      }
    }
  )

  readonly setReload = this.updater(
    (state, toReload: boolean): PickingListsListingState => {
      return {
        ...state,
        toReload,
      }
    }
  )

  readonly setFields = this.updater(
    (state, fields: PickingListField[]): PickingListsListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    }
  )

  readonly setFilters = this.updater(
    (state, filters?: PickingListSearchParams): PickingListsListingState => {
      return {
        ...state,
        filters,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    }
  )

  readonly setCustomFilters = this.updater(
    (
      state,
      customFilters: PickingListSearchParams
    ): PickingListsListingState => {
      return {
        ...state,
        customFilters,
        toReload: state.isInitialized,
      }
    }
  )

  readonly setSearchKeys = this.updater(
    (state, searchKeys?: PickingListSearchParams): PickingListsListingState => {
      return {
        ...state,
        searchKeys,
        toReload: state.isInitialized,
      }
    }
  )

  readonly resetState = this.updater(
    (_): PickingListsListingState => PICKING_LISTS_INITIAL_STATE
  )

  readonly resetError = this.updater((state): PickingListsListingState => {
    return {
      ...state,
      error: undefined,
    }
  })

  readonly resetFilters = this.updater((state): PickingListsListingState => {
    return {
      ...state,
      filters: undefined,
      searchString: undefined,
      error: undefined,
      customFilters: undefined,
      toReload: true,
    }
  })

  /**
   * Utilities
   */

  private parseStateFilterParams(
    state: PickingListsListingState
  ): PickingListSearchParams {
    let params: PickingListSearchParams = state.filters || {}
    if (state.searchString) {
      params.q = state.searchString
    }
    if (state.customFilters) {
      params = { ...params, ...state.customFilters }
    }
    if (state.searchKeys) {
      params = { ...params, ...state.searchKeys }
    }

    return params
  }
}
