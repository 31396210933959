import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  PackingListNotificationCode,
  PackingListNotificationData,
  PackingListNotificationOptions,
} from '../packing-list.model'

export class PackingListNotification implements Notification {
  public code: PackingListNotificationCode
  public type: NotificationType
  public data?: PackingListNotificationData
  public dialog?: DialogParams
  constructor({ code, data }: PackingListNotificationOptions) {
    const { type, dialog } = parsePackingNotification(code, data)
    this.code = code
    this.type = type
    this.data = data
    this.dialog = dialog
  }

  static from(opts: PackingListNotificationOptions): PackingListNotification {
    if (opts instanceof PackingListNotification) {
      return opts
    } else {
      return new PackingListNotification(opts)
    }
  }
}

/**
 * Parse packing-list notification to a message
 * @param notification - the packing notification
 * @returns the notification data
 */
function parsePackingNotification(
  code: PackingListNotificationCode,
  data?: PackingListNotificationData,
): { dialog: DialogParams; type: NotificationType } {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'TOTE_NOT_FOUND':
      dialog = {
        message: 'Tote not found',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'ORDER_UPDATED':
      dialog = {
        message: 'Order successfully updated',
        color: 'success',
      }
      break
    case 'ORDER_PRINTED':
      dialog = {
        message: 'Order label printed',
        color: 'success',
      }
      break
    case 'ORDER_NOT_SELECTED':
      dialog = {
        message: 'Order not selected',
        title: 'Warning',
        color: 'warning',
      }
      break
    case 'ORDER_NOT_FOUND':
      dialog = {
        message: 'Order not found',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'TOTE_EMPTY':
      dialog = {
        message: 'Tote without picking-lists',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'PACKAGING_ADDED':
      dialog = {
        message: 'Packaging added to the current packing-list',
        color: 'info',
      }
      break
    case 'PACKAGING_DISABLED':
      dialog = {
        message: 'Please contact the office',
        title: 'Packaging disabled',
        color: 'warning',
      }
      break
    case 'PICKING_WITHOUT_TOTES':
      dialog = {
        message: 'Picking without totes',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'PICKING_NOT_PACKABLE':
      dialog = {
        message: 'Picking orders not packable',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'STATION_NOT_SELECTED':
      dialog = {
        message: 'Station not selected',
        color: 'warning',
      }
      break
    case 'PRODUCT_NOT_FOUND':
      dialog = {
        title: 'Product not found',
        message: 'No products found with this code.',
        color: 'danger',
      }
      break
    case 'PRODUCT_ORDER_NOT_CONTAINED':
      dialog = {
        message: 'Product not contained in the current order',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'PRODUCT_PACK_NOT_CONTAINED':
      dialog = {
        message: 'Product not contained in the current packing-list',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'PRODUCT_ALREADY_PACKED':
      dialog = {
        message: 'Product already packed',
        title: 'Warning',
        color: 'danger',
      }
      break
    case 'PACKED_BY_ANOTHER_USER': {
      const { pickingListName, user } = data || {}
      const messageParams = []

      if (pickingListName) {
        messageParams.push(pickingListName)
      }

      if (user) {
        messageParams.push(`${user.name} ${user.surname}`)
      } else {
        messageParams.push(`User not found`)
      }

      dialog = {
        icon: 'warning',
        color: 'danger',
        title: 'Picking-list already started',
        message:
          'Picking-list <strong>%s</strong> already started by <strong>%s</strong>. Proceed with packing?',
        messageParams,
        size: 'lg',
      }
      type = 'alert'
      break
    }
    case 'ORDER_PACKED': {
      dialog = {}
      type = 'event'
      break
    }
  }

  return { dialog, type }
}
