import { uniq } from 'lodash'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { Page } from '../../../models/util.model'
import {
  PickingList,
  PickingListSearchField,
  PickingListSearchParams,
  PickingListSortField,
  PickingListsListingKeys,
} from '../picking-list.model'
import { getPickingUserIds } from './picking-list.lib'

export function pickingListSortParams(
  params: PickingListSearchParams,
  sort: QueryStringSort<PickingListSortField>
): PickingListSearchParams {
  const searchParams: PickingListSearchParams = {}

  switch (sort.field) {
    case 'pickingNumber':
      searchParams.sort = 'pickingNumber'
      searchParams.order = sort.order
      break
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'ordersCount':
      searchParams.sort = 'ordersCount'
      searchParams.order = sort.order
      break
    case 'itemsCount':
      searchParams.sort = 'itemsCount'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function pickingListFilterParams(
  params: PickingListSearchParams,
  filter: QueryStringFilter<PickingListSearchField>
): PickingListSearchParams {
  const searchParams: PickingListSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      }
      break
    case 'pickingNumber':
      if (filter.operator === '=') {
        searchParams.pickingNumber = filter.value
      } else if (filter.operator === '<') {
        searchParams['pickingNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['pickingNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['pickingNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['pickingNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['pickingNumber:ne'] = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams.status = filter.value
      }
      break
    case 'type':
      if (filter.value !== null) {
        searchParams.type = filter.value
      }
      break
    case 'ordersCount':
      if (filter.operator === '=') {
        searchParams.ordersCount = filter.value
      } else if (filter.operator === '<') {
        searchParams['ordersCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['ordersCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['ordersCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['ordersCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['ordersCount:ne'] = filter.value
      }
      break
    case 'itemsCount':
      if (filter.operator === '=') {
        searchParams.itemsCount = filter.value
      } else if (filter.operator === '<') {
        searchParams['itemsCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['itemsCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['itemsCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['itemsCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['itemsCount:ne'] = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams.createdAt = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'notes':
      if (typeof filter.value === 'boolean') {
        searchParams['notes:ex'] = filter.value
      } else if (filter.operator === '=') {
        searchParams.notes = filter.value
      } else if (filter.operator === '<>') {
        searchParams['notes:ne'] = filter.value
      }
      break
    case 'isPartial':
      if (filter.value !== null) {
        searchParams['isPartial'] = filter.value
      }
      break
    case 'isUrgent':
      if (filter.value !== null) {
        searchParams['isUrgent'] = filter.value
      }
      break
    case 'isBlocked':
      if (filter.value !== null) {
        searchParams['isBlocked'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse picking-list page keys
 * @param page - the picking-list page
 * @returns the page updated
 */
export function parsePickingListPageKeys(
  page: Page<PickingList>
): PickingListsListingKeys {
  return page.data.reduce<PickingListsListingKeys>(
    (acc, r) => parsePickingListKeys(r, acc),
    {}
  )
}

/**
 * Parse picking-list keys
 * @param pickingList - the picking-list
 * @param keys - the keys already loaded
 */
export function parsePickingListKeys(
  pickingList: PickingList,
  keys: PickingListsListingKeys = {}
): PickingListsListingKeys {
  const userIds = uniq([
    ...(keys['userIds'] || []),
    ...getPickingUserIds(pickingList),
  ])

  return {
    userIds,
  }
}
