import { Pipe, PipeTransform } from '@angular/core'
import { EMPTY, Observable, catchError } from 'rxjs'
import { WarehousesService } from '../warehouses.service'
import { Warehouse } from '../warehouse.model'

@Pipe({
  name: 'warehouse',
})
export class WarehousePipe implements PipeTransform {
  constructor(private warehousesService: WarehousesService) {}

  transform(warehouseId: string): Observable<Warehouse> {
    return this.warehousesService
      .read$(warehouseId)
      .pipe(catchError(() => EMPTY))
  }
}
