import { Component, OnInit } from '@angular/core'
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms'

import {
  AuthStoreService,
  userPasswordChange,
} from '@evologi/shared/auth-store'

@Component({
  selector: 'op-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  public changeForm?: UntypedFormGroup
  public capsOn = false

  constructor(
    private authStoreService: AuthStoreService,
    private builder: UntypedFormBuilder,
  ) {}

  // Lifecycle methods

  ngOnInit() {
    // Init form
    this.changeForm = this.builder.group({
      oldPassword: ['', Validators.compose([Validators.required])],
      newPassword: ['', Validators.compose([Validators.required])],
    })
  }

  // Change methods

  change() {
    const { oldPassword, newPassword } = this.changeForm?.value || {}
    this.authStoreService.changePassword(oldPassword, newPassword)
  }
}
