import { Pipe, PipeTransform } from '@angular/core'
import { get } from 'lodash'

@Pipe({
  name: 'get',
})
export class GetPipe implements PipeTransform {
  transform(value: any, keyField: string | number): any {
    return get(value, keyField)
  }
}
