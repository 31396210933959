import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  AuthNotificationCode,
  AuthNotificationData,
  AuthNotificationOptions,
} from '../auth.model'

/**
 * Auth notification class
 */
export class AuthNotification implements Notification {
  public code: AuthNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: AuthNotificationOptions) {
    const { type, dialog } = parseAuthNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: AuthNotificationOptions): AuthNotification {
    if (opts instanceof AuthNotification) {
      return opts
    } else {
      return new AuthNotification(opts)
    }
  }
}

/**
 * Parse picking-list notification to a message
 * @param code - the notification code
 * @param data - the notification data
 * @returns the notification data
 */
function parseAuthNotification(
  code: AuthNotificationCode,
  data?: AuthNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'PASSWORD_CHANGED':
    case 'PASSWORD_UPDATED':
      dialog = {
        message: 'Password changed successfully',
        color: 'success',
      }
      break
    case 'PASSWORD_RECOVERED':
      dialog = {
        title: 'Password resetted successfully',
        message:
          'You will shortly receive an email with your new temporary password.',
        color: 'info',
      }
      break
    case 'SESSION_CONFLICT':
      type = 'alert'
      dialog = {
        icon: 'warning',
        color: 'info',
        title: 'User already logged',
        message:
          'An active session already exists for this user. Proceed anyway?',
        size: 'lg',
      }
      break
    case 'SESSION_EXPIRED':
      dialog = {
        title: 'Sessione expired',
        message: 'Please log in again to continue.',
        color: 'info',
      }
      break
    case 'TENANT_SAVED':
      dialog = {
        message: 'Settings saved successfully',
        color: 'success',
      }
      break
    case 'TENANT_SELECTED':
      dialog = {
        title: 'Welcome!',
        message: 'Tenant selected successfully',
        color: 'success',
      }
      break
    case 'USER_SAVED':
      dialog = {
        message: 'Profile saved successfully',
        color: 'success',
      }
      break
  }

  return { dialog, type }
}
