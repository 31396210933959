import { cloneDeep, defaultsDeep } from 'lodash'
import { Activity, ActivityContext } from './activity.model'
import { DeepPartial } from '../../models/util.model'

// Initial states

const CONTEXT_INITIAL_STATE: Partial<ActivityContext> = {
  _id: undefined,
  code: undefined,
}

const ACTIVITY_INITIAL_STATE: DeepPartial<Activity> = {
  context: CONTEXT_INITIAL_STATE,
  label: undefined,
  code: undefined,
  description: undefined,
  isDisabled: false,
  fields: [],
  values: {},
}

// Reducers

export function initActivity(activity: Partial<Activity> = {}): Activity {
  return defaultsDeep(cloneDeep(activity), ACTIVITY_INITIAL_STATE)
}
