import { Injectable } from '@angular/core'
import { OrderCounters } from '../order.model'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Observable, switchMap, tap } from 'rxjs'
import { OrdersService } from '../orders.service'
import { HttpErrorResponse } from '@angular/common/http'

interface OrdersCountersState {
  counters?: OrderCounters
  isLoading: boolean
  error?: HttpErrorResponse
}

const COUNTERS_INITIAL_STATE: OrdersCountersState = {
  isLoading: false,
}

@Injectable()
export class OrdersCountersUseCase extends ComponentStore<OrdersCountersState> {
  constructor(private ordersService: OrdersService) {
    super(COUNTERS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<OrdersCountersState> = this.select(
    (state) => state,
  )

  readonly selectCounters$: Observable<OrderCounters | undefined> = this.select(
    (state) => state.counters,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  /**
   * EFFECTS
   */

  readonly load$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      switchMap(() =>
        this.ordersService.counts$().pipe(
          tapResponse(
            (pageData) => this.setCounters(pageData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): OrdersCountersState => ({
      ...state,
      isLoading,
      error: undefined,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): OrdersCountersState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setCounters = this.updater(
    (state, counters: OrderCounters): OrdersCountersState => {
      return {
        ...state,
        counters,
        isLoading: false,
      }
    },
  )
}
