<form
  *ngIf="changeForm"
  class="form"
  [formGroup]="changeForm"
  (ngSubmit)="change()"
  (tkCapsLock)="capsOn = $event"
>
  <h5 class="title">Aggiornamento password</h5>
  <div class="form-group">
    <label for="old-password">Password corrente</label>
    <input
      type="password"
      id="old-password"
      class="form-control form-control-lg"
      [class.border]="capsOn"
      [class.border-info]="capsOn"
      placeholder="Password corrente"
      formControlName="oldPassword"
      required
    />
  </div>
  <div class="form-group">
    <label for="new-password">Nuova password</label>
    <input
      type="password"
      id="new-password"
      class="form-control form-control-lg"
      [class.border]="capsOn"
      [class.border-info]="capsOn"
      placeholder="Nuova password"
      formControlName="newPassword"
      required
    />
  </div>
  <p class="text-center text-info font-weight-bold" *ngIf="capsOn">
    <small>Caps Lock attivo <i class="fad fa-lock-alt"></i></small>
  </p>
  <button
    class="btn btn-primary btn-block btn-lg"
    type="submit"
    [disabled]="!changeForm.valid"
  >
    Aggiorna password
  </button>
  <a
    class="link-back text-secondary"
    data-test="link-back"
    [routerLink]="['/auth/login']"
  >
    Torna al login
  </a>
</form>
