import { Pipe, PipeTransform } from '@angular/core'
import { PickingList, PickingListLocationPicked } from '../picking-list.model'
import { getPickingLocationsPicked } from '../libs/picking-list.lib'

@Pipe({
  name: 'pickingListLocationsPicked',
})
export class PickingListLocationsPickedPipe implements PipeTransform {
  transform(
    pickingList: PickingList,
    productId: string
  ): PickingListLocationPicked[] {
    return getPickingLocationsPicked(pickingList, productId)
  }
}
