import { ProductNotification, parseProductNotification } from '..'
import { NotificationManager } from '../../../models/notification.model'

export abstract class ProductsRepository {
  constructor(protected notificationManager?: NotificationManager) {}

  /**
   * Notify a message about a replenishment event
   * @param notification - the replenishment notification
   */
  notify(notification: ProductNotification): void {
    this.notificationManager?.show(parseProductNotification(notification))
  }
}
