import { Component, HostListener, Inject, Input } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '@evologi/shared/data-access-api'
import { UiColor, UiSize } from '../../models/ui.model'

@Component({
  selector: 'op-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input({ required: true }) modalId!: string
  @Input() message?: string
  @Input() messageParams?: Array<string | number>
  @Input() title?: string
  @Input() titleParams?: Array<string | number>

  @Input() color?: UiColor
  @Input() icon?: 'info' | 'delete' | 'warning'
  @Input() size?: UiSize = 'md'

  constructor(@Inject(MODAL_MANAGER) private modalManager: ModalManager) {}

  hide(kind: 'CONFIRM' | 'CANCEL') {
    this.modalManager.hide(this.modalId, kind)
  }

  @HostListener('document:keydown.enter')
  onSubmit() {
    this.hide('CONFIRM')
  }
}
