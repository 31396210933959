import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { ChannelReference } from '../channels'

export interface File extends Entity {
  name: string
  fileName: string
  referencedBy: FileReference[]
  path: string
  fullPath: string
  type: string
  channelReferences?: ChannelReference[]
}

export interface FileReference {
  _id: string
  type: FileReferenceType
}

export enum FileType {
  document = 'DOCUMENT',
  image = 'IMAGE',
  directory = 'DIRECTORY',
}

export enum FileReferenceType {
  product = 'PRODUCT',
  banner = 'BANNER',
}

export enum FileUpdateMethod {
  pull = 'pull',
  push = 'push',
  set = 'set',
  unset = 'unset',
  add = 'add',
  subtract = 'subtract',
  multiply = 'multiply',
  divide = 'divide',
}

export enum FileUpdateType {
  xls = 'XLS',
}

export enum FileDownloadType {
  empty = 'EMPTY',
  filled = 'FILLED',
}

export interface FileSearchParams extends QueryStringParams {
  name?: string
  type?: string
}

export interface Attachment {
  _id: string
  contentType: string
  digest: string
  size: number
  filename?: string
  createdAt: Date
  createdBy: string
}
