import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter'
import dxDataGrid from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'

export function exportGrid(
  component: dxDataGrid,
  fileName: string,
  sheetName = 'Sheet',
  customizeCell?: (options: {
    gridCell?: DataGridCell
    excelCell?: any
  }) => void,
  catalog?: string,
): void {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet(sheetName)
  exportDataGrid({
    component: component,
    worksheet: worksheet,
    selectedRowsOnly: !!component.getSelectedRowKeys().length,
    autoFilterEnabled: true,
    loadPanel: {
      enabled: false,
    },
    customizeCell,
  }).then(() =>
    workbook.xlsx
      .writeBuffer()
      .then((buffer: BlobPart) =>
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${fileName}.xlsx`,
        ),
      ),
  )
}

export function getGridFilterKeys(filters: any[]): string[] {
  const keys: string[] = []

  if (!filters.length) {
    return keys
  }

  // Check single filter
  if (!filters.some((f) => f instanceof Array)) {
    keys.push(filters[2])
    return keys
  }

  for (const filter of filters) {
    if (filter instanceof Array) {
      keys.push(filter[2])
    }
  }

  return keys
}
