import { cloneDeep, defaultsDeep } from 'lodash'

import {
  SupplierSearchParams,
  SupplierSearchField,
  SupplierSortField,
  Supplier,
} from './supplier.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const SUPPLIER_INITIAL_STATE: Partial<Supplier> = {
  code: undefined,
  alias: undefined,
  businessName: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  province: undefined,
  postalCode: undefined,
  countryCode: undefined,
  fiscalCode: undefined,
  vat: undefined,
  phone: undefined,
  email: undefined,
  contactName: undefined,
  contactSurname: undefined,
}

// Init

export function initSupplier(supplier: Partial<Supplier> = {}): Supplier {
  return defaultsDeep(cloneDeep(supplier), SUPPLIER_INITIAL_STATE)
}

// Search

export function supplierSortParams(
  params: SupplierSearchParams,
  sort: QueryStringSort<SupplierSortField>
): SupplierSearchParams {
  const searchParams: SupplierSearchParams = {}

  switch (sort.field) {
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
    case 'alias':
      searchParams.sort = 'alias'
      searchParams.order = sort.order
      break
    case 'businessName':
      searchParams.sort = 'businessName'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function supplierFilterParams(
  params: SupplierSearchParams,
  filter: QueryStringFilter<SupplierSearchField>
): SupplierSearchParams {
  const searchParams: SupplierSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      }
      break
    case 'alias':
      if (filter.operator === '=') {
        searchParams.alias = filter.value
      } else if (filter.operator === '<>') {
        searchParams['alias:ne'] = filter.value
      }
      break
    case 'businessName':
      if (filter.operator === '=') {
        searchParams.businessName = filter.value
      } else if (filter.operator === '<>') {
        searchParams['businessName:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['businessName:ct'] = filter.value
      }
      break
    case 'address1':
      if (filter.operator === '=') {
        searchParams.address1 = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address1:ne'] = filter.value
      }
      break
    case 'email':
      if (filter.operator === '=') {
        searchParams.email = filter.value
      } else if (filter.operator === '<>') {
        searchParams['email:ne'] = filter.value
      }
      break
    case 'city':
      if (filter.operator === '=') {
        searchParams.city = filter.value
      } else if (filter.operator === '<>') {
        searchParams['city:ne'] = filter.value
      }
      break
    case 'province':
      if (filter.operator === '=') {
        searchParams.province = filter.value
      } else if (filter.operator === '<>') {
        searchParams['province:ne'] = filter.value
      }
      break
    case 'countryCode':
      if (filter.operator === '=') {
        searchParams.countryCode = filter.value
      } else if (filter.operator === '<>') {
        searchParams['countryCode:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
