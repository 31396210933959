import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { AuthNotification } from './libs/auth-notification.lib'
import { AuthNotificationOptions } from './auth.model'
import { EMPTY, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Show auth alert
   * @param options - the auth notification or notification options
   * @returns the observable for show the alert about authentication
   */
  alert$(
    opts: AuthNotification | AuthNotificationOptions,
  ): Observable<boolean> {
    const notification = AuthNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a brand event
   * @param notification - the brand notification
   */
  notify(opts: AuthNotification | AuthNotificationOptions): void {
    const notification = AuthNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }
}
