import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Invoice, InvoiceSearchParams } from './invoice.model'
import { Page } from '../../models/util.model'

const MODEL = 'invoices'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read an invoice by ID
   * @param invoiceId - The invoice ID
   * @returns The observable<Invoice> for read the invoice
   */
  read$(invoiceId: string): Observable<Invoice> {
    return this._read$<Invoice>(invoiceId)
  }

  /**
   * Update an invoice by ID
   * @param invoiceId - The invoice ID
   * @param invoice - The invoice body to update
   * @returns The observable<Invoice> for update the invoice
   */
  update$(invoiceId: string, invoice: Invoice): Observable<Invoice> {
    return this._update$<Invoice>(invoiceId, invoice)
  }

  /**
   * Delete an invoice by ID
   * @param invoiceId - The invoice ID
   * @returns The observable<Invoice> for delete the invoice
   */
  delete$(invoiceId: string): Observable<Invoice> {
    return this._delete$<Invoice>(invoiceId)
  }

  /**
   * Search invoices by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Invoice>> for search invoices
   */
  search$(
    params?: InvoiceSearchParams,
    returnAll = false,
  ): Observable<Page<Invoice>> {
    return this._search$<Invoice>(params, returnAll)
  }

  /**
   * List invoices by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Invoice[]> for list invoices
   */
  list$(
    params?: InvoiceSearchParams,
    returnAll = false,
  ): Observable<Invoice[]> {
    return this._list$<Invoice>(params, returnAll)
  }
}
