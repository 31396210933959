import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router'
import { isEqual } from 'lodash'

interface StoredRoute {
  route: ActivatedRouteSnapshot
  handle: DetachedRouteHandle
}

@Injectable({
  providedIn: 'root',
})
export class RouteUsageStrategy implements RouteReuseStrategy {
  // Routes
  private storedRoutes: Record<string, StoredRoute> = {}

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .filter((p) => !!p && p !== '')
      .join('/')
      .trim()
      .replace(/\/$/, '')
  }

  // Controls

  shouldReuseRoute(
    previous: ActivatedRouteSnapshot,
    next: ActivatedRouteSnapshot
  ): boolean {
    return previous.routeConfig === next.routeConfig
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this.getRouteKey(route)
    const storedRoute = this.storedRoutes[key]

    if (route.data['resetStoreRoute']) {
      this.erase()
      return false
    }

    if (!!route.routeConfig && !!storedRoute) {
      const paramsMatch = isEqual(route.params, storedRoute.route.params)
      const queryParamsMatch = isEqual(
        route.queryParams,
        storedRoute.route.queryParams
      )
      return paramsMatch && queryParamsMatch
    }

    return false
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['storeRoute']
  }

  // Store&Retrieve

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this.getRouteKey(route)
    this.storedRoutes[key] = { route, handle }
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const key = this.getRouteKey(route)
    const storedRoute = this.storedRoutes[key]

    if (!route.routeConfig || route.routeConfig.loadChildren || !storedRoute) {
      return null
    }

    return storedRoute.handle
  }

  erase() {
    this.storedRoutes = {}
  }
}
