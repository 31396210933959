import { Pipe, PipeTransform } from '@angular/core'
import { TranslationService } from '@evologi/shared/util-translation'
import { vsprintf } from 'sprintf-js'

import { Attribute, parseAttributeValue } from '../../attributes'
import { ViewExternalData, ViewExternalKey } from '../view.model'
import { FILTER_ALL_VALUE, FILTER_TYPES } from '../../../consts/filter.const'
import { FilterOperator } from '../../../models/filter.model'
import { isDate } from 'lodash'

@Pipe({
  name: 'viewFieldValue',
})
export class ViewFieldValuePipe implements PipeTransform {
  constructor(private translateService: TranslationService) {}

  transform(
    value: any,
    operator?: FilterOperator,
    property?: string,
    extData?: ViewExternalData[],
    attributes?: Attribute[]
  ): string {
    const filter = FILTER_TYPES.find((f) => f.operator === operator)

    if (!filter || !property) {
      return FILTER_ALL_VALUE
    }

    if (isDate(value)) {
      value = value.toISOString()
    }

    let translatedValue = value
    if (property in ViewExternalKey) {
      const extEntity = extData?.find((e) => e?.value === value)
      translatedValue = extEntity?.name
    } else if (property.includes('attribute_')) {
      const attributeCode = property.replace('attribute_', '')
      const attribute = attributes?.find((a) => a.code === attributeCode)
      translatedValue = attribute
        ? parseAttributeValue(attribute, value)
        : value
    }

    if (
      filter.operator !== FilterOperator.exists &&
      filter.operator !== FilterOperator.notExists &&
      translatedValue === undefined
    ) {
      return FILTER_ALL_VALUE
    }

    translatedValue = this.translateService.translate(translatedValue)
    return vsprintf(filter.translation, [translatedValue])
  }
}
