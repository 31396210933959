import { cloneDeep, defaultsDeep, isEqual } from 'lodash'

import { Product, ProductBarcode, ProductBarcodeType } from '../product.model'
import { PRODUCT_BARCODE_INITIAL_STATE } from '../product.const'

/**
 * Initialize product barcodes
 * @param barcode - the partial barcode data
 * @returns the product barcode field updated
 */
export function initProductBarcode(
  barcode: Partial<ProductBarcode> = {}
): ProductBarcode {
  return defaultsDeep(cloneDeep(barcode), PRODUCT_BARCODE_INITIAL_STATE)
}

/**
 * Add barcode to product
 * @param productBarcodes - the product barcodes
 * @param barcode - the barcode to add
 * @returns the product barcode field updated
 */
export function addProductBarcode(
  productBarcodes: ProductBarcode[],
  barcode?: ProductBarcode
): ProductBarcode[] {
  return [...productBarcodes, barcode || PRODUCT_BARCODE_INITIAL_STATE]
}

/**
 * Update a barcode of product barcodes
 * @param barcodes - the product barcodes
 * @param barcode - the barcode updated
 * @param barcodeIndex - the barcode index
 * @returns the product barcode field updated
 */
export function updateProductBarcode(
  barcodes: ProductBarcode[],
  barcode: ProductBarcode,
  barcodeIndex: number
): ProductBarcode[] {
  return barcodes.map((b, i) => (i === barcodeIndex ? barcode : b))
}

/**
 * Delete a barcode from product barcodes
 * @param productBarcodes - the product barcodes
 * @param barcode - the barcode to delete
 * @returns the product barcode field updated
 */
export function deleteProductBarcode(
  productBarcodes: ProductBarcode[],
  barcode: ProductBarcode
): ProductBarcode[] {
  return productBarcodes.filter((b) => !isEqual(b, barcode))
}

/**
 * Get product barcode qty
 * @param product - the product
 * @param barcode - the barcode
 * @returns the qty of the barcode
 */
export function getProductBarcodeQty(
  product: Product,
  barcode: string
): number | undefined {
  const productBarcode = product.barcodes?.find((b) => b.value === barcode)
  return productBarcode?.qty
}

/**
 * Validate a barcode
 * @param barcode - the product barcode to check
 * @returns the barcode validation data
 */
export function validateProductBarcode(barcode: ProductBarcode): {
  isValid: boolean
  error?: string
} {
  const numericRegExp = new RegExp('^[0-9]+$')
  let isValid = false
  let error

  switch (barcode.type) {
    case ProductBarcodeType.generic:
      isValid = true
      break

    case ProductBarcodeType.ean13:
      isValid = numericRegExp.test(barcode.value) && barcode.value.length === 13

      if (!isValid) {
        error = 'Codice BARCODE EAN13 non valido: 13 cifre necessarie'
      }
      break

    case ProductBarcodeType.ean8:
      isValid = numericRegExp.test(barcode.value) && barcode.value.length === 8
      if (!isValid) {
        error = 'Codice BARCODE EAN8 non valido: 8 cifre necessarie'
      }
      break

    case ProductBarcodeType.minsan:
      isValid = numericRegExp.test(barcode.value) && barcode.value.length === 9
      if (!isValid) {
        error = 'Codice BARCODE MINSAN non valido: 9 cifre necessarie'
      }
      break

    case ProductBarcodeType.gtin14:
      isValid = numericRegExp.test(barcode.value) && barcode.value.length === 14
      if (!isValid) {
        error = 'Codice BARCODE GTIN14 non valido: 14 cifre necessarie'
      }
      break
  }

  return { isValid, error }
}
