import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies/policy.model'
import { UserField, UserHomeModule, UserTab, UserType } from './user.model'

export const USER_SCOPE: PermissionsScope = 'iam'
export const USER_FRAGMENT = 'user'

export const USER_TYPES: SelectionData<UserType>[] = [
  {
    label: 'Utente',
    value: UserType.human,
    icon: 'fad fa-user-astronaut',
  },
  {
    label: 'Bot',
    value: UserType.service,
    icon: 'fad fa-user-robot',
  },
]

export const USER_DEFAULT_FIELDS: UserField[] = ['username', 'surname', 'name']

export const USER_TABS = [
  {
    code: UserTab.general,
    name: 'Generale',
    types: [UserType.human, UserType.service],
  },
  {
    code: UserTab.tenant,
    name: 'Tenant',
    types: [UserType.human],
  },
  {
    code: UserTab.policies,
    name: 'Permessi',
    types: [UserType.human, UserType.service],
    hideOnProfile: true,
  },
  {
    code: UserTab.security,
    name: 'Sicurezza',
    types: [UserType.human],
  },
]

export const USER_HOME_MODULES: SelectionData<UserHomeModule>[] = [
  { value: 'GOODS_RECEIVES', label: 'Arrivi merce' },
  { value: 'PICKING_LISTS', label: 'Picking lists' },
  { value: 'ORDERS', label: 'Ordini' },
  { value: 'PRODUCTS', label: 'Prodotti' },
  { value: 'REPLENISHMENTS', label: 'Missioni di abbassamento' },
  { value: 'LOCATIONS', label: 'Ubicazioni' },
]

export const USER_HOME_STATUSES: {
  value: string
  label: string
  modules: UserHomeModule[]
}[] = [
  {
    value: 'CONFIRMED',
    label: 'Confermato',
    modules: ['GOODS_RECEIVES'],
  },
  {
    value: 'PENDING',
    label: 'In attesa',
    modules: ['ORDERS'],
  },
  {
    value: 'PROCESSING',
    label: 'In lavorazione',
    modules: ['GOODS_RECEIVES'],
  },
  {
    value: 'PROCESSING',
    label: 'In prelievo',
    modules: ['PICKING_LISTS', 'ORDERS', 'REPLENISHMENTS'],
  },
  {
    value: 'PENDING',
    label: 'Da prelevare',
    modules: ['PICKING_LISTS', 'REPLENISHMENTS'],
  },
  {
    value: 'TOTAL',
    label: 'Totale',
    modules: ['PRODUCTS', 'LOCATIONS'],
  },
]
