import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  Observable,
  switchMap,
  tap,
  map,
  filter,
  withLatestFrom,
  take,
  EMPTY,
} from 'rxjs'
import {
  User,
  UserField,
  UserSearchParams,
  UsersListingPage,
} from '../user.model'
import { UsersService } from '../users.service'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { UsersRepository } from '../users.repository'
import { UserNotification } from '../libs/user-notification.lib'
import { USER_DEFAULT_FIELDS } from '../user.const'
import { parseUsersPage } from '../libs/user.lib'

interface UsersListingState extends UsersListingPage {
  fields: UserField[]
  filters?: UserSearchParams
  tenantId?: string
  notification?: UserNotification
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const USERS_LISTING_INITIAL_STATE: UsersListingState = {
  fields: USER_DEFAULT_FIELDS,
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class UsersListingUseCase extends ComponentStore<UsersListingState> {
  constructor(
    private usersService: UsersService,
    private usersRepository: UsersRepository,
  ) {
    super(USERS_LISTING_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectFilters$: Observable<UserSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {}, ['_id', 'type'])),
  )

  readonly selectUsers$: Observable<User[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<Page<User>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectUsers$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  readonly selectToReload$ = this.select((state) => state.toReload)

  readonly selectExtData$ = this.select((state) => state.extData)

  readonly selectRoles$ = this.selectExtData$.pipe(
    map((extData) => extData?.roles),
  )

  readonly selectNotification$ = this.select((state) => state.notification)

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<UserSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectState$),
        switchMap(([searchParams, state]) => {
          if (!state.fields || !state.tenantId) {
            return EMPTY
          }

          this.setFilters(searchParams)
          return this.usersRepository
            .searchUsers$(
              searchParams,
              state.fields,
              state.tenantId,
              state.extData,
            )
            .pipe(
              tapResponse(
                (page) =>
                  this.setPage(
                    parseUsersPage(page, state.fields, state.tenantId),
                  ),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<UserSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        switchMap(([searchParams, state]) => {
          if (!state.fields || !state.tenantId) {
            return EMPTY
          }

          return this.usersRepository
            .searchUsers$(
              { ...searchParams, limit: state.totalCount },
              state.fields,
              state.tenantId,
              state.extData,
            )
            .pipe(
              tapResponse(
                (page) =>
                  this.setPage(
                    parseUsersPage(page, state.fields, state.tenantId),
                  ),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly delete$ = this.effect((user$: Observable<User>) => {
    return user$.pipe(
      switchMap((user) =>
        this.usersRepository
          .alert$(
            UserNotification.from({
              code: 'USER_DELETION',
              data: { user },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.usersService.delete$(user._id).pipe(
                tapResponse(
                  () => this.setUserDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  readonly eject$ = this.effect((user$: Observable<User>) => {
    return user$.pipe(
      switchMap((user) =>
        this.usersRepository
          .alert$(
            UserNotification.from({
              code: 'USER_EJECTION',
              data: { user },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.usersService.eject$(user._id).pipe(
                tapResponse(
                  () => this.setUserEjected(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  // Reducers

  readonly setFilters = this.updater(
    (state, filters?: UserSearchParams): UsersListingState => {
      return {
        filters,
        tenantId: state.tenantId,
        extData: state.extData,
        fields: state.fields,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: UserField[]): UsersListingState => {
      return {
        ...state,
        fields,
      }
    },
  )

  readonly setTenantId = this.updater(
    (state, tenantId: string): UsersListingState => {
      return {
        ...state,
        tenantId,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: UsersListingPage): UsersListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        extData: page.extData,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): UsersListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): UsersListingState => {
      return {
        ...state,
        isLoading: true,
        toReload,
      }
    },
  )

  readonly setUsers = this.updater((state, data: User[]): UsersListingState => {
    return {
      ...state,
      data,
    }
  })

  readonly setUserDeleted = this.updater((state): UsersListingState => {
    return {
      ...state,
      notification: UserNotification.from({ code: 'USER_DELETED' }),
      toReload: true,
    }
  })

  readonly setUserEjected = this.updater((state): UsersListingState => {
    return {
      ...state,
      notification: UserNotification.from({ code: 'USER_EJECTED' }),
      toReload: true,
    }
  })

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): UsersListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )
}
