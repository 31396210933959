export enum AddressRecipientType {
  customer = 'CUSTOMER',
  warehouse = 'WAREHOUSE',
  pickupPoint = 'PICKUP_POINT',
}

export enum AddressType {
  shipping = 'SHIPPING',
  billing = 'BILLING',
}

export interface Address {
  name?: string
  address1?: string
  address2?: string
  city?: string
  province?: string
  postalCode?: string
  countryCode?: string
}

export interface ShippingAddress extends Address {
  _id?: string
  businessName?: string
  phone?: string
  email?: string
  addressType?: AddressRecipientType
}

export interface BillingAddress extends Address {
  _id?: string
  businessName?: string
  phone?: string
  email?: string
  fiscalCode?: string
  vat?: string
  sdiCode?: string
  pec?: string
}
