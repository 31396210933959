import * as XLSX from 'xlsx'
import { isEmpty } from 'lodash'

export function parseSearchFile<T>(
  file: File,
  fieldKey: string
): Promise<T | undefined> {
  return readSyncFile<T>(file, fieldKey)
}

function readSyncFile<T>(file: File, fieldKey: string): Promise<T | undefined> {
  const reader: FileReader = new FileReader()
  let params: T | undefined

  const promise = new Promise<T | undefined>((resolve, reject) => {
    // Reader events
    reader.onload = (e) => {
      const data = e.target?.result
      const workbook = XLSX.read(data, {
        type: 'binary',
      })

      workbook.SheetNames.forEach((sheetName) => {
        const jsonRows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])

        if (isEmpty(jsonRows)) {
          reject('FILE_NOT_VALID')
        }

        params = parseRows<T>(jsonRows, params, fieldKey)

        if (isEmpty(params)) {
          reject('FILE_NOT_VALID')
        }

        resolve(params)
      })
    }

    reader.onerror = (error) => reject(error)

    reader.readAsBinaryString(file)
  })

  return promise
}

function parseRows<T>(
  rows: any[],
  params: any = {},
  fieldKey: string
): T | undefined {
  if (!rows.length) {
    return params
  }

  return rows.reduce((acc, row) => parseRow(row, acc, fieldKey), params || {})
}

function parseRow<T>(row: any, params: any = {}, fieldKey: string): T {
  const rowKey = Object.keys(row)[0]
  const objKey = checkKey(rowKey) ? '_id' : fieldKey
  params[objKey] = [...(params[objKey] || []), row[rowKey]]
  return params
}

function checkKey(key: string): boolean {
  key = key
    .replace(/[^a-zA-Z0-9 _]/g, '')
    .replace(/\s+/g, '')
    .replace(/-+/g, '')
    .toLowerCase()

  return key.includes('id')
}
