import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Banner,
  BannerSearchField,
  BannerSearchParams,
  BannerSortField,
} from './banner.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const BANNER_INITIAL_STATE: Partial<Banner> = {
  name: undefined,
}

// Init

export function initBanner(banner: Partial<Banner> = {}): Banner {
  return defaultsDeep(cloneDeep(banner), BANNER_INITIAL_STATE)
}

// Search

export function bannerSortParams(
  params: BannerSearchParams,
  sort: QueryStringSort<BannerSortField>
): BannerSearchParams {
  const searchParams: BannerSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function bannerFilterParams(
  params: BannerSearchParams,
  filter: QueryStringFilter<BannerSearchField>
): BannerSearchParams {
  const searchParams: BannerSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'file':
      searchParams['file:ex'] = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
