import { Pipe, PipeTransform } from '@angular/core'
import { TranslationService } from './translation.service'

@Pipe({
  name: 'translate',
})
export class TranslationPipe implements PipeTransform {
  constructor(private translateService: TranslationService) {}

  transform(label: string, params?: Array<string | number>): string {
    return this.translateService.translate(label, params)
  }
}
