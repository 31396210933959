import { Pipe, PipeTransform } from '@angular/core'
import { Product } from '../product.model'
import { uniq } from 'lodash'

@Pipe({
  name: 'productLocations',
})
export class ProductLocationsPipe implements PipeTransform {
  transform(product?: Product): string[] | undefined {
    const locationIds: string[] = []
    product?.locations.map(function (loc) {
      if (loc._id) {
        locationIds.push(loc._id)
      }
    })
    product?.sites?.map(function (loc) {
      if (loc._id) {
        locationIds.push(loc._id)
      }
    })
    return locationIds.length == 0 ? undefined : uniq(locationIds)
  }
}
