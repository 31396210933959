import { Pipe, PipeTransform } from '@angular/core'
import {
  OrderChangeStatusAction,
  OrderPermission,
  OrderStatus,
} from '../order.model'
import { EntityStatusAction } from '../../../models/entity.model'
import { ORDER_ACTIONS } from '../order.const'

@Pipe({
  name: 'orderActions',
})
export class OrderActionsPipe implements PipeTransform {
  transform(
    orderStatus: OrderStatus,
    orderTransport?: string,
  ): {
    permissions: OrderPermission[]
    actions: EntityStatusAction<OrderChangeStatusAction>[]
  } {
    const actions = ORDER_ACTIONS
    if (orderStatus != OrderStatus.packed || orderTransport == 'CARRIER') {
      return actions[orderStatus]
    }
    // The SHIPPED status of orders means "the order was accepted by the carrier".
    // The Carrier manages an order only if order.header.transport === 'CARRIER'.
    // When an order arrives in PACKED status, it could only go to SHIPPED status
    // if the order is managed by a Carrier, otherwise the order should be placed
    // in DELIVERED status. For this reason, if the order is in PACKED status and
    // its order.header.transport is different than 'CARRIER', it needs to get the
    // actions available for the PROCESSED status.
    return actions[OrderStatus.processed]
  }
}
