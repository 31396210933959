import { defaultsDeep, cloneDeep } from 'lodash'
import { Role, RoleSearchField, RoleSearchParams } from '../role.model'
import { QueryStringFilter } from '../../../models/query-string.model'

// Initial states

const ROLE_INITIAL_STATE: Partial<Role> = {
  code: undefined,
  label: undefined,
}

/**
 * Initialize a partial role
 * @param role - the partial role
 * @returns the role updated
 */
export function initRole(role: Partial<Role> = {}): Role {
  return defaultsDeep(cloneDeep(role), ROLE_INITIAL_STATE)
}

/**
 * Parse role filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the role search params updated
 */
export function roleFilterParams(
  params: RoleSearchParams,
  filter: QueryStringFilter<RoleSearchField>,
): RoleSearchParams {
  const searchParams: RoleSearchParams = {}

  switch (filter.field) {
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      }
      break
    case 'label':
      if (filter.operator === 'contains') {
        searchParams['label:ct'] = filter.value
      } else if (filter.operator === '=') {
        searchParams['label'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['label:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
