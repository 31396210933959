import { Injectable } from '@angular/core'
import { WarehousesService } from '../warehouses'
import { SuppliersService } from '../suppliers'
import {
  SupplierOrder,
  SupplierOrderField,
  SupplierOrderSearchParams,
  SupplierOrdersListingData,
  SupplierOrdersListingPage,
} from './supplier-order.model'
import { Observable, combineLatest, map, of, switchMap } from 'rxjs'
import { SupplierOrdersService } from './supplier-orders.service'
import { Page } from '../../models/util.model'
import { parseSupplierOrderPageKeys } from './libs/supplier-order-search.lib'

@Injectable({
  providedIn: 'root',
})
export class SupplierOrdersRepository {
  constructor(
    private supplierOrdersService: SupplierOrdersService,
    private warehousesService: WarehousesService,
    private suppliersService: SuppliersService,
  ) {}

  /**
   * Search supplier orders and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search supplier orders
   */
  searchSupplierOrders$(
    searchParams: SupplierOrderSearchParams,
    fields: SupplierOrderField[],
    listingData?: SupplierOrdersListingData,
  ): Observable<SupplierOrdersListingPage> {
    return this.supplierOrdersService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Load external page supplier orders data
   * @param page - the supplier orders page
   * @param fields - the supplier orders fields
   * @returns the observable for load external data
   */
  loadExtData$(
    page: Page<SupplierOrder>,
    fields: SupplierOrderField[],
    listingData?: SupplierOrdersListingData,
  ): Observable<SupplierOrdersListingData> {
    const extDataKeys = parseSupplierOrderPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('warehouseId') && extDataKeys.warehouseIds) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (fields.includes('supplierId') && extDataKeys.supplierIds) {
      obs$['suppliers'] = this.suppliersService.store$(
        extDataKeys.supplierIds,
        listingData?.suppliers || [],
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }
}
