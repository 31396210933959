import moment from 'moment'
import { StatusHistory } from '../models/util.model'

export function statusTransactionTime(
  statusHistory: StatusHistory[],
  beginStatus: string,
  endStatus: string
): string | undefined {
  const statusFrom = statusHistory.find(
    (status) => status.status === beginStatus
  )
  const statusTo = statusHistory.find((status) => status.status === endStatus)

  if (!statusFrom || !statusTo) {
    return undefined
  }

  const duration = moment.utc(moment(statusTo.date).diff(statusFrom.date))

  return `${duration.format('m')} m ${duration.format('s')} s`
}
