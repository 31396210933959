import { HttpErrorResponse } from '@angular/common/http'
import {
  Order,
  OrderAdditionalPackage,
  OrderNotification,
  OrderPackage,
  OrderPackageType,
  OrderPackagesData,
} from '../order.model'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { OrdersRepository } from '../orders.repository'
import { Observable, map, switchMap, tap, withLatestFrom } from 'rxjs'
import {
  Product,
  ProductStatus,
  ProductType,
  ProductWrapper,
  getProductWrapperByBarcode,
} from '../../products'
import {
  addOrderAdditionalPackage,
  addOrderPackage,
  addOrderProductAdditionalPackage,
  addOrderProductPackage,
  addOrderWrapperAdditionalPackage,
  addOrderWrapperPackage,
  getOrderPackagesVolume,
  getOrderPackagesWeight,
  removeOrderAdditionalPackage,
  removeOrderPackage,
  updateOrderPackageVolume,
} from '../libs/order-packages.lib'
import { uniqBy } from 'lodash'
import { OrdersService } from '../orders.service'

interface OrderPackagesState {
  orderId?: string
  packages?: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]
  products?: Product[]
  error?: HttpErrorResponse
  notification?: OrderNotification
  packageType: OrderPackageType
  weightToPack: number
  isInitialized: boolean
  isLoading: boolean
  isModified: boolean
}

const ORDER_PACKAGES_INITIAL_STATE: OrderPackagesState = {
  packageType: 'package',
  weightToPack: 0,
  isInitialized: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class OrderPackagesUseCase extends ComponentStore<OrderPackagesState> {
  constructor(
    private ordersRepository: OrdersRepository,
    private ordersService: OrdersService,
  ) {
    super(ORDER_PACKAGES_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectPackageType$ = this.select((state) => state.packageType)

  readonly selectPackages$ = this.selectState$.pipe(
    map((state) => state.packages),
  )

  readonly selectAdditionalPackages$ = this.selectState$.pipe(
    map((state) => state.additionalPackages),
  )

  readonly selectProducts$ = this.selectState$.pipe(
    map((state) => state.products),
  )

  readonly selectVolume$ = this.selectPackages$.pipe(
    map((packages) => getOrderPackagesVolume(packages || [])),
  )

  readonly selectWeight$ = this.selectPackages$.pipe(
    map((packages) => getOrderPackagesWeight(packages || [])),
  )

  readonly selectWeightToPack$ = this.selectState$.pipe(
    map((state) => state.weightToPack),
  )

  readonly selectNotification$ = this.selectState$.pipe(
    map((state) => state.notification),
  )

  readonly selectIsLoading$ = this.selectState$.pipe(
    map((state) => state.isLoading),
  )

  readonly selectIsInitialized$ = this.selectState$.pipe(
    map((state) => state.isInitialized),
  )

  // Effects

  readonly load$ = this.effect((orderId$: Observable<string>) => {
    return orderId$.pipe(
      tap((orderId) => this.setOrderId(orderId)),
      withLatestFrom(this.selectProducts$),
      switchMap(([orderId, products]) => {
        return this.ordersService.read$(orderId).pipe(
          switchMap((order) =>
            this.ordersRepository.getOrderPackagesData$(order, products).pipe(
              tapResponse(
                (packagesData) => this.setPackagesData(packagesData),
                (error) => this.parseError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly set$ = this.effect((order$: Observable<Order>) => {
    return order$.pipe(
      tap((order) => this.setOrderId(order._id)),
      withLatestFrom(this.selectProducts$),
      switchMap(([order, products]) => {
        return this.ordersRepository
          .getOrderPackagesData$(order, products)
          .pipe(
            tapResponse(
              (packagesData) => this.setPackagesData(packagesData),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly searchPackage$ = this.effect((code$: Observable<string>) => {
    return code$.pipe(
      tap(() => this.setIsLoading(true)),
      switchMap((code) =>
        this.ordersRepository.getProductPackage$(code).pipe(
          tapResponse(
            (productPackage) => this.parseProduct(productPackage, code),
            (error) => this.parseError(error),
          ),
        ),
      ),
    )
  })

  // Reducers

  readonly setOrderId = this.updater(
    (state, orderId: string): OrderPackagesState => {
      return {
        orderId,
        packageType: 'package',
        weightToPack: 0,
        isLoading: true,
        isInitialized: false,
        isModified: false,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): OrderPackagesState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setPackagesData = this.updater(
    (state, packagesData: OrderPackagesData): OrderPackagesState => {
      return {
        ...state,
        packages: packagesData.packages,
        additionalPackages: packagesData.additionalPackages,
        products: packagesData.products,
        isLoading: false,
        isModified: false,
        isInitialized: true,
      }
    },
  )

  readonly addPackage = this.updater(
    (state, packData: { additional?: boolean }): OrderPackagesState => {
      return {
        ...state,
        packages: !packData.additional
          ? addOrderPackage(state.packages || [], {
              weight: state.weightToPack,
            })
          : state.packages,
        additionalPackages: packData.additional
          ? addOrderAdditionalPackage(state.additionalPackages || [], {
              weight: state.weightToPack,
            })
          : state.additionalPackages,
        weightToPack: 0,
        isModified: true,
      }
    },
  )

  readonly removePackage = this.updater(
    (
      state,
      packData: {
        additional?: boolean
        index?: number
      },
    ): OrderPackagesState => {
      const packs = packData.additional
        ? state.additionalPackages
        : state.packages
      if (!packs?.length) {
        return state
      }

      const indexToRemove =
        packData.index !== undefined ? packData.index : packs.length - 1
      const weightToRestore = packs[indexToRemove].weight

      return {
        ...state,
        packages: !packData.additional
          ? removeOrderPackage(state.packages!, indexToRemove)
          : state.packages,
        additionalPackages: packData.additional
          ? removeOrderAdditionalPackage(
              state.additionalPackages!,
              indexToRemove,
            )
          : state.additionalPackages,
        weightToPack: state.weightToPack + weightToRestore,
        isModified: true,
      }
    },
  )

  readonly addProductPackage = this.updater(
    (state, productPackage: Product): OrderPackagesState => {
      return {
        ...state,
        packages:
          state.packageType === 'package'
            ? addOrderProductPackage(
                state.packages || [],
                productPackage,
                state.weightToPack,
              )
            : state.packages,
        additionalPackages:
          state.packageType === 'additionalPackage'
            ? addOrderProductAdditionalPackage(
                state.additionalPackages || [],
                productPackage,
                state.weightToPack,
              )
            : state.additionalPackages,
        notification: { code: 'PACKAGE_ADDED' } as OrderNotification,
        weightToPack: 0,
        products: uniqBy([...(state.products || []), productPackage], '_id'),
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly addProductWrapper = this.updater(
    (state, wrapper: ProductWrapper): OrderPackagesState => {
      return {
        ...state,
        packages:
          state.packageType === 'package'
            ? addOrderWrapperPackage(
                state.packages || [],
                wrapper,
                state.weightToPack,
              )
            : state.packages,
        additionalPackages:
          state.packageType === 'additionalPackage'
            ? addOrderWrapperAdditionalPackage(
                state.additionalPackages || [],
                wrapper,
                state.weightToPack,
              )
            : state.additionalPackages,
        notification: { code: 'PACKAGE_ADDED' } as OrderNotification,
        weightToPack: 0,
        isModified: true,
        isLoading: false,
      }
    },
  )

  readonly updatePackage = this.updater(
    (
      state,
      packData: {
        package: OrderPackage | OrderAdditionalPackage
        additional: boolean
        index: number
      },
    ): OrderPackagesState => {
      return {
        ...state,
        packages: !packData.additional
          ? state.packages?.map((p, i) =>
              i === packData.index
                ? updateOrderPackageVolume(packData.package)
                : p,
            )
          : state.packages,
        additionalPackages: packData.additional
          ? state.additionalPackages?.map((p, i) =>
              i === packData.index
                ? updateOrderPackageVolume(
                    packData.package as OrderAdditionalPackage,
                  )
                : p,
            )
          : state.additionalPackages,
        isModified: true,
      }
    },
  )

  readonly setPackageType = this.updater(
    (state, packageType: OrderPackageType): OrderPackagesState => {
      return {
        ...state,
        packageType,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): OrderPackagesState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setNotification = this.updater(
    (state, notification: OrderNotification): OrderPackagesState => {
      return {
        ...state,
        notification,
        isLoading: false,
      }
    },
  )

  readonly reset = this.updater(
    (state): OrderPackagesState => ORDER_PACKAGES_INITIAL_STATE,
  )

  // Utilities

  private parseError(errorResponse: unknown) {
    if (errorResponse instanceof HttpErrorResponse) {
      this.setError(errorResponse)
    } else {
      this.setNotification(errorResponse as OrderNotification)
    }
  }

  private parseProduct(productPackage: Product, code: string) {
    // Check wrapper
    const wrapper = getProductWrapperByBarcode(productPackage, code)
    if (wrapper?.isSelfShipping) {
      this.addProductWrapper(wrapper)
      return
    }

    // Check type
    if (productPackage.productType !== ProductType.package) {
      this.setNotification({
        code: 'PACKAGE_NOT_AVAILABLE',
      } as OrderNotification)
      return
    }

    // Check status
    if (productPackage.status === ProductStatus.disabled) {
      this.setNotification({ code: 'PACKAGE_DISABLED' } as OrderNotification)
      return
    }

    this.addProductPackage(productPackage)
  }
}
