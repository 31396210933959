import {
  PickingList,
  PickingListMissionOrder,
  PickingListMissionStatus,
  PickingListOrderStatus,
} from '../picking-list.model'

/**
 * Get the quantity of completed orders of a picking list
 * @param pickingList - The picking list
 * @returns the quantity of completed orders
 */
export function getPickingOrdersCompleted(pickingList: PickingList): number {
  return pickingList.orders.filter((o) =>
    checkPickingOrderPicked(pickingList, o._id)
  ).length
}

/**
 * Check if a order inside a picking list has been picked
 * @param pickingList - The picking list
 * @param orderId - The order id to check
 * @returns a boolean that indicates if the order has been picked
 */
export function checkPickingOrderPicked(
  pickingList: PickingList,
  orderId: string
): boolean {
  const orderMissions = pickingList.missions.filter((m) =>
    m.orders.map((o) => o._id).includes(orderId)
  )
  return orderMissions.every((m) =>
    [
      PickingListMissionStatus.picked,
      PickingListMissionStatus.canceled,
    ].includes(m.status)
  )
}

/**
 * Get the picking missions of an order
 * @param pickingList - The picking list
 * @param orderId - The order id
 * @returns the picking mission order
 */
export function getPickingOrderMissions(
  pickingList: PickingList,
  orderId: string
): PickingListMissionOrder[] {
  return pickingList.missions.reduce<PickingListMissionOrder[]>(
    (acc, mission) => {
      const orderMission = mission.orders.find((m) => m._id === orderId)

      if (orderMission) {
        acc.push(orderMission)
      }

      return acc
    },
    []
  )
}

/**
 * Get the order status of the picking list
 * @param pickingList - The picking list
 * @param orderId - The order id
 * @returns the status of the order
 */
export function getPickingOrderStatus(
  pickingList: PickingList,
  orderId: string
): PickingListOrderStatus {
  const isPicked = checkPickingOrderPicked(pickingList, orderId)
  return isPicked
    ? PickingListOrderStatus.picked
    : PickingListOrderStatus.processing
}

/**
 * Get the order quantity to pick of a picking list
 * @param pickingList - The picking list
 * @param orderId - The order id
 * @returns the quantity of items to pick of an order of the picking list
 */
export function getPickingOrderQtyToPick(
  pickingList: PickingList,
  orderId: string
): number {
  const orderMissions = getPickingOrderMissions(pickingList, orderId)
  return orderMissions.reduce((total, mission) => total + mission.qtyToPick, 0)
}

/**
 * Get the order quantity picked of a picking list
 * @param pickingList - The picking list
 * @param orderId - The order id
 * @returns the quantity of items picked of an order of the picking list
 */
export function getPickingOrderPickedQty(
  pickingList: PickingList,
  orderId: string
): number {
  const orderMissions = getPickingOrderMissions(pickingList, orderId)
  return orderMissions.reduce((total, mission) => total + mission.qtyPicked, 0)
}
