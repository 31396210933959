import { Field } from '../../../models/field.model'
import { AttributeField } from '../../attributes/attribute.model'
import { PRODUCT_FIELDS } from '../product.const'
import { ProductField, ProductViewType } from '../product.model'

export function productFieldToField(field: Field): AttributeField {
  return {
    name: field.label,
    field: field.fileField || field.field,
    methods: field.fileMethods,
    valuePerCatalog: field.valuePerCatalog,
    isLocalizable: field.isLocalizable,
  }
}

export function productFieldsToFields(fields: Field[]): AttributeField[] {
  return fields.map((f) => productFieldToField(f))
}

export function getProductViewFields(
  viewType: ProductViewType
): ProductField[] {
  return PRODUCT_FIELDS.filter((f) => f.defaultViews?.includes(viewType)).map(
    (f) => f.field
  )
}
