import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { BaseService } from '../../services/base.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { SaleSearchResponse, SalesSearchParams } from './sale.model'
import { Page } from '../../models/util.model'

const MODEL = 'sales'
const VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class SalesService extends BaseService {
  protected override model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(http, `${config.apiUrl}/${SDK_SETTINGS.apiPath}`, VERSION)
  }

  /**
   * Search sales by barams
   * @param params - the search params
   * @returns the observable<Page<SaleSearchResponse>> for search sales
   */
  searchPeriods$(
    params: SalesSearchParams,
  ): Observable<Page<SaleSearchResponse>> {
    return this.http.post<Page<SaleSearchResponse>>(
      `${this.path}/${this.version}/${this.model}/search-periods`,
      params,
    )
  }
}
