import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import {
  ReplenishmentField,
  ReplenishmentPriority,
  ReplenishmentStatus,
} from './replenishment.model'

export const REPLENISHMENT_FRAGMENT = 'replenishment'
export const REPLENISHMENT_SCOPE: PermissionsScope = 'replenishments'

export const REPLENISHMENT_DEFAULT_FIELDS: ReplenishmentField[] = [
  'replenishmentNumber',
  'createdAt',
  'movedQty',
  'priority',
  'product.SKU',
  'product._id',
  'warehouseId',
  'source._id',
  'target._id',
  'status',
  'requiredQty',
  'movedQty',
  'userId',
  'priority',
]

export const REPLENISHMENT_STATUSES: EntityStatusDesc<ReplenishmentStatus>[] = [
  {
    code: 'PENDING',
    name: 'In attesa',
    primary: true,
    tooltip: "Trasferimento in attesa dell'operatore.",
  },
  {
    code: 'PROCESSING',
    name: 'In lavorazione',
    primary: true,
    tooltip: "Trasferimento in lavorazione da parte dell'operatore.",
  },
  {
    code: 'COMPLETED',
    name: 'Completato',
    primary: true,
    tooltip: "Trasferimento completato da parte dell'operatore.",
  },
  {
    code: 'CANCELED',
    name: 'Annullato',
    primary: false,
    tooltip: 'Trasferimento annullato.',
  },
]

export const REPLENISHMENT_PRIORITIES: SelectionData<ReplenishmentPriority>[] =
  [
    {
      label: 'Alta',
      value: 70,
    },
    {
      label: 'Media',
      value: 40,
    },
    {
      label: 'Bassa',
      value: 20,
    },
  ]
