import { Directive, HostListener, Input } from '@angular/core'

// Services
import { AdviceService } from '../services/advice.service'

@Directive({
  selector: '[opAdvice]',
})
export class AdviceDirective {
  // eslint-disable-next-line
  @Input('opAdvice') advice?: string

  constructor(private adviceService?: AdviceService) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.adviceService) {
      this.adviceService.advice = this.advice
    }
  }

  @HostListener('mouseleave')
  @HostListener('click')
  onMouseLeave() {
    this.adviceService?.resetAdvice()
  }
}
