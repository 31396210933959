import { cloneDeep, defaultsDeep } from 'lodash'
import { OrderRow, OrderRowProduct } from '../order.model'
import { getCatalogValue } from '../../catalogs'
import {
  Product,
  ProductSelection,
  ProductType,
  getProductSellingPrice,
  initProduct,
} from '../../products'
import {
  Percentages,
  Prices,
  calculatePercentage,
  calculatePrices,
} from '../../../libs/dinero.lib'

/**
 * Order row initial state
 */
const ORDER_ROW_INITIAL_STATE: Partial<OrderRow> = {
  orderedQty: 1,
  shippedQty: 0,
  unitPrice: 0,
  unitPriceWithTaxes: 0,
  productTaxRate: 0,
  discount: 0,
  discountWithTaxes: 0,
  totalTaxes: 0,
  totalAmount: 0,
  totalAmountWithTaxes: 0,
}

/**
 * Initialize an order row
 * @param row - the partial row
 * @returns the row updated
 */
export function initOrderRow(row: Partial<OrderRow> = {}): OrderRow {
  return defaultsDeep(cloneDeep(row), ORDER_ROW_INITIAL_STATE)
}

/**
 * Initialize an order row product
 * @param product - the product
 * @returns the order row product updated
 */
export function initOrderRowProduct(product: Product): OrderRowProduct {
  let productRow: OrderRowProduct = {
    _id: product._id!,
    SKU: product.SKU,
    name: getCatalogValue<string>(product.name),
    productType: 'SIMPLE',
  }

  if (product.productType === ProductType.kit) {
    productRow = {
      ...productRow,
      productType: 'KIT',
      simpleProducts:
        product.kitProducts?.map((p) => ({
          _id: p._id,
          SKU: p.SKU,
          quantity: p.quantity,
        })) || [],
    }
  } else if (product.productType === ProductType.service) {
    productRow = {
      ...productRow,
      productType: 'SERVICE',
    }
  }

  return productRow
}

/**
 * Create order rows from products selection
 * @param products - the products selection
 * @returns the order rows
 */
export function createOrderRows(products: ProductSelection[]): OrderRow[] {
  return products.map((p) => createOrderRow(p.product, p.qty))
}

/**
 * Create an order row by product and quantity
 * @param product - the product
 * @param qty - the ordered quantity
 * @param taxRate - the tax rate flag
 * @returns the order row
 */
export function createOrderRow(
  product: Product,
  qty: number | undefined,
  taxRate?: number
): OrderRow {
  const _product = initProduct(product)

  const orderedQty = qty || 1
  const productTaxRate = taxRate
    ? taxRate
    : _product.taxRate
    ? _product.taxRate
    : 0
  const unitPriceWithTaxes = getProductSellingPrice(_product)
  const unitPrice = unitPriceWithTaxes / (productTaxRate / 100 + 1)

  return initOrderRow({
    product: initOrderRowProduct(_product),
    orderedQty,
    productTaxRate,
    unitPriceWithTaxes,
    unitPrice,
  })
}

/**
 * Apply a discount to an order row
 * @param row - the row
 * @param discountToApply - the discount to apply
 * @param priceTaxes - the price taxes flag
 * @param discountWithPercentage - the discount percentage flag
 * @returns the row updated
 */
export function applyOrderRowDiscount(
  row: OrderRow,
  discountToApply: number,
  priceTaxes = false,
  discountWithPercentage = false
): OrderRow {
  const discount =
    !priceTaxes && !discountWithPercentage ? discountToApply : row.discount
  const discountWithTaxes =
    priceTaxes && !discountWithPercentage
      ? discountToApply
      : row.discountWithTaxes
  const discountPercentage = discountWithPercentage
    ? discountToApply
    : row.discountPercentage

  return updateOrderRowAmounts(
    {
      ...row,
      discount,
      discountWithTaxes,
      discountPercentage,
    },
    priceTaxes,
    discountWithPercentage
  )
}

/**
 * Update order row amounts
 * @param row - the row to update
 * @param priceTaxes - the price taxes flag
 * @param discountWithPercentage - the discount percentage flag
 * @returns the order row updated
 */
export function updateOrderRowAmounts(
  row: OrderRow,
  priceTaxes = false,
  discountWithPercentage = false
): OrderRow {
  // Check ordered and taxRate
  const taxRate = !row.productTaxRate ? 0 : +row.productTaxRate
  const orderedQty =
    !row.orderedQty || row.orderedQty <= 0 ? 1 : +row.orderedQty

  const productPrices = calculatePrices({
    taxRate,
    price: priceTaxes ? undefined : row.unitPrice,
    total: priceTaxes ? row.unitPriceWithTaxes : undefined,
  })

  let discountPercentages: Percentages
  let discountPrices: Prices
  if (row.discountPercentage === undefined || !discountWithPercentage) {
    discountPrices = calculatePrices({
      taxRate,
      price: priceTaxes ? undefined : row.discount,
      total: priceTaxes ? row.discountWithTaxes : undefined,
    })
    discountPercentages = calculatePercentage({
      total: productPrices.total.multiply(orderedQty).toUnit(),
      value: discountPrices.total.toUnit(),
    })
  } else {
    discountPercentages = calculatePercentage({
      total: productPrices.total.multiply(orderedQty).toUnit(),
      percentage: row.discountPercentage,
    })
    discountPrices = calculatePrices({
      taxRate,
      total: discountPercentages.value.toUnit(),
    })
  }

  const totalAmount = productPrices.price
    .multiply(orderedQty)
    .subtract(discountPrices.price)

  const totalTaxes = productPrices.taxes
    .multiply(orderedQty)
    .subtract(discountPrices.taxes)

  const totalAmountWithTaxes = productPrices.total
    .multiply(orderedQty)
    .subtract(discountPrices.total)

  return {
    ...row,
    productTaxRate: taxRate,
    orderedQty,
    unitPrice: productPrices.price.toRoundedUnit(4),
    unitPriceWithTaxes: productPrices.total.toRoundedUnit(4),
    discount: discountPrices.price.toRoundedUnit(4),
    discountPercentage: discountPercentages.percentage.toRoundedUnit(4),
    discountWithTaxes: discountPrices.total.toRoundedUnit(4),
    totalTaxes: totalTaxes.toRoundedUnit(4),
    totalAmount: totalAmount.toRoundedUnit(4),
    totalAmountWithTaxes: totalAmountWithTaxes.toRoundedUnit(4),
  }
}

/**
 * Add rows to an order
 * @param rows - the order rows
 * @param _rows - the rows to add
 * @returns the order rows updated
 */
export function addOrderRows(rows: OrderRow[], _rows: OrderRow[]): OrderRow[] {
  const newRows = [...rows]

  _rows.forEach((row) => {
    if (!row._id) {
      newRows.push(row)
      return
    }

    const currentRowIndex = newRows.findIndex((r) => r._id === row._id)

    if (currentRowIndex >= 0) {
      newRows[currentRowIndex] = mergeOrderRows(newRows[currentRowIndex], row)
    } else {
      newRows.push(row)
    }
  })

  return newRows
}

/**
 * Merge rows from two orders
 * @param firstRow - the first row
 * @param secondRow - the second row
 * @returns the row updated
 */
export function mergeOrderRows(
  firstRow: OrderRow,
  secondRow: OrderRow
): OrderRow {
  return updateOrderRowAmounts({
    ...firstRow,
    orderedQty: firstRow.orderedQty + secondRow.orderedQty,
    discount: firstRow.discount + secondRow.discount,
  })
}

/**
 * Decrement a row since it's necessary to remove it.
 * @param rows - the order rows
 * @param rowId - the row ID
 * @param qty - the qty
 * @returns the rows updated
 */
export function decOrderRows(
  rows: OrderRow[],
  rowId: string,
  qty: number
): OrderRow[] {
  const row = rows.find((_row) => _row._id === rowId)
  return row!.orderedQty === qty
    ? removeOrderRow(rows, rowId)
    : [
        ...rows.map((_row) =>
          _row._id === rowId
            ? updateOrderRowQty(_row, _row.orderedQty - qty)
            : _row
        ),
      ]
}

/**
 * Remove order row
 * @param rows - the order rows
 * @param rowId - the order row ID to remove
 * @returns the rows updated
 */
export function removeOrderRow(rows: OrderRow[], rowId: string): OrderRow[] {
  return rows.filter((_row) => _row._id !== rowId)
}

/**
 * Update order row quantity
 * @param row - the row to update
 * @param qty - the ordered qty to apply
 * @returns the row updated
 */
export function updateOrderRowQty(row: OrderRow, qty: number): OrderRow {
  const orderedQty = qty
  const discount = (row.discount / row.orderedQty) * orderedQty

  return {
    ...row,
    orderedQty,
    discount,
  }
}

/**
 * Count order rows products
 * @param rows - the order rows
 * @returns the amount of ordered products
 */
export function countOrderRowsProducts(rows: OrderRow[]): number {
  return rows.reduce((acc, row) => acc + row.orderedQty, 0)
}

/**
 * Count order rows simple products
 * @param rows - the order rows
 * @returns the amount of ordered simple products
 */
export function countOrderRowsSimpleProducts(rows: OrderRow[]): number {
  return rows.reduce((count, row) => {
    const productsAmout =
      row.product.productType !== ProductType.kit
        ? row.orderedQty
        : row.product.simpleProducts?.reduce(
            (kitCount, simpleProduct) =>
              kitCount + simpleProduct.quantity * row.orderedQty,
            0
          ) || 0
    return count + productsAmout
  }, 0)
}
