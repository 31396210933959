/**
 * Normalize a string with non-latin characters
 * E.g. 'yóu arè beåutiful' > 'you-are-beautiful'
 * @param txt - The string to normalize
 * @param trim - The trim flag
 * @param replacer - The space replacer
 * @returns the string normalized
 */
export function slugify(txt: string, trim = true, replacer = '_'): string {
  let text = trim ? txt.replace(/^\s+|\s+$/g, '') : txt // trim

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/-,:;'
  const to = 'aaaaaaeeeeiiiioooouuuunc______'

  for (let i = 0, l = from.length; i < l; i++) {
    text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  text = text
    .replace(/[^(a-zA-Z0-9)]/g, replacer) // remove invalid chars
    .replace(/\s+/g, replacer) // collapse whitespace and replace by replacer

  return text
}

/**
 * Pad a number or a string with a placeholder
 * @param txt - The string or number to pad
 * @param length - The lenght of total padding
 * @param placeholder - The character to use as placeholder
 * @returns the string padded
 */
export function pad(
  txt: string | number,
  length = 2,
  placeholder = '0'
): string {
  let s = String(txt)
  while (s.length < length) {
    s = placeholder + s
  }
  return s
}

/**
 * Transform a hex color to rgb values
 * @param hex - The hex color
 * @returns the rgb values
 */
export function hexToRgb(
  hex: string
): { r: number; g: number; b: number } | undefined {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    return undefined
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
}

/**
 * Check if a hex color is "light" or "dark" for human
 * @param hex - The hex color
 * @returns the check if the color is clear
 */
export function isLightColor(hex: string): boolean {
  const color = hexToRgb(hex)
  if (!color) {
    return false
  }

  const contrast =
    1 - (0.299 * color.r + 0.587 * color.g + 0.114 * color.b) / 255
  return contrast < 0.5
}
