import { defaultsDeep, cloneDeep, uniq } from 'lodash'
import {
  User,
  UserField,
  UserSearchParams,
  UserSessionType,
  UserTenantSettings,
  UserType,
  UsersListingKeys,
  UsersListingPage,
} from '../user.model'
import { QueryStringFilter } from '../../../models/query-string.model'
import { DeepPartial, Page } from '../../../models/util.model'

/**
 * The user initial state
 */
const USER_INITIAL_STATE: DeepPartial<User> = {
  type: UserType.human,
  settings: {
    pickingLists: {},
    goodsReceives: {},
  },
}

const TENANT_SETTINGS_INITIAL_STATE: UserTenantSettings = {
  printers: {},
}

/**
 * Initialize a partial user
 * @param user- the partial user
 * @returns the user updated
 */
export function initUser(user: Partial<User> = {}): User {
  return defaultsDeep(cloneDeep(user), USER_INITIAL_STATE)
}

/**
 * Initialize user tenant settings
 * @param settings - the current tenant settings
 * @returns the settings updated
 */
export function initUserTenantSettings(
  settings: Partial<UserTenantSettings> = {},
): UserTenantSettings {
  return defaultsDeep(cloneDeep(settings), TENANT_SETTINGS_INITIAL_STATE)
}

/**
 * Get user tenant settings by tenant ID
 * @param user - the user
 * @param tenantId - the tenant ID
 * @returns the user tenant settings
 */
export function getUserTenantSettings(
  user: User,
  tenantId: string,
): UserTenantSettings | undefined {
  return user.tenants?.find((t) => t._id === tenantId)?.settings
}

/**
 * Set user tenant settings
 * @param user - the current user
 * @param tenantId - the tenant ID
 * @param settings - the settings to update
 * @returns the user updated
 */
export function setUserTenantSettings(
  user: User,
  tenantId: string,
  settings: UserTenantSettings,
): User {
  const userTenant = user.tenants?.find((t) => t._id === tenantId)
  if (!userTenant) {
    return user
  }

  return {
    ...user,
    tenants: user.tenants?.map((t) =>
      t._id === tenantId
        ? {
            ...t,
            settings,
          }
        : t,
    ),
  }
}

/**
 * Return a boolean that indicates if a session type is allowed
 * @param user - the user
 * @param sessionType - the session type
 * @returns true if a session type is allowed otherwise false
 */
export function checkUserSessionAllowed(
  user: User,
  sessionType: UserSessionType,
): boolean {
  return !!user.allowedSessions?.includes(sessionType)
}

/**
 * Parse user sessions allowed into a dictionary
 * @param user - the user
 * @returns the dictionary with the session allowed
 */
export function parseUserSessionsAllowed(user: User): {
  [sessionType: string]: boolean
} {
  if (!user.allowedSessions) {
    return {}
  }

  return user.allowedSessions.reduce(
    (acc, s) => ({
      ...acc,
      [s]: true,
    }),
    {},
  )
}

/**
 * Enable/disable a session type
 * @param user - the use
 * @param sessionType - the session type
 * @param active - the activation boolean
 * @returns the user updated
 */
export function toggleUserSessionAllowed(
  user: User,
  sessionType: UserSessionType,
  active: boolean,
): User {
  let allowedSessions = user.allowedSessions || []
  allowedSessions = active
    ? [...allowedSessions, sessionType]
    : allowedSessions.filter((s) => s !== sessionType)

  return {
    ...user,
    allowedSessions: allowedSessions.length ? allowedSessions : undefined,
  }
}

/**
 * Parse user filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the user search params updated
 */
export function userFilterParams(
  params: UserSearchParams,
  filter: QueryStringFilter<UserField>,
): UserSearchParams {
  const searchParams: UserSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.value !== null) {
        searchParams._id = filter.value
      }
      break
    case 'type':
      if (filter.operator === '=') {
        searchParams.type = filter.value
      }
      break
    case 'username':
      if (filter.operator === '=') {
        searchParams.username = filter.value
      } else if (filter.operator === '<>') {
        searchParams['username:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['username:ct'] = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'surname':
      if (filter.operator === '=') {
        searchParams.surname = filter.value
      } else if (filter.operator === '<>') {
        searchParams['surname:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['surname:ct'] = filter.value
      }
      break
    case 'email':
      if (filter.operator === '=') {
        searchParams.email = filter.value
      } else if (filter.operator === '<>') {
        searchParams['email:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['email:ct'] = filter.value
      }
      break
    case 'tenants.settings.warehouseId':
      if (filter.value !== null) {
        searchParams['tenants.settings.warehouseId'] = filter.value
      } else {
        searchParams['tenants.settings.warehouseId:ex'] = false
      }
      break
    case 'tenants.settings.pickupPointId':
      if (filter.value !== null) {
        searchParams['tenants.settings.pickupPointId'] = filter.value
      } else {
        searchParams['tenants.settings.pickupPointId:ex'] = false
      }
      break
    case 'roles._id':
      if (filter.value !== null) {
        searchParams['roles._id'] = filter.value
      } else {
        searchParams['roles._id:ex'] = false
      }
      break
    case 'isDisabled':
      searchParams.isDisabled = filter.value
      break
    case 'isGuest':
      searchParams.isGuest = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse user name
 * @param user - the user to parse
 * @returns the user name expanded
 */
export function parseUserName(user: User): string {
  return `${user.surname} ${user.name}`
}

/**
 * Parse user page keys
 * @param page - the users page
 * @returns the page updated
 */
export function parseUsersPageKeys(
  page: Page<User>,
  tenantId: string,
): UsersListingKeys {
  return page.data.reduce<UsersListingKeys>(
    (acc, r) => parseUserKeys(r, acc, tenantId),
    {},
  )
}

/**
 * Parse user external data
 * @param user - the replenishment
 * @param extData - the external data object
 * @returns the extData updated
 */
export function parseUserKeys(
  user: User,
  extData: UsersListingKeys = {},
  tenantId: string,
): UsersListingKeys {
  const userRolesIds = user.roles
    ?.filter((r) => r.tenantId === tenantId)
    .map((r) => r._id)
  const roleIds = uniq([...(extData['roleIds'] || []), ...(userRolesIds || [])])

  return {
    roleIds,
  }
}

/**
 * Parse users page with relative fields
 * @param usersPage - the users listing page
 * @param tenantId - the tenant ID
 * @returns the users page parsed
 */
export function parseUsersPage(
  usersPage: UsersListingPage,
  fields: UserField[],
  tenantId?: string,
): UsersListingPage {
  if (
    !tenantId ||
    !(fields.includes('isGuest') && fields.includes('isDisabled'))
  ) {
    return usersPage
  }

  return {
    ...usersPage,
    data: usersPage.data?.map((u) => ({
      ...u,
      isGuest: u.tenantId !== tenantId,
      isDisabled: !!u.isDisabled,
    })),
  }
}
