import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { Locales } from '../../models/util.model'

export enum AttributeKind {
  text = 'TEXT',
  number = 'NUMBER',
  date = 'DATE',
  textarea = 'TEXTAREA',
  price = 'PRICE',
  boolean = 'BOOLEAN',
  simpleSelect = 'SIMPLE-SELECT',
  multiSelect = 'MULTI-SELECT',
}

export interface Attribute extends Entity {
  code: string
  name: Locales
  kind: AttributeKind
  attributesGroupCode: string
  isUnique?: boolean
  isSearchable?: boolean
  htmlEditor?: boolean
  valuePerCatalog?: boolean
  isLocalizable?: boolean
  locales: string[]
  schema: AttributeSchema
  options: AttributeOption[]
}

export interface AttributeSchema {
  type?: 'number' | 'integer'
  maxLength?: number
  maximum?: number
  minLength?: number
  minimum?: number
  pattern?: string
}

export interface AttributeOption {
  value: string
  label: Locales
}

export interface AttributeConfiguration {
  isUnique: boolean
  isSearchable: boolean
  isLocalizable: boolean
  valuePerCatalog: boolean
  min: boolean
  max: boolean
  minLenght: boolean
  maxLenght: boolean
  options: boolean
}

export interface AttributeField {
  field: string
  name: string
  attribute?: Attribute
  valuePerCatalog?: boolean
  isLocalizable?: boolean
  methods?: string[]
}

export interface AttributeFilter {
  code: 'ALL' | 'EMPTY' | 'MANDATORYEMPTY'
  name: string
}

export type AttributeSearchField =
  | '_id'
  | 'code'
  | 'name'
  | 'kind'
  | 'attributesGroupCode'

export type AttributeSortField = 'code' | 'name'

export interface AttributeSearchParams
  extends QueryStringParams<AttributeSortField> {
  code?: string | string[]
  'code:ne'?: string
  'code:ct'?: string
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  kind?: AttributeKind | AttributeKind[]
  attributesGroupCode?: string[] | string
  'attributesGroupCode:ex'?: boolean
  locale?: string
}

export type AttributeTab = 'general' | 'settings' | 'options'
