import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  LocationModel,
  LocationModelSearchParams,
} from './location-model.model'
import { Page } from '../../models/util.model'

const MODEL = 'location-models'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class LocationModelsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a location model by ID
   * @param modelId - The location model ID
   * @returns The observable<LocationModel> for read the location
   */
  read$(modelId: string): Observable<LocationModel> {
    return this._read$<LocationModel>(modelId)
  }

  /**
   * Update a location model by ID
   * @param modelId - The location model ID
   * @param model - The location model body to update
   * @returns The observable<LocationModel> for update the model
   */
  update$(modelId: string, model: LocationModel): Observable<LocationModel> {
    return this._update$<LocationModel>(modelId, model)
  }

  /**
   * Create or update a location by ID
   * @param modelId - The location model ID
   * @param model - The location model body to update
   * @returns The observable<LocationModel> for update the model
   */
  upsert$(model: LocationModel): Observable<LocationModel> {
    return this._upsert$<LocationModel>(model, model._id)
  }

  /**
   * Search models by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<LocationModel>> for search models
   */
  search$(
    params?: LocationModelSearchParams,
    returnAll = false,
  ): Observable<Page<LocationModel>> {
    return this._search$<LocationModel>(params, returnAll)
  }

  /**
   * List models by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<LocationModel[]> for list models
   */
  list$(
    params?: LocationModelSearchParams,
    returnAll = false,
  ): Observable<LocationModel[]> {
    return this._list$<LocationModel>(params, returnAll)
  }

  /**
   * Delete model by ID
   * @param modelId - the model ID
   * @returns the observable<LocationModel> for delete model
   */
  delete$(modelId: string): Observable<LocationModel> {
    return this._delete$<LocationModel>(modelId)
  }
}
