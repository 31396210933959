import { Product } from '../product.model'

export function getProductChannelIds(product: Product): string[] {
  if (!product.externalSKUs?.length) {
    return []
  }

  return product.externalSKUs
    .filter((c) => !!c.channelId)
    .map((c) => c.channelId!)
}
