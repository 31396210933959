import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { Country, CountrySearchParams } from './country.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'countries'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a country by ID
   * @param countryId - The country ID
   * @returns The observable<Country> for read the country
   */
  read$(countryId: string): Observable<Country> {
    return this._read$<Country>(countryId)
  }

  /**
   * Find a country by params
   * @param params - The search params
   * @returns the observable<Country> for find a country
   */
  readOne$(params?: CountrySearchParams): Observable<Country> {
    return this._readOne$<Country>(params)
  }

  /**
   * Search countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Country>> for search countries
   */
  search$(
    params?: CountrySearchParams,
    returnAll = false,
  ): Observable<Page<Country>> {
    return this._search$<Country>(params, returnAll)
  }

  /**
   * List countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Country[]> for list countries
   */
  list$(
    params?: CountrySearchParams,
    returnAll = false,
  ): Observable<Country[]> {
    return this._list$<Country>(params, returnAll)
  }

  /**
   * Generate countries store
   * @param countryKeys - the country keys to load
   * @param countries - the countries already loaded
   * @returns the Observable<Country[]> as store
   */
  store$(
    countryKeys: string[],
    countries: Country[],
    key: '_id' | 'alpha2Code' = '_id',
  ): Observable<Country[]> {
    countryKeys = uniq(countryKeys)
    countryKeys = difference(
      countryKeys,
      countries.map((u) => u[key]),
    )

    if (countryKeys.length === 0) {
      return of(countries)
    }

    return this.list$({ [key]: countryKeys }).pipe(
      map((ctrs) => [...countries, ...ctrs]),
    )
  }
}
