import { cloneDeep, defaultsDeep } from 'lodash'
import { uniq } from 'lodash'
import { getPickingMissionTotes } from './missions.lib'
import {
  PickingList,
  PickingListLocationPicked,
  PickingListOrder,
  PickingListStatus,
  PickingListType,
} from '../picking-list.model'
import { Order, OrderStatus } from '../../orders/order.model'

// Initial states

const PICKING_LIST_INITIAL_STATE: Partial<PickingList> = {
  name: undefined,
  status: PickingListStatus.pending,
  statusHistory: [],
  type: PickingListType.sort,
  orders: [],
  totes: [],
  missions: [],
  ordersCount: 0,
  itemsCount: 0,
}

/**
 * Initialize the picking list with initial values
 * @param pickingList - Partial values of a picking list
 * @returns the picking list
 */
export function initPickingList(
  pickingList: Partial<PickingList> = {},
): PickingList {
  return defaultsDeep(cloneDeep(pickingList), PICKING_LIST_INITIAL_STATE)
}

/**
 * Set picking list defaults values
 * @param pickingList - The picking list
 * @param defaults - The default values
 * @returns the picking list updated
 */
export function setPickingDefaults(
  pickingList: PickingList,
  defaults: Partial<PickingList>,
): PickingList {
  return defaultsDeep(cloneDeep(pickingList), defaults)
}

/**
 * Update picking counters
 * @param pickingList - The picking list to update
 * @returns the picking list updated
 */
export function updatePickingCounters(pickingList: PickingList): PickingList {
  return {
    ...pickingList,
    ordersCount: pickingList.orders.length,
    itemsCount: pickingList.orders.reduce(
      (total, order) =>
        total +
        order.rows.reduce((totalRow, row) => totalRow + row.orderedQty, 0),
      0,
    ),
    estimatedVolume: pickingList.orders.reduce(
      (total, order) => total + (order.estimatedVolume || 0),
      0,
    ),
    estimatedWeight: pickingList.orders.reduce(
      (total, order) => total + (order.estimatedWeight || 0),
      0,
    ),
  }
}

/**
 * Check if there are duplicated orders inside the picking list
 * @param pickingList - The picking list to check
 * @returns the boolean check of duplicated orders inside the picking list
 */
export function checkPickingDuplicatedOrders(
  pickingList: PickingList,
): boolean {
  const rifOrders = pickingList.orders
    .map((ord) => ord.header.rifOrder)
    .filter((ord) => ord && ord !== '')
  return rifOrders.length !== uniq(rifOrders).length
}

/**
 * Check if a picking list have too many orders
 * @param pickingList - The picking list to check
 * @returns a boolean that indicates if there are too many orders inside the picking list
 */
export function checkPickingTooManyOrders(pickingList: PickingList): boolean {
  return (
    pickingList.type === PickingListType.pack && pickingList.orders.length > 10
  )
}

/**
 * Get the empty order inside a picking list
 * @param pickingList - The picking list to check
 * @returns the picking list order without rows
 */
export function getPickingEmptyOrder(
  pickingList: PickingList,
): PickingListOrder | undefined {
  return pickingList.orders.find((ord) => !ord.rows || !ord.rows.length)
}

/**
 * Get the picking list quantity to pick
 * @param pickingList - The picking list
 * @returns the quantity of items to pick of a picking list
 */
export function getPickingQtyToPick(pickingList: PickingList): number {
  return pickingList.missions.reduce(
    (total, mission) => total + mission.qtyToPick,
    0,
  )
}

/**
 * Get the picking list quantity picked
 * @param pickingList - The picking list
 * @returns the quantity of items picked of a picking list
 */
export function getPickingPickedQty(pickingList: PickingList): number {
  return pickingList.missions.reduce(
    (total, mission) => total + mission.qtyPicked,
    0,
  )
}

/**
 * Check if a picking list is partially picked
 * @param pickingList - The picking list
 * @returns a boolean that indicates if a picking list is partially picked
 */
export function checkPickingPartial(pickingList: PickingList): boolean {
  return getPickingQtyToPick(pickingList) !== pickingList.itemsCount
}

/**
 * Get user ids that have created or modified the picking list
 * @param pickingList - The picking list
 * @returns a list of user ids
 */
export function getPickingUserIds(pickingList: PickingList): string[] {
  const userIds: string[] = []

  // Creation & update
  if (pickingList.createdBy) {
    userIds.push(pickingList.createdBy)
  }

  if (pickingList.updatedBy) {
    userIds.push(pickingList.updatedBy)
  }

  // Meta
  if (pickingList.statusHistory) {
    pickingList.statusHistory
      .filter((el) => el.userId !== undefined)
      .map((obj) => userIds.push(obj.userId!))
  }

  return userIds
}

/**
 * Get picking-list picked by user ID
 * @param pickingList - the picking-list
 * @returns the user ID
 */
export function getPickingPickedByUser(
  pickingList: PickingList,
): string | undefined {
  const pickedBy = pickingList.statusHistory.find(
    (s) => s.status === PickingListStatus.picked,
  )
  return pickedBy?.userId
}

/**
 * Get picking list product used totes
 * @param pickingList - the picking list
 * @param productId - the product ID
 * @returns the list of tote ids used
 */
export function getPickingProductUsedTotes(
  pickingList: PickingList,
  productId: string,
): string[] {
  let toteIds: string[] = []

  toteIds = pickingList.missions
    .filter((m) => m.product._id === productId && m.qtyPicked > 0)
    .reduce<string[]>((acc, m) => [...acc, ...getPickingMissionTotes(m)], [])

  return uniq(toteIds)
}

/**
 * Get picking list mission used totes
 * @param pickingList - the picking list
 * @param orderId - the order ID
 * @returns the list of tote ids used
 */
export function getPickingOrderUsedTotes(
  pickingList: PickingList,
  orderId: string,
): string[] {
  let toteIds: string[] = []

  toteIds = pickingList.missions
    .filter(
      (m) => m.orders.map((o) => o._id).includes(orderId) && m.qtyPicked > 0,
    )
    .reduce<string[]>((acc, m) => [...acc, ...getPickingMissionTotes(m)], [])

  return uniq(toteIds)
}

/**
 * Check if any orders of a picking-list can be packable
 * @param orders - the orders of a picking-list
 * @returns the boolean flag that indicates if a picking-list can be packed
 */
export function checkPickingOrdersPackable(orders: Order[]): boolean {
  return orders.some((o) => o.status === OrderStatus.processed)
}

/**
 * Get picking list starting tote
 * @param pickingList - the picking list
 * @returns the starting tote  of the picking-list if present
 */
export function getPickingStartingToteId(
  pickingList: PickingList,
): string | undefined {
  return pickingList.totes.length ? pickingList.totes[0]._id : undefined
}

/**
 * Get locations of a picked product
 * @param pickingList - the picking-list
 * @param productId - the product ID
 * @returns the list of location paths
 */
export function getPickingLocationsPicked(
  pickingList: PickingList,
  productId: string,
): PickingListLocationPicked[] {
  return pickingList.missions
    .filter((m) => m.product._id === productId)
    .map((m) => ({
      path: m.location.path,
      qtyPicked: m.qtyPicked,
    }))
}
