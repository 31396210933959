import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  Observable,
  switchMap,
  tap,
  map,
  filter,
  withLatestFrom,
  take,
} from 'rxjs'
import { Brand, BrandPageData, BrandSearchParams } from '../brand.model'
import { BrandsService } from '../brands.service'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { parseBrandsPage } from '../libs/brand.lib'
import { BrandsRepository } from '../brands.repository'
import { BrandNotification } from '../libs/brand-notification.lib'

interface BrandsListingState {
  filters?: BrandSearchParams
  data?: BrandPageData[]
  totalCount?: number
  notification?: BrandNotification
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const BRANDS_LISTING_INITIAL_STATE: BrandsListingState = {
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class BrandsListingUseCase extends ComponentStore<BrandsListingState> {
  constructor(
    private brandsService: BrandsService,
    private brandsRepository: BrandsRepository,
  ) {
    super(BRANDS_LISTING_INITIAL_STATE)
  }

  // Selectors

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectFilters$: Observable<BrandSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {}, ['_id'])),
  )

  readonly selectBrands$ = this.select((state) => state.data || [])

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<Page<Brand>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectBrands$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectNotification$ = this.select((state) => state.notification)

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<BrandSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.brandsService.searchData$(searchParams).pipe(
            tapResponse(
              (pageData) =>
                this.setPage(
                  parseBrandsPage(pageData.page, pageData.productsCounts),
                ),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<BrandSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectTotalCount$),
        map(([params, limit]) => ({ ...params, limit })),
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.brandsService.searchData$(searchParams).pipe(
            tapResponse(
              (pageData) =>
                this.setPage(
                  parseBrandsPage(pageData.page, pageData.productsCounts),
                ),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly delete$ = this.effect((brand$: Observable<Brand>) => {
    return brand$.pipe(
      switchMap((brand) =>
        this.brandsRepository
          .alert$(
            BrandNotification.from({
              code: 'BRAND_DELETION',
              data: { brand },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.brandsService.delete$(brand._id).pipe(
                tapResponse(
                  () => this.setBrandDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  // Reducers

  readonly setFilters = this.updater(
    (state, filters?: BrandSearchParams): BrandsListingState => {
      return {
        filters,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: Page<BrandPageData>): BrandsListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): BrandsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): BrandsListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setBrands = this.updater(
    (state, data: Brand[]): BrandsListingState => {
      return {
        ...state,
        data,
      }
    },
  )

  readonly setBrandDeleted = this.updater((state): BrandsListingState => {
    return {
      ...state,
      notification: BrandNotification.from({ code: 'BRAND_DELETED' }),
      toReload: true,
    }
  })

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): BrandsListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )
}
