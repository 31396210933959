import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import {
  AutomationAction,
  AutomationCollection,
  AutomationEvent,
  AutomationsTab,
  GoodsReceivePlaceHolder,
  OrderPlaceHolder,
  PickupPointPlaceHolder,
} from './automation.model'

export const AUTOMATION_SCOPE: PermissionsScope = 'integrations'

export const AUTOMATION_EVENTS: SelectionData<AutomationEvent>[] = [
  {
    label: 'Cambio di stato',
    value: AutomationEvent.change_status,
    icon: 'fad fa-exchange',
  },
]

export const AUTOMATION_ACTIONS: SelectionData<AutomationAction>[] = [
  {
    label: 'Invio email',
    value: AutomationAction.submit_email,
    icon: 'fad fa-paper-plane',
  },
]

export const AUTOMATION_COLLECTIONS: SelectionData<AutomationCollection>[] = [
  {
    label: 'Ordini clienti',
    value: AutomationCollection.orders,
  },
  {
    label: 'Arrivi merce',
    value: AutomationCollection.goodsReceives,
  },
]

export const AUTOMATION_EMAIL_TAGS = [
  {
    label: 'Ordine cliente',
    items: [
      {
        value: `*|${OrderPlaceHolder.number}|*`,
        text: 'Numero ordine',
        label: 'Numero ordine interno',
      },
      {
        value: `*|${OrderPlaceHolder.date}|*`,
        text: 'Data ordine',
        label: 'Data ordine interno',
      },
      {
        value: `*|${OrderPlaceHolder.ref}|*`,
        text: 'Riferimento ordine',
        label: 'Riferimento ordine esterno',
      },
      {
        value: `*|${OrderPlaceHolder.refDate}|*`,
        text: 'Riferimento data ordine',
        label: 'Data riferimento ordine esterno',
      },
      {
        value: `*|${OrderPlaceHolder.billingAddress}|*`,
        text: 'Indirizzo fatturazione completo',
        label: 'Indirizzo di fatturazione completo',
      },
      {
        value: `*|${OrderPlaceHolder.billingAddressName}|*`,
        text: 'Nome cliente fatturazione',
        label: 'Nome cliente indirizzo di fatturazione',
      },
      {
        value: `*|${OrderPlaceHolder.billingAddressEmail}|*`,
        text: 'Email cliente fatturazione',
        label: 'Email cliente indirizzo di fatturazione',
      },
      {
        value: `*|${OrderPlaceHolder.billingAddressPhone}|*`,
        text: 'Telefono cliente fatturazione',
        label: 'Telefono cliente indirizzo di fatturazione',
      },
      {
        value: `*|${OrderPlaceHolder.shippingAddress}|*`,
        text: 'Indirizzo spedizione completo',
        label: 'Indirizzo di spedizione completo',
      },
      {
        value: `*|${OrderPlaceHolder.shippingAddressName}|*`,
        text: 'Nome cliente spedizione',
        label: 'Nome cliente indirizzo di spedizione',
      },
      {
        value: `*|${OrderPlaceHolder.shippingAddressEmail}|*`,
        text: 'Email cliente spedizione',
        label: 'Email cliente indirizzo di spedizione',
      },
      {
        value: `*|${OrderPlaceHolder.shippingAddressPhone}|*`,
        text: 'Telefono cliente spedizione',
        label: 'Telefono cliente indirizzo di spedizione',
      },
    ],
  },
  {
    label: 'Arrivo merce',
    items: [
      {
        value: `*|${GoodsReceivePlaceHolder.number}|*`,
        text: 'Numero arrivo merce',
        label: 'Numero arrivo merce',
      },
      {
        value: `*|${GoodsReceivePlaceHolder.date}|*`,
        text: 'Data arrivo merce',
        label: 'Data arrivo merce',
      },
      {
        value: `*|${GoodsReceivePlaceHolder.ref}|*`,
        text: 'Riferimento arrivo merce',
        label: 'Riferimento arrivo merce',
      },
      {
        value: `*|${GoodsReceivePlaceHolder.refDate}|*`,
        text: 'Riferimento data arrivo merce',
        label: 'Data riferimento arrivo merce',
      },
    ],
  },
  {
    label: 'Pickup Point',
    items: [
      {
        value: `*|${PickupPointPlaceHolder.name}|*`,
        text: 'Nome',
        label: 'Nome pickup point',
      },
      {
        value: `*|${PickupPointPlaceHolder.address}|*`,
        text: 'Indirizzo',
        label: 'Indirizzo pickup point',
      },
      {
        value: `*|${PickupPointPlaceHolder.notes}|*`,
        text: 'Note',
        label: 'Note pickup point',
      },
      {
        value: `*|${PickupPointPlaceHolder.openings}|*`,
        text: 'Orari',
        label: "Orari d'apertura pickup point",
      },
    ],
  },
]

export const AUTOMATIONS_TABS: SelectionData<AutomationsTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'event',
    label: 'Evento',
  },
  {
    value: 'action',
    label: 'Azione',
  },
]
