import { Pipe, PipeTransform } from '@angular/core'
import { LoadOptions } from 'devextreme/data'
import DataSource from 'devextreme/data/data_source'
import { lastValueFrom, Observable, of } from 'rxjs'

@Pipe({
  name: 'dataSource',
})
export class DataSourcePipe implements PipeTransform {
  transform(
    search$: (loadOptions: LoadOptions) => Observable<any>,
    update$?: (key: string, updated: any) => Observable<any>,
    key = '_id'
  ): DataSource {
    return new DataSource({
      load: (loadOptions: LoadOptions) => lastValueFrom(search$(loadOptions)),
      update: (key: string, updated: any) =>
        lastValueFrom(update$ ? update$(key, updated) : of()),
      key,
    })
  }
}
