import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { FilterOperator } from '../../models/filter.model'
import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import { AttributeFilter, AttributeKind, AttributeTab } from './attribute.model'

export const ATTRIBUTE_SCOPE: PermissionsScope = 'products'

type AttributeAvailable = SelectionData<AttributeKind> & {
  configuration: any
  bulkMethods: BulkUpdateMethod[]
  filters: FilterOperator[]
  initialValue: any
}

export const ATTRIBUTE_TYPES: AttributeAvailable[] = [
  {
    label: 'Testo',
    logo: 'assets/img/fields/input-text.png',
    value: AttributeKind.text,
    configuration: {
      isUnique: true,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: true,
      maxLength: true,
      options: false,
      pattern: true,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.contains,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Numero',
    logo: 'assets/img/fields/number.png',
    value: AttributeKind.number,
    configuration: {
      isUnique: true,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: true,
      max: true,
      minLength: false,
      maxLength: false,
      options: false,
      pattern: false,
      numericType: true,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Data',
    logo: 'assets/img/fields/calendar-pick.png',
    value: AttributeKind.date,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      options: false,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Prezzo',
    logo: 'assets/img/fields/price-tag.png',
    value: AttributeKind.price,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      options: false,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Editor di testo',
    logo: 'assets/img/fields/editor.png',
    value: AttributeKind.textarea,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: true,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: true,
      maxLength: true,
      options: false,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.contains,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Si/No',
    logo: 'assets/img/fields/toggle.png',
    value: AttributeKind.boolean,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      options: false,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Select',
    logo: 'assets/img/fields/select.png',
    value: AttributeKind.simpleSelect,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      options: true,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
  {
    label: 'Multiselect',
    logo: 'assets/img/fields/multi-select.png',
    value: AttributeKind.multiSelect,
    configuration: {
      isUnique: false,
      isSearchable: true,
      htmlEditor: false,
      isLocalizable: true,
      valuePerCatalog: true,
      min: false,
      max: false,
      minLength: false,
      maxLength: false,
      options: true,
      pattern: false,
      numericType: false,
    },
    bulkMethods: [
      BulkUpdateMethod.set,
      BulkUpdateMethod.unset,
      BulkUpdateMethod.push,
      BulkUpdateMethod.pull,
    ],
    filters: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
      FilterOperator.notExists,
    ],
    initialValue: undefined,
  },
]

export const ATTRIBUTE_FILTERS: AttributeFilter[] = [
  {
    code: 'ALL',
    name: 'Tutti gli attributi',
  },
  {
    code: 'EMPTY',
    name: 'Attributi non compilati',
  },
  {
    code: 'MANDATORYEMPTY',
    name: 'Attributi richiesti non compilati',
  },
]

export const ATTRIBUTE_TABS: SelectionData<AttributeTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'settings',
    label: 'Impostazioni',
  },
  {
    value: 'options',
    label: 'Opzioni',
  },
]
