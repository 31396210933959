import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { HttpErrorResponse } from '@angular/common/http'
import {
  EMPTY,
  Observable,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import {
  PackingList,
  PackingListAction,
  PackingListOrderData,
  PackingListPackData,
  PackingListPackages,
  PackingListPickData,
  PackingListStationData,
  PackingListStatus,
} from '../packing-list.model'
import { PackingListsRepository } from '../packing-lists.repository'
import { PickingList, PickingListType } from '../../picking-lists'
import {
  Order,
  OrderAdditionalPackage,
  OrderPackage,
  addOrderPackage,
} from '../../orders'
import { Product } from '../../products'
import {
  getPackingNextOrder,
  isPackingOrderLast,
  isPackingOrderPackable,
  isPackingOrderPacked,
  isPackingOrderWaiting,
} from '../libs/packing-orders.lib'
import { getPackingActionWeightToPack } from '../libs/packing-products.lib'
import { PackingListNotification } from '../libs/packing-notification.lib'

interface PackingListPackingState {
  step: PackingListPackingStep
  packingData?: PackingListPickData
  stationData?: PackingListStationData
  orderData?: PackingListOrderData
  lastOrder?: Order
  lastPackingList?: PackingList
  notification?: PackingListNotification
  error?: HttpErrorResponse
  isLoading: boolean
  isPrinting: boolean
}

type PackingListPackingStep = 'search' | 'detail' | 'packing'

const PACKING_INITIAL_STATE: PackingListPackingState = {
  step: 'search',
  isLoading: false,
  isPrinting: false,
}

@Injectable()
export class PackingListPackingUseCase extends ComponentStore<PackingListPackingState> {
  constructor(private packingListsRepository: PackingListsRepository) {
    super(PACKING_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectError$: Observable<HttpErrorResponse | undefined> =
    this.select((state) => state.error)

  readonly selectNotification$: Observable<
    PackingListNotification | undefined
  > = this.select((state) => state.notification)

  readonly selectStep$: Observable<PackingListPackingStep> = this.select(
    (state) => state.step,
  )

  readonly selectStepToFocus$: Observable<PackingListPackingStep> =
    this.selectState$.pipe(
      filter((state) => {
        if (state?.isLoading || state?.isPrinting) {
          return false
        }

        if (state?.notification?.code === 'ORDER_PACKED') {
          return false
        }

        return state?.step !== 'detail'
      }),
      map((state) => state?.step || 'search'),
    )

  readonly selectPackingData$: Observable<PackingListPickData | undefined> =
    this.select((state) => state.packingData)

  readonly selectPickingList$: Observable<PickingList | undefined> =
    this.selectPackingData$.pipe(map((packingData) => packingData?.pickingList))

  readonly selectPackingList$: Observable<PackingList | undefined> =
    this.selectPackingData$.pipe(map((packingData) => packingData?.packingList))

  readonly selectOrderData$: Observable<PackingListOrderData | undefined> =
    this.select((state) => state.orderData)

  readonly selectStationData$ = this.select((state) => state.stationData)

  readonly selectWarehouseId$ = this.selectStationData$.pipe(
    map((station) => station?.warehouseId),
  )

  readonly selectOrder$: Observable<Order | undefined> =
    this.selectOrderData$.pipe(map((orderData) => orderData?.order))

  readonly selectOrderId$: Observable<string | undefined> =
    this.selectOrder$.pipe(map((order) => order?._id))

  readonly selectOrderCarrierId$: Observable<string | undefined> =
    this.selectOrder$.pipe(map((order) => order?.carrierId))

  readonly selectOrderChannelId$: Observable<string | undefined> =
    this.selectOrder$.pipe(map((order) => order?.header.channel))

  readonly selectOrderPaymentId$: Observable<string | undefined> =
    this.selectOrder$.pipe(map((order) => order?.header.paymentType))

  readonly selectAction$: Observable<PackingListAction | undefined> =
    this.selectOrderData$.pipe(map((orderData) => orderData?.action))

  readonly selectProducts$: Observable<Product[] | undefined> =
    this.selectOrderData$.pipe(map((orderData) => orderData?.products))

  readonly selectPackages$: Observable<OrderPackage[] | undefined> =
    this.selectOrderData$.pipe(map((orderData) => orderData?.packages))

  readonly selectAdditionalPackages$: Observable<
    OrderAdditionalPackage[] | undefined
  > = this.selectOrderData$.pipe(
    map((orderData) => orderData?.additionalPackages),
  )

  readonly selectPackOrderData$: Observable<{
    packingList: PackingList
    orderId: string
  }> = this.selectPackingList$.pipe(
    switchMap((packingList) => {
      return this.selectOrderId$.pipe(
        switchMap((orderId) =>
          !packingList || !orderId ? EMPTY : of({ packingList, orderId }),
        ),
      )
    }),
  )

  readonly selectOrderIsLast$: Observable<boolean> =
    this.selectPackOrderData$.pipe(
      map(({ packingList, orderId }) =>
        isPackingOrderLast(packingList, orderId),
      ),
    )

  readonly selectOrderIsWaiting$: Observable<boolean> =
    this.selectPackOrderData$.pipe(
      map(({ packingList, orderId }) =>
        isPackingOrderWaiting(packingList, orderId),
      ),
    )

  readonly selectOrderIsPacked$: Observable<boolean | undefined> =
    this.selectPackOrderData$.pipe(
      map(({ packingList, orderId }) =>
        isPackingOrderPacked(packingList, orderId),
      ),
    )

  readonly selectOrderIsPackable$: Observable<boolean | undefined> =
    this.selectPackOrderData$.pipe(
      map(({ packingList, orderId }) =>
        isPackingOrderPackable(packingList, orderId),
      ),
    )

  readonly selectLastOrder$: Observable<Order | undefined> = this.select(
    (state) => state.lastOrder,
  )

  readonly selectLastPackingList$: Observable<PackingList | undefined> =
    this.select((state) => state.lastPackingList)

  // Effects

  readonly searchByTote$ = this.effect((code$: Observable<string>) => {
    return code$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectWarehouseId$),
      switchMap(([code, warehouseId]) => {
        if (!warehouseId) {
          return EMPTY
        }

        return this.packingListsRepository
          .loadPackingDataByToteCode$(code, warehouseId)
          .pipe(
            tapResponse(
              (packingData) => this.setPackingPickData(packingData),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly searchByPicking$ = this.effect((pickingId$: Observable<string>) => {
    return pickingId$.pipe(
      tap(() => this.startLoading()),
      switchMap((pickingId) =>
        this.packingListsRepository.loadPackingDataByPickingId$(pickingId).pipe(
          tapResponse(
            (packingData) => this.setPackingPickData(packingData),
            (error) => this.parseError(error),
          ),
        ),
      ),
    )
  })

  readonly searchById$ = this.effect((packingId$: Observable<string>) => {
    return packingId$.pipe(
      tap(() => this.startLoading()),
      switchMap((packingId) =>
        this.packingListsRepository.loadPackingDataById$(packingId).pipe(
          tapResponse(
            (packingData) => this.setPackingPickData(packingData),
            (error) => this.parseError(error),
          ),
        ),
      ),
    )
  })

  readonly startPacking$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (
          !state?.packingData ||
          !state.stationData?.printerId ||
          !state.stationData.A4PrinterId
        ) {
          return EMPTY
        }

        const { pickingList, orders } = state.packingData
        const { printerId, A4PrinterId, packagingLocation } = state.stationData

        return this.packingListsRepository
          .startPacking$(
            pickingList,
            orders,
            printerId,
            A4PrinterId,
            packagingLocation?._id,
          )
          .pipe(
            tapResponse(
              (packingPackData) => this.setPackingPackData({ packingPackData }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly restorePacking$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (
          !state?.packingData?.packingList ||
          !state.stationData?.currentUserId ||
          !state.stationData?.printerId ||
          !state.stationData?.A4PrinterId
        ) {
          return EMPTY
        }

        const { packingList, pickingList, orders } = state.packingData
        const { currentUserId, printerId, A4PrinterId, packagingLocation } =
          state.stationData

        return this.packingListsRepository
          .restorePacking$(
            packingList,
            pickingList,
            orders,
            currentUserId,
            printerId,
            A4PrinterId,
            packagingLocation?._id,
          )
          .pipe(
            tapResponse(
              (packingPackData) => this.setPackingPackData({ packingPackData }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly takeUpPacking$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (
          !state?.packingData?.packingList ||
          !state.stationData?.printerId ||
          !state.stationData.A4PrinterId
        ) {
          return EMPTY
        }

        const { packingList, orders } = state.packingData
        const { printerId, A4PrinterId, packagingLocation } = state.stationData

        return this.packingListsRepository
          .takeUpPacking$(
            packingList._id,
            orders,
            printerId,
            A4PrinterId,
            packagingLocation?._id,
          )
          .pipe(
            tapResponse(
              (packingPackData) => this.setPackingPackData({ packingPackData }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly skipOrder$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (!state?.packingData?.packingList || !state.packingData.orders) {
          return EMPTY
        }

        const packingList = state.packingData.packingList
        const currentOrder = state.orderData?.order
        const nextOrderPack = getPackingNextOrder(
          packingList,
          currentOrder?._id,
        )
        const nextOrder = state.packingData.orders.find(
          (o) => o._id === nextOrderPack._id,
        )

        if (!nextOrder) {
          return EMPTY
        }

        return this.packingListsRepository
          .loadOrderData$(packingList, nextOrder)
          .pipe(
            tapResponse(
              (orderData) => this.setPackingOrderData(orderData),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly scan$ = this.effect((code$: Observable<string>) => {
    return code$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([code, state]) => {
        if (
          !state?.packingData?.packingList ||
          !state.packingData.orders ||
          !state.orderData
        ) {
          return EMPTY
        }

        // Check if a TOTE is scanned
        if (
          state.packingData.pickingList.type === PickingListType.pack &&
          !state.orderData.order
        ) {
          const scannedTote = state.packingData.pickingList.totes.find(
            (c) => c.code === code,
          )
          const orderToLoad = state.packingData.orders.find(
            (o) => o._id === scannedTote?.orderIds[0],
          )
          if (
            !state.orderData.order &&
            state.packingData.packingList &&
            orderToLoad
          ) {
            return this.packingListsRepository
              .loadOrderData$(state?.packingData?.packingList, orderToLoad)
              .pipe(
                tapResponse(
                  (orderData) => this.setPackingOrderData(orderData),
                  (error) => this.parseError(error),
                ),
              )
          }
        }

        return this.packingListsRepository
          .scanProduct$(code, state.packingData, state.orderData)
          .pipe(
            tapResponse(
              (packingPackData) =>
                this.setPackingPackData({ packingPackData, checkPack: true }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly packAction$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.startLoading()),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (!state?.packingData || !state.orderData) {
          return EMPTY
        }

        const { packingList, orders } = state.packingData
        const { action, products } = state.orderData

        if (!packingList || !orders || !action || !products) {
          return EMPTY
        }

        const product = products.find((p) => p._id === action.productId)
        const qty = action.qtyToPack - action.qtyPacked

        if (!product || !qty) {
          return EMPTY
        }

        return this.packingListsRepository
          .packProduct$(
            product,
            packingList,
            orders,
            state.orderData,
            product.SKU,
            qty,
          )
          .pipe(
            tapResponse(
              (packingPackData) =>
                this.setPackingPackData({ packingPackData, checkPack: true }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly packOrder$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (!state?.packingData?.packingList || !state.orderData) {
          return EMPTY
        }

        return this.packingListsRepository
          .packTote$(state.packingData.packingList, state.orderData)
          .pipe(
            tapResponse(
              (packingPackData) =>
                this.setPackingPackData({ packingPackData, checkPack: true }),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly setOrderUpdated$ = this.effect((order$: Observable<Order>) => {
    return order$.pipe(
      withLatestFrom(this.selectState$),
      switchMap(([order, state]) => {
        if (!state?.packingData?.packingList) {
          return EMPTY
        }

        return this.packingListsRepository
          .loadOrderData$(state?.packingData?.packingList, order)
          .pipe(
            tapResponse(
              (orderData) => this.setPackingOrderData(orderData),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly printOrder$ = this.effect(
    (
      printParams$: Observable<{
        component: any
        automaticCheck?: boolean
      }>,
    ) => {
      return printParams$.pipe(
        tap(() => this.setIsPrinting(true)),
        withLatestFrom(this.selectState$),
        switchMap(([params, state]) => {
          if (
            !state?.packingData?.packingList ||
            !state.orderData ||
            !state.orderData?.order
          ) {
            return EMPTY
          }

          const packingList = state.packingData.packingList
          const orderData = state.orderData
          const orderId = state.orderData.order._id

          const packagesData = this.parseStatePackages(state)
          packagesData && this.setPackages(packagesData)

          let obs$: Observable<PackingListPackData>

          if (
            params.automaticCheck &&
            state?.stationData?.printingType === 'automatic'
          ) {
            obs$ = this.packingListsRepository.printOrder$(
              packingList,
              orderId,
              state.packingData.orders,
              packagesData?.packages || [],
              packagesData?.additionalPackages,
            )
          } else {
            obs$ = this.packingListsRepository
              .editOrderPackages$(packingList, orderData, params.component)
              .pipe(
                take(1),
                switchMap((packagesData) => {
                  if (!packagesData) {
                    return EMPTY
                  }

                  this.setPackages(packagesData)
                  return this.packingListsRepository.printOrder$(
                    packingList,
                    orderId,
                    state.packingData?.orders || [],
                    packagesData.packages,
                    packagesData.additionalPackages,
                  )
                }),
              )
          }

          return obs$.pipe(
            tapResponse(
              (packingPackData) => this.setPackingPackData({ packingPackData }),
              (error) => this.parseError(error),
            ),
          )
        }),
      )
    },
  )

  // Reducers

  readonly setPackingPickData = this.updater(
    (state, packingPickData: PackingListPickData): PackingListPackingState => {
      return {
        ...state,
        step: 'detail',
        packingData: packingPickData,
        isLoading: false,
      }
    },
  )

  readonly setPackingPackData = this.updater(
    (
      state,
      data: { packingPackData: PackingListPackData; checkPack?: boolean },
    ): PackingListPackingState => {
      const { packingList, lastOrder, ...orderData } = data.packingPackData

      return {
        ...state,
        packingData: state.packingData
          ? {
              ...state.packingData,
              packingList:
                packingList.status === PackingListStatus.printed
                  ? undefined
                  : packingList,
            }
          : state.packingData,
        orderData: { ...orderData },
        lastOrder,
        lastPackingList:
          packingList.status === PackingListStatus.printed
            ? packingList
            : undefined,
        notification:
          data.checkPack &&
          orderData.order?._id &&
          isPackingOrderPacked(packingList, orderData.order._id)
            ? PackingListNotification.from({ code: 'ORDER_PACKED' })
            : undefined,
        step:
          packingList.status === PackingListStatus.printed
            ? 'search'
            : 'packing',
        isLoading: false,
        isPrinting: false,
      }
    },
  )

  readonly setPackingOrderData = this.updater(
    (state, orderData: PackingListOrderData): PackingListPackingState => {
      return {
        ...state,
        orderData,
        isLoading: false,
      }
    },
  )

  readonly setOrder = this.updater(
    (state, order: Order): PackingListPackingState => {
      if (!state.packingData || !state.orderData) {
        return state
      }

      return {
        ...state,
        packingData: {
          ...state.packingData,
          orders: state.packingData.orders.map((o) =>
            o._id === order._id ? order : o,
          ),
        },
        orderData: {
          ...state.orderData,
          order,
        },
      }
    },
  )

  readonly unsetLastPackingList = this.updater(
    (state): PackingListPackingState => {
      return {
        ...state,
        lastPackingList: undefined,
      }
    },
  )

  readonly setPackages = this.updater(
    (state, packagesData: PackingListPackages): PackingListPackingState => {
      if (!state.orderData) {
        return state
      }

      return {
        ...state,
        orderData: {
          ...state.orderData,
          packages: packagesData.packages,
          additionalPackages: packagesData.additionalPackages,
        },
      }
    },
  )

  readonly setPackingOrderAction = this.updater(
    (state, action: PackingListAction): PackingListPackingState => {
      if (!state.orderData) {
        return state
      }

      return {
        ...state,
        orderData: {
          ...state.orderData,
          action,
        },
      }
    },
  )

  readonly setStationData = this.updater(
    (state, stationData: PackingListStationData): PackingListPackingState => {
      return { ...state, stationData }
    },
  )

  readonly cancelPacking = this.updater((state): PackingListPackingState => {
    return {
      ...state,
      step: 'search',
      packingData: undefined,
      notification: undefined,
      isLoading: false,
    }
  })

  readonly setIsPrinting = this.updater(
    (state, isPrinting: boolean): PackingListPackingState => {
      return {
        ...state,
        isPrinting,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): PackingListPackingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly startLoading = this.updater((state): PackingListPackingState => {
    return {
      ...state,
      error: undefined,
      notification: undefined,
      isLoading: true,
    }
  })

  readonly setNotification = this.updater(
    (
      state,
      notification: PackingListNotification | undefined,
    ): PackingListPackingState => {
      return {
        ...state,
        notification,
        isLoading: false,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse | undefined): PackingListPackingState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  // Utilities

  private parseError(errorResponse: unknown) {
    if (errorResponse instanceof HttpErrorResponse) {
      this.setError(errorResponse)
    } else if (errorResponse instanceof PackingListNotification) {
      this.setNotification(errorResponse)
    } else {
      this.setIsLoading(false)
    }
  }

  private parseStatePackages(
    state?: PackingListPackingState,
  ): PackingListPackages | undefined {
    if (!state?.packingData?.packingList || !state.orderData?.order) {
      return undefined
    }

    // Check packages
    let packages = state.orderData.packages || []
    const additionalPackages = state.orderData.additionalPackages
    if (!state.stationData?.packagingManagement && !packages?.length) {
      packages = addOrderPackage(packages, {
        weight: getPackingActionWeightToPack(
          state.packingData.packingList,
          state.orderData.order._id,
          state.orderData.packages || [],
        ),
      })
    }

    return { packages, additionalPackages }
  }
}
