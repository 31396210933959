import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { saveAs } from 'file-saver'
import {
  EMPTY,
  Observable,
  combineLatest,
  filter,
  finalize,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import { omit } from 'lodash'
import {
  Warehouse,
  WarehouseArea,
  WarehouseAreaCreationParams,
  WarehouseInvitation,
  WarehouseTotes,
} from '../warehouse.model'
import {
  Location,
  LocationSearchParams,
  LocationsService,
} from '../../locations'
import { WarehousesService } from '../warehouses.service'
import { LocationModel, LocationModelsService } from '../../location-models'
import { Page } from '../../../models/util.model'
import {
  addWarehouseCarrier,
  getWarehouseUserIds,
  initWarehouse,
  initWarehouseTotes,
  removeWarehouseCarrier,
} from '../libs/warehouse.lib'
import { WarehouseNotification } from '../libs/warehouse-notification.lib'
import { WarehousesRepository } from '../warehouses.repository'
import { User, UsersService } from '../../users'

interface WarehouseDetailState {
  warehouseId?: string
  warehouse?: Warehouse
  invitations?: WarehouseInvitation[]
  models?: LocationModel[]
  locations?: WarehouseStateLocations
  users?: User[]
  error?: HttpErrorResponse
  notification?: WarehouseNotification
  toClose: boolean
  toReload: boolean
  isLoading: boolean
  isModified: boolean
}

interface WarehouseStateLocations {
  filters?: LocationSearchParams
  selected?: Location[]
  data?: Location[]
  totalCount?: number
  isInitialized?: boolean
}

interface WarehouseData {
  warehouse: Warehouse
  invitations?: WarehouseInvitation[]
  models?: LocationModel[]
  users?: User[]
}

interface WarehouseInvitationData {
  invitations: WarehouseInvitation[]
  notification?: WarehouseNotification
}

interface WarehouseSavedData {
  warehouse: Warehouse
  notification: WarehouseNotification
  toClose: boolean
}

interface LocationsFilterData {
  params: LocationSearchParams
  count: number
}

const WAREHOUSE_DETAIL_INITIAL_STATE: WarehouseDetailState = {
  toClose: false,
  toReload: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class WarehouseDetailUseCase extends ComponentStore<WarehouseDetailState> {
  constructor(
    private warehousesService: WarehousesService,
    private warehousesRepository: WarehousesRepository,
    private usersService: UsersService,
    private locationsService: LocationsService,
    private locationModelsService: LocationModelsService,
  ) {
    super(WAREHOUSE_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectToClose$ = this.select((state) => state.toClose)

  readonly selectToReload$ = this.select((state) => state.toReload)

  readonly selectIsModified$ = this.select((state) => state.isModified)

  readonly selectIsLoading$ = this.select((state) => state.isLoading)

  readonly selectIsErrored$ = this.select((state) => !!state.error)

  readonly selectWarehouseId$ = this.select((state) => state.warehouse?._id)

  readonly selectWarehouse$ = this.select((state) => state.warehouse)

  readonly selectWarehouseLoaded$ = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() => this.select((state) => state.warehouse)),
  )

  readonly selectInvitations$ = this.select((state) => state.invitations)

  readonly selectLocations$ = this.select(
    (state) => state.locations?.data || [],
  )

  readonly selectUsers$ = this.select((state) => state.users)

  readonly selectUserIds$ = this.select((state) =>
    state.users?.map((u) => u._id),
  )

  readonly selectModels$ = this.select((state) => state.models)

  readonly selectLocationsFilterData$: Observable<LocationsFilterData> =
    this.selectState$.pipe(map((state) => this.parseStateFilterData(state)))

  readonly selectLocationsFilters$: Observable<
    LocationSearchParams | undefined
  > = this.select((state) => state.locations?.filters)

  readonly selectLocationsIsInitialized$: Observable<boolean> = this.select(
    (state) => !!state.locations?.isInitialized,
  )

  readonly selectLocationsTotalCount$: Observable<number> = this.select(
    (state) => state.locations?.totalCount || 0,
  )

  readonly selectLocationsSelected$ = this.select(
    (state) => state.locations?.selected,
  )

  readonly selectLocationsPage$: Observable<Page<Location>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectLocations$,
          this.selectLocationsTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true },
        ),
      ),
    )

  readonly selectNotification$: Observable<WarehouseNotification | undefined> =
    this.select((state) => state.notification)

  // Effects

  readonly init$ = this.effect(
    (warehouse$: Observable<Partial<Warehouse> | undefined>) => {
      return warehouse$.pipe(
        map((warehouse) => initWarehouse(warehouse)),
        tap((warehouse) => this.setWarehouse(warehouse)),
      )
    },
  )

  readonly read$ = this.effect(
    (readData$: Observable<{ warehouseId: string; tenantId: string }>) => {
      return readData$.pipe(
        tap((readData) => this.setWarehouseId(readData.warehouseId)),
        withLatestFrom(this.selectUsers$),
        switchMap(([readData, users]) =>
          this.warehousesService.read$(readData.warehouseId).pipe(
            switchMap((warehouse) =>
              combineLatest({
                warehouse: of(warehouse),
                users: this.usersService.store$(
                  getWarehouseUserIds(warehouse),
                  users || [],
                ),
                models: this.locationModelsService.list$(),
                invitations:
                  warehouse.tenantId === readData.tenantId
                    ? this.warehousesService.getInvitiations$(warehouse._id)
                    : of(undefined),
              }).pipe(
                tapResponse(
                  (warehouseData) => this.setWarehouseData(warehouseData),
                  (error) => this.parseError(error),
                ),
              ),
            ),
          ),
        ),
      )
    },
  )

  readonly loadInvitations$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      switchMap(() =>
        this.selectWarehouseId$.pipe(
          take(1),
          switchMap((warehouseId) => {
            if (!warehouseId) {
              return EMPTY
            }

            this.setIsLoading(true)
            return this.warehousesService.getInvitiations$(warehouseId).pipe(
              tapResponse(
                (invitations) => this.setInvitations({ invitations }),
                (error) => this.parseError(error),
              ),
            )
          }),
        ),
      ),
    )
  })

  readonly searchLocations$ = this.effect(
    (searchParams$: Observable<LocationSearchParams>) => {
      return searchParams$.pipe(
        tap(() => this.setIsLoading(true)),
        tap((searchParams) => this.setLocationsFilters(searchParams)),
        withLatestFrom(this.selectWarehouseId$),
        switchMap(([searchParams, warehouseId]) =>
          this.locationsService
            .search$({ ...searchParams, warehouseId, 'code:ex': true })
            .pipe(
              tapResponse(
                (page) => this.setLocationsPage(page),
                (error) => this.parseError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly printLocations$ = this.effect(
    (locations$: Observable<{ locations: Location[]; printerId: string }>) => {
      return locations$.pipe(
        tap(() => this.setIsLoading(true)),
        switchMap((params) =>
          this.locationsService
            .printLabels$(
              params.locations.map((l) => l._id),
              params.printerId,
            )
            .pipe(
              tapResponse(
                () =>
                  this.setNotification(
                    WarehouseNotification.from({
                      code: 'LOCATIONS_PRINTED',
                    }),
                  ),
                (error) => this.parseError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly downloadLocationLabel$ = this.effect(
    (location$: Observable<Location>) => {
      return location$.pipe(
        tap(() => this.setIsLoading(true)),
        switchMap((location) =>
          this.locationsService.downloadLabel$(location).pipe(
            tap((res) => saveAs(res.blob, res.label)),
            finalize(() => tap(() => this.setIsLoading(false))),
          ),
        ),
      )
    },
  )

  readonly createModel$ = this.effect((model$: Observable<LocationModel>) => {
    return model$.pipe(
      switchMap((model) =>
        this.locationModelsService.upsert$(model).pipe(
          tap(() => this.setIsLoading(true)),
          switchMap(() =>
            this.locationModelsService.list$().pipe(
              tapResponse(
                (models) =>
                  this.setModels({
                    models,
                    notification: WarehouseNotification.from({
                      code: 'MODEL_SAVED',
                    }),
                  }),
                (error) => this.parseError(error),
              ),
            ),
          ),
        ),
      ),
    )
  })

  readonly delete$ = this.effect((warehouse$: Observable<Warehouse>) => {
    return warehouse$.pipe(
      switchMap((warehouse) =>
        this.warehousesRepository
          .alert$(
            WarehouseNotification.from({
              code: 'WAREHOUSE_DELETION',
              data: { warehouse },
            }),
          )
          .pipe(
            take(1),
            switchMap((confirmation) => {
              if (!confirmation) {
                return EMPTY
              }

              this.setIsLoading(true)
              return this.warehousesService.delete$(warehouse._id).pipe(
                tapResponse(
                  () =>
                    this.setNotification(
                      WarehouseNotification.from({
                        code: 'WAREHOUSE_DELETED',
                      }),
                    ),
                  (error) => this.parseError(error),
                ),
              )
            }),
          ),
      ),
    )
  })

  readonly deleteModel$ = this.effect((model$: Observable<LocationModel>) => {
    return model$.pipe(
      switchMap((model) =>
        this.warehousesRepository
          .alert$(
            WarehouseNotification.from({
              code: 'MODEL_DELETION',
              data: { model },
            }),
          )
          .pipe(
            take(1),
            switchMap((confirmation) => {
              if (!confirmation) {
                return EMPTY
              }

              this.setIsLoading(true)
              return this.locationModelsService.delete$(model._id).pipe(
                switchMap(() =>
                  this.locationModelsService.list$().pipe(
                    tapResponse(
                      (models) =>
                        this.setModels({
                          models,
                          notification: WarehouseNotification.from({
                            code: 'MODEL_DELETED',
                          }),
                        }),
                      (error) => this.parseError(error),
                    ),
                  ),
                ),
              )
            }),
          ),
      ),
    )
  })

  readonly createArea$ = this.effect(
    (areaParams$: Observable<WarehouseAreaCreationParams>) => {
      return areaParams$.pipe(
        withLatestFrom(this.selectWarehouseId$),
        switchMap(([areaParams, warehouseId]) => {
          if (!warehouseId) {
            return EMPTY
          }

          this.setIsLoading(true)
          return this.warehousesService
            .createArea$(warehouseId, areaParams)
            .pipe(
              tapResponse(
                (newArea) => this.setNewArea(newArea),
                (error) => this.parseError(error),
              ),
            )
        }),
      )
    },
  )

  readonly updateArea$ = this.effect((area$: Observable<WarehouseArea>) => {
    return area$.pipe(
      withLatestFrom(this.selectWarehouseId$),
      switchMap(([area, warehouseId]) => {
        if (!warehouseId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.warehousesService
          .updateArea$(warehouseId, area._id, area)
          .pipe(
            tapResponse(
              (areaUpdated) => this.updateArea(areaUpdated),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly deleteArea$ = this.effect((area$: Observable<WarehouseArea>) => {
    return area$.pipe(
      switchMap((area) =>
        this.warehousesRepository
          .alert$(
            WarehouseNotification.from({
              code: 'AREA_DELETION',
              data: { area },
            }),
          )
          .pipe(
            take(1),
            withLatestFrom(this.selectWarehouseId$),
            switchMap(([confirmation, warehouseId]) => {
              if (!confirmation || !warehouseId) {
                return EMPTY
              }

              this.setIsLoading(true)
              return this.warehousesService
                .deleteArea$(warehouseId, area._id)
                .pipe(
                  map(() => area),
                  tapResponse(
                    (areaDeleted) => this.removeArea(areaDeleted),
                    (error) => this.parseError(error),
                  ),
                )
            }),
          ),
      ),
    )
  })

  readonly addTotes$ = this.effect((totesCount$: Observable<number>) => {
    return totesCount$.pipe(
      withLatestFrom(this.selectWarehouse$),
      switchMap(([totesCount, warehouse]) => {
        if (!warehouse) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.warehousesService
          .updateTotes$(
            warehouse._id,
            initWarehouseTotes({
              ...warehouse.totes,
              count: warehouse.totes.count + totesCount,
            }),
          )
          .pipe(
            tapResponse(
              (newTotes) => this.setNewTotes(newTotes),
              (error) => this.parseError(error),
            ),
          )
      }),
    )
  })

  readonly inviteTenant$ = this.effect((tenantId$: Observable<string>) => {
    return tenantId$.pipe(
      withLatestFrom(this.selectWarehouseId$),
      switchMap(([tenantId, warehouseId]) => {
        if (!warehouseId || !tenantId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.warehousesService.inviteTenant$(warehouseId, tenantId).pipe(
          switchMap(() =>
            this.warehousesService.getInvitiations$(warehouseId).pipe(
              tapResponse(
                (invitations) =>
                  this.setInvitations({
                    invitations,
                    notification: WarehouseNotification.from({
                      code: 'TENANT_INVITED',
                    }),
                  }),
                (error) => this.parseError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly ejectTenant$ = this.effect((tenantId$: Observable<string>) => {
    return tenantId$.pipe(
      withLatestFrom(this.selectWarehouseId$),
      switchMap(([tenantId, warehouseId]) => {
        if (!warehouseId || !tenantId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.warehousesService.ejectTenant$(warehouseId, tenantId).pipe(
          switchMap(() =>
            this.warehousesService.getInvitiations$(warehouseId).pipe(
              tapResponse(
                (invitations) => {
                  this.setInvitations({
                    invitations,
                    notification: WarehouseNotification.from({
                      code: 'TENANT_REJECTED',
                    }),
                  })
                },
                (error) => this.parseError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      withLatestFrom(this.selectWarehouse$),
      switchMap(([toClose, warehouse]) => {
        if (!warehouse) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.warehousesService.upsert$(warehouse).pipe(
          tapResponse(
            (warehouse) =>
              this.setWarehouseSaved({
                warehouse: warehouse,
                notification: WarehouseNotification.from({
                  code: 'WAREHOUSE_SAVED',
                }),
                toClose,
              }),
            (error) => this.parseError(error),
          ),
        )
      }),
    )
  })

  // Reducers

  readonly setLocationsFilters = this.updater(
    (state, filters?: LocationSearchParams): WarehouseDetailState => ({
      ...state,
      locations: {
        ...state.locations,
        filters,
        isInitialized: state.locations?.isInitialized,
      },
      isLoading: true,
    }),
  )

  readonly setLocationsPage = this.updater(
    (state, page: Page<Location>): WarehouseDetailState => ({
      ...state,
      locations: {
        ...state.locations,
        isInitialized: true,
        data: page.data,
        totalCount: page.totalCount,
      },
      isLoading: false,
    }),
  )

  readonly setWarehouseId = this.updater(
    (state, warehouseId: string): WarehouseDetailState => ({
      warehouseId,
      notification: undefined,
      toClose: false,
      toReload: false,
      isLoading: true,
      isModified: false,
    }),
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): WarehouseDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setWarehouseData = this.updater(
    (state, warehouseData: WarehouseData): WarehouseDetailState => ({
      ...state,
      warehouse: warehouseData.warehouse,
      invitations: warehouseData.invitations,
      models: warehouseData.models,
      users: warehouseData.users,
      notification: undefined,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setWarehouse = this.updater(
    (state, warehouse: Warehouse): WarehouseDetailState => ({
      ...state,
      warehouse,
      notification: undefined,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setInvitations = this.updater(
    (
      state,
      invitationsData: WarehouseInvitationData,
    ): WarehouseDetailState => ({
      ...state,
      invitations: invitationsData.invitations,
      notification: invitationsData.notification,
      isLoading: false,
    }),
  )

  readonly addCarrier = this.updater(
    (state, carrierId: string): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? addWarehouseCarrier(state.warehouse, carrierId)
        : state.warehouse,
      isModified: true,
    }),
  )

  readonly removeCarrier = this.updater(
    (state, carrierId: string): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? removeWarehouseCarrier(state.warehouse, carrierId)
        : state.warehouse,
      isModified: true,
    }),
  )

  readonly setModels = this.updater(
    (
      state,
      modelData: {
        models: LocationModel[]
        notification: WarehouseNotification
      },
    ): WarehouseDetailState => {
      return {
        ...state,
        models: modelData.models,
        notification: modelData.notification,
        isLoading: false,
      }
    },
  )

  readonly setNewArea = this.updater(
    (state, newArea: WarehouseArea): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? {
            ...state.warehouse,
            areas: [...(state.warehouse.areas || []), newArea],
          }
        : state.warehouse,
      notification: WarehouseNotification.from({
        code: 'AREA_SAVED',
      }),
      toReload: true,
      isLoading: false,
    }),
  )

  readonly updateArea = this.updater(
    (state, area: WarehouseArea): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? {
            ...state.warehouse,
            areas: (state.warehouse.areas || []).map((a) =>
              a._id === area._id ? area : a,
            ),
          }
        : state.warehouse,
      notification: WarehouseNotification.from({
        code: 'AREA_SAVED',
      }),
      isLoading: false,
    }),
  )

  readonly removeArea = this.updater(
    (state, area: WarehouseArea): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? {
            ...state.warehouse,
            areas: (state.warehouse.areas || []).filter(
              (a) => a._id !== area._id,
            ),
          }
        : state.warehouse,
      notification: WarehouseNotification.from({
        code: 'AREA_DELETED',
      }),
      isLoading: false,
    }),
  )

  readonly setNewTotes = this.updater(
    (state, newTotes: WarehouseTotes): WarehouseDetailState => ({
      ...state,
      warehouse: state.warehouse
        ? {
            ...state.warehouse,
            totes: newTotes,
          }
        : state.warehouse,
      notification: WarehouseNotification.from({
        code: 'TOTES_SAVED',
      }),
      toReload: true,
      isLoading: false,
    }),
  )

  readonly setWarehouseSaved = this.updater(
    (state, data: WarehouseSavedData): WarehouseDetailState => {
      return {
        ...state,
        warehouse: data.warehouse,
        notification: data.notification,
        toClose: data.toClose,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly updateWarehouse = this.updater(
    (state, warehouse: Warehouse): WarehouseDetailState => {
      return {
        ...state,
        warehouse,
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly setLocationsSelected = this.updater(
    (state, selected: Location[]): WarehouseDetailState => {
      return {
        ...state,
        locations: {
          ...(state.locations || {}),
          selected,
        },
      }
    },
  )

  readonly setNotification = this.updater(
    (state, notification: WarehouseNotification): WarehouseDetailState => ({
      ...state,
      notification,
      isLoading: false,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): WarehouseDetailState => ({
      ...state,
      error,
      isLoading: false,
    }),
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): WarehouseDetailState => ({
      ...state,
      toReload,
    }),
  )

  // Utilities

  private parseError(errorResponse: unknown) {
    if (errorResponse instanceof HttpErrorResponse) {
      this.setError(errorResponse)
    } else if (errorResponse instanceof WarehouseNotification) {
      this.setNotification(errorResponse)
    }
  }

  private parseStateFilterData(
    state: WarehouseDetailState,
  ): LocationsFilterData {
    if (state.locations?.selected?.length) {
      return {
        params: {
          _id: state.locations.selected.map((o) => o._id),
        },
        count: state.locations.selected.length,
      }
    }

    return {
      params: omit(state.locations?.filters, [
        'limit',
        'offset',
        'sort',
        'order',
      ]),
      count: state.locations?.totalCount || 0,
    }
  }
}
