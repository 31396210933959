import { defaultsDeep } from 'lodash'
import { initProduct } from './product.lib'
import { removeEmptyScope } from './locale.lib'
import { removeEmptyPrices } from './price.lib'
import {
  defineProductScopeAttributes,
  removeProductEmptyAttributes,
} from './attribute.lib'
import { removeProductEmptyCategories } from './category.lib'
import {
  defineProductScopeFamily,
  removeProductEmptyFamily,
} from './family.lib'
import {
  defineProductScopeClassifications,
  removeProductEmptyClassifications,
} from './classification.lib'
import { Product, ProductPrice, ProductScope } from '../product.model'
import { PRODUCT_SCOPE_INITIAL_STATE } from '../product.const'
import { CatalogLocales, Price } from '../../../models/util.model'
import { ProductFamily } from '../../product-families/product-family.model'
import { Attribute } from '../../attributes/attribute.model'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'
import { CATALOG_DEFAULT_CODE } from '../../catalogs'
import {
  ViewExternalData,
  ViewParam,
  addViewParam,
  initViewParam,
} from '../../views'

// Init

export function initProductScope(
  scope: Partial<ProductScope> = {},
): ProductScope {
  return defaultsDeep(scope, PRODUCT_SCOPE_INITIAL_STATE)
}

// Check methods

export function checkProductScope(
  product: Partial<Product> = {},
  scope: ProductScope,
  family?: ProductFamily,
  attributes?: Attribute[],
): Product {
  return initProduct({
    ...product,
    name: defineProductScope<string>(product.name, scope),
    price: defineProductScopePrices(product.price, scope),
    classifications: defineProductScopeClassifications(
      product.classifications,
      scope,
    ),
    attributes: attributes
      ? defineProductScopeAttributes(product.attributes, attributes, scope)
      : undefined,
    description: defineProductScope<string>(product.description, scope),
    shortDescription: defineProductScope<string>(
      product.shortDescription,
      scope,
    ),
    family: family
      ? defineProductScopeFamily(product.family, family, scope)
      : undefined,
    _rev: product._rev,
  })
}

export function checkProductEmptyScope(product: Product): Product {
  return {
    ...product,
    name: removeEmptyScope<string>(product.name)!,
    price: removeEmptyPrices(product.price),
    categories: removeProductEmptyCategories(product.categories),
    classifications: removeProductEmptyClassifications(product.classifications),
    attributes: removeProductEmptyAttributes(product.attributes),
    description: removeEmptyScope<string>(product.description),
    shortDescription: removeEmptyScope<string>(product.shortDescription),
    family: removeProductEmptyFamily(product.family),
    variantsManagement: product.variantsManagement?.attributeIds.length
      ? product.variantsManagement
      : undefined,
  }
}

// Definition methods

export function defineProductScope<T>(
  values: CatalogLocales<T>[] | undefined,
  scope: ProductScope = PRODUCT_SCOPE_INITIAL_STATE,
  defaultValue?: any,
): CatalogLocales<T>[] {
  const newValues = !values ? [] : values
  const catalogValues = newValues.find(
    (cat) => cat.catalogCode === scope.catalogCode,
  )

  return !catalogValues
    ? [
        ...newValues,
        {
          catalogCode: scope.catalogCode,
          locales: {
            [scope.locale]: defaultValue,
          },
        },
      ]
    : !catalogValues.locales[scope.locale]
      ? [
          ...newValues.map((val) =>
            val.catalogCode === scope.catalogCode
              ? {
                  ...val,
                  locales: { ...val.locales, [scope.locale]: defaultValue },
                }
              : val,
          ),
        ]
      : newValues
}

export function undefineProductScope<T>(
  values: CatalogLocales<T>[] | undefined,
  scope: ProductScope,
): CatalogLocales<T>[] | undefined {
  const productScope = values?.find((v) => v.catalogCode === scope.catalogCode)

  if (!productScope) {
    return values || []
  }

  return values?.filter((v) => v.catalogCode !== scope.catalogCode)
}

export function defineProductScopePrices(
  price: ProductPrice | undefined,
  scope: ProductScope,
): ProductPrice {
  return {
    ...price,
    listing: defineProductScope<Price>(price?.listing, scope, {}),
    purchase: defineProductScope<Price>(price?.purchase, scope, {}),
  }
}

export function setDiscount(
  price: ProductPrice | undefined,
  scope: ProductScope,
): ProductPrice {
  return {
    ...price,
    discount: defineProductScope<Price>(price?.discount, scope, {}),
  }
}

export function unsetDiscount(
  price: ProductPrice | undefined,
  scope: ProductScope,
): ProductPrice {
  return {
    ...price,
    discount: undefineProductScope<Price>(price?.discount, scope),
  }
}

export function setProductDiscount(
  product: Product,
  scope: ProductScope,
): Product {
  return {
    ...product,
    price: setDiscount(product.price, scope),
  }
}

export function unsetProductDiscount(
  product: Product,
  scope: ProductScope,
): Product {
  return {
    ...product,
    price: unsetDiscount(product.price, scope),
  }
}

/**
 * Parse view params to product scope
 * @param params - the view params
 * @param extData - the external data
 * @returns the product scope
 */
export function parseViewParamsToScope(
  params: ViewParam[] = [],
  extData?: ViewExternalData[],
): ProductScope {
  return params.reduce<ProductScope>(
    (acc, param) => {
      switch (param.property) {
        case 'catalogCode':
          acc['catalogCode'] = param.value as string
          acc['categoriesRootId'] = extData?.find(
            (d) => d.property === 'catalogCode',
          )?.entity['categoryId']
          break
        case 'locale':
          acc['locale'] = param.value as string
          break
        case 'warehouseId':
          acc['warehouseId'] = param.value as string
          break
        case 'supplierId':
          acc['supplierId'] = param.value as string
          break
      }

      return acc
    },
    {
      catalogCode: CATALOG_DEFAULT_CODE,
      locale: LOCALE_DEFAULT_CODE,
    },
  )
}

/**
 * Parse scope to view params
 * @param scope - the product scope
 * @returns the view params
 */
export function parseScopeToViewParams(scope: ProductScope): ViewParam[] {
  let params: ViewParam[] = []

  if (scope.catalogCode !== CATALOG_DEFAULT_CODE) {
    params = addViewParam(
      params,
      initViewParam({
        property: 'catalogCode',
        value: scope.catalogCode,
      }),
    )
  }

  if (scope.locale !== LOCALE_DEFAULT_CODE) {
    params = addViewParam(
      params,
      initViewParam({
        property: 'locale',
        value: scope.locale,
      }),
    )
  }

  if (scope.warehouseId) {
    params = addViewParam(
      params,
      initViewParam({
        property: 'warehouseId',
        value: scope.warehouseId,
      }),
    )
  }

  if (scope.supplierId) {
    params = addViewParam(
      params,
      initViewParam({
        property: 'supplierId',
        value: scope.supplierId,
      }),
    )
  }

  return params
}
