import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export enum TagTarget {
  products = 'products',
  orders = 'orders',
}

export interface Tag extends Entity {
  target: TagTarget
  value: string
  color?: string
}

export type TagSearchField = '_id' | 'target' | 'value'
export type TagSortField = 'value'

export interface TagSearchParams extends QueryStringParams<TagSortField> {
  value?: string | string[]
  'value:ne'?: string
  'value:ct'?: string
  target?: TagTarget | TagTarget[]
}
