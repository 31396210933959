import { Pipe, PipeTransform } from '@angular/core'
import { replace } from 'lodash'
import { FsService } from './fs.service'

@Pipe({
  name: 'fileUrl',
})
export class FsPipe implements PipeTransform {
  constructor(private fsService: FsService) {}

  transform(path: string, tenantId: string): string {
    const url = this.fsService.getFileUrl(tenantId, path)
    const regex = /[ ()]/g

    return replace(url, regex, '-')
  }
}
