import { QueryStringFilter } from '../../models/query-string.model'
import { TaskSearchField, TaskSearchParams } from './task.model'

/**
 * Parse filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the task search params updated
 */
export function taskFilterParams(
  params: TaskSearchParams,
  filter: QueryStringFilter<TaskSearchField>,
): TaskSearchParams {
  const searchParams: TaskSearchParams = {}

  switch (filter.field) {
    case 'createdBy':
      if (filter.operator === '=') {
        searchParams.createdBy = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams['status'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
