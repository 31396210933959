import moment from 'moment'
import { isDate } from '@evologi/shared/util-toolkit'
import {
  Stock,
  StockSearchParams,
  StockSortField,
  StocksListingKeys,
} from './stock.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import { Page } from '../../models/util.model'
import { uniq } from 'lodash'

// Search

export function stockSortParams(
  params: StockSearchParams,
  sort: QueryStringSort<StockSortField>,
): StockSearchParams {
  const searchParams: StockSearchParams = {}

  switch (sort.field) {
    case 'onHandQty':
      searchParams.sort = 'onHandQty'
      searchParams.order = sort.order
      break
    case 'scrappedQty':
      searchParams.sort = 'scrappedQty'
      searchParams.order = sort.order
      break
    case 'reservedQty':
      searchParams.sort = 'reservedQty'
      searchParams.order = sort.order
      break
    case 'pickedQty':
      searchParams.sort = 'pickedQty'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function stockFilterParams(
  params: StockSearchParams,
  filter: QueryStringFilter<keyof StockSearchParams>,
): StockSearchParams {
  const searchParams: StockSearchParams = {}

  // Check date object
  if (
    ['string', 'object'].includes(typeof filter.value) &&
    isDate(filter.value)
  ) {
    filter.value = moment(filter.value).utc().toISOString()
  }

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'warehouse._id':
      if (filter.value !== null) {
        searchParams['warehouse._id'] = filter.value
      }
      break
    case 'type':
      if (filter.value !== null) {
        searchParams.type = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      }
      break
    case 'onHandQty':
      if (filter.operator === '=') {
        searchParams['onHandQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['onHandQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['onHandQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['onHandQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['onHandQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['onHandQty:ne'] = filter.value
      }
      break
    case 'scrappedQty':
      if (filter.operator === '=') {
        searchParams['scrappedQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['scrappedQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['scrappedQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['scrappedQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['scrappedQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['scrappedQty:ne'] = filter.value
      }
      break
    case 'reservedQty':
      if (filter.operator === '=') {
        searchParams['reservedQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['reservedQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['reservedQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['reservedQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['reservedQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['reservedQty:ne'] = filter.value
      }
      break
    case 'pickedQty':
      if (filter.operator === '=') {
        searchParams['pickedQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['pickedQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['pickedQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['pickedQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['pickedQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['pickedQty:ne'] = filter.value
      }
      break
    case 'lot':
      if (filter.operator === '=') {
        searchParams.lot = filter.value
      } else if (filter.operator === '<>') {
        searchParams['lot:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['lot:ct'] = filter.value
      }
      break
    case 'expirationDate':
      if (filter.operator === '=') {
        searchParams['expirationDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['expirationDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['expirationDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['expirationDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['expirationDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['expirationDate:ne'] = filter.value
      }
      break
    case 'serial':
      if (filter.operator === '=') {
        searchParams.serial = filter.value
      } else if (filter.operator === '<>') {
        searchParams['serial:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['serial:ct'] = filter.value
      }
      break
    case 'product.SKU':
      if (filter.operator === '=') {
        searchParams['product.SKU'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['product.SKU:ne'] = filter.value
      }
      break
    case 'product.name':
      if (filter.operator === '=') {
        searchParams['product.name'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['product.name:ne'] = filter.value
      }
      break
    case 'place.0.label':
      if (filter.operator === '=') {
        searchParams['place.0.label'] = filter.value
      }
      break
    case 'place.1.label':
      if (filter.operator === '=') {
        searchParams['place.1.label'] = filter.value
      }
      break
    case 'place.2.label':
      if (filter.operator === '=') {
        searchParams['place.2.label'] = filter.value
      }
      break
    case 'place.3.label':
      if (filter.operator === '=') {
        searchParams['place.3.label'] = filter.value
      }
      break
    case 'place.4.label':
      if (filter.operator === '=') {
        searchParams['place.4.label'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function parseStockPageKeys(page: Page<Stock>) {
  return page.data.reduce<StocksListingKeys>(
    (acc, r) => parseStockKeys(r, acc),
    {},
  )
}

/**
 * Parse stocks external data
 * @param stock - the stock
 * @param extData - the external data object
 * @returns the extData updated
 */
export function parseStockKeys(
  stock: Stock,
  extData: StocksListingKeys = {},
): StocksListingKeys {
  const warehouseIds = uniq(
    stock.warehouse?._id
      ? [...(extData['warehouseIds'] || []), stock.warehouse!._id]
      : [...(extData['warehouseIds'] || [])],
  )
  return {
    warehouseIds,
  }
}
