import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

// Modal reference
import { BsModalRef } from 'ngx-bootstrap/modal'

/**
 * @deprecated
 */
@Component({
  selector: 'op-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteDeprecatedComponent implements OnInit {
  @Output() readonly action = new EventEmitter()
  @Input() id?: string
  @Input() title = 'Elimina'
  @Input() name?: string
  @Input() notes?: string
  @Input() type?: string

  // Models

  constructor(public modal: BsModalRef) {}

  ngOnInit() {
    this.title += ` ${this.type}`
  }

  delete() {
    this.modal.hide()
    this.action.emit({ action: 'delete', id: this.id })
  }
}
