import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Catalog,
  CatalogNotification,
  CatalogSearchField,
  CatalogSearchParams,
  CatalogSortField,
} from './catalog.model'
import { CatalogLocales } from '../../models/util.model'
import { CATALOG_DEFAULT_CODE } from './catalog.const'
import { LOCALE_DEFAULT_CODE } from '../../consts/locale.const'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import { DialogParams } from '../../models/notification.model'

/**
 * The catalog initial state
 */
const CATALOG_INITIAL_STATE: Partial<Catalog> = {
  name: {},
  code: undefined,
  locales: [],
}

/**
 * Initialize a partial catalog
 * @param catalog - the partial catalog
 * @returns the catalog updated
 */
export function initCatalog(catalog: Partial<Catalog> = {}): Catalog {
  return defaultsDeep(cloneDeep(catalog), CATALOG_INITIAL_STATE)
}

/**
 * Get a catalogable value
 * @param catalogValues - the catalog values
 * @param catalogCode - the catalog code
 * @param locale - the locale code
 * @returns the value
 */
export function getCatalogValue<T>(
  catalogValues: CatalogLocales<T>[] | undefined,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE
): T | undefined {
  if (!catalogValues) {
    return undefined
  }

  if (typeof catalogValues === 'string') {
    return catalogValues
  }

  const catalogValue = catalogValues.find(
    (cat) => cat.catalogCode === catalogCode
  )
  return catalogValue && catalogValue.locales
    ? catalogValue.locales[locale]
    : undefined
}

/**
 * Set a catalogable value
 * @param catalogValues - the catalog values
 * @param value - the value to set
 * @param catalogCode - the catalog code
 * @param locale - the locale code
 * @returns the catalogable value updated
 */
export function setCatalogValue<T>(
  catalogValues: CatalogLocales<T>[],
  value: T,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE
): CatalogLocales<T>[] {
  return [
    ...catalogValues.map((cat) =>
      cat.catalogCode === catalogCode
        ? {
            ...cat,
            locales: { ...cat.locales, [locale]: value },
          }
        : cat
    ),
  ]
}

/**
 * Parse catalog sort params
 * @param params - the search params
 * @param sort - the sort param value
 * @returns the catalog search params updated
 */
export function catalogSortParams(
  params: CatalogSearchParams,
  sort: QueryStringSort<CatalogSortField>
): CatalogSearchParams {
  const searchParams: CatalogSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse catalog filter params
 * @param params - the search params
 * @param filter - the filter param value
 * @returns the catalog search params updated
 */
export function catalogFilterParams(
  params: CatalogSearchParams,
  filter: QueryStringFilter<CatalogSearchField>
): CatalogSearchParams {
  const searchParams: CatalogSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse catalog notification to notification data
 * @param notification - the catalog notification
 * @returns the notification data
 */
export function parseCatalogNotification(
  notification: CatalogNotification
): DialogParams {
  switch (notification.kind) {
    case 'CATALOG_SAVED':
      return {
        message: 'Catalog saved successfully',
        color: 'success',
      }
    case 'CATALOG_DELETED':
      return {
        message: 'Catalog deleted successfully',
        color: 'success',
      }
    case 'MISSING_DEFAULT_LANGUAGE':
      return {
        title: 'Warning',
        message: 'The default catalog shoud have the default language',
        color: 'danger',
      }
    case 'CATALOG_INCOMPLETE':
      return {
        title: 'Warning',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
  }
}
