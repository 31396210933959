import { Inject, Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http'
import { switchMap, take } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'

@Injectable({
  providedIn: 'root',
})
export class RefreshInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.authManager.useAuthToken) {
      return next.handle(request)
    }

    return this.authManager.getRefreshToken$().pipe(
      take(1),
      switchMap((refreshToken) => {
        if (refreshToken) {
          request = request.clone({
            setHeaders: {
              'op-refresh-token': refreshToken,
            },
          })
        }

        return next.handle(request)
      }),
    )
  }
}
