import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs'

import {
  Attribute,
  AttributeGroup,
  Page,
  ATTRIBUTE_GROUP_DEFAULT_CODE,
  ATTRIBUTE_SCOPE,
  AttributeGroupSearchParams,
  AttributeGroupsService,
  AttributeSearchParams,
  AttributesService,
  initAttributeGroup,
} from '@evologi/shared/data-access-api'

import { StoreState } from '../store.state'
import { AuthStoreService } from '../auth'
import {
  selectAttributes,
  selectAttributesByCodes,
  selectGroupAttributes,
  selectGroups,
} from './attributes.selectors'
import {
  loadAttributes,
  loadAttributesByCodes,
  loadGroupAttributes,
  loadGroups,
  unsetAttribute,
  unsetGroup,
  upsertAttribute,
  upsertGroup,
} from './attributes.actions'

@Injectable({
  providedIn: 'root',
})
export class AttributesStoreService {
  private authCheck$: Observable<boolean> = this.auth
    .isAuthenticated$()
    .pipe(take(1))
  private policyCheck$: Observable<boolean> = this.auth
    .isAllowed$(ATTRIBUTE_SCOPE)
    .pipe(take(1))
  private attributesCheck$: Observable<boolean> = combineLatest([
    this.authCheck$,
    this.policyCheck$,
  ]).pipe(map(([authCheck, policyCheck]) => authCheck && policyCheck))
  private tenantId$: Observable<string> = this.attributesCheck$.pipe(
    switchMap((check) => (check ? this.auth.getTenantId$() : of(undefined))),
    filter((tenantId) => !!tenantId),
    distinctUntilChanged(),
    // eslint-disable-next-line
    map((tenantId) => tenantId!),
  )

  constructor(
    private store: Store<StoreState>,
    private auth: AuthStoreService,
    private attributesService: AttributesService,
    private attributeGroupsService: AttributeGroupsService,
  ) {}

  /**
   * Get all attribute groups of a tenant
   * @param groupIds - the group IDs
   * @returns the observable for load tenant groups
   */
  getGroups$(groupIds?: string[]): Observable<AttributeGroup[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store
          .select(selectGroups(tenantId, groupIds))
          .pipe(
            tap(
              (groups) =>
                !groups &&
                this.store.dispatch(loadGroups({ tenantId, groupIds })),
            ),
          ),
      ),
    )
  }

  /**
   * Get all product groups
   * @returns the observable for load product groups
   */
  getProductGroups$(
    groupIds?: string[],
  ): Observable<AttributeGroup[] | undefined> {
    return this.getGroups$(groupIds).pipe(
      map((groups) => [
        initAttributeGroup({
          _id: ATTRIBUTE_GROUP_DEFAULT_CODE,
          code: ATTRIBUTE_GROUP_DEFAULT_CODE,
          name: { default: 'All groups' },
        }),
        ...(groups || []),
      ]),
    )
  }

  /**
   * Reload groups
   * @returns the observable for reload tenant attribute groups
   */
  reloadGroups$(): Observable<void> {
    return this.tenantId$.pipe(
      map((tenantId) => this.store.dispatch(loadGroups({ tenantId }))),
    )
  }

  /**
   * Get all attributes of a group
   * @param groupId - the group ID
   * @returns the observable for load group attributes
   */
  getGroupAttributes$(groupId: string): Observable<Attribute[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store
          .select(selectGroupAttributes(tenantId, groupId))
          .pipe(
            tap(
              (attributes) =>
                !attributes &&
                this.store.dispatch(loadGroupAttributes({ tenantId, groupId })),
            ),
          ),
      ),
    )
  }

  /**
   * Get attributes loaded
   * @returns the observable for get attributes
   */
  getAttributes$(): Observable<Attribute[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) => this.store.select(selectAttributes(tenantId))),
    )
  }

  /**
   * Get attributes by IDs
   * @deprecated
   * @param attributeIds - the attribute IDs
   * @returns the observable for load attributes
   */
  loadAndGetAttributes$(
    attributeIds?: string[],
  ): Observable<Attribute[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store
          .select(selectAttributes(tenantId, attributeIds))
          .pipe(
            tap(
              (attributes) =>
                !attributes &&
                this.store.dispatch(loadAttributes({ tenantId, attributeIds })),
            ),
          ),
      ),
    )
  }

  /**
   * Get attributes by codes
   * @param attributeCodes - the attribute codes
   * @returns the observable for load attributes by codes
   */
  getAttributesByCodes$(
    attributeCodes: string[],
  ): Observable<Attribute[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store
          .select(selectAttributesByCodes(tenantId, attributeCodes))
          .pipe(
            tap(
              (attributes) =>
                !attributes &&
                this.store.dispatch(
                  loadAttributesByCodes({ tenantId, attributeCodes }),
                ),
            ),
          ),
      ),
    )
  }

  /**
   * List attributes by params
   * @param params - the search params
   * @param returnAll - the return all flag
   * @returns the observable for list attributes
   */
  listAttributes$(
    params?: AttributeSearchParams,
    returnAll = false,
  ): Observable<Attribute[]> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributesService.list$(params, returnAll)),
    )
  }

  /**
   * Search attributes by params
   * @param params - the search params
   * @param returnAll - the return all flag
   * @returns the observable for search attributes
   */
  searchAttributes$(
    params?: AttributeSearchParams,
    returnAll = false,
  ): Observable<Page<Attribute>> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributesService.search$(params, returnAll)),
    )
  }

  /**
   * List attribute groups by params
   * @param params - the search params
   * @param returnAll - the return all flag
   * @returns the observable for list attribute groups
   */
  listGroups$(
    params?: AttributeGroupSearchParams,
    returnAll = false,
  ): Observable<AttributeGroup[]> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributeGroupsService.list$(params, returnAll)),
    )
  }

  /**
   * Search attribute groups by params
   * @param params - the search params
   * @param returnAll - the return all flag
   * @returns the observable for search attribute groups
   */
  searchGroups$(
    params?: AttributeGroupSearchParams,
    returnAll = false,
  ): Observable<Page<AttributeGroup>> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributeGroupsService.search$(params, returnAll)),
    )
  }

  /**
   * Read an attribute
   * @param attributeId - the attribute ID
   * @returns the observable for load the attribute
   */
  readAttribute$(attributeId: string): Observable<Attribute> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributesService.read$(attributeId)),
    )
  }

  /**
   * Read an attribute group
   * @param groupId - the group ID
   * @returns the observable for load the attribute group
   */
  readGroup$(groupId: string): Observable<AttributeGroup> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributeGroupsService.read$(groupId)),
    )
  }

  /**
   * Upsert an attribute
   * @param attribute - the attribute to upsert
   * @returns the observable<Attribute> for upsert the attribute
   */
  upsertAttribute$(attribute: Attribute): Observable<Attribute> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      tap(() => this.store.dispatch(upsertAttribute({ attribute }))),
      map(() => attribute),
    )
  }

  /**
   * Upsert an attribute group
   * @param group - the attribute group
   * @returns the observable<AttributeGroup> for upsert the attribute group
   */
  upsertGroup$(group: AttributeGroup): Observable<AttributeGroup> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      tap(() => this.store.dispatch(upsertGroup({ group }))),
      map(() => group),
    )
  }

  /**
   * Delete an attribute
   * @param attribute - the attribute to delete
   * @returns the observable<Attribute> for delete the attribute
   */
  deleteAttribute$(attribute: Attribute): Observable<Attribute> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributesService.delete$(attribute._id)),
      tap(() => this.store.dispatch(unsetAttribute({ attribute }))),
      map(() => attribute),
    )
  }

  /**
   * Delete an attribute group
   * @param group - the attribute group to delete
   * @returns the observable<AttributeGroup> for delete the group
   */
  deleteGroup$(group: AttributeGroup): Observable<AttributeGroup> {
    return this.attributesCheck$.pipe(
      filter((check) => !!check),
      switchMap(() => this.attributeGroupsService.delete$(group._id)),
      tap(() => this.store.dispatch(unsetGroup({ group }))),
      map(() => group),
    )
  }
}
