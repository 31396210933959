import { Injectable } from '@angular/core'
import {
  combineLatest,
  concat,
  last,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs'
import {
  Product,
  ProductPageData,
  ProductScope,
  ProductType,
  ProductsListingSearchData,
  ProductsService,
  getProductChannelIds,
  getProductSupplierIds,
  getProductsPageCategories,
  parseProductsPageAttributes,
  parseProductsPageBrands,
  parseProductsPageCategories,
  parseProductsPageChannels,
  parseProductsPageFamilies,
  parseProductsPageHistoricData,
  parseProductsPageKits,
  parseProductsPageLocations,
  parseProductsPageManufacturers,
  parseProductsPageParents,
  parseProductsPageSuppliers,
  parseProductsPageTags,
} from '..'
import { CategoriesService, Category } from '../../categories'
import { Brand, BrandsService } from '../../brands'
import { Manufacturer, ManufacturersService } from '../../manufacturers'
import { ProductFamiliesService, ProductFamily } from '../../product-families'
import { Supplier, SuppliersService } from '../../suppliers'
import { Channel, ChannelsService } from '../../channels'
import { Tag, TagTarget, TagsService } from '../../tags'
import { Page } from '../../../models/util.model'
import { Location, LocationsService } from '../../locations'
import { OrdersService } from '../../orders'
import { SupplierOrdersService } from '../../supplier-orders'
import { GoodsReceivesService } from '../../goods-receives'
import { PickingListsService } from '../../picking-lists'

@Injectable({
  providedIn: 'root',
})
export class ProductsListingRepository {
  // Stores
  private brands: Brand[] = []
  private categories: Category[] = []
  private suppliers: Supplier[] = []
  private channels: Channel[] = []
  private kits: Product[] = []
  private manufacturers: Manufacturer[] = []
  private parents: Product[] = []
  private tags: Tag[] = []
  private families: ProductFamily[] = []
  private locations: Location[] = []

  private paginationLimit = 200

  constructor(
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private supplierOrdersService: SupplierOrdersService,
    private goodsReceivesService: GoodsReceivesService,
    private pickingListsService: PickingListsService,
    private categoriesService: CategoriesService,
    private brandsService: BrandsService,
    private manufacturersService: ManufacturersService,
    private familiesService: ProductFamiliesService,
    private suppliersService: SuppliersService,
    private channelsService: ChannelsService,
    private tagsService: TagsService,
    private locationsService: LocationsService,
  ) {}

  /**
   * Search products page data by search params
   * @param searchData - the search data
   * @returns the observable for search products
   */
  searchProducts$(
    searchData: ProductsListingSearchData,
  ): Observable<Page<ProductPageData>> {
    // Check pagination limit
    if (
      searchData.params.limit !== undefined &&
      searchData.params.limit > this.paginationLimit
    ) {
      return this._paginateProducts$(searchData)
    }

    return this._searchProducts$(searchData)
  }

  /**
   * Load product variant family IDs
   * @param product - the product
   * @returns the observable for load product variant family IDs
   */
  getVariantFamilyIds$(product: Product): Observable<string[]> {
    if (product.productType === ProductType.virtual) {
      return of([
        product._id,
        ...(product.variantsManagement?.childrenIds || []),
      ])
    }

    if (!product.parentId) {
      return of([])
    }

    return this.productsService
      .list$({ _id: product.parentId, fields: ['_id'] })
      .pipe(
        map((products) => products.map((p) => p._id)),
        map((productIds) => [product._id, ...productIds]),
      )
  }

  /**
   * Load order product IDs
   * @param orderId - the order ID
   * @returns the observable for load order product IDs
   */
  getOrderIds$(orderId: string): Observable<string[]> {
    return this.ordersService
      .read$(orderId)
      .pipe(map((order) => order.rows.map((r) => r.product._id)))
  }

  /**
   * Load supplier order product IDs
   * @param supplierOrderId - the supplier order ID
   * @returns the observable for load supplier-order product IDs
   */
  getSupplierOrderIds$(supplierOrderId: string): Observable<string[]> {
    return this.supplierOrdersService
      .read$(supplierOrderId)
      .pipe(
        map((supplierOrder) => supplierOrder.rows.map((r) => r.product._id)),
      )
  }

  /**
   * Load goods-receive product IDs
   * @param goodsReceiveId - the goods-receive ID
   * @returns the observable for load goods-receive product IDs
   */
  getGoodsReceiveIds$(goodsReceiveId: string): Observable<string[]> {
    return this.goodsReceivesService
      .read$(goodsReceiveId)
      .pipe(map((goodsReceive) => goodsReceive.rows.map((r) => r.product._id)))
  }

  /**
   * Load picking-list product IDs
   * @param pickingListId - the picking-list ID
   * @returns the observable for load picking-list product IDs
   */
  getPickingListIds$(pickingListId: string): Observable<string[]> {
    return this.pickingListsService
      .read$(pickingListId)
      .pipe(
        map((pickingList) => pickingList.missions.map((m) => m.product._id)),
      )
  }

  /**
   * Load products page external data
   * @param page - the products page
   * @param searchData - the search data
   * @returns the observable for load external data
   */
  getPageExternalData$(
    page: Page<ProductPageData>,
    searchData: ProductsListingSearchData,
  ): Observable<Page<ProductPageData>> {
    const obs$ = []

    // Brands
    if (searchData.fields.includes('brandId')) {
      obs$.push(this.getPageBrands$(page))
    }

    // Manufacturers
    if (searchData.fields.includes('manufacturerId')) {
      obs$.push(this.getPageManufacturers$(page))
    }

    // Parents
    if (searchData.fields.includes('parentId')) {
      obs$.push(this.getPageParents$(page))
    }

    // Kits
    if (searchData.fields.includes('kitIds')) {
      obs$.push(this.getPageKits$(page))
    }

    // Categories
    if (
      searchData.fields.includes('categories') ||
      searchData.fields.includes('categories.primaryId')
    ) {
      obs$.push(this.getPageCategories$(page, searchData.scope))
    }

    // Channels
    if (
      searchData.fields.includes('channels._id') ||
      searchData.fields.includes('externalSKUs.channelId')
    ) {
      obs$.push(this.getPageChannels$(page))
    }

    // Suppliers
    if (
      searchData.fields.includes('suppliers.supplierId') ||
      searchData.fields.includes('suppliers._id')
    ) {
      obs$.push(this.getPageSuppliers$(page))
    }

    // Tags
    if (searchData.fields.includes('tags')) {
      obs$.push(this.getPageTags$(page))
    }

    // Families
    if (searchData.fields.includes('family.code')) {
      obs$.push(this.getPageFamilies$(page))
    }

    // Sites
    if (searchData.fields.includes('sites')) {
      obs$.push(this.getPageSites$(page))
    }

    // Attributes
    if (searchData.fields.some((f) => f.startsWith('attribute_'))) {
      obs$.push(
        of(
          parseProductsPageAttributes(
            page,
            searchData.scope,
            searchData.attributes,
          ),
        ),
      )
    }

    // Historic quantity
    if (searchData.historicData?.date && searchData.historicData?.warehouseId) {
      const { warehouseId, date } = searchData.historicData
      obs$.push(this.getPageHistoricQuantities$(page, warehouseId, date))
    }

    if (!obs$.length) {
      return of(page)
    }

    return combineLatest(obs$).pipe(map((pages) => this._mergePages(pages)))
  }

  /**
   * Load products page brands
   * @param page - the product page
   * @returns the observable for load product brands
   */
  getPageBrands$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const brandIds = page.data.filter((p) => p.brandId).map((p) => p.brandId!)

    return this.brandsService.store$(brandIds, this.brands).pipe(
      tap((brands) => (this.brands = brands)),
      map((brands) => parseProductsPageBrands(page, brands)),
    )
  }

  /**
   * Load products page suppliers
   * @param page - the product page
   * @returns the observable for load product suppliers
   */
  getPageSuppliers$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const supplierIds = page.data.reduce<string[]>(
      (ids, p) => [...ids, ...getProductSupplierIds(p)],
      [],
    )

    return this.suppliersService.store$(supplierIds, this.suppliers).pipe(
      tap((suppliers) => (this.suppliers = suppliers)),
      map((suppliers) => parseProductsPageSuppliers(page, suppliers)),
    )
  }

  getPageFamilies$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const familyCodes = page.data
      .filter((p) => p.family?.code)
      // eslint-disable-next-line
      .map((p) => p.family?.code!)

    return this.familiesService.store$(familyCodes, this.families, 'code').pipe(
      tap((families) => (this.families = families)),
      map((families) => parseProductsPageFamilies(page, families)),
    )
  }

  /**
   * Load products page tags
   * @param page - the product page
   * @returns the observable for load product tags
   */
  getPageTags$(page: Page<ProductPageData>): Observable<Page<ProductPageData>> {
    const tagValues = page.data.reduce<string[]>(
      (ids, p) => [...ids, ...(p.tags || [])],
      [],
    )

    return this.tagsService
      .store$(tagValues, this.tags, 'value', TagTarget.products)
      .pipe(
        tap((tags) => (this.tags = tags)),
        map((tags) => parseProductsPageTags(page, tags)),
      )
  }

  /**
   * Load products page manufacturers
   * @param page - the product page
   * @returns the observable for load product manufacturers
   */
  getPageManufacturers$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const manufacturerIds = page.data
      .filter((p) => p.manufacturerId)
      // eslint-disable-next-line
      .map((p) => p.manufacturerId!)

    return this.manufacturersService
      .store$(manufacturerIds, this.manufacturers)
      .pipe(
        tap((manufacturers) => (this.manufacturers = manufacturers)),
        map((manufacturers) =>
          parseProductsPageManufacturers(page, manufacturers),
        ),
      )
  }

  /**
   * Load products page parents
   * @param page - the product page
   * @returns the observable for load product parents
   */
  getPageParents$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const parentIds = page.data
      .filter((p) => p.parentId)
      // eslint-disable-next-line
      .map((p) => p.parentId!)

    return this.productsService.store$(parentIds, this.parents).pipe(
      tap((parents) => (this.parents = parents)),
      map((parents) => parseProductsPageParents(page, parents)),
    )
  }

  /**
   * Load products page kits
   * @param page - the product page
   * @returns the observable for load product kits
   */
  getPageKits$(page: Page<ProductPageData>): Observable<Page<ProductPageData>> {
    const kitIds = page.data.reduce<string[]>(
      (acc, p) => [...acc, ...(p.kitIds || [])],
      [],
    )

    return this.productsService.store$(kitIds, this.kits).pipe(
      tap((kits) => (this.kits = kits)),
      map((kits) => parseProductsPageKits(page, kits)),
    )
  }

  /**
   * Load products page categories
   * @param page - the product page
   * @returns the observable for load product categories
   */
  getPageCategories$(
    page: Page<ProductPageData>,
    scope?: ProductScope,
  ): Observable<Page<ProductPageData>> {
    const categoryIds = getProductsPageCategories(page, scope)
    if (!categoryIds.length) {
      return of(page)
    }

    return this.categoriesService.store$(categoryIds, this.categories).pipe(
      tap((categories) => (this.categories = categories)),
      map((categories) =>
        parseProductsPageCategories(page, categories || [], scope),
      ),
    )
  }

  /**
   * Load products page channels
   * @param page - the product page
   * @returns the observable for load product channels
   */
  getPageChannels$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const channelIds = page.data.reduce<string[]>(
      (ids, p) => [...ids, ...getProductChannelIds(p)],
      [],
    )

    return this.channelsService.store$(channelIds, this.channels).pipe(
      tap((channels) => (this.channels = channels)),
      map((channels) => parseProductsPageChannels(page, channels)),
    )
  }

  /**
   * Load products page historic quantities
   * @param page - the product page
   * @param warehouseId - the warehouse ID
   * @param date - the date
   * @returns the observable for load products page data
   */
  getPageHistoricQuantities$(
    page: Page<ProductPageData>,
    warehouseId: string,
    date: string,
  ): Observable<Page<ProductPageData>> {
    const productIds = page.data.map((p) => p._id)
    return this.productsService
      .historicQuantities$(productIds, warehouseId, date)
      .pipe(
        map((historicQuantities) =>
          parseProductsPageHistoricData(page, historicQuantities),
        ),
      )
  }

  /**
   * Load products page locations
   * @param page - the product page
   * @returns the observable for load products locations data
   */
  getPageSites$(
    page: Page<ProductPageData>,
  ): Observable<Page<ProductPageData>> {
    const locationIds = page.data.reduce<string[]>(
      (acc, p) => [...acc, ...(p.sites?.map((s) => s.locationId) || [])],
      [],
    )
    return this.locationsService.store$(locationIds, this.locations).pipe(
      tap((locations) => (this.locations = locations)),
      map((locations) => parseProductsPageLocations(page, locations)),
    )
  }

  // Utilities

  private _mergePages(pages: Page<ProductPageData>[]): Page<ProductPageData> {
    const page: Page<ProductPageData> = {
      data: [],
      totalCount: 0,
    }

    for (const pg of pages) {
      page.data = this._mergePageData(pg.data, page.data)
      page.totalCount = pg.totalCount
    }

    return page
  }

  private _mergePageData(
    products1: ProductPageData[],
    products2: ProductPageData[],
  ): ProductPageData[] {
    const products = []

    for (const i in products1) {
      products.push({ ...products1[i], ...products2[i] })
    }

    return products
  }

  private _searchProducts$(
    searchData: ProductsListingSearchData,
  ): Observable<Page<ProductPageData>> {
    return this.productsService
      .search$(searchData.params)
      .pipe(switchMap((page) => this.getPageExternalData$(page, searchData)))
  }

  private _paginateProducts$(
    searchData: ProductsListingSearchData,
  ): Observable<Page<ProductPageData>> {
    const pages$ = this._parsePages$(searchData)
    let response: Page<ProductPageData> = {
      totalCount: 0,
      data: [],
    }

    return concat(...pages$).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      }),
      last(),
    )
  }

  private _parsePages$(
    searchData: ProductsListingSearchData,
  ): Observable<Page<ProductPageData>>[] {
    const observables$: Observable<Page<ProductPageData>>[] = []
    const totalCount = searchData.params.limit || this.paginationLimit
    let offset = 0

    while (offset <= totalCount) {
      observables$.push(
        this._searchProducts$({
          ...searchData,
          params: {
            ...searchData.params,
            limit: this.paginationLimit,
            offset,
          },
        }),
      )
      offset += this.paginationLimit
    }

    return observables$
  }
}
