import { createSelector } from '@ngrx/store'
import { StoreState } from '../store.state'
import { CacheState } from './cache.state'

export const selectCacheState = (state: StoreState) => state.cache

export const selectCache = (tenantId: string, cacheKey: string) =>
  createSelector(selectCacheState, (state: CacheState) => {
    const tenantCache = state[tenantId]

    if (!tenantCache) {
      return undefined
    }

    return tenantCache[cacheKey]
  })

export const selectCacheItems = (tenantId: string, cacheKey: string) =>
  createSelector(selectCacheState, (state: CacheState) => {
    const tenantCache = state[tenantId]

    if (!tenantCache || !tenantCache[cacheKey].items) {
      return undefined
    }

    return Object.values(tenantCache[cacheKey].items!)
  })

export const selectCacheTotalCount = (tenantId: string, cacheKey: string) =>
  createSelector(selectCacheState, (state: CacheState) => {
    const tenantCache = state[tenantId]

    if (!tenantCache) {
      return undefined
    }

    return tenantCache[cacheKey].totalCount
  })

export const selectCacheItem = (
  tenantId: string,
  cacheKey: string,
  itemKey: string,
) =>
  createSelector(selectCacheState, (state: CacheState) => {
    const tenantCache = state[tenantId]
    const cacheItems = tenantCache[cacheKey].items

    if (!cacheItems) {
      return undefined
    }

    return cacheItems[itemKey]
  })
