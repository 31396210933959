import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'
import { isEmpty } from 'lodash'

import { ProductScope, initProductScope } from '../../products'
import { CatalogLocales } from '../../../models/util.model'
import { CATALOG_DEFAULT_CODE } from '../catalog.const'

@Pipe({
  name: 'catalogLocale',
})
export class CatalogLocalePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    values: CatalogLocales<string>[],
    scope: ProductScope = initProductScope(),
    returnDefault = true,
    returnRaw = false,
  ): string | any {
    if (!values || isEmpty(values)) {
      return ''
    }

    if (typeof values === 'string') {
      return values
    }

    const locale =
      values.find(
        (val) =>
          val.catalogCode === scope.catalogCode && !!val.locales[scope.locale],
      ) ||
      (returnDefault && values.find((val) => val.catalogCode === 'default'))

    if (!locale) {
      return ''
    }

    let localeValue =
      locale.catalogCode === 'default' || !locale.locales[scope.locale]
        ? locale.locales.default
        : locale.locales[scope.locale]

    if (
      typeof localeValue === 'string' &&
      locale.catalogCode === CATALOG_DEFAULT_CODE &&
      scope.catalogCode !== CATALOG_DEFAULT_CODE
    ) {
      localeValue = `[${locale.locales.default}]`
    }

    if (
      typeof localeValue === 'object' &&
      localeValue['currency'] &&
      !returnRaw
    ) {
      localeValue =
        this.currencyPipe.transform(
          localeValue['value'],
          localeValue['currency'],
          'symbol',
          '1.2',
        ) || ''
    }

    return localeValue || ''
  }
}
