import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import { isDate } from '@evologi/shared/util-toolkit'

import {
  BordereauSearchParams,
  BordereauSearchField,
  BordereauSortField,
} from './bordereau.model'
import { Bordereau, BordereauStatus } from './bordereau.model'
import { Order } from '../orders/order.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const BORDEREAU_INITIAL_STATE: Partial<Bordereau> = {
  status: BordereauStatus.processing,
  packagesCount: 0,
  orders: [],
  date: new Date(),
}

// Init

export function initBordereau(bordereau: Partial<Bordereau> = {}): Bordereau {
  return defaultsDeep(cloneDeep(bordereau), BORDEREAU_INITIAL_STATE)
}

// Orders

export function addBordereauOrders(
  bordereau: Bordereau,
  orders: Order[]
): Bordereau {
  const orderIds = uniq([...orders.map((o) => o._id), ...bordereau.orders])
  const packagesCount =
    bordereau.packagesCount +
    orders.reduce((acc, o) => acc + o.packagesCount, 0)
  return {
    ...bordereau,
    orders: orderIds,
    packagesCount,
  }
}

export function removeBordereauOrders(
  bordereau: Bordereau,
  orders: Order[]
): Bordereau {
  const orderIdsToRemove = orders.map((o) => o._id)
  const orderIds = bordereau.orders.filter(
    (orderId) => !orderIdsToRemove.includes(orderId)
  )
  const packagesCount =
    bordereau.packagesCount -
    orders.reduce((acc, o) => acc + o.packagesCount, 0)

  return {
    ...bordereau,
    orders: orderIds,
    packagesCount,
  }
}

// Search

export function bordereauSortParams(
  params: BordereauSearchParams,
  sort: QueryStringSort<BordereauSortField>
): BordereauSearchParams {
  const searchParams: BordereauSearchParams = {}

  switch (sort.field) {
    case 'number':
      searchParams.sort = 'number'
      searchParams.order = sort.order
      break
    case 'date':
      searchParams.sort = 'date'
      searchParams.order = sort.order
      break
    case 'sentToCarrierDate':
      searchParams.sort = 'sentToCarrierDate'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function bordereauFilterParams(
  params: BordereauSearchParams,
  filter: QueryStringFilter<BordereauSearchField>
): BordereauSearchParams {
  const searchParams: BordereauSearchParams = {}

  // Check date object
  if (typeof filter.value === 'object' && isDate(filter.value)) {
    filter.value = filter.value.toISOString()
  }

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'number':
      if (filter.operator === '=') {
        searchParams.number = filter.value
      } else if (filter.operator === '<') {
        searchParams['number:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['number:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['number:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['number:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['number:ne'] = filter.value
      }
      break
    case 'date':
      if (filter.operator === '=') {
        searchParams.date = filter.value
      } else if (filter.operator === '<') {
        searchParams['date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['date:ne'] = filter.value
      }
      break
    case 'sentToCarrierDate':
      if (filter.operator === '=') {
        searchParams.sentToCarrierDate = filter.value
      } else if (filter.operator === '<') {
        searchParams['sentToCarrierDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['sentToCarrierDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['sentToCarrierDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['sentToCarrierDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['sentToCarrierDate:ne'] = filter.value
      }
      break
    case 'carrierId':
      if (filter.value !== null) {
        searchParams.carrierId = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams.status = filter.value
      }
      break
    case 'packagesCount':
      if (filter.operator === '=') {
        searchParams.packagesCount = filter.value
      } else if (filter.operator === '<') {
        searchParams['packagesCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['packagesCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['packagesCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['packagesCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['packagesCount:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
