import { HttpErrorResponse } from '@angular/common/http'
import {
  Replenishment,
  ReplenishmentCreationParams,
  ReplenishmentStatus,
} from '../replenishment.model'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import {
  EMPTY,
  Observable,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import { initReplenishment } from '../replenishment.lib'
import { ReplenishmentsRepository } from '../replenishments.repository'
import { ReplenishmentsService } from '../replenishments.service'
import { Product, ProductsService } from '../../products'

export interface ReplenishmentDetailState {
  replenishmentId?: string
  replenishment?: Replenishment
  product?: Product
  error?: HttpErrorResponse
  toClose: boolean
  toReload: boolean
  isLoading: boolean
  isModified: boolean
}

const REPLENISHMENT_DETAIL_INITIAL_STATE: ReplenishmentDetailState = {
  toClose: false,
  toReload: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class ReplenishmentDetailUseCase extends ComponentStore<ReplenishmentDetailState> {
  constructor(
    private replenishmentsRepository: ReplenishmentsRepository,
    private replenishmentsService: ReplenishmentsService,
    private productService: ProductsService,
  ) {
    super(REPLENISHMENT_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<ReplenishmentDetailState> = this.select(
    (state) => state,
  )

  readonly selectCreatedBy$: Observable<string | undefined> = this.select(
    (state) => state.replenishment?.createdBy,
  )

  readonly selectUpdatedBy$: Observable<string | undefined> = this.select(
    (state) => state.replenishment?.updatedBy,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectReplenishmentId$: Observable<string | undefined> = this.select(
    (state) => state.replenishment?._id,
  )

  readonly selectReplenishmentStatus$: Observable<
    ReplenishmentStatus | undefined
  > = this.select((state) => state.replenishment?.status)

  readonly selectReplenishment$: Observable<Replenishment | undefined> =
    this.select((state) => state.replenishment)

  readonly selectReplenishmentLoaded$: Observable<Replenishment | undefined> =
    this.select((state) => state).pipe(
      filter((state) => !state.isLoading),
      map((state) => state.replenishment),
    )

  readonly selectProduct$: Observable<Product | undefined> = this.select(
    (state) => state,
  ).pipe(
    filter((state) => !state.isLoading),
    map((state) => state.product),
  )

  // Effects

  readonly init$ = this.effect(
    (replenishment$: Observable<Partial<Replenishment> | undefined>) => {
      return replenishment$.pipe(
        map((replenishment) => initReplenishment(replenishment)),
        tap((replenishment) => this.setReplenishment(replenishment)),
      )
    },
  )

  readonly set$ = this.effect((replenishment$: Observable<Replenishment>) => {
    return replenishment$.pipe(
      tap((replenishment) => this.setReplenishment(replenishment)),
    )
  })

  readonly read$ = this.effect((replenishmentId$: Observable<string>) => {
    return replenishmentId$.pipe(
      tap((replenishmentId) => this.setReplenishmentId(replenishmentId)),
      switchMap((replenishmentId) =>
        this.replenishmentsService.read$(replenishmentId).pipe(
          tapResponse(
            (replenishment) => this.setReplenishment(replenishment),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly readProduct$ = this.effect((productId$: Observable<string>) => {
    return productId$.pipe(
      switchMap((productId) =>
        this.productService.read$(productId).pipe(
          tapResponse(
            (product) => this.setProduct(product),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      switchMap((toClose) =>
        this.selectReplenishment$.pipe(
          take(1),
          switchMap((replenishment) => {
            if (!replenishment || !replenishment.product) {
              return EMPTY
            }

            const obs$ = replenishment._id
              ? this.replenishmentsService.update$(
                  replenishment._id,
                  replenishment,
                )
              : this.replenishmentsService.create$(
                  replenishment as ReplenishmentCreationParams,
                )

            return obs$.pipe(
              tapResponse(
                (replenishment) => {
                  this.replenishmentsRepository.notify({
                    kind: 'REPLENISHMENT_SAVED',
                  })
                  this.setReplenishmentSaved({ replenishment, toClose })
                },
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
          }),
        ),
      ),
    )
  })

  readonly cancel$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectReplenishment$),
      switchMap(([empty, replenishment]) => {
        if (!replenishment) {
          return EMPTY
        }

        return this.replenishmentsRepository
          .showCancelDialog$(replenishment)
          .pipe(
            switchMap((confirmation) => {
              if (!confirmation) {
                return EMPTY
              }

              return this.replenishmentsService.cancel$(replenishment._id).pipe(
                tapResponse(
                  () => {
                    this.replenishmentsRepository.notify({
                      kind: 'REPLENISHMENT_CANCELED',
                    })
                    this.setFlags({
                      toClose: true,
                      toReload: true,
                      isLoading: false,
                    })
                  },
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              )
            }),
          )
      }),
    )
  })

  // Reducers

  readonly setReplenishmentId = this.updater(
    (state, replenishmentId: string): ReplenishmentDetailState => ({
      replenishmentId,
      toClose: false,
      toReload: false,
      isLoading: true,
      isModified: false,
    }),
  )

  readonly setReplenishment = this.updater(
    (state, replenishment: Replenishment): ReplenishmentDetailState => ({
      ...state,
      replenishment,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setProduct = this.updater(
    (state, product: Product): ReplenishmentDetailState => ({
      ...state,
      product: product,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setReplenishmentSaved = this.updater(
    (
      state,
      params: { replenishment: Replenishment; toClose?: boolean },
    ): ReplenishmentDetailState => ({
      ...state,
      replenishment: params.replenishment,
      toClose: params.toClose || false,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly updateReplenishment = this.updater(
    (state, replenishment: Replenishment): ReplenishmentDetailState => {
      return {
        ...state,
        replenishment,
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ReplenishmentDetailState => ({
      ...state,
      error,
      isLoading: false,
    }),
  )

  readonly setFlags = this.updater(
    (
      state,
      flags: { toClose?: boolean; toReload?: boolean; isLoading?: boolean },
    ): ReplenishmentDetailState => {
      return {
        ...state,
        ...flags,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): ReplenishmentDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )
}
