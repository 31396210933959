import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

@Directive({
  selector: '[opViewAttribute]',
})
export class ViewAttributeDirective implements OnInit {
  private field?: string

  constructor(
    private templateRef: TemplateRef<any>,
    private changes: ChangeDetectorRef,
    private viewContainer: ViewContainerRef
  ) {}

  // Lifecycle

  ngOnInit(): void {
    this.checkElement()
  }

  // Setters

  @Input() set opViewAttribute(field: string | undefined) {
    if (field) {
      this.field = field
    }
  }

  // Useful

  checkElement() {
    if (!this.field) {
      this.toggleElement(false)
      return
    }

    this.toggleElement(this.field.startsWith('attribute_'))
    return
  }

  toggleElement(canBeShown: boolean) {
    this.viewContainer.clear()

    if (canBeShown) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }

    this.changes.markForCheck()
  }
}
