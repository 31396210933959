import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Observable, filter, map, switchMap, tap, withLatestFrom } from 'rxjs'

import { Warehouse, WarehouseSearchParams } from '../warehouse.model'
import { WarehousesService } from '../warehouses.service'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { WarehouseNotification } from '../libs/warehouse-notification.lib'

interface WarehousesListingState {
  filters?: WarehouseSearchParams
  data?: Warehouse[]
  totalCount?: number
  notification?: WarehouseNotification
  error?: HttpErrorResponse
  isLoading: boolean
  isInitialized: boolean
}

const WAREHOUSES_INITIAL_STATE: WarehousesListingState = {
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class WarehousesListingUseCase extends ComponentStore<WarehousesListingState> {
  constructor(private warehousesService: WarehousesService) {
    super(WAREHOUSES_INITIAL_STATE)
  }

  // Selectors

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectFilters$: Observable<WarehouseSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {})),
  )

  readonly selectWarehouses$: Observable<Warehouse[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectNotification$: Observable<WarehouseNotification | undefined> =
    this.select((state) => state.notification)

  readonly selectPage$: Observable<Page<Warehouse>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectWarehouses$,
          this.selectTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true },
        ),
      ),
    )

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<WarehouseSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.warehousesService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error) => this.parseError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<WarehouseSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectTotalCount$),
        map(([params, limit]) => ({ ...params, limit })),
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.warehousesService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error) => this.parseError(error),
            ),
          ),
        ),
      )
    },
  )

  // Reducers

  private parseError(errorResponse: unknown) {
    if (errorResponse instanceof HttpErrorResponse) {
      this.setError(errorResponse)
    } else if (errorResponse instanceof WarehouseNotification) {
      this.setNotification(errorResponse)
    }
  }

  readonly setFilters = this.updater(
    (state, filters?: WarehouseSearchParams): WarehousesListingState => ({
      filters,
      totalCount: state.totalCount,
      isLoading: true,
      isInitialized: state.isInitialized,
    }),
  )

  readonly setPage = this.updater(
    (state, page: Page<Warehouse>): WarehousesListingState => ({
      ...state,
      data: page.data,
      totalCount: page.totalCount,
      isLoading: false,
      isInitialized: true,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): WarehousesListingState => ({
      ...state,
      data: undefined,
      totalCount: 0,
      error,
      isLoading: false,
      isInitialized: true,
    }),
  )

  readonly setWarehouses = this.updater(
    (state, data: Warehouse[]): WarehousesListingState => ({
      ...state,
      data,
    }),
  )

  readonly setNotification = this.updater(
    (state, notification: WarehouseNotification): WarehousesListingState => ({
      ...state,
      notification,
    }),
  )
}
