import { Inject, Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http'
import { filter, switchMap, take } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'

@Injectable({
  providedIn: 'root',
})
export class ReviveInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.authManager.isReviving$().pipe(
      take(1),
      switchMap((isReviving) => {
        if (!isReviving) {
          return next.handle(request)
        }

        return this.authManager.isReviving$().pipe(
          filter((isReviving) => request.url.includes('/auth') || !isReviving),
          take(1),
          switchMap(() => next.handle(request)),
        )
      }),
    )
  }
}
