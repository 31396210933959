import { Pipe, PipeTransform } from '@angular/core'
import { EMPTY, Observable, catchError } from 'rxjs'
import { CategoriesService } from '../categories.service'
import { Category } from '../category.model'

@Pipe({
  name: 'category',
})
export class CategoryPipe implements PipeTransform {
  constructor(private categoriesService: CategoriesService) {}

  transform(categoryId?: string): Observable<Category> {
    if (!categoryId) {
      return EMPTY
    }

    return this.categoriesService
      .read$(categoryId)
      .pipe(catchError(() => EMPTY))
  }
}
