import { Component } from '@angular/core'

// Environments
import { environment } from '../../../environments/environment'
import packageJson from 'package.json'

@Component({
  selector: 'op-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  public production = environment.production
  public localhost = environment.localhost
  public version = packageJson.version
}
