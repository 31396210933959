import { HttpErrorResponse } from '@angular/common/http'
import { ErrorWrapper } from '../models/error.model'

/**
 * Parse local error
 * @param error - The local error
 * @returns The error wrapper
 */
export function parseClientError(error: Error): ErrorWrapper {
  return {
    source: error,
    message: error.message ?? error.toString(),
  }
}

/**
 * Parse remote error
 * @param error - The http error response
 * @returns The error wrapper
 */
export function parseServerError(error: HttpErrorResponse): ErrorWrapper {
  return {
    message: parseErrorMessage(error),
    title: parseErrorKind(error),
    source: error,
  }
}

/**
 * Parse OrderPod error kind
 * @default 'UNKNOWN_ERROR'
 * @param httpError - The http error response
 * @returns The error kind in uppercase snake_case mode. E.g. INVALID_CREDENTIALS
 */
export function parseErrorKind(httpError: HttpErrorResponse): string {
  const opError = httpError.error
  return opError?.error || 'UNKNOWN_ERROR'
}

/**
 * Parse OrderPod error message
 * @default 'Unknown Error'
 * @param httpError - The http error response
 * @returns The error message explanation. E.g. Invalid credentials
 */
export function parseErrorMessage(httpError: HttpErrorResponse): string {
  const opError = httpError.error
  return opError?.message || 'Unknown Error'
}

/**
 * 
 * UNKNOWN ERROR (Offline)
 * 
 * 
 * // Show a toast error
          // parseError(this.toastService, this.translateService, err)
 {
  error: ProgressEvent {isTrusted: true, lengthComputable: false, loaded: 0, total: 0, type: 'error', …}
  headers: HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, headers: Map(0)}
  message: "Http failure response for https://stagindqwwqdp/api/auth/authenticate: 0 Unknown Error"
  name: "HttpErrorResponse"
  ok: false
  status: 0
  statusText: "Unknown Error"
  url: "https://stagindqwwqdp/api/auth/authe
 }
 * 
 */

/**
 *
 *  OrderPod ERROR
 */
/* 

INVALID_CREDENTIALS
{
  error: {
    error: 'INVALID_CREDENTIALS', 
    message: 'Invalid credentials', 
    details: {}, 
    statusCode: 401
  },
  headers: HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ}
  message: "Http failure response for https://staging.orderpod.app/api/auth/authenticate: 401 OK"
  name: "HttpErrorResponse"
  ok: false
  status: 401
  statusText: "OK"
  url: "https://staging.orderpod.app/api/a
}

SESSION_CONFLICT
{
  error: {
    details: {},
    error: "SESSION_CONFLICT",
    message: "There is a current active session for this user"
    statusCode: 409
  },
  headers: HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ}
  message: "Http failure response for https://staging.orderpod.app/api/auth/authenticate: 409 OK"
  name: "HttpErrorResponse"
  ok: false
  status: 409
  statusText: "OK"
  url: "https://staging.orderpod.app/api/auth/authenticate"
}

GSPED_ERROR
{
  error: {
    details: {
      request: {...},
      response: {
        error: "rcpt_email: valore obbligatorio per questo corriere"
      },
      statusCode: 400
    },
    error: "GSPED_ERROR",
    message: "rcpt_email: valore obbligatorio per questo corriere",
    statusCode: 409,
  },
  headers: HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ},
  message: "Http failure response for https://orderpod.app/api/v2/orders/633d5f28a22b56505921bc54/print: 409 OK",
  name: "HttpErrorResponse",
  ok: false,
  status: 409,
  statusText: "OK",
  url: "https://orderpod.app/api/v2/orders/633d5f28a22b56505921bc54/print"
}
*/
