import { cloneDeep, defaultsDeep, isEqual } from 'lodash'
import {
  GoodsReceiveLocation,
  GoodsReceiveLocationBatch,
  GoodsReceiveProduct,
  GoodsReceiveRow,
} from '../goods-receive.model'
import {
  Product,
  ProductBatch,
  ProductLocation,
  ProductSelection,
  getProductPurchasingPrice,
  getProductSupplierSKU,
  initProduct,
} from '../../products'
import {
  SupplierOrderRow,
  SupplierOrderRowSelection,
} from '../../supplier-orders/supplier-order.model'
import { getCatalogValue } from '../../catalogs'
import { calculatePrices } from '../../../libs/dinero.lib'
import { DeepPartial } from '../../../models/util.model'

// Initial states

const GOODS_RECEIVE_PRODUCT_INITIAL_STATE: Partial<GoodsReceiveProduct> = {
  putAwayLocations: [],
}

const GoodsReceiveRowInitialState: DeepPartial<GoodsReceiveRow> = {
  product: GOODS_RECEIVE_PRODUCT_INITIAL_STATE,
  productTaxRate: 0,
  orderedQty: 0,
  receivedQty: 0,
  invoicedQty: 0,
  netPrice: 0,
  invoicedNetPrice: 0,
  invoicedNetPriceAmount: 0,
  notCompliantQty: 0,
  notCompliantQuality: 0,
  notCompliantPrice: 0,
  notCompliantPriceAmount: 0,
  isPartial: false,
}

// Init

export function initGoodsReceiveRow(
  row: Partial<GoodsReceiveRow> = {}
): GoodsReceiveRow {
  return defaultsDeep(cloneDeep(row), GoodsReceiveRowInitialState)
}

export function createGoodsReceiveRows(
  products: ProductSelection[],
  supplierId?: string,
  taxRate?: number
): GoodsReceiveRow[] {
  return products.map((p) =>
    createGoodsReceiveRow(p.product, p.qty, supplierId, taxRate)
  )
}

export function createGoodsReceiveSupplierRows(
  selection: SupplierOrderRowSelection[]
): GoodsReceiveRow[] {
  return selection.map((s) =>
    createGoodsReceiveSupplierRow(s.row, s.supplierOrderId, s.qty)
  )
}

export function createGoodsReceiveRow(
  product: Product,
  qty?: number,
  supplierId?: string,
  taxRate?: number
): GoodsReceiveRow {
  const _product = initProduct(product)

  const name = getCatalogValue(_product.name) || 'PRODUCT'
  const supplierSKU = getProductSupplierSKU(_product, supplierId)
  const orderedQty = qty ?? 1
  const productTaxRate = taxRate || _product.taxRate || 0
  const price = getProductPurchasingPrice(_product, supplierId)

  return initGoodsReceiveRow({
    product: {
      _id: _product._id,
      SKU: _product.SKU,
      name,
      supplierSKU,
      price,
    },
    productTaxRate,
    orderedQty,
  })
}

export function createGoodsReceiveSupplierRow(
  supplierOrderRow: SupplierOrderRow,
  supplierOrderId: string,
  qty?: number
): GoodsReceiveRow {
  const { _id, SKU, name, supplierSKU } = supplierOrderRow.product
  const orderedQty =
    qty !== undefined
      ? qty
      : supplierOrderRow.orderedQty - supplierOrderRow.receivedQty

  return initGoodsReceiveRow({
    product: {
      _id,
      SKU,
      name,
      supplierSKU,
      price: supplierOrderRow.unitPrice,
    },
    productTaxRate: supplierOrderRow.productTaxRate,
    orderedQty,
    supplierOrderId,
  })
}

// Amounts

export function updateGoodsReceiveRowAmounts(
  row: GoodsReceiveRow
): GoodsReceiveRow {
  // Quantities
  const orderedQty = !row.orderedQty ? 0 : +row.orderedQty
  const receivedQty =
    !row.receivedQty || row.receivedQty.toString() === '' ? 0 : +row.receivedQty
  const invoicedQty = !row.invoicedQty ? 0 : +row.invoicedQty

  // Not compliants
  let notCompliantQuality = row.notCompliantQuality
    ? Math.abs(+row.notCompliantQuality)
    : 0
  notCompliantQuality =
    notCompliantQuality > receivedQty ? receivedQty : notCompliantQuality
  const notCompliantQty = invoicedQty - receivedQty

  const isPartial = orderedQty > receivedQty

  // Tax rate
  const taxRate = row.productTaxRate || 0

  // Prices
  const netPrice = !row.netPrice ? row.product.price || 0 : +row.netPrice
  const invoicedNetPrice = !row.invoicedNetPrice ? 0 : +row.invoicedNetPrice

  const productPrices = calculatePrices({
    taxRate,
    price: netPrice,
  })
  const productInvoicedPrices = calculatePrices({
    taxRate,
    price: invoicedNetPrice,
  })
  const invoicedNetPriceAmount =
    productInvoicedPrices.price.multiply(invoicedQty)
  const notCompliantPrice = productInvoicedPrices.price.subtract(
    productPrices.price
  )
  const notCompliantPriceAmount = notCompliantPrice.multiply(
    receivedQty - notCompliantQuality
  )

  return {
    ...row,
    productTaxRate: taxRate,

    orderedQty,
    receivedQty,
    invoicedQty,

    netPrice: productPrices.price.toUnit(),
    invoicedNetPrice: productInvoicedPrices.price.toUnit(),
    invoicedNetPriceAmount: invoicedNetPriceAmount.toUnit(),
    notCompliantQty,
    notCompliantQuality,
    notCompliantPrice: notCompliantPrice.toUnit(),
    notCompliantPriceAmount: notCompliantPriceAmount.toUnit(),

    isPartial,
  }
}

export function removeGoodsReceiveRow(
  rows: GoodsReceiveRow[],
  row: GoodsReceiveRow
): GoodsReceiveRow[] {
  return rows.filter((_row) =>
    row._id ? _row._id !== row._id : !isEqual(_row, row)
  )
}

export function copyRowReceivedData(row: GoodsReceiveRow): GoodsReceiveRow {
  return {
    ...row,
    invoicedQty: row.receivedQty,
    invoicedNetPrice: row.netPrice,
  }
}

export function copyRowReceivedQty(row: GoodsReceiveRow): GoodsReceiveRow {
  return {
    ...row,
    invoicedQty: row.receivedQty,
  }
}

export function copyRowOrderedQty(row: GoodsReceiveRow): GoodsReceiveRow {
  return {
    ...row,
    invoicedQty: row.orderedQty,
  }
}

// Locations

export function parseRowProductLocations(
  row: GoodsReceiveRow,
  product: Product
): ProductLocation[] {
  const locationIds = row.product.putAwayLocations?.map((l) => l._id) || []
  return product.locations
    .filter((l) => locationIds.includes(l._id!))
    .map((productLocation) => {
      const rowLocation = row.product.putAwayLocations?.find(
        (pl) => pl._id === productLocation._id
      )
      return parseRowProductLocation(rowLocation!, productLocation)
    })
}

export function parseRowProductLocation(
  rowLocation: GoodsReceiveLocation,
  productLocation: ProductLocation
): ProductLocation {
  return {
    ...productLocation,
    onHandQty: rowLocation.quantity,
    batches: parseRowProductLocationBatches(
      rowLocation.batches,
      productLocation.batches
    ),
  }
}

export function parseRowProductLocationBatches(
  rowBatches?: GoodsReceiveLocationBatch[],
  productBatches?: ProductBatch[]
): ProductBatch[] | undefined {
  if (!rowBatches || !productBatches) {
    return
  }

  return productBatches
    .filter((pb) => !!rowBatches.find((rb) => compareBatches(pb, rb)))
    .map((pb) => {
      const rowBatch = rowBatches.find((rb) => compareBatches(pb, rb))
      return {
        ...pb,
        onHandQty: rowBatch?.quantity || pb.onHandQty,
      }
    })
}

function compareBatches(
  productBatch: ProductBatch,
  rowBatch: GoodsReceiveLocationBatch
) {
  return (
    productBatch.expirationDate === rowBatch.expirationDate &&
    productBatch.lot === rowBatch.lot &&
    productBatch.serial === rowBatch.serial
  )
}
