import { cloneDeep, defaultsDeep } from 'lodash'
import moment from 'moment'

import { SalesSearchParams, SalesSearchPeriod } from './sale.model'
import { ProductStatus } from '../products/product.model'

// Initial state

const SalesSearchParams: SalesSearchParams = {
  products: {
    status: ProductStatus.enabled,
  },
  periods: [
    {
      startDate: new Date(),
      endDate: new Date(),
    },
    {
      startDate: new Date(),
      endDate: new Date(),
    },
    {
      startDate: new Date(),
      endDate: new Date(),
    },
  ],
}

// Init functions

export function initSalesSearchParams(
  searchParams: Partial<SalesSearchParams> = {},
): SalesSearchParams {
  return defaultsDeep(cloneDeep(searchParams), SalesSearchParams)
}

export function initSalesSearchCurrentPeriod(
  days = 7,
  searchByWeek = false,
): SalesSearchParams {
  const firstPeriod = {
    startDate: moment().subtract(days, 'd').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  }
  return updateSalesSearchPeriods(
    initSalesSearchParams({ periods: [firstPeriod] }),
    searchByWeek,
  )
}

// Period functions

export function updateSalesSearchPeriods(
  searchParams: SalesSearchParams,
  searchByWeek = false,
): SalesSearchParams {
  if (!searchParams.periods.length) {
    return searchParams
  }

  const firstPeriod = searchParams.periods[0]
  let secondPeriodStart
  let secondPeriodEnd
  let thirdPeriodStart
  let thirdPeriodEnd

  // Get difference by days
  const days = moment(firstPeriod.endDate).diff(firstPeriod.startDate, 'd')

  if (searchByWeek) {
    // Calculate by week and day number
    const currentWeekStart = moment(firstPeriod.startDate).week()
    const currentDayStart = moment(firstPeriod.startDate).day()

    secondPeriodStart = moment()
      .subtract(1, 'y')
      .week(currentWeekStart)
      .day(currentDayStart)
      .startOf('day')
    secondPeriodEnd = moment(secondPeriodStart).add(days, 'd').endOf('day')
    thirdPeriodStart = moment(secondPeriodEnd).add(1, 'd').startOf('day')
    thirdPeriodEnd = moment(thirdPeriodStart).add(days, 'd').endOf('day')
  } else {
    // Calculate secondPeriod periods from current one
    secondPeriodStart = moment(firstPeriod.startDate)
      .subtract(1, 'y')
      .startOf('day')
    secondPeriodEnd = moment(firstPeriod.endDate).subtract(1, 'y').endOf('day')
    thirdPeriodStart = moment(secondPeriodEnd).add(1, 'd').startOf('day')
    thirdPeriodEnd = moment(thirdPeriodStart).add(days, 'd').endOf('day')
  }

  return {
    ...searchParams,
    periods: [
      firstPeriod,
      {
        startDate: secondPeriodStart.toDate(),
        endDate: secondPeriodEnd.toDate(),
      },
      {
        startDate: thirdPeriodStart.toDate(),
        endDate: thirdPeriodEnd.toDate(),
      },
    ],
  }
}

export function checkSalesSearchPeriods(
  searchParams: SalesSearchParams,
): SalesSearchParams {
  return {
    ...searchParams,
    periods: searchParams.periods.map((period) => checkPeriod(period)),
  }
}

function checkPeriod(period: SalesSearchPeriod): SalesSearchPeriod {
  return {
    startDate: moment(period.startDate).startOf('day').toDate(),
    endDate: moment(period.endDate).endOf('day').toDate(),
  }
}
