import { Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { PaymentsService } from '../payments.service'
import { Payment } from '../payment.model'

@Pipe({
  name: 'payment',
})
export class PaymentPipe implements PipeTransform {
  constructor(private paymentsService: PaymentsService) {}

  transform(paymentId: string | undefined): Observable<Payment | undefined> {
    if (!paymentId) {
      return of(undefined)
    }

    return this.paymentsService.read$(paymentId)
  }
}
