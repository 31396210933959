import { createReducer, on } from '@ngrx/store'
import {
  LOCALE_DEFAULT_CODE,
  CATALOG_DEFAULT_CODE,
  ProductScope,
} from '@evologi/shared/data-access-api'

import * as ProductsActions from './products.actions'
import { ProductsState } from './products.state'

const initialState: ProductsState = {
  scope: {
    catalogCode: CATALOG_DEFAULT_CODE,
    locale: LOCALE_DEFAULT_CODE,
  },
}

export const productsReducers = createReducer(
  initialState,

  // Lifecycle

  on(ProductsActions.destroyProducts, (state) => initialState),

  // Crud

  on(
    ProductsActions.setProductsScope,
    (state, action: { scope: ProductScope }): ProductsState => ({
      ...state,
      scope: action.scope,
    }),
  ),

  on(
    ProductsActions.setProductsToModify,
    (state, action: { ids: string[] }): ProductsState => ({
      ...state,
      productsToModify: action.ids.map((_id) => ({ _id, status: 'PENDING' })),
    }),
  ),

  on(
    ProductsActions.unsetProductsToModify,
    (state): ProductsState => ({
      ...state,
      productsToModify: undefined,
    }),
  ),

  on(
    ProductsActions.processProductToModify,
    (state, action: { id: string }): ProductsState => ({
      ...state,
      productsToModify: state.productsToModify?.map((p) => ({
        ...p,
        status: p._id === action.id ? 'PROCESSING' : p.status,
      })),
    }),
  ),
)
