import { cloneDeep, defaultsDeep } from 'lodash'
import { ChannelReference } from '../channel.model'

const REF_INITIAL_STATE: Partial<ChannelReference> = {
  _id: undefined,
  reference: undefined,
  isActive: true,
}

export function initReference(
  ref: Partial<ChannelReference> = {}
): ChannelReference {
  return defaultsDeep(cloneDeep(ref), REF_INITIAL_STATE)
}

export function addReference(
  references: ChannelReference[] | undefined,
  reference: ChannelReference
): ChannelReference[] {
  return [...(references || []), reference]
}

export function removeReference(
  references: ChannelReference[] | undefined,
  i?: number
): ChannelReference[] | undefined {
  if (!references) {
    return references
  }

  const refIndex = i ?? references.length - 1
  const refs = references.filter((r, index) => index !== refIndex)
  return refs.length ? refs : undefined
}
