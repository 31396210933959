import { createReducer, on } from '@ngrx/store'
import { Locale } from '@evologi/shared/data-access-api'

import { LocalesState, LocalesTenant } from './locales.state'
import * as LocalesActions from './locales.actions'
import { parseItems } from '../../libs/state.lib'

const initialState: LocalesState = {}

export const localesReducers = createReducer(
  initialState,

  // Lifecycle

  on(LocalesActions.destroyLocales, (): LocalesState => initialState),

  // Crud

  on(
    LocalesActions.setLocales,
    (
      state: LocalesState,
      action: { tenantId: string; locales: Locale[] },
    ): LocalesState => {
      const tenantLocales: LocalesTenant = state[action.tenantId] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantLocales,
          currencies: parseItems<Locale>(action.locales),
        },
      }
    },
  ),
)
