import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'
import { Carrier, CarrierSearchParams } from './carrier.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'carriers'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CarriersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new carrier
   * @param carrier - The carrier to create
   * @returns The observable<Carrier> to create the carrier
   */
  create$(carrier: Carrier): Observable<Carrier> {
    return this._create$<Carrier>(carrier)
  }

  /**
   * Read a carrier by ID
   * @param carrierId - The carrier ID
   * @returns The observable<Carrier> for read the carrier
   */
  read$(carrierId: string): Observable<Carrier> {
    return this._read$<Carrier>(carrierId)
  }

  /**
   * Update a carrier by ID
   * @param carrierId - The carrier ID
   * @param carrier - The carrier body to update
   * @returns The observable<Carrier> for update the carrier
   */
  update$(carrierId: string, carrier: Carrier): Observable<Carrier> {
    return this._update$<Carrier>(carrierId, carrier)
  }

  /**
   * Create or update a carrier by ID
   * @param carrierId - The carrier ID
   * @param carrier - The carrier body to update
   * @returns The observable<Carrier> for update the carrier
   */
  upsert$(carrier: Carrier): Observable<Carrier> {
    return this._upsert$<Carrier>(carrier, carrier._id)
  }

  /**
   * Delete a carrier by ID
   * @param carrierId - The carrier ID
   * @returns The observable<Carrier> for delete the carrier
   */
  delete$(carrierId: string): Observable<void> {
    return this._delete$<void>(carrierId)
  }

  /**
   * Search carriers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Carrier>> for search carriers
   */
  search$(
    params?: CarrierSearchParams,
    returnAll = false,
  ): Observable<Page<Carrier>> {
    return this._search$<Carrier>(params, returnAll)
  }

  /**
   * List carriers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Carrier[]> for list carriers
   */
  list$(
    params?: CarrierSearchParams,
    returnAll = false,
  ): Observable<Carrier[]> {
    return this._list$<Carrier>(params, returnAll)
  }

  /**
   * Generate carriers store
   * @param carrierIds - the carrier IDs to load
   * @param carriers - the carriers already loaded
   * @returns the Observable<Carrier[]> as store
   */
  store$(carrierIds: string[], carriers: Carrier[]): Observable<Carrier[]> {
    carrierIds = uniq(carrierIds)
    carrierIds = difference(
      carrierIds,
      carriers.map((u) => u._id),
    )

    if (carrierIds.length === 0) {
      return of(carriers)
    }

    return this.list$({ _id: carrierIds }).pipe(
      map((crs) => [...carriers, ...crs]),
    )
  }
}
