import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ToastrModule } from 'ngx-toastr'
import { ClipboardModule } from 'ngx-clipboard'
import { ButtonsModule } from 'ngx-bootstrap/buttons'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ColorPickerModule } from 'ngx-color-picker'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { NgxSpinnerModule } from 'ngx-spinner'

// Modules
import { SharedUtilDevextremeModule } from '@evologi/shared/util-devextreme'
import { SharedUtilToolkitModule } from '@evologi/shared/util-toolkit'
import { SharedUtilTranslationModule } from '@evologi/shared/util-translation'

// Directives
import { AdviceDirective } from './directives/advice.directive'
import { ClipboardDirective } from './directives/clipboard.directive'
import { UniqueValidator } from '@evologi/shared/data-access-api'

// Components
import { AccordionComponent } from './components/accordion/accordion.component'
import { AdviceComponent } from './components/advice/advice.component'
import { BadgeComponent } from './components/badge/badge.component'
import { ButtonComponent } from './components/button/button.component'
import { ButtonsGroupComponent } from './components/buttons-group/buttons-group.component'
import { ButtonsNavigationComponent } from './components/buttons-navigation/buttons-navigation.component'
import { CardComponent } from './components/card/card.component'
import { CheckBoxComponent } from './components/check-box/check-box.component'
import { ColorPickerComponent } from './components/color-picker/color-picker.component'
import { DateBoxComponent } from './components/date-box/date-box.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { FormGroupComponent } from './components/form-group/form-group.component'
import { FormRowComponent } from './components/form-row/form-row.component'
import { GridComponent } from './components/grid/grid.component'
import { HeadlineComponent } from './components/headline/headline.component'
import { HtmlEditorComponent } from './components/html-editor/html-editor.component'
import { InputComponent } from './components/input/input.component'
import { JumbotronComponent } from './components/jumbotron/jumbotron.component'
import { ListComponent } from './components/list/list.component'
import { ListGroupComponent } from './components/list-group/list-group.component'
import { ListGroupItemComponent } from './components/list-group-item/list-group-item.component'
import { LoaderComponent } from './components/loader/loader.component'
import { NavigationShoulderComponent } from './components/navigation-shoulder/navigation-shoulder.component'
import { PlaceholderComponent } from './components/placeholder/placeholder.component'
import { SelectBoxComponent } from './components/select-box/select-box.component'
import { SelectMultiBoxComponent } from './components/select-multi-box/select-multi-box.component'
import { TagBoxComponent } from './components/tag-box/tag-box.component'
import { TextAreaComponent } from './components/text-area/text-area.component'
import { StackComponent } from './components/stack/stack.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,

    // Modules
    SharedUtilDevextremeModule,
    SharedUtilToolkitModule,
    SharedUtilTranslationModule,

    // Loader
    NgxSkeletonLoaderModule,
    NgxSpinnerModule,

    // Ngx-bootstrap
    ButtonsModule.forRoot(),
    BsDropdownModule,

    // Clipboard
    ClipboardModule,

    // Toast
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
  ],
  declarations: [
    // Directives
    AdviceDirective,
    ClipboardDirective,
    UniqueValidator,

    // Components
    AccordionComponent,
    AdviceComponent,
    BadgeComponent,
    ButtonComponent,
    ButtonsGroupComponent,
    ButtonsNavigationComponent,
    CardComponent,
    CheckBoxComponent,
    ColorPickerComponent,
    DateBoxComponent,
    DialogComponent,
    DropdownComponent,
    FormGroupComponent,
    FormRowComponent,
    GridComponent,
    HeadlineComponent,
    HtmlEditorComponent,
    InputComponent,
    JumbotronComponent,
    ListComponent,
    ListGroupComponent,
    ListGroupItemComponent,
    LoaderComponent,
    NavigationShoulderComponent,
    PlaceholderComponent,
    SelectBoxComponent,
    SelectMultiBoxComponent,
    StackComponent,
    TagBoxComponent,
    TextAreaComponent,
  ],
  exports: [
    // Directives
    AdviceDirective,
    ClipboardDirective,
    UniqueValidator,

    NgxSpinnerModule,

    // Components
    AccordionComponent,
    AdviceComponent,
    BadgeComponent,
    ButtonComponent,
    ButtonsGroupComponent,
    ButtonsNavigationComponent,
    CardComponent,
    CheckBoxComponent,
    ColorPickerComponent,
    DateBoxComponent,
    DropdownComponent,
    FormGroupComponent,
    FormRowComponent,
    GridComponent,
    HeadlineComponent,
    HtmlEditorComponent,
    InputComponent,
    JumbotronComponent,
    ListComponent,
    ListGroupComponent,
    ListGroupItemComponent,
    LoaderComponent,
    NavigationShoulderComponent,
    PlaceholderComponent,
    SelectBoxComponent,
    SelectMultiBoxComponent,
    StackComponent,
    TagBoxComponent,
    TextAreaComponent,
  ],
})
export class AdminUiModule {}
