import { PackingList } from '../packing-list.model'

/**
 * Get all user IDs that operates on a packing-list
 * @param packingList - The packing-list
 * @returns the list of user IDs
 */
export function getPackingUserIds(packingList: PackingList): string[] {
  const userIds: string[] = []

  // Creation & update
  if (packingList.createdBy) {
    userIds.push(packingList.createdBy)
  }

  if (packingList.updatedBy) {
    userIds.push(packingList.updatedBy)
  }

  // Meta
  if (packingList.statusHistory) {
    packingList.statusHistory.reduce<string[]>((acc, h) => {
      if (h.userId) {
        acc.push(h.userId)
      }

      return acc
    }, userIds)
  }

  return userIds
}
