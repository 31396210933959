import { Pipe, PipeTransform } from '@angular/core'
import { Product, ProductScope } from '..'

@Pipe({
  name: 'productCatPrimary',
})
export class ProductCatPrimaryPipe implements PipeTransform {
  transform(product: Product, scope: ProductScope, catId: string): boolean {
    if (!product.categories) {
      return false
    }

    const productCategories = product.categories.find(
      (c) => c.catalogCode === scope.catalogCode,
    )

    return productCategories?.primaryId === catId
  }
}
