import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  GoodsReturnNotificationCode,
  GoodsReturnNotificationData,
  GoodsReturnNotificationOptions,
} from '../goods-return.model'

export class GoodsReturnNotification implements Notification {
  public code: GoodsReturnNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: GoodsReturnNotificationOptions) {
    const { type, dialog } = parseGoodsReturnNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: GoodsReturnNotificationOptions): GoodsReturnNotification {
    if (opts instanceof GoodsReturnNotification) {
      return opts
    } else {
      return new GoodsReturnNotification(opts)
    }
  }
}

/**
 * Parse goods-return notification to a message
 * @param notification - the goods-return notification
 * @returns the notification data
 */
export function parseGoodsReturnNotification(
  code: GoodsReturnNotificationCode,
  data?: GoodsReturnNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'GOODS_RETURN_SAVED':
      dialog = {
        message: 'Goods-return saved successfully',
        color: 'success',
      }
      break
    case 'GOODS_RETURN_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'GOODS_RETURN_DELETION': {
      const grName = data?.goodsReturn?.header.returnNumber
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete goods-return',
        message:
          'You are about to delete the goods-return <strong>%s</strong>. Proceed with deletion?',
        messageParams: grName ? [grName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'GOODS_RETURN_DELETED':
      dialog = {
        message: 'Goods-return deleted successfully',
        color: 'success',
      }
      break
  }

  return { dialog, type }
}
