import { Injectable } from '@angular/core'
import {
  LOCALE_DEFAULT_CODE,
  Locale,
  Tenant,
} from '@evologi/shared/data-access-api'
import { Store } from '@ngrx/store'

import { StoreState } from '../store.state'
import { AuthStoreService } from '../auth'
import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs'
import { selectLocales } from './locales.selectors'
import { loadLocales } from './locales.actions'

@Injectable({
  providedIn: 'root',
})
export class LocalesStoreService {
  private authCheck$: Observable<boolean> = this.auth
    .isAuthenticated$()
    .pipe(take(1))
  private tenant$: Observable<Tenant> = this.authCheck$.pipe(
    switchMap((check) => (check ? this.auth.getTenant$() : of(undefined))),
    filter((tenant) => !!tenant),
    distinctUntilChanged(),
    // eslint-disable-next-line
    map((tenant) => tenant!),
  )

  constructor(
    private store: Store<StoreState>,
    private auth: AuthStoreService,
  ) {}

  /**
   * Get all locales of a tenant
   * @returns the observable for load tenant locales
   */
  getLocales$(): Observable<Locale[] | undefined> {
    return this.tenant$.pipe(
      switchMap((tenant) =>
        this.store.select(selectLocales(tenant._id)).pipe(
          tap(
            (locales) =>
              !locales &&
              tenant?.catalog?.locales &&
              tenant.catalog.locales.length &&
              this.store.dispatch(
                loadLocales({
                  tenantId: tenant._id,
                  params: {
                    tag: tenant.catalog.locales,
                  },
                }),
              ),
          ),
        ),
      ),
    )
  }

  /**
   * Get all product locales
   * @returns the observable for load product locales
   */
  getProductLocales$(): Observable<Locale[]> {
    return this.getLocales$().pipe(
      map((locales) => [
        {
          _id: LOCALE_DEFAULT_CODE,
          tag: LOCALE_DEFAULT_CODE,
          name: 'Default',
          language: 'Default',
        } as Locale,
        ...(locales || []),
      ]),
    )
  }
}
