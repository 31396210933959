import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { GoodsReturnNotification } from './libs/goods-return-notification.lib'
import {
  GoodsReturn,
  GoodsReturnField,
  GoodsReturnNotificationOptions,
  GoodsReturnSearchParams,
  GoodsReturnsListingData,
  GoodsReturnsListingPage,
} from './goods-return.model'
import {
  EMPTY,
  Observable,
  combineLatest,
  concatMap,
  from,
  last,
  map,
  of,
  switchMap,
} from 'rxjs'
import { GoodsReturnsService } from './goods-returns.service'
import { Page } from '../../models/util.model'
import { parseGoodsReturnPageKeys } from './libs/goods-returns-page.lib'
import { ChannelsService } from '../channels'
import { PaymentsService } from '../payments'
import { CountriesService } from '../countries'
import { ReasonsService } from '../reasons'

@Injectable({
  providedIn: 'root',
})
export class GoodsReturnsRepository {
  private paginationLimit = 200

  constructor(
    private goodsReturnsService: GoodsReturnsService,
    private channelsService: ChannelsService,
    private paymentsService: PaymentsService,
    private countriesService: CountriesService,
    private reasonsService: ReasonsService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Search goods-returns and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @param listingData - the already loaded listing data
   * @returns the observable for search goods-returns
   */
  searchGoodsReturns$(
    searchParams: GoodsReturnSearchParams,
    fields: GoodsReturnField[],
    listingData?: GoodsReturnsListingData,
  ): Observable<GoodsReturnsListingPage> {
    return searchParams.limit !== undefined &&
      searchParams.limit > this.paginationLimit
      ? this._paginateGoodsReturns$(searchParams, fields, listingData)
      : this._searchGoodsReturns$(searchParams, fields, listingData)
  }

  /**
   * Show brands alert
   * @param options - the goods-return notification or notification options
   * @returns the observable for show the alert about goods-return
   */
  alert$(
    opts: GoodsReturnNotification | GoodsReturnNotificationOptions,
  ): Observable<boolean> {
    const notification = GoodsReturnNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a goods-return event
   * @param notification - the goods-return notification
   */
  notify(opts: GoodsReturnNotification | GoodsReturnNotificationOptions): void {
    const notification = GoodsReturnNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }

  /**
   * Search goods returns with relative external data
   * @param searchParams - the search params
   * @param fields - the goods-returns fields
   * @param listingData - the already loaded listing data
   * @returns the observable<GoodsReturnsListingPage> for search goods-returns
   */
  private _searchGoodsReturns$(
    searchParams: GoodsReturnSearchParams,
    fields: GoodsReturnField[],
    listingData?: GoodsReturnsListingData,
  ): Observable<GoodsReturnsListingPage> {
    return this.goodsReturnsService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this._loadPageExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Load external page goods-returns data
   * @param page - the goods-returns page
   * @param fields - the goods-returns fields
   * @returns the observable for load external data
   */
  private _loadPageExtData$(
    page: Page<GoodsReturn>,
    fields: GoodsReturnField[],
    listingData?: GoodsReturnsListingData,
  ): Observable<GoodsReturnsListingData> {
    const extDataKeys = parseGoodsReturnPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('header.channelId') && extDataKeys.channelIds) {
      obs$['channels'] = this.channelsService.store$(
        extDataKeys.channelIds,
        listingData?.channels || [],
      )
    }

    if (fields.includes('header.paymentId') && extDataKeys.paymentIds) {
      obs$['payments'] = this.paymentsService.store$(
        extDataKeys.paymentIds,
        listingData?.payments || [],
      )
    }

    if (extDataKeys.reasonIds && fields.includes('header.reasonId')) {
      obs$['reasons'] = this.reasonsService.store$(
        extDataKeys.reasonIds,
        listingData?.reasons || [],
      )
    }

    if (
      extDataKeys.countryCodes &&
      fields.includes('header.shippingAddress.countryCode')
    ) {
      obs$['countries'] = this.countriesService.store$(
        extDataKeys.countryCodes,
        listingData?.countries || [],
        'alpha2Code',
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  /**
   * Search more than 200 goods-returns with relative external data
   * @param searchParams - the search params
   * @param fields - the goods-returns fields
   * @param listingData - the already loaded listing data
   * @returns the observable<GoodsReturnsListingPage> for search goods-returns
   */
  private _paginateGoodsReturns$(
    searchParams: GoodsReturnSearchParams,
    fields: GoodsReturnField[],
    listingData?: GoodsReturnsListingData,
  ): Observable<GoodsReturnsListingPage> {
    // Paginate requests
    const totalCount = searchParams.limit || this.paginationLimit
    const pagesCount = Math.ceil(totalCount / this.paginationLimit)

    const pages$ = from(
      Array.from({ length: pagesCount }, (_, i) => ({
        ...searchParams,
        limit: this.paginationLimit,
        offset: i * this.paginationLimit,
      })),
    )

    let response: GoodsReturnsListingPage = {
      totalCount: 0,
      data: [],
    }
    let extData = listingData

    return pages$.pipe(
      concatMap((pageParams) =>
        this._searchGoodsReturns$(pageParams, fields, extData).pipe(
          map((res) => {
            response = {
              totalCount: (response.totalCount || 0) + (res.totalCount || 0),
              data: [...(response.data || []), ...(res.data || [])],
              extData: { ...response.extData, ...res.extData },
            }
            extData = response.extData

            return response
          }),
        ),
      ),
      last(),
    )
  }
}
