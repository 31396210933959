export const SALE_FIELDS = [
  {
    field: 'product.SKU',
    defaultVisibility: true,
  },
  {
    field: 'product.supplier.SKU',
    defaultVisibility: true,
  },
  {
    field: 'product.name',
    defaultVisibility: true,
  },
  {
    field: 'product.tags',
    defaultVisibility: false,
  },
  {
    field: 'product.status',
    defaultVisibility: false,
  },
  {
    field: 'product.barcodes',
    defaultVisibility: false,
  },
  {
    field: 'product.brandId',
    defaultVisibility: true,
  },
  {
    field: 'product.categories',
    defaultVisibility: false,
  },
  {
    field: 'product.classifications',
    defaultVisibility: false,
  },
  {
    field: 'product.onHandQty',
    defaultVisibility: true,
  },
  {
    field: 'product.availableQty',
    defaultVisibility: true,
  },
  {
    field: 'product.incomingQty',
    defaultVisibility: true,
  },
  {
    field: 'product.receivingQty',
    defaultVisibility: false,
  },
  {
    field: 'product.minProvisions',
    defaultVisibility: false,
  },
  {
    field: 'product.maxProvisions',
    defaultVisibility: false,
  },
  {
    field: 'product.packQty',
    defaultVisibility: false,
  },
  {
    field: 'product.palletQty',
    defaultVisibility: false,
  },
  {
    field: 'product.abcClass',
    defaultVisibility: false,
  },
  {
    field: 'product.supplier.price.net',
    defaultVisibility: true,
  },
  {
    field: 'product.supplier.price.promotion.name',
    defaultVisibility: true,
  },
  {
    field: 'product.supplier.price.promotion.startDate',
    defaultVisibility: false,
  },
  {
    field: 'product.supplier.price.promotion.endDate',
    defaultVisibility: false,
  },
  {
    field: 'product.supplier.price.promotion.minQty',
    defaultVisibility: false,
  },
  {
    field: 'product.supplier.price.promotion.percentage',
    defaultVisibility: false,
  },
  {
    field: 'product.supplier.price.promotion.price',
    defaultVisibility: false,
  },
  {
    field: 'product.price.listing',
    defaultVisibility: true,
  },
  {
    field: 'product.price.discount.name',
    defaultVisibility: true,
  },
  {
    field: 'product.price.discount.startDate',
    defaultVisibility: false,
  },
  {
    field: 'product.price.discount.endDate',
    defaultVisibility: false,
  },
  {
    field: 'product.price.discount.minQty',
    defaultVisibility: false,
  },
  {
    field: 'product.price.discount.percentage',
    defaultVisibility: false,
  },
  {
    field: 'product.price.discount.price',
    defaultVisibility: false,
  },
]
