// Search

import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import {
  MovementSearchField,
  MovementSearchParams,
  MovementSortField,
} from './movement.model'

export function movementSortParams(
  params: MovementSearchParams,
  sort: QueryStringSort<MovementSortField>
): MovementSearchParams {
  const searchParams: MovementSearchParams = {}

  switch (sort.field) {
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'SKU':
      searchParams.sort = 'SKU'
      searchParams.order = sort.order
      break
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function movementFilterParams(
  params: MovementSearchParams,
  filter: QueryStringFilter<MovementSearchField>
): MovementSearchParams {
  const searchParams: MovementSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams['createdAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'SKU':
      if (filter.operator === '=') {
        searchParams.SKU = filter.value
      } else if (filter.operator === '<>') {
        searchParams['SKU:ne'] = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      }
      break
    case 'sign':
      if (filter.operator !== null) {
        searchParams.sign = filter.value
      }
      break
    case 'reason':
      if (filter.operator !== null) {
        searchParams.reason = filter.value
      }
      break
    case 'details.code':
      if (filter.operator === '=') {
        searchParams['details.code'] = filter.value
      }
      break
    case 'order.orderNumber':
      if (filter.operator === '=') {
        searchParams['order.orderNumber'] = filter.value
      }
      break
    case 'order.rifOrder':
      if (filter.operator === '=') {
        searchParams['order.rifOrder'] = filter.value
      }
      break
    case 'userId':
      if (filter.operator !== null) {
        searchParams.userId = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
