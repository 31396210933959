import { HttpErrorResponse } from '@angular/common/http'
import {
  Replenishment,
  ReplenishmentField,
  ReplenishmentSearchParams,
  ReplenishmentsListingData,
  ReplenishmnetsListingPage,
} from '../replenishment.model'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Observable, filter, map, switchMap, tap, withLatestFrom } from 'rxjs'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Product } from '../../products'
import { User } from '../../users'
import { Location } from '../../locations'
import { REPLENISHMENT_DEFAULT_FIELDS } from '../replenishment.const'
import { Warehouse } from '../../warehouses'
import { ReplenishmentsRepository } from '../replenishments.repository'

interface ReplenishmentsListingState extends ReplenishmnetsListingPage {
  fields: ReplenishmentField[]
  filters?: ReplenishmentSearchParams
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
  canExport: boolean
}

const REPLENISHMENTS_INITIAL_STATE: ReplenishmentsListingState = {
  fields: REPLENISHMENT_DEFAULT_FIELDS,
  toReload: false,
  isLoading: false,
  isInitialized: false,
  canExport: false,
}

@Injectable()
export class ReplenishmentsListingUseCase extends ComponentStore<ReplenishmentsListingState> {
  constructor(private replenishmentsRepository: ReplenishmentsRepository) {
    super(REPLENISHMENTS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<ReplenishmentsListingState> = this.select(
    (state) => state,
  )

  readonly selectFilters$: Observable<ReplenishmentSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectCanExport$: Observable<boolean> = this.select(
    (state) => state.canExport,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {})),
  )

  readonly selectReplenishments$: Observable<Replenishment[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectExtData$: Observable<ReplenishmentsListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectUsers$: Observable<User[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.users))

  readonly selectWarehouses$: Observable<Warehouse[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.warehouses))

  readonly selectLocations$: Observable<Location[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.locations))

  readonly selectProducts$: Observable<Product[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.products))

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<ReplenishmnetsListingPage> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectTotalCount$,
          this.selectReplenishments$,
          this.selectExtData$,
          (totalCount, data, extData) => ({ totalCount, data, extData }),
          { debounce: true },
        ),
      ),
    )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<ReplenishmentSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        switchMap(([searchParams, state]) =>
          this.replenishmentsRepository
            .searchReplenishments$(searchParams, state.fields, state.extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<ReplenishmentSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        map(([params, state]) => ({
          params: { ...params, limit: state.totalCount },
          fields: state.fields,
          extData: state.extData,
        })),
        switchMap(({ params, fields, extData }) =>
          this.replenishmentsRepository
            .searchReplenishments$(params, fields, extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  // Reducers

  readonly setFilters = this.updater(
    (
      state,
      filters?: ReplenishmentSearchParams,
    ): ReplenishmentsListingState => {
      return {
        filters,
        fields: state.fields,
        data: state.data,
        extData: state.extData,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
        canExport: state.canExport,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: ReplenishmnetsListingPage): ReplenishmentsListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ReplenishmentsListingState => ({
      ...state,
      data: [],
      extData: undefined,
      totalCount: 0,
      error,
      toReload: false,
      isLoading: false,
      isInitialized: true,
    }),
  )

  readonly setCanExport = this.updater(
    (state, canExport: boolean): ReplenishmentsListingState => ({
      ...state,
      canExport: canExport,
    }),
  )

  readonly setLoading = this.updater(
    (state, isLoading: boolean): ReplenishmentsListingState => ({
      ...state,
      isLoading: isLoading,
    }),
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): ReplenishmentsListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setReplenishments = this.updater(
    (state, data: Replenishment[]): ReplenishmentsListingState => ({
      ...state,
      data,
    }),
  )
}
