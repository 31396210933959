import { Directive, forwardRef, Input } from '@angular/core'
import { NG_VALIDATORS, ValidationErrors } from '@angular/forms'

@Directive({
  selector: '[tkFormatValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormatValidatorDirective),
      multi: true,
    },
  ],
})
export class FormatValidatorDirective {
  @Input('tkFormatValidator') format?: string

  validate(
    control: import('@angular/forms').AbstractControl
  ): ValidationErrors | null {
    const value = control.value

    if (!value || !this.format) {
      return null
    }

    const regex = new RegExp(this.format)
    const isValid = regex.test(value)

    if (!isValid) {
      return { invalidFormat: true }
    }

    return null
  }
}
