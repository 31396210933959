import { Injectable } from '@angular/core'
import {
  AttributeGroupsService,
  AttributesService,
} from '@evologi/shared/data-access-api'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs'

import * as AuthActions from '../auth/auth.actions'
import * as AttributesActions from './attributes.actions'

@Injectable({
  providedIn: 'root',
})
export class AttributesEffects {
  // Lifecycle effects

  destroyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOutSuccess),
      map(() => AttributesActions.destroyAttributes()),
    )
  })

  // Crud effects

  getGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.loadGroups),
      switchMap((action) => {
        const obs$ = action.groupIds
          ? this.attributeGroupsService.search$({
              _id: action.groupIds,
            })
          : this.attributeGroupsService.search$(undefined, true)

        return obs$.pipe(
          map((groupsData) =>
            AttributesActions.setGroups({
              tenantId: action.tenantId,
              groups: groupsData.data,
              totalCount: action.groupIds ? undefined : groupsData.totalCount,
            }),
          ),
        )
      }),
    )
  })

  getGroupAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.loadGroupAttributes),
      switchMap((action) =>
        this.attributeGroupsService.read$(action.groupId).pipe(
          switchMap((group) =>
            this.attributesService
              .list$({
                attributesGroupCode: group.code,
              })
              .pipe(
                map((attributes) =>
                  AttributesActions.setGroupAttributes({
                    tenantId: action.tenantId,
                    group,
                    attributes,
                  }),
                ),
              ),
          ),
        ),
      ),
    )
  })

  getAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.loadAttributes),
      switchMap((action) => {
        const obs$ = action.attributeIds
          ? this.attributesService.search$({
              _id: action.attributeIds,
            })
          : this.attributesService.search$(undefined, true)

        return obs$.pipe(
          map((attributesData) =>
            AttributesActions.setAttributes({
              tenantId: action.tenantId,
              attributes: attributesData.data,
              totalCount: action.attributeIds
                ? undefined
                : attributesData.totalCount,
            }),
          ),
        )
      }),
    )
  })

  getAttributesByCodes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.loadAttributesByCodes),
      switchMap((action) =>
        this.attributesService.list$({ code: action.attributeCodes }).pipe(
          map((attributes) =>
            AttributesActions.setAttributes({
              tenantId: action.tenantId,
              attributes,
            }),
          ),
        ),
      ),
    )
  })

  upsertAttribute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.upsertAttribute),
      switchMap((action) =>
        this.attributesService
          .upsert$(action.attribute)
          .pipe(
            map((attribute) => AttributesActions.setAttribute({ attribute })),
          ),
      ),
    )
  })

  upsertGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributesActions.upsertGroup),
      switchMap((action) =>
        this.attributeGroupsService
          .upsert$(action.group)
          .pipe(map((group) => AttributesActions.setGroup({ group }))),
      ),
    )
  })

  constructor(
    private actions$: Actions,
    private attributesService: AttributesService,
    private attributeGroupsService: AttributeGroupsService,
  ) {}
}
