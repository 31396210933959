import { Product } from '../product.model'
import { getProductWrappersQtyLabel } from './wrappers.lib'

type ProductQuantityKind =
  | 'onHandQty'
  | 'availableQty'
  | 'incomingQty'
  | 'receivingQty'
  | 'scrappedQty'
  | 'reservedQty'
  | 'pickedQty'

/**
 * Get product qty
 * @param product - the product
 * @param kind - the quantity kind
 * @param warehouseId - the warehouse ID
 * @returns the quantity needed
 */
export function getProductQty(
  product: Product,
  kind: ProductQuantityKind,
  warehouseId?: string
): number {
  const quantities = warehouseId
    ? product.warehouses?.find((w) => w._id === warehouseId)
    : product

  switch (kind) {
    case 'onHandQty':
      return quantities?.onHandQty || 0
    case 'availableQty':
      return quantities?.availableQty || 0
    case 'incomingQty':
      return quantities?.incomingQty || 0
    case 'receivingQty':
      return product.receivingQty || 0
    case 'scrappedQty':
      return quantities?.scrappedQty || 0
    default:
      return 0
  }
}

/**
 * Get product location qty
 * @param product - the product
 * @param kind - the location kind
 * @param locationId - the location ID
 * @returns the quantity needed
 */
export function getProductLocationQty(
  product: Product,
  kind: ProductQuantityKind,
  locationId: string
): number {
  const location = product.locations.find((l) => l._id === locationId)

  switch (kind) {
    case 'onHandQty':
      return location?.onHandQty || 0
    case 'reservedQty':
      return location?.reservedQty || 0
    case 'pickedQty':
      return location?.pickedQty || 0
    case 'scrappedQty':
      return location?.scrappedQty || 0
    default:
      return 0
  }
}

/**
 * Get the product packing quantity label
 * @param product - the product
 * @param orderedQty - the ordered qty
 * @returns the label summary of the packages quantities
 */
export function getProductPackagesQtyLabel(
  product: Product,
  orderedQty: number
): string | undefined {
  // Check wrappers
  if (product.wrappers?.length) {
    return getProductWrappersQtyLabel(product, orderedQty)
  }

  return getProductPackQtyLabel(product, orderedQty)
}

/**
 * Get product pack quantity label
 * @param product - the product
 * @param orderedQty - the ordered quantity
 * @returns the label summary of the pack qty
 */
export function getProductPackQtyLabel(
  product: Product,
  orderedQty: number
): string | undefined {
  if (product.packQty === undefined || orderedQty < product.packQty) {
    return undefined
  }

  const packagesAmount = Math.trunc(orderedQty / product.packQty)
  const itemsAmount = orderedQty % product.packQty

  let badge = `${packagesAmount} CONF`
  if (itemsAmount) {
    badge += ` + ${itemsAmount} PZ`
  }

  return badge
}
