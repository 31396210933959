import { HttpClient } from '@angular/common/http'
import { Observable, concat } from 'rxjs'
import { map } from 'rxjs/operators'
import { Page } from '../models/util.model'

/**
 * @deprecated
 * Use CrudService instead
 */
export abstract class BaseService {
  protected model?: string

  // Pagination
  private limit = 200

  constructor(
    protected http: HttpClient,
    protected path: string,
    protected version: string
  ) {}

  // CRUD methods

  private find<T>(params?: any): Observable<Page<T>> {
    return this.http.get<Page<T>>(
      `${this.path}/${this.version}/${this.model}${this.paramsToQuery(params)}`
    )
  }

  public findAll<T>(params?: any): Observable<T[]> {
    return this.find<T>(params).pipe(
      map((res) => this.pageToList<T>(res, params))
    )
  }

  public findPage<T>(params?: any, totalCount?: number): Observable<Page<T>> {
    return totalCount !== undefined
      ? this.findCountedPaginated(totalCount, params)
      : this.find(params)
  }

  /**
   * @deprecated: use findPage
   */
  public findCounted<T>(params?: any): Observable<Page<T>> {
    return this.http.get<Page<T>>(
      `${this.path}/${this.version}/${this.model}${this.paramsToQuery(params)}`
    )
  }

  /**
   * @deprecated: use findPage
   */
  public findCountedPaginated<T>(
    totalCount: number,
    params?: any
  ): Observable<Page<T>> {
    if (totalCount <= this.limit) {
      return this.findCounted<T>({ ...params, limit: totalCount })
    }

    let response: Page<T> = {
      totalCount: 0,
      data: [],
    }
    return concat(...this.findAllPages$<T>(totalCount, params)).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      })
    )
  }

  public findByKey<T>(key: string): Observable<T> {
    return this.http.get<T>(`${this.path}/${this.version}/${this.model}/${key}`)
  }

  public insert<T>(item: T): Observable<T> {
    return this.http.post<T>(`${this.path}/${this.version}/${this.model}`, item)
  }

  public update<T>(id: string, update: Partial<T>): Observable<T> {
    return this.http.put<T>(
      `${this.path}/${this.version}/${this.model}/${id}`,
      update
    )
  }

  public upsert<T>(item: T, id?: string): Observable<T> {
    return id ? this.update<T>(id, item) : this.insert<T>(item)
  }

  public delete<T>(id: string, params?: any): Observable<T> {
    return this.http.delete<T>(
      `${this.path}/${this.version}/${this.model}/${id}`,
      { params }
    )
  }

  // Children models: CRUD methods

  public findAllChildren<T>(
    key: string,
    childModel: string,
    params?: any
  ): Observable<T[]> {
    return this.findCountedChildren<T>(key, childModel, params).pipe(
      map((res) => this.pageToList<T>(res, params))
    )
  }

  public findCountedChildren<T>(
    key: string,
    childModel: string,
    params?: any
  ): Observable<Page<T>> {
    return this.http.get<Page<T>>(
      `${this.path}/${this.version}/${
        this.model
      }/${key}/${childModel}${this.paramsToQuery(params)}`
    )
  }

  public findCountedChildrenPaginated<T>(
    totalCount: number,
    key: string,
    childModel: string,
    params?: any
  ): Observable<Page<T>> {
    if (totalCount <= this.limit) {
      return this.findCountedChildren<T>(key, childModel, {
        ...params,
        limit: totalCount,
      })
    }

    let response: Page<T> = {
      totalCount: 0,
      data: [],
    }
    return concat(
      ...this.findAllChildrenPages$<T>(totalCount, key, childModel, params)
    ).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      })
    )
  }

  public findChildByKey<T>(
    key: string,
    childModel: string,
    childKey: string
  ): Observable<T> {
    return this.http.get<T>(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}/${childKey}`
    )
  }

  public insertChild<T>(
    key: string,
    childModel: string,
    item: any
  ): Observable<T> {
    return this.http.post<T>(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}`,
      item
    )
  }

  public insertChildren<T>(
    key: string,
    childModel: string,
    items: any[]
  ): Observable<T> {
    return this.http.post<T>(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}`,
      {
        [childModel]: items,
      }
    )
  }

  public updateChild<T>(
    key: string,
    childModel: string,
    id: string,
    update: any
  ): Observable<T> {
    return this.http.put<T>(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}/${id}`,
      update
    )
  }

  public deleteChild<T>(
    key: string,
    childModel: string,
    id: string
  ): Observable<T> {
    return this.http.delete<T>(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}/${id}`
    )
  }

  // Useful

  private pageToList<T>(response: Page<T>, params: any): T[] {
    if (!response.data) {
      return []
    }

    return response.data
  }

  private findAllPages$<T>(
    totalCount: number,
    params: any
  ): Observable<Page<T>>[] {
    const observables$ = []
    let offset = 0

    while (offset <= totalCount) {
      observables$.push(
        this.findCounted<T>({
          ...params,
          client: 'table',
          limit: 200,
          offset,
        })
      )
      offset += 200
    }

    return observables$
  }

  private findAllChildrenPages$<T>(
    totalCount: number,
    key: string,
    childModel: string,
    params: any
  ): Observable<Page<T>>[] {
    const observables$ = []
    let offset = 0

    while (offset <= totalCount) {
      observables$.push(
        this.findCountedChildren<T>(key, childModel, {
          ...params,
          client: 'table',
          limit: 200,
          offset,
        })
      )
      offset += 200
    }

    return observables$
  }

  public paramsToQuery(params?: any): string {
    let query = ''
    const searchParams = []

    if (params) {
      if (typeof params === 'object') {
        for (const k in params) {
          if (k === 'filters') {
            searchParams.push(params[k])
          } else {
            searchParams.push(k + '=' + params[k])
          }
        }

        query = '?' + searchParams.join('&')
      } else {
        query = '?' + params
      }
    } else {
      query = '?limit=' + this.limit
    }

    return query
  }
}
