<op-modal title="{{ 'Delete' | translate }} {{ entity | translate }}">
  <!-- Content -->
  <modal-content>
    <p>
      Sicuro di voler eliminare <strong>{{ ref }}</strong>
      ?
    </p>
    <p *ngIf="notes" [innerHtml]="notes"></p>
  </modal-content>

  <!-- Actions -->
  <modal-actions-right>
    <button class="btn btn-danger" type="button" (click)="delete()">
      Elimina
    </button>
  </modal-actions-right>
</op-modal>
