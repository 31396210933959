import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  CustomerNotificationCode,
  CustomerNotificationData,
  CustomerNotificationOptions,
} from '../customer.model'

export class CustomerNotification implements Notification {
  public code: CustomerNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: CustomerNotificationOptions) {
    const { type, dialog } = parseCustomerNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: CustomerNotificationOptions): CustomerNotification {
    if (opts instanceof CustomerNotification) {
      return opts
    } else {
      return new CustomerNotification(opts)
    }
  }
}

/**
 * Parse customer notification to a message
 * @param notification - the customer notification
 * @returns the notification data
 */
export function parseCustomerNotification(
  code: CustomerNotificationCode,
  data?: CustomerNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'CUSTOMER_SAVED':
      dialog = {
        message: 'Customer saved successfully',
        color: 'success',
      }
      break
    case 'CUSTOMER_DELETION': {
      const customerName = data?.customer?.name
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete customer',
        message:
          'You are about to delete the customer <strong>%s</strong>. Proceed with deletion?',
        messageParams: customerName ? [customerName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'CUSTOMER_DELETED': {
      dialog = {
        message: 'Customer deleted successfully',
        color: 'success',
      }
      break
    }
    case 'CUSTOMER_INCOMPLETE': {
      dialog = {
        message: 'Some fields are not filled in correctly',
        color: 'danger',
      }
      break
    }
  }
  return { dialog, type }
}
