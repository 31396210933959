import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'packingTime',
})
export class PackingTimePipe implements PipeTransform {
  transform(statusHistory: any): string | undefined {
    if (statusHistory.length < 2) {
      return
    }
    return `${((new Date(statusHistory[1].date).getTime() - new Date(statusHistory[0].date).getTime()) / 60000).toFixed(1)}`
  }
}
