import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Reason,
  ReasonSearchField,
  ReasonSearchParams,
  ReasonSortField,
  ReasonTarget,
  ReasonType,
  ReasonUsage,
} from './reason.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const REASON_INITIAL_STATE: Partial<Reason> = {
  code: undefined,
  name: undefined,
  reason: undefined,
  target: ReasonTarget.movement,
  isDefault: false,
}

// Init functions

export function initReason(reason: Partial<Reason> = {}): Reason {
  return defaultsDeep(cloneDeep(reason), REASON_INITIAL_STATE)
}

// Search

export function reasonSortParams(
  params: ReasonSearchParams,
  sort: QueryStringSort<ReasonSortField>
): ReasonSearchParams {
  const searchParams: ReasonSearchParams = {}

  switch (sort.field) {
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function reasonFilterParams(
  params: ReasonSearchParams,
  filter: QueryStringFilter<ReasonSearchField>
): ReasonSearchParams {
  const searchParams: ReasonSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'externalId':
      if (filter.operator === '=') {
        searchParams.externalId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['externalId:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['externalId:ct'] = filter.value
      }
      break
    case 'target':
      if (filter.value !== null) {
        searchParams['target'] = filter.value
      }
      break
    case 'reason':
      if (filter.value !== null) {
        searchParams['reason'] = filter.value
      }
      break
    case 'isDefault':
      searchParams.isDefault = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function reasonParamsByUsage(
  usage: ReasonUsage,
  type?: ReasonType
): ReasonSearchParams {
  let params: ReasonSearchParams = {}

  switch (usage) {
    case 'GOODS_RECEIVES':
    case 'SUPPLIER_ORDERS':
      params = {
        target: ReasonTarget.inbound,
        reason: ReasonType.order,
      }
      break
    case 'GOODS_RETURNS':
      params = {
        target: ReasonTarget.inbound,
        reason: ReasonType.return,
      }
      break
    case 'ORDERS':
    case 'INVOICES':
      params = {
        target: ReasonTarget.outbound,
        reason: ReasonType.order,
      }
      break
    case 'PRODUCTS':
      params = {
        target: ReasonTarget.movement,
        reason: type,
      }
      break
  }

  return params
}

export function parseReasonByUsage(
  reasons: Reason[],
  usage: ReasonUsage,
  type?: ReasonType
): Reason[] {
  const params = reasonParamsByUsage(usage, type)
  return reasons.filter(
    (reason) =>
      reason.target === params.target && reason.reason === params.reason
  )
}
