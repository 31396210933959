import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BaseService } from '../../services/base.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'

const MODEL = 'tenants'
const VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class TenantsService extends BaseService {
  protected override model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(http, `${config.apiUrl}/${SDK_SETTINGS.apiPath}`, VERSION)
  }

  /**
   * Get tenant shippy pro carriers
   * @param tenantId - the tenant ID
   * @returns the observable for get tenant shippy pro carriers
   */
  getShippyProCarriers$(tenantId: string): Observable<any> {
    return this.http.get(
      `${this.path}/${this.version}/${this.model}/${tenantId}/shippypro-carriers/`,
    )
  }
}
