import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pickingTimeOnMission',
})
export class PickingTimeOnMissionPipe implements PipeTransform {
  transform(column: any): string | undefined {
    if (
      column.data['statusHistory']?.length < 3 ||
      !column.data['statusHistory'][1].date ||
      !column.data['statusHistory'][2].date
    ) {
      return
    }
    const differenceInMinutes =
      (new Date(column.data['statusHistory'][2].date).getTime() -
        new Date(column.data['statusHistory'][1].date).getTime()) /
      60000
    return `${(
      Math.round((differenceInMinutes / column.data.missions.length) * 100) /
      100
    ).toFixed(1)}`
  }
}
