import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import { DeepPartial, Page } from '../../models/util.model'
import {
  Replenishment,
  ReplenishmentsListingKeys,
  ReplenishmentNotification,
  ReplenishmentSearchParams,
  ReplenishmentSortField,
} from './replenishment.model'
import { Tenant } from '../tenants'
import { DialogParams } from '../../models/notification.model'

/**
 * The replenishment initial state
 */
const REPLENISHMENT_INITIAL_STATE: DeepPartial<Replenishment> = {
  status: 'PENDING',
  product: {},
  priority: 20,
}

/**
 * Initialize a partial replenishment
 * @param brand - the partial replenishment
 * @returns the replenishment updated
 */
export function initReplenishment(
  replenishment: Partial<Replenishment> = {}
): Replenishment {
  return defaultsDeep(cloneDeep(replenishment), REPLENISHMENT_INITIAL_STATE)
}

/**
 * Parse sort params
 * @param params - the search params
 * @param sort - the sort param to parse
 * @returns the replenishment search params updated
 */
export function replenishmentSortParams(
  params: ReplenishmentSearchParams,
  sort: QueryStringSort<ReplenishmentSortField>
): ReplenishmentSearchParams {
  const searchParams: ReplenishmentSearchParams = {}

  switch (sort.field) {
    case 'replenishmentNumber':
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'priority':
      searchParams.sort = 'priority'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the replenishment search params updated
 */
export function replenishmentFilterParams(
  params: ReplenishmentSearchParams,
  filter: QueryStringFilter<keyof ReplenishmentSearchParams>
): ReplenishmentSearchParams {
  const searchParams: ReplenishmentSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'product.SKU':
      if (filter.operator === '=') {
        searchParams['product.SKU'] = filter.value
      }
      break
    case 'status':
      if (filter.operator === '=') {
        searchParams.status = filter.value
      }
      break
    case 'userId':
      if (filter.operator === '=') {
        searchParams.userId = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse replenishment page keys
 * @param page - the replenishment page
 * @returns the page updated
 */
export function parseRepPageKeys(
  page: Page<Replenishment>
): ReplenishmentsListingKeys {
  return page.data.reduce<ReplenishmentsListingKeys>(
    (acc, r) => parseRepKeys(r, acc),
    {}
  )
}

/**
 * Parse replenishment external data
 * @param replenishment - the replenishment
 * @param extData - the external data object
 * @returns the extData updated
 */
export function parseRepKeys(
  replenishment: Replenishment,
  extData: ReplenishmentsListingKeys = {}
): ReplenishmentsListingKeys {
  const warehouseIds = uniq([
    ...(extData['warehouseIds'] || []),
    replenishment.warehouseId,
  ])
  const productIds = uniq([
    ...(extData['productIds'] || []),
    replenishment.product!._id,
  ])

  const locationIds = extData['locationIds'] || []
  if (replenishment.source?._id) {
    locationIds.push(replenishment.source._id)
  }
  if (replenishment.target?._id) {
    locationIds.push(replenishment.target._id)
  }

  const userIds = extData['userIds'] || []
  if (replenishment.userId) {
    userIds.push(replenishment.userId)
  }

  return {
    warehouseIds,
    locationIds,
    productIds,
    userIds,
  }
}

/**
 * Parse replenishment notification to a message
 * @param notification - the replenishment notification
 * @returns the notification data
 */
export function parseReplenishmentNotification(
  notification: ReplenishmentNotification
): DialogParams {
  switch (notification.kind) {
    case 'REPLENISHMENT_SAVED':
      return {
        message: 'Replenishment saved successfully',
        color: 'success',
      }
    case 'REPLENISHMENT_INCOMPLETE':
      return {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
    case 'REPLENISHMENT_CANCELED':
      return {
        message: 'Replenishment canceled successfully',
        color: 'success',
      }
  }
}

/**
 * Get replenishment defaults from the tenant
 * @param tenant - the tenant
 * @returns the replenishment defaults
 */
export function getReplenishmentDefaults(
  tenant: Tenant
): Partial<Replenishment> {
  return {
    warehouseId: tenant.defaults?.warehouseId,
  }
}
