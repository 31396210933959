import { Order, OrderRow } from '../../orders/order.model'
import { Product, ProductOrder, ProductType } from '../product.model'

export function parseProductOrders(
  product: Product,
  orders: Order[],
  warehouseId?: string
): ProductOrder[] {
  // Initial qty
  let consumptionQty = warehouseId
    ? product.warehouses.find((w) => w._id === warehouseId)!.onHandQty
    : product.onHandQty

  // Sort order by creation date
  const ordersSorted = orders.sort(
    (order1: Order, order2: Order) =>
      new Date(order1.createdAt!).getTime() -
      new Date(order2.createdAt!).getTime()
  )

  return ordersSorted.map((order) => {
    const orderedQty = getOrderedQty(product, order)
    consumptionQty -= orderedQty

    return {
      ...order,
      orderedQty,
      consumptionQty,
    }
  })
}

function getOrderProductRows(product: Product, order: Order): OrderRow[] {
  return order.rows.filter((row) => {
    if (!row.product) {
      return false
    }

    if (row.product?.productType === ProductType.kit) {
      return row.product.simpleProducts?.find((prd) => prd._id === product._id!)
    }

    return row?.product._id === product._id
  })
}

function getOrderRowOrderedQty(product: Product, row: OrderRow): number {
  let orderedQty = row.orderedQty

  if (row.product.productType === ProductType.kit) {
    const simpleProductQty =
      row.product.simpleProducts?.find((prd) => prd._id === product._id!)
        ?.quantity || 1
    orderedQty = orderedQty * simpleProductQty
  }

  return orderedQty
}

function getOrderedQty(product: Product, order: Order): number {
  const rows = getOrderProductRows(product, order)

  return rows.reduce(
    (total: number, row: OrderRow) =>
      total + getOrderRowOrderedQty(product, row),
    0
  )
}
