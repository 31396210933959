import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies/policy.model'
import { ReasonTarget, ReasonType } from './reason.model'

export const REASON_SCOPE: PermissionsScope = 'locations'

export const REASON_TARGETS: SelectionData<ReasonTarget>[] = [
  {
    label: 'Movimenti',
    value: ReasonTarget.movement,
    icon: 'fad fa-exchange',
  },
  {
    label: 'In arrivo',
    value: ReasonTarget.inbound,
    icon: 'fad fa-inbox-in',
  },
  {
    label: 'In uscita',
    value: ReasonTarget.outbound,
    icon: 'fad fa-inbox-out',
  },
]

export const REASON_TYPES = [
  {
    code: ReasonType.increase,
    name: 'Carico merce',
    target: [ReasonTarget.movement],
  },
  {
    code: ReasonType.decrease,
    name: 'Scarico merce',
    target: [ReasonTarget.movement],
  },
  {
    code: ReasonType.order,
    name: 'Ordine',
    target: [ReasonTarget.inbound, ReasonTarget.outbound],
  },
  {
    code: ReasonType.return,
    name: 'Reso',
    target: [ReasonTarget.inbound],
  },
]
