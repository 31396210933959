import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule as NgrxStoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EffectsModule } from '@ngrx/effects'
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store'

// Services
import { AuthStoreService } from './store/auth/auth-store.service'
import { AttributesStoreService } from './store/attributes/attributes-store.service'
import { CacheService } from './store/cache/cache.service'
import { CurrenciesStoreService } from './store/currencies/currencies-store.service'
import { LocalesStoreService } from './store/locales/locales-store.service'

// State configuration
import {
  AppReducers,
  AppEffects,
  StoreConfiguration,
  STORE_CONFIGURATION,
  metaReducers,
} from './store'

@NgModule({
  imports: [
    // NGRX Store
    NgrxStoreModule.forRoot(AppReducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(AppEffects),

    CommonModule,
  ],
  exports: [
    // Modules
    NgrxStoreModule,
    StoreDevtoolsModule,
    StoreRouterConnectingModule,
    EffectsModule,
  ],
})
export class AuthStoreModule {
  static forRoot(
    configuration: StoreConfiguration,
  ): ModuleWithProviders<AuthStoreModule> {
    return {
      ngModule: AuthStoreModule,
      providers: [
        // Store Configuration
        { provide: STORE_CONFIGURATION, useValue: configuration },

        // Services
        AuthStoreService,
        AttributesStoreService,
        CacheService,
        CurrenciesStoreService,
        LocalesStoreService,
      ],
    }
  }
}
