<button
  class="btn {{ btnClasses }} {{ buttonClass }}"
  [class.disabled]="faded || disabled"
  type="button"
  [disabled]="disabled"
  [title]="title"
  [opAdvice]="advice"
  (click)="buttonClick.emit()"
>
  <ng-content></ng-content> <i *ngIf="icon" class="icon {{ icon }}"></i>
</button>
