import { EntityStatusAuth, EntityStatusDesc } from '../../models/entity.model'
import { Field } from '../../models/field.model'
import {
  FilterCollection,
  FilterOperator,
  FilterType,
} from '../../models/filter.model'
import { PermissionsScope } from '../policies'
import {
  SupplierOrderChangeStatusAction,
  SupplierOrderField,
  SupplierOrderPermission,
  SupplierOrderRowStatus,
  SupplierOrderStatus,
  SupplierOrderViewType,
} from './supplier-order.model'

export const SUPPLIER_ORDER_SCOPE: PermissionsScope = 'supplierOrders'
export const SUPPLIER_ORDER_PATH_KEY = 'supplier-order'

export const SUPPLIER_ORDER_STATUSES_CLOSED = [SupplierOrderStatus.closed]
export const SUPPLIER_ORDER_STATUSES_ACTIVE = [
  SupplierOrderStatus.confirmed,
  SupplierOrderStatus.assigned,
  SupplierOrderStatus.received,
]

export const SUPPLIER_ORDER_STATUSES: EntityStatusDesc<SupplierOrderStatus>[] =
  [
    {
      code: SupplierOrderStatus.drafted,
      name: 'Bozza',
      primary: true,
      tooltip: 'Ordine fornitore in bozza, pronto per essere confermato.',
    },
    {
      code: SupplierOrderStatus.confirmed,
      name: 'Confermato',
      primary: true,
      tooltip: 'Ordine pronto per essere inserito in un arrivo merce.',
    },
    {
      code: SupplierOrderStatus.assigned,
      name: 'Assegnato',
      primary: true,
      tooltip:
        "Una o più righe dell'ordine sono state assegnate ad un arrivo merce. In attesa del riscontro merce.",
    },
    {
      code: SupplierOrderStatus.received,
      name: 'Ricevuto',
      primary: true,
      tooltip:
        "Una o più righe dell'ordine sono state riscontrate e caricate a magazzino.",
    },
    {
      code: SupplierOrderStatus.closed,
      name: 'Chiuso',
      primary: true,
      tooltip:
        "Ordine definitivamente chiuso. Tutti gli eventuali arrivi merce assegnati alle righe d'ordine sono stati processati.",
    },
    {
      code: SupplierOrderStatus.canceled,
      name: 'Annullato',
      primary: false,
      tooltip: 'Ordine annullato.',
    },
  ]

export const SUPPLIER_ORDER_ROW_STATUSES: EntityStatusDesc<SupplierOrderRowStatus>[] =
  [
    {
      code: SupplierOrderRowStatus.drafted,
      name: 'Bozza',
    },
    {
      code: SupplierOrderRowStatus.confirmed,
      name: 'Confermato',
    },
    {
      code: SupplierOrderRowStatus.assigned,
      name: 'Assegnato',
    },
    {
      code: SupplierOrderRowStatus.received,
      name: 'Ricevuto',
    },
    {
      code: SupplierOrderRowStatus.canceled,
      name: 'Annullato',
    },
    {
      code: SupplierOrderRowStatus.closed,
      name: 'Chiuso',
    },
  ]

export const SUPPLIER_ORDER_ACTIONS: EntityStatusAuth<
  SupplierOrderChangeStatusAction,
  SupplierOrderPermission
> = {
  DRAFTED: {
    permissions: ['addProducts', 'editHeader', 'saveOrder', 'deleteOrder'],
    actions: [
      {
        label: 'Conferma ordine',
        action: 'confirm',
      },
    ],
  },
  CONFIRMED: {
    permissions: [
      'addProducts',
      'editHeader',
      'saveOrder',
      'cancelOrder',
      'closeOrder',
    ],
    actions: [],
  },
  ASSIGNED: {
    permissions: [],
    actions: [],
  },
  RECEIVED: {
    permissions: [],
    actions: [
      {
        label: 'Chiudi ordine',
        action: 'close',
      },
    ],
  },
  CANCELED: {
    permissions: [],
    actions: [],
  },
  CLOSED: {
    permissions: ['editNotes', 'saveOrder'],
    actions: [],
  },
}

export const SUPPLIER_ORDER_FIELDS: Field<
  SupplierOrderField,
  SupplierOrderViewType
>[] = [
  {
    label: '# ordine',
    field: 'header.orderNumber',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Data/Ora creazione',
    field: 'createdAt',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Data consegna prevista',
    field: 'header.deliveryDate',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Magazzino',
    field: 'warehouseId',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.warehouses,
  },
  {
    label: 'Rif. ordine',
    field: 'header.rifOrder',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Rif. data',
    field: 'header.rifDate',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Fornitore',
    field: 'supplierId',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.suppliers,
  },
  {
    label: 'Subtotale i.e.',
    field: 'header.subTotal',
  },
  {
    label: 'Totale',
    field: 'header.totalOrderAmount',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Stato',
    field: 'status',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Righe',
    field: 'rowsCount',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Pezzi',
    field: 'productsCount',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: '% Ord./Ric.',
    field: 'completion',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Note',
    field: 'header.notes',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Parziale',
    field: 'isPartial',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Allegati',
    field: 'attachments',
    defaultViews: ['SUPPLIER_ORDERS_LISTING_MAIN'],
  },
]
