import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'
import { DxListComponent } from 'devextreme-angular'
import DataSource from 'devextreme/data/data_source'

@Component({
  selector: 'op-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class ListComponent {
  @ViewChild('list', { static: true }) list!: DxListComponent

  @Input() placeholder?: string
  @Input() class?: string
  @Input() key?: string
  @Input() values?: any
  @Input() dataSource?: any

  // Templates
  @Input() itemTemplate?: TemplateRef<any>

  // Search params
  @Input() keyField = '_id'
  @Input() searchField = 'name'
  @Input() displayField = 'name'

  // Style
  @Input() searchEnabled = false
  @Input() selectionEnabled = false
  @Input() clearButton = true
  @Input() disabled = false

  @Output() readonly valuesChange = new EventEmitter<any>()

  reloadList() {
    this.list.instance.reload()
  }

  checkValue(values: any | null) {
    return values === null ? [] : values
  }
}
