import { Attachment } from '../files'
import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { OrderTransport } from '../orders/order.model'
import { Payment } from '../payments'
import { Reason } from '../reasons'
import { Supplier } from '../suppliers'
import { Warehouse } from '../warehouses'

export enum GoodsReceiveStatus {
  drafted = 'DRAFTED',
  pending = 'PENDING',
  confirmed = 'CONFIRMED',
  processing = 'PROCESSING',
  processed = 'PROCESSED',
  closed = 'CLOSED',
  canceled = 'CANCELED',
}

export interface GoodsReceive extends Entity {
  status: GoodsReceiveStatus // Stato ordine

  warehouseId?: string // ID Magazzino destinazione
  supplierId?: string // ID Fornitore
  locationId?: string // ID Location di default

  header: GoodsReceiveHeader
  rows: GoodsReceiveRow[] // Righe ordine

  attachments?: Attachment[]
}

export interface GoodsReceiveHeader {
  orderNumber?: number // Numero ordine

  date: Date // Data ordine
  transport?: OrderTransport // Trasporto
  paymentType?: string // Pagamento
  currency?: string

  deliveryDate?: Date // Data consegna stimata
  arrivalDate?: Date // Data di arrivo

  rifDoc?: string // Riferimento Documento di trasporto
  rifDate?: Date // Riferimento data Documento di trasporto
  reasonId?: string // Causale

  shippingCost: number // Costo spedizione IVA esclusa
  shippingCostWithTaxes: number // Costo spedizione IVA inclusa
  shippingTaxRate: number // Percentuale IVA spese di spedizione
  shippingTaxes: number // Importo IVA delle spese di spedizione

  subTotal: number // Totale fatturato iva esclusa
  subTotalWithTaxes: number // Totale fatturato iva inclusa
  subTotalReceived: number // Totale ricevuto iva esclusa
  subTotalReceivedWithTaxes: number // Totale ricevuto iva inclusa
  totalOrderAmount: number // Totale fatturato IVA inclusa comprese spese di spedizione
  totalReceivedAmount: number // Totale ricevuto IVA inclusa comprese spese di spedizione
  notCompliantQtyAmount: number // Sommatoria di (notCompliantQty * invoiceNetPrice)
  notCompliantQualityAmount: number // Sommatoria di (notCompliantQuality * invoiceNetPrice)
  notCompliantPriceAmount: number // Sommatoria di notCompliantPriceAmount
  notCompliantTotal?: number

  notes?: string
}

export interface GoodsReceiveRow {
  _id?: string

  supplierOrderId?: string // ID Ordine fornitore
  transferOrderId?: string // ID Ordine di trasferimento

  product: GoodsReceiveProduct
  productTaxRate?: number

  orderedQty: number // Qtà ordinata
  receivedQty: number // Qtà ricevuta
  invoicedQty: number // Qtà fatturata

  netPrice: number // Prezzo singolo articolo su OrderPod
  invoicedNetPrice: number // Prezzo singolo articolo fatturato
  invoicedNetPriceAmount: number // Prezzo totale riga fatturato
  notCompliantQty: number // Quantità prodotti non conforme per quantità fatturata (receivedQty - invoicedQty)
  notCompliantQuality: number // Quantità prodotti non conforme per qualità (prodotti danneggiati)
  notCompliantPrice: number // Prezzo singolo non conforme -> invoiceNetPrice - netPrice
  notCompliantPriceAmount: number // Totale prezzo non conforme -> notCompliantPrice * invoicedQty

  isPartial?: boolean
}

export interface GoodsReceiveProduct {
  _id: string // ID
  SKU: string // SKU
  name: string // Nome
  supplierSKU?: string // SKU Fornitore
  price?: number // Prezzo Fornitore
  putAwayLocations?: GoodsReceiveLocation[]
}

export interface GoodsReceiveLocation {
  _id: string
  quantity: number
  batches?: GoodsReceiveLocationBatch[]
}

export interface GoodsReceiveLocationBatch {
  quantity: number
  lot?: string
  expirationDate?: string
  serial?: string
}

export interface GoodsReceiveSearchParams
  extends QueryStringParams<GoodsReceiveSortField> {
  status?: GoodsReceiveStatus | GoodsReceiveStatus[]
  warehouseId?: string | string[]
  supplierId?: string | string[]
  locationId?: string | string[]
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  'header.orderNumber'?: number | number[]
  'header.orderNumber:ne'?: number
  'header.orderNumber:lt'?: number
  'header.orderNumber:le'?: number
  'header.orderNumber:gt'?: number
  'header.orderNumber:ge'?: number
  'header.date'?: string
  'header.date:ne'?: string
  'header.date:lt'?: string
  'header.date:le'?: string
  'header.date:gt'?: string
  'header.date:ge'?: string
  'header.transport'?: OrderTransport | OrderTransport[]
  'header.paymentType'?: string | string[]
  'header.currency'?: string | string[]
  'header.deliveryDate'?: string
  'header.deliveryDate:ne'?: string
  'header.deliveryDate:lt'?: string
  'header.deliveryDate:le'?: string
  'header.deliveryDate:gt'?: string
  'header.deliveryDate:ge'?: string
  'header.arrivalDate'?: string
  'header.arrivalDate:ne'?: string
  'header.arrivalDate:lt'?: string
  'header.arrivalDate:le'?: string
  'header.arrivalDate:gt'?: string
  'header.arrivalDate:ge'?: string
  'header.rifDate'?: string
  'header.rifDate:ne'?: string
  'header.rifDate:lt'?: string
  'header.rifDate:le'?: string
  'header.rifDate:gt'?: string
  'header.rifDate:ge'?: string
  'header.rifDoc'?: string
  'header.rifDoc:ne'?: string
  'header.reasonId'?: string | string[]
  'rows.product._id'?: string | string[]
  'rows.product.SKU'?: string | string[]
  'rows.notCompliantQuality:gt'?: number
  q?: string
}

export interface GoodsReceiveRowSearchParams {
  limit?: number
  offset?: number
  'product._id'?: string
  'product.SKU'?: string
}

export interface GoodsReceiveProductSummary {
  productsCount: number
  rowsCount: number
  rowsReceivedCount: number
  product: {
    locations: GoodsReceiveLocation[]
    batches: GoodsReceiveLocationBatch[]
    orderedQty: number
    receivedQty: number
  }
}

export interface GoodsReceivesListingPage {
  data?: GoodsReceive[]
  extData?: GoodsReceivesListingData
  totalCount?: number
}

export interface GoodsReceiveListingKeys {
  warehouseIds?: string[]
  supplierIds?: string[]
  paymentIds?: string[]
  reasonIds?: string[]
}

export interface GoodsReceivesListingData {
  warehouses?: Warehouse[]
  suppliers?: Supplier[]
  payments?: Payment[]
  reasons?: Reason[]
}

export type GoodsReceiveViewType = 'GOODS_RECEIVES_LISTING_MAIN'

export type GoodsReceiveField =
  | '_id'
  | 'createdAt'
  | 'status'
  | 'warehouseId'
  | 'supplierId'
  | 'locationId'
  | 'header.orderNumber'
  | 'header.date'
  | 'header.transport'
  | 'header.paymentType'
  | 'header.currency'
  | 'header.deliveryDate'
  | 'header.arrivalDate'
  | 'header.rifDate'
  | 'header.rifDoc'
  | 'header.reasonId'
  | 'header.totalOrderAmount'
  | 'rows.product._id'
  | 'rows.product.SKU'
  | 'rows.notCompliantQuality'
  | 'rowsCount'
  | 'productsCount'
  | 'completionOrd'
  | 'completionInv'
  | 'header.notes'
  | 'attachments'

export type GoodsReceiveSortField =
  | 'createdAt'
  | 'header.orderNumber'
  | 'header.date'
  | 'header.rifDate'
  | 'header.arrivalDate'
  | 'header.deliveryDate'

export type GoodsReceiveChangeStatusAction =
  | 'pend'
  | 'confirm'
  | 'manage'
  | 'process'
  | 'cancel'
  | 'close'

export type GoodsReceivePermission =
  | 'addProducts'
  | 'editHeader'
  | 'editWarehouse'
  | 'checkInvoice'
  | 'saveOrder'
  | 'deleteOrder'
  | 'cancelOrder'
  | 'editArrivalDate'
  | 'checkQuality'
  | 'refMandatory'
  | 'editShipping'
  | 'editArrival'
  | 'editNotes'
  | 'toggleAmountsEditable'
