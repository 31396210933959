import { createSelector } from '@ngrx/store'
import { StoreState } from '../store.state'
import { CurrenciesState } from './currencies.state'

export const selectCurrenciesState = (state: StoreState) => state.currencies

export const selectCurrencies = (tenantId: string) =>
  createSelector(selectCurrenciesState, (state: CurrenciesState) => {
    const tenantCurrencies = state[tenantId]

    if (!tenantCurrencies || !tenantCurrencies.currencies) {
      return undefined
    }

    return tenantCurrencies.currencies
      ? Object.values(tenantCurrencies.currencies)
      : undefined
  })

export const selectCurrency = (tenantId: string, currencyCode: string) =>
  createSelector(selectCurrenciesState, (state: CurrenciesState) => {
    const tenantCurrencies = state[tenantId]

    if (!tenantCurrencies || !tenantCurrencies.currencies) {
      return undefined
    }

    return tenantCurrencies.currencies[currencyCode]
  })
