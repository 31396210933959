import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { registerLocaleData, DecimalPipe } from '@angular/common'

// PWA
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'

// Routing
import { AppRoutingModule } from './app.routing'

// Components
import { AppComponent } from './app.component'
import {
  AuthComponent,
  CarouselComponent,
  LoginComponent,
  PasswordChangeComponent,
  PasswordRecoveryComponent,
  TenantSelectionComponent,
} from './components'

// Modules
import { AdminCoreModule } from '@evologi/admin/core'
import { AuthStoreModule } from '@evologi/shared/auth-store'
import {
  ENVIRONMENT,
  SharedDataAccessApiModule,
} from '@evologi/shared/data-access-api'

// I18N
import localeIt from '@angular/common/locales/it'
registerLocaleData(localeIt, 'it')

// External modules
import { ModalModule } from 'ngx-bootstrap/modal'
import { CarouselModule } from 'ngx-bootstrap/carousel'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

// Devextreme configuration and localization
import config from 'devextreme/core/config'
import { locale, loadMessages } from 'devextreme/localization'
import messagesIt from 'devextreme/localization/messages/it.json'

// Fixes missing translations
messagesIt.it['dxHtmlEditor-dialogBackgroundCaption'] = 'Colore sfondo'
messagesIt.it['dxHtmlEditor-dialogColorCaption'] = 'Colore testo'
messagesIt.it['dxHtmlEditor-dialogLinkCaption'] = 'Aggiungi collegamento'
messagesIt.it['dxHtmlEditor-dialogLinkTargetField'] =
  'Apri in una nuova finestra'
messagesIt.it['dxHtmlEditor-dialogLinkTextField'] = 'Testo'
messagesIt.it['dxHtmlEditor-dialogLinkUrlField'] = 'URL'
messagesIt.it['dxHtmlEditor-heading'] = 'Testata'
messagesIt.it['dxHtmlEditor-normalText'] = 'Paragrafo'
messagesIt.it['dxHtmlEditor-bold'] = 'Grassetto'
messagesIt.it['dxHtmlEditor-dialogImageCaption'] = 'Aggiungi immagine'
messagesIt.it['dxHtmlEditor-dialogImageUrlField'] = 'URL'
messagesIt.it['dxHtmlEditor-dialogImageAltField'] = 'Testo alternativo'
messagesIt.it['dxHtmlEditor-dialogImageWidthField'] = 'Larghezza (px)'
messagesIt.it['dxHtmlEditor-dialogImageHeightField'] = 'Altezza (px)'
messagesIt.it['dxHtmlEditor-dialogInsertTableRowsField'] = 'Righe'
messagesIt.it['dxHtmlEditor-dialogInsertTableColumnsField'] = 'Colonne'
messagesIt.it['dxHtmlEditor-dialogInsertTableCaption'] = 'Inserisci tabella'
messagesIt.it['dxHtmlEditor-background'] = 'Colore sfondo'
messagesIt.it['dxHtmlEditor-color'] = 'Colore testo'
messagesIt.it['dxHtmlEditor-font'] = 'Font'
messagesIt.it['dxHtmlEditor-italic'] = 'Corsivo'
messagesIt.it['dxHtmlEditor-link'] = 'Aggiungi collegamento'
messagesIt.it['dxHtmlEditor-image'] = 'Aggiungi immagine'
messagesIt.it['dxHtmlEditor-size'] = 'Dimensione'
messagesIt.it['dxHtmlEditor-strike'] = 'Strikethrough'
messagesIt.it['dxHtmlEditor-subscript'] = 'Subscript'
messagesIt.it['dxHtmlEditor-superscript'] = 'Superscript'
messagesIt.it['dxHtmlEditor-underline'] = 'Sottolineato'
messagesIt.it['dxHtmlEditor-blockquote'] = 'Blockquote'
messagesIt.it['dxHtmlEditor-header'] = 'Testata'
messagesIt.it['dxHtmlEditor-increaseIndent'] = 'Aumenta indentazione'
messagesIt.it['dxHtmlEditor-decreaseIndent'] = 'Diminuisci indentazione'
messagesIt.it['dxHtmlEditor-orderedList'] = 'Lista ordinata'
messagesIt.it['dxHtmlEditor-bulletList'] = 'Lista puntata'
messagesIt.it['dxHtmlEditor-alignLeft'] = 'Allineamento a sinistra'
messagesIt.it['dxHtmlEditor-alignCenter'] = 'Allineamento al centro'
messagesIt.it['dxHtmlEditor-alignRight'] = 'Allineamento a destra'
messagesIt.it['dxHtmlEditor-alignJustify'] = 'Allineamento giustificato'
messagesIt.it['dxHtmlEditor-codeBlock'] = 'Blocco codice'
messagesIt.it['dxHtmlEditor-variable'] = 'Aggiungi variabile'
messagesIt.it['dxHtmlEditor-undo'] = 'Indietro'
messagesIt.it['dxHtmlEditor-redo'] = 'Avanti'
messagesIt.it['dxHtmlEditor-clear'] = 'Pulisci formattazione'
messagesIt.it['dxHtmlEditor-insertTable'] = 'Inserisci tabella'
messagesIt.it['dxHtmlEditor-insertHeaderRow'] = 'Inserisci riga di testata'
messagesIt.it['dxHtmlEditor-insertRowAbove'] = 'Inserisci riga sopra'
messagesIt.it['dxHtmlEditor-insertRowBelow'] = 'Inserisci riga sotto'
messagesIt.it['dxHtmlEditor-insertColumnLeft'] = 'Inserisci colonna a sinistra'
messagesIt.it['dxHtmlEditor-insertColumnRight'] = 'Inserisci colonna a destra'
messagesIt.it['dxHtmlEditor-deleteColumn'] = 'Elimina colonna'
messagesIt.it['dxHtmlEditor-deleteRow'] = 'Elimina riga'
messagesIt.it['dxHtmlEditor-deleteTable'] = 'Elimina tabella'
messagesIt.it['dxHtmlEditor-cellProperties'] = 'Proprietà cella'
messagesIt.it['dxHtmlEditor-tableProperties'] = 'Proprietà tabella'
messagesIt.it['dxHtmlEditor-insert'] = 'Inserisci'
messagesIt.it['dxHtmlEditor-delete'] = 'Elimina'
messagesIt.it['dxHtmlEditor-border'] = 'Bordo'
messagesIt.it['dxHtmlEditor-style'] = 'Stile'
messagesIt.it['dxHtmlEditor-width'] = 'Larghezza'
messagesIt.it['dxHtmlEditor-height'] = 'Altezza'
messagesIt.it['dxHtmlEditor-borderColor'] = 'Colore'
messagesIt.it['dxHtmlEditor-tableBackground'] = 'Sfondo'
messagesIt.it['dxHtmlEditor-dimensions'] = 'Dimensioni'
messagesIt.it['dxHtmlEditor-alignment'] = 'Allineamento'
messagesIt.it['dxHtmlEditor-horizontal'] = 'Orizzontale'
messagesIt.it['dxHtmlEditor-vertical'] = 'Verticale'
messagesIt.it['dxHtmlEditor-paddingVertical'] = 'Spaziatura verticale'
messagesIt.it['dxHtmlEditor-paddingHorizontal'] = 'Spaziatura orizzontale'
messagesIt.it['dxHtmlEditor-pixels'] = 'Pixel'
messagesIt.it['dxHtmlEditor-list'] = 'Lista'
messagesIt.it['dxHtmlEditor-ordered'] = 'Ordinata'
messagesIt.it['dxHtmlEditor-bullet'] = 'Puntata'
messagesIt.it['dxHtmlEditor-align'] = 'Allineamento'
messagesIt.it['dxHtmlEditor-center'] = 'Centro'
messagesIt.it['dxHtmlEditor-left'] = 'Sinistra'
messagesIt.it['dxHtmlEditor-right'] = 'Destra'
messagesIt.it['dxHtmlEditor-indent'] = 'Indentatura'
messagesIt.it['dxHtmlEditor-justify'] = 'Giustificato'

loadMessages(messagesIt)
locale(navigator.language)
config({
  forceIsoDateParsing: true,
})

@NgModule({
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Routing modules
    AppRoutingModule,

    // External modules
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'Caricamento...',
    }),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),

    // PWA
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.ngswEnabled,
    }),

    // Modules
    AdminCoreModule.forRoot(),
    AuthStoreModule.forRoot({
      authenticationRoute: '/auth/login',
      tenantSelectionRoute: '/auth/tenant',
      passwordChangeRoute: '/auth/change',
      authenticatedRoute: '/home',
      privateKey: environment.privateKey,
      useAuthToken: environment.localhost,
      useLocalStorage: true,
    }),
    SharedDataAccessApiModule.forRoot({
      apiUrl: environment.apiUrl,
      client: environment.localhost ? 'APP' : 'WEB',
    }),
  ],
  declarations: [
    AppComponent,
    AuthComponent,
    CarouselComponent,
    LoginComponent,
    PasswordChangeComponent,
    PasswordRecoveryComponent,
    TenantSelectionComponent,
  ],
  providers: [
    DecimalPipe,

    // Environment
    { provide: ENVIRONMENT, useValue: environment },

    // i18n
    { provide: LOCALE_ID, useValue: 'it' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
