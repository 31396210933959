import { isEmpty, isNil } from 'lodash'
import { isDate } from '@evologi/shared/util-toolkit'

import { CatalogLocales, Locales } from '../../../models/util.model'

export function removeEmptyScope<T>(
  values?: CatalogLocales<T>[]
): CatalogLocales<T>[] | undefined {
  const newValues = values
    ? values.filter((val) => !isEmpty(removeEmptyLocale<T>(val.locales)))
    : []
  return newValues?.length ? [...newValues] : undefined
}

export function removeEmptyLocale<T>(locales: Locales<T>): Locales<T> {
  const values: any = {}

  for (const locale of Object.keys(locales)) {
    const localeValue: any = locales[locale]

    // Multi value type
    if (!isNil(localeValue) && localeValue !== '') {
      if (Array.isArray(localeValue)) {
        if (localeValue.length > 0) {
          values[locale] = localeValue
        }
      } else if (typeof localeValue === 'object') {
        if (isDate(localeValue)) {
          values[locale] = localeValue
        } else if (
          !isNil(localeValue['value']) &&
          localeValue['value'] !== ''
        ) {
          values[locale] = localeValue
        }
      } else {
        values[locale] = localeValue
      }
    }
  }
  return values
}
