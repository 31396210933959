import { cloneDeep, defaultsDeep } from 'lodash'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import {
  Currency,
  CurrencySearchField,
  CurrencySearchParams,
  CurrencySortField,
} from './currency.model'

// Initial states

const CURRENCY_INITIAL_STATE: Partial<Currency> = {
  code: undefined,
  symbol: undefined,
}

// Init

export function initCurrency(currency: Partial<Currency> = {}): Currency {
  return defaultsDeep(cloneDeep(currency), CURRENCY_INITIAL_STATE)
}

// Search

export function currencySortParams(
  params: CurrencySearchParams,
  sort: QueryStringSort<CurrencySortField>
): CurrencySearchParams {
  const searchParams: CurrencySearchParams = {}

  switch (sort.field) {
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function currencyFilterParams(
  params: CurrencySearchParams,
  filter: QueryStringFilter<CurrencySearchField>
): CurrencySearchParams {
  const searchParams: CurrencySearchParams = {}

  switch (filter.field) {
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
