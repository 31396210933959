import { TagTarget } from './tag.model'

export const TAG_TARGETS = [
  {
    code: TagTarget.products,
    name: 'Prodotti',
  },
  {
    code: TagTarget.orders,
    name: 'Ordini clienti',
  },
]
