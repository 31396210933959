import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import {
  combineLatest,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'

import { User, UserRole, UserTenantSettings, UserToken } from '../user.model'
import { UsersService } from '../users.service'
import {
  getUserTenantSettings,
  initUser,
  setUserTenantSettings,
} from '../libs/user.lib'
import { UsersRepository } from '../users.repository'
import { UserNotification } from '../libs/user-notification.lib'
import { Permissions, initPermissions } from '../../policies'

export interface UserDetailState {
  tenantId?: string
  userId?: string
  user?: User
  permissions?: Permissions
  rolesToApply?: string[]
  error?: HttpErrorResponse
  notification?: UserNotification
  toClose: boolean
  isLoading: boolean
  isModified: boolean
  isPermissionsModified: boolean
}

const USER_DETAIL_INITIAL_STATE: UserDetailState = {
  toClose: false,
  isLoading: false,
  isModified: false,
  isPermissionsModified: false,
}

@Injectable()
export class UserDetailUseCase extends ComponentStore<UserDetailState> {
  constructor(
    private usersService: UsersService,
    private usersRepository: UsersRepository,
  ) {
    super(USER_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<UserDetailState> = this.select(
    (state) => state,
  )

  readonly selectCreatedBy$: Observable<string | undefined> = this.select(
    (state) => state.user?.createdBy,
  )

  readonly selectUpdatedBy$: Observable<string | undefined> = this.select(
    (state) => state.user?.updatedBy,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified || state.isPermissionsModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsEnabled$: Observable<boolean> = this.select(
    (state) => !state.user?.isDisabled,
  )

  readonly selectIsDisabled$: Observable<boolean> = this.select(
    (state) => !!state.user?.isDisabled,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectUserId$: Observable<string | undefined> = this.select(
    (state) => state.user?._id,
  )

  readonly selectUser$: Observable<User | undefined> = this.select(
    (state) => state.user,
  )

  readonly selectPermissions$ = this.select((state) => state.permissions)

  readonly selectType$ = this.selectUser$.pipe(map((user) => user?.type))

  readonly selectUserLoaded$: Observable<User | undefined> = this.select(
    (state) => state,
  ).pipe(
    filter((state) => !state.isLoading),
    map((state) => state.user),
  )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectNotification$: Observable<UserNotification | undefined> =
    this.select((state) => state.notification)

  readonly selectTenantId$ = this.selectState$.pipe(
    map((state) => state.tenantId),
  )

  readonly selectTenantSettings$: Observable<UserTenantSettings | undefined> =
    this.selectState$.pipe(
      map((state) =>
        state.tenantId && state.user
          ? getUserTenantSettings(state.user, state.tenantId)
          : undefined,
      ),
    )

  readonly selectUserRoles$: Observable<UserRole[] | undefined> =
    this.selectUser$.pipe(map((user) => user?.roles))

  readonly selectRoleIds$ = this.selectUserRoles$.pipe(
    withLatestFrom(this.selectTenantId$),
    map(([roles, tenantId]) =>
      roles?.filter((r) => r.tenantId === tenantId).map((r) => r._id),
    ),
  )

  // Effects

  readonly init$ = this.effect(
    (user$: Observable<Partial<User> | undefined>) => {
      return user$.pipe(
        tap((user) =>
          this.setUserData({
            user: initUser(user),
            permissions: initPermissions(),
          }),
        ),
      )
    },
  )

  readonly load$ = this.effect((userId$: Observable<string>) => {
    return userId$.pipe(
      tap((userId) => this.setUserId(userId)),
      switchMap((userId) =>
        this.usersService.read$(userId).pipe(
          switchMap((user) =>
            this.usersService.getPermissions$(user._id).pipe(
              tapResponse(
                (permissions) => this.setUserData({ user, permissions }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      withLatestFrom(this.selectState$),
      switchMap(([toClose, state]) => {
        if (!state.user || !state.tenantId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.upsert$(state.user).pipe(
          switchMap((user) =>
            combineLatest({
              roles:
                state.rolesToApply && state.tenantId
                  ? this.usersRepository.updateUserRoles$(
                      user,
                      state.tenantId,
                      state.rolesToApply,
                    )
                  : of(null),
              permissions:
                state.isPermissionsModified && state.permissions
                  ? this.usersService.setPermissions$(
                      user._id,
                      state.permissions,
                    )
                  : of(state.permissions),
            }).pipe(
              tapResponse(
                ({ permissions }) =>
                  this.setUserSaved({ user, permissions, toClose }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly updateRoles$ = this.effect((rolesIds$: Observable<string[]>) => {
    return rolesIds$.pipe(
      withLatestFrom(this.selectUser$, this.selectTenantId$),
      switchMap(([roleIds, user, tenantId]) => {
        if (!user || !tenantId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersRepository
          .updateUserRoles$(user, tenantId, roleIds)
          .pipe(
            tapResponse(
              ({ user, permissions }) =>
                this.setUserData({
                  user,
                  permissions,
                  notification: UserNotification.from({
                    code: 'USERS_ROLES_UPDATED',
                  }),
                }),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly setPassword$ = this.effect((newPassword$: Observable<string>) => {
    return newPassword$.pipe(
      withLatestFrom(this.selectUserId$),
      switchMap(([newPassword, userId]) => {
        if (!userId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.setPassword$(userId, newPassword).pipe(
          tapResponse(
            () =>
              this.setNotification(
                UserNotification.from({ code: 'PASSWORD_UPDATED' }),
              ),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly createToken$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectUserId$),
      switchMap(([_, userId]) => {
        if (!userId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.createToken$(userId).pipe(
          switchMap((token) =>
            this.usersService.read$(userId).pipe(
              tapResponse(
                (user) =>
                  this.setUserData({
                    user,
                    notification: UserNotification.from({
                      code: 'TOKEN_CREATED',
                      data: token,
                    }),
                  }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly deleteToken$ = this.effect((token$: Observable<UserToken>) => {
    return token$.pipe(
      withLatestFrom(this.selectUserId$),
      switchMap(([token, userId]) => {
        if (!userId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.deleteToken$(userId, token._id).pipe(
          switchMap(() =>
            this.usersService.read$(userId).pipe(
              tapResponse(
                (user) =>
                  this.setUserData({
                    user,
                    notification: UserNotification.from({
                      code: 'TOKEN_DELETED',
                    }),
                  }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
          ),
        )
      }),
    )
  })

  readonly enable$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectUserId$),
      switchMap(([_, userId]) => {
        if (!userId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.enable$(userId).pipe(
          tapResponse(
            (user) => this.setUser(user),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly disable$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectUserId$),
      switchMap(([_, userId]) => {
        if (!userId) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.usersService.disable$(userId).pipe(
          tapResponse(
            (user) => this.setUser(user),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly delete$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectUser$),
      switchMap(([_, user]) => {
        if (!user) {
          return EMPTY
        }

        return this.usersRepository
          .alert$(
            UserNotification.from({
              code: 'USER_DELETION',
              data: { user },
            }),
          )
          .pipe(
            take(1),
            switchMap((confirmation) => {
              if (!confirmation || !user) {
                return EMPTY
              }

              this.setIsLoading(true)
              return this.usersService.delete$(user._id).pipe(
                tapResponse(
                  () => this.setUserDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              )
            }),
          )
      }),
    )
  })

  // Reducers

  readonly setUserId = this.updater(
    (state, userId: string): UserDetailState => {
      return {
        tenantId: state.tenantId,
        userId,
        toClose: false,
        isLoading: true,
        isModified: false,
        isPermissionsModified: false,
      }
    },
  )

  readonly setRolesToApply = this.updater(
    (state, rolesToApply: string[]): UserDetailState => {
      return {
        ...state,
        rolesToApply,
        isModified: true,
      }
    },
  )

  readonly setTenantId = this.updater(
    (state, tenantId: string): UserDetailState => {
      return {
        ...state,
        tenantId,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): UserDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setIsModified = this.updater(
    (state, isModified: boolean): UserDetailState => {
      return {
        ...state,
        isModified,
      }
    },
  )

  readonly setUserData = this.updater(
    (
      state,
      userData: {
        user: User
        permissions?: Permissions
        notification?: UserNotification
      },
    ): UserDetailState => {
      const { user, permissions, notification } = userData
      return {
        ...state,
        user,
        permissions,
        notification,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setNotification = this.updater(
    (state, notification: UserNotification): UserDetailState => {
      return {
        ...state,
        notification,
        isLoading: false,
      }
    },
  )

  readonly setUser = this.updater(
    (state, user: User): UserDetailState => ({
      ...state,
      user,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setUserSaved = this.updater(
    (
      state,
      params: { user: User; permissions?: Permissions; toClose?: boolean },
    ): UserDetailState => {
      return {
        ...state,
        user: params.user,
        permissions: params.permissions,
        toClose: params.toClose || false,
        notification: UserNotification.from({ code: 'USER_SAVED' }),
        isLoading: false,
        isModified: false,
        isPermissionsModified: false,
      }
    },
  )

  readonly setUserDeleted = this.updater((state): UserDetailState => {
    return {
      ...state,
      notification: UserNotification.from({ code: 'USER_DELETED' }),
      toClose: true,
    }
  })

  readonly updateUser = this.updater((state, user: User): UserDetailState => {
    return {
      ...state,
      user,
      isLoading: false,
      isModified: true,
    }
  })

  readonly updatePermissions = this.updater(
    (state, permissions: Permissions): UserDetailState => {
      return {
        ...state,
        permissions,
        isPermissionsModified: true,
      }
    },
  )

  readonly updateUserTenantSettings = this.updater(
    (state, settings: UserTenantSettings): UserDetailState => {
      if (!state.user || !state.tenantId) {
        return state
      }

      return {
        ...state,
        user: setUserTenantSettings(state.user, state.tenantId, settings),
        isModified: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): UserDetailState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setToClose = this.updater(
    (state, toClose: boolean): UserDetailState => {
      return {
        ...state,
        toClose,
      }
    },
  )
}
