import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
} from '@angular/core'
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { ModalAlert } from '@evologi/shared/data-access-api'
import { LoadingBarService } from '@ngx-loading-bar/core'
import { ModalClosureComponent } from '../modal-closure/modal-closure.component'

@Component({
  selector: 'op-modal',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalFrameComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() public title?: string
  @Input() public tabs = false
  @Input() public shoulder = false
  @Input() public shoulderFull = false
  @Input() public shoulderLeft = false
  @Input() public shoulderRight = false
  @Input() public padding = true
  @Input() public header = true
  @Input() public headerCustom = false
  @Input() public footer = true
  @Input() public btnClass = ''
  @Input() public alert?: ModalAlert
  @Input() public info?: any[]
  @Input() public showCloseButton?: boolean
  @Input() cancelLabel?: string
  // Status
  @Input() public modified? = false
  @Input() public loading? = false

  // Loader
  private loader = this.loadingBar.useRef('modal')

  // Closure modal
  private modalClosure?: BsModalRef
  private modalOptions: ModalOptions = {
    backdrop: 'static',
    class: 'modal-sm modal-dialog-centered',
    initialState: {},
  }

  constructor(
    private loadingBar: LoadingBarService,
    private modal: BsModalRef,
    private modalService: BsModalService,
  ) {}

  // Lifecycle methods

  ngOnInit() {
    this.setZIndex()
  }

  ngOnDestroy() {
    this.setZIndex()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading']) {
      changes['loading'].currentValue ? this.loader.start() : this.loader.stop()
    }
  }

  ngAfterViewInit() {
    if (!this.modalOptions) {
      return
    }

    this.modalOptions.initialState = {
      element: this.title,
    }
  }

  // Modal methods

  closeModal() {
    if (this.modified) {
      this.showClosureModal()
    } else {
      this.modal.hide()
    }
  }

  showClosureModal() {
    this.modalClosure = this.modalService.show(
      ModalClosureComponent,
      this.modalOptions,
    )
    this.modalClosure.content.action.subscribe((options: any) => {
      if (options['parent']) {
        this.modal.hide()
      }

      if (options['save']) {
        this.modal.content.save()
      }
    })
  }

  // Useful

  setZIndex() {
    let zIndex = 1040
    const elements = Array.from(document.getElementsByClassName('modal'))

    elements.reverse().forEach((element: any) => {
      element['style']['zIndex'] = zIndex
      zIndex -= 10
    })
  }
}
