import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'
import { difference, uniq } from 'lodash'

import { Category, CategorySearchParams } from './category.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'categories'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new category
   * @param category - The category to create
   * @returns The observable<Category> to create the category
   */
  create$(category: Category): Observable<Category> {
    return this._create$<Category>(category)
  }

  /**
   * Read a category by ID
   * @param categoryId - The category ID
   * @returns The observable<Category> for read the category
   */
  read$(categoryId: string): Observable<Category> {
    return this._read$<Category>(categoryId)
  }

  /**
   * Update a category by ID
   * @param categoryId - The category ID
   * @param category - The category body to update
   * @returns The observable<Category> for update the category
   */
  update$(categoryId: string, category: Category): Observable<Category> {
    return this._update$<Category>(categoryId, category)
  }

  /**
   * Create or update a category by ID
   * @param categoryId - The category ID
   * @param category - The category body to update
   * @returns The observable<Category> for update the category
   */
  upsert$(category: Category): Observable<Category> {
    return this._upsert$<Category>(category, category._id)
  }

  /**
   * Delete a category by ID
   * @param categoryId - The category ID
   * @returns The observable<Category> for delete the category
   */
  delete$(categoryId: string): Observable<Category> {
    return this._delete$<Category>(categoryId)
  }

  /**
   * Search categories by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Category>> for search categories
   */
  search$(
    params?: CategorySearchParams,
    returnAll = false,
  ): Observable<Page<Category>> {
    return this._search$<Category>(params, returnAll)
  }

  /**
   * List categories by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Category[]> for list categories
   */
  list$(
    params?: CategorySearchParams,
    returnAll = false,
  ): Observable<Category[]> {
    return this._list$<Category>(params, returnAll)
  }

  /**
   * Read a category by params
   * @param params - The search params
   * @returns the observable<Category> for find a catalog
   */
  readOne$(params?: CategorySearchParams): Observable<Category> {
    return this._readOne$<Category>(params)
  }

  /**
   * Find a category by params
   * @param params - The search params
   * @returns the observable<Category> for find a catalog
   */
  findOne$(params?: CategorySearchParams): Observable<Category | undefined> {
    return this._findOne$<Category>(params)
  }

  /**
   * Count products of each category
   * @param categoryIds - The category IDs to count
   * @param catalogCode - The catalog code
   * @returns The observable<{ _id: string, count: number }[]> for count product categories
   */
  countProducts$(
    categoryIds: string[],
    catalogCode?: string,
  ): Observable<{ _id: string; count: number }[]> {
    return this.http.post<{ _id: string; count: number }[]>(
      `${this.apiUrl}/products/count`,
      {
        categoryIds,
        catalogCode,
      },
    )
  }

  /**
   * List categories trees
   * @returns the observable for load categories trees
   */
  listTrees$(): Observable<Category[]> {
    return this.list$(
      {
        'parentId:ex': false,
      },
      true,
    )
  }

  /**
   * List tree categories
   * @param rootId - the root category ID
   * @returns the observable for load tree categories
   */
  listTreeCategories$(rootId: string): Observable<Category[]> {
    return this.list$(
      {
        rootId,
        'parentId:ex': true,
      },
      true,
    )
  }

  /**
   * Generate categories store
   * @param categoryIds - the category IDs to load
   * @param categories - the categories already loaded
   * @returns the Observable<Category[]> as store
   */
  store$(
    categoryIds: string[],
    categories: Category[],
  ): Observable<Category[]> {
    categoryIds = uniq(categoryIds)
    categoryIds = difference(
      categoryIds,
      categories.map((u) => u._id),
    )

    if (categoryIds.length === 0) {
      return of(categories)
    }

    return this.list$({ _id: categoryIds }).pipe(
      map((cats) => [...categories, ...cats]),
    )
  }
}
