import { Injectable } from '@angular/core'
import { WarehousesService } from '../warehouses'
import { Observable, combineLatest, map, of, switchMap } from 'rxjs'
import { Page } from '../../models/util.model'
import {
  Stock,
  StockSearchParams,
  StocksField,
  StocksListingData,
  StocksListingPage,
} from './stock.model'
import { parseStockPageKeys } from './stock.lib'
import { StocksService } from './stocks.service'

@Injectable({
  providedIn: 'root',
})
export class StocksRepository {
  constructor(
    private stocksService: StocksService,
    private warehousesService: WarehousesService,
  ) {}

  /**
   * Search stocks and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search stocks and external data
   */
  searchStocks$(
    searchParams: StockSearchParams,
    fields: StocksField[],
    listingData?: StocksListingData,
  ): Observable<StocksListingPage> {
    return this.stocksService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Load page external data
   * @param page - the stocks page
   * @param fields - the fields
   * @returns the observable for load external data
   */
  private loadExtData$(
    page: Page<Stock>,
    fields: StocksField[],
    listingData?: StocksListingData,
  ): Observable<StocksListingData> {
    const extDataKeys = parseStockPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('warehouseId') && extDataKeys.warehouseIds) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }
}
