import { SelectionData } from '../../models/util.model'
import {
  FileDownloadType,
  FileUpdateMethod,
  FileUpdateType,
} from './file.model'

export const FILE_UPDATE_TYPES: SelectionData<FileUpdateType>[] = [
  {
    label: 'XLS',
    value: FileUpdateType.xls,
    icon: 'fad fa-file-spreadsheet',
  },
]

export const FILE_DOWNLOAD_TYPES: SelectionData<FileDownloadType>[] = [
  {
    label: 'Vuoto',
    value: FileDownloadType.empty,
    icon: 'fad fa-file',
  },
  {
    label: 'Compilato',
    value: FileDownloadType.filled,
    icon: 'fad fa-file-alt',
  },
]

export const FILE_UPDATE_METHODS: SelectionData<FileUpdateMethod>[] = [
  {
    label: 'Imposta valore',
    value: FileUpdateMethod.set,
    icon: 'fad fa-share',
  },
  {
    label: 'Cancella valore',
    value: FileUpdateMethod.unset,
    icon: 'fad fa-window-close',
  },
  {
    label: 'Aggiungi valore',
    value: FileUpdateMethod.push,
    icon: 'fad fa-layer-plus',
  },
  {
    label: 'Rimuovi valore',
    value: FileUpdateMethod.pull,
    icon: 'fad fa-layer-minus',
  },
  {
    label: 'Somma valore',
    value: FileUpdateMethod.add,
    icon: 'fas fa-plus',
  },
  {
    label: 'Sottrai valore',
    value: FileUpdateMethod.subtract,
    icon: 'fas fa-minus',
  },
  {
    label: 'Moltiplica valore',
    value: FileUpdateMethod.multiply,
    icon: 'fas fa-times',
  },
  {
    label: 'Dividi valore',
    value: FileUpdateMethod.divide,
    icon: 'fas fa-divide',
  },
]
