import { InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'

/**
 * Dialog structure
 */

type DialogSize =
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
type DialogIcon = 'info' | 'delete' | 'warning'
type DialogColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'light'
  | 'dark'

export interface DialogParams {
  title?: string
  titleParams?: Array<string | number>
  message?: string
  messageParams?: Array<string | number>
  color?: DialogColor
  icon?: DialogIcon
  size?: DialogSize
}

export type DialogResponse = 'CONFIRM' | 'CANCEL'

/**
 * Notification base data
 */
export type NotificationLevel = 'success' | 'info' | 'error' | 'warning'
export type NotificationType = 'event' | 'alert' | 'notification'
export declare interface Notification {
  code: string
  type: NotificationType
  level?: NotificationLevel
  dialog?: DialogParams
}

/**
 * Notification service base structure
 */
export declare interface NotificationManager {
  show: (params: DialogParams, override?: any) => any
  show$: (params: DialogParams) => Observable<void>
  success: (params: DialogParams, override?: any) => any
  error: (params: DialogParams, override?: any) => any
  warning: (params: DialogParams, override?: any) => any
  info: (params: DialogParams, override?: any) => any
}

export const NOTIFICATION_MANAGER = new InjectionToken<NotificationManager>(
  'NotificationManager',
)
