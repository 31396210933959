import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Banner, BannerSearchParams } from './banner.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'banners'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class BannersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new banner
   * @param banner - The banner to create
   * @returns The observable<Banner> to create the banner
   */
  create$(banner: Banner): Observable<Banner> {
    return this._create$<Banner>(banner)
  }

  /**
   * Read a banner by ID
   * @param bannerId - The banner ID
   * @returns The observable<Banner> for read the banner
   */
  read$(bannerId: string): Observable<Banner> {
    return this._read$<Banner>(bannerId)
  }

  /**
   * Update a banner by ID
   * @param bannerId - The banner ID
   * @param banner - The banner body to update
   * @returns The observable<Banner> for update the banner
   */
  update$(bannerId: string, banner: Banner): Observable<Banner> {
    return this._update$<Banner>(bannerId, banner)
  }

  /**
   * Create or update a banner by ID
   * @param bannerId - The banner ID
   * @param banner - The banner body to update
   * @returns The observable<Banner> for update the banner
   */
  upsert$(banner: Banner): Observable<Banner> {
    return this._upsert$<Banner>(banner, banner._id)
  }

  /**
   * Delete a banner by ID
   * @param bannerId - The banner ID
   * @returns The observable<Banner> for delete the banner
   */
  delete$(bannerId: string): Observable<Banner> {
    return this._delete$<Banner>(bannerId)
  }

  /**
   * Search banners by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Banner>> for search banners
   */
  search$(
    params?: BannerSearchParams,
    returnAll = false,
  ): Observable<Page<Banner>> {
    return this._search$<Banner>(params, returnAll)
  }

  /**
   * List banners by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Banner[]> for list banners
   */
  list$(params?: BannerSearchParams, returnAll = false): Observable<Banner[]> {
    return this._list$<Banner>(params, returnAll)
  }

  /**
   * Attach a file to a banner
   * @param bannerId - The banner ID
   * @param fileId - The file ID to attach
   * @returns The observable<Banner> for attach the file
   */
  attachFile$(bannerId: string, fileId: string): Observable<Banner> {
    return this.http.post<Banner>(`${this.apiUrl}/${bannerId}/files`, {
      fileId,
    })
  }

  /**
   * Detach a file from a banner
   * @param bannerId - The banner ID
   * @param fileId - The file ID to detach
   * @returns The observable<Banner> for detach the file
   */
  detachFile$(bannerId: string, fileId: string): Observable<Banner> {
    return this.http.delete<Banner>(
      `${this.apiUrl}/${bannerId}/files/${fileId}`,
    )
  }
}
