import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { RoleNotification } from './libs/role-notification.lib'
import { RoleNotificationOptions } from './role.model'
import { EMPTY, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RolesRepository {
  constructor(
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Show role alert
   * @param options - the warehouse notification or notification options
   * @returns the observable for show the alert about warehouse
   */
  alert$(
    opts: RoleNotification | RoleNotificationOptions,
  ): Observable<boolean> {
    const notification = RoleNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a packing event
   * @param notification - the packing notification
   */
  notify(opts: RoleNotification | RoleNotificationOptions): void {
    const notification = RoleNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }
}
