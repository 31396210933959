import { uniq } from 'lodash'
import { Tenant, TenantCatalogBarcode } from '../tenant.model'
import { ProductBarcodeType } from '../../products'
import { Locale } from '../../locales'
import { Currency } from '../../currencies'

// Barcodes

export function addTenantCatalogBarcode(
  tenant: Tenant,
  barcode: TenantCatalogBarcode = {
    type: ProductBarcodeType.generic,
    strict: false,
  }
): Tenant {
  const tenantBarcodes =
    tenant.catalog?.mandatoryProductProperties?.barcodes || []
  return {
    ...tenant,
    catalog: {
      ...(tenant.catalog || {}),
      mandatoryProductProperties: {
        barcodes: [...tenantBarcodes, barcode],
      },
    },
  }
}

export function removeTenantCatalogBarcode(
  tenant: Tenant,
  index: number
): Tenant {
  if (!tenant.catalog?.mandatoryProductProperties?.barcodes) {
    return tenant
  }

  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      mandatoryProductProperties: {
        barcodes: [
          ...tenant.catalog.mandatoryProductProperties.barcodes.filter(
            (b, i) => i !== index
          ),
        ],
      },
    },
  }
}

// Locales

export function addTenantCatalogLocales(
  tenant: Tenant,
  locales: Locale[]
): Tenant {
  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      locales: uniq([
        ...(tenant.catalog?.locales || []),
        ...locales.map((loc) => loc.tag),
      ]),
    },
  }
}

export function removeTenantCatalogLocale(
  tenant: Tenant,
  locale: Locale
): Tenant {
  if (!tenant.catalog?.locales) {
    return tenant
  }

  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      locales: [...tenant.catalog.locales.filter((lcl) => lcl !== locale.tag)],
    },
  }
}

// Currencies

export function addTenantCatalogCurrencies(
  tenant: Tenant,
  currencies: Currency[]
): Tenant {
  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      currencies: uniq([
        ...(tenant.catalog?.currencies || []),
        ...currencies.map((loc) => loc.code),
      ]),
    },
  }
}

export function removeTenantCatalogCurrencies(
  tenant: Tenant,
  currency: Currency
): Tenant {
  if (!tenant.catalog?.currencies) {
    return tenant
  }

  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      currencies: [
        ...tenant.catalog.currencies.filter((cur) => cur !== currency.code),
      ],
    },
  }
}

// Classifications

export function checkTenantClassifications(tenant: Tenant): Tenant {
  if (!tenant.catalog?.classifications?.active) {
    return {
      ...tenant,
      catalog: {
        ...tenant.catalog,
        classifications: {
          active: false,
          labels: undefined,
        },
      },
    }
  }

  if (!tenant.catalog.classifications.labels?.length) {
    return addTenantClassification(tenant)
  }

  return tenant
}

export function addTenantClassification(tenant: Tenant): Tenant {
  if (!tenant.catalog?.classifications?.active) {
    return tenant
  }

  const label = { default: '' }

  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      classifications: {
        ...tenant.catalog.classifications,
        labels: [...(tenant.catalog?.classifications?.labels || []), label],
      },
    },
  }
}

export function removeTenantClassification(
  tenant: Tenant,
  index: number
): Tenant {
  if (!tenant.catalog?.classifications) {
    return tenant
  }

  const labels = tenant.catalog.classifications.labels
    ? tenant.catalog.classifications.labels.filter((l, i) => i !== index)
    : []
  const active = labels.length > 0

  return {
    ...tenant,
    catalog: {
      ...tenant.catalog,
      classifications: {
        active,
        labels,
      },
    },
  }
}
