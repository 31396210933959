import {
  InvoiceCashOnDelivery,
  InvoiceGift,
  InvoiceHeader,
  InvoiceRow,
  Prices,
  calculatePrices,
  dinero,
} from '@evologi/shared/data-access-api'

/**
 * Update the invoice header amounts
 * @param header - the invoice header
 * @param rows - the invoice rows
 * @param priceTaxes - the price taxes flag
 */
export function updateInvoiceHeaderAmounts(
  header: InvoiceHeader,
  rows: InvoiceRow[],
  priceTaxes = false
): InvoiceHeader {
  // Shipping prices
  const {
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
  } = updateShippingAmounts(header, priceTaxes)

  // CashOnDelivery prices
  const cashOnDelivery = updateCodAmounts(header, priceTaxes)

  // Discount prices
  const discountPrices = updateDiscountAmounts(header, priceTaxes)

  // Gift
  const gift = updateGiftAmounts(header, priceTaxes)

  // Footer amounts
  const subTotal = rows
    .reduce((acc, row) => acc.add(dinero(row.totalAmount)), dinero(0))
    .subtract(discountPrices.price)

  const subTotalWithTaxes = rows
    .reduce((acc, row) => acc.add(dinero(row.totalAmountWithTaxes)), dinero(0))
    .subtract(discountPrices.total)

  const bodyTaxes = rows
    .reduce((acc, row) => acc.add(dinero(row.totalTaxes)), dinero(0))
    .subtract(discountPrices.taxes)

  const totalInvoiceAmount = subTotalWithTaxes
    .add(dinero(shippingCostWithTaxes))
    .add(dinero(gift.costWithTaxes))
    .add(dinero(cashOnDelivery.feeWithTaxes))

  return {
    ...header,
    subTotal: subTotal.toRoundedUnit(4),
    subTotalWithTaxes: subTotalWithTaxes.toRoundedUnit(4),
    bodyTaxes: bodyTaxes.toRoundedUnit(4),
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
    cashOnDelivery,
    gift,
    discount: {
      discountTaxRate: discountPrices.taxRate,
      discountWithoutTaxes: discountPrices.price.toRoundedUnit(4),
      discountWithTaxes: discountPrices.total.toRoundedUnit(4),
    },
    totalInvoiceAmount: totalInvoiceAmount.toRoundedUnit(4),
  }
}

/**
 * Update shipping amounts
 * @param header - the invoice header
 * @param priceTaxes - the price taxes flag
 * @returns the shipping amounts
 */
function updateShippingAmounts(header: InvoiceHeader, priceTaxes = false) {
  const shippingPrices = calculatePrices({
    taxRate: header.shippingTaxRate,
    price: priceTaxes ? undefined : header.shippingCost,
    total: priceTaxes ? header.shippingCostWithTaxes : undefined,
  })

  return {
    shippingTaxRate: shippingPrices.taxRate,
    shippingCost: shippingPrices.price.toUnit(),
    shippingCostWithTaxes: shippingPrices.total.toUnit(),
    shippingTaxes: shippingPrices.taxes.toUnit(),
  }
}

/**
 * Update the cash-on-delivery amounts
 * @param header - the order header
 * @param priceTaxes - the price taxes flag
 * @returns the cod amounts updated
 */
function updateCodAmounts(
  header: InvoiceHeader,
  priceTaxes = false
): InvoiceCashOnDelivery {
  const codPrices = calculatePrices({
    taxRate: header.cashOnDelivery?.taxRate,
    price: priceTaxes ? undefined : header.cashOnDelivery?.fee,
    total: priceTaxes ? header.cashOnDelivery?.feeWithTaxes : undefined,
  })

  return {
    fee: codPrices.price.toUnit(),
    feeWithTaxes: codPrices.total.toUnit(),
    taxes: codPrices.taxes.toUnit(),
    taxRate: codPrices.taxRate,
    amount: header.cashOnDelivery?.amount || 0,
  }
}

/**
 * Update invoice discount amounts
 * @param header - the order header
 * @param subTotalWithTaxes - the subtotal of the order
 * @param priceTaxes - the price taxes flag
 * @returns the discount amounts
 */
function updateDiscountAmounts(
  header: InvoiceHeader,
  priceTaxes = false
): Prices {
  return calculatePrices({
    taxRate: header.discount?.discountTaxRate,
    price: priceTaxes ? undefined : header.discount?.discountWithoutTaxes,
    total: priceTaxes ? header.discount?.discountWithTaxes : undefined,
  })
}

/**
 * Update the gift amounts
 * @param header - the invoice header
 * @param priceTaxes - the price taxes flag
 * @returns the gift amounts updated
 */
function updateGiftAmounts(
  header: InvoiceHeader,
  priceTaxes = false
): InvoiceGift {
  const giftPrices = calculatePrices({
    taxRate: header.gift?.taxRate,
    price: priceTaxes ? undefined : header.gift?.cost,
    total: priceTaxes ? header.gift?.costWithTaxes : undefined,
  })

  return {
    taxRate: giftPrices.taxRate,
    cost: giftPrices.price.toUnit(),
    costWithTaxes: giftPrices.total.toUnit(),
  }
}
