import { isEmpty, isNil, omitBy, defaultsDeep, cloneDeep } from 'lodash'

import {
  Product,
  ProductSupplier,
  ProductSupplierPrice,
} from '../product.model'
import { PRODUCT_SUPPLIER_INITIAL_STATE } from '../product.const'

// Init

export function initProductSupplier(
  supplier: Partial<ProductSupplier> = {}
): ProductSupplier {
  return defaultsDeep(cloneDeep(supplier), PRODUCT_SUPPLIER_INITIAL_STATE)
}

// Prices

export function updateProductSupplierPrice(
  supplier: ProductSupplier
): ProductSupplier {
  let netPrice = +supplier.price.purchase

  // Parse all discounts
  netPrice = supplier.price.discounts.reduce(
    (price, discount) =>
      discount ? (price -= (price / 100) * discount) : price,
    netPrice
  )

  supplier.price.net = +netPrice.toFixed(2)

  if (supplier.price.promotion) {
    supplier.price.promotion.value = getSupplierDiscountedPrice(supplier)
  }

  return supplier
}

export function getSupplierDiscountedPrice(supplier: ProductSupplier): number {
  const discount =
    !supplier.price.net || !supplier.price.promotion?.percentage
      ? 0
      : supplier.price.net * (1 - supplier.price.promotion.percentage / 100)
  return +discount.toFixed(2)
}

// Check

export function checkSupplier(supplier: ProductSupplier): ProductSupplier {
  return {
    ...supplier,
    price: checkSupplierPrice(supplier.price),
  }
}

export function checkSupplierPrice(
  price: ProductSupplierPrice
): ProductSupplierPrice {
  const promotion =
    price && price.promotion ? omitBy(price.promotion, isNil) : {}

  return {
    ...price,
    promotion: !isEmpty(promotion) ? promotion : undefined,
  }
}

// Useful

export function getProductSupplierIds(product: Product): string[] {
  if (!product.suppliers?.length) {
    return []
  }

  return product.suppliers
    .filter((s) => !!s.supplierId)
    .map((s) => s.supplierId!)
}

export function getProductSupplierSKU(
  product: Product,
  supplierId?: string
): string | undefined {
  const supplier = product.suppliers?.find(
    (sup) => sup.supplierId === supplierId
  )
  return supplier ? supplier.SKU : undefined
}
