<div id="dialog">
  <!-- Data -->
  <div class="data">
    <!-- Icon -->
    <ng-container *ngIf="'icon text-' + color as iconClass">
      <ng-container [ngSwitch]="icon">
        <i
          *ngSwitchCase="'info'"
          class="fad fa-info-circle {{ iconClass }}"
        ></i>
        <i
          *ngSwitchCase="'delete'"
          class="fad fa-trash-alt {{ iconClass }}"
        ></i>
        <i
          *ngSwitchCase="'warning'"
          class="fad fa-exclamation-triangle {{ iconClass }}"
        ></i>
      </ng-container>
    </ng-container>

    <!-- Title -->
    <h3 *ngIf="title" class="title">{{ title | translate: titleParams }}</h3>
    <p
      *ngIf="message"
      class="text"
      [innerHtml]="message | translate: messageParams"
    ></p>
  </div>

  <!-- Actions -->
  <div class="actions">
    <op-button
      [size]="size"
      [color]="color"
      fill="outline"
      (buttonClick)="hide('CANCEL')"
    >
      {{ 'Cancel' | translate }}
    </op-button>
    <op-button
      [size]="size"
      [color]="color"
      fill="solid"
      (buttonClick)="hide('CONFIRM')"
    >
      {{ 'Confirm' | translate }}
      <small class="text-uppercase">[{{ 'Submit' | translate }}]</small>
    </op-button>
  </div>
</div>
