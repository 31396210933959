import { Data, Route } from '@angular/router'
import { PermissionsScope } from '@evologi/shared/data-access-api'

export interface DashboardRoute extends Route {
  data?: DashboardRouteData
}

export interface DashboardRouteData extends Data {
  breadcrumbs?: {
    url?: string
    label: string
  }[]
  storeRoute?: boolean
  scope?: PermissionsScope
}

export enum RoutePath {
  about = 'about',
  activities = 'activities',
  attributeGroups = 'attribute-group',
  attributes = 'attributes',
  automations = 'automations',
  backlog = 'backlog',
  banners = 'banners',
  bordereau = 'bordereau',
  brands = 'brands',
  carriers = 'carriers',
  catalogs = 'catalogs',
  categories = 'categories',
  channels = 'channels',
  classifications = 'classifications',
  customers = 'customers',
  executions = 'executions',
  goodsReceive = 'goods-receive',
  goodsReturn = 'goods-return',
  home = 'home',
  invoices = 'invoices',
  manufacturers = 'manufacturers',
  mobile = 'mobile',
  movements = 'movements',
  orders = 'orders',
  packing = 'packing',
  packingLists = 'packing-lists',
  payments = 'payments',
  pickings = 'pickings',
  pickupPoints = 'pickup-points',
  printers = 'printers',
  productFamilies = 'product-families',
  products = 'products',
  reasons = 'reasons',
  replenishments = 'replenishments',
  roles = 'roles',
  sales = 'sales',
  settings = 'settings',
  stocks = 'stocks',
  stockCounts = 'stock-counts',
  supplierOrders = 'supplier-orders',
  suppliers = 'suppliers',
  tags = 'tags',
  tasks = 'tasks',
  users = 'users',
  usersProfile = 'users/me',
  views = 'views',
  warehouses = 'warehouses',
  webhooks = 'webhooks',
}

export interface RouteBreadCrumb {
  url?: string
  label: string
}
