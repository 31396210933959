import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import { Observable, switchMap, tap } from 'rxjs'

import { Stats, StatsParams } from '../stats.model'
import { StatsService } from '../stats.service'
import { isEmpty, omitBy } from 'lodash'

interface StatsState {
  params?: StatsParams
  stats?: Partial<Stats>
  isLoading: boolean
  error?: HttpErrorResponse
}

const STATS_INITIAL_STATE: StatsState = {
  isLoading: false,
}

@Injectable()
export class StatsUseCase extends ComponentStore<StatsState> {
  constructor(private statsService: StatsService) {
    super(STATS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectStats$ = this.select((state) => state.stats)

  readonly selectIsLoading$ = this.select((state) => state.isLoading)

  /**
   * EFFECTS
   */

  readonly search$ = this.effect((params$: Observable<StatsParams>) => {
    return params$.pipe(
      tap((params) => this.setParams(params)),
      switchMap((params) =>
        this.statsService.stats$(params).pipe(
          tapResponse(
            (stats) => this.setStats(stats),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): StatsState => ({
      ...state,
      isLoading,
      error: undefined,
    }),
  )

  readonly setParams = this.updater(
    (state, params: StatsParams): StatsState => {
      return {
        ...state,
        params,
        isLoading: true,
        error: undefined,
      }
    },
  )

  readonly setStats = this.updater((state, stats: Stats): StatsState => {
    return {
      ...state,
      stats: omitBy<Stats>(stats, isEmpty),
      isLoading: false,
      error: undefined,
    }
  })

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): StatsState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )
}
