import { Pipe, PipeTransform } from '@angular/core'
import { Carrier } from '../carrier.model'
import { isGenericCarrier } from '../carrier.lib'

@Pipe({
  name: 'carrierGeneric',
})
export class CarrierGenericPipe implements PipeTransform {
  transform(carrier: Carrier): boolean {
    return isGenericCarrier(carrier)
  }
}
