import { ProductScope } from '@evologi/shared/data-access-api'
import { createAction, props } from '@ngrx/store'

export enum ProductsAction {
  setScope = '[PRODUCTS] set scope',
  setProductsToModify = '[PRODUCTS] set products to modify',
  unsetProductsToModify = '[PRODUCTS] unset products to modify',
  processProduct = '[PRODUCTS] set as PROCESSING the product to modify',
  modifyProduct = '[PRODUCTS] set as MODIFIED the product to modify',
  destroy = '[PRODUCTS] destroy',
}

export const setProductsScope = createAction(
  ProductsAction.setScope,
  props<{ scope: ProductScope }>(),
)

export const setProductsToModify = createAction(
  ProductsAction.setProductsToModify,
  props<{ ids: string[] }>(),
)

export const unsetProductsToModify = createAction(
  ProductsAction.unsetProductsToModify,
)

export const processProductToModify = createAction(
  ProductsAction.processProduct,
  props<{ id: string }>(),
)

export const modifyProductToModify = createAction(
  ProductsAction.modifyProduct,
  props<{ id: string }>(),
)

export const destroyProducts = createAction(ProductsAction.destroy)
