import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import { EMPTY, filter, map, Observable, switchMap, take, tap } from 'rxjs'

import { Customer } from '../customer.model'
import { CustomersService } from '../customers.service'
import { initCustomer } from '../libs/customer.lib'
import { CustomersRepository } from '../customers.repository'
import { CustomerNotification } from '../libs/customer-notification.lib'

export interface CustomerDetailState {
  customerId?: string
  customer?: Customer
  error?: HttpErrorResponse
  notification?: CustomerNotification
  toClose: boolean
  isLoading: boolean
  isModified: boolean
}

const CUSTOMER_DETAIL_INITIAL_STATE: CustomerDetailState = {
  toClose: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class CustomerDetailUseCase extends ComponentStore<CustomerDetailState> {
  constructor(
    private customersService: CustomersService,
    private customersRepository: CustomersRepository,
  ) {
    super(CUSTOMER_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<CustomerDetailState> = this.select(
    (state) => state,
  )

  readonly selectCreatedBy$: Observable<string | undefined> = this.select(
    (state) => state.customer?.createdBy,
  )

  readonly selectUpdatedBy$: Observable<string | undefined> = this.select(
    (state) => state.customer?.updatedBy,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectCustomerId$: Observable<string | undefined> = this.select(
    (state) => state.customer?._id,
  )

  readonly selectCustomer$: Observable<Customer | undefined> = this.select(
    (state) => state.customer,
  )

  readonly selectCustomerLoaded$: Observable<Customer | undefined> =
    this.select((state) => state).pipe(
      filter((state) => !state.isLoading),
      map((state) => state.customer),
    )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectNotification$: Observable<CustomerNotification | undefined> =
    this.select((state) => state.notification)

  // Effects

  readonly init$ = this.effect(
    (customer$: Observable<Partial<Customer> | undefined>) => {
      return customer$.pipe(
        map((customer) => initCustomer(customer)),
        tap((customer) => this.setCustomer(customer)),
      )
    },
  )

  readonly load$ = this.effect((customerId$: Observable<string>) => {
    return customerId$.pipe(
      tap((customerId) => this.setCustomerId(customerId)),
      switchMap((customerId) =>
        this.customersService.read$(customerId).pipe(
          tapResponse(
            (customerData) => this.setCustomer(customerData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      switchMap((toClose) =>
        this.selectCustomer$.pipe(
          take(1),
          switchMap((customer) => {
            if (!customer) {
              return EMPTY
            }
            return this.customersService.upsert$(customer).pipe(
              tapResponse(
                (customer) => this.setCustomerSaved({ customer, toClose }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
          }),
        ),
      ),
    )
  })

  readonly delete$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      switchMap(() =>
        this.selectCustomer$.pipe(
          switchMap((customer) => {
            if (!customer) {
              return EMPTY
            }

            return this.customersRepository
              .alert$(
                CustomerNotification.from({
                  code: 'CUSTOMER_DELETION',
                  data: { customer },
                }),
              )
              .pipe(
                take(1),
                switchMap((confirmation) => {
                  if (!confirmation || !customer) {
                    return EMPTY
                  }

                  this.setIsLoading(true)
                  return this.customersService.delete$(customer._id).pipe(
                    tapResponse(
                      () => this.setCustomerDeleted(),
                      (error: HttpErrorResponse) => this.setError(error),
                    ),
                  )
                }),
              )
          }),
        ),
      ),
    )
  })

  // Reducers

  readonly setCustomerId = this.updater(
    (state, customerId: string): CustomerDetailState => {
      return {
        customerId,
        toClose: false,
        isLoading: true,
        isModified: false,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): CustomerDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setCustomer = this.updater(
    (state, customer: Customer): CustomerDetailState => ({
      ...state,
      customer,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setCustomerSaved = this.updater(
    (
      state,
      params: { customer: Customer; toClose?: boolean },
    ): CustomerDetailState => {
      return {
        ...state,
        customer: params.customer,
        toClose: params.toClose || false,
        notification: CustomerNotification.from({ code: 'CUSTOMER_SAVED' }),
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setCustomerDeleted = this.updater((state): CustomerDetailState => {
    return {
      ...state,
      notification: CustomerNotification.from({ code: 'CUSTOMER_DELETED' }),
      toClose: true,
    }
  })

  readonly updateCustomer = this.updater(
    (state, customer: Customer): CustomerDetailState => {
      return {
        ...state,
        customer,
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): CustomerDetailState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setToClose = this.updater(
    (state, toClose: boolean): CustomerDetailState => {
      return {
        ...state,
        toClose,
      }
    },
  )
}
