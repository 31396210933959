import { Entity } from '../../models/entity.model'
import {
  FilterCollection,
  FilterOperator,
  FilterOption,
  FilterType,
} from '../../models/filter.model'
import { Attribute } from '../attributes'
import { QueryStringParams } from '../../models/query-string.model'

export enum ViewTarget {
  products = 'products',
  orders = 'orders',
  supplierOrders = 'supplierOrders',
  goodsReceives = 'goodsReceives',
  sales = 'sales',
  pickingLists = 'pickingLists',
  goodsReturns = 'goodsReturns',
}

export interface View extends Entity {
  name: string
  target: ViewTarget
  /**
   * Visible fields
   */
  fields?: string[]
  /**
   * Pre-compiled filters
   */
  filters?: ViewFilter[]
  /**
   * Useful UI params like catalogCode or locale
   */
  params?: ViewParam[]
  /**
   * Default ordering
   */
  ordering?: ViewOrdering[]
  /**
   * @deprecated
   */
  defaultFor: string[]
  /**
   * View shared with other users
   */
  isShared: boolean
}

export interface ViewFilter {
  property: string
  operator: FilterOperator
  value?: string | number
  additionalValue?: string | number
}

export interface ViewParam {
  property: string
  value: string | number
}

export interface ViewOrdering {
  property: string
  sort: 'ASC' | 'DESC'
}

export interface ViewTargetParam {
  name: string
  param: string
  paramType?: FilterType
  paramOptions?: FilterOption[]
  paramCollection?: FilterCollection
}

export enum ViewExternalKey {
  brandId = 'brandId',
  manufacturerId = 'manufacturerId',
  parentId = 'parentId',
  kitIds = 'kitIds',
  'externalSKUs.channelId' = 'externalSKUs.channelId',
  categories = 'categories',
  'suppliers.supplierId' = 'suppliers.supplierId',
  catalogCode = 'catalogCode',
  supplierId = 'supplierId',
  warehouseId = 'warehouseId',
  assignedWarehouseId = 'assignedWarehouseId',
  'header.channel' = 'header.channel',
  carrierId = 'carrierId',
  'header.paymentType' = 'header.paymentType',
}

export interface ViewData {
  view: View
  attributes?: Attribute[]
  externalData?: ViewExternalData[]
}

export interface ViewExternalData<T = any> {
  name: string // Brand name
  value: string | number // _id brand
  property: ViewExternalKey
  entity: T
}

export interface ViewSearchParams extends QueryStringParams {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  userId?: string | string[]
  target?: ViewTarget | ViewTarget[]
  isShared?: boolean
}

export interface ViewNotification {
  kind: ViewNotificationCode
  data?: { view?: View }
}

export type ViewNotificationCode =
  | 'VIEW_INCOMPLETE'
  | 'VIEW_SAVED'
  | 'VIEW_DELETED'

export type ViewField =
  | '_id'
  | 'name'
  | 'surname'
  | 'userId'
  | 'target'
  | 'isShared'
