import { Component, OnInit } from '@angular/core'
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms'
import { Subject } from 'rxjs'
import { AuthStoreService } from '@evologi/shared/auth-store'

@Component({
  selector: 'op-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit {
  public recoveryForm?: UntypedFormGroup
  public capsOn = false

  // Observables
  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private authStoreService: AuthStoreService,
    private builder: UntypedFormBuilder,
  ) {}

  // Lifecycle methods

  ngOnInit() {
    // Init form
    this.recoveryForm = this.builder.group({
      username: ['', Validators.compose([Validators.required])],
    })
  }

  // Recovery methods

  recovery() {
    const { username } = this.recoveryForm?.value || {}
    this.authStoreService.recoveryPassword(username)
  }
}
