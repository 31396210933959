<!-- Header -->
<div
  *ngIf="header"
  class="modal-header"
  [ngClass]="{ tabs: tabs, custom: headerCustom }"
>
  <!-- Custom -->
  <ng-container *ngIf="headerCustom; else headerDefault">
    <ng-content select="modal-header"></ng-content>
  </ng-container>

  <!-- Default -->
  <ng-template #headerDefault>
    <div class="pull-left">
      <h4 class="modal-title">{{ title }}</h4>

      <p *ngIf="info" class="modal-subtitle">
        <ng-container *ngFor="let i of info">
          <span *ngIf="i" class="info" [title]="i.explain || ''">
            <i *ngIf="i.icon" class="{{ i.icon }} icon"></i>
            <strong *ngIf="i.label" class="label">
              {{ i.label | translate }}:
            </strong>
            <ng-container *ngIf="i.text">{{ i.text }}</ng-container>
            <ng-container *ngIf="i.date">{{
              i.date | date: 'medium'
            }}</ng-container>
          </span>
        </ng-container>
      </p>
    </div>
  </ng-template>

  <!-- Status -->
  <div class="modal-status">
    <ng-content select="modal-status"></ng-content>
  </div>

  <!-- Info -->
  <div class="modal-info">
    <ng-content select="modal-info"></ng-content>

    <span *ngIf="modified" class="badge badge-primary">MODIFICATO</span>
  </div>

  <!-- Close modal -->
  <button
    type="button"
    class="close pull-right"
    (click)="closeModal()"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- Loader -->
<ngx-loading-bar
  ref="modal"
  [includeSpinner]="false"
  [fixed]="false"
></ngx-loading-bar>

<!-- Shoulder management -->
<ng-container *ngIf="shoulder; else noShoulder">
  <div class="d-flex">
    <div
      *ngIf="shoulderLeft"
      class="modal-shoulder left border-right"
      [class.full]="shoulderFull"
    >
      <ng-content select="modal-shoulder-left"></ng-content>
    </div>
    <div class="modal-body-wrapper">
      <ng-container *ngTemplateOutlet="modalBody"></ng-container>

      <ng-container *ngIf="footer && shoulderFull">
        <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
      </ng-container>
    </div>
    <div
      *ngIf="shoulderRight"
      class="modal-shoulder right border-left"
      [class.full]="shoulderFull"
    >
      <ng-content select="modal-shoulder-right"></ng-content>
    </div>
  </div>
  <ng-container *ngIf="footer && !shoulderFull">
    <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
  </ng-container>
</ng-container>
<ng-template #noShoulder>
  <ng-container *ngTemplateOutlet="modalBody"></ng-container>

  <ng-container *ngIf="footer">
    <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
  </ng-container>
</ng-template>

<!-- Modal body -->
<ng-template #modalBody>
  <!-- Alert -->
  <div *ngIf="alert && alert.visible" class="modal-alert {{ alert.type }}">
    {{ alert.text }}
  </div>

  <!-- Body -->
  <div class="modal-body" [ngClass]="{ tabs: tabs, 'p-0': !padding }">
    <ng-content select="modal-content"></ng-content>
  </div>
</ng-template>

<!-- Modal footer -->
<ng-template #modalFooter>
  <div class="modal-footer justify-content-between">
    <div>
      <ng-content select="modal-actions-left"></ng-content>
      <op-button
        fill="outline"
        [color]="showCloseButton ? 'primary' : undefined"
        (click)="closeModal()"
      >
        {{ showCloseButton ? 'Chiudi' : (cancelLabel ?? 'Annulla') }}
      </op-button>
    </div>
    <div>
      <ng-content select="modal-actions-center"></ng-content>
    </div>
    <div>
      <ng-content select="modal-actions-right"></ng-content>
    </div>
  </div>
</ng-template>
