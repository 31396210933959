import { Pipe, PipeTransform } from '@angular/core'

import { ProductCatalogAttribute, ProductScope } from '../../products'
import { Attribute } from '../attribute.model'
import { CATALOG_DEFAULT_CODE } from '../../catalogs'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'

// SDK

@Pipe({
  name: 'attributeValue',
})
export class AttributeValuePipe implements PipeTransform {
  transform(
    productAttributes: ProductCatalogAttribute[],
    attribute: Attribute,
    scope: ProductScope,
  ): any {
    const attributeScope: ProductScope = {
      catalogCode: attribute.valuePerCatalog
        ? scope.catalogCode
        : CATALOG_DEFAULT_CODE,
      locale: attribute.isLocalizable ? scope.locale : LOCALE_DEFAULT_CODE,
    }
    const productAttribute = productAttributes
      ? productAttributes.find(
          (attr) =>
            attr.attributeCode === attribute.code &&
            attr.catalogCode === attributeScope.catalogCode,
        )
      : undefined

    return productAttribute
      ? productAttribute.locales[attributeScope.locale]
      : undefined
  }
}
