import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import {
  Address,
  BillingAddress,
  ShippingAddress,
} from '../../../models/address.model'
import { Carrier } from '../carrier.model'
import { isCarrierAddressValid } from '../carrier.lib'

interface WarningInput {
  address?: ShippingAddress | BillingAddress | Address
  carrier?: Carrier
}

@Directive({
  selector: '[opCarrierAddressWarning]',
})
export class CarrierAddressWarningDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private changes: ChangeDetectorRef,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set opCarrierAddressWarning(check: WarningInput) {
    if (!check.address || !check.address.address1 || !check.carrier) {
      this.toggleElement(false)
      return
    }

    const isValid = isCarrierAddressValid(check.carrier, check.address)
    this.toggleElement(!isValid)
  }

  toggleElement(canBeShown: boolean) {
    this.viewContainer.clear()

    if (canBeShown) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }

    this.changes.markForCheck()
  }
}
