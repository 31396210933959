import { createAction, props } from '@ngrx/store'

export enum CacheAction {
  setItems = '[CACHE] set items',
  setItem = '[CACHE] set item',
  unsetItem = '[CACHE] unset item',
  destroy = '[CACHE] destroy',
}

export const setItems = createAction(
  CacheAction.setItems,
  props<{
    tenantId: string
    cacheKey: string
    items: any[]
    itemFieldKey?: string
    totalCount?: number
  }>(),
)

export const setItem = createAction(
  CacheAction.setItem,
  props<{ tenantId: string; cacheKey: string; itemKey: string; item: any }>(),
)

export const unsetItem = createAction(
  CacheAction.unsetItem,
  props<{ tenantId: string; cacheKey: string; itemKey: string }>(),
)

export const destroyCache = createAction(CacheAction.destroy)
