import { cloneDeep, defaultsDeep } from 'lodash'
import {
  BillingAddress,
  ShippingAddress,
  Address,
} from '../models/address.model'

// Initial states

const ADDRESS_INITIAL_STATE: Partial<Address> = {
  address1: undefined,
  city: undefined,
  province: undefined,
  postalCode: undefined,
  countryCode: undefined,
}

const SHIPPING_ADDRESS_INITIAL_STATE: Partial<ShippingAddress> = {
  _id: undefined,
  name: undefined,
  businessName: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  province: undefined,
  postalCode: undefined,
  countryCode: undefined,
  phone: undefined,
  email: undefined,
  addressType: undefined,
}

const BILLING_ADDRESS_INITIAL_STATE: Partial<BillingAddress> = {
  _id: undefined,
  name: undefined,
  businessName: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  province: undefined,
  postalCode: undefined,
  countryCode: undefined,
  phone: undefined,
  email: undefined,
  fiscalCode: undefined,
  vat: undefined,
}

// Reducers

export function initAddress(address: Partial<Address> = {}): Address {
  return defaultsDeep(cloneDeep(address), ADDRESS_INITIAL_STATE)
}

export function initShippingAddress(
  address: Partial<ShippingAddress> = {}
): ShippingAddress {
  return defaultsDeep(cloneDeep(address), SHIPPING_ADDRESS_INITIAL_STATE)
}

export function initBillingAddress(
  address: Partial<BillingAddress> = {}
): BillingAddress {
  return defaultsDeep(cloneDeep(address), BILLING_ADDRESS_INITIAL_STATE)
}
