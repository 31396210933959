import { Customer } from '../../customers'
import { Order } from '../order.model'

/**
 * Set order billing address from customer
 * @param order - the order
 * @param customer - the customer
 * @returns the order updated
 */
export function setOrderBillingAddressByCustomer(
  order: Order,
  customer: Customer
): Order {
  return {
    ...order,
    header: {
      ...order.header,
      billingAddress: {
        _id: customer._id,
        name: customer.name,
        businessName: customer.businessName,
        email: customer.email,
        phone: customer.phone,
        fiscalCode: customer.fiscalCode,
        vat: customer.vat,
        sdiCode: customer.sdiCode,
        pec: customer.pec,
        ...customer.billingAddress,
      },
    },
  }
}

/**
 * Set order shipping address from customer
 * @param order - the order
 * @param customer - the customer
 * @returns the order updated
 */
export function setOrderShippingAddressByCustomer(
  order: Order,
  customer: Customer
): Order {
  const address =
    customer.shippingAddress.length === 1 ||
    !customer.shippingAddress.find((a) => a.isDefault)
      ? customer.shippingAddress[0]
      : customer.shippingAddress.find((a) => a.isDefault)

  return {
    ...order,
    header: {
      ...order.header,
      shippingAddress: {
        _id: customer._id,
        addressType: order.header.shippingAddress?.addressType,
        ...address!,
      },
    },
  }
}
