import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  BrandNotificationCode,
  BrandNotificationData,
  BrandNotificationOptions,
} from '../brand.model'

export class BrandNotification implements Notification {
  public code: BrandNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: BrandNotificationOptions) {
    const { type, dialog } = parseBrandNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: BrandNotificationOptions): BrandNotification {
    if (opts instanceof BrandNotification) {
      return opts
    } else {
      return new BrandNotification(opts)
    }
  }
}

/**
 * Parse brand notification to a message
 * @param notification - the brand notification
 * @returns the notification data
 */
export function parseBrandNotification(
  code: BrandNotificationCode,
  data?: BrandNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'BRAND_SAVED':
      dialog = {
        message: 'Brand saved successfully',
        color: 'success',
      }
      break
    case 'BRAND_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'BRAND_DELETION': {
      const brandName = data?.brand?.name
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete brand',
        message:
          'You are about to delete the brand <strong>%s</strong>. Proceed with deletion?',
        messageParams: brandName ? [brandName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'BRAND_DELETED':
      dialog = {
        message: 'Brand deleted successfully',
        color: 'success',
      }
      break
  }

  return { dialog, type }
}
