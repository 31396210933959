import { cloneDeep, defaultsDeep } from 'lodash'
import {
  countInvoiceRowsProducts,
  createInvoiceRows,
  deleteInvoiceRow,
  updateInvoiceRowAmounts,
} from './invoice-rows.lib'
import { updateInvoiceHeaderAmounts } from './invoice-header.lib'
import { DeepPartial } from '../../../models/util.model'
import {
  Invoice,
  InvoiceRow,
  InvoiceSearchField,
  InvoiceSearchParams,
  InvoiceSortField,
  InvoiceType,
} from '../invoice.model'
import { ProductSelection } from '../../products'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'

/**
 * Invoice initial state
 */
const INVOICE_INITIAL_STATE: DeepPartial<Invoice> = {
  documentType: InvoiceType.invoice,
  productsCount: 0,
  rowsCount: 0,
  packagesCount: 0,
  header: {
    date: new Date(),
    rifDate: new Date(),
    currency: 'EUR',
    shippingTaxes: 0,
    bodyTaxes: 0,
    subTotal: 0,
    subTotalWithTaxes: 0,
    totalInvoiceAmount: 0,
  },
  rows: [],
}

/**
 * Init invoice data
 * @param invoice - the partial invoice to init
 * @returns the invoice initialized
 */
export function initInvoice(invoice: Partial<Invoice> = {}): Invoice {
  return defaultsDeep(cloneDeep(invoice), INVOICE_INITIAL_STATE)
}

/**
 * Set invoice defaults
 * @param invoice - the invoice
 * @param defaults - the defaults to set
 * @returns the invoice updated
 */
export function setInvoiceDefaults(
  invoice: Invoice,
  defaults: DeepPartial<Invoice>
): Invoice {
  return defaultsDeep(cloneDeep(invoice), defaults)
}

/**
 * Update invoice amounts
 * @param invoice - the invoice
 * @param priceWithTaxes - the price taxes flag
 * @returns the invoice updated
 */
export function updateInvoiceAmounts(
  invoice: Invoice,
  priceWithTaxes = false
): Invoice {
  const rows = invoice.rows.map((row) =>
    updateInvoiceRowAmounts(row, priceWithTaxes)
  )

  const header = updateInvoiceHeaderAmounts(
    invoice.header,
    rows,
    priceWithTaxes
  )

  return {
    ...invoice,
    header,
    rows,
    productsCount: countInvoiceRowsProducts(rows),
    rowsCount: rows.length,
  }
}

/**
 * Add products to an invoice
 * @param invoice - the invoice
 * @param selection - the products selection
 * @param priceTaxes - the price taxes flag
 * @returns the invoice updated
 */
export function addInvoiceProducts(
  invoice: Invoice,
  selection: ProductSelection[],
  priceTaxes = false
): Invoice {
  return updateInvoiceAmounts(
    {
      ...invoice,
      rows: [...invoice.rows, ...createInvoiceRows(selection)],
    },
    priceTaxes
  )
}

/**
 * Remove a row from an invoice
 * @param invoice - the invoice to update
 * @param row - the row to remove
 * @param priceTaxes - the price taxes flag
 * @returns the invoice updated
 */
export function removeInvoiceRow(
  invoice: Invoice,
  row: InvoiceRow,
  priceTaxes = false
): Invoice {
  return updateInvoiceAmounts(
    {
      ...invoice,
      rows: deleteInvoiceRow(invoice.rows, row),
    },
    priceTaxes
  )
}

/**
 * Parse invoice sort params
 * @param params - the search params
 * @param sort - the sort params
 * @returns the search params updated
 */
export function invoiceSortParams(
  params: InvoiceSearchParams,
  sort: QueryStringSort<InvoiceSortField>
): InvoiceSearchParams {
  const searchParams: InvoiceSearchParams = {}

  switch (sort.field) {
    case 'header.date':
      searchParams.sort = 'header.date'
      searchParams.order = sort.order
      break
    case 'header.invoiceNumber':
      searchParams.sort = 'header.invoiceNumber'
      searchParams.order = sort.order
      break
    case 'header.rifDate':
      searchParams.sort = 'header.rifDate'
      searchParams.order = sort.order
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse invoice filter params
 * @param params - the search params
 * @param filter - the filter params
 * @returns the search params updated
 */
export function invoiceFilterParams(
  params: InvoiceSearchParams,
  filter: QueryStringFilter<InvoiceSearchField>
): InvoiceSearchParams {
  const searchParams: InvoiceSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'documentType':
      if (filter.value !== null) {
        searchParams['documentType'] = filter.value
      }
      break
    /* case 'header.currency':
      if (filter.value !== null) {
        searchParams['header.currency'] = filter.value
      }
      break */
    case 'header.date':
      if (filter.operator === '=') {
        searchParams['header.date'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.date:ne'] = filter.value
      }
      break
    case 'header.externalrifInvoice':
      if (filter.operator === '=') {
        searchParams['header.externalrifInvoice'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['header.externalrifInvoice:ct'] = filter.value
      }
      break
    case 'header.invoiceNumber':
      if (filter.operator === '=') {
        searchParams['header.invoiceNumber'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.invoiceNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.invoiceNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.invoiceNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.invoiceNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.invoiceNumber:ne'] = filter.value
      }
      break
    case 'header.paymentType':
      if (filter.value !== null) {
        searchParams['header.paymentType'] = filter.value
      }
      break
    case 'header.rifDate':
      if (filter.operator === '=') {
        searchParams['header.rifDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.rifDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.rifDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.rifDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.rifDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifDate:ne'] = filter.value
      }
      break
    case 'header.rifInvoice':
      if (filter.operator === '=') {
        searchParams['header.rifInvoice'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
