import {
  Component,
  Input,
  Output,
  NgZone,
  Renderer2,
  EventEmitter,
  AfterViewInit,
} from '@angular/core'
import { setFocus } from '@evologi/shared/util-toolkit'

@Component({
  selector: 'op-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit {
  @Input() key = 'search-input'
  @Input() searchClass?: string
  @Input() inputClass?: string
  @Input() searchTerm?: string
  @Input() icon = 'fas fa-search'
  @Input() placeholder = 'Ricerca (min. 3 car.)'

  @Input() autofocus = false
  @Input() autocomplete = true
  @Input() loaded? = false
  @Input() disabled? = false

  // eslint-disable-next-line
  @Output('search') readonly searchEvent: EventEmitter<string> =
    new EventEmitter<string>()
  @Output() readonly searchTermChange: EventEmitter<string> =
    new EventEmitter<string>()

  constructor(private ngZone: NgZone, private renderer: Renderer2) {}

  // Lifecycle

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus()
    }
  }

  // Input utilities

  reset() {
    this.searchTerm = undefined
    this.searchEvent.emit(this.searchTerm)
  }

  search(e?: any) {
    const term = e ? e.target.value : this.searchTerm

    if (!term || term === '') {
      this.reset()
      return
    }

    if (term.length < 3) {
      return
    }

    this.searchEvent.emit(term)
  }

  focus() {
    setFocus(this.ngZone, this.renderer, `#${this.key}`, 500)
  }
}
