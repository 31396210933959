import { Pipe, PipeTransform } from '@angular/core'
import { Product, ProductLocation } from '../../products/product.model'

@Pipe({
  name: 'productQty',
})
export class ProductQtyPipe implements PipeTransform {
  transform(product: Product | undefined, locationId: string): number {
    if (!product) {
      return 0
    }
    const locations: ProductLocation[] = product?.locations.filter(
      (loc) => loc._id === locationId,
    )
    if (locations.length < 1) {
      return 0
    } else {
      return locations[0].onHandQty
    }
  }
}
