import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { paginateArray } from '@evologi/shared/util-toolkit'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Brand,
  BrandData,
  BrandExtData,
  BrandProductsCount,
  BrandSearchParams,
} from './brand.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'brands'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class BrandsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new brand
   * @param brand - The brand to create
   * @returns The observable<Brand> to create the brand
   */
  create$(brand: Brand): Observable<Brand> {
    return this._create$<Brand>(brand)
  }

  /**
   * Read a brand by ID
   * @param brandId - The brand ID
   * @returns The observable<Brand> for read the brand
   */
  read$(brandId: string): Observable<Brand> {
    return this._read$<Brand>(brandId)
  }

  /**
   * Update a brand by ID
   * @param brandId - The brand ID
   * @param brand - The brand body to update
   * @returns The observable<Brand> for update the brand
   */
  update$(brandId: string, brand: Brand): Observable<Brand> {
    return this._update$<Brand>(brandId, brand)
  }

  /**
   * Create or update a brand by ID
   * @param brandId - The brand ID
   * @param brand - The brand body to update
   * @returns The observable<Brand> for update the brand
   */
  upsert$(brand: Brand): Observable<Brand> {
    return this._upsert$<Brand>(brand, brand._id)
  }

  /**
   * Delete a brand by ID
   * @param brandId - The brand ID
   * @returns The observable<Brand> for delete the brand
   */
  delete$(brandId: string): Observable<Brand> {
    return this._delete$<Brand>(brandId)
  }

  /**
   * Search brands by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Brand>> for search brands
   */
  search$(
    params?: BrandSearchParams,
    returnAll = false,
  ): Observable<Page<Brand>> {
    return this._search$<Brand>(params, returnAll)
  }

  /**
   * List brands by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Brand[]> for list brands
   */
  list$(params?: BrandSearchParams, returnAll = false): Observable<Brand[]> {
    return this._list$<Brand>(params, returnAll)
  }

  /**
   * Count products of each brand
   * @param brandIds - The brand IDs to count
   * @returns The observable<{ _id: string, count: number }[]> for count product brands
   */
  countProducts$(brandIds: string[]): Observable<BrandProductsCount[]> {
    const observables$: Observable<{ _id: string; count: number }[]>[] = []

    for (const page of paginateArray(brandIds, this.limit)) {
      observables$.push(
        this.http.post<{ _id: string; count: number }[]>(
          `${this.apiUrl}/products/count`,
          {
            brandIds: brandIds.slice(page.start, page.end),
          },
        ),
      )
    }

    return combineLatest(observables$).pipe(
      map((response) => response.reduce((acc, b) => acc.concat(b), [])),
    )
  }

  /**
   * Generate brands store
   * @param brandIds - the brand IDs to load
   * @param brands - the brands already loaded
   * @returns the Observable<Brand[]> as store
   */
  store$(brandIds: string[], brands: Brand[]): Observable<Brand[]> {
    brandIds = uniq(brandIds)
    brandIds = difference(
      brandIds,
      brands.map((u) => u._id),
    )

    if (brandIds.length === 0) {
      return of(brands)
    }

    return this.list$({ _id: brandIds }).pipe(
      map((brnds) => [...brands, ...brnds]),
    )
  }

  /**
   * Search brands data by params
   * @param searchParams - the search params
   * @returns the observable for search brands and its data
   */
  searchData$(
    searchParams?: BrandSearchParams,
    returnAll?: boolean,
  ): Observable<{ page: Page<Brand>; productsCounts?: BrandProductsCount[] }> {
    return this.search$(searchParams, returnAll).pipe(
      switchMap((page) =>
        this._countProducts$(page).pipe(
          map((productsCounts) => ({ page, productsCounts })),
        ),
      ),
    )
  }

  /**
   * Load brand with external data
   * @param brandId - the brand ID
   * @returns the observable for load all brand data
   */
  loadBrandData$(brandId: string): Observable<BrandData> {
    return this.read$(brandId).pipe(
      switchMap((brand) =>
        this.loadData$(brand).pipe(
          map((brandData) => ({
            brand,
            ...brandData,
          })),
        ),
      ),
    )
  }

  /**
   * Load brand external data
   * @param brand - the brand
   * @returns the observable for load the brand external data
   */
  loadData$(brand: Brand): Observable<BrandExtData> {
    return combineLatest({
      productsCount: this.countProducts$([brand._id]).pipe(
        map((c) => c[0].count),
      ),
    })
  }

  /**
   * Count products by brands list or page
   * @param brands - the brands page/list
   * @returns the products counts
   */
  private _countProducts$(
    brands: Brand[] | Page<Brand>,
  ): Observable<BrandProductsCount[] | undefined> {
    const items = Array.isArray(brands) ? brands : brands.data

    if (!items.length) {
      return of(undefined)
    }

    return this.countProducts$(items.map((b) => b._id))
  }
}
