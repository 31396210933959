import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  PackingListAuthData,
  PackingListStationData,
} from '../packing-list.model'
import { HttpErrorResponse } from '@angular/common/http'
import { PackingListsRepository } from '../packing-lists.repository'
import { Observable, map, switchMap, tap } from 'rxjs'
import { Printers } from '../../print-node'

interface PackingListsStationState extends Partial<PackingListStationData> {
  error?: HttpErrorResponse
  isLoading: boolean
  isInitialized: boolean
}

const PACKING_STATION_INITIAL_STATE: PackingListsStationState = {
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class PackingListsStationUseCase extends ComponentStore<PackingListsStationState> {
  constructor(private packingListsRepository: PackingListsRepository) {
    super(PACKING_STATION_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<PackingListsStationState> = this.select(
    (state) => state
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error
  )

  readonly selectWarehouseId$: Observable<string | undefined> =
    this.selectState$.pipe(map((state) => state?.warehouseId))

  readonly selectStationData$: Observable<PackingListStationData | undefined> =
    this.selectState$.pipe(
      map((state) => {
        const { error, isLoading, isInitialized, ...stationData } = state
        if (!stationData.warehouseId || !stationData.printingType) {
          return undefined
        }

        return stationData as PackingListStationData
      })
    )

  readonly selectPrinters$: Observable<Printers | undefined> =
    this.selectState$.pipe(
      map((stationData) =>
        stationData?.printerId && stationData.A4PrinterId
          ? {
              labels: stationData?.printerId,
              a4: stationData?.A4PrinterId,
            }
          : undefined
      )
    )

  // Effects

  readonly init$ = this.effect((authData$: Observable<PackingListAuthData>) => {
    return authData$.pipe(
      switchMap((authData) =>
        this.packingListsRepository.loadPackingStationData$(authData).pipe(
          tapResponse(
            (stationData) => this.setStationData(stationData),
            (error: HttpErrorResponse) => this.setError(error)
          )
        )
      )
    )
  })

  readonly setStationData$ = this.effect(
    (stationData$: Observable<PackingListStationData>) => {
      return stationData$.pipe(
        tap((stationData) =>
          this.packingListsRepository.savePackingStationData(stationData)
        ),
        tap((stationData) => this.setStationData(stationData))
      )
    }
  )

  // Reducers

  private readonly setStationData = this.updater(
    (state, stationData: PackingListStationData): PackingListsStationState => {
      return {
        ...state,
        ...stationData,
        isLoading: false,
        isInitialized: true,
      }
    }
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): PackingListsStationState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    }
  )
}
