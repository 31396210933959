import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Stock, StockSearchParams } from './stock.model'
import { Page } from '../../models/util.model'

const MODEL = 'stocks'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class StocksService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Search stocks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Stock>> for search stocks
   */
  search$(
    params?: StockSearchParams,
    returnAll = false,
  ): Observable<Page<Stock>> {
    return this._search$<Stock>(params, returnAll)
  }

  /**
   * List stocks by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Stock[]> for list stocks
   */
  list$(params?: StockSearchParams, returnAll = false): Observable<Stock[]> {
    return this._list$<Stock>(params, returnAll)
  }
}
