import { SelectionData } from '../../models/util.model'
import { TenantTab } from './tenant.model'

export const TENANT_STATUSES = [
  {
    code: 'ENABLED',
    name: 'Abilitato',
  },
  {
    code: 'DISABLED',
    name: 'Disabilitato',
  },
]

export const TENANT_DEFAULT_CURRENCIES = ['EUR', 'USD', 'GBP']

export const TENANT_LOT_FORMATS: SelectionData<string>[] = [
  {
    value: 'YYYYMMDD',
    label: 'YYYYMMDD (es. 20501020)',
  },
  {
    value: 'YYYYMM',
    label: 'YYYYMM (es. 205010)',
  },
  {
    value: 'YYYY-MM-DD',
    label: 'YYYY-MM-DD (es. 2050-10-20)',
  },
  {
    value: 'YYYY-MM',
    label: 'YYYY-MM (es. 2050-10)',
  },
]

export const TENANT_TABS: SelectionData<TenantTab>[] = [
  {
    value: TenantTab.general,
    label: 'Generale',
  },
  {
    value: TenantTab.catalog,
    label: 'Catalogo',
  },
  {
    value: TenantTab.sales,
    label: 'Vendite',
  },
  {
    value: TenantTab.purchases,
    label: 'Acquisti',
  },
  {
    value: TenantTab.logistics,
    label: 'Logistica',
  },
]
