import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  UserNotificationCode,
  UserNotificationData,
  UserNotificationOptions,
  UserType,
} from '../user.model'

export class UserNotification implements Notification {
  public code: UserNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  public data?: UserNotificationData
  constructor({ code, data }: UserNotificationOptions) {
    const { type, dialog } = parseUserNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
    this.data = data
  }

  static from(opts: UserNotificationOptions): UserNotification {
    if (opts instanceof UserNotification) {
      return opts
    } else {
      return new UserNotification(opts)
    }
  }
}

/**
 * Parse user notification to a message
 * @param notification - the user notification
 * @returns the notification data
 */
export function parseUserNotification(
  code: UserNotificationCode,
  data?: UserNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'USER_SAVED':
      dialog = {
        message: 'User saved successfully',
        color: 'success',
      }
      break
    case 'USER_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'USER_DELETION': {
      const isHuman = data?.user?.type === UserType.human
      const userName = isHuman
        ? `${data?.user?.name} ${data?.user?.surname}`
        : `${data?.user?.surname}`
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: isHuman ? 'Delete user' : 'Delete bot',
        message: isHuman
          ? 'You are about to delete the user <strong>%s</strong>. Proceed with deletion?'
          : 'You are about to delete the bot <strong>%s</strong>. Proceed with deletion?',
        messageParams: [userName],
        size: 'lg',
      }
      break
    }
    case 'USER_DELETED':
      dialog = {
        message: 'User deleted successfully',
        color: 'success',
      }
      break
    case 'USER_EJECTION': {
      const userName = `${data?.user?.name} ${data?.user?.surname}`
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Eject user',
        message:
          'You are about to eject the user <strong>%s</strong>. This user will no longer be able to access your tenant. Proceed with ejection?',
        messageParams: [userName],
        size: 'lg',
      }
      break
    }
    case 'USER_EJECTED':
      dialog = {
        message: 'User ejected successfully',
        color: 'success',
      }
      break
    case 'USER_INVITED':
      dialog = {
        message: 'User invited successfully',
        color: 'success',
      }
      break
    case 'USERS_ROLES_UPDATED':
      dialog = {
        message: 'Roles updated successfully',
        color: 'success',
      }
      break
    case 'TOKEN_CREATED': {
      type = 'alert'
      dialog = {
        icon: 'info',
        color: 'success',
        title: 'Token created',
        message:
          'API Token created successfully, confirm to copy it to memory.<br /><strong>It will no longer be visible afterwards</strong>.',
        size: 'lg',
      }
      break
    }
    case 'TOKEN_DELETED':
      dialog = {
        message: 'Token deleted successfully',
        color: 'success',
      }
      break
    case 'PASSWORD_UPDATED':
      dialog = {
        message: 'Password updated successfully',
        color: 'success',
      }
      break
  }

  return { dialog, type }
}
