import { cloneDeep, defaultsDeep, omit } from 'lodash'
import {
  HomeConfigurationSettings,
  User,
  UserTenantSettings,
} from '../user.model'
import { getUserTenantSettings, setUserTenantSettings } from './user.lib'
import {
  StatsGoodsReceivesStatus,
  StatsGoodsReturnsStatus,
  StatsLocationsStatus,
  StatsOrdersStatus,
  StatsParams,
  StatsPickingListsStatus,
  StatsProductsStatus,
  StatsReplenishmentsStatus,
} from '../../stats'

/**
 * Home page configuration management
 */
export const USER_HOME_INITIAL_STATE: HomeConfigurationSettings[] = [
  {
    module: 'GOODS_RECEIVES',
    statuses: ['CONFIRMED', 'PROCESSING'],
  },
  {
    module: 'PICKING_LISTS',
    statuses: ['PENDING', 'PROCESSING'],
  },
  {
    module: 'ORDERS',
    statuses: ['PENDING', 'PROCESSING'],
  },
  {
    module: 'PRODUCTS',
    statuses: ['TOTAL'],
  },
]

/**
 * Initialize user home configuration
 * @param user - the user
 * @returns the user initialized
 */
export function initUserHome(user: User): User {
  return {
    ...user,
    settings: {
      ...(user.settings || {}),
      homeConfiguration: defaultsDeep(
        user.settings?.homeConfiguration || [],
        USER_HOME_INITIAL_STATE,
      ),
    },
  }
}

/**
 * Add user home section
 * @param user - the user
 * @returns the user updated
 */
export function addUserHomeSection(user: User): User {
  return {
    ...user,
    settings: {
      ...(user.settings || {}),
      homeConfiguration: [
        ...(user.settings?.homeConfiguration || []),
        {
          module: 'ORDERS',
          statuses: [],
        },
      ],
    },
  }
}

/**
 * Remove user home section
 * @param user - the user
 * @param sectionIndex - the section index to remove
 * @returns the user updated
 */
export function removeUserHomeSection(user: User, sectionIndex: number): User {
  const homeConfiguration = user.settings?.homeConfiguration?.filter(
    (h, i) => i !== sectionIndex,
  )

  return {
    ...user,
    settings: {
      ...(user.settings || {}),
      homeConfiguration: homeConfiguration?.length
        ? homeConfiguration
        : undefined,
    },
  }
}

/**
 * Get user default view ID
 * @param userSettings - the current user settings
 * @param viewType - the view type
 * @returns the view ID
 */
export function getUserDefaultView(
  userSettings: UserTenantSettings,
  viewType: string,
): string | undefined {
  return userSettings.defaultViews
    ? userSettings.defaultViews[viewType]
    : undefined
}

/**
 * Set user default viewId
 * @param user - the user
 * @param tenantId - the tenant ID
 * @param viewType - the viewType
 * @param viewId - the view ID
 * @returns the user updated
 */
export function setUserDefaultView(
  user: User,
  tenantId: string,
  viewKey: string,
  viewId: string,
): User {
  const tenantSettings = getUserTenantSettings(user, tenantId) || {}
  const defaultViews = {
    ...(tenantSettings?.defaultViews || {}),
    [viewKey]: viewId,
  }
  return setUserTenantSettings(user, tenantId, {
    ...tenantSettings,
    defaultViews,
  })
}

/**
 * Unset user default viewType
 * @param user - the user
 * @param tenantId - the tenantId
 * @param viewKey - the view key
 * @returns the user updated
 */
export function unsetUserDefaultView(
  user: User,
  tenantId: string,
  viewKey: string,
): User {
  const tenantSettings = getUserTenantSettings(user, tenantId) || {}

  if (!tenantSettings.defaultViews) {
    return user
  }

  return setUserTenantSettings(user, tenantId, {
    ...tenantSettings,
    defaultViews: omit(tenantSettings.defaultViews || {}, [viewKey]),
  })
}

/**
 * Parse user settings to stats params
 * @param user - the current user
 * @returns the params for search stats
 */
export function parseUserToStatsParams(user: User): StatsParams {
  const homeConfiguration =
    user?.settings?.homeConfiguration || USER_HOME_INITIAL_STATE
  return homeConfiguration.reduce<StatsParams>(
    (acc, conf) => parseUserConfToStatsParams(acc, conf),
    {},
  )
}

/**
 * Parse user home configuration to stats params
 * @param conf - the home configuration
 * @returns the params for search stats
 */
export function parseUserConfToStatsParams(
  params: StatsParams,
  conf: HomeConfigurationSettings,
): StatsParams {
  switch (conf.module) {
    case 'GOODS_RECEIVES':
      params.goodsReceives = [
        ...(params.goodsReceives || []),
        ...(conf.statuses as StatsGoodsReceivesStatus[]),
      ]
      break
    case 'PICKING_LISTS':
      params.pickingLists = [
        ...(params.pickingLists || []),
        ...(conf.statuses as StatsPickingListsStatus[]),
      ]
      break
    case 'ORDERS':
      params.orders = [
        ...(params.orders || []),
        ...(conf.statuses as StatsOrdersStatus[]),
      ]
      break
    case 'PRODUCTS':
      params.products = [
        ...(params.products || []),
        ...(conf.statuses as StatsProductsStatus[]),
      ]
      break
    case 'REPLENISHMENTS':
      params.replenishments = [
        ...(params.replenishments || []),
        ...(conf.statuses as StatsReplenishmentsStatus[]),
      ]
      break
    case 'LOCATIONS':
      params.locations = [
        ...(params.locations || []),
        ...(conf.statuses as StatsLocationsStatus[]),
      ]
      break
  }

  return params
}
