import { cloneDeep, defaultsDeep, isEqual } from 'lodash'
import { Order, OrderCashOnDelivery, OrderPayment } from '../order.model'
import { dinero } from '../../../libs/dinero.lib'
import { Payment } from '../../payments'

// Initial states

const PAYMENT_INITIAL_STATE: OrderPayment = {
  date: new Date().toISOString(),
}

const COD_INITIAL_STATE: OrderCashOnDelivery = {
  fee: 0,
  feeWithTaxes: 0,
  taxRate: 0,
  taxes: 0,
  amount: 0,
}

export function initOrderPayment(
  payment: Partial<OrderPayment> = {}
): OrderPayment {
  return defaultsDeep(cloneDeep(payment), PAYMENT_INITIAL_STATE)
}

export function initOrderCashOnDelivery(
  cashOnDelivery: Partial<OrderCashOnDelivery> = {}
): OrderCashOnDelivery {
  return defaultsDeep(cloneDeep(cashOnDelivery), COD_INITIAL_STATE)
}

// Check COD amount

export function checkOrderCodAmount(order: Order, payment?: Payment): boolean {
  if (!payment?.cashOnDelivery) {
    return true
  }

  return dinero(order.header.totalOrderAmount, 2).equalsTo(
    dinero(order.header.cashOnDelivery?.amount || 0, 2)
  )
}

export function checkOrderCodPayment(
  order: Order,
  payment?: Payment
): OrderCashOnDelivery | undefined {
  return payment?.cashOnDelivery
    ? initOrderCashOnDelivery(order.header.cashOnDelivery)
    : undefined
}

// Payments management

export function updateOrderPayments(
  payments: OrderPayment[],
  payment: OrderPayment,
  paymentIndex?: number
): OrderPayment[] {
  return paymentIndex !== undefined
    ? payments.map((p, i) => (i === paymentIndex ? payment : p))
    : [...payments, payment]
}

export function removeOrderPayment(
  payments: OrderPayment[],
  payment: OrderPayment
): OrderPayment[] {
  return payments.filter((p) => !isEqual(p, payment))
}
