import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import {
  LocaleSearchField,
  LocaleSearchParams,
  LocaleSortField,
} from './locale.model'

// Search

export function localeSortParams(
  params: LocaleSearchParams,
  sort: QueryStringSort<LocaleSortField>
): LocaleSearchParams {
  const searchParams: LocaleSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'language':
      searchParams.sort = 'language'
      searchParams.order = sort.order
      break
    case 'tag':
      searchParams.sort = 'tag'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function localeFilterParams(
  params: LocaleSearchParams,
  filter: QueryStringFilter<LocaleSearchField>
): LocaleSearchParams {
  const searchParams: LocaleSearchParams = {}

  switch (filter.field) {
    case 'language':
      if (filter.operator === '=') {
        searchParams.language = filter.value
      } else if (filter.operator === '<>') {
        searchParams['language:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['language:ct'] = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'tag':
      if (filter.value !== null) {
        searchParams.tag = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
