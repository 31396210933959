import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BaseService } from '../../services/base.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'

const MODEL = 'classifications'
const VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class ClassificationsService extends BaseService {
  protected override model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(http, `${config.apiUrl}/${SDK_SETTINGS.apiPath}`, VERSION)
  }
}
