export type StoredenCurrency = 'EUR'
export type StoredenLocale = 'it_IT'

export interface StoredenStore {
  uid: number
  url: string
  store_name: string
  currency: StoredenCurrency
  locale: {
    main: StoredenLocale
    additional: StoredenLocale[]
  }
}

export interface StoredenPayment {
  id: string
  label: string
}

export enum StoredenProductField {
  title = 'title',
  description = 'description',
  tax_profile = 'tax_profile',
  price = 'price',
  final_price = 'final_price',
  package_width = 'package_width',
  package_height = 'package_height',
  package_length = 'package_length',
  weight = 'weight',
  shipping_cost = 'shipping_cost',
  dataEAN = 'data.ean13',
  dataMPN = 'data.mpn',
  dataunlimitedsell = 'data.unlimitedsell',
  dataminQuantity = 'data.minQuantity',
  datamaxQuantity = 'data.maxQuantity',
  datasteps = 'data.steps',
  seotitle = 'seo.title',
  seokeywords = 'seo.keywords',
  seodescription = 'seo.description',
  additional_tabs0 = 'additional_tabs.0',
  additional_tabs1 = 'additional_tabs.1',
  additional_tabs2 = 'additional_tabs.2',
  additional_tabs3 = 'additional_tabs.3',
  additional_tabs4 = 'additional_tabs.4',
  status = 'status',
}
