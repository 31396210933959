import { HttpHeaders } from '@angular/common/http'
import { get, has, isObject } from 'lodash'

/**
 * Parse entity headers that should be sent to backend
 * @param body - the entity body
 * @returns the http headers
 */
export function parseEntityHeaders<E>(body: E): HttpHeaders {
  // If-Match management
  let headers = new HttpHeaders()

  if (isObject(body) && has(body, '_rev')) {
    const _rev = get(body, '_rev')
    if (_rev) {
      headers = headers.append('If-Match', _rev as string)
    }
  }

  return headers
}
