import { Component } from '@angular/core'
import { AuthStoreService } from '@evologi/shared/auth-store'

@Component({
  selector: 'op-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.scss'],
})
export class TenantSelectionComponent {
  constructor(private authStoreService: AuthStoreService) {}

  // Observables
  public user$ = this.authStoreService.getUser$()
  public tenant$ = this.authStoreService.getTenant$()

  // Auth methods

  selectTenant(tenantId: string) {
    this.authStoreService.selectTenant(tenantId, true)
  }
}
