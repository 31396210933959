import { Injectable, Inject, LOCALE_ID } from '@angular/core'
import { vsprintf } from 'sprintf-js'
import { TRANSLATIONS } from './consts/translation'

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(
    @Inject(TRANSLATIONS) private translations: any,
    @Inject(LOCALE_ID) public currentLang: string
  ) {}

  public translate(key: string, params?: Array<string | number>): string {
    const langCode = this.currentLang.includes('-')
      ? this.currentLang.split('-')[0]
      : this.currentLang
    const label =
      this.translations[langCode] && this.translations[langCode][key]
        ? this.translations[langCode][key]
        : key
    return params ? vsprintf(label, params) : label
  }
}
