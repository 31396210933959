<div [class.input-group]="group">
  <!-- Prepend -->
  <div *ngIf="group && (preLabel || preContent)" class="input-group-prepend">
    <span *ngIf="preLabel" class="input-group-text">{{ preLabel }}</span>

    <ng-container *ngIf="preContent">
      <ng-content select="pre-content"></ng-content>
    </ng-container>
  </div>
  <!-- Input -->
  <input
    class="form-control {{ inputClasses }} {{ class }}"
    [type]="type"
    [id]="key"
    [placeholder]="placeholder || ''"
    [ngModel]="value"
    (ngModelChange)="emitValue($event)"
    (focusout)="focusOut.emit(value)"
    (keydown.enter)="valueSubmit.emit(value)"
    name="{{ key }}"
    #inputModel="ngModel"
    [ngClass]="{
      'is-valid': inputModel.valid && inputModel.touched && markIfValid,
      'is-invalid': inputModel.invalid && inputModel.touched
    }"
    [min]="min"
    [max]="max"
    [step]="step"
    [required]="required"
    [disabled]="disabled"
    [tkSlugify]="slugify !== undefined ? slugify : false"
    [tkFormatValidator]="formatValidator"
    [tkCoordinateValidator]="validateCoordinate"
    [opValidateUnique]="validateUnique"
    [validateField]="validateField"
    [validateElement]="validateElement"
    [tkValidateEqual]="validateEqual"
    [minlength]="minLength || ''"
    [maxlength]="maxLength || ''"
    [tkLowercase]="toLowercase"
  />

  <!-- Append -->
  <div *ngIf="group && (postLabel || postContent)" class="input-group-append">
    <span *ngIf="postLabel" class="input-group-text">{{ postLabel }}</span>

    <ng-container *ngIf="postContent">
      <ng-content select="post-content"></ng-content>
    </ng-container>
  </div>
</div>

<!-- Errors -->
<div
  *ngIf="inputModel.invalid && inputModel.touched && inputModel.errors"
  class="text-danger"
>
  <small>
    <ng-container *ngIf="inputModel.errors['required']">
      {{ requiredError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['invalidFormat']">
      {{ formatValidatorError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['notUnique']">
      {{ validateUniqueError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['minlength']">
      {{ minLengthError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['maxlength']">
      {{ maxLengthError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['invalidCoordinate']">
      {{ validateCoordinateError }}
    </ng-container>
    <ng-container *ngIf="inputModel.errors['isEqual']">
      {{ validateEqualError }}
    </ng-container>
  </small>
</div>

<!-- Buggy validators -->
<div
  *ngIf="
    type === 'number' &&
    min !== undefined &&
    minError !== undefined &&
    value < min
  "
  class="text-danger"
>
  <small>{{ minError }}</small>
</div>
<div
  *ngIf="
    type === 'number' &&
    max !== undefined &&
    maxError !== undefined &&
    value > max
  "
  class="text-danger"
>
  <small>{{ maxError }}</small>
</div>
