import { Pipe, PipeTransform } from '@angular/core'
import { CountriesService } from '../countries.service'
import { Observable, of } from 'rxjs'
import { Country } from '../country.model'
import { uniq } from 'lodash'

@Pipe({
  name: 'countries',
})
export class CountriesPipe implements PipeTransform {
  constructor(private countriesService: CountriesService) {}

  transform(
    countryKeys: string[],
    key: '_id' | 'alpha2Code' = '_id',
  ): Observable<Country[] | undefined> {
    countryKeys = uniq(countryKeys)
    countryKeys = countryKeys.filter((key) => !!key)
    if (!countryKeys.length) {
      return of(undefined)
    }

    return this.countriesService.list$({ [key]: countryKeys }, true)
  }
}
