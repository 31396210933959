import * as _ from 'lodash'
import { IColumnVisibility } from '../models'

export function parseVisibilityColumns(
  columns: IColumnVisibility[],
  defaults: { field: string; defaultVisibility?: boolean }[]
): Record<string, boolean> {
  const userColumns = _.chain(columns)
    .keyBy('dataField')
    .mapValues('visible')
    .value()
  const defaultColumns = _.chain(defaults)
    .keyBy('field')
    .mapValues((c) => c.defaultVisibility === true)
    .value()

  return { ...defaultColumns, ...userColumns }
}

export function unParseVisibilityColumns(columns: any) {
  return Object.keys(columns).map((key) => ({
    dataField: key,
    visible: columns[key],
  }))
}
