import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators'
import {
  CurrenciesService,
  TENANT_DEFAULT_CURRENCIES,
} from '@evologi/shared/data-access-api'

import * as AuthActions from '../auth/auth.actions'
import * as CurrenciesActions from './currencies.actions'

@Injectable({
  providedIn: 'root',
})
export class CurrenciesEffects {
  // Effects

  tenantSavedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSavedSuccess),
      switchMap((action) => {
        const tenant = action.tenant
        const params = {
          code:
            tenant?.catalog?.currencies && tenant?.catalog.currencies.length
              ? tenant.catalog.currencies
              : TENANT_DEFAULT_CURRENCIES,
        }

        return this.currenciesService.search$(params).pipe(
          map((currenciesData) =>
            CurrenciesActions.setCurrencies({
              tenantId: tenant._id,
              currencies: currenciesData.data,
            }),
          ),
        )
      }),
    )
  })

  destroyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOutSuccess),
      map(() => CurrenciesActions.destroyCurrencies()),
    )
  })

  // Crud effects

  getCurrencies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CurrenciesActions.loadCurrencies),
      switchMap((action) => {
        const obs$ = action.params
          ? this.currenciesService.search$(action.params)
          : this.currenciesService.search$(undefined, true)

        return obs$.pipe(
          map((currenciesData) =>
            CurrenciesActions.setCurrencies({
              tenantId: action.tenantId,
              currencies: currenciesData.data,
            }),
          ),
        )
      }),
    )
  })

  constructor(
    private actions$: Actions,
    private currenciesService: CurrenciesService,
  ) {}
}
