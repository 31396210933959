import { Pipe, PipeTransform } from '@angular/core'

import {
  checkUserSessionAllowed,
  parseUserSessionsAllowed,
} from '../libs/user.lib'
import { User } from '../user.model'

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  transform(
    user: User,
    kind: 'flat-name' | 'session-allowed' | 'sessions-object',
    options?: {
      sessionType?: 'WEB' | 'APP'
    },
  ): string | boolean | object {
    switch (kind) {
      case 'flat-name':
        return `${user.name} ${user.surname}`
      case 'session-allowed':
        return options?.sessionType
          ? checkUserSessionAllowed(user, options.sessionType)
          : false
      case 'sessions-object':
        return parseUserSessionsAllowed(user)
      default:
        return ''
    }
  }
}
