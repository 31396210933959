import { Pipe, PipeTransform } from '@angular/core'
import { difference } from 'lodash'

@Pipe({
  name: 'difference',
})
export class DifferencePipe implements PipeTransform {
  transform(values: any[], valuesToRemove: any[]): any[] {
    return difference(values, valuesToRemove)
  }
}
