import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { BordereauStatus } from './bordereau.model'

export const BORDEREAU_SCOPE: PermissionsScope = 'bordereaus'

export const BORDEREAU_STATUSES: EntityStatusDesc<BordereauStatus>[] = [
  {
    code: BordereauStatus.processing,
    name: 'In lavorazione',
    tooltip:
      'Distinta in lavorazione, non è ancora stato trasmesso al corriere',
  },
  {
    code: BordereauStatus.shipped,
    name: 'Spedito',
    tooltip: 'Distinta trasmessa al corriere',
  },
]
