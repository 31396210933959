import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import { View, ViewField, ViewSearchParams } from '../view.model'
import { ViewsService } from '../views.service'
import { VIEW_DEFAULT_FIELDS } from '../view.const'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'

interface ViewsListingState {
  fields: ViewField[]
  filters?: ViewSearchParams
  data?: View[]
  totalCount?: number
  userId?: string
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const VIEWS_LISTING_INITIAL_STATE: ViewsListingState = {
  fields: VIEW_DEFAULT_FIELDS,
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class ViewsListingUseCase extends ComponentStore<ViewsListingState> {
  constructor(private viewsService: ViewsService) {
    super(VIEWS_LISTING_INITIAL_STATE)
  }

  // Selectors

  readonly selectUserId$: Observable<string | undefined> = this.select(
    (state) => state.userId,
  )

  readonly selectFilters$: Observable<ViewSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {})),
  )

  readonly selectViews$: Observable<View[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectPage$: Observable<Page<View>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectViews$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<ViewSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectUserId$),
        switchMap(([searchParams, userId]) => {
          if (!userId) {
            return EMPTY
          }

          return this.viewsService.search$({ ...searchParams, userId }).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
        }),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<ViewSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectTotalCount$, this.selectUserId$),
        map(([params, limit, userId]) => ({ ...params, limit, userId })),
        switchMap((searchParams) =>
          this.viewsService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  // Reducers

  readonly setUserId = this.updater(
    (state, userId: string | undefined): ViewsListingState => {
      return {
        ...state,
        userId,
        toReload: true,
      }
    },
  )

  readonly setFilters = this.updater(
    (state, filters?: ViewSearchParams): ViewsListingState => {
      return {
        ...state,
        filters,
        toReload: false,
        isLoading: true,
        error: undefined,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: Page<View>): ViewsListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ViewsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): ViewsListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )
}
