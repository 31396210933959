import { PromotionType } from './promotion.model'

export const PROMOTION_TYPES = [
  {
    code: PromotionType.coupon,
    name: 'Coupon',
  },
  {
    code: PromotionType.giftCard,
    name: 'Gift card',
  },
]
