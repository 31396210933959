import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Customer, CustomerSearchParams } from './customer.model'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'customers'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new customer
   * @param customer - The customer to create
   * @returns The observable<Customer> to create the customer
   */
  create$(customer: Customer): Observable<Customer> {
    return this._create$<Customer>(customer)
  }

  /**
   * Read a customer by ID
   * @param customerId - The customer ID
   * @returns The observable<Customer> for read the customer
   */
  read$(customerId: string): Observable<Customer> {
    return this._read$<Customer>(customerId)
  }

  /**
   * Update a customer by ID
   * @param customerId - The customer ID
   * @param customer - The customer body to update
   * @returns The observable<Customer> for update the customer
   */
  update$(customerId: string, customer: Customer): Observable<Customer> {
    return this._update$<Customer>(customerId, customer)
  }

  /**
   * Create or update a customer by ID
   * @param customerId - The customer ID
   * @param customer - The customer body to update
   * @returns The observable<Customer> for update the customer
   */
  upsert$(customer: Customer): Observable<Customer> {
    return this._upsert$<Customer>(customer, customer._id)
  }

  /**
   * Delete a customer by ID
   * @param customerId - The customer ID
   * @returns The observable<Customer> for delete the customer
   */
  delete$(customerId: string): Observable<Customer> {
    return this._delete$<Customer>(customerId)
  }

  /**
   * Search customers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Customer>> for search customers
   */
  search$(
    params?: CustomerSearchParams,
    returnAll = false,
  ): Observable<Page<Customer>> {
    return this._search$<Customer>(params, returnAll)
  }

  /**
   * List customers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Customer[]> for list customers
   */
  list$(
    params?: CustomerSearchParams,
    returnAll = false,
  ): Observable<Customer[]> {
    return this._list$<Customer>(params, returnAll)
  }
}
