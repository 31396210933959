import { Pipe, PipeTransform } from '@angular/core'
import { Locales } from '../../../models/util.model'
import { ProductScope, initProductScope } from '../../products'

// SDK

@Pipe({
  name: 'locales',
})
export class LocalesPipe implements PipeTransform {
  transform(
    value: Locales<string> | string,
    scope: ProductScope = initProductScope(),
  ): string {
    if (!value) {
      return ''
    }

    if (typeof value === 'string') {
      return value
    }

    if (!!value && !!value[scope.locale]) {
      return value[scope.locale] as string
    }

    if (value.default) {
      return `[${value.default}]`
    }

    return ''
  }
}
