import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import {
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'

import { Brand, BrandData } from '../brand.model'
import { BrandsService } from '../brands.service'
import { initBrand } from '../libs/brand.lib'
import { BrandsRepository } from '../brands.repository'
import { BrandNotification } from '../libs/brand-notification.lib'

export interface BrandDetailState {
  brandId?: string
  brand?: Brand
  productsCount?: number
  error?: HttpErrorResponse
  notification?: BrandNotification
  toClose: boolean
  isLoading: boolean
  isModified: boolean
}

const BRAND_DETAIL_INITIAL_STATE: BrandDetailState = {
  toClose: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class BrandDetailUseCase extends ComponentStore<BrandDetailState> {
  constructor(
    private brandsService: BrandsService,
    private brandsRepository: BrandsRepository,
  ) {
    super(BRAND_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<BrandDetailState> = this.select(
    (state) => state,
  )

  readonly selectCreatedBy$: Observable<string | undefined> = this.select(
    (state) => state.brand?.createdBy,
  )

  readonly selectUpdatedBy$: Observable<string | undefined> = this.select(
    (state) => state.brand?.updatedBy,
  )

  readonly selectProductsCount$: Observable<number | undefined> = this.select(
    (state) => state.productsCount,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectBrandId$: Observable<string | undefined> = this.select(
    (state) => state.brand?._id,
  )

  readonly selectBrand$: Observable<Brand | undefined> = this.select(
    (state) => state.brand,
  )

  readonly selectBrandLoaded$: Observable<Brand | undefined> = this.select(
    (state) => state,
  ).pipe(
    filter((state) => !state.isLoading),
    map((state) => state.brand),
  )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectNotification$: Observable<BrandNotification | undefined> =
    this.select((state) => state.notification)

  // Effects

  readonly init$ = this.effect(
    (brand$: Observable<Partial<Brand> | undefined>) => {
      return brand$.pipe(
        map((brand) => initBrand(brand)),
        tap((brand) => this.setBrand(brand)),
      )
    },
  )

  readonly load$ = this.effect((brandId$: Observable<string>) => {
    return brandId$.pipe(
      tap((brandId) => this.setBrandId(brandId)),
      switchMap((brandId) =>
        this.brandsService.loadBrandData$(brandId).pipe(
          tapResponse(
            (brandData) => this.setBrandData(brandData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectBrand$),
      switchMap(([toClose, brand]) => {
        if (!brand) {
          return EMPTY
        }

        return this.brandsService.upsert$(brand).pipe(
          tapResponse(
            (brand) => this.setBrandSaved({ brand, toClose }),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly delete$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectBrand$),
      switchMap(([_, brand]) => {
        if (!brand) {
          return EMPTY
        }

        return this.brandsRepository
          .alert$(
            BrandNotification.from({
              code: 'BRAND_DELETION',
              data: { brand },
            }),
          )
          .pipe(
            take(1),
            switchMap((confirmation) => {
              if (!confirmation || !brand) {
                return EMPTY
              }

              this.setIsLoading(true)
              return this.brandsService.delete$(brand._id).pipe(
                tapResponse(
                  () => this.setBrandDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              )
            }),
          )
      }),
    )
  })

  // Reducers

  readonly setBrandId = this.updater(
    (state, brandId: string): BrandDetailState => {
      return {
        brandId,
        toClose: false,
        isLoading: true,
        isModified: false,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): BrandDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setBrandData = this.updater(
    (state, brandData: BrandData): BrandDetailState => ({
      ...state,
      brand: brandData.brand,
      productsCount: brandData.productsCount,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setBrand = this.updater(
    (state, brand: Brand): BrandDetailState => ({
      ...state,
      brand,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setBrandSaved = this.updater(
    (state, params: { brand: Brand; toClose?: boolean }): BrandDetailState => {
      return {
        ...state,
        brand: params.brand,
        productsCount: state.productsCount || 0,
        toClose: params.toClose || false,
        notification: BrandNotification.from({ code: 'BRAND_SAVED' }),
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setBrandDeleted = this.updater((state): BrandDetailState => {
    return {
      ...state,
      notification: BrandNotification.from({ code: 'BRAND_DELETED' }),
      toClose: true,
    }
  })

  readonly updateBrand = this.updater(
    (state, brand: Brand): BrandDetailState => {
      return {
        ...state,
        brand,
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): BrandDetailState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setToClose = this.updater(
    (state, toClose: boolean): BrandDetailState => {
      return {
        ...state,
        toClose,
      }
    },
  )
}
