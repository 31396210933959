import { isEmpty } from 'lodash'
import { ProductCatalogClassification, ProductScope } from '../product.model'
import { PRODUCT_SCOPE_INITIAL_STATE } from '../product.const'

export function defineProductScopeClassifications(
  classifications: ProductCatalogClassification[] = [],
  scope: ProductScope = PRODUCT_SCOPE_INITIAL_STATE
): ProductCatalogClassification[] {
  return classifications.find((cat) => cat.catalogCode === scope.catalogCode)
    ? [...classifications]
    : [...classifications, { catalogCode: scope.catalogCode, ids: [] }]
}

export function removeProductEmptyClassifications(
  classifications?: ProductCatalogClassification[]
): ProductCatalogClassification[] | undefined {
  const newClassifications = classifications
    ? classifications.filter((cat) => !isEmpty(cat.ids))
    : []
  return newClassifications.length ? [...newClassifications] : undefined
}
