import { DeepPartial } from '@evologi/shared/data-access-api'
import { OrderBulkUpdateAction, OrderBulkUpdateField } from '../order.model'

/**
 * Init bulk update params
 * @param subject - the bulk update field
 * @returns the params
 */
export function initOrderBulkAction(
  subject: OrderBulkUpdateField
): DeepPartial<OrderBulkUpdateAction> {
  let action: DeepPartial<OrderBulkUpdateAction> = {}

  switch (subject) {
    case OrderBulkUpdateField.carrier:
      action = {
        subject: OrderBulkUpdateField.carrier,
        value: {},
      }
      break
    default:
      break
  }

  return action
}
