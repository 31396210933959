import { Injectable } from '@angular/core'
import { Storage } from './'

@Injectable({
  providedIn: 'root',
})
export class StorageBrowser extends Storage {
  override set(key: string, value: any) {
    value = typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, value)
  }

  override get(key: string): any {
    const data: string = localStorage.getItem(key) as string
    return this.isJSON(data) ? JSON.parse(data) : data
  }

  override remove(key: string): any {
    localStorage[key] && localStorage.removeItem(key)
  }

  private isJSON(data: string) {
    try {
      JSON.parse(data)
    } catch (error) {
      return false
    }
    return true
  }
}
