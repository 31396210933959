import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies/policy.model'
import { WarehouseTab } from './warehouse.model'

export const WAREHOUSE_FRAGMENT = 'warehouse'
export const WAREHOUSE_PATH = 'warehouses'
export const WAREHOUSE_SCOPE: PermissionsScope = 'locations'

export const WAREHOUSE_TABS: SelectionData<WarehouseTab>[] = [
  {
    value: WarehouseTab.structure,
    label: 'Struttura',
  },
  {
    value: WarehouseTab.logistic,
    label: 'Logistica',
  },
]

export const WAREHOUSE_AREA_LEVELS: string[] = [
  'Corsia',
  'Scaffale',
  'Livello',
  'Posizione',
  'Scomparto',
]
