import { Inject, Injectable } from '@angular/core'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { HttpClient, HttpParams } from '@angular/common/http'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Observable } from 'rxjs'
import { Stats, StatsParams } from './stats.model'

const MODEL = 'stats'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class StatsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Get stats
   * @param params - the stats params
   * @returns the obsevable<Stats> for load the stats
   */
  stats$(params: StatsParams): Observable<Stats> {
    return this.http.get<Stats>(`${this.apiUrl}`, {
      params: params as HttpParams,
    })
  }
}
