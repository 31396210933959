import { Injectable } from '@angular/core'
import { Product, ProductsService } from '../products'
import {
  Observable,
  catchError,
  combineLatest,
  concatMap,
  from,
  last,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs'
import {
  getOrderSimpleProductIds,
  parseOrderDetailKeys,
} from './libs/order.lib'
import {
  Coverage,
  Order,
  OrderDetail,
  OrderDetailData,
  OrderField,
  OrderNotificationCode,
  OrderPackagesData,
  OrderSearchParams,
  OrderShipmentData,
  OrderTransport,
  OrdersListingData,
  OrdersListingPage,
} from './order.model'
import { OrdersService } from './orders.service'
import {
  Carrier,
  CarriersService,
  generateCarrierTrackingUrl,
  isGenericCarrier,
} from '../carriers'
import { ORDER_ACTIONS } from './order.const'
import {
  PickingList,
  PickingListOrderRow,
  PickingListsService,
  PickingUnusableProduct,
} from '../picking-lists'
import { UsersService } from '../users'
import { Page } from '../../models/util.model'
import { parseOrderPageKeys } from './libs/orders-page.lib'
import { TagTarget, TagsService } from '../tags'
import { WarehousesService } from '../warehouses'
import { CurrenciesService } from '../currencies'
import { CountriesService } from '../countries'
import { PaymentsService } from '../payments'
import { ChannelsService } from '../channels'
import { getOrderPackagesProductIds } from './libs/order-packages.lib'
import { PackingList, PackingListsService } from '../packing-lists'
import { Bordereau, BordereausService } from '../bordereaus'

@Injectable({
  providedIn: 'root',
})
export class OrdersRepository {
  private paginationLimit = 200

  constructor(
    private ordersService: OrdersService,
    private usersService: UsersService,
    private packingListsService: PackingListsService,
    private pickingListsService: PickingListsService,
    private carriersService: CarriersService,
    private tagsService: TagsService,
    private bordereausService: BordereausService,
    private warehousesService: WarehousesService,
    private currenciesService: CurrenciesService,
    private countriesService: CountriesService,
    private productsService: ProductsService,
    private paymentsService: PaymentsService,
    private channelsService: ChannelsService,
  ) {}

  /**
   * Load order products
   * @param orderId - the order ID
   * @returns the observable for load order products
   */
  getOrderProducts$(
    orderId: string,
    products?: Product[],
  ): Observable<Product[]> {
    return this.ordersService.read$(orderId).pipe(
      map((order) => getOrderSimpleProductIds(order)),
      switchMap((productIds) =>
        this.productsService.store$(productIds, products || [], true),
      ),
    )
  }

  /**
   * Load order packages
   * @param order - the order
   * @param products - the products already loaded
   * @returns the observable for load the order packages data
   */
  getOrderPackagesData$(
    order: Order,
    products?: Product[],
  ): Observable<OrderPackagesData> {
    const productIds = getOrderPackagesProductIds(
      order.packages,
      order.additionalPackages,
    )
    return this.productsService.store$(productIds, products || []).pipe(
      map((products) => ({
        products,
        packages: order.packages,
        additionalPackages: order.additionalPackages,
      })),
    )
  }

  /**
   * Search product package by code
   * @param code - the search code
   * @returns the observable for get product package
   */
  getProductPackage$(code: string): Observable<Product> {
    // Check code length
    if (!code.length) {
      return this._orderNotification$('SKU_NOT_VALID')
    }

    return this.productsService.readOne$({ code }).pipe(
      catchError(() => this._orderNotification$('PACKAGE_NOT_FOUND')),
      switchMap((product) =>
        product
          ? of(product)
          : this._orderNotification$('PACKAGE_NOT_AVAILABLE'),
      ),
    )
  }

  /**
   * Load order shipment data
   * @param order - the order shipment data
   * @param carrier - the carrier of the order
   * @returns the observable for load the order shipment data
   */
  getOrderShipmentData$(
    order: Order,
    carrier?: Carrier,
  ): Observable<OrderShipmentData | undefined> {
    // Check order status
    // TODO: this not should be here
    const orderPermissions = ORDER_ACTIONS[order.status].permissions
    if (!orderPermissions.includes('showShipmentInfo')) {
      return of(undefined)
    }

    // Check order transport
    if (order.header.transport !== OrderTransport.carrier || !order.carrierId) {
      return of(undefined)
    }

    // Retrieve carrier if not passed
    const obs$ = carrier
      ? of(carrier)
      : this.carriersService.read$(order.carrierId)

    return obs$.pipe(
      switchMap((carrier) => this.getOrderCarrierShipment$(order._id, carrier)),
    )
  }

  /**
   * Get order shipment data by carrier
   * @param orderId - the order ID
   * @param carrier - the carrier of the order
   * @returns the observable for
   */
  getOrderCarrierShipment$(
    orderId: string,
    carrier: Carrier,
  ): Observable<OrderShipmentData | undefined> {
    if (isGenericCarrier(carrier)) {
      return of(undefined)
    }

    return this.ordersService.getShipment$(orderId).pipe(
      map((shipment) => {
        if (!shipment.trackingNumbers?.length) {
          return undefined
        }

        return {
          shipment,
          tracking: {
            number: shipment.trackingNumbers[0],
            link: generateCarrierTrackingUrl(carrier, shipment),
          },
        }
      }),
    )
  }

  /**
   * Load picking-list order IDs
   * @param pickingListId - the order ID
   * @returns the observable for load picking-list order IDs
   */
  getPickingListIds$(pickingListId: string): Observable<string[]> {
    return this.pickingListsService
      .read$(pickingListId)
      .pipe(map((pickingList) => pickingList.orders.map((o) => o._id)))
  }

  /**
   * Download order label
   * @param orderId - the order ID
   * @returns the observable for download the order label
   */
  downloadOrderLabel$(orderId: string): Observable<any> {
    return this.ordersService.getShipment$(orderId).pipe(
      switchMap((shipment) => {
        const label = (shipment.labels || []).find(
          (l) => l.fileType === 'pdf' || l.type === 'PDF',
        )
        return label && label._id
          ? this.ordersService.downloadLabel$(orderId, label._id)
          : of(undefined)
      }),
    )
  }

  /**
   * Search orders and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @param listingData - the already loaded listing data
   * @returns the observable for search orders
   */
  searchOrders$(
    searchParams: OrderSearchParams,
    fields: OrderField[],
    listingData?: OrdersListingData,
  ): Observable<OrdersListingPage> {
    return searchParams.limit !== undefined &&
      searchParams.limit > this.paginationLimit
      ? this._paginateOrders$(searchParams, fields, listingData)
      : this._searchOrders$(searchParams, fields, listingData)
  }

  /**
   * Load order and external data if needed
   * @param orderId - the order ID
   * @param fields - the order fields
   */
  loadOrder$(orderId: string, fields: OrderField[]): Observable<OrderDetail> {
    return this.ordersService
      .read$(orderId)
      .pipe(
        switchMap((order) =>
          this._loadDetailExtData$(order, fields).pipe(
            map((extData) => ({ order, extData })),
          ),
        ),
      )
  }

  /**
   * Save order and load external data if needed
   * @param order - the order ID
   * @param fields - the order fields
   * @returns the observable for save order
   */
  saveOrder$(order: Order, fields: OrderField[]): Observable<OrderDetail> {
    return this.ordersService
      .update$(order._id, order)
      .pipe(
        switchMap((order) =>
          this._loadDetailExtData$(order, fields).pipe(
            map((extData) => ({ order, extData })),
          ),
        ),
      )
  }

  /**
   * Load order picking-list
   * @param order - the order
   * @returns the observable for load order picking-list
   */
  loadOrderPickingList$(order: Order): Observable<PickingList | undefined> {
    if (!order.pickingListId) {
      return of(undefined)
    }

    return this.pickingListsService.read$(order.pickingListId)
  }

  /**
   * Load order packing-list
   * @param order - the order
   * @returns the observable for load order packing-list
   */
  loadOrderPackingList$(order: Order): Observable<PackingList | undefined> {
    if (!order.pickingListId) {
      return of(undefined)
    }

    return this.packingListsService
      .list$({
        pickingListId: order.pickingListId,
      })
      .pipe(map((packings) => packings[0]))
  }

  /**
   * Load order products
   * @param order - the order
   * @returns the observable for load order products
   */
  loadOrderProducts$(order: Order): Observable<Product[]> {
    const orderPermissions = ORDER_ACTIONS[order.status].permissions

    if (
      !order.rows.length ||
      !orderPermissions.includes('showProductQuantities')
    ) {
      return of([])
    }

    const productIds = getOrderSimpleProductIds(order)

    if (!productIds.length) {
      return of([])
    }

    return this.productsService.list$(
      {
        fields: [
          '_id',
          'warehouses',
          'onHandQty',
          'availableQty',
          'incomingQty',
          'receivingQty',
        ],
        _id: productIds,
      },
      true,
    )
  }

  /**
   * Load order bordereau
   * @param order - the order
   * @returns the observable for load order bordereau
   */
  loadOrderBordereau$(order: Order): Observable<Bordereau | undefined> {
    if (!order.bordereauId) {
      return of(undefined)
    }

    return this.bordereausService.read$(order.bordereauId)
  }

  /**
   * Load order simple products
   * @param order - the order
   * @returns the observable for load order simple products
   */
  loadOrderSimpleProducts$(order: Order): Observable<Product[]> {
    const productIds = getOrderSimpleProductIds(order)

    if (!productIds.length) {
      return of([])
    }

    return this.productsService.list$({ _id: productIds })
  }

  /**
   * Load order coverage
   * @param warehouseId - the warehouseId
   * @param orderId - the orderId
   * @returns the observable for load order coverage
   */
  loadOrderCoverage$(
    warehouseId: string,
    orderId: string,
  ): Observable<{
    coverage: Coverage
    rows: PickingListOrderRow[]
    unusable: PickingUnusableProduct[]
  }> {
    return this.pickingListsService
      .simulate$(warehouseId, [{ _id: orderId }])
      .pipe(
        map((simulation) => {
          const orderCoverage = simulation.orders.find((o) => o._id === orderId)

          return {
            coverage: orderCoverage?.status || Coverage.unavailable,
            rows: orderCoverage?.rows || [],
            unusable:
              simulation.unusable?.filter((r) => r.orderId === orderId) || [],
          }
        }),
      )
  }

  /**
   * Check product barcode
   * @param order - the order
   * @param barcode - the product barcode
   * @returns the observable for check if a product is inside the an order
   */
  checkOrderProductBarcode$(order: Order, code: string): Observable<number> {
    return this.productsService.readOne$({ code }).pipe(
      map((product) =>
        order.rows.findIndex(
          (orderRow) => orderRow.product._id === product._id,
        ),
      ),
      tap((rowIndex) => {
        if (rowIndex < 0) {
          throw new Error('PRODUCT_NOT_FOUND')
        }
      }),
    )
  }

  /**
   * Load external order data
   * @param order - the order
   * @param fields - the orders fields
   * @returns the observable for load external data
   */
  private _loadDetailExtData$(
    order: Order,
    fields: OrderField[],
  ): Observable<OrderDetailData> {
    const extDataKeys = parseOrderDetailKeys(order)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('carrierId') && extDataKeys.carrierId) {
      obs$['carrier'] = this.carriersService.read$(extDataKeys.carrierId)
    }

    if (fields.includes('assignedWarehouseId') && extDataKeys.warehouseId) {
      obs$['warehouse'] = this.warehousesService.read$(extDataKeys.warehouseId)
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  /**
   * Load external page orders data
   * @param page - the orders page
   * @param fields - the orders fields
   * @returns the observable for load external data
   */
  private _loadPageExtData$(
    page: Page<Order>,
    fields: OrderField[],
    listingData?: OrdersListingData,
  ): Observable<OrdersListingData> {
    const extDataKeys = parseOrderPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (
      (fields.includes('warehouseId') ||
        fields.includes('assignedWarehouseId')) &&
      extDataKeys.warehouseIds
    ) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (fields.includes('header.currency') && extDataKeys.currencies) {
      obs$['currencies'] = this.currenciesService.store$(
        extDataKeys.currencies,
        listingData?.currencies || [],
        'code',
      )
    }

    if (fields.includes('header.paymentType') && extDataKeys.paymentIds) {
      obs$['payments'] = this.paymentsService.store$(
        extDataKeys.paymentIds,
        listingData?.payments || [],
      )
    }

    if (
      extDataKeys.countryCodes &&
      (fields.includes('header.billingAddress.countryCode') ||
        fields.includes('header.shippingAddress.countryCode'))
    ) {
      obs$['countries'] = this.countriesService.store$(
        extDataKeys.countryCodes,
        listingData?.countries || [],
        'alpha2Code',
      )
    }

    if (fields.includes('carrierId') && extDataKeys.carrierIds) {
      obs$['carriers'] = this.carriersService.store$(
        extDataKeys.carrierIds,
        listingData?.carriers || [],
      )
    }

    if (fields.includes('header.channel') && extDataKeys.channelIds) {
      obs$['channels'] = this.channelsService.store$(
        extDataKeys.channelIds,
        listingData?.channels || [],
      )
    }

    if (
      fields.includes('processedBy') ||
      (fields.includes('packedBy') && extDataKeys.userIds)
    ) {
      obs$['users'] = this.usersService.store$(
        extDataKeys.userIds || [],
        listingData?.users || [],
      )
    }

    if (fields.includes('pickingListId') && extDataKeys.pickingListIds) {
      obs$['pickingLists'] = this.pickingListsService.store$(
        extDataKeys.pickingListIds,
        listingData?.pickingLists || [],
      )
    }

    if (fields.includes('tags') && extDataKeys.tagValues) {
      obs$['tags'] = this.tagsService.store$(
        extDataKeys.tagValues,
        listingData?.tags || [],
        'value',
        TagTarget.orders,
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  /**
   * Search orders with relative external data
   * @param searchParams - the search params
   * @param fields - the orders field
   * @param listingData - the already loaded listing data
   * @returns the observable<OrdersListingPage> for search orders
   */
  private _searchOrders$(
    searchParams: OrderSearchParams,
    fields: OrderField[],
    listingData?: OrdersListingData,
  ): Observable<OrdersListingPage> {
    return this.ordersService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this._loadPageExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Search more than 200 orders with relative external data
   * @param searchParams - the search params
   * @param fields - the orders fields
   * @param listingData - the already loaded listing data
   * @returns the observable<OrdersListingData> for search orders
   */
  private _paginateOrders$(
    searchParams: OrderSearchParams,
    fields: OrderField[],
    listingData?: OrdersListingData,
  ): Observable<OrdersListingPage> {
    // Paginate requests
    const totalCount = searchParams.limit || this.paginationLimit
    const pagesCount = Math.ceil(totalCount / this.paginationLimit)

    const pages$ = from(
      Array.from({ length: pagesCount }, (_, i) => ({
        ...searchParams,
        limit: this.paginationLimit,
        offset: i * this.paginationLimit,
      })),
    )

    let response: OrdersListingPage = {
      totalCount: 0,
      data: [],
    }
    let extData = listingData

    return pages$.pipe(
      concatMap((pageParams) =>
        this._searchOrders$(pageParams, fields, extData).pipe(
          map((res) => {
            response = {
              totalCount: (response.totalCount || 0) + (res.totalCount || 0),
              data: [...(response.data || []), ...(res.data || [])],
              extData: { ...response.extData, ...res.extData },
            }
            extData = response.extData

            return response
          }),
        ),
      ),
      last(),
    )
  }

  private _orderNotification$(code: OrderNotificationCode): Observable<never> {
    return throwError(() => ({ code }))
  }
}
