import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects'
import { of, combineLatest, throwError, EMPTY, Observable } from 'rxjs'
import { catchError, map, switchMap, take } from 'rxjs/operators'

import {
  Tenant,
  User,
  Permissions,
  WarehousesService,
  TenantsService,
  AuthService,
  getPermissionsObjectId,
  PickupPointsService,
  SuppliersService,
} from '@evologi/shared/data-access-api'

import { encrypt } from '../../libs/crypto.lib'
import {
  StoreState,
  StoreConfiguration,
  STORE_CONFIGURATION,
} from '../store.state'
import * as AuthActions from './auth.actions'
import * as AuthSelectors from './auth.selectors'
import * as RouterActions from '../router'

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  // Init

  initEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      take(1),
      concatLatestFrom(() =>
        this.store.select(AuthSelectors.selectAuthenticated),
      ),
      switchMap(([action, authenticated]) =>
        authenticated
          ? this.loadUser$().pipe(map(() => AuthActions.userInitialized()))
          : of(AuthActions.stateInitialized()),
      ),
    )
  })

  // Login

  loggedInEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedIn),
      switchMap((action) =>
        this.authService
          .authenticate$(action.username, action.password, {
            force: action.force,
            code: action.code,
          })
          .pipe(
            map((refreshment) =>
              AuthActions.userLoggedInToken({
                refreshToken: this.config.useAuthToken
                  ? encrypt(refreshment.token, this.config.privateKey)
                  : undefined,
              }),
            ),
            catchError((error) => of(AuthActions.userLoggedInFailed(error))),
          ),
      ),
    )
  })

  loggedInSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedInToken),
      switchMap(() =>
        this.loadUser$().pipe(
          map((user) => AuthActions.userLoggedInSuccess({ user })),
          catchError((error) => of(AuthActions.userLoggedInFailed(error))),
        ),
      ),
    )
  })

  userAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedInSuccess),
      switchMap((action) => this.getUserAction$(action.user)),
    )
  })

  // Logout

  loggedOutEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOut),
      switchMap(() => this.authService.logout$()),
      map(() => AuthActions.userLoggedOutSuccess()),
      catchError(() => of(AuthActions.userLoggedOutSuccess())),
    )
  })

  loggedOutSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userLoggedOutSuccess, AuthActions.userExpel),
      map(() => RouterActions.go({ path: [this.config.authenticationRoute] })),
    )
  })

  // Revive

  userRevive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userRevive),
      concatLatestFrom((action) =>
        this.store.select(AuthSelectors.selectAuthTenantId),
      ),
      switchMap(([action, tenantId]) => {
        if (!tenantId) {
          return EMPTY
        }

        return this.authService.refresh$(tenantId).pipe(
          map((authentication) =>
            AuthActions.userReviveSuccess({
              accessToken: encrypt(
                authentication.token,
                this.config.privateKey,
              ),
            }),
          ),
          catchError((error) => of(AuthActions.userReviveFailed(error))),
        )
      }),
    )
  })

  // Tenant effects

  tenantSelectionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSelected),
      switchMap((action) =>
        this.authService.refresh$(action.tenantId).pipe(
          map((authentication) =>
            AuthActions.tenantSelectedToken({
              redirect: action.redirect,
              tenantId: action.tenantId,
              accessToken: encrypt(
                authentication.token,
                this.config.privateKey,
              ),
            }),
          ),
          catchError((error) => of(AuthActions.tenantSelectedFailed(error))),
        ),
      ),
    )
  })

  tenantSelectionTokenEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSelectedToken),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      switchMap(([action, user]) =>
        this.loadAuthData$(user, action.tenantId).pipe(
          map((authData) =>
            AuthActions.tenantSelectedSuccess({
              ...authData,
              redirect: action.redirect,
            }),
          ),
          catchError((error) => of(AuthActions.tenantSelectedFailed(error))),
        ),
      ),
    )
  })

  tenantSelectionSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSelectedSuccess),
      switchMap((action) => {
        if (!action.redirect) {
          return EMPTY
        }

        return of(
          RouterActions.go({
            path: [this.config.authenticatedRoute],
            extras: { replaceUrl: true },
          }),
        )
      }),
    )
  })

  // Password management

  passwordUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userPasswordUpdate),
      switchMap((action) =>
        this.authService
          .changePassword$(action.oldPassword, action.newPassword)
          .pipe(
            catchError((error) =>
              of(AuthActions.userPasswordUpdateFailed(error)),
            ),
            concatLatestFrom(() => this.store.select(AuthSelectors.selectAuth)),
            switchMap(([response, auth]) =>
              this.restoreUser$(
                auth?.user,
                auth?.tenant,
                action.newPassword,
              ).pipe(
                map((restoration) =>
                  AuthActions.userPasswordUpdateSuccess(restoration),
                ),
                catchError((error) =>
                  of(AuthActions.userPasswordUpdateFailed(error)),
                ),
              ),
            ),
          ),
      ),
    )
  })

  passwordChangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userPasswordChange),
      switchMap((action) =>
        this.authService
          .changePassword$(action.oldPassword, action.newPassword)
          .pipe(
            map(() =>
              AuthActions.userPasswordChangeSuccess({
                password: action.newPassword,
              }),
            ),
            catchError((error) =>
              of(AuthActions.userPasswordChangeFailed(error)),
            ),
          ),
      ),
    )
  })

  passwordChangeSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userPasswordChangeSuccess),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      map(([action, user]) =>
        AuthActions.userLoggedIn({
          username: user?.username || '',
          password: action.password,
          force: true,
        }),
      ),
    )
  })

  passwordRecoveryEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userPasswordRecovery),
      switchMap((action) =>
        this.authService.forgotPassword$(action.username).pipe(
          map(() => AuthActions.userPasswordRecoverySuccess()),
          catchError((error) =>
            of(AuthActions.userPasswordRecoveryFailed(error)),
          ),
        ),
      ),
    )
  })

  passwordRecoverySuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userPasswordRecoverySuccess),
      map(() => RouterActions.go({ path: [this.config.authenticationRoute] })),
    )
  })

  // Profiles update

  tenantSaved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.tenantSaved),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      switchMap(([action, user]) => {
        const tenant = action.tenant

        if (!tenant._id || !user) {
          return EMPTY
        }

        return this.tenantsService.update(tenant._id, tenant).pipe(
          map((tenant) => AuthActions.tenantSavedSuccess({ tenant, user })),
          catchError((error) => of(AuthActions.tenantSavedFailed(error))),
        )
      }),
    )
  })

  userSaved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.userSaved),
      concatLatestFrom(() =>
        this.store.select(AuthSelectors.selectAuthTenantId),
      ),
      switchMap(([action, tenantId]) =>
        this.authService.updateProfile$(action.user).pipe(
          map((user) => AuthActions.userSavedSuccess({ user, tenantId })),
          catchError((error) => of(AuthActions.userSavedFailed(error))),
        ),
      ),
    )
  })

  // Useful

  restoreUser$ = (
    user?: User,
    tenant?: Tenant,
    password?: string,
  ): Observable<{
    accessToken: string
    refreshToken?: string
  }> => {
    if (!user || !tenant?._id || !password) {
      return EMPTY
    }

    const tenantId = tenant._id
    return this.authService
      .authenticate$(user.username, password, { force: true })
      .pipe(
        switchMap((refreshment) =>
          this.authService.refresh$(tenantId).pipe(
            map((authentication) => ({
              refreshToken: this.config.useAuthToken
                ? encrypt(refreshment.token, this.config.privateKey)
                : undefined,
              accessToken: encrypt(
                authentication.token,
                this.config.privateKey,
              ),
            })),
          ),
        ),
      )
  }

  getUserAction$ = (user?: User) => {
    if (!user || !user.tenants) {
      return EMPTY
    }

    if (user.forcePasswordChange) {
      return of(RouterActions.go({ path: [this.config.passwordChangeRoute] }))
    }

    if (user.tenants.length > 1) {
      return of(RouterActions.go({ path: [this.config.tenantSelectionRoute] }))
    }

    return of(
      AuthActions.tenantSelected({
        tenantId: user.tenants[0]._id,
        redirect: true,
      }),
    )
  }

  loadAuthData$ = (user: User | undefined, tenantId: string | undefined) => {
    if (!user) {
      return throwError(() => 'USER_NOT_PRESENT')
    }

    const userTenant = user.tenants?.find((t) => t._id === tenantId)

    if (!userTenant) {
      return throwError(() => 'USER_NOT_ENABLED')
    }

    return this.loadPermissions$().pipe(
      switchMap((permissions) =>
        combineLatest({
          tenant: this.loadTenant$(),
          permissions: of(permissions),
          warehouse: this.loadWarehouse$(permissions),
          pickupPoint: this.loadPickupPoint$(permissions),
          supplier: this.loadSupplier$(permissions),
        }),
      ),
    )
  }

  loadUser$ = () => this.authService.whoAmI$()

  loadTenant$ = () => this.authService.tenant$()

  loadPermissions$ = () => this.authService.permissions$()

  updateTenant$ = (tenantId: string, tenant: Tenant) =>
    this.tenantsService.update<Tenant>(tenantId, tenant)

  loadWarehouse$ = (permissions: Permissions) => {
    const warehouseId = getPermissionsObjectId(
      permissions.orders,
      'WAREHOUSE_ORDERS',
    )
    return warehouseId
      ? this.warehousesService.read$(warehouseId)
      : of(undefined)
  }

  loadPickupPoint$ = (permissions: Permissions) => {
    const pickupPointId = getPermissionsObjectId(
      permissions.orders,
      'PICKUP_POINT_ORDERS',
    )
    return pickupPointId
      ? this.pickupPointsService.read$(pickupPointId)
      : of(undefined)
  }

  loadSupplier$ = (permissions: Permissions) => {
    const supplierId = getPermissionsObjectId(
      permissions.products,
      'SUPPLIER_PRODUCTS',
    )
    return supplierId ? this.suppliersService.read$(supplierId) : of(undefined)
  }

  constructor(
    @Inject(STORE_CONFIGURATION) public config: StoreConfiguration,
    private store: Store<StoreState>,
    private actions$: Actions,
    private authService: AuthService,
    private tenantsService: TenantsService,
    private warehousesService: WarehousesService,
    private pickupPointsService: PickupPointsService,
    private suppliersService: SuppliersService,
  ) {}
}
