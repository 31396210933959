import { createSelector } from '@ngrx/store'
import { Attribute, AttributeGroup } from '@evologi/shared/data-access-api'

import { StoreState } from '../store.state'
import { AttributesState } from './attributes.state'

export const selectAttributesState = (state: StoreState) => state.attributes

export const selectGroups = (tenantId: string, groupIds?: string[]) =>
  createSelector(selectAttributesState, (state: AttributesState) => {
    const tenantAttributes = state[tenantId]

    if (!tenantAttributes || !tenantAttributes.groups) {
      return undefined
    }

    if (!groupIds) {
      return tenantAttributes.groupsTotalCount !== undefined
        ? Object.values(tenantAttributes.groups)
        : undefined
    }

    const groups = groupIds
      .map((id) =>
        tenantAttributes.groups ? tenantAttributes.groups[id] : undefined,
      )
      .filter((g) => !!g)

    if (groups.length !== groupIds.length) {
      return undefined
    }

    return groups as AttributeGroup[]
  })

export const selectGroupAttributes = (tenantId: string, groupId: string) =>
  createSelector(selectAttributesState, (state: AttributesState) => {
    const tenantAttributes = state[tenantId]

    if (!tenantAttributes || !tenantAttributes.groups) {
      return undefined
    }

    const group = tenantAttributes.groups[groupId]
    const attributes = Object.values(tenantAttributes.attributes || {})
    const groupAttributes = attributes.filter(
      (a) => a.attributesGroupCode === group?.code,
    )

    if (!group || !groupAttributes.length) {
      return undefined
    }

    return groupAttributes
  })

export const selectAttributes = (tenantId: string, attributeIds?: string[]) =>
  createSelector(selectAttributesState, (state) => {
    const tenantAttributes = state[tenantId]

    if (!tenantAttributes || !tenantAttributes.attributes) {
      return undefined
    }

    if (!attributeIds) {
      return tenantAttributes.attributesTotalCount !== undefined
        ? Object.values(tenantAttributes.attributes)
        : undefined
    }

    const attributes = attributeIds
      .map((id) =>
        tenantAttributes.attributes
          ? tenantAttributes.attributes[id]
          : undefined,
      )
      .filter((c) => !!c)

    if (attributes.length !== attributeIds.length) {
      return undefined
    }

    return attributes as Attribute[]
  })

export const selectAttributesByCodes = (
  tenantId: string,
  attributeCodes: string[],
) =>
  createSelector(selectAttributesState, (state) => {
    const tenantAttributes = state[tenantId]
    if (!tenantAttributes || !tenantAttributes.attributes) {
      return undefined
    }

    const attributes = Object.values(tenantAttributes.attributes).filter((a) =>
      attributeCodes.includes(a.code),
    )

    if (attributes.length !== attributeCodes.length) {
      return undefined
    }

    return attributes as Attribute[]
  })
