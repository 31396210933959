import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export enum ReasonType {
  increase = 'INCREASE',
  decrease = 'DECREASE',
  order = 'ORDER',
  return = 'RETURN',
}

export enum ReasonTarget {
  movement = 'MOVEMENT',
  inbound = 'INBOUND',
  outbound = 'OUTBOUND',
}

export interface Reason extends Entity {
  code: string
  name: string
  reason?: ReasonType
  target?: ReasonTarget
  isDefault?: boolean
}

export type ReasonSearchField =
  | '_id'
  | 'code'
  | 'name'
  | 'target'
  | 'reason'
  | 'externalId'
  | 'isDefault'
export type ReasonSortField = 'code' | 'name'

export interface ReasonSearchParams extends QueryStringParams<ReasonSortField> {
  code?: string
  'code:ne'?: string
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  externalId?: string
  'externalId:ne'?: string
  'externalId:ct'?: string
  target?: ReasonTarget | ReasonTarget[]
  reason?: ReasonType | ReasonType[]
  isDefault?: boolean
}

export type ReasonUsage =
  | 'GOODS_RECEIVES'
  | 'GOODS_RETURNS'
  | 'PRODUCTS'
  | 'SUPPLIER_ORDERS'
  | 'ORDERS'
  | 'INVOICES'
