import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, concat, last } from 'rxjs'

import {
  GoodsReturnCounters,
  GoodsReturnSearchParams,
  GoodsReturnChangeStatusAction,
  GoodsReturn,
} from './goods-return.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'goods-returns'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class GoodsReturnsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new goods-return
   * @param goodsReturn - The goods-return to create
   * @returns The observable<GoodsReturn> to create the goods-return
   */
  create$(goodsReturn: GoodsReturn): Observable<GoodsReturn> {
    return this._create$<GoodsReturn>(goodsReturn)
  }

  /**
   * Read a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns The observable<GoodsReturn> for read the goods-return
   */
  read$(goodsReturnId: string): Observable<GoodsReturn> {
    return this._read$<GoodsReturn>(goodsReturnId)
  }

  /**
   * Update a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @param goodsReturn - The goods-return body to update
   * @returns The observable<GoodsReturn> for update the goods-return
   */
  update$(
    goodsReturnId: string,
    goodsReturn: GoodsReturn,
  ): Observable<GoodsReturn> {
    return this._update$<GoodsReturn>(goodsReturnId, goodsReturn)
  }

  /**
   * Create or update a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @param goodsReturn - The goods-return body to update
   * @returns The observable<GoodsReturn> for update the goods-return
   */
  upsert$(goodsReturn: GoodsReturn): Observable<GoodsReturn> {
    return this._upsert$<GoodsReturn>(goodsReturn, goodsReturn._id)
  }

  /**
   * Delete a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns The observable<GoodsReturn> for delete the goods-return
   */
  delete$(goodsReturnId: string): Observable<GoodsReturn> {
    return this._delete$<GoodsReturn>(goodsReturnId)
  }

  /**
   * Search goods-returns by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<GoodsReturn>> for search goods-returns
   */
  search$(
    params?: GoodsReturnSearchParams,
    returnAll = false,
  ): Observable<Page<GoodsReturn>> {
    return this._search$<GoodsReturn>(params, returnAll)
  }

  /**
   * List goods-returns by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<GoodsReturn[]> for list goods-returns
   */
  list$(
    params?: GoodsReturnSearchParams,
    returnAll = false,
  ): Observable<GoodsReturn[]> {
    return this._list$<GoodsReturn>(params, returnAll)
  }

  /**
   * Cancel a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns the observable<GoodsReturn> for cancel the return
   */
  cancel$(goodsReturnId: string): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/cancel`,
      {},
    )
  }

  /**
   * Close a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns the observable<GoodsReturn> for close the return
   */
  close$(goodsReturnId: string): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/close`,
      {},
    )
  }

  /**
   * Receive a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns the observable<GoodsReturn> for receive the return
   */
  receive$(goodsReturnId: string): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/receive`,
      {},
    )
  }

  /**
   * Return a goods-return by ID
   * @param goodsReturnId - The goods-return ID
   * @returns the observable<GoodsReturn> for retrieve the return
   */
  return$(goodsReturnId: string): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/return`,
      {},
    )
  }

  /**
   * Add a comment to goods-return
   * @param goodsReturnId - The return ID
   * @param comment - The comment to add
   * @returns The Observable<GoodsReturn> for add a comment
   */
  addComment$(
    goodsReturnId: string,
    comment: {
      replyTo?: string
      text: string
    },
  ): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/comments`,
      comment,
    )
  }

  /**
   * Get goods-return counters
   * @returns The observable<GoodsReturnCounters> for goods-returns count
   */
  counts$(): Observable<GoodsReturnCounters> {
    return this.http.get<GoodsReturnCounters>(`${this.apiUrl}/counts`)
  }

  /**
   * Change goods-receive status
   * @param goodsReturnId - The return ID
   * @param action - The change status action
   * @returns The Observable<GoodsReturn> for change the return status
   */
  changeStatus$(
    goodsReturnId: string,
    action: GoodsReturnChangeStatusAction,
  ): Observable<GoodsReturn> {
    switch (action) {
      case 'cancel':
        return this.cancel$(goodsReturnId)
      case 'close':
        return this.close$(goodsReturnId)
      case 'receive':
        return this.receive$(goodsReturnId)
      case 'return':
        return this.return$(goodsReturnId)
    }
  }

  /**
   * Generates the path to which attachments for a goods return must be uploaded
   * @param goodsReturnId - The goods return ID
   * @returns the path
   */
  getGoodsReturnAttachmentsUploadUrl(goodsReturnId: string): string {
    return `${this.apiUrl}/${goodsReturnId}/attachments`
  }

  /**
   * Creates an attachment for a goods return
   * @param goodsReturnId - The goods return ID
   * @param attachment - The attachment as binary file
   * @returns the updated observable<Order>
   */
  createAttachment$(
    goodsReturnId: string,
    attachment: any,
  ): Observable<GoodsReturn> {
    return this.http.post<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/attachments`,
      {
        body: attachment,
      },
    )
  }

  /**
   * Creates multiple attachments for a goods return
   * @param goodsReturnId - The goods return ID
   * @param attachments - The list of attachments as binary files
   * @returns the updated observable<Order>
   */
  createAttachments$(
    goodsReturnId: string,
    attachments: [any],
  ): Observable<GoodsReturn> {
    return concat(
      ...attachments.map((attachment) =>
        this.createAttachment$(goodsReturnId, attachment),
      ),
    ).pipe(last())
  }

  /**
   * Deletes an attachment from a goods return
   * @param goodsReturnId - The goods return ID
   * @param attachmentId - The attachment ID
   */
  deleteAttachment$(
    goodsReturnId: string,
    attachmentId: string,
  ): Observable<GoodsReturn> {
    return this.http.delete<GoodsReturn>(
      `${this.apiUrl}/${goodsReturnId}/attachments/${attachmentId}`,
      { body: {} },
    )
  }

  /**
   * Downloads an attachment from a goods return
   * @param productId - The product ID
   * @param attachmentId - The attachment ID
   * @returns the observable<Attachment>
   */
  downloadAttachment$(
    goodsReturnId: string,
    attachmentId: string,
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/${goodsReturnId}/attachments/${attachmentId}/content`,
      { responseType: 'blob' },
    )
  }
}
