import {
  PickingListNotificationCode,
  PickingListNotificationData,
  PickingListNotificationOptions,
} from '..'
import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'

export class PickingListNotification implements Notification {
  public code: PickingListNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: PickingListNotificationOptions) {
    const { type, dialog } = parsePickingNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: PickingListNotificationOptions): PickingListNotification {
    if (opts instanceof PickingListNotification) {
      return opts
    } else {
      return new PickingListNotification(opts)
    }
  }
}

/**
 * Parse picking-list notification to a message
 * @param code - the notification code
 * @param data - the notification data
 * @returns the notification data
 */
function parsePickingNotification(
  code: PickingListNotificationCode,
  data?: PickingListNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'PICKING_LIST_SAVED':
      dialog = {
        message: 'Picking-list saved successfully',
        color: 'success',
      }
      break
    case 'PICKING_LIST_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'PICKING_LIST_DELETED':
      dialog = {
        message: 'Picking-list deleted successfully',
        color: 'success',
      }
      break
    case 'PICKING_LIST_PRINTED':
      dialog = {
        message: 'Picking-list printed successfully',
        color: 'success',
      }
      break
    case 'PICKING_LIST_MISSIONS_PRINTED':
      dialog = {
        message: 'Picking-list missions printed successfully',
        color: 'success',
      }
      break
    case 'PICKING_WAREHOUSE_MISSING':
      dialog = {
        message: 'Picking-list warehouse missing',
        color: 'danger',
      }
      break
    case 'PICKING_ORDERS_DUPLICATED':
      dialog = {
        message: 'Multiple orders with the same reference',
        color: 'warning',
      }
      break
    case 'PICKING_ORDERS_NOT_AVAILABLE':
      dialog = {
        message: 'Orders to pick not available',
        color: 'info',
      }
      break
    case 'PICKING_ORDERS_TOO_MANY':
      type = 'alert'
      dialog = {
        icon: 'warning',
        color: 'warning',
        title: 'Too many orders',
        message: 'Picking list with more than 10 orders',
        size: 'lg',
      }
      break
    case 'PICKING_ORDER_EMPTY':
      dialog = {
        message: 'Picking list with an empty order',
        color: 'warning',
      }
      break
    case 'PICKING_LIST_DELETION': {
      const pickingName = data?.pickingList?.name
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete picking-list',
        message:
          'You are about to delete the picking-list <strong>%s</strong>. Proceed with deletion?',
        messageParams: pickingName ? [pickingName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'PICKING_LIST_PARTIAL':
      type = 'alert'
      dialog = {
        icon: 'warning',
        color: 'warning',
        title: 'Picking-list partial',
        message:
          'Quantity to be picked different from the number of total pieces',
        size: 'lg',
      }
  }

  return { dialog, type }
}
