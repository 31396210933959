import { Inject, Injectable, Optional } from '@angular/core'
import { PickingListsService } from './picking-lists.service'
import {
  PickingList,
  PickingListField,
  PickingListNotificationOptions,
  PickingListSearchParams,
  PickingListsListingData,
  PickingListsListingPage,
  parsePickingListPageKeys,
} from '.'
import { EMPTY, Observable, combineLatest, map, of, switchMap } from 'rxjs'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { UsersService } from '../users'
import { Page } from '../../models/util.model'
import { PickingListNotification } from './libs/picking-notification.lib'

@Injectable({
  providedIn: 'root',
})
export class PickingListsRepository {
  constructor(
    private pickingListsService: PickingListsService,
    private usersService: UsersService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager
  ) {}

  /**
   * Search picking-lists and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search picking-lists
   */
  searchPickingLists$(
    searchParams: PickingListSearchParams,
    fields: PickingListField[],
    listingData?: PickingListsListingData
  ): Observable<PickingListsListingPage> {
    return this.pickingListsService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData }))
          )
        )
      )
  }

  /**
   * Load external page goods-receives data
   * @param page - the goods-receives page
   * @param fields - the goods-receives fields
   * @param listingData - the listing data already loaded
   * @returns the observable for load external data
   */
  loadExtData$(
    page: Page<PickingList>,
    fields: PickingListField[],
    listingData?: PickingListsListingData
  ): Observable<PickingListsListingData> {
    const extDataKeys = parsePickingListPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (
      (fields.includes('createdBy') ||
        fields.includes('packedBy') ||
        fields.includes('pickedBy') ||
        fields.includes('startedBy')) &&
      extDataKeys.userIds
    ) {
      obs$['users'] = this.usersService.store$(
        extDataKeys.userIds,
        listingData?.users || []
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  /**
   * Show picking-list alert
   * @param options - the picking-list notification or notification options
   * @returns the observable for show the alert about picking-list
   */
  alert$(
    opts: PickingListNotification | PickingListNotificationOptions
  ): Observable<boolean> {
    const notification = PickingListNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a brand event
   * @param notification - the brand notification
   */
  notify(opts: PickingListNotification | PickingListNotificationOptions): void {
    const notification = PickingListNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }
}
