import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Payment, PaymentSearchParams } from './payment.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'payments'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new payment method
   * @param paymentMethod - The payment method to create
   * @returns The observable<Payment> to create the payment
   */
  create$(payment: Payment): Observable<Payment> {
    return this._create$<Payment>(payment)
  }

  /**
   * Read a payment by ID
   * @param paymentId - The payment ID
   * @returns The observable<Payment> for read the payment
   */
  read$(paymentId: string): Observable<Payment> {
    return this._read$<Payment>(paymentId)
  }

  /**
   * Update a payment by ID
   * @param paymentId - The payment ID
   * @param payment - The payment body to update
   * @returns The observable<Payment> for update the payment
   */
  update$(paymentId: string, payment: Payment): Observable<Payment> {
    return this._update$<Payment>(paymentId, payment)
  }

  /**
   * Create or update a payment by ID
   * @param paymentId - The payment ID
   * @param payment - The payment body to update
   * @returns The observable<Payment> for update the payment
   */
  upsert$(payment: Payment): Observable<Payment> {
    return this._upsert$<Payment>(payment, payment._id)
  }

  /**
   * Delete a payment by ID
   * @param paymentId - The payment ID
   * @returns The observable<Payment> for delete the payment
   */
  delete$(paymentId: string): Observable<Payment> {
    return this._delete$<Payment>(paymentId)
  }

  /**
   * Search payments by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Payment>> for search payments
   */
  search$(
    params?: PaymentSearchParams,
    returnAll = false,
  ): Observable<Page<Payment>> {
    return this._search$<Payment>(params, returnAll)
  }

  /**
   * List payments by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Payment[]> for list payments
   */
  list$(
    params?: PaymentSearchParams,
    returnAll = false,
  ): Observable<Payment[]> {
    return this._list$<Payment>(params, returnAll)
  }

  /**
   * Generate payments store
   * @param paymentIds - the payment IDs to load
   * @param payments - the payments already loaded
   * @returns the Observable<Payment[]> as store
   */
  store$(paymentIds: string[], payments: Payment[]): Observable<Payment[]> {
    paymentIds = uniq(paymentIds)
    paymentIds = difference(
      paymentIds,
      payments.map((u) => u._id),
    )

    if (paymentIds.length === 0) {
      return of(payments)
    }

    return this.list$({ _id: paymentIds }).pipe(
      map((pymts) => [...payments, ...pymts]),
    )
  }
}
