import { cloneDeep, defaultsDeep, isEqual, uniq } from 'lodash'
import {
  SupplierOrderRow,
  SupplierOrderRowStatus,
} from '../supplier-order.model'
import {
  Product,
  ProductProvisioningSettings,
  ProductSelection,
  getProductPurchasingPrice,
  getProductSupplierSKU,
  initProduct,
} from '../../products'
import { getCatalogValue } from '../../catalogs'
import { calculatePrices } from '../../../libs/dinero.lib'
import { DeepPartial } from '../../../models/util.model'

// Initial states

const SUPPLIER_ORDER_ROW_INITIAL_STATE: DeepPartial<SupplierOrderRow> = {
  orderedQty: 1,
  assignedQty: 0,
  receivedQty: 0,
  status: SupplierOrderRowStatus.confirmed,
  unitPrice: 0,
  unitPriceWithTaxes: 0,
  productTaxRate: 0,
  totalTaxes: 0,
  totalAmount: 0,
  totalAmountWithTaxes: 0,
  product: {},
  receptions: [],
}

// Init

export function initSupplierOrderRow(
  row: Partial<SupplierOrderRow> = {}
): SupplierOrderRow {
  return defaultsDeep(cloneDeep(row), SUPPLIER_ORDER_ROW_INITIAL_STATE)
}

// Crud

export function addSupplierOrderRows(
  rows: SupplierOrderRow[],
  selection: ProductSelection[],
  supplierId?: string
): SupplierOrderRow[] {
  const newRows = selection.map((s) =>
    createSupplierOrderRow(s.product, s.qty, supplierId, {
      useIncomingQty: true,
    })
  )
  return mergeSupplierOrderRows([...rows, ...newRows])
}

export function addSupplierOrderProvisioningRows(
  rows: SupplierOrderRow[],
  products: Product[],
  supplierId?: string,
  provisioning?: ProductProvisioningSettings
): SupplierOrderRow[] {
  const newRows = products.map((product) =>
    createSupplierOrderRow(product, undefined, supplierId, provisioning)
  )
  return mergeSupplierOrderRows([...rows, ...newRows])
}

export function createSupplierOrderRow(
  product: Product,
  qty: number | undefined,
  supplierId: string | undefined,
  provisioning?: ProductProvisioningSettings,
  taxRate?: number
): SupplierOrderRow {
  const _product = initProduct(product)

  // Tax rate
  const productTaxRate = taxRate
    ? taxRate
    : _product.taxRate
    ? _product.taxRate
    : 0

  // Quantities
  let orderedQty = qty

  // Check incoming and provisioning quantities
  if (orderedQty === undefined) {
    let toOrder = -_product.availableQty

    if (provisioning?.useIncomingQty) {
      toOrder -= _product.incomingQty ? _product.incomingQty : 0
    }

    if (provisioning?.useMaxProvisions && _product.maxProvisions) {
      toOrder += _product.maxProvisions
    }

    if (provisioning?.useMinProvisions && _product.minProvisions) {
      toOrder += _product.minProvisions
    }

    toOrder = _product.packQty
      ? Math.ceil(toOrder / _product.packQty) * _product.packQty
      : toOrder
    orderedQty = toOrder <= 0 ? 1 : toOrder
  }

  // Prices
  const unitPrice = getProductPurchasingPrice(_product, supplierId)
  const unitPriceWithTaxes = unitPrice * (productTaxRate / 100 + 1)

  return initSupplierOrderRow({
    product: {
      _id: _product._id,
      SKU: _product.SKU,
      name: getCatalogValue<string>(_product.name) || 'PRODUCT',
      supplierSKU: getProductSupplierSKU(_product, supplierId),
    },
    orderedQty,
    productTaxRate,
    unitPrice,
    unitPriceWithTaxes,
  })
}

export function updateSupplierOrderRowAmounts(
  row: SupplierOrderRow
): SupplierOrderRow {
  // Check ordered and taxRate
  const taxRate = !row.productTaxRate ? 0 : +row.productTaxRate
  const orderedQty = !row.orderedQty || row.orderedQty < 0 ? 0 : +row.orderedQty
  const receivedQty =
    !row.receivedQty || row.receivedQty < 0 ? 0 : +row.receivedQty

  const productPrices = calculatePrices({
    taxRate,
    price: row.unitPrice,
  })

  const totalAmount = productPrices.price.multiply(orderedQty)
  const totalTaxes = productPrices.taxes.multiply(orderedQty)
  const totalAmountWithTaxes = productPrices.total.multiply(orderedQty)

  return {
    ...row,
    productTaxRate: taxRate,
    orderedQty,
    receivedQty,
    unitPrice: productPrices.price.toUnit(),
    unitPriceWithTaxes: productPrices.total.toUnit(),
    totalTaxes: totalTaxes.toUnit(),
    totalAmount: totalAmount.toUnit(),
    totalAmountWithTaxes: totalAmountWithTaxes.toUnit(),
  }
}

export function removeSupplierOrderRow(
  rows: SupplierOrderRow[],
  row: SupplierOrderRow
): SupplierOrderRow[] {
  return rows.filter((_row) =>
    row._id ? _row._id !== row._id : !isEqual(_row, row)
  )
}

// Useful

function mergeSupplierOrderRows(rows: SupplierOrderRow[]): SupplierOrderRow[] {
  const productIds = uniq(rows.map((r) => r.product._id))
  return productIds.reduce<SupplierOrderRow[]>((acc, productId) => {
    const productRows = rows.filter((r) => r.product._id === productId)
    const orderedQty = productRows.reduce((qty, r) => qty + r.orderedQty, 0)

    return [
      ...acc,
      {
        ...productRows[0],
        orderedQty,
      },
    ]
  }, [])
}
