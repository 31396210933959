import { cloneDeep, defaultsDeep, uniq } from 'lodash'

import {
  SUPPLIER_ORDER_HEADER_INITIAL_STATE,
  updateSupplierOrderHeaderAmounts,
} from './supplier-order-header.lib'
import {
  addSupplierOrderRows,
  addSupplierOrderProvisioningRows,
  removeSupplierOrderRow,
  updateSupplierOrderRowAmounts,
} from './supplier-order-rows.lib'
import {
  SupplierOrder,
  SupplierOrderRow,
  SupplierOrderStatus,
  SupplierOrderViewType,
} from '../supplier-order.model'
import { SUPPLIER_ORDER_FIELDS } from '../supplier-order.const'
import { DeepPartial } from '../../../models/util.model'
import {
  Product,
  ProductProvisioningSettings,
  ProductSelection,
} from '../../products'
import { Tenant } from '../../tenants'

// Initial states

const SUPPLIER_ORDER_INITIAL_STATE: DeepPartial<SupplierOrder> = {
  status: SupplierOrderStatus.drafted,
  header: SUPPLIER_ORDER_HEADER_INITIAL_STATE,
  rows: [],
}

// Init

export function initSupplierOrder(
  supplierOrder: Partial<SupplierOrder> = {}
): SupplierOrder {
  return defaultsDeep(cloneDeep(supplierOrder), SUPPLIER_ORDER_INITIAL_STATE)
}

export function setSupplierOrderDefaults(
  supplierOrder: SupplierOrder,
  defaults: DeepPartial<SupplierOrder>
): SupplierOrder {
  return defaultsDeep(cloneDeep(supplierOrder), defaults)
}

// Amounts

export function updateSupplierOrderAmounts(
  supplierOrder: SupplierOrder
): SupplierOrder {
  const rows = supplierOrder.rows.map((row) =>
    updateSupplierOrderRowAmounts(row)
  )
  const header = updateSupplierOrderHeaderAmounts(supplierOrder.header, rows)

  const productsCount = rows.reduce((acc, row) => acc + row.orderedQty, 0)
  const rowsCount = rows.length

  return {
    ...supplierOrder,
    header,
    rows,
    productsCount,
    rowsCount,
  }
}

// Rows

export function addSupplierOrderProducts(
  supplierOrder: SupplierOrder,
  selection: ProductSelection[]
): SupplierOrder {
  return updateSupplierOrderAmounts({
    ...supplierOrder,
    rows: addSupplierOrderRows(
      supplierOrder.rows,
      selection,
      supplierOrder.supplierId
    ),
  })
}

export function deleteSupplierOrderRow(
  supplierOrder: SupplierOrder,
  row: SupplierOrderRow
): SupplierOrder {
  return updateSupplierOrderAmounts({
    ...supplierOrder,
    rows: removeSupplierOrderRow(supplierOrder.rows, row),
  })
}

export function addSupplierOrderProvisioningProducts(
  supplierOrder: SupplierOrder,
  products: Product[],
  settings: ProductProvisioningSettings
): SupplierOrder {
  return updateSupplierOrderAmounts({
    ...supplierOrder,
    rows: addSupplierOrderProvisioningRows(
      supplierOrder.rows,
      products,
      supplierOrder.supplierId,
      settings
    ),
  })
}

// Products

export function getSupplierOrderProductIds(
  supplierOrder: SupplierOrder
): string[] {
  // eslint-disable-next-line
  return uniq(supplierOrder.rows.map((row) => row.product._id!))
}

// Defaults

export function getSupplierOrderDefaults(
  tenant: Tenant
): DeepPartial<SupplierOrder> {
  return {
    warehouseId: tenant?.supplierOrders?.warehouseId,
    header: {
      currency: tenant.defaults?.currency,
    },
  }
}

export function getSupplierOrderViewFields(
  viewType: SupplierOrderViewType
): string[] {
  return SUPPLIER_ORDER_FIELDS.filter((f) =>
    f.defaultViews?.includes(viewType)
  ).map((f) => f.field)
}
