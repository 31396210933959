import { Pipe, PipeTransform } from '@angular/core'
import { ProductWrapper } from '../product.model'

@Pipe({
  name: 'wrapperFamilies',
})
export class WrapperFamiliesPipe implements PipeTransform {
  transform(wrappers: ProductWrapper[]): ProductWrapper[][] {
    return this.groupWrappersByFamily(wrappers)
  }

  private groupWrappersByFamily(
    wrappers: ProductWrapper[],
  ): ProductWrapper[][] {
    const wrapperMap = new Map<string, ProductWrapper>()
    const childParentMap = new Map<string, ProductWrapper>()
    const families: ProductWrapper[][] = []
    wrappers.forEach((wrapper) => {
      wrapperMap.set(wrapper.barcode, wrapper)
      if (wrapper.childBarcode) {
        childParentMap.set(wrapper.childBarcode, wrapper)
      }
    })
    wrapperMap.forEach((wrapper) => {
      if (!childParentMap.has(wrapper.barcode)) {
        const family: ProductWrapper[] = []
        let currentWrapper: ProductWrapper | undefined = wrapper
        while (currentWrapper) {
          family.push(currentWrapper)
          currentWrapper = currentWrapper.childBarcode
            ? wrapperMap.get(currentWrapper.childBarcode)
            : undefined
        }
        families.push(family)
      }
    })
    return families
  }
}
