import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { Page } from '../../models/util.model'
import { Location } from '../locations'
import { Product, ProductBatchSignature } from '../products'
import { User } from '../users'
import { Warehouse } from '../warehouses'

export interface Replenishment extends Entity {
  replenishmentNumber: number
  status: ReplenishmentStatus
  product?: ReplenishmentProduct
  warehouseId: string
  /**
   * Percentage.
   */
  priority: number
  /**
   * Source Location where to pick.
   */
  source?: ReplenishmentLocation
  /**
   * Target Location where to place the picked quantities.
   */
  target?: ReplenishmentLocation
  /**
   * If defined, the quantity cannot be changed.
   */
  viewers?: ReplenishmentViewer[]
  /**
   * Set only when COMPLETED.
   */
  isPartial?: boolean
  /**
   * Positive integer.
   */
  requiredQty: number
  /**
   * Zero or positive integer.
   */
  movedQty: number
  userId?: string
  actions?: ReplenishmentAction[]
}

export interface ReplenishmnetsListingPage {
  data?: Replenishment[]
  extData?: ReplenishmentsListingData
  totalCount?: number
}

export interface ReplenishmentsListingKeys {
  warehouseIds?: string[]
  productIds?: string[]
  locationIds?: string[]
  userIds?: string[]
}

export interface ReplenishmentsListingData {
  warehouses?: Warehouse[]
  products?: Product[]
  locations?: Location[]
  users?: User[]
}

export interface ReplenishmentProduct {
  /**
   * Product identifier.
   */
  _id: string
  SKU?: string
}

export type ReplenishmentStatus =
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETED'
  | 'CANCELED'

export type ReplenishmentPriority = 20 | 40 | 70

export interface ReplenishmentLocation {
  /**
   * Location identifier.
   */
  _id: string
  areaId?: string
}

export interface ReplenishmentViewer {
  /**
   * Viewer identifier.
   */
  _id: string
  /**
   * Viewer type.
   */
  type: 'PICKING_LIST'
  pickingListId: string
  missionId: string
  /**
   * Expected quantity.
   */
  quantity: number
}

export interface ReplenishmentAction {
  date: Date
  movedQty: number
  userId: string
  /**
   * Batch signature of the moved Product (if batch management enabled).
   */
  productSignature?: ProductBatchSignature
}

export interface ReplenishmentCreationParams {
  product: { _id: string }
  warehouseId: string
  /**
   * Percentage.
   */
  priority: number
  /**
   * Positive integer.
   */
  requiredQty: number
  /**
   * Source Location where to pick.
   */
  source?: { _id: string }
  /**
   * Target Location where to place the picked quantities.
   */
  target?: { _id: string }
}

export interface ReplenishmentSearchParams extends QueryStringParams {
  'product.SKU'?: string
  status?: ReplenishmentStatus | ReplenishmentStatus[]
  userId?: string
}

export interface ReplenishmentMoveParams {
  code: string
  actions: ReplenishmentActionBody[]
}

export interface ReplenishmentUpdateParams {
  source?: { _id: string }
  target?: { _id: string }
}

interface ReplenishmentActionBody {
  quantity: number
  lot?: string
  expirationDate?: string
  serial?: string
}

export interface ReplenishmentNotification {
  kind: ReplenishmentNotificationCode
  data?: { replenishment?: Replenishment }
}

export type ReplenishmentNotificationCode =
  | 'REPLENISHMENT_INCOMPLETE'
  | 'REPLENISHMENT_SAVED'
  | 'REPLENISHMENT_CANCELED'

export type ReplenishmentField =
  | 'replenishmentNumber'
  | 'createdAt'
  | 'product._id'
  | 'product.SKU'
  | 'warehouseId'
  | 'source._id'
  | 'target._id'
  | 'status'
  | 'requiredQty'
  | 'movedQty'
  | 'userId'
  | 'priority'

export type ReplenishmentSortField =
  | 'replenishmentNumber'
  | 'createdAt'
  | 'priority'

export const replenishmentsExcelKeys = [
  'number',
  'sku',
  'source',
  'target',
  'quantity',
  'priority',
  'action',
]
