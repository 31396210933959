import { InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'

/**
 * Modal manager base structure
 */
export declare interface CacheManager {
  getItems$: <T>(cacheKey: string) => Observable<T[] | undefined>
  getItem$: <T>(cacheKey: string, itemKey: string) => Observable<T | undefined>
  setItems: <T>(
    cacheKey: string,
    items: T[],
    totalCount?: number,
    itemFieldKey?: string
  ) => void
  setItem: <T>(cacheKey: string, itemKey: string, item: T) => void
  unsetItem: <T>(cacheKey: string, itemKey: string) => void
}

export const CACHE_MANAGER = new InjectionToken<CacheManager>('CacheManager')
