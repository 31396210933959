import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { SelectionData } from '../../models/util.model'

export enum CarrierFreightType {
  f = 'F', // Franco
  a = 'A', // Assegnato
}

export enum CarrierParcelType {
  normal = '0', // normale
  plus = '4', // plus
}

export enum CarrierPaymentMethod {
  cont = 'CONT', // Contante
  ac = 'AC', // Ass Circolare
  ab = 'AB', // Ass Bancario
  ap = 'AP', // Ass Postale
  ass = 'ASS', // Ass CIRC / BANC / POST
  abp = 'ABP', // Ass BANC / POST
  asr = 'ASR', // Ass Come Rilasciato
  arm = 'ARM', // Ass Com Rilasciato Intestato al mittente
  abc = 'ABC', // Ass BANC / CIRC No postale
  asrp = 'ASRP', // Ass Come Rilasciato No Postale
  armp = 'ARMP', // Ass Come Rilasciato Intestato Mittente No Postale
}

export enum CarrierStatus {
  inactive = 'INACTIVE',
  active = 'ACTIVE',
}

export enum CarrierType {
  amazon = 'AMAZON',
  gls = 'GLS',
  glsItaly = 'GLSItaly',
  glsInt = 'GLSINT',
  fdx = 'FDX',
  generic = 'GENERIC',
  auto = 'AUTO',
  brt = 'BRT',
  tnt = 'TNT',
  ups = 'UPS',
  dhl = 'DHL',
  spring = 'SpringXBS',
  sda = 'SDA',
  nexive = 'NEX',
  mil = 'MIL',
  fercam = 'FER',
  sgt = 'SGT',
  poste = 'POSTE_ITALIANE',
  artoni = 'RTN',
  dhlFre = 'DHLFRE',
  dhlPkt = 'DHLPKT',
  dmm = 'DMM',
  ctd = 'CTD',
  spt = 'SPT',
  dbs = 'DBS',
  zst = 'ZST',
  tss = 'TSS',
  dpd = 'DPD',
}

export enum CarrierProvider {
  evologi = 'evologi',
  gsped = 'gsped',
  shippypro = 'shippypro',
}

export enum CarrierIncoterm {
  dap = 'DAP',
  ddp = 'DDP',
}

export interface CarrierShipmentService {
  value: number | string
  name: string
  externalValue?: string
}

export interface Carrier extends Entity {
  name: string
  code: CarrierType
  customName?: string
  gspedClientId?: string
  incoterm?: CarrierIncoterm
  status: CarrierStatus
  logo?: string
  provider: CarrierProvider
  services: CarrierShipmentService[]
  defaultService?: CarrierShipmentService
  goodsDescription?: string
  login: CarrierLogin
  customSettings: CarrierSettings
  brt?: CarrierBrt
}

export interface CarrierLogin {
  // BRT
  VABCCM?: string
  VABLNP?: string
  usernameFtp?: string
  passwordFtp?: string

  // FDX
  accountNumber?: string
  metterNumber?: string
  accessKey?: string
  accessKeyPassword?: string

  // GLS INT
  contactId?: string
  customerNumber?: string
  username?: string
  password?: string

  // GLS ITA
  sedeGls?: string
  codiceClienteGls?: string
  passwordClienteGls?: string
  codiceContrattoGls?: string

  // Nexive
  userIdToImpersonate?: string

  // SDA
  codicePostazione?: string
  codiceCliente?: string

  // TNT
  codiceContratto?: string

  // UPS
  userId?: string
}

export interface CarrierSettings {
  // BRT
  codiceTariffaIT?: string
  codiceTariffaEU?: string
  emailPrenotazioneRitiri?: string

  // GLS
  weight?: number // PesoReale
  freightType?: CarrierFreightType // TipoPorto
  parcelType?: CarrierParcelType // TipoCollo
  paymentMethod?: CarrierPaymentMethod // ModalitaIncasso
  volumeWeightRatio?: number
}

export interface CarrierAvailable extends SelectionData<CarrierType> {
  active: boolean
  externalId?: string | number
  providers: CarrierProvider[]
  trackingLink?: string
  fields?: CarrierFieldAvailable[]
  services?: CarrierShipmentService[]
  servicesConfiguration?: boolean
}

export interface CarrierFieldAvailable {
  field: string
  property?: string
  label: string
  type: string
  options?: { value: any; label: string }[]
  required?: boolean
  providers?: CarrierProvider[]
}

export interface CarrierBrt {
  userId: string
  password: string
  /**
   * Precision 7
   * Scale 0
   */
  senderCustomerCode: number
  /**
   * Precision 3
   * Scale 0
   */
  departureDepot: number
  /**
   * Client contract number
   */
  pricingConditionCode?: string
  /**
   * Alert is sent via email or phone number (if the BRT contract allows it).
   */
  isAlertRequired: boolean
  /**
   * 'DAP' (porto franco)
   * 'EXW' (porto assegnato)
   */
  deliveryFreightTypeCode: 'DAP' | 'EXW'
  /**
   * Max length 2
   * Payment method for Cash On Delivery
   */
  codPaymentType?: 'BM' | 'CM' | 'BB' | 'OM' | 'OC' | 'TM'
  /**
   * Max length 2
   * Goods management code
   */
  parcelsHandlingCode?: string
  /**
   * Min 0.1
   * Precision 5
   * Scale 1
   */
  weight: number
}

export type CarrierSearchField =
  | '_id'
  | 'name'
  | 'customName'
  | 'code'
  | 'provider'
  | 'status'
export type CarrierSortField = 'name' | 'customName' | 'code'

export interface CarrierSearchParams
  extends QueryStringParams<CarrierSortField> {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  customName?: string
  'customName:ne'?: string
  'customName:ct'?: string
  code?: string
  'code:ne'?: string
  'code:ct'?: string
  provider?: CarrierProvider | CarrierProvider[]
  status?: CarrierStatus | CarrierStatus[]
}

export type CarriersTab = 'general' | 'settings' | 'services'
