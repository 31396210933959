import { Pipe, PipeTransform } from '@angular/core'
import { Category } from '../../categories'
import { Product, ProductScope } from '../product.model'

@Pipe({
  name: 'productCatListing',
})
export class ProductCatListingPipe implements PipeTransform {
  transform(
    product: Product,
    scope: ProductScope,
    categories?: Category[],
  ): Category[] {
    if (!product.categories || !categories?.length) {
      return []
    }

    const productCategories = product.categories.find(
      (c) => c.catalogCode === scope.catalogCode,
    )
    const catIds = productCategories?.ids || []

    return categories.filter((c) => catIds.includes(c._id))
  }
}
