import {
  SupplierOrder,
  SupplierOrderRow,
} from '../../supplier-orders/supplier-order.model'
import { Product, ProductSupplierOrder } from '../product.model'

export function parseProductSupplierOrders(
  product: Product,
  supplierOrders: SupplierOrder[],
  warehouseId?: string
): ProductSupplierOrder[] {
  // Filter warehouse orders
  const warehouseOrders = supplierOrders.filter((order) =>
    warehouseId ? order.warehouseId === warehouseId : true
  )

  return warehouseOrders.map((order) => {
    const orderedQty = getOrderedQty(product, order)
    const receivedQty = getReceivedQty(product, order)

    return {
      ...order,
      orderedQty,
      receivedQty,
    }
  })
}

function getOrderedQty(product: Product, supplierOrder: SupplierOrder): number {
  const rows = supplierOrder.rows.filter((r) => r.product._id === product._id)
  return rows.reduce(
    (total: number, row: SupplierOrderRow) => total + row.orderedQty,
    0
  )
}

function getReceivedQty(
  product: Product,
  supplierOrder: SupplierOrder
): number {
  const rows = supplierOrder.rows.filter((r) => r.product._id === product._id)
  return rows.reduce(
    (total: number, row: SupplierOrderRow) => total + row.receivedQty,
    0
  )
}
