import { defaultsDeep, cloneDeep, uniq, get } from 'lodash'
import { sortByKey } from '@evologi/shared/util-toolkit'

import {
  ViewSearchParams,
  View,
  ViewTarget,
  ViewExternalData,
  ViewExternalKey,
  ViewTargetParam,
  ViewParam,
  ViewNotification,
  ViewField,
} from '../view.model'
import { VIEW_DEFAULT_CODE } from '../view.const'
import { PRODUCT_FIELDS, PRODUCT_PARAMS } from '../../products'
import { ORDER_FIELDS } from '../../orders'
import { SUPPLIER_ORDER_FIELDS } from '../../supplier-orders'
import { GOODS_RECEIVE_FIELDS } from '../../goods-receives'
import { Attribute } from '../../attributes'
import { QueryStringFilter } from '../../../models/query-string.model'
import { Field } from '../../../models/field.model'
import { DialogParams } from '../../../models/notification.model'
import { GOODS_RETURN_FIELDS } from '../../goods-returns'

// Initial states

const VIEW_INITIAL_STATE: Partial<View> = {
  name: undefined,
  target: undefined,
  defaultFor: [],
  isShared: false,
}

/**
 * Initialize view with default values
 * @param view - the partial view
 * @returns the view complete
 */
export function initView(view: Partial<View> = {}): View {
  return defaultsDeep(cloneDeep(view), VIEW_INITIAL_STATE)
}

/**
 * Initialize default view by target
 * @param target - the view target
 * @returns the view complete
 */
export function initDefaultView(target: ViewTarget, viewType: string): View {
  const fields = getViewFields(target)
  return initView({
    _id: VIEW_DEFAULT_CODE,
    name: 'Default',
    target,
    fields: fields
      .filter((field) => field.defaultViews?.includes(viewType))
      .map((field) => field.field),
  })
}

/**
 * Get all view attributes codes
 * @param view - the view
 * @returns the array with attributes codes
 */
export function getViewAttributeCodes(view: View): string[] {
  let attributeCodes: string[] = []

  // Fields
  if (view.fields) {
    attributeCodes = view.fields
      .filter((field) => field.startsWith('attribute_'))
      .map((field) => field.replace('attribute_', ''))
  }

  // Filters
  if (view.filters) {
    attributeCodes = [
      ...attributeCodes,
      ...view.filters
        .filter((filter) => filter.property.startsWith('attribute_'))
        .map((filter) => filter.property.replace('attribute_', '')),
    ]
  }

  // Sorting
  if (view.ordering) {
    attributeCodes = [
      ...attributeCodes,
      ...view.ordering
        .filter((order) => order.property.startsWith('attribute_'))
        .map((order) => order.property.replace('attribute_', '')),
    ]
  }

  return uniq(attributeCodes)
}

/**
 * Get all view attributes codes
 * @param view - the view
 * @returns the array with external data
 */
export function getViewExternalData(view: View): Partial<ViewExternalData>[] {
  let viewExtData: Partial<ViewExternalData>[] = []

  // Filters
  if (view.filters) {
    viewExtData = view.filters
      .filter((f) => f.property in ViewExternalKey)
      .filter((f) => !!f.value)
      .map((f) => ({
        property: f.property as ViewExternalKey,
        value: f.value,
      }))
  }

  // Params
  if (view.params) {
    viewExtData = [
      ...viewExtData,
      ...view.params
        .filter((f) => f.property in ViewExternalKey)
        .filter((f) => !!f.value)
        .map((f) => ({
          property: f.property as ViewExternalKey,
          value: f.value,
        })),
    ]
  }

  return viewExtData
}

/**
 * Parse a property and the relative entity into ViewExternalData
 * @param property - the property key
 * @param entity - the entity
 * @returns the view external data
 */
export function parseViewExternalData(
  property: ViewExternalKey,
  entity: any,
): ViewExternalData {
  switch (property) {
    case ViewExternalKey.brandId:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
    case ViewExternalKey.catalogCode:
      return {
        property,
        value: get(entity, 'code') as string,
        name: get(entity, 'name.default') as string,
        entity,
      }
    case ViewExternalKey.categories:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name.default') as string,
        entity,
      }
    case ViewExternalKey['header.channel']:
    case ViewExternalKey['externalSKUs.channelId']:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
    case ViewExternalKey.kitIds:
    case ViewExternalKey.parentId:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name.default') as string,
        entity,
      }
    case ViewExternalKey.manufacturerId:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
    case ViewExternalKey.supplierId:
    case ViewExternalKey['suppliers.supplierId']:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'businessName') as string,
        entity,
      }
    case ViewExternalKey.assignedWarehouseId:
    case ViewExternalKey.warehouseId:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
    case ViewExternalKey.carrierId:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
    case ViewExternalKey['header.paymentType']:
      return {
        property,
        value: get(entity, '_id') as string,
        name: get(entity, 'name') as string,
        entity,
      }
  }
}

/**
 * Return the array of fields available for the view
 * @param target - the view target
 * @returns the array of fields
 */
export function getViewFields(
  target: ViewTarget,
  sortBy?: 'field' | 'label',
): Field[] {
  let viewFields: Field[] = []
  switch (target) {
    case ViewTarget.products:
      viewFields = PRODUCT_FIELDS
      break
    case ViewTarget.orders:
      viewFields = ORDER_FIELDS
      break
    case ViewTarget.supplierOrders:
      viewFields = SUPPLIER_ORDER_FIELDS
      break
    case ViewTarget.goodsReceives:
      viewFields = GOODS_RECEIVE_FIELDS
      break
    case ViewTarget.goodsReturns:
      viewFields = GOODS_RETURN_FIELDS
      break
    default:
      break
  }

  return sortBy ? sortByKey(viewFields, 'label') : viewFields
}

/**
 * Return the array of params available for the view
 * @param target - the view target
 * @returns the array of params
 */
export function getViewTargetParams(
  target: ViewTarget,
): ViewTargetParam[] | undefined {
  let viewParams: ViewTargetParam[] = []

  switch (target) {
    case ViewTarget.products:
      viewParams = PRODUCT_PARAMS
      break
    default:
      break
  }

  return viewParams.length ? sortByKey(viewParams, 'name') : undefined
}

/**
 * Return the value of a param by its property
 * @param property - the param property
 * @returns the value of the param
 */
export function getViewParamValue(
  params: ViewParam[],
  property: string,
): string | undefined {
  const param = params.find((p) => p.property === property)
  return param?.value ? String(param?.value) : undefined
}

/**
 * Translate the field of a view
 * @param view - the view
 * @param field - the field
 * @param attributes - the attributes available
 * @returns the label of the field
 */
export function parseViewField(
  field: string,
  target: ViewTarget,
  attributes?: Attribute[],
): string | undefined {
  // Check if it is an attribute
  if (field.startsWith('attribute_')) {
    const attributeCode = field.replace('attribute_', '')
    return attributes?.find((a) => a.code === attributeCode)?.name.default
  }

  const fields = getViewFields(target)
  return fields.find((f) => f.field === field)?.label
}

/**
 * Parse view filter params
 * @param params - the search params
 * @param filter - the filter values
 * @returns the updated search params
 */
export function viewFilterParams(
  params: ViewSearchParams,
  filter: QueryStringFilter<ViewField>,
): ViewSearchParams {
  const searchParams: ViewSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'userId':
      searchParams.userId = filter.value
      break
    case 'target':
      searchParams.target = filter.value
      break
    case 'isShared':
      searchParams.isShared = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse view notification to a message
 * @param notification - the view notification
 * @returns the notification data
 */
export function parseViewNotification(
  notification: ViewNotification,
): DialogParams {
  switch (notification.kind) {
    case 'VIEW_SAVED':
      return {
        message: 'View saved successfully',
        color: 'success',
      }
    case 'VIEW_INCOMPLETE':
      return {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
    case 'VIEW_DELETED':
      return {
        message: 'View deleted successfully',
        color: 'success',
      }
  }
}
