import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver'
import {
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import { ProductFamily } from '../../product-families'
import { User } from '../../users'
import { Catalog, getCatalogValue } from '../../catalogs'
import { Channel } from '../../channels'
import { Supplier } from '../../suppliers'
import {
  Product,
  ProductData,
  ProductFilesData,
  ProductNotification,
  ProductNotificationData,
  ProductScope,
  ProductType,
  ProductVariantsData,
  ProductVariantsManagement,
  ProductVariantsRepository,
  ProductsService,
  addProductVariantAttribute,
  checkProduct,
  checkProductEmptyScope,
  checkProductScope,
  defineProductScopeFamily,
  parseProductVariants,
  removeProductVariantAttribute,
  setProductFiles,
  setProductVariants,
} from '..'
import { ProductDetailRepository } from '../repositories/product-detail.repository'
import { ATTRIBUTE_GROUP_DEFAULT_CODE } from '../../attribute-groups'
import { Attribute } from '../../attributes'
import { File } from '../../files'
import { Tenant } from '../../tenants'
import { isEqual } from 'lodash'

interface ProductDetailState extends ProductVariantsData {
  productId?: string
  product?: Product
  family?: ProductFamily
  users?: User[]
  files?: File[]
  channels?: Channel[]
  suppliers?: Supplier[]
  idsToModify?: string[]
  categoriesRootId?: string
  attributesData?: ProductDetailAttributes
  notification?: ProductNotification
  error?: HttpErrorResponse
  isInitialized: boolean
  toClose: boolean
  toReload: boolean
  isLoading: boolean
  isModified: boolean
}

interface ProductDetailAttributes {
  groupIds?: string[]
  attributeCodes?: string[]
  currentGroupId: string
  currentFilter: string
}

const PRODUCT_DETAIL_INITIAL_STATE: ProductDetailState = {
  attributesData: {
    currentGroupId: ATTRIBUTE_GROUP_DEFAULT_CODE,
    currentFilter: ATTRIBUTE_GROUP_DEFAULT_CODE,
  },
  toClose: false,
  toReload: false,
  isInitialized: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class ProductDetailUseCase extends ComponentStore<ProductDetailState> {
  constructor(
    private productDetailRepository: ProductDetailRepository,
    private productVariantRepository: ProductVariantsRepository,
    private productsService: ProductsService,
  ) {
    super(PRODUCT_DETAIL_INITIAL_STATE)
  }

  /**
   * SELECTORS
   */

  readonly selectState$: Observable<ProductDetailState> = this.select(
    (state) => state,
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectProductId$: Observable<string | undefined> = this.select(
    (state) => state.product?._id,
  )

  readonly selectProduct$: Observable<Product | undefined> = this.select(
    (state) => state.product,
  )

  readonly selectVariantsManagement$: Observable<
    ProductVariantsManagement | undefined
  > = this.select((state) => state.product?.variantsManagement)

  readonly selectProductName$: Observable<string | undefined> = this.select(
    (state) =>
      state.product ? getCatalogValue(state.product.name) : undefined,
  )

  readonly selectProductLoaded$: Observable<Product> = this.selectState$.pipe(
    filter((state) => !state.isLoading && !!state.product),
    // eslint-disable-next-line
    map((state) => state.product!),
  )

  readonly selectProductType$: Observable<ProductType | undefined> =
    this.select((state) => state.product?.productType)

  readonly selectCategoriesRootId$: Observable<string | undefined> =
    this.selectState$.pipe(map((state) => state?.categoriesRootId))

  readonly selectChannels$: Observable<Channel[] | undefined> = this.select(
    (state) => state.channels,
  )

  readonly selectSuppliers$: Observable<Supplier[] | undefined> = this.select(
    (state) => state.suppliers,
  )

  readonly selectFamily$: Observable<ProductFamily | undefined> = this.select(
    (state) => state.family,
  )

  readonly selectFiles$: Observable<File[] | undefined> = this.select(
    (state) => state.files,
  )

  readonly selectAttributes$: Observable<ProductDetailAttributes | undefined> =
    this.select((state) => state.attributesData)

  readonly selectAttributeCurrentGroupId$: Observable<string | undefined> =
    this.selectAttributes$.pipe(map((attributes) => attributes?.currentGroupId))

  readonly selectAttributeCurrentFilter$: Observable<string | undefined> =
    this.selectAttributes$.pipe(map((attributes) => attributes?.currentFilter))

  readonly selectAttributeGroupIds$: Observable<string[] | undefined> =
    this.selectAttributes$.pipe(map((attributes) => attributes?.groupIds))

  readonly selectAttributeCodes$: Observable<string[] | undefined> =
    this.selectAttributes$.pipe(map((attributes) => attributes?.attributeCodes))

  readonly selectUsers$: Observable<User[] | undefined> = this.select(
    (state) => state.users,
  )

  readonly selectIdsToModify$: Observable<string[] | undefined> = this.select(
    (state) => state.idsToModify,
  )

  readonly selectNextProduct$: Observable<string | undefined> =
    this.selectState$.pipe(
      map((state) => {
        const productIndex = state.idsToModify?.findIndex(
          (pr) => pr === state.product?._id,
        )
        return productIndex != undefined && state.idsToModify
          ? state.idsToModify[productIndex + 1]
          : undefined
      }),
    )

  readonly selectNotification$: Observable<ProductNotification | undefined> =
    this.select((state) => state.notification)

  readonly selectError$: Observable<HttpErrorResponse | undefined> =
    this.select((state) => state.error)

  /**
   * EFFECTS
   */

  readonly init$ = this.effect(
    (
      initParams$: Observable<{
        productId: string
        scope?: ProductScope
        attributes?: Attribute[]
        catalogs?: Catalog[]
      }>,
    ) => {
      return initParams$.pipe(
        tap((initParams) => this.setProductId(initParams.productId)),
        withLatestFrom(this.selectState$),
        switchMap(([initParams, state]) => {
          if (!initParams.scope || !initParams.attributes) {
            return EMPTY
          }

          // On change scope
          if (initParams.productId === state.product?._id) {
            this.setProductScope({
              scope: initParams.scope,
              catalogs: initParams.catalogs,
              attributes: initParams.attributes,
            })
            return EMPTY
          }

          return this.productDetailRepository
            .readProduct$(
              initParams.productId,
              initParams.scope,
              initParams.attributes,
              initParams.catalogs,
            )
            .pipe(
              tapResponse(
                (productData) => this.setProductData(productData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly save$ = this.effect(
    (saveParams$: Observable<{ tenant: Tenant; toClose?: boolean }>) => {
      return saveParams$.pipe(
        tap(() => this.setIsLoading(true)),
        withLatestFrom(this.selectState$),
        switchMap(([saveParams, state]) => {
          const product = this.parseStateSaveParams(state)

          if (!product || !saveParams.tenant) {
            return EMPTY
          }

          return this.productDetailRepository
            .saveProduct$(product, saveParams.tenant)
            .pipe(
              tapResponse(
                (productData) =>
                  this.setProductNotification({
                    ...productData,
                    toClose: saveParams.toClose,
                  }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly saveAttributes$ = this.effect((save$: Observable<void>) => {
    return save$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectState$),
      switchMap(([_, state]) => {
        if (!state.parent || !state.isModified) {
          return EMPTY
        }

        return this.productsService.upsert$(state.parent).pipe(
          tapResponse(
            (variantsData) => this.setParent(variantsData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly checkChannels$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectProduct$),
      switchMap(([empty, product]) => {
        if (!product) {
          return EMPTY
        }

        return this.productDetailRepository.loadChannels$(product).pipe(
          tapResponse(
            (channels) => this.setChannels(channels),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly checkSuppliers$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectProduct$),
      switchMap(([empty, product]) => {
        if (!product) {
          return EMPTY
        }

        return this.productDetailRepository.loadSuppliers$(product).pipe(
          tapResponse(
            (suppliers) => this.setSuppliers(suppliers),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly attachFiles$ = this.effect((files$: Observable<File[]>) => {
    return files$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectProduct$),
      switchMap(([files, product]) => {
        if (!product) {
          return EMPTY
        }

        return this.productDetailRepository
          .attachFiles$(product._id, files)
          .pipe(
            tapResponse(
              (res) => res && this.setFiles(res),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly detachFile$ = this.effect((fileId$: Observable<string>) => {
    return fileId$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectProduct$),
      switchMap(([fileId, product]) => {
        if (!product) {
          return EMPTY
        }

        return this.productDetailRepository
          .detachFile$(product._id, fileId)
          .pipe(
            tapResponse(
              (res) => res && this.setFiles(res),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly printLabel$ = this.effect(
    (
      printData$: Observable<{
        printerId: string
        qty: number
        barcode?: string
      }>,
    ) => {
      return printData$.pipe(
        tap(() => this.setIsLoading(true)),
        withLatestFrom(this.selectProduct$),
        switchMap(([printData, product]) => {
          if (!product) {
            return EMPTY
          }

          return this.productsService.printLabel$(product._id, printData).pipe(
            tapResponse(
              () =>
                this.setNotification({
                  kind: 'LABEL_PRINTED',
                }),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
        }),
      )
    },
  )

  readonly downloadLabel$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectProduct$),
      switchMap(([empty, product]) => {
        if (!product) {
          return EMPTY
        }

        return this.productsService.downloadLabel$(product).pipe(
          tap((res) => saveAs(res.blob, res.label)),
          tap(() => this.setIsLoading(false)),
        )
      }),
    )
  })

  /**
   * REDUCERS
   */

  readonly setProductId = this.updater(
    (state, productId: string): ProductDetailState => ({
      ...state,
      productId,
      notification: undefined,
      error: undefined,
      isLoading: true,
      isModified: false,
      toClose: false,
    }),
  )

  private setProductNotification = this.updater(
    (state, data: ProductNotificationData): ProductDetailState => {
      return {
        ...state,
        product: state.product
          ? {
              ...state.product,
              _rev: data.product._rev,
            }
          : state.product,
        notification: data.notification,
        isLoading: false,
        toClose: data.toClose || false,
        isModified: data.notification?.kind !== 'PRODUCT_SAVED',
      }
    },
  )

  readonly setProductScope = this.updater(
    (
      state,
      productScope: {
        scope: ProductScope
        attributes?: Attribute[]
        catalogs?: Catalog[]
      },
    ): ProductDetailState => {
      const { scope, catalogs, attributes } = productScope
      const catalog = catalogs?.find((c) => c.code === scope.catalogCode)

      return {
        ...state,
        product: checkProductScope(
          state.product,
          scope,
          state.family,
          attributes,
        ),
        categoriesRootId: catalog?.categoryId,
        attributesData: state.attributesData
          ? {
              currentGroupId:
                state.attributesData.currentGroupId ||
                ATTRIBUTE_GROUP_DEFAULT_CODE,
              currentFilter: ATTRIBUTE_GROUP_DEFAULT_CODE,
              groupIds: catalog?.attributeGroupIds
                ? [ATTRIBUTE_GROUP_DEFAULT_CODE, ...catalog.attributeGroupIds]
                : undefined,
            }
          : state.attributesData,
      }
    },
  )

  readonly setProductData = this.updater(
    (state, productData: ProductData): ProductDetailState => {
      return {
        ...state,
        product: productData.product,
        files: productData.files,
        family: productData.family,
        channels: productData.channels,
        suppliers: productData.suppliers,
        users: productData.users,
        categoriesRootId: productData.catalog?.categoryId,
        attributesData: {
          currentGroupId:
            productData.family?.attributeGroupId ||
            ATTRIBUTE_GROUP_DEFAULT_CODE,
          currentFilter: ATTRIBUTE_GROUP_DEFAULT_CODE,
          groupIds: productData.catalog?.attributeGroupIds
            ? [
                ATTRIBUTE_GROUP_DEFAULT_CODE,
                ...productData.catalog.attributeGroupIds,
              ]
            : undefined,
        },
        kits: productData.kits,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setChannels = this.updater(
    (state, channels: Channel[] | undefined): ProductDetailState => ({
      ...state,
      channels: [...(state.channels || []), ...(channels || [])],
    }),
  )

  readonly setSuppliers = this.updater(
    (state, suppliers: Supplier[] | undefined): ProductDetailState => ({
      ...state,
      suppliers: [...(state.suppliers || []), ...(suppliers || [])],
    }),
  )

  readonly updateProduct = this.updater(
    (state, product: Product): ProductDetailState => ({
      ...state,
      product,
      isLoading: false,
      isModified: true,
    }),
  )

  readonly setVariantsManagement = this.updater(
    (
      state,
      variantsManagement: ProductVariantsManagement | undefined,
    ): ProductDetailState => {
      if (isEqual(state.product?.variantsManagement, variantsManagement)) {
        return state
      }

      return {
        ...state,
        product: state.product
          ? {
              ...state.product,
              variantsManagement,
            }
          : state.product,
        isModified: true,
      }
    },
  )

  readonly setCategoriesRootId = this.updater(
    (state, categoriesRootId: string | undefined): ProductDetailState => {
      return {
        ...state,
        categoriesRootId,
      }
    },
  )

  readonly setFiles = this.updater(
    (state, filesData: ProductFilesData): ProductDetailState => ({
      ...state,
      product: state.product
        ? {
            ...state.product,
            updatedAt: filesData.product.updatedAt,
            files: filesData.product.files,
            _rev: filesData.product._rev,
          }
        : state.product,
      files: filesData.files,
      isModified: state.isModified,
      isLoading: false,
    }),
  )

  readonly updateFiles = this.updater(
    (state, files: File[]): ProductDetailState => ({
      ...state,
      product: state.product
        ? setProductFiles(state.product, files)
        : state.product,
      files: files,
      isModified: true,
    }),
  )

  readonly setAttributeCurrentGroup = this.updater(
    (state, groupId: string): ProductDetailState => {
      return {
        ...state,
        attributesData: state.attributesData
          ? {
              ...state.attributesData,
              currentGroupId: groupId,
            }
          : state.attributesData,
      }
    },
  )

  readonly setAttributeCurrentFilter = this.updater(
    (state, attributeFilter: string): ProductDetailState => {
      return {
        ...state,
        attributesData: state.attributesData
          ? {
              ...state.attributesData,
              currentFilter: attributeFilter,
            }
          : state.attributesData,
      }
    },
  )

  readonly addChannel = this.updater(
    (state, channel: Channel): ProductDetailState => ({
      ...state,
      channels: [...(state.channels || []), channel],
      isModified: true,
    }),
  )

  readonly setFamily = this.updater(
    (
      state,
      familyData: { family?: ProductFamily; scope?: ProductScope },
    ): ProductDetailState => {
      return {
        ...state,
        product: state.product
          ? {
              ...state.product,
              family:
                familyData.family && familyData.scope
                  ? defineProductScopeFamily(
                      state.product.family,
                      familyData.family,
                      familyData.scope,
                    )
                  : undefined,
            }
          : state.product,
        family: familyData.family,
        attributesData: {
          currentGroupId:
            familyData.family?.attributeGroupId || ATTRIBUTE_GROUP_DEFAULT_CODE,
          currentFilter: ATTRIBUTE_GROUP_DEFAULT_CODE,
        },
        isModified: true,
      }
    },
  )

  readonly setIdsToModify = this.updater(
    (state, idsToModify: string[]): ProductDetailState => {
      return {
        ...state,
        idsToModify,
      }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): ProductDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setNotification = this.updater(
    (
      state,
      notification: ProductNotification | undefined,
    ): ProductDetailState => ({
      ...state,
      notification,
      isLoading: false,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ProductDetailState => ({
      ...state,
      error,
      isLoading: false,
    }),
  )

  /**
   * UTILITIES
   */

  private parseStateSaveParams(state: ProductDetailState): Product | undefined {
    if (!state.product) {
      return undefined
    }

    return checkProductEmptyScope(checkProduct(state.product))
  }

  readonly set$ = this.effect((product$: Observable<Product>) => {
    return product$.pipe(
      switchMap((product) => {
        return this.productVariantRepository.loadVariantsData$(product).pipe(
          tapResponse(
            (variantsData) => this.setVariantsData(variantsData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  private setVariantsData = this.updater(
    (state, variants: ProductVariantsData | undefined): ProductDetailState => {
      return {
        ...state,
        ...variants,
        isLoading: false,
      }
    },
  )

  readonly selectParent$: Observable<Product | undefined> = this.select(
    (state) => this._parseStateParent(state),
  )

  private _parseStateParent(state: ProductDetailState): Product | undefined {
    return state.parent
      ? state.parent
      : state.product?.productType === ProductType.virtual
        ? state.product
        : undefined
  }

  readonly selectKits$: Observable<Product[] | undefined> = this.select(
    (state) => state.kits,
  )

  readonly selectChildren$: Observable<Product[] | undefined> =
    this.selectState$.pipe(
      map((state) => {
        const parent = this._parseStateParent(state)
        const children = state.children
        return parent && children
          ? parseProductVariants(parent, children)
          : undefined
      }),
    )

  readonly updateVariants = this.updater(
    (state, variants: Product[]): ProductDetailState => {
      // Update current product if there isn't a parent
      if (!state.parent) {
        return {
          ...state,
          product: state.product
            ? setProductVariants(state.product, variants)
            : state.product,
        }
      }

      return {
        ...state,
        parent: setProductVariants(state.parent, variants),
        isModified: true,
      }
    },
  )

  readonly createVariant$ = this.effect((newVariant$: Observable<Product>) => {
    return newVariant$.pipe(
      withLatestFrom(this.selectParent$),
      switchMap(([variant, parent]) => {
        if (!parent) {
          return EMPTY
        }

        return this.productVariantRepository
          .createVariant$(parent._id, variant)
          .pipe(
            tapResponse(
              (variantsData) => this.setVariantsData(variantsData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly assignVariants$ = this.effect((variants$: Observable<Product[]>) => {
    return variants$.pipe(
      withLatestFrom(this.selectParent$),
      switchMap(([variants, parent]) => {
        if (!variants.length || !parent) {
          return EMPTY
        }

        return this.productVariantRepository
          .assignVariants$(parent._id, variants)
          .pipe(
            tapResponse(
              (variantsData) => this.setVariantsData(variantsData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly assignParent$ = this.effect((parent$: Observable<Product>) => {
    return parent$.pipe(
      tap((_) => this.setIsLoading(true)),
      withLatestFrom(this.selectState$),
      switchMap(([parent, state]) => {
        if (!state.product) {
          return EMPTY
        }

        return this.productVariantRepository
          .setParent$(state.product._id, parent._id)
          .pipe(
            tapResponse(
              (variantsData) => this.setVariantsData(variantsData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly removeParent$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectState$),
      switchMap(([empty, state]) => {
        if (!state.parent || !state.product) {
          return EMPTY
        }

        return this.productVariantRepository
          .unsetParent$(state.product._id, state.parent._id)
          .pipe(
            tapResponse(
              (response) => this.unsetVariantsData(response?.product),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  private unsetVariantsData = this.updater(
    (state, product?: Product): ProductDetailState => {
      return {
        ...state,
        product: product,
        parent: undefined,
        children: undefined,
        isLoading: false,
      }
    },
  )

  readonly addAttribute = this.updater((state): ProductDetailState => {
    if (!state.parent) {
      return {
        ...state,
        product: state.product
          ? addProductVariantAttribute(state.product)
          : state.product,
      }
    }

    return {
      ...state,
      parent: addProductVariantAttribute(state.parent),
      isModified: true,
    }
  })

  readonly removeAttribute = this.updater(
    (state, index: number): ProductDetailState => {
      if (!state.parent) {
        return {
          ...state,
          product: state.product
            ? removeProductVariantAttribute(state.product, index)
            : state.product,
        }
      }

      return {
        ...state,
        parent: removeProductVariantAttribute(state.parent, index),
        isModified: true,
      }
    },
  )

  private setParent = this.updater(
    (state, parent: Product): ProductDetailState => {
      return {
        ...state,
        parent,
        isModified: false,
        isLoading: false,
      }
    },
  )
}
