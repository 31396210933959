import { Pipe, PipeTransform } from '@angular/core'
import { ProductWrapper } from '../product.model'

@Pipe({
  name: 'wrapperVolumeWeight',
})
export class WrapperVolumeWeightPipe implements PipeTransform {
  transform(wrapper: ProductWrapper): string {
    return `${(wrapper.dimensions?.volume ?? 0).toFixed(1)} m³ / ${(wrapper.dimensions?.weight ?? 0).toFixed(1)} kg`
  }
}
