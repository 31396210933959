import { createReducer, on } from '@ngrx/store'
import { AuthState } from './auth.state'
import {
  AuthNotification,
  getUserTenantSettings,
} from '@evologi/shared/data-access-api'

import * as AuthActions from './auth.actions'

const initialState: AuthState = {
  isLoading: false,
  isReviving: false,
}

export const authReducers = createReducer(
  initialState,

  // Initializing reducers

  on(
    AuthActions.userInitialized,
    AuthActions.stateInitialized,
    (state): AuthState => {
      return {
        ...state,
        isReviving: false,
        isLoading: false,
        error: undefined,
        notification: undefined,
      }
    },
  ),

  // User actions reducers

  on(AuthActions.userLoggedIn, (): AuthState => {
    return {
      isLoading: true,
      isReviving: false,
    }
  }),

  on(AuthActions.userLoggedInToken, (state, action): AuthState => {
    return {
      ...state,
      rt: action.refreshToken,
    }
  }),

  on(AuthActions.userLoggedInSuccess, (state, action): AuthState => {
    return {
      ...state,
      user: action.user,
      isLoading: false,
    }
  }),

  on(AuthActions.userLoggedInFailed, (state, action): AuthState => {
    return {
      isLoading: false,
      isReviving: false,
      error: action.error,
      notification:
        action.error.error === 'SESSION_CONFLICT'
          ? AuthNotification.from({ code: 'SESSION_CONFLICT' })
          : undefined,
    }
  }),

  on(
    AuthActions.userLoggedOutSuccess,
    AuthActions.userExpel,
    (): AuthState => ({ ...initialState }),
  ),

  on(AuthActions.tenantSelected, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.tenantSelectedToken, (state, action): AuthState => {
    return {
      ...state,
      at: action.accessToken,
    }
  }),

  on(AuthActions.tenantSelectedSuccess, (state, action): AuthState => {
    if (!state.user) {
      return state
    }

    const tenant = action.tenant
    const tenantSettings = getUserTenantSettings(
      state.user,
      tenant._id as string,
    )
    const isPrintingEnabled =
      !!tenant.defaults?.printNodeKey && !tenantSettings?.printDisabled

    return {
      ...state,
      tenant: action.tenant,
      settings: tenantSettings,
      permissions: action.permissions,
      warehouse: action.warehouse,
      pickupPoint: action.pickupPoint,
      supplier: action.supplier,
      isPrintingEnabled,
      notification: AuthNotification.from({ code: 'TENANT_SELECTED' }),
      isLoading: false,
    }
  }),

  on(AuthActions.tenantSelectedFailed, (state, action): AuthState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    }
  }),

  on(AuthActions.userSaved, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.userSavedSuccess, (state, action): AuthState => {
    return {
      ...state,
      user: action.user,
      settings: action.tenantId
        ? getUserTenantSettings(action.user, action.tenantId)
        : undefined,
      notification: AuthNotification.from({ code: 'USER_SAVED' }),
      isLoading: false,
    }
  }),

  on(AuthActions.userSavedFailed, (state, action): AuthState => {
    return {
      ...state,
      error: action.error,
      isLoading: false,
    }
  }),

  on(AuthActions.userPasswordRecovery, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.userPasswordRecoverySuccess, (state): AuthState => {
    return {
      ...state,
      isLoading: false,
      notification: AuthNotification.from({ code: 'PASSWORD_RECOVERED' }),
    }
  }),

  on(AuthActions.userPasswordRecoveryFailed, (state, action): AuthState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    }
  }),

  on(AuthActions.userPasswordUpdate, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.userPasswordUpdateSuccess, (state, action): AuthState => {
    return {
      ...state,
      at: action.accessToken,
      rt: action.refreshToken,
      isLoading: false,
      notification: AuthNotification.from({ code: 'PASSWORD_UPDATED' }),
    }
  }),

  on(AuthActions.userPasswordUpdateFailed, (state, action): AuthState => {
    return {
      ...state,
      error: action.error,
      isLoading: false,
    }
  }),

  on(AuthActions.userPasswordChange, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.userPasswordChangeSuccess, (state): AuthState => {
    if (!state.user) {
      return state
    }

    return {
      ...state,
      user: {
        ...state.user,
        forcePasswordChange: false,
      },
      notification: AuthNotification.from({ code: 'PASSWORD_CHANGED' }),
      isLoading: false,
    }
  }),

  on(AuthActions.userPasswordChangeFailed, (state, action): AuthState => {
    return {
      ...state,
      error: action.error,
      isLoading: false,
    }
  }),

  on(AuthActions.tenantSaved, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.tenantSavedSuccess, (state, action): AuthState => {
    const tenant = action.tenant
    const tenantSettings = getUserTenantSettings(
      action.user,
      tenant._id as string,
    )
    const isPrintingEnabled =
      !!tenant.defaults?.printNodeKey && !tenantSettings?.printDisabled

    return {
      ...state,
      tenant: action.tenant,
      isPrintingEnabled,
      notification: AuthNotification.from({ code: 'TENANT_SAVED' }),
      isLoading: false,
    }
  }),

  on(AuthActions.tenantSavedFailed, (state, action): AuthState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    }
  }),

  on(AuthActions.userRevive, (state): AuthState => {
    return {
      ...state,
      isReviving: true,
    }
  }),

  on(AuthActions.userReviveSuccess, (state, action): AuthState => {
    return {
      ...state,
      at: action.accessToken,
      isReviving: false,
    }
  }),

  on(AuthActions.userReviveFailed, (state): AuthState => {
    return {
      ...state,
      isReviving: false,
    }
  }),

  on(AuthActions.userLoggedOut, (state): AuthState => {
    return {
      ...state,
      isLoading: true,
    }
  }),

  on(AuthActions.userLoggedOutSuccess, (state): AuthState => {
    return {
      ...state,
      isLoading: false,
    }
  }),
)
