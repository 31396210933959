import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { Supplier, SupplierSearchParams } from './supplier.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'suppliers'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class SuppliersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new supplier
   * @param supplier - The supplier to create
   * @returns The observable<Supplier> to create the supplier
   */
  create$(supplier: Supplier): Observable<Supplier> {
    return this._create$<Supplier>(supplier)
  }

  /**
   * Read a supplier by ID
   * @param supplierId - The supplier ID
   * @returns The observable<Supplier> for read the supplier
   */
  read$(supplierId: string): Observable<Supplier> {
    return this._read$<Supplier>(supplierId)
  }

  /**
   * Update a supplier by ID
   * @param supplierId - The supplier ID
   * @param supplier - The supplier body to update
   * @returns The observable<Supplier> for update the supplier
   */
  update$(supplierId: string, supplier: Supplier): Observable<Supplier> {
    return this._update$<Supplier>(supplierId, supplier)
  }

  /**
   * Create or update a supplier by ID
   * @param supplierId - The supplier ID
   * @param supplier - The supplier body to update
   * @returns The observable<Supplier> for update the supplier
   */
  upsert$(supplier: Supplier): Observable<Supplier> {
    return this._upsert$<Supplier>(supplier, supplier._id)
  }

  /**
   * Delete a supplier by ID
   * @param supplierId - The supplier ID
   * @returns The observable<Supplier> for delete the supplier
   */
  delete$(supplierId: string): Observable<Supplier> {
    return this._delete$<Supplier>(supplierId)
  }

  /**
   * Search suppliers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Supplier>> for search suppliers
   */
  search$(
    params?: SupplierSearchParams,
    returnAll = false,
  ): Observable<Page<Supplier>> {
    return this._search$<Supplier>(params, returnAll)
  }

  /**
   * List suppliers by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Supplier[]> for list suppliers
   */
  list$(
    params?: SupplierSearchParams,
    returnAll = false,
  ): Observable<Supplier[]> {
    return this._list$<Supplier>(params, returnAll)
  }

  /**
   * Generate suppliers store
   * @param supplierIds - the supplier IDs to load
   * @param suppliers - the suppliers already loaded
   * @returns the Observable<Supplier[]> as store
   */
  store$(supplierIds: string[], suppliers: Supplier[]): Observable<Supplier[]> {
    supplierIds = uniq(supplierIds)
    supplierIds = difference(
      supplierIds,
      suppliers.map((u) => u._id),
    )

    if (supplierIds.length === 0) {
      return of(suppliers)
    }

    return this.list$({ _id: supplierIds }).pipe(
      map((supls) => [...suppliers, ...supls]),
    )
  }
}
