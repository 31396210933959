import { defaultsDeep, cloneDeep } from 'lodash'
import { pad } from '@evologi/shared/util-toolkit'
import { vsprintf } from 'sprintf-js'

import {
  Carrier,
  CarrierSearchField,
  CarrierSearchParams,
  CarrierSortField,
  CarrierStatus,
} from './carrier.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import {
  Address,
  BillingAddress,
  ShippingAddress,
} from '../../models/address.model'
import { CARRIER_TYPES } from './carrier.const'
import { Shipment } from '../orders'

/**
 * Carrier initial state
 */
const CARRIER_INITIAL_STATE: Partial<Carrier> = {
  name: undefined,
  code: undefined,
  status: CarrierStatus.active,
  provider: undefined,
  services: [],
  login: {},
  customSettings: {},
}

/**
 * Initialize a carrier with default options
 * @param carrier - the partial carrier
 * @returns the carrier updated
 */
export function initCarrier(carrier: Partial<Carrier> = {}): Carrier {
  return defaultsDeep(cloneDeep(carrier), CARRIER_INITIAL_STATE)
}

/**
 * Parse carrier sort params into search params
 * @param params - the search params
 * @param sort - the sort values
 * @returns the carrier search params
 */
export function carrierSortParams(
  params: CarrierSearchParams,
  sort: QueryStringSort<CarrierSortField>,
): CarrierSearchParams {
  const searchParams: CarrierSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'customName':
      searchParams.sort = 'customName'
      searchParams.order = sort.order
      break
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse carrier filter params into search params
 * @param params - the search params
 * @param filter - the filter values
 * @returns the carrier search params
 */
export function carrierFilterParams(
  params: CarrierSearchParams,
  filter: QueryStringFilter<CarrierSearchField>,
): CarrierSearchParams {
  const searchParams: CarrierSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break

    case 'customName':
      if (filter.operator === '=') {
        searchParams.customName = filter.value
      } else if (filter.operator === '<>') {
        searchParams['customName:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['customName:ct'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
    case 'provider':
      searchParams.provider = filter.value
      break
    case 'status':
      searchParams.status = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Is carrier address valid
 * @param carrier - the carrier
 * @param address - the address to check
 * @returns the boolean flag that indicates if the address is valid
 */
export function isCarrierAddressValid(
  carrier: Carrier,
  address: ShippingAddress | BillingAddress | Address,
): boolean {
  let isValid = false

  switch (carrier.code) {
    case 'GLS':
      isValid = (address.address1 + (address.address2 || '')).length <= 35
      break
    case 'GENERIC':
      isValid = true
      break
    default:
      isValid = !!address.address1 && address.address1?.length <= 35
      break
  }

  return isValid
}

/**
 * Is carrier generic
 * @param carrier - the carrier
 * @returns the boolean flag that indicates if the carrier is generic
 */
export function isGenericCarrier(carrier?: Carrier): boolean {
  const carrierAv = CARRIER_TYPES.find((c) => c.value === carrier?.code)
  return carrierAv?.value === 'GENERIC'
}

/**
 * Generate carrier tracking URL
 * @param carrier - the carrier
 * @param shipment -  the shipment
 * @returns the tracking URL
 */
export function generateCarrierTrackingUrl(
  carrier: Carrier,
  shipment: Shipment,
): string | undefined {
  const carrierType = CARRIER_TYPES.find((car) => car.value === carrier.code)
  if (
    !shipment.trackingNumbers?.length ||
    !shipment.trackingNumbers[0] ||
    !carrierType?.trackingLink
  ) {
    return
  }

  const trackingNumber = shipment.trackingNumbers[0]
  let trackingUrl

  switch (carrierType?.value) {
    case 'BRT':
    case 'DPD':
      trackingUrl = carrier.brt?.senderCustomerCode
        ? vsprintf(carrierType.trackingLink, [
            trackingNumber.substring(0, 15),
            pad(carrier.brt.senderCustomerCode, 7),
          ])
        : undefined
      break
    case 'AMAZON':
      trackingUrl = carrierType.trackingLink + trackingNumber
      break
    default:
      trackingUrl = vsprintf(carrierType.trackingLink, [trackingNumber])
      break
  }

  return trackingUrl
}
