import { RouterReducerState } from '@ngrx/router-store'
import { InjectionToken } from '@angular/core'

import { AttributesState } from './attributes/attributes.state'
import { AuthState } from './auth/auth.state'
import { CacheState } from './cache/cache.state'
import { CurrenciesState } from './currencies/currencies.state'
import { LocalesState } from './locales/locales.state'
import { ProductsState } from './products/products.state'

export const KEYS_TO_SYNC = ['auth', 'products']

export interface StoreState {
  auth: AuthState
  attributes: AttributesState
  cache: CacheState
  currencies: CurrenciesState
  locales: LocalesState
  products: ProductsState
  router: RouterReducerState
}

export interface StoreConfiguration {
  authenticationRoute: string
  tenantSelectionRoute: string
  authenticatedRoute: string
  passwordChangeRoute: string
  useAuthToken: boolean
  useLocalStorage: boolean
  privateKey: string
}

export const STORE_CONFIGURATION = new InjectionToken<StoreConfiguration>(
  'StoreConfiguration',
)
