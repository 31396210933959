import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Locale, LocaleSearchParams } from './locale.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'locales'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class LocalesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Search locales by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Locale>> for search locales
   */
  search$(
    params?: LocaleSearchParams,
    returnAll = false,
  ): Observable<Page<Locale>> {
    return this._search$<Locale>(params, returnAll)
  }

  /**
   * List locales by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Locale[]> for list locales
   */
  list$(params?: LocaleSearchParams, returnAll = false): Observable<Locale[]> {
    return this._list$<Locale>(params, returnAll)
  }

  /**
   * Read a locale by ID
   * @param localeId - the locale ID
   * @returns the observable for read the locale
   */
  read$(localeId: string): Observable<Locale> {
    return this._read$<Locale>(localeId)
  }

  /**
   * Find a locale by params
   * @param params - The search params
   * @returns the observable<Locale> for find a locale
   */
  readOne$(params?: LocaleSearchParams): Observable<Locale> {
    return this._readOne$<Locale>(params)
  }
}
