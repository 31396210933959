import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { StoredenPayment, StoredenStore } from './storeden.model'

const MODEL = 'storeden'
const VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class StoredenService {
  private path: string
  private version: string
  private model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    private http: HttpClient,
  ) {
    this.path = `${config.apiUrl}/${SDK_SETTINGS.proxyPath}`
    this.version = VERSION
  }

  /**
   * Load store info
   * @param key - the store key
   * @param exchange - the store exchange key
   * @returns the observable for get storeden store info
   */
  getStore$(key: string, exchange: string): Observable<StoredenStore> {
    return this.http.get<StoredenStore>(
      `${this.path}/${this.version}/${this.model}/store/info.json`,
      {
        headers: { key, exchange },
      },
    )
  }

  /**
   * Load store languages
   * @param key - the store key
   * @param exchange - the store exchange key
   * @returns the observable for get storeden languages
   */
  getStoreLanguages$(key: string, exchange: string): Observable<unknown> {
    return this.http.get(
      `${this.path}/${this.version}/${this.model}/store/languages.json`,
      {
        headers: { key, exchange },
      },
    )
  }

  /**
   * Load store payments
   * @param key - the store key
   * @param exchange - the store exchange key
   * @returns the observable for get storeden payments
   */
  getStorePayments$(
    key: string,
    exchange: string,
  ): Observable<StoredenPayment[]> {
    return this.http.get<StoredenPayment[]>(
      `${this.path}/${this.version}/${this.model}/payments/list.json`,
      {
        headers: { key, exchange },
      },
    )
  }
}
