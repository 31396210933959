import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Location,
  LocationSearchField,
  LocationSearchParams,
  LocationSortField,
  LocationType,
} from './location.model'
import { LocationModel } from '../location-models/location-model.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const LOCATION_INITIAL_STATE: Partial<Location> = {
  type: LocationType.pick,
  ancestors: [],
  size: {},
}

const MODEL_INITIAL_STATE: Partial<LocationModel> = {
  height: 1,
  length: 1,
  width: 1,
}

// Init

export function initLocation(location: Partial<Location> = {}): Location {
  return defaultsDeep(cloneDeep(location), LOCATION_INITIAL_STATE)
}

export function initLocationModel(
  model: Partial<LocationModel> = {}
): LocationModel {
  return defaultsDeep(cloneDeep(model), MODEL_INITIAL_STATE)
}

// Model

export function getLocationModelVolume(model: LocationModel): number {
  if (
    model.height === undefined ||
    model.length === undefined ||
    model.width === undefined
  ) {
    return 0
  }

  const total =
    (model.height / 100) * (model.length / 100) * (model.width / 100)

  return +total.toFixed(6)
}

// Search

export function locationSortParams(
  params: LocationSearchParams,
  sort: QueryStringSort<LocationSortField>
): LocationSearchParams {
  const searchParams: LocationSearchParams = {}

  switch (sort.field) {
    case 'path':
      searchParams.sort = 'path'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function locationFilterParams(
  params: LocationSearchParams,
  filter: QueryStringFilter<LocationSearchField>
): LocationSearchParams {
  const searchParams: LocationSearchParams = {}

  switch (filter.field) {
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams.warehouseId = filter.value
      }
      break
    case 'pickingListId':
      if (filter.value !== null) {
        searchParams.pickingListId = filter.value
      }
      break
    case 'type':
      if (filter.value !== null) {
        searchParams.type = [filter.value]
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      }
      break
    case 'singular':
      if (filter.operator === '=') {
        searchParams.singular = filter.value
      }
      break
    case 'path':
      if (filter.operator === '=') {
        searchParams.path = filter.value
      } else if (filter.operator === '<>') {
        searchParams['path:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['path:ct'] = filter.value
      } else if (filter.operator === 'startswith') {
        searchParams['path:sw'] = filter.value
      } else if (filter.operator === 'endswith') {
        searchParams['path:ew'] = filter.value
      }
      break
    case 'modelId':
      if (filter.operator === '=') {
        searchParams.modelId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['modelId:ne'] = filter.value
      }
      break
    case 'isLeaf':
      searchParams.isLeaf = filter.value
      break
    case 'isPlaced':
      searchParams.isPlaced = filter.value
      break
    case 'forScraps':
      searchParams.forScraps = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
