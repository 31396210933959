import { PermissionsScope } from '../policies/policy.model'
import { InvoiceType } from './invoice.model'

export const INVOICE_SCOPE: PermissionsScope = 'invoices'
export const INVOICE_FRAGMENT = 'invoice'

export const INVOICE_TYPES = [
  {
    code: InvoiceType.invoice,
    name: 'Fattura',
  },
  {
    code: InvoiceType.creditNote,
    name: 'Nota di credito',
  },
  {
    code: InvoiceType.receipt,
    name: 'Scontrino',
  },
  {
    code: InvoiceType.receiptFiscalCode,
    name: 'Scontrino con CF',
  },
  {
    code: InvoiceType.return,
    name: 'Reso',
  },
  {
    code: InvoiceType.generic,
    name: 'Generico',
  },
]
