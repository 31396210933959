import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Movement, MovementSearchParams } from './movement.model'
import { Page } from '../../models/util.model'

const MODEL = 'movements'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class MovementsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a movement by ID
   * @param movementId - The movement ID
   * @returns The observable<Movement> for read the movement
   */
  read$(movementId: string): Observable<Movement> {
    return this._read$<Movement>(movementId)
  }

  /**
   * Search countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Movement>> for search countries
   */
  search$(
    params?: MovementSearchParams,
    returnAll = false,
  ): Observable<Page<Movement>> {
    return this._search$<Movement>(params, returnAll)
  }

  /**
   * List countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Movement[]> for list countries
   */
  list$(
    params?: MovementSearchParams,
    returnAll = false,
  ): Observable<Movement[]> {
    return this._list$<Movement>(params, returnAll)
  }
}
