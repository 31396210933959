import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AdviceService {
  public currentAdvice?: string

  set advice(adv: string | undefined) {
    this.currentAdvice = adv
  }

  get advice(): string | undefined {
    return this.currentAdvice
  }

  resetAdvice() {
    this.currentAdvice = undefined
  }
}
