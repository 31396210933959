import { cloneDeep, defaultsDeep } from 'lodash'
import { Fs } from './fs.model'

// Initial states

const FS_INITIAL_STATE: Partial<Fs> = {
  name: '/',
  path: '/',
  isDirectory: false,
  isEditing: false,
  isOpened: false,
  subfolders: [],
}

// Init

export function initFs(fs: Partial<Fs> = {}): Fs {
  return defaultsDeep(cloneDeep(fs), FS_INITIAL_STATE)
}
