import { createReducer, on } from '@ngrx/store'

import { parseItems } from '../../libs/state.lib'
import * as CacheActions from './cache.actions'
import { Cache, CacheState, CacheTenant } from './cache.state'

const initialState: CacheState = {}

export const cacheReducers = createReducer(
  initialState,

  // Lifecycle

  on(CacheActions.destroyCache, (): CacheState => initialState),

  // Crud

  on(
    CacheActions.setItems,
    (
      state: CacheState,
      action: {
        tenantId: string
        cacheKey: string
        items: any[]
        itemFieldKey?: string
        totalCount?: number
      },
    ): CacheState => {
      const tenantCache: CacheTenant = state[action.tenantId] || {}
      const store: Cache = tenantCache[action.cacheKey] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantCache,
          [action.cacheKey]: {
            items: parseItems(action.items, store.items, action.itemFieldKey),
            totalCount: action.totalCount,
          },
        },
      }
    },
  ),

  on(
    CacheActions.setItem,
    (
      state: CacheState,
      action: {
        tenantId: string
        cacheKey: string
        itemKey: string
        item: any
      },
    ): CacheState => {
      const tenantCache: CacheTenant = state[action.tenantId] || {}
      const store: Cache = tenantCache[action.cacheKey] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantCache,
          [action.cacheKey]: {
            ...store,
            items: {
              ...(store.items || {}),
              [action.itemKey]: action.item,
            },
          },
        },
      }
    },
  ),

  on(
    CacheActions.unsetItem,
    (
      state: CacheState,
      action: { tenantId: string; cacheKey: string; itemKey: string },
    ): CacheState => {
      const tenantCache: CacheTenant = state[action.tenantId]

      if (!tenantCache) {
        return state
      }

      const store: Cache = tenantCache[action.cacheKey]

      if (!store || !store.items) {
        return state
      }

      const newItems = Object.keys(store.items).reduce(
        (acc, id) =>
          id !== action.itemKey ? { ...acc, [id]: store.items![id] } : acc,
        {},
      )

      return {
        ...state,
        [action.tenantId]: {
          ...tenantCache,
          [action.cacheKey]: {
            ...store,
            items: newItems,
          },
        },
      }
    },
  ),
)
