import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Tag,
  TagSearchField,
  TagSearchParams,
  TagSortField,
  TagTarget,
} from './tag.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial state

const TAG_INITIAL_STATE: Partial<Tag> = {
  target: TagTarget.products,
}

// Init

export function initTag(tag: Partial<Tag> = {}): Tag {
  return defaultsDeep(cloneDeep(tag), TAG_INITIAL_STATE)
}

// Search

export function tagSortParams(
  params: TagSearchParams,
  sort: QueryStringSort<TagSortField>
): TagSearchParams {
  const searchParams: TagSearchParams = {}

  switch (sort.field) {
    case 'value':
      searchParams.sort = 'value'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function tagFilterParams(
  params: TagSearchParams,
  filter: QueryStringFilter<TagSearchField>
): TagSearchParams {
  const searchParams: TagSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'value':
      if (filter.operator === '=') {
        searchParams.value = filter.value
      } else if (filter.operator === '<>') {
        searchParams['value:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['value:ct'] = filter.value
      }
      break
    case 'target':
      if (filter.value !== null) {
        searchParams.target = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
