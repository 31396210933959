import { defaultsDeep } from 'lodash'
import {
  Product,
  ProductWrapper,
  ProductWrapperDimensions,
} from '../product.model'

/**
 * Wrapper initial state
 */
const PRODUCT_WRAPPER_INITIAL_STATE: Partial<ProductWrapper> = {
  dimensions: {},
  multiplier: 1,
}

/**
 * Initialize a product wrapper
 * @param wrapper - the partial wrapper
 * @returns the product wrapper
 */
export function initProductWrapper(
  wrapper: Partial<ProductWrapper> = {}
): ProductWrapper {
  return defaultsDeep(wrapper, PRODUCT_WRAPPER_INITIAL_STATE)
}

/**
 * Add a wrapper to a product
 * @param wrappers - the product wrappers
 * @param wrapper - the wrapper to add
 * @returns the wrappers updated
 */
export function addProductWrapper(
  wrappers: ProductWrapper[],
  wrapper?: Partial<ProductWrapper>
): ProductWrapper[] {
  const wrap = initProductWrapper(wrapper)
  const currentWrapper = wrappers.find((w) => w.barcode === wrap.barcode)

  if (!currentWrapper) {
    return [...wrappers, wrap]
  }

  return wrappers.map((w) => (w.barcode === wrap.barcode ? wrap : w))
}

/**
 * Remove a wrapper from a product
 * @param wrappers - the product wrappers
 * @param wrapperIndex - the wrapper index
 * @returns the wrappers updated
 */
export function removeProductWrapper(
  wrappers: ProductWrapper[],
  wrapperIndex?: number
): ProductWrapper[] {
  const indexToRemove =
    wrapperIndex !== undefined ? wrapperIndex : wrappers.length - 1
  return wrappers.filter((p, i) => i !== indexToRemove)
}

/**
 * Get product wrappers quantity label
 * @param product - the product
 * @param orderedQty - the ordered quantity
 * @returns the
 */
export function getProductWrappersQtyLabel(
  product: Product,
  orderedQty: number
): string | undefined {
  if (!product.wrappers?.length) {
    return undefined
  }

  // Get all wrappers used
  const wrappers: { label: string; wrapperQty: number }[] = []
  const leftPieces = product.wrappers.reduce((leftQty, wrapper) => {
    // Get the single wrapper pieces quantity
    const wrapperQty = getProductWrapperPiecesQty(product, wrapper)

    // The amount of wrappers
    const wrappersAmount = Math.trunc(leftQty / wrapperQty)

    if (wrappersAmount > 0) {
      wrappers.push({ label: wrapper.label, wrapperQty: wrappersAmount })
    }

    // Return the left qty
    return leftQty % wrapperQty
  }, orderedQty)

  let label = wrappers.map((w) => `${w.wrapperQty} ${w.label}`).join(' + ')
  if (leftPieces > 0) {
    label += wrappers.length ? ` + ` : ''
    label += `${leftPieces} PZ`
  }

  return label
}

/**
 * Get product wrapper quantity of pieces contained
 * @param product - the product
 * @param wrapper - the product wrapper
 * @returns the quantity of pieces contained in wrapper
 */
export function getProductWrapperPiecesQty(
  product: Product,
  wrapper: ProductWrapper
): number {
  const barcode = product.barcodes?.find((b) => wrapper.barcode === b.value)
  return barcode?.qty || 1
}

/**
 * Get product wrapper by barcode
 * @param product - the product
 * @param barcode - the barcode
 * @returns the wrapper if founded
 */
export function getProductWrapperByBarcode(
  product: Product,
  barcode: string
): ProductWrapper | undefined {
  return product.wrappers?.find((w) => w.barcode === barcode)
}

/**
 * Update wrapper volume
 * @param wrapper - the wrapper to update
 * @returns the wrapper updated
 */
export function updateProductWrapperVolume(
  wrapper: ProductWrapper
): ProductWrapper {
  return {
    ...wrapper,
    dimensions: wrapper.dimensions
      ? updateProductWrapperDimensions(wrapper.dimensions)
      : undefined,
  }
}

/**
 * Update product wrapper dimensions
 * @param dimensions - the dimensions of the wrapper
 * @returns the dimensions updated
 */
export function updateProductWrapperDimensions(
  dimensions: ProductWrapperDimensions
): ProductWrapperDimensions {
  const { height, length, width } = dimensions

  if (height === undefined || length === undefined || width === undefined) {
    return dimensions
  }

  const volume = (height / 100) * (length / 100) * (width / 100)

  return {
    ...dimensions,
    volume: +volume.toFixed(6),
  }
}
