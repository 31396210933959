import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import { CustomerTab } from './customer.model'

export const CUSTOMER_FRAGMENT = 'customer'
export const CUSTOMER_SCOPE: PermissionsScope = 'customers'

export const CUSTOMER_TABS: SelectionData<CustomerTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'addresses',
    label: 'Indirizzi di spedizione',
  },
]
