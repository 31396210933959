import { Inject, Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'
import {
  PermissionsCheckMode,
  PermissionsLevel,
  PermissionsScope,
} from '../../policies'

@Pipe({
  name: 'auth',
})
export class AuthPipe implements PipeTransform {
  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
  ) {}

  transform(
    scopes: PermissionsScope[] | PermissionsScope | undefined,
    level: PermissionsLevel = 'READ_ONLY',
    mode: PermissionsCheckMode = 'ONE',
  ): Observable<boolean> {
    if (!scopes) {
      return of(true)
    }

    return Array.isArray(scopes)
      ? this.authManager.isMultiAllowed$(scopes, level, mode)
      : this.authManager.isAllowed$(scopes, level)
  }
}
