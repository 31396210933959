import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { omit } from 'lodash'
import {
  filter,
  map,
  mergeMap,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import {
  Product,
  ProductField,
  ProductHistoricStockData,
  ProductPageData,
  ProductScope,
  ProductSearchParams,
  ProductsListingCategories,
  ProductsListingSearchData,
  ProductsListingState,
} from '../product.model'
import { ProductsListingRepository } from '../repositories/products-listing.repository'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { Attribute } from '../../attributes'

const PRODUCTS_INITIAL_STATE: ProductsListingState = {
  isLoading: false,
  isInitialized: false,
  toReload: false,
}

interface ProductsFilterData {
  params?: ProductSearchParams
  count?: number
  scope?: ProductScope
}

@Injectable()
export class ProductsListingUseCase extends ComponentStore<ProductsListingState> {
  constructor(private productsListingRepository: ProductsListingRepository) {
    super(PRODUCTS_INITIAL_STATE)
  }

  /**
   * SELECTORS
   */

  readonly selectState$: Observable<ProductsListingState> = this.select(
    (state) => state,
  )

  readonly selectSearchString$: Observable<string | undefined> = this.select(
    (state) => state.searchString,
  )

  readonly selectFilters$: Observable<ProductSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectSearchFilters$: Observable<ProductSearchParams | undefined> =
    this.selectFilters$.pipe(
      mergeMap((filters) =>
        this.selectSearchString$.pipe(
          map((q) => ({ ...(q ? { q } : {}), ...(filters ? filters : {}) })),
        ),
      ),
    )

  readonly selectFilterData$: Observable<ProductsFilterData> =
    this.selectState$.pipe(map((state) => this.parseStateFilterData(state)))

  readonly selectCustomFilters$: Observable<ProductSearchParams | undefined> =
    this.select((state) => state.customFilters)

  readonly selectSearchKeys$: Observable<ProductSearchParams | undefined> =
    this.select((state) => state.searchKeys)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> =
    this.selectSearchFilters$.pipe(
      filter((filters) => !!filters),
      map((filters) =>
        isQueryStringFiltered(filters || {}, [
          'catalogCode',
          'locale',
          'warehouseId',
          'supplierId',
        ]),
      ),
    )

  readonly selectProducts$: Observable<ProductPageData[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectProductsSelected$: Observable<Product[] | undefined> =
    this.select((state) => state.selected)

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectFields$: Observable<ProductField[] | undefined> = this.select(
    (state) => state.fields,
  )

  readonly selectPage$: Observable<Page<ProductPageData>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectProducts$,
          this.selectTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true },
        ),
      ),
    )

  readonly selectHistoricDate$: Observable<string | undefined> =
    this.selectState$.pipe(map((state) => state.historic?.date))

  readonly selectCategories$: Observable<
    ProductsListingCategories | undefined
  > = this.selectState$.pipe(
    filter((state) => !!state.isInitialized),
    map((state) => state.categories),
  )

  readonly selectCategoriesRootId$: Observable<string | undefined> =
    this.selectCategories$.pipe(map((categories) => categories?.rootId))

  readonly selectCategoriesCurrentId$: Observable<string | undefined> =
    this.selectCategories$.pipe(map((categories) => categories?.currentId))

  readonly selectCategoriesIsUncategorized$: Observable<boolean> =
    this.selectCategories$.pipe(
      map((categories) => !!categories?.uncategorized),
    )

  readonly selectAttributes$: Observable<Attribute[] | undefined> =
    this.selectState$.pipe(map((state) => state.attributes))

  readonly selectScope$: Observable<ProductScope | undefined> =
    this.selectState$.pipe(map((state) => state.scope))

  readonly selectScopeCatalogCode$: Observable<string | undefined> =
    this.selectScope$.pipe(map((scope) => scope?.catalogCode))

  readonly selectScopeWarehouseId$: Observable<string | undefined> =
    this.selectScope$.pipe(map((scope) => scope?.warehouseId))

  readonly selectScopeSupplierId$: Observable<string | undefined> =
    this.selectScope$.pipe(map((scope) => scope?.supplierId))

  /**
   * EFFECTS
   */

  readonly search$ = this.effect(
    (searchParams$: Observable<ProductSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        map(([_, state]) => this.parseStateSearchParams(state)),
        switchMap((searchData) =>
          this.productsListingRepository.searchProducts$(searchData).pipe(
            tapResponse(
              (pageData) => this.setPage(pageData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<ProductSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        map(([_, state]) => this.parseStateSearchParams(state, true)),
        switchMap((searchData) =>
          this.productsListingRepository.searchProducts$(searchData).pipe(
            tapResponse(
              (pageData) => this.setPage(pageData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchVariants$ = this.effect((product$: Observable<Product>) => {
    return product$.pipe(
      switchMap((product) =>
        this.productsListingRepository.getVariantFamilyIds$(product).pipe(
          tapResponse(
            (variantIds) => this.setCustomFilters({ _id: variantIds }),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly searchOrder$ = this.effect((orderId$: Observable<string>) => {
    return orderId$.pipe(
      switchMap((orderId) =>
        this.productsListingRepository.getOrderIds$(orderId).pipe(
          tapResponse(
            (productIds) => this.setSearchKeys({ _id: productIds }),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly searchSupplierOrder$ = this.effect(
    (orderId$: Observable<string>) => {
      return orderId$.pipe(
        switchMap((orderId) =>
          this.productsListingRepository.getSupplierOrderIds$(orderId).pipe(
            tapResponse(
              (productIds) => this.setSearchKeys({ _id: productIds }),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchGoodsReceive$ = this.effect(
    (goodsReceiveId$: Observable<string>) => {
      return goodsReceiveId$.pipe(
        switchMap((goodsReceiveId) =>
          this.productsListingRepository
            .getGoodsReceiveIds$(goodsReceiveId)
            .pipe(
              tapResponse(
                (productIds) => this.setSearchKeys({ _id: productIds }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchPickingList$ = this.effect(
    (goodsReceiveId$: Observable<string>) => {
      return goodsReceiveId$.pipe(
        switchMap((goodsReceiveId) =>
          this.productsListingRepository
            .getPickingListIds$(goodsReceiveId)
            .pipe(
              tapResponse(
                (productIds) => this.setSearchKeys({ _id: productIds }),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): ProductsListingState => ({
      ...state,
      isLoading,
    }),
  )

  readonly setSearchString = this.updater(
    (state, searchString: string): ProductsListingState => ({
      ...state,
      searchString,
      toReload: true,
    }),
  )

  readonly setSelected = this.updater(
    (state, products: Product[] | undefined): ProductsListingState => {
      return {
        ...state,
        selected: products,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: Page<ProductPageData>): ProductsListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ProductsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isInitialized: true,
        isLoading: false,
        toReload: false,
      }
    },
  )

  readonly setReload = this.updater(
    (state, toReload: boolean): ProductsListingState => ({
      ...state,
      toReload,
    }),
  )

  readonly setFields = this.updater(
    (state, fields: ProductField[]): ProductsListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setFilters = this.updater(
    (state, filters?: ProductSearchParams): ProductsListingState => {
      return {
        ...state,
        filters,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setCustomFilters = this.updater(
    (state, customFilters: ProductSearchParams): ProductsListingState => ({
      ...state,
      customFilters,
      toReload: state.isInitialized,
    }),
  )

  readonly setSearchKeys = this.updater(
    (state, searchKeys?: ProductSearchParams): ProductsListingState => {
      return {
        ...state,
        searchKeys,
        toReload: state.isInitialized,
      }
    },
  )

  readonly resetState = this.updater(
    (state): ProductsListingState => PRODUCTS_INITIAL_STATE,
  )

  readonly resetError = this.updater((state): ProductsListingState => {
    return {
      ...state,
      error: undefined,
    }
  })

  readonly resetFilters = this.updater((state): ProductsListingState => {
    return {
      ...state,
      filters: undefined,
      searchString: undefined,
      historic: undefined,
      error: undefined,
      toReload: true,
    }
  })

  readonly setHistoricParams = this.updater(
    (
      state,
      historic: ProductHistoricStockData | undefined,
    ): ProductsListingState => ({
      ...state,
      historic,
      toReload: state.isInitialized,
    }),
  )

  readonly setScope = this.updater(
    (state, scope: ProductScope | undefined): ProductsListingState => {
      return {
        ...state,
        scope,
        categories: scope?.categoriesRootId
          ? {
              rootId: scope.categoriesRootId,
            }
          : state.categories,
        toReload: true,
      }
    },
  )

  readonly setCategoriesRootId = this.updater(
    (state, rootId: string): ProductsListingState => {
      return {
        ...state,
        categories: {
          rootId,
        },
        scope: state.scope
          ? {
              ...state.scope,
              categoriesRootId: rootId,
            }
          : state.scope,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setCategoriesCurrentId = this.updater(
    (state, currentId: string): ProductsListingState => {
      return {
        ...state,
        categories: {
          rootId: state.categories?.rootId,
          currentId,
        },
        toReload: state.isInitialized,
      }
    },
  )

  // readonly setListing

  readonly toggleUncategorizedFlag = this.updater(
    (state, uncategorized: boolean): ProductsListingState => ({
      ...state,
      categories: {
        rootId: state.categories?.rootId,
        uncategorized: uncategorized || undefined,
      },
      toReload: state.isInitialized,
    }),
  )

  readonly resetCategories = this.updater(
    (state): ProductsListingState => ({
      ...state,
      categories: {
        rootId: state.categories?.rootId,
      },
      toReload: state.isInitialized,
    }),
  )

  readonly setAttributes = this.updater(
    (state, attributes: Attribute[] | undefined): ProductsListingState => {
      return {
        ...state,
        attributes,
      }
    },
  )

  /**
   * Utilities
   */

  private parseStateSearchParams(
    state: ProductsListingState,
    exportAll?: boolean,
  ): ProductsListingSearchData {
    return {
      params: this.parseStateFilterParams(state, exportAll),
      fields: state.fields || [],
      scope: state.scope,
      attributes: state.attributes,
      historicData: state.historic,
    }
  }

  private parseStateFilterParams(
    state: ProductsListingState,
    exportAll?: boolean,
  ): ProductSearchParams {
    let params: ProductSearchParams = state.filters || {}

    // Q search
    if (state.searchString) {
      params.q = state.searchString
    }

    // Scope
    if (state.scope?.catalogCode) {
      params.catalogCode = state.scope.catalogCode
    }
    if (state.scope?.locale) {
      params.locale = state.scope.locale
    }
    if (state.scope?.supplierId) {
      params['suppliers._id'] = state.scope.supplierId
    }
    if (state.scope?.warehouseId) {
      params.warehouseId = state.scope.warehouseId
    }

    // Categories
    if (state.categories?.currentId) {
      params.categories = state.categories?.currentId
    }
    if (state.categories?.uncategorized) {
      params['categories:ex'] = false
    }

    // Custom filters
    if (state.customFilters) {
      params = { ...params, ...state.customFilters }
    }
    if (state.searchKeys) {
      params = { ...params, ...state.searchKeys }
    }

    if (exportAll) {
      params.limit = state.totalCount
    }

    return params
  }

  private parseStateFilterData(
    state: ProductsListingState,
  ): ProductsFilterData {
    if (state.selected?.length) {
      return {
        params: {
          _id: state.selected.map((o) => o._id),
        },
        count: state.selected.length,
        scope: state.scope,
      }
    }

    return {
      params: omit(this.parseStateFilterParams(state), [
        'limit',
        'offset',
        'sort',
        'order',
      ]),
      count: state.totalCount,
      scope: state.scope,
    }
  }
}
