import { get } from 'lodash'

import { checkProductAttributeEmpty } from './attribute.lib'
import {
  Product,
  ProductCompletableField,
  ProductScope,
} from '../product.model'
import { CatalogLocales, Price } from '../../../models/util.model'
import { getCatalogValue } from '../../catalogs/catalog.lib'
import { Attribute } from '../../attributes/attribute.model'
import { ProductEmptyField } from '../../product-families/product-family.model'

export function isProductFieldEmpty(
  product: Product,
  field: ProductCompletableField,
  scope: ProductScope
): boolean {
  const productField = get(product, field)

  if (!productField) {
    return true
  }

  switch (field) {
    case 'name':
    case 'description':
    case 'shortDescription': {
      const value = getCatalogValue(
        productField as CatalogLocales<string>[],
        scope.catalogCode,
        scope.locale
      )
      return value === undefined
    }
    case 'categories': {
      const categories = product.categories?.find(
        (tree) => tree.catalogCode === scope.catalogCode
      )
      return categories === undefined || categories.ids.length === 0
    }
    case 'files':
      return !!product.files && product.files.length === 0
    case 'externalSKUs':
      return !!product.externalSKUs && product.externalSKUs.length === 0
    case 'price.purchase':
    case 'price.discount':
    case 'price.listing': {
      const price = getCatalogValue<Price>(
        productField as CatalogLocales<Price>[],
        scope.catalogCode,
        scope.locale
      )
      return (
        price === undefined ||
        price.value === undefined ||
        price.currency === undefined
      )
    }
    default:
      return false
  }
}

export function checkProductCompleteness(
  product: Product,
  attributes: Attribute[],
  scope: ProductScope
) {
  const productFields = []
  const emptyFields: ProductEmptyField[] = []

  // Check common fields
  if (product.family?.fields) {
    product.family.fields
      .filter((field) => field.requiredIn.includes(scope.catalogCode))
      .forEach((field) => {
        productFields.push(field)

        if (isProductFieldEmpty(product, field.field, scope)) {
          emptyFields.push(field)
        }
      })
  }

  // Check attributes
  product.family?.attributes
    .filter((attr) => attr.requiredIn.includes(scope.catalogCode))
    .forEach((attr) => {
      const attribute = attributes.find((a) => a.code === attr.code)
      productFields.push(attr)
      checkProductAttributeEmpty(product.attributes || [], attribute, scope) &&
        emptyFields.push(attr)
    })

  const percentageEmpty = Math.round(
    (emptyFields.length / productFields.length) * 100
  )
  const completeness = !isNaN(percentageEmpty) ? 100 - percentageEmpty : 0

  return {
    completeness,
    emptyFields,
  }
}
