import { Directive, forwardRef, Input } from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms'

@Directive({
  selector: '[tkCoordinateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoordinateValidatorDirective),
      multi: true,
    },
  ],
})
export class CoordinateValidatorDirective {
  @Input('tkCoordinateValidator') format?: 'lat' | 'long'

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value

    if (!value || !this.format) {
      return null
    }

    const regex = RegExp(
      this.format === 'lat'
        ? '^(\\+|-)?(?:90(?:(?:\\.0{1,15})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,15})?))$'
        : '^(\\+|-)?(?:180(?:(?:\\.0{1,15})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,15})?))$'
    )

    const isValid = regex.test(value)

    if (!isValid) {
      return { invalidCoordinate: true }
    }

    return null
  }
}
