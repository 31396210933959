import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Guard
import { AuthGuard } from '@evologi/shared/data-access-api'

// Components
import {
  AuthComponent,
  LoginComponent,
  PasswordChangeComponent,
  PasswordRecoveryComponent,
  TenantSelectionComponent,
} from './components'

const routes: Routes = [
  // Common pages
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
        title: 'Login',
        data: {
          resetStoreRoute: true,
        },
      },
      {
        path: 'tenant',
        component: TenantSelectionComponent,
        pathMatch: 'full',
        title: 'Tenant',
        data: {
          resetStoreRoute: true,
        },
      },
      {
        path: 'change',
        component: PasswordChangeComponent,
        pathMatch: 'full',
        title: 'Modifica password',
        data: {
          resetStoreRoute: true,
        },
      },
      {
        path: 'recovery',
        component: PasswordRecoveryComponent,
        pathMatch: 'full',
        title: 'Recupero password',
        data: {
          resetStoreRoute: true,
        },
      },
    ],
  },

  // Dashboard
  {
    path: '',
    loadChildren: () =>
      import('@evologi/admin/dashboard').then(
        (mod) => mod.AdminDashboardModule,
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },

  // otherwise redirect to login
  { path: '**', redirectTo: 'auth' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
