import { Product, ProductVolume } from '../product.model'

export function updateVolume(volume: ProductVolume | undefined): ProductVolume {
  if (!volume) {
    return {}
  }

  if (
    volume.h === undefined ||
    volume.l === undefined ||
    volume.w === undefined
  ) {
    return volume
  }

  const total = (volume.h / 100) * (volume.l / 100) * (volume.w / 100)

  return {
    ...volume,
    total: +total.toFixed(6),
  }
}

export function getProductVolume(volume: ProductVolume | undefined): number {
  if (!volume) {
    return 0
  }

  if (
    volume.h === undefined ||
    volume.l === undefined ||
    volume.w === undefined
  ) {
    return 0
  }

  return (volume.h / 100) * (volume.l / 100) * (volume.w / 100)
}

export function updateProductVolume(
  product: Product,
  volume: ProductVolume | undefined
): Product {
  return {
    ...product,
    volume: updateVolume(volume),
  }
}
