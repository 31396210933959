import { Pipe, PipeTransform } from '@angular/core'
import { ProductSearchParams } from '../product.model'

@Pipe({
  name: 'columnFilterValue',
})
export class ColumnFilterValuePipe implements PipeTransform {
  transform(
    params: ProductSearchParams | undefined,
    key: string,
  ): string | undefined {
    if (!params) {
      return undefined
    }
    return params[key]
  }
}
