import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'
import { UiSize } from '../../models/ui.model'

@Component({
  selector: 'op-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class SelectBoxComponent implements OnInit {
  @Input() placeholder?: string
  @Input() class?: string
  @Input() key?: string
  @Input() value?: any
  @Input() dataSource?: any
  @Input() size?: UiSize

  // Templates
  @Input() displayParser?: (item: any) => void
  @Input() itemTemplate?: TemplateRef<any>

  // Search params
  @Input() keyField: string | ((item: any) => void) = '_id'
  @Input() searchField: string | string[] = 'name'
  @Input() displayField = 'name'

  // Style
  @Input() clearButton = true
  @Input() disabled = false
  @Input() stylingMode: 'outlined' | 'filled' | 'underlined' = 'outlined'

  // Errors
  @Input() required = false
  @Input() requiredError?: string

  public selectClasses = ''

  @Output() readonly valueChange = new EventEmitter<any>()

  ngOnInit() {
    const selectClasses: string[] = []

    // Size
    if (this.size) {
      selectClasses.push(`form-control-${this.size}`)
    }

    this.selectClasses = selectClasses.join(' ')
  }

  checkValue(value: any | null) {
    return value === null ? undefined : value
  }
}
