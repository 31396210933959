import { cloneDeep, defaultsDeep } from 'lodash'
import {
  ProductFamily,
  ProductFamilySearchField,
  ProductFamilySearchParams,
  ProductFamilySortField,
} from './product-family.model'
import { Attribute } from '../attributes/attribute.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const PRODUCT_FAMILY_INITIAL_STATE: Partial<ProductFamily> = {
  code: undefined,
  name: {},
  fields: [],
  attributes: [],
}

// Initializers

export function initProductFamily(
  family: Partial<ProductFamily> = {}
): ProductFamily {
  return defaultsDeep(cloneDeep(family), PRODUCT_FAMILY_INITIAL_STATE)
}

// Attributes

export function addProductFamilyAttributes(
  family: ProductFamily,
  attributes: Attribute[]
): ProductFamily {
  return {
    ...family,
    attributes: [
      ...family.attributes,
      ...attributes
        .filter(
          (attr) => !family.attributes.map((a) => a.code).includes(attr.code)
        )
        .map((attr) => ({ code: attr.code, requiredIn: [] })),
    ],
  }
}

export function removeProductFamilyAttribute(
  family: ProductFamily,
  i: number
): ProductFamily {
  return {
    ...family,
    attributes: [...family.attributes.filter((attr, index) => index !== i)],
  }
}

// Search

export function productFamilySortParams(
  params: ProductFamilySearchParams,
  sort: QueryStringSort<ProductFamilySortField>
): ProductFamilySearchParams {
  const searchParams: ProductFamilySearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function productFamilyFilterParams(
  params: ProductFamilySearchParams,
  filter: QueryStringFilter<ProductFamilySearchField>
): ProductFamilySearchParams {
  const searchParams: ProductFamilySearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
