import { COUNTRY_EU_CODES } from '../../../consts/country.const'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import {
  GoodsReturnSearchParams,
  GoodsReturnField,
  GoodsReturnSortField,
} from '../goods-return.model'

export function goodsReturnSortParams(
  params: GoodsReturnSearchParams,
  sort: QueryStringSort<GoodsReturnSortField>,
): GoodsReturnSearchParams {
  const searchParams: GoodsReturnSearchParams = {}

  switch (sort.field) {
    case 'header.returnNumber':
      searchParams.sort = 'header.returnNumber'
      searchParams.order = sort.order
      break
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'header.date':
      searchParams.sort = 'header.date'
      searchParams.order = sort.order
      break
    case 'header.arrivalDate':
      searchParams.sort = 'header.arrivalDate'
      searchParams.order = sort.order
      break
    case 'header.refundDate':
      searchParams.sort = 'header.refundDate'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function goodsReturnFilterParams(
  params: GoodsReturnSearchParams,
  filter: QueryStringFilter<GoodsReturnField>,
): GoodsReturnSearchParams {
  const searchParams: GoodsReturnSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams['status'] = filter.value
      }
      break
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams.warehouseId = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams['createdAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'header.returnNumber':
      if (filter.operator === '=') {
        searchParams['header.returnNumber'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.returnNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.returnNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.returnNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.returnNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.returnNumber:ne'] = filter.value
      }
      break
    case 'header.channelId':
      if (filter.value !== null) {
        searchParams['header.channelId'] = filter.value
      } else {
        searchParams['header.channelId:ex'] = false
      }
      break
    case 'header.reasonId':
      if (filter.value !== null) {
        searchParams['header.reasonId'] = filter.value
      } else {
        searchParams['header.reasonId:ex'] = false
      }
      break
    case 'header.paymentId':
      if (filter.value !== null) {
        searchParams['header.paymentId'] = filter.value
      } else {
        searchParams['header.paymentId:ex'] = false
      }
      break
    case 'header.currency':
      if (filter.value !== null) {
        searchParams['header.currency'] = filter.value
      }
      break
    case 'header.date':
      if (filter.operator === '=') {
        searchParams['header.date'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.date:ne'] = filter.value
      }
      break
    case 'header.arrivalDate':
      if (filter.operator === '=') {
        searchParams['header.arrivalDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.arrivalDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.arrivalDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.arrivalDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.arrivalDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.arrivalDate:ne'] = filter.value
      }
      break
    case 'header.creditNotes':
      if (filter.operator === '=') {
        searchParams['header.creditNotes'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.creditNotes:ne'] = filter.value
      }
      break
    case 'header.refundDate':
      if (filter.operator === '=') {
        searchParams['header.refundDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.refundDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.refundDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.refundDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.refundDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.refundDate:ne'] = filter.value
      }
      break
    case 'header.refundNotes':
      if (filter.operator === '=') {
        searchParams['header.refundNotes'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.refundNotes:ne'] = filter.value
      }
      break
    case 'header.shippingAddress.countryCode':
      if (filter.value === 'CEE') {
        searchParams['header.shippingAddress.countryCode'] = COUNTRY_EU_CODES
      } else if (filter.value === '!CEE') {
        searchParams['header.shippingAddress.countryCode:ne'] = COUNTRY_EU_CODES
      } else if (filter.value !== null) {
        searchParams['header.shippingAddress.countryCode'] = filter.value
      }
      break
    case 'referenceOrder.number':
      if (filter.operator === '=') {
        searchParams['referenceOrder.number'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['referenceOrder.number:ne'] = filter.value
      }
      break
    case 'referenceOrder.refDate':
      if (filter.operator === '=') {
        searchParams['referenceOrder.refDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['referenceOrder.refDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['referenceOrder.refDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['referenceOrder.refDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['referenceOrder.refDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['referenceOrder.refDate:ne'] = filter.value
      }
      break
    case 'referenceOrder.refOrder':
      if (filter.operator === '=') {
        searchParams['referenceOrder.refOrder'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['referenceOrder.refOrder:ne'] = filter.value
      }
      break
    case 'recoveryOrder.number':
      if (filter.operator === '=') {
        searchParams['recoveryOrder.number'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['recoveryOrder.number:ne'] = filter.value
      }
      break
    case 'recoveryOrder.refDate':
      if (filter.operator === '=') {
        searchParams['recoveryOrder.refDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['recoveryOrder.refDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['recoveryOrder.refDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['recoveryOrder.refDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['recoveryOrder.refDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['recoveryOrder.refDate:ne'] = filter.value
      }
      break
    case 'recoveryOrder.refOrder':
      if (filter.operator === '=') {
        searchParams['recoveryOrder.refOrder'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['recoveryOrder.refOrder:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
