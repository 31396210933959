import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { EMPTY, Observable, switchMap, tap, withLatestFrom } from 'rxjs'

import {
  Product,
  ProductGoodsReceive,
  ProductIncomings,
  ProductOrder,
  ProductOutgoings,
  ProductPositions,
  ProductSalesByYear,
  ProductSupplierOrder,
} from '../product.model'
import { ProductsService } from '../products.service'
import { ProductQuantitiesRepository } from '../repositories/product-quantities.repository'
import { Location } from '../../locations/location.model'
import { Supplier } from '../../suppliers'
import { parseProductSales } from '../libs/product.lib'
import { Tenant } from '../../tenants'

interface ProductQuantitiesState {
  productId?: string
  warehouseId?: string
  product?: Product
  tenant?: Tenant
  sales?: ProductSalesByYear
  incomings?: ProductIncomings
  outgoings?: ProductOutgoings
  positions?: ProductPositions
  error?: HttpErrorResponse
  isLoading: boolean
}

export const PRODUCT_QUANTITIES_INITIAL_STATE: ProductQuantitiesState = {
  isLoading: false,
}

@Injectable()
export class ProductQuantitiesUseCase extends ComponentStore<ProductQuantitiesState> {
  constructor(
    private productsService: ProductsService,
    private productQuantitiesService: ProductQuantitiesRepository,
  ) {
    super(PRODUCT_QUANTITIES_INITIAL_STATE)
  }

  /**
   * SELECTORS
   */

  readonly selectProductId$: Observable<string | undefined> = this.select(
    (state) => state.productId,
  )

  readonly selectProduct$: Observable<Product | undefined> = this.select(
    (state) => state.product,
  )

  readonly selectTenant$: Observable<Tenant | undefined> = this.select(
    (state) => state.tenant,
  )

  readonly selectPositions$: Observable<ProductPositions | undefined> =
    this.select((state) => state.positions)

  readonly selectOutgoings$: Observable<ProductOutgoings | undefined> =
    this.select((state) => state.outgoings)

  readonly selectOutgoingsOrders$: Observable<ProductOrder[] | undefined> =
    this.select((state) => state.outgoings?.orders)

  readonly selectIncomings$: Observable<ProductIncomings | undefined> =
    this.select((state) => state.incomings)

  readonly selectIncomingsSuppliers$: Observable<Supplier[] | undefined> =
    this.select((state) => state.incomings?.suppliers)

  readonly selectIncomingsSupplierOrders$: Observable<
    ProductSupplierOrder[] | undefined
  > = this.select((state) => state.incomings?.supplierOrders)

  readonly selectIncomingsGoodsReceives$: Observable<
    ProductGoodsReceive[] | undefined
  > = this.select((state) => state.incomings?.goodsReceives)

  readonly selectPositionLocations$: Observable<Location[] | undefined> =
    this.select((state) => state.positions?.locations)

  readonly selectSales$: Observable<ProductSalesByYear | undefined> =
    this.select((state) => state.sales)

  /**
   * EFFECTS
   */

  readonly load$ = this.effect((productId$: Observable<string>) => {
    return productId$.pipe(
      tap((productId) => this.setProductId(productId)),
      switchMap((productId) =>
        this.productsService.read$(productId).pipe(
          tapResponse(
            (product) => this.setProduct(product),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly searchOnHandPositions$ = this.effect(
    (warehouseId$: Observable<string | undefined>) => {
      return warehouseId$.pipe(
        tap(() => this.resetQuantities()),
        withLatestFrom(this.selectProduct$),
        switchMap(([warehouseId, product]) => {
          if (!product) {
            return EMPTY
          }

          const productLocations = product.locations.filter(
            (l) =>
              l.onHandQty > 0 &&
              (warehouseId ? l.warehouseId === warehouseId : true),
          )

          return this.productQuantitiesService
            .getPositions$(productLocations)
            .pipe(
              tapResponse(
                (positionsData) => this.setPositions(positionsData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchScrappedPositions$ = this.effect(
    (warehouseId$: Observable<string | undefined>) => {
      return warehouseId$.pipe(
        tap(() => this.resetQuantities()),
        withLatestFrom(this.selectProduct$),
        switchMap(([warehouseId, product]) => {
          if (!product) {
            return EMPTY
          }

          const productLocations = product.locations.filter(
            (l) =>
              l.scrappedQty &&
              l.scrappedQty > 0 &&
              (warehouseId ? l.warehouseId === warehouseId : true),
          )

          return this.productQuantitiesService
            .getPositions$(productLocations)
            .pipe(
              tapResponse(
                (positionsData) => this.setPositions(positionsData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchOutgoings$ = this.effect(
    (warehouseId$: Observable<string | undefined>) => {
      return warehouseId$.pipe(
        tap(() => this.resetQuantities()),
        withLatestFrom(this.selectProduct$),
        switchMap(([warehouseId, product]) => {
          if (!product) {
            return EMPTY
          }

          return this.productQuantitiesService
            .getOutgoings$(product, warehouseId)
            .pipe(
              tapResponse(
                (outgoings) => this.setOutgoings(outgoings),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchIncomings$ = this.effect(
    (warehouseId$: Observable<string | undefined>) => {
      return warehouseId$.pipe(
        tap(() => this.resetQuantities()),
        withLatestFrom(this.selectProduct$, this.selectTenant$),
        switchMap(([warehouseId, product, tenant]) => {
          if (!product || !tenant) {
            return EMPTY
          }

          return this.productQuantitiesService
            .getIncomings$(tenant, product, warehouseId)
            .pipe(
              tapResponse(
                (incomings) => this.setIncomings(incomings),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchReceivings$ = this.effect(
    (warehouseId$: Observable<string | undefined>) => {
      return warehouseId$.pipe(
        tap(() => this.resetQuantities()),
        withLatestFrom(this.selectProduct$, this.selectTenant$),
        switchMap(([warehouseId, product, tenant]) => {
          if (!product || !tenant) {
            return EMPTY
          }

          return this.productQuantitiesService
            .getGoodsReceives$(tenant, product, warehouseId)
            .pipe(
              tapResponse(
                (incomings) => this.setIncomings(incomings),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  /**
   * REDUCERS
   */

  readonly setProductId = this.updater(
    (state, productId: string): ProductQuantitiesState => {
      return {
        productId,
        tenant: state.tenant,
        isLoading: true,
      }
    },
  )

  readonly setTenant = this.updater(
    (state, tenant: Tenant): ProductQuantitiesState => {
      return {
        ...state,
        tenant,
      }
    },
  )

  readonly setProduct = this.updater(
    (state, product: Product): ProductQuantitiesState => {
      return {
        product,
        sales: parseProductSales(product),
        tenant: state.tenant,
        isLoading: false,
      }
    },
  )

  readonly resetQuantities = this.updater(
    (state): ProductQuantitiesState => ({
      ...state,
      isLoading: true,
      incomings: undefined,
      outgoings: undefined,
      positions: undefined,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ProductQuantitiesState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly setPositions = this.updater(
    (
      state,
      positions: ProductPositions | undefined,
    ): ProductQuantitiesState => ({
      ...state,
      positions: positions,
    }),
  )

  readonly setOutgoings = this.updater(
    (state, orders: ProductOrder[] | undefined): ProductQuantitiesState => ({
      ...state,
      outgoings: {
        ...state.outgoings,
        orders,
      },
    }),
  )

  readonly setIncomings = this.updater(
    (
      state,
      incomings: ProductIncomings | undefined,
    ): ProductQuantitiesState => ({
      ...state,
      incomings: {
        ...state.incomings,
        suppliers: incomings?.suppliers,
        goodsReceives: incomings?.goodsReceives,
        supplierOrders: incomings?.supplierOrders,
      },
    }),
  )
}
