import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import { Execution, ExecutionSearchParams } from './execution.model'
import { Page } from '../../models/util.model'
import { SDK_SETTINGS } from '../../consts/config.const'

const MODEL = 'executions'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ExecutionsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a execution by ID
   * @param executionId - The execution ID
   * @returns The observable<Execution> for read the execution
   */
  read$(executionId: string): Observable<Execution> {
    return this._read$<Execution>(executionId)
  }

  /**
   * Delete a execution by ID
   * @param executionId - The execution ID
   * @returns The observable<Execution> for delete the execution
   */
  delete$(executionId: string): Observable<Execution> {
    return this._delete$<Execution>(executionId)
  }

  /**
   * Search executions by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Execution>> for search executions
   */
  search$(
    params?: ExecutionSearchParams,
    returnAll = false,
  ): Observable<Page<Execution>> {
    return this._search$<Execution>(params, returnAll)
  }

  /**
   * List executions by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Execution[]> for list executions
   */
  list$(
    params?: ExecutionSearchParams,
    returnAll = false,
  ): Observable<Execution[]> {
    return this._list$<Execution>(params, returnAll)
  }

  /**
   * Load execution logs
   * @param executionId - The execution ID
   * @returns The observable<string> to retrieve execution logs
   */
  loadLogs$(executionId: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'text' as 'json',
    }

    return this.http.get<string>(`${this.apiUrl}/${executionId}/logs`, {
      ...httpOptions,
    })
  }
}
