import { Pipe, PipeTransform } from '@angular/core'
import { Product, ProductScope } from '../product.model'

@Pipe({
  name: 'productCategories',
})
export class ProductCategoriesPipe implements PipeTransform {
  transform(product: Product, scope: ProductScope): string[] {
    if (!product.categories) {
      return []
    }

    const productCategories = product.categories.find(
      (c) => c.catalogCode === scope.catalogCode,
    )

    return productCategories?.ids || []
  }
}
