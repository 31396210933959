import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { AttributeSearchParams, Attribute } from './attribute.model'
import { Page } from '../../models/util.model'

const MODEL = 'attributes'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class AttributesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new attribute
   * @param attribute - The attribute to create
   * @returns The observable<Attribute> to create the attribute
   */
  create$(attribute: Attribute): Observable<Attribute> {
    return this._create$<Attribute>(attribute)
  }

  /**
   * Read a attribute by ID
   * @param attributeId - The attribute ID
   * @returns The observable<Attribute> for read the attribute
   */
  read$(attributeId: string): Observable<Attribute> {
    return this._read$<Attribute>(attributeId)
  }

  /**
   * Update a attribute by ID
   * @param attributeId - The attribute ID
   * @param attribute - The attribute body to update
   * @returns The observable<Attribute> for update the attribute
   */
  update$(attributeId: string, attribute: Attribute): Observable<Attribute> {
    return this._update$<Attribute>(attributeId, attribute)
  }

  /**
   * Create or update a attribute by ID
   * @param attributeId - The attribute ID
   * @param attribute - The attribute body to update
   * @returns The observable<Attribute> for update the attribute
   */
  upsert$(attribute: Attribute): Observable<Attribute> {
    return this._upsert$<Attribute>(attribute, attribute._id)
  }

  /**
   * Delete a attribute by ID
   * @param attributeId - The attribute ID
   * @returns The observable<Attribute> for delete the attribute
   */
  delete$(attributeId: string): Observable<Attribute> {
    return this._delete$<Attribute>(attributeId)
  }

  /**
   * Search attributes by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Attribute>> for search attributes
   */
  search$(
    params?: AttributeSearchParams,
    returnAll = false,
  ): Observable<Page<Attribute>> {
    return this._search$<Attribute>(params, returnAll)
  }

  /**
   * List attributes by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Attribute[]> for list attributes
   */
  list$(
    params?: AttributeSearchParams,
    returnAll = false,
  ): Observable<Attribute[]> {
    return this._list$<Attribute>(params, returnAll)
  }

  /**
   * Find an attribute by params
   * @param params - The search params
   * @returns the observable<Attribute> for find an attribute
   */
  readOne$(params?: AttributeSearchParams): Observable<Attribute> {
    return this._readOne$<Attribute>(params)
  }
}
