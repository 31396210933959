import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  Observable,
  switchMap,
  tap,
  map,
  filter,
  withLatestFrom,
  EMPTY,
  concat,
  last,
} from 'rxjs'
import { saveAs } from 'file-saver'
import { Task, TaskSearchParams } from './task.model'
import { TasksService } from './tasks.service'
import { isQueryStringFiltered } from '../../libs/query-string.lib'
import { Page } from '../../models/util.model'

interface TasksListingState {
  filters?: TaskSearchParams
  data?: Task[]
  totalCount?: number
  userId?: string
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const TASKS_INITIAL_STATE: TasksListingState = {
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class TasksListingUseCase extends ComponentStore<TasksListingState> {
  constructor(private tasksService: TasksService) {
    super(TASKS_INITIAL_STATE)
  }

  // Selectors

  readonly selectUserId$: Observable<string | undefined> = this.select(
    (state) => state.userId,
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectFilters$: Observable<TaskSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {}, ['_id'])),
  )

  readonly selectTasks$: Observable<Task[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<Page<Task>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectTasks$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<TaskSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectUserId$),
        switchMap(([searchParams, userId]) => {
          if (!userId) {
            return EMPTY
          }

          return this.tasksService
            .search$({ ...searchParams, createdBy: userId })
            .pipe(
              tapResponse(
                (page) => this.setPage(page),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
        }),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<TaskSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectTotalCount$, this.selectUserId$),
        map(([params, limit, userId]) => ({
          ...params,
          limit,
          createdBy: userId,
        })),
        switchMap((searchParams) =>
          this.tasksService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly downloadAttachments$ = this.effect((task$: Observable<Task>) => {
    return task$.pipe(
      switchMap((task) => {
        if (!task.attachments) {
          return EMPTY
        }

        const obs$ = task.attachments.map((a) =>
          this.tasksService
            .downloadAttachment$(task._id, a._id)
            .pipe(
              map((attachment) =>
                saveAs(attachment, a.filename || 'attachment.txt'),
              ),
            ),
        )

        this.setIsLoading(true)
        return concat(...obs$).pipe(
          last(),
          tapResponse(
            () => this.setIsLoading(false),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  // Reducers

  readonly setUserId = this.updater(
    (state, userId: string | undefined): TasksListingState => {
      return {
        ...state,
        userId,
        toReload: true,
      }
    },
  )

  readonly setFilters = this.updater(
    (state, filters?: TaskSearchParams): TasksListingState => {
      return {
        filters,
        userId: state.userId,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: Page<Task>): TasksListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): TasksListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): TasksListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setTasks = this.updater((state, data: Task[]): TasksListingState => {
    return {
      ...state,
      data,
    }
  })

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): TasksListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )
}
