import { Injectable } from '@angular/core'
import { CacheManager } from '@evologi/shared/data-access-api'
import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs'
import { Store } from '@ngrx/store'

import { StoreState } from '../store.state'
import { AuthStoreService } from '../auth'
import { selectCacheItem, selectCacheItems } from './cache.selectors'
import { setItem, setItems, unsetItem } from './cache.actions'

@Injectable({
  providedIn: 'root',
})
export class CacheService implements CacheManager {
  private tenantId$: Observable<string> = this.auth.getTenantId$().pipe(
    filter((tenantId) => !!tenantId),
    distinctUntilChanged(),
    // eslint-disable-next-line
    map((tenantId) => tenantId!),
  )

  constructor(
    private store: Store<StoreState>,
    private auth: AuthStoreService,
  ) {}

  /**
   * Get items cached
   * @param cacheKey - the cache key
   * @returns the observable for get all the items cached
   */
  getItems$<T>(cacheKey: string): Observable<T[] | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store.select(selectCacheItems(tenantId, cacheKey)),
      ),
    )
  }

  /**
   * Get item cached
   * @param cacheKey - the cache key
   * @param itemKey - the item key
   * @returns the observable for get the item cached
   */
  getItem$<T>(cacheKey: string, itemKey: string): Observable<T | undefined> {
    return this.tenantId$.pipe(
      switchMap((tenantId) =>
        this.store.select(selectCacheItem(tenantId, cacheKey, itemKey)),
      ),
    )
  }

  /**
   * Set items to cache
   * @param cacheKey - the cache key
   * @param items - the items to cache
   * @param itemFieldKey - the item field to use as key
   * @param totalCount - the total count
   */
  setItems<T>(
    cacheKey: string,
    items: T[],
    totalCount?: number,
    itemFieldKey?: string,
  ): void {
    this.tenantId$.pipe(
      take(1),
      tap((tenantId) =>
        this.store.dispatch(
          setItems({ cacheKey, tenantId, items, itemFieldKey, totalCount }),
        ),
      ),
    )
  }

  /**
   * Set item to cache
   * @param cacheKey - the cache key
   * @param itemKey - the item key
   * @param item - the item to cache
   */
  setItem<T>(cacheKey: string, itemKey: string, item: T): void {
    this.tenantId$.pipe(
      take(1),
      tap((tenantId) =>
        this.store.dispatch(setItem({ cacheKey, tenantId, item, itemKey })),
      ),
    )
  }

  /**
   * Unset item to cache
   * @param cacheKey - the cache key
   * @param itemKey - the item key
   */
  unsetItem(cacheKey: string, itemKey: string): void {
    this.tenantId$.pipe(
      take(1),
      tap((tenantId) =>
        this.store.dispatch(unsetItem({ cacheKey, tenantId, itemKey })),
      ),
    )
  }
}
