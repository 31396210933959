import { BulkUpdateMethod } from '../models/bulk-update.model'
import { SelectionData } from '../models/util.model'

export const BULK_UPDATE_METHODS: SelectionData<BulkUpdateMethod>[] = [
  {
    value: BulkUpdateMethod.set,
    label: 'Imposta valore',
    description:
      'Imposta uno o più valori e rimuove quelli già associati agli elementi',
    icon: 'fad fa-share',
  },
  {
    value: BulkUpdateMethod.push,
    label: 'Aggiungi valore',
    description:
      'Aggiunge uno o più valori. Non viene rimosso alcun valore già associato agli elementi',
    icon: 'fad fa-layer-plus',
  },
  {
    value: BulkUpdateMethod.pull,
    label: 'Rimuovi valore',
    description:
      'Rimuove uno o più valori. Non viene rimosso alcun altro valore associato agli elementi',
    icon: 'fad fa-layer-minus',
  },
  {
    value: BulkUpdateMethod.unset,
    label: 'Cancella valore',
    description: 'Rimuove tutti i valori associati agli elementi',
    icon: 'fad fa-window-close',
  },
  {
    value: BulkUpdateMethod.refresh,
    label: 'Reimposta ubicazioni consigliate',
    description:
      'Rimuove tutte le attuali ubicazioni consigliate, e la ripopolerà con le ubicazioni che attualmente contengono il prodotto',
    icon: 'fad fa-arrows-rotate',
  },
]
