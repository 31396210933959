import { HttpErrorResponse } from '@angular/common/http'
import { createAction, props } from '@ngrx/store'

import {
  Warehouse,
  Tenant,
  PickupPoint,
  Supplier,
  User,
  Permissions,
} from '@evologi/shared/data-access-api'

export enum AuthAction {
  stateInitialized = '[AUTH] state initialized',
  userInitialized = '[AUTH] user initialized',
  userLoggedIn = '[AUTH] user logged-in',
  userLoggedInToken = '[AUTH] user logged-in token',
  userLoggedInSuccess = '[AUTH] user logged-in success',
  userLoggedInFailed = '[AUTH] user logged-in failed',
  userLoggedOut = '[AUTH] user logged-out',
  userLoggedOutSuccess = '[AUTH] user logged-out success',
  userPasswordChange = '[AUTH] user password change',
  userPasswordChangeSuccess = '[AUTH] user password change success',
  userPasswordChangeFailed = '[AUTH] user password change failed',
  userPasswordUpdate = '[AUTH] user password update',
  userPasswordUpdateSuccess = '[AUTH] user password update success',
  userPasswordUpdateFailed = '[AUTH] user password update failed',
  userPasswordRecovery = '[AUTH] user password recovery',
  userPasswordRecoverySuccess = '[AUTH] user password recovery success',
  userPasswordRecoveryFailed = '[AUTH] user password recovery failed',
  tenantSelected = '[AUTH] tenant selected',
  tenantSelectedToken = '[AUTH] tenant selected token',
  tenantSelectedSuccess = '[AUTH] tenant selected success',
  tenantSelectedFailed = '[AUTH] tenant selected failed',
  userSaved = '[AUTH] user updated',
  userSavedSuccess = '[AUTH] user updated success',
  userSavedFailed = '[AUTH] user updated failed',
  tenantSaved = '[AUTH] tenant saved',
  tenantSavedSuccess = '[AUTH] tenant saved success',
  tenantSavedFailed = '[AUTH] tenant saved failed',
  userExpel = '[AUTH] user expel',
  userRevive = '[AUTH] user revive',
  userReviveSuccess = '[AUTH] user revive success',
  userReviveFailed = '[AUTH] user revive failed',
}

// User actions

export const stateInitialized = createAction(AuthAction.stateInitialized)
export const userInitialized = createAction(AuthAction.userInitialized)

export const userLoggedIn = createAction(
  AuthAction.userLoggedIn,
  props<{
    username: string
    password: string
    force: boolean
    code?: string
  }>(),
)
export const userLoggedInToken = createAction(
  AuthAction.userLoggedInToken,
  props<{
    refreshToken?: string
  }>(),
)
export const userLoggedInSuccess = createAction(
  AuthAction.userLoggedInSuccess,
  props<{
    user: User
  }>(),
)
export const userLoggedInFailed = createAction(
  AuthAction.userLoggedInFailed,
  props<{ error: HttpErrorResponse }>(),
)

export const userLoggedOut = createAction(AuthAction.userLoggedOut)
export const userLoggedOutSuccess = createAction(
  AuthAction.userLoggedOutSuccess,
)

export const userSaved = createAction(
  AuthAction.userSaved,
  props<{ user: User }>(),
)
export const userSavedSuccess = createAction(
  AuthAction.userSavedSuccess,
  props<{ user: User; tenantId?: string }>(),
)
export const userSavedFailed = createAction(
  AuthAction.userSavedFailed,
  props<{ user: User; error: HttpErrorResponse }>(),
)

export const userExpel = createAction(AuthAction.userExpel)

export const userRevive = createAction(AuthAction.userRevive)
export const userReviveSuccess = createAction(
  AuthAction.userReviveSuccess,
  props<{ accessToken: string }>(),
)
export const userReviveFailed = createAction(
  AuthAction.userReviveFailed,
  props<{ error: HttpErrorResponse }>(),
)

export const userPasswordChange = createAction(
  AuthAction.userPasswordChange,
  props<{
    oldPassword: string
    newPassword: string
  }>(),
)
export const userPasswordChangeSuccess = createAction(
  AuthAction.userPasswordChangeSuccess,
  props<{ password: string }>(),
)
export const userPasswordChangeFailed = createAction(
  AuthAction.userPasswordChangeFailed,
  props<{ error: HttpErrorResponse }>(),
)

export const userPasswordUpdate = createAction(
  AuthAction.userPasswordUpdate,
  props<{
    oldPassword: string
    newPassword: string
  }>(),
)
export const userPasswordUpdateSuccess = createAction(
  AuthAction.userPasswordUpdateSuccess,
  props<{
    refreshToken?: string
    accessToken: string
  }>(),
)
export const userPasswordUpdateFailed = createAction(
  AuthAction.userPasswordUpdateFailed,
  props<{ error: HttpErrorResponse }>(),
)

export const userPasswordRecovery = createAction(
  AuthAction.userPasswordRecovery,
  props<{ username: string }>(),
)
export const userPasswordRecoverySuccess = createAction(
  AuthAction.userPasswordRecoverySuccess,
)
export const userPasswordRecoveryFailed = createAction(
  AuthAction.userPasswordRecoveryFailed,
  props<{ error: HttpErrorResponse }>(),
)

// Tenant actions

export const tenantSelected = createAction(
  AuthAction.tenantSelected,
  props<{ tenantId: string; redirect?: boolean }>(),
)
export const tenantSelectedToken = createAction(
  AuthAction.tenantSelectedToken,
  props<{ accessToken: string; tenantId: string; redirect?: boolean }>(),
)
export const tenantSelectedSuccess = createAction(
  AuthAction.tenantSelectedSuccess,
  props<{
    tenant: Tenant
    permissions: Permissions
    warehouse?: Warehouse
    pickupPoint?: PickupPoint
    supplier?: Supplier
    redirect?: boolean
  }>(),
)
export const tenantSelectedFailed = createAction(
  AuthAction.tenantSelectedFailed,
  props<{ error: HttpErrorResponse }>(),
)

export const tenantSaved = createAction(
  AuthAction.tenantSaved,
  props<{ tenant: Tenant }>(),
)
export const tenantSavedSuccess = createAction(
  AuthAction.tenantSavedSuccess,
  props<{ tenant: Tenant; user: User }>(),
)
export const tenantSavedFailed = createAction(
  AuthAction.tenantSavedFailed,
  props<{ tenant: Tenant; error: HttpErrorResponse }>(),
)
