import { cloneDeep, defaultsDeep } from 'lodash'
import { SupplierOrderHeader, SupplierOrderRow } from '../supplier-order.model'
import { dinero } from '../../../libs/dinero.lib'

// Initial states

export const SUPPLIER_ORDER_HEADER_INITIAL_STATE: Partial<SupplierOrderHeader> =
  {
    date: new Date(),
    rifDate: new Date(),
    subTotal: 0,
    subTotalWithTaxes: 0,
    bodyTaxes: 0,
    totalOrderAmount: 0,
  }

// Init

export function initSupplierOrderHeader(
  header: Partial<SupplierOrderHeader> = {}
): SupplierOrderHeader {
  return defaultsDeep(cloneDeep(header), SUPPLIER_ORDER_HEADER_INITIAL_STATE)
}

// Amounts

export function updateSupplierOrderHeaderAmounts(
  header: SupplierOrderHeader,
  rows: SupplierOrderRow[]
): SupplierOrderHeader {
  // Subtotals
  const subTotal = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalAmount)),
    dinero(0)
  )
  const subTotalWithTaxes = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalAmountWithTaxes)),
    dinero(0)
  )
  const bodyTaxes = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalTaxes)),
    dinero(0)
  )

  // Amounts
  const totalOrderAmount = subTotalWithTaxes

  return {
    ...header,
    subTotal: subTotal.toUnit(),
    subTotalWithTaxes: subTotalWithTaxes.toUnit(),
    bodyTaxes: bodyTaxes.toUnit(),
    totalOrderAmount: totalOrderAmount.toUnit(),
  }
}
