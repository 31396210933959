import { createAction, props } from '@ngrx/store'
import { NavigationExtras } from '@angular/router'

export enum ERouterActions {
  go = '[ROUTER] go',
  back = '[ROUTER] back',
  forward = '[ROUTER] forward',
}

export const go = createAction(
  ERouterActions.go,
  props<{ path: any[]; extras?: NavigationExtras }>(),
)
export const back = createAction(ERouterActions.back)
export const forward = createAction(ERouterActions.forward)
