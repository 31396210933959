import { Injectable } from '@angular/core'
import {
  DialogParams,
  NotificationManager,
} from '@evologi/shared/data-access-api'
import { TranslationService } from '@evologi/shared/util-translation'
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements NotificationManager {
  constructor(
    private toastr: ToastrService,
    private translationService: TranslationService,
  ) {}

  /**
   * Show a simple toast
   * @param params - the notification params
   * @param override - the configuration to override
   * @returns the active toast
   */
  show(
    params: DialogParams,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    // Labels
    const title = params.title
      ? this.translationService.translate(params.title, params.titleParams)
      : undefined
    const message = params.message
      ? this.translationService.translate(params.message, params.messageParams)
      : undefined

    // Notification type
    let notification
    switch (params.color) {
      case 'success':
        notification = this.toastr.success(message, title, override)
        break
      case 'warning':
        notification = this.toastr.warning(message, title, override)
        break
      case 'danger':
        notification = this.toastr.error(message, title, override)
        break
      default:
        notification = this.toastr.info(message, title, override)
        break
    }

    return notification
  }

  /**
   * Show a success toast
   * @param params - the notification params
   * @param override - the configuration to override
   * @returns the success toast
   */
  success(
    params: DialogParams,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    return this.show({ ...params, color: 'success' }, override)
  }

  /**
   * Show an error toast
   * @param params - the notification params
   * @param override - the configuration to override
   * @returns the error toast
   */
  error(
    params: DialogParams,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    return this.show({ ...params, color: 'danger' }, override)
  }

  /**
   * Show a warning toast
   * @param message - the message
   * @param title - the title
   * @param override - the configuration to override
   * @returns the warning toast
   */
  warning(
    params: DialogParams,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    return this.show({ ...params, color: 'warning' }, override)
  }

  /**
   * Show an info toast
   * @param message - the message
   * @param title - the title
   * @param override - the configuration to override
   * @returns the info toast
   */
  info(
    params: DialogParams,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<any> {
    return this.show({ ...params, color: 'info' }, override)
  }

  /**
   * Show a static toast dismissable on tap
   * @param type - the notification type
   * @param message - the message
   * @param title - the title
   * @param override - the configuration to override
   * @return the observable for the toast
   */
  show$(params: DialogParams): Observable<void> {
    const toast = this.show(params, {
      disableTimeOut: true,
      tapToDismiss: true,
    })
    return toast.onTap
  }
}
