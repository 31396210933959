import { uniq } from 'lodash'
import { Page } from '../../../models/util.model'
import { GoodsReceive, GoodsReceiveListingKeys } from '../goods-receive.model'

/**
 * Parse goods-receive page keys
 * @param page - the goods-receive page
 * @returns the page updated
 */
export function parseGoodsReceivePageKeys(
  page: Page<GoodsReceive>
): GoodsReceiveListingKeys {
  return page.data.reduce<GoodsReceiveListingKeys>(
    (acc, r) => parseGoodsReceiveKeys(r, acc),
    {}
  )
}

/**
 * Parse goods-receive keys
 * @param goodsReceive - the goods-receive
 * @param keys - the keys already loaded
 */
export function parseGoodsReceiveKeys(
  goodsReceive: GoodsReceive,
  keys: GoodsReceiveListingKeys = {}
): GoodsReceiveListingKeys {
  const warehouseIds = uniq([
    ...(keys['warehouseIds'] || []),
    ...(goodsReceive.warehouseId ? [goodsReceive.warehouseId] : []),
  ])
  const supplierIds = uniq([
    ...(keys['supplierIds'] || []),
    ...(goodsReceive.supplierId ? [goodsReceive.supplierId] : []),
  ])
  const paymentIds = uniq([
    ...(keys['paymentIds'] || []),
    ...(goodsReceive.header.paymentType
      ? [goodsReceive.header.paymentType]
      : []),
  ])
  const reasonIds = uniq([
    ...(keys['reasonIds'] || []),
    ...(goodsReceive.header.reasonId ? [goodsReceive.header.reasonId] : []),
  ])

  return {
    warehouseIds,
    supplierIds,
    paymentIds,
    reasonIds,
  }
}
