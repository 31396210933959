import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { BrandNotification } from './libs/brand-notification.lib'
import { BrandNotificationOptions } from './brand.model'
import { EMPTY, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class BrandsRepository {
  constructor(
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Show brands alert
   * @param options - the brand notification or notification options
   * @returns the observable for show the alert about brand
   */
  alert$(
    opts: BrandNotification | BrandNotificationOptions,
  ): Observable<boolean> {
    const notification = BrandNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a brand event
   * @param notification - the brand notification
   */
  notify(opts: BrandNotification | BrandNotificationOptions): void {
    const notification = BrandNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }
}
