import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { uniq } from 'lodash'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// SDK
import {
  StatusHistory,
  User,
  UsersService,
} from '@evologi/shared/data-access-api'

@Component({
  selector: 'op-modal-history',
  templateUrl: './modal-history.component.html',
  styleUrls: ['./modal-history.component.scss'],
})
export class ModalHistoryComponent implements OnInit, OnDestroy {
  @Output() readonly action = new EventEmitter()
  @Input() public btnClass = ''

  // Models
  public title?: string
  public documentType?: string
  public documentRef?: string | number
  public history: StatusHistory[] = []
  public status: any[] = []
  public users: User[] = []

  // Observable
  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private usersService: UsersService,
    public modal: BsModalRef,
  ) {}

  // Lifecycle methods

  ngOnInit() {
    const userIds = uniq(this.history.map((history) => history.userId!))
    this.usersService
      .list$({ _id: userIds })
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => (this.users = users))
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
