import { createAction, props } from '@ngrx/store'
import { Locale, LocaleSearchParams } from '@evologi/shared/data-access-api'

export enum LocalesAction {
  loadLocales = '[LOCALES] load locales',
  setLocales = '[LOCALES] set locales',
  destroy = '[LOCALES] destroy',
}

export const loadLocales = createAction(
  LocalesAction.loadLocales,
  props<{ tenantId: string; params?: LocaleSearchParams }>(),
)

export const setLocales = createAction(
  LocalesAction.setLocales,
  props<{ tenantId: string; locales: Locale[] }>(),
)

export const destroyLocales = createAction(LocalesAction.destroy)
