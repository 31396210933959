import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { concat, last, map, Observable, tap } from 'rxjs'

import {
  GoodsReceiveSearchParams,
  GoodsReceiveRowSearchParams,
  GoodsReceive,
  GoodsReceiveRow,
  GoodsReceiveChangeStatusAction,
} from './goods-receive.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'goods-receives'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class GoodsReceivesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new goods-receive
   * @param goodsReceive - The goods-receive to create
   * @returns The observable<GoodsReceive> to create the goods-receive
   */
  create$(goodsReceive: GoodsReceive): Observable<GoodsReceive> {
    return this._create$<GoodsReceive>(goodsReceive)
  }

  /**
   * Read a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for read the goods-receive
   */
  read$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this._read$<GoodsReceive>(goodsReceiveId)
  }

  /**
   * Update a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @param goodsReceive - The goods-receive body to update
   * @returns The observable<GoodsReceive> for update the goods-receive
   */
  update$(
    goodsReceiveId: string,
    goodsReceive: GoodsReceive,
  ): Observable<GoodsReceive> {
    return this._update$<GoodsReceive>(goodsReceiveId, goodsReceive)
  }

  /**
   * Create or update a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @param goodsReceive - The goods-receive body to update
   * @returns The observable<GoodsReceive> for update the goods-receive
   */
  upsert$(goodsReceive: GoodsReceive): Observable<GoodsReceive> {
    return this._upsert$<GoodsReceive>(goodsReceive, goodsReceive._id)
  }

  /**
   * Delete a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for delete the goods-receive
   */
  delete$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this._delete$<GoodsReceive>(goodsReceiveId)
  }

  /**
   * Search goods-receives by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<GoodsReceive>> for search goods-receives
   */
  search$(
    params?: GoodsReceiveSearchParams,
    returnAll = false,
  ): Observable<Page<GoodsReceive>> {
    return this._search$<GoodsReceive>(params, returnAll)
  }

  /**
   * List goods-receives by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<GoodsReceive[]> for list goods-receives
   */
  list$(
    params?: GoodsReceiveSearchParams,
    returnAll = false,
  ): Observable<GoodsReceive[]> {
    return this._list$<GoodsReceive>(params, returnAll)
  }

  /**
   * List goods-receive rows by params
   * @param params - The search params
   * @returns the observable<GoodsReceiveRow[]> for list goods-receive rows
   */
  listRows$(
    goodsReceiveId: string,
    params?: GoodsReceiveRowSearchParams,
    returnAll = false,
  ): Observable<GoodsReceiveRow[]> {
    return this._listChildren$(goodsReceiveId, 'rows', params, returnAll)
  }

  /**
   * Pend a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for pend the goods-receive
   */
  pend$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/pend`,
      {},
    )
  }

  /**
   * Confirm a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for confirm the goods-receive
   */
  confirm$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/confirm`,
      {},
    )
  }

  /**
   * Manage a goods-receive by ID (status PROCESSING)
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for manage the goods-receive
   */
  manage$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/manage`,
      {},
    )
  }

  /**
   * Process a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for process the order
   */
  process$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/process`,
      {},
    )
  }

  /**
   * Cancel a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for cancel the order
   */
  cancel$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/cancel`,
      {},
    )
  }

  /**
   * Close a goods-receive by ID
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<GoodsReceive> for close the order
   */
  close$(goodsReceiveId: string): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/close`,
      {},
    )
  }

  /**
   * Change goods-receive status
   * @param goodsReceiveId - The order ID
   * @param action - The change status action
   * @returns The Observable<GoodsReceive> for change the order status
   */
  changeStatus$(
    goodsReceiveId: string,
    action: GoodsReceiveChangeStatusAction,
  ): Observable<GoodsReceive> {
    switch (action) {
      case 'cancel':
        return this.cancel$(goodsReceiveId)
      case 'confirm':
        return this.confirm$(goodsReceiveId)
      case 'manage':
        return this.manage$(goodsReceiveId)
      case 'pend':
        return this.pend$(goodsReceiveId)
      case 'process':
        return this.process$(goodsReceiveId)
      case 'close':
        return this.close$(goodsReceiveId)
    }
  }

  /**
   * Scan a product for the goods-receive acceptance
   * @param goodsReceiveId - The goods-receive ID
   * @param productId - The product ID
   * @param params - The scan params
   * @returns The observable<GoodsReceive> for the goods-receive acceptance
   */
  productScan$(
    goodsReceiveId: string,
    productId: string,
    params: {
      locationId: string
      quantity: number
      lot?: string
      expirationDate?: string
      serial?: string
      notCompliantQuality?: boolean
    },
  ): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/products/${productId}/scan`,
      params,
    )
  }

  /**
   * Review a product for the goods-receive acceptance
   * @param goodsReceiveId - The goods-receive ID
   * @param productId - The product ID
   * @param params - The review params
   * @returns The observable<GoodsReceive> for the goods-receive acceptance
   */
  productReview$(
    goodsReceiveId: string,
    productId: string,
    params: {
      locationId: string
      quantity: number
      lot?: string
      expirationDate?: string
      serial?: string
      notCompliantQuality?: boolean
    },
  ): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/products/${productId}/review`,
      params,
    )
  }

  /**
   * Read a goods-receive row by ID
   * @param goodsReceiveId - The goods-receive ID
   * @param rowId - The row ID
   * @returns The observable<GoodsReceiveRow> for read the goods-receive-row
   */
  readRow$(
    goodsReceiveId: string,
    rowId: string,
  ): Observable<GoodsReceiveRow | undefined> {
    return this.read$(goodsReceiveId).pipe(
      map((goodsReceive) => goodsReceive.rows.find((r) => r._id === rowId)),
      tap((row) => {
        if (!row) {
          throw new Error('ROW_NOT_FOUND')
        }
      }),
    )
  }

  /**
   * Print goods-receive summary
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<void> for print the goods-receive summary
   */
  printSummary$(goodsReceiveId: string, printerId: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${goodsReceiveId}/files/summary/print`,
      {
        printerId,
      },
    )
  }

  /**
   * Download the goods-receive summary
   * @param goodsReceiveId - The goods-receive ID
   * @returns The observable<any> for download the goods-receive summary
   */
  downloadSummary$(goodsReceiveId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${goodsReceiveId}/files/summary`, {
      responseType: 'blob',
    })
  }

  /**
   * Generates the path to which attachments for a goods receive must be uploaded
   * @param goodsReceiveId - The goods receive ID
   * @returns the path
   */
  getGoodsReceiveAttachmentsUploadUrl(goodsReceiveId: string): string {
    return `${this.apiUrl}/${goodsReceiveId}/attachments`
  }

  /**
   * Creates an attachment for a goods receive
   * @param goodsReceiveId - The goods receive ID
   * @param attachment - The attachment as binary file
   * @returns the updated observable<Order>
   */
  createAttachment$(
    goodsReceiveId: string,
    attachment: any,
  ): Observable<GoodsReceive> {
    return this.http.post<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/attachments`,
      {
        body: attachment,
      },
    )
  }

  /**
   * Creates multiple attachments for a goods receive
   * @param goodsReceiveId - The goods receive ID
   * @param attachments - The list of attachments as binary files
   * @returns the updated observable<Order>
   */
  createAttachments$(
    goodsReceiveId: string,
    attachments: [any],
  ): Observable<GoodsReceive> {
    return concat(
      ...attachments.map((attachment) =>
        this.createAttachment$(goodsReceiveId, attachment),
      ),
    ).pipe(last())
  }

  /**
   * Deletes an attachment from a goods receive
   * @param goodsReceiveId - The goods receive ID
   * @param attachmentId - The attachment ID
   */
  deleteAttachment$(
    goodsReceiveId: string,
    attachmentId: string,
  ): Observable<GoodsReceive> {
    return this.http.delete<GoodsReceive>(
      `${this.apiUrl}/${goodsReceiveId}/attachments/${attachmentId}`,
      { body: {} },
    )
  }

  /**
   * Downloads an attachment from a goods receive
   * @param productId - The product ID
   * @param attachmentId - The attachment ID
   * @returns the observable<Attachment>
   */
  downloadAttachment$(
    goodsReceiveId: string,
    attachmentId: string,
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/${goodsReceiveId}/attachments/${attachmentId}/content`,
      { responseType: 'blob' },
    )
  }
}
