import { Component, Inject, Input } from '@angular/core'
import {
  MODAL_MANAGER,
  ModalComponent,
  ModalManager,
} from '@evologi/shared/data-access-api'

@Component({
  selector: 'op-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteComponent implements ModalComponent {
  @Input() modalId!: string
  @Input({ required: true }) entity!: string
  @Input() ref?: string
  @Input() notes?: string

  constructor(@Inject(MODAL_MANAGER) private modalManager: ModalManager) {}

  // Models

  delete() {
    this.modalManager.hide(this.modalId, { kind: 'DELETE' })
  }
}
