import {
  Product,
  ProductBatchManagement,
  ProductBatchSignature,
} from '../product.model'

export function isBatchManagementEditable(product: Product): boolean {
  return (
    !product.locations ||
    !product.locations.length ||
    product.locations.reduce(
      (inc, loc) => (inc += (loc.pickedQty || 0) + (loc.reservedQty || 0)),
      0
    ) === 0
  )
}

export function isBatchManageMentValid(product: Product): boolean {
  if (!product.batchManagement?.enabled) {
    return true
  }

  return (
    !!product.batchManagement.expirationDateRequired ||
    !!product.batchManagement.lotRequired ||
    !!product.batchManagement.serialRequired
  )
}

export function validateBatchSignature(
  batchManagement: ProductBatchManagement,
  batchSignature: ProductBatchSignature
): boolean {
  let isValid = true

  if (!batchManagement.enabled) {
    return isValid
  }

  const { lot, expirationDate, serial } = batchSignature

  if (batchManagement.lotRequired) {
    isValid = !!lot
  }

  if (batchManagement.expirationDateRequired) {
    isValid = isValid && !!expirationDate
  }

  if (batchManagement.serialRequired) {
    isValid = isValid && !!serial
  }

  return isValid
}
