import { isEqual, defaultsDeep, cloneDeep } from 'lodash'
import { Promotion, PromotionType } from './promotion.model'

// Initial states

const PROMOTION_INITIAL_STATE: Partial<Promotion> = {
  type: PromotionType.coupon,
  code: undefined,
  amount: undefined,
}

// Init functions

export function initPromotion(promotion: Partial<Promotion> = {}): Promotion {
  return defaultsDeep(cloneDeep(promotion), PROMOTION_INITIAL_STATE)
}

// Payments management

export function updateOrderPromotions(
  promotions: Promotion[],
  promotion: Promotion,
  promotionIndex?: number
): Promotion[] {
  return promotionIndex !== undefined
    ? promotions.map((p, i) => (i === promotionIndex ? promotion : p))
    : [...promotions, promotion]
}

export function removeOrderPromotion(
  promotions: Promotion[],
  promotion: Promotion
): Promotion[] {
  return promotions.filter((p) => !isEqual(p, promotion))
}
