import { AddressRecipientType } from '../../models/address.model'
import { Entity } from '../../models/entity.model'
import { Locales } from '../../models/util.model'
import { InvoiceType } from '../invoices/invoice.model'
import { OrderTransport } from '../orders/order.model'
import { Payment } from '../payments/payment.model'

export interface Tenant extends Entity {
  name: string
  shortName?: string
  maxUsers?: number
  email?: string
  vat?: string
  fiscalCode?: string
  phone?: string
  address?: TenantAddress
  payments?: Payment[]
  defaults?: TenantDefaults
  simpleOrders?: TenantSimpleOrders
  fastProcess?: boolean
  orders?: TenantOrders
  supplierOrders?: TenantSupplierOrders
  goodsReceive?: TenantGoodsReceives
  catalog?: TenantCatalog
  akite?: TenantAkite
  shippyproSettings?: TenantShippyPro
  gspedSettings?: TenantGsped
  qaplaSettings?: TenantQapla
  dropShipping?: TenantDropShipping
  packing?: TenantPacking
  disk?: TenantDisk
}

export interface TenantAddress {
  businessName?: string
  address1?: string
  city?: string
  province?: string
  postalCode?: string
  countryCode?: string
}

export interface TenantAkite {
  username?: string
  password?: string
}

export interface TenantShippyPro {
  apiKey?: string
}
export interface TenantDefaults {
  warehouseId?: string
  carrierId?: string
  country?: string
  channel?: string
  payment?: string
  currency?: string
  transport?: OrderTransport
  orderVat?: number
  shippingVat?: number
  printNodeKey?: string
}

export interface TenantSimpleOrders {
  active: boolean
  defaultStatus?:
    | 'DRAFTED'
    | 'PENDING'
    | 'CONFIRMED'
    | 'PROCESSING'
    | 'PROCESSED'
    | 'PACKED'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'PARKED'
    | 'HANDED'
    | 'CANCELED'
    | 'DROPSHIPPED'
  assignedWarehouseId?: string
  carrierId?: string
  channel?: string
  payment?: string
  currency?: string
  transport?: string
  shippingCost?: number
  shippingTaxes?: number
}

export interface TenantSupplierOrders {
  warehouseId?: string
  productionExport?: TenantSupplierOrdersProductionExport
}

export interface TenantSupplierOrdersProductionExport {
  isActive: boolean
  title?: string
  xAttributeId?: string
  yAttributeId?: string
}

export interface TenantGoodsReceives {
  warehouseId?: string
  locationId?: string
  transport?: string
  payment?: string
  shippingCost?: number
  shippingTaxes?: number
  productInfoRequired?: boolean
  toteLoadingAlert?: boolean
}

export interface TenantCatalog {
  classifications?: TenantCatalogClassifications
  mandatoryProductProperties?: {
    barcodes?: TenantCatalogBarcode[]
  }
  batchManagement?: {
    automaticExpiringDay?: boolean
    lotPresetFormat?: 'YYYYMMDD' | 'YYYYMM' | 'YYYY-MM-DD' | 'YYYY-MM'
  }
  incomingHistory?: {
    active?: boolean
    daysPeriod?: number
  }
  currencies?: string[]
  locales?: string[]
  siteConflictAlert?: boolean
}

export interface TenantCatalogClassifications {
  active: boolean
  labels?: Locales<string>[]
}

export interface TenantCatalogBarcode {
  type: 'EAN13' | 'EAN8' | 'MINSAN' | 'GENERIC' | 'GTIN14'
  strict: boolean
}

export interface TenantGsped {
  apiNamespace?: string
  apiKey?: string
}

export interface TenantQapla {
  active: boolean
  callOn: 'PACKING' | 'SHIPPING'
  reference: 'rifOrder' | 'orderNumber'
}

export interface TenantOrders {
  shippingAddressType?: AddressRecipientType
  invoiceType?: InvoiceType
}

export interface TenantDropShipping {
  active: boolean
  createInvoice: boolean
}
export interface TenantPacking {
  packageLabelPrint?: {
    isActive: boolean
    title?: string
    shippingAddressType?: AddressRecipientType
  }
}

export interface TenantDisk {
  /**
   * Disk quota in GiB (default is 10 GiB).
   */
  quota?: number
  /**
   * Disk usage in bytes (all uploaded files).
   */
  usage?: number
  /**
   * Prevents disk quota auto-scale (tier up).
   */
  locked?: boolean
}

export enum TenantTab {
  general = 'general',
  catalog = 'catalog',
  sales = 'sales',
  purchases = 'purchases',
  logistics = 'logistics',
}
