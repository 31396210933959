import moment from 'moment'
import { isDate } from '@evologi/shared/util-toolkit'
import {
  ProductField,
  ProductSearchParams,
  ProductSortField,
} from '../product.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'

// Datagrid

export function productSortParams(
  params: ProductSearchParams,
  sort: QueryStringSort<ProductSortField>,
): ProductSearchParams {
  const searchParams: ProductSearchParams = {}

  switch (sort.field) {
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'updatedAt':
      searchParams.sort = 'updatedAt'
      searchParams.order = sort.order
      break
    case 'SKU':
      searchParams.sort = 'SKU'
      searchParams.order = sort.order
      break
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'maxProvisions':
      searchParams.sort = 'maxProvisions'
      searchParams.order = sort.order
      break
    case 'minProvisions':
      searchParams.sort = 'minProvisions'
      searchParams.order = sort.order
      break
    case 'netWeight':
      searchParams.sort = 'netWeight'
      searchParams.order = sort.order
      break
    case 'grossWeight':
      searchParams.sort = 'grossWeight'
      searchParams.order = sort.order
      break
    case 'abcClass':
      searchParams.sort = 'abcClass'
      searchParams.order = sort.order
      break
    case 'volume.total':
      searchParams.sort = 'volume.total'
      searchParams.order = sort.order
      break
    case 'price.purchase':
      searchParams.sort = 'price.purchase'
      searchParams.order = sort.order
      break
    case 'price.listing':
      searchParams.sort = 'price.listing'
      searchParams.order = sort.order
      break
    case 'price.discount':
      searchParams.sort = 'price.discount'
      searchParams.order = sort.order
      break
    case 'onHandQty':
      searchParams.sort = 'onHandQty'
      searchParams.order = sort.order
      break
    case 'availableQty':
      searchParams.sort = 'availableQty'
      searchParams.order = sort.order
      break
    case 'incomingQty':
      searchParams.sort = 'incomingQty'
      searchParams.order = sort.order
      break
    case 'receivingQty':
      searchParams.sort = 'receivingQty'
      searchParams.order = sort.order
  }

  if (sort.field.match(/^(attributes.)/g)) {
    searchParams.sort = sort.field
    searchParams.order = sort.order
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function productFilterParams(
  params: ProductSearchParams,
  filter: QueryStringFilter<ProductField>,
): ProductSearchParams {
  const searchParams: ProductSearchParams = {}

  // Check date object
  if (
    ['string', 'object'].includes(typeof filter.value) &&
    isDate(filter.value)
  ) {
    filter.value = moment(filter.value).utc().toISOString()
  }

  // Check field
  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams['createdAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'updatedAt':
      if (filter.operator === '=') {
        searchParams['updatedAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['updatedAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['updatedAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['updatedAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['updatedAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['updatedAt:ne'] = filter.value
      }
      break
    case 'SKU':
      if (filter.operator === '=') {
        searchParams.SKU = filter.value
      } else if (filter.operator === '<>') {
        searchParams['SKU:ne'] = filter.value
      }
      break
    case 'barcodes':
      if (filter.operator === '=') {
        searchParams.barcodes = filter.value
      } else if (filter.operator === '<>') {
        searchParams['barcodes:ne'] = filter.value
      }
      break
    case 'channels._id':
      if (filter.value !== null) {
        searchParams['channels._id'] = filter.value
        searchParams['channels.active'] = true
      } else {
        searchParams['channels._id:ex'] = false
      }
      break
    case 'channels.SKU':
      if (filter.operator === '=') {
        searchParams['channels.SKU'] = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      }
      break
    case 'shortDescription':
      if (filter.operator === '=') {
        searchParams.shortDescription = filter.value
      } else if (filter.operator === '<>') {
        searchParams['shortDescription:ne'] = filter.value
      }
      break
    case 'description':
      if (filter.operator === '=') {
        searchParams.description = filter.value
      } else if (filter.operator === '<>') {
        searchParams['description:ne'] = filter.value
      }
      break
    case 'notes':
      if (filter.operator === '=') {
        searchParams.notes = filter.value
      } else if (filter.operator === '<>') {
        searchParams['notes:ne'] = filter.value
      }
      break
    case 'productType':
      if (filter.value !== null) {
        searchParams.productType = filter.value
      } else {
        searchParams['productType:ex'] = false
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams.status = filter.value
      } else {
        searchParams['status:ex'] = false
      }
      break
    case 'categories':
      if (filter.value !== null) {
        searchParams.categories = filter.value
      } else {
        searchParams['categories:ex'] = false
      }
      break
    case 'classifications':
      if (filter.value !== null) {
        searchParams.classifications = filter.value
      } else {
        searchParams['classifications:ex'] = false
      }
      break
    case 'family.code':
      if (filter.value !== null) {
        searchParams['family.code'] = filter.value
      } else {
        searchParams['family.code:ex'] = false
      }
      break
    case 'family.complete':
      if (filter.operator === '=') {
        searchParams['family.complete'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['family.complete:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['family.complete:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['family.complete:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['family.complete:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['family.complete:ne'] = filter.value
      }
      break

    case 'parentId':
      if (filter.value !== null) {
        searchParams.parentId = filter.value
      } else {
        searchParams['parentId:ex'] = false
      }
      break
    case 'kitIds':
      if (filter.value !== null) {
        searchParams.kitIds = filter.value
      } else {
        searchParams['kitIds:ex'] = false
      }
      break
    case 'brandId':
      if (filter.value !== null) {
        searchParams.brandId = filter.value
      } else {
        searchParams['brandId:ex'] = false
      }
      break
    case 'manufacturerId':
      if (filter.value !== null) {
        searchParams.manufacturerId = filter.value
      } else {
        searchParams['manufacturerId:ex'] = false
      }
      break
    case 'suppliers.supplierId':
    case 'suppliers._id':
      if (filter.value !== null) {
        searchParams['suppliers._id'] = filter.value
      } else {
        searchParams['suppliers._id:ex'] = false
      }
      break
    case 'suppliers.SKU':
      if (filter.operator === '=') {
        searchParams['suppliers.SKU'] = filter.value
      }
      break
    case 'tags':
      if (filter.value !== null) {
        searchParams.tags = filter.value
      } else {
        searchParams['tags:ex'] = false
      }
      break
    case 'maxProvisions':
      if (filter.operator === '=') {
        searchParams['maxProvisions'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['maxProvisions:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['maxProvisions:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['maxProvisions:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['maxProvisions:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['maxProvisions:ne'] = filter.value
      }
      break
    case 'minProvisions':
      if (filter.operator === '=') {
        searchParams['minProvisions'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['minProvisions:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['minProvisions:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['minProvisions:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['minProvisions:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['minProvisions:ne'] = filter.value
      }
      break
    case 'netWeight':
      if (filter.operator === '=') {
        searchParams['netWeight'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['netWeight:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['netWeight:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['netWeight:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['netWeight:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['netWeight:ne'] = filter.value
      }
      break
    case 'grossWeight':
      if (filter.operator === '=') {
        searchParams['grossWeight'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['grossWeight:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['grossWeight:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['grossWeight:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['grossWeight:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['grossWeight:ne'] = filter.value
      }
      break
    case 'logisticsDescription':
      if (filter.operator === '=') {
        searchParams.logisticsDescription = filter.value
      } else if (filter.operator === '<>') {
        searchParams['logisticsDescription:ne'] = filter.value
      }
      break
    case 'abcClass':
      if (filter.value === null) {
        searchParams['abcClass:ex'] = filter.operator === '<>'
      } else if (filter.operator === '=') {
        searchParams.abcClass = filter.value
      } else if (filter.operator === '<>') {
        searchParams['abcClass:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['abcClass:ct'] = filter.value
      }
      break
    case 'webEnabled':
      searchParams['webEnabled'] = filter.value
      break
    case 'volume.w':
      if (filter.operator === '=') {
        searchParams['volume.w'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['volume.w:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['volume.w:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['volume.w:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['volume.w:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['volume.w:ne'] = filter.value
      }
      break
    case 'volume.h':
      if (filter.operator === '=') {
        searchParams['volume.h'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['volume.h:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['volume.h:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['volume.h:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['volume.h:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['volume.h:ne'] = filter.value
      }
      break
    case 'volume.l':
      if (filter.operator === '=') {
        searchParams['volume.l'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['volume.l:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['volume.l:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['volume.l:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['volume.l:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['volume.l:ne'] = filter.value
      }
      break
    case 'volume.total':
      if (filter.operator === '=') {
        searchParams['volume.total'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['volume.total:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['volume.total:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['volume.total:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['volume.total:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['volume.total:ne'] = filter.value
      }
      break
    case 'isDropShip':
      searchParams['isDropShip'] = filter.value
      break
    case 'files':
      searchParams['files:ex'] = filter.value
      break
    case 'batchManagement':
    case 'batchManagement.enabled':
      searchParams['batchManagement'] = filter.value
      break
    case 'batchManagement.lotRequired':
      searchParams['batchManagement.lotRequired'] = filter.value
      break
    case 'batchManagement.expirationDateRequired':
      searchParams['batchManagement.expirationDateRequired'] = filter.value
      break
    case 'batchManagement.serialRequired':
      searchParams['batchManagement.serialRequired'] = filter.value
      break
    case 'infiniteStocks':
      searchParams['infiniteStocks'] = filter.value
      break
    case 'stockCountId':
      if (filter.value !== null) {
        searchParams.stockCountId = filter.value
      } else {
        searchParams['stockCountId:ex'] = false
      }
      break
    case 'countedAt':
      if (filter.operator === '=') {
        searchParams['countedAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['countedAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['countedAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['countedAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['countedAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['countedAt:ne'] = filter.value
      }
      break
    case 'price.purchase':
      if (filter.operator === '=') {
        searchParams['price.purchase'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['price.purchase:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['price.purchase:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['price.purchase:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['price.purchase:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['price.purchase:ne'] = filter.value
      }
      break
    case 'price.listing':
      if (filter.operator === '=') {
        searchParams['price.listing'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['price.listing:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['price.listing:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['price.listing:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['price.listing:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['price.listing:ne'] = filter.value
      }
      break
    case 'price.discount':
      if (filter.operator === '=') {
        searchParams['price.discount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['price.discount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['price.discount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['price.discount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['price.discount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['price.discount:ne'] = filter.value
      }
      break
    case 'taxRate':
      if (filter.operator === '=') {
        searchParams['taxRate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['taxRate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['taxRate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['taxRate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['taxRate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['taxRate:ne'] = filter.value
      }
      break
    case 'onHandQty':
      if (filter.operator === '=') {
        searchParams['onHandQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['onHandQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['onHandQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['onHandQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['onHandQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['onHandQty:ne'] = filter.value
      }
      break
    case 'availableQty':
      if (filter.operator === '=') {
        searchParams['availableQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['availableQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['availableQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['availableQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['availableQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['availableQty:ne'] = filter.value
      }
      break
    case 'incomingQty':
      if (filter.operator === '=') {
        searchParams['incomingQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['incomingQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['incomingQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['incomingQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['incomingQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['incomingQty:ne'] = filter.value
      }
      break
    case 'receivingQty':
      if (filter.operator === '=') {
        searchParams['receivingQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['receivingQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['receivingQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['receivingQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['receivingQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['receivingQty:ne'] = filter.value
      }
      break
    case 'scrappedQty':
      if (filter.operator === '=') {
        searchParams['scrappedQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['scrappedQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['scrappedQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['scrappedQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['scrappedQty:ge'] = filter.value
      }
      break
    case 'packQty':
      if (filter.operator === '=') {
        searchParams['packQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['packQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['packQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['packQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['packQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['packQty:ne'] = filter.value
      }
      break
    case 'palletQty':
      if (filter.operator === '=') {
        searchParams['palletQty'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['palletQty:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['palletQty:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['palletQty:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['palletQty:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['palletQty:ne'] = filter.value
      }
      break
    case 'customsInfo.description':
      if (filter.operator === '=') {
        searchParams['customsInfo.description'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['customsInfo.description:ne'] = filter.value
      }
      break
    case 'customsInfo.hsCode':
      if (filter.operator === '=') {
        searchParams['customsInfo.hsCode'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['customsInfo.hsCode:ne'] = filter.value
      }
      break
    case 'customsInfo.originCountry':
      if (filter.operator === '=') {
        searchParams['customsInfo.originCountry'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['customsInfo.originCountry:ne'] = filter.value
      }
      break
  }

  // Check attribute
  if (
    filter.field.startsWith('attributes.') ||
    filter.field.startsWith('attributesData.')
  ) {
    let field = filter.field.replace('attributes.', '')
    field = field.replace('attributesData.', '')
    field = `attributes.${field}`

    if (filter.value === null) {
      searchParams[`${field}:ex`] = false
    } else if (filter.operator === '=') {
      searchParams[field] = filter.value
    } else if (filter.operator === '<') {
      searchParams[`${field}:lt`] = filter.value
    } else if (filter.operator === '<=') {
      searchParams[`${field}:le`] = filter.value
    } else if (filter.operator === '>') {
      searchParams[`${field}:gt`] = filter.value
    } else if (filter.operator === '>=') {
      searchParams[`${field}:ge`] = filter.value
    } else if (filter.operator === '<>') {
      searchParams[`${field}:ne`] = filter.value
    } else if (filter.operator === 'contains') {
      searchParams[`${field}:ct`] = filter.value
    }
  }

  return {
    ...params,
    ...searchParams,
  }
}
