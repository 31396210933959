import { defaultsDeep, uniq } from 'lodash'
import { ProductSite } from '../product.model'
import { Location } from '../../locations/location.model'

/**
 * Initialize a product site
 * @param site - the partial site
 * @returns the product site
 */
export function initProductSite(site: Partial<ProductSite> = {}): ProductSite {
  return defaultsDeep(site, {})
}

/**
 * Add product sites by locations
 * @param sites - the product sites
 * @param locations - the locations to add
 * @returns the product sites updated
 */
export function addProductSitesByLocations(
  sites: ProductSite[],
  locations: Location[]
): ProductSite[] {
  return [
    ...sites,
    ...locations.map((l) => initProductSite({ locationId: l._id })),
  ]
}

/**
 * Add a site to a product
 * @param sites - the product sites
 * @param site - the site to add
 * @returns the sites updated
 */
export function addProductSite(
  sites: ProductSite[],
  site?: Partial<ProductSite>
): ProductSite[] {
  return [...sites, initProductSite(site)]
}

/**
 * Remove a site from a product
 * @param sites - the product sites
 * @param siteIndex - the site index
 * @returns the sites updated
 */
export function removeProductSite(
  sites: ProductSite[],
  siteIndex?: number
): ProductSite[] {
  const indexToRemove = siteIndex !== undefined ? siteIndex : sites.length - 1
  return sites.filter((p, i) => i !== indexToRemove)
}

/**
 * Get all sites location IDs
 * @param sites - the product sites
 * @returns the product location IDs
 */
export function getProductSitesLocationIds(sites: ProductSite[]): string[] {
  return uniq(sites.map((s) => s.locationId))
}
