import { cloneDeep, defaultsDeep } from 'lodash'
import {
  DeepPartial,
  Permission,
  Permissions,
  PermissionsLevel,
  PermissionsScope,
  PermissionSignature,
  PermissionsCheckMode,
  PermissionsSubObject,
} from '@evologi/shared/data-access-api'

// Initial states

const PERMISSIONS_INITIAL_STATE: DeepPartial<Permissions> = {
  activities: { level: 'DENY' },
  banners: { level: 'DENY' },
  bordereaus: { level: 'DENY' },
  brands: { level: 'DENY' },
  carriers: { level: 'DENY' },
  catalogs: { level: 'DENY' },
  categories: { level: 'DENY' },
  channels: { level: 'DENY' },
  classifications: { level: 'DENY' },
  customers: { level: 'DENY' },
  files: { level: 'DENY' },
  goodsReceives: { level: 'DENY' },
  goodsReturns: { level: 'DENY' },
  iam: { level: 'DENY' },
  integrations: { level: 'DENY' },
  invoices: { level: 'DENY' },
  locations: { level: 'DENY' },
  manufacturers: { level: 'DENY' },
  movements: { level: 'DENY' },
  orders: { level: 'DENY' },
  packingLists: { level: 'DENY' },
  payments: { level: 'DENY' },
  pickingLists: { level: 'DENY' },
  pickupPoints: { level: 'DENY' },
  products: { level: 'DENY' },
  replenishments: { level: 'DENY' },
  supplierOrders: { level: 'DENY' },
  suppliers: { level: 'DENY' },
  tenant: { level: 'READ_ONLY' },
}

// Init

export function initPermissions(
  permissions: Partial<Permissions> = {}
): Permissions {
  return defaultsDeep(cloneDeep(permissions), PERMISSIONS_INITIAL_STATE)
}

// Check

export function checkPermissionLevel(
  permission: Permission,
  level: PermissionsLevel = 'READ_ONLY'
): boolean {
  if (level === 'DENY') {
    return false
  } else if (level === 'READ_ONLY') {
    return permission.level === 'READ_ONLY' || permission.level === 'READ_WRITE'
  } else {
    return permission.level === level
  }
}

export function checkPermissionArray(
  permission: Permission[],
  level: PermissionsLevel = 'READ_ONLY'
): boolean {
  return permission.some((p) => checkPermissionLevel(p, level))
}

export function checkPermission(
  permissions: Permissions | undefined,
  scope: PermissionsScope,
  level: PermissionsLevel = 'READ_ONLY'
): boolean {
  if (level === 'DENY' || !permissions || !permissions[scope]) {
    return false
  }

  const permission = permissions[scope]
  if (Array.isArray(permission)) {
    return checkPermissionArray(permission, level)
  } else {
    return checkPermissionLevel(permission, level)
  }
}

export function checkPermissions(
  permissions: Permissions | undefined,
  scopes: PermissionsScope[],
  level: PermissionsLevel = 'READ_ONLY',
  mode: PermissionsCheckMode = 'ONE'
): boolean {
  if (!permissions) {
    return false
  }

  if (mode === 'ONE') {
    return scopes.some((scope) => checkPermission(permissions, scope, level))
  } else if (mode === 'ALL') {
    return scopes.every((scope) => checkPermission(permissions, scope, level))
  } else {
    return !scopes.every((scope) => checkPermission(permissions, scope, level))
  }
}

export function signaturesToPermissions(
  signatures: PermissionSignature[]
): Permissions {
  const permissions = signatures.reduce(
    (acc, sign) => ({
      ...acc,
      [sign.scope]: {
        level: sign.level,
      },
    }),
    {}
  )
  return initPermissions(permissions)
}

// Levels management

export function initPermissionSubLevels(
  level: PermissionsLevel | PermissionsSubObject
) {
  if (
    level === 'PICKUP_POINT_ORDERS' ||
    level === 'WAREHOUSE_ORDERS' ||
    level === 'SUPPLIER_PRODUCTS'
  ) {
    return [initPermissionSubLevel(level)]
  }

  return undefined
}

export function initPermissionSubLevel(
  subObjectType: PermissionsSubObject
): Permission {
  return {
    level: 'READ_ONLY',
    objectType: subObjectType,
    objectId: undefined,
    foreign: false,
    policies: [],
  }
}

export function addPermissionSubLevel(
  subLevels: Permission[],
  subObjectType: PermissionsSubObject
): Permission[] {
  return [...subLevels, initPermissionSubLevel(subObjectType)]
}

export function removePermissionSubLevel(
  subLevels: Permission[],
  index: number
): Permission[] {
  subLevels.splice(index, 1)
  return [...subLevels]
}

export function getPermissionsObjectId(
  permission: Permission | Permission[],
  objectType: 'WAREHOUSE_ORDERS' | 'PICKUP_POINT_ORDERS' | 'SUPPLIER_PRODUCTS'
): string | undefined {
  const permissionObject = Array.isArray(permission)
    ? permission.find((p) => p.objectType === objectType)
    : permission.objectType === objectType
    ? permission
    : undefined

  if (!permissionObject) {
    return undefined
  }

  return permissionObject.objectId
}
