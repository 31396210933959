import { Product, ProductCatalogCategory, ProductScope } from '../product.model'

/**
 * Remove empty category objects from product
 * @param categories - the product categories to check
 * @returns the product categories updated
 */
export function removeProductEmptyCategories(
  categories?: ProductCatalogCategory[],
): ProductCatalogCategory[] | undefined {
  const newCategories = categories
    ? categories.filter((cat) => !!cat.ids.length)
    : []
  return newCategories.length ? [...newCategories] : undefined
}

/**
 * Set product categories scoped
 * @param product - the product to update
 * @param categoryIds - the categories IDs
 * @param scope - the scope
 * @returns the product updated
 */
export function setProductCategories(
  product: Product,
  categoryIds: string[],
  scope: ProductScope,
): Product {
  const prodCategories = product.categories || []
  let scopedCategories = prodCategories.find(
    (c) => c.catalogCode === scope.catalogCode,
  )

  if (!scopedCategories) {
    return {
      ...product,
      categories: [
        ...prodCategories,
        { ids: categoryIds, catalogCode: scope.catalogCode },
      ],
    }
  }

  // Update scoped categories
  scopedCategories = {
    ...scopedCategories,
    ids: categoryIds,
    primaryId:
      scopedCategories.primaryId &&
      categoryIds.includes(scopedCategories.primaryId)
        ? scopedCategories.primaryId
        : undefined,
  }

  return {
    ...product,
    categories: prodCategories.map((c) =>
      c.catalogCode === scope.catalogCode ? scopedCategories! : c,
    ),
  }
}

/**
 * Set product primary category
 * @param product - the product to update
 * @param categoryIds - the category ID to set as primary
 * @param scope - the scope
 * @returns the product updated
 */
export function setProductPrimaryCategory(
  product: Product,
  categoryId: string,
  scope: ProductScope,
): Product {
  const prodCategories = product.categories || []
  const scopedCategories = prodCategories.find(
    (c) => c.catalogCode === scope.catalogCode,
  )

  if (!scopedCategories) {
    return product
  }

  scopedCategories.primaryId = categoryId

  return {
    ...product,
    categories: prodCategories.map((c) =>
      c.catalogCode === scope.catalogCode ? scopedCategories : c,
    ),
  }
}

/**
 * Unset product primary category
 * @param product - the product to update
 * @param scope - the scope
 * @returns the product updated
 */
export function unsetProductPrimaryCategory(
  product: Product,
  scope: ProductScope,
): Product {
  const prodCategories = product.categories || []
  const scopedCategories = prodCategories.find(
    (c) => c.catalogCode === scope.catalogCode,
  )

  if (!scopedCategories) {
    return product
  }

  scopedCategories.primaryId = undefined
  return {
    ...product,
    categories: prodCategories.map((c) =>
      c.catalogCode === scope.catalogCode ? scopedCategories : c,
    ),
  }
}
