import { Attribute } from '../../attributes'

/**
 * Add view field
 * @param fields - the view fields
 * @param field - the field
 * @returns the fields
 */
export function addViewField(
  fields: string[] | undefined,
  field: string
): string[] {
  return [...(fields || []), field]
}

/**
 * Add view attribute field
 * @param fields - the view fields
 * @param attribute - the attribute
 * @returns the fields
 */
export function addViewAttributeField(
  fields: string[] | undefined,
  attribute: Attribute
): string[] {
  return [...(fields || []), `attribute_${attribute.code}`]
}

/**
 * Remove view field
 * @param fields - the view fields
 * @param field - the field
 * @returns the fields
 */
export function removeViewField(
  fields: string[] | undefined,
  field: string
): string[] | undefined {
  if (!fields) {
    return fields
  }

  fields = fields.filter((f) => f !== field)
  return fields.length ? fields : undefined
}
