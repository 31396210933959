import { Entity } from '../../models/entity.model'
import { Printers } from '../print-node/print-node.model'
import { QueryStringParams } from '../../models/query-string.model'
import { StatusHistory } from '../../models/util.model'
import {
  Order,
  OrderAdditionalPackage,
  OrderPackage,
} from '../orders/order.model'
import {
  PickingList,
  PickingListType,
} from '../picking-lists/picking-list.model'
import { Product, ProductVolume } from '../products/product.model'
import { Location } from '../locations/location.model'
import { User, UserTenantSettings } from '../users/user.model'
import { Warehouse } from '../warehouses'

/**
 * Possible packing list statuses:
 * - `PROCESSING`: There's at least one order that can be processed.
 * - `PACKED`: All orders are PACKED.
 * - `PRINTED`: All orders are PRINTED.
 * - `CANCELED`: All orders are CANCELED.
 */
export enum PackingListStatus {
  processing = 'PROCESSING',
  packed = 'PACKED',
  printed = 'PRINTED',
  canceled = 'CANCELED',
}

/**
 * Possible packing list types:
 * - `PACK`: Per order
 * - `SORT`: Massive.
 */
export enum PackingListType {
  pack = 'PACK',
  sort = 'SORT',
}

/**
 * Possibile packing list's order statuses.
 * - `WAITING`: The order is still being picked by a picking list.
 * - `READY`: The order was picked and is ready to be packed.
 * - `PROCESSING`: The order has at least one piece packed and **cannot** be CANCELED.
 * - `PACKED`: The order is fully packed and ready to be shipped.
 * - `PRINTED`: The order has its shipping label and the shipment was requested to the carrier.
 * - `CANCELED`: The order was canceled **before** any packing.
 */
export enum PackingListOrderStatus {
  waiting = 'WAITING',
  ready = 'READY',
  processing = 'PROCESSING',
  packed = 'PACKED',
  printed = 'PRINTED',
  canceled = 'CANCELED',
}

export interface PackingList extends Entity {
  warehouseId: string
  pickingListId: string
  userId: string
  locationId?: string
  status: PackingListStatus
  statusHistory: StatusHistory[]
  type: PackingListType
  orders: PackingListOrder[]
  actions: PackingListAction[]
  printers: PackingListPrinters
}

export interface PackingListOrder {
  _id: string
  status: PackingListOrderStatus
  code: string
}

export interface PackingListAction {
  _id: string
  qtyToPack: number
  qtyPacked: number
  toteId: string
  orderId: string
  productId: string
  lot?: string
  expirationDate?: Date
  serial?: string
  volume?: ProductVolume
  grossWeight?: number
}

export interface PackingListScanParams {
  productId: string
  quantity: number
  lot?: string
  expirationDate?: Date
  serial?: string
}

export interface PackingListPackParams {
  packages: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]
  printers?: Printers
  force: boolean
}

export interface PackingListPrinters {
  labels: string
  a4: string
}

export interface PackingListPackages {
  packages: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]
}

export interface PackingListsListingPage {
  data?: PackingList[]
  extData?: PackingListsListingData
  totalCount?: number
}

export interface PackingListsListingKeys {
  pickingListIds?: string[]
  warehouseIds?: string[]
  userIds?: string[]
}

export interface PackingListsListingData {
  pickingLists?: PickingList[]
  warehouses?: Warehouse[]
  users?: User[]
}

export type PackingListNotificationCode =
  | 'PACKED_BY_ANOTHER_USER'
  | 'ORDER_PACKED'
  | 'TOTE_NOT_FOUND'
  | 'ORDER_NOT_FOUND'
  | 'ORDER_NOT_SELECTED'
  | 'ORDER_UPDATED'
  | 'ORDER_PRINTED'
  | 'TOTE_EMPTY'
  | 'PACKAGING_ADDED'
  | 'PACKAGING_DISABLED'
  | 'PICKING_WITHOUT_TOTES'
  | 'PICKING_NOT_PACKABLE'
  | 'STATION_NOT_SELECTED'
  | 'PRODUCT_NOT_FOUND'
  | 'PRODUCT_PACK_NOT_CONTAINED'
  | 'PRODUCT_ORDER_NOT_CONTAINED'
  | 'PRODUCT_ALREADY_PACKED'

export interface PackingListNotificationData {
  pickingListName?: string
  user?: User
}

export interface PackingListNotificationOptions {
  code: PackingListNotificationCode
  data?: PackingListNotificationData
}

export type PackingListField =
  | '_id'
  | 'pickingListId'
  | 'type'
  | 'status'
  | 'warehouseId'
  | 'createdAt'
  | 'startedBy'
  | 'packedBy'
  | 'ordersCount'
  | 'missionsCount'

export type PackingListSearchField =
  | 'pickingListId'
  | 'warehouseId'
  | 'createdAt'
  | 'type'
  | 'status'

export type PackingListSortField = 'createdAt'

export interface PackingListSearchParams
  extends QueryStringParams<PackingListSortField> {
  pickingListId?: string | string[]
  warehouseId?: string | string[]
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  type?: PickingListType | PickingListType[]
  status?: PackingListStatus | PackingListStatus[]
}

export type PrintingType = 'manual' | 'automatic'

export interface PackingListPickData {
  pickingList: PickingList
  packingList?: PackingList
  pickingUser?: User
  orders: Order[]
  totes: Location[]
}

export interface PackingListAuthData {
  user?: User
  settings?: UserTenantSettings
  warehouse?: Warehouse
}

export interface PackingListStationData {
  warehouseId: string
  printingType: PrintingType
  packagingLocation?: Location
  packagingManagement?: boolean
  currentUserId?: string
  computerId?: string
  printerId?: string
  A4PrinterId?: string
}

export interface PackingListPackData extends PackingListOrderData {
  packingList: PackingList
  lastOrder?: Order
}

export interface PackingListOrderData {
  order?: Order
  action?: PackingListAction
  products?: Product[]
  packages?: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]
}
