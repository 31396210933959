import { cloneDeep, defaultsDeep } from 'lodash'

import {
  AutomationSearchParams,
  AutomationSearchField,
  AutomationSortField,
  Automation,
  AutomationChangeStatus,
  AutomationEmail,
  AutomationEvent,
  AutomationAction,
} from './automation.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import { DeepPartial } from '../../models/util.model'

// Initial states

const AUTOMATION_INITIAL_STATE: Partial<Automation> = {
  name: undefined,
  event: undefined,
  action: undefined,
  isActive: false,
}

const CHANGE_STATUS_INITIAL_STATE: Partial<AutomationChangeStatus> = {
  collection: undefined,
  status: undefined,
}

const EMAIL_INITIAL_STATE: DeepPartial<AutomationEmail> = {
  smtp: {
    host: undefined,
    port: undefined,
    username: undefined,
    password: undefined,
  },
  from: undefined,
  subject: undefined,
}

// Init methods

export function initAutomation(
  automation: Partial<Automation> = {}
): Automation {
  return defaultsDeep(cloneDeep(automation), AUTOMATION_INITIAL_STATE)
}

export function initAutomationData(automation: Automation): Automation {
  const changeStatus =
    automation.event === AutomationEvent.change_status
      ? defaultsDeep(
          cloneDeep(automation.changeStatus),
          CHANGE_STATUS_INITIAL_STATE
        )
      : undefined
  const emailSettings =
    automation.action === AutomationAction.submit_email
      ? defaultsDeep(cloneDeep(automation.emailSettings), EMAIL_INITIAL_STATE)
      : undefined

  return {
    ...automation,
    changeStatus,
    emailSettings,
  }
}

// Search

export function automationSortParams(
  params: AutomationSearchParams,
  sort: QueryStringSort<AutomationSortField>
): AutomationSearchParams {
  const searchParams: AutomationSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function automationFilterParams(
  params: AutomationSearchParams,
  filter: QueryStringFilter<AutomationSearchField>
): AutomationSearchParams {
  const searchParams: AutomationSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'action':
      if (filter.value !== null) {
        searchParams.action = filter.value
      }
      break
    case 'event':
      if (filter.value !== null) {
        searchParams.event = filter.value
      }
      break
    case 'isActive':
      searchParams.isActive = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
