import { Inject, Injectable, Optional } from '@angular/core'
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
} from 'rxjs'

import { ViewsService } from './views.service'
import { BrandsService } from '../brands'
import { ProductsService } from '../products'
import { WarehousesService } from '../warehouses'
import { CategoriesService } from '../categories'
import { ChannelsService } from '../channels'
import { SuppliersService } from '../suppliers'
import { ManufacturersService } from '../manufacturers'
import { CatalogsService, getCatalogValue } from '../catalogs'
import { Attribute, AttributesService } from '../attributes'
import { CarriersService } from '../carriers'
import { PaymentsService } from '../payments'
import {
  View,
  ViewData,
  ViewExternalData,
  ViewNotification,
  ViewTarget,
} from './view.model'
import {
  getViewAttributeCodes,
  getViewExternalData,
  initDefaultView,
  parseViewNotification,
} from './libs/view.lib'
import { VIEW_DEFAULT_CODE } from './view.const'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'

@Injectable({
  providedIn: 'root',
})
export class ViewsRepository {
  constructor(
    private viewsService: ViewsService,
    private brandsService: BrandsService,
    private productsService: ProductsService,
    private warehousesService: WarehousesService,
    private categoriesService: CategoriesService,
    private channelsService: ChannelsService,
    private suppliersService: SuppliersService,
    private manufacturersService: ManufacturersService,
    private catalogsService: CatalogsService,
    private attributesService: AttributesService,
    private carriersService: CarriersService,
    private paymentsService: PaymentsService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Load views data
   * @param viewId - the view ID
   * @param target - the view target
   * @param viewType - the view type
   * @returns the observable for load views data
   */
  loadViewsData$(
    viewId = VIEW_DEFAULT_CODE,
    target: ViewTarget,
    userId: string,
    viewType: string,
  ): Observable<{ viewData: ViewData; views: View[] }> {
    return combineLatest({
      viewData: this.loadViewData$(viewId, target, viewType),
      views: this.loadViewsByTarget$(target, userId, viewType),
    })
  }

  /**
   * Load view data
   * @param viewId - the view ID
   * @returns the observable for load view data
   */
  loadViewData$(
    viewId = VIEW_DEFAULT_CODE,
    target?: ViewTarget,
    viewType?: string,
  ): Observable<ViewData> {
    // Check default view
    if (viewId === VIEW_DEFAULT_CODE && target && viewType) {
      return of({ view: initDefaultView(target, viewType) })
    }

    return this.viewsService.read$(viewId).pipe(
      switchMap((view) =>
        combineLatest({
          attributes: this.loadAttributes$(view),
          externalData: this.loadExternalData$(view),
          view: of(view),
        }),
      ),
      catchError((error) =>
        target && viewType
          ? of({ view: initDefaultView(target, viewType) })
          : of(error),
      ),
    )
  }

  /**
   * Get all views by target
   * @param target - the view target
   * @returns the observable for load views by target
   */
  loadViewsByTarget$(
    target: ViewTarget,
    userId: string,
    viewType: string,
  ): Observable<View[]> {
    return this.viewsService
      .list$({ target, userId })
      .pipe(
        map((views) => [initDefaultView(target, viewType), ...(views || [])]),
      )
  }

  /**
   * Load view attributes
   * @param view - the view
   * @returns the observable for load view attributes
   */
  loadAttributes$(view: View): Observable<Attribute[] | undefined> {
    if (!view.fields && !view.filters && !view.ordering) {
      return of(undefined)
    }

    const attributeCodes = getViewAttributeCodes(view)
    return attributeCodes.length
      ? this.attributesService.list$({ code: attributeCodes })
      : of(undefined)
  }

  /**
   * Load view external data
   * @param view - the view
   * @returns the observable for load view external data
   */
  loadExternalData$(view: View): Observable<ViewExternalData[] | undefined> {
    if (!view.fields && !view.filters && !view.ordering && !view.params) {
      return of(undefined)
    }

    const extData = getViewExternalData(view)
    if (!extData.length) {
      return of(undefined)
    }

    const externalData$ = extData.map((data) =>
      this.parseExternalData$(data as ViewExternalData),
    )
    return combineLatest(externalData$)
  }

  /**
   * Notify a message about a view event
   * @param notification - the replenishment notification
   */
  notify(notification: ViewNotification): void {
    this.notificationManager?.show(parseViewNotification(notification))
  }

  /**
   * Show dialog to confirm view deletion
   * @param view - the view to delete
   * @returns the observable for show the dialog and confirm the deletion
   */
  showDeleteDialog$(view: View): Observable<boolean> {
    if (!this.modalManager) {
      return EMPTY
    }

    const message =
      `Stai per eliminare la vista <strong>${view.name}</strong>.<br />` +
      `Procedere all'eliminazione?`

    return this.modalManager.showDialog$({
      icon: 'warning',
      color: 'danger',
      message,
      size: 'lg',
    })
  }

  /**
   * Load external data
   * @param data - the view data
   * @returns the observable for load external data
   */
  private parseExternalData$(
    data: ViewExternalData,
  ): Observable<ViewExternalData> {
    let obs$

    switch (data.property) {
      case 'brandId':
        obs$ = this.brandsService
          .read$(String(data.value))
          .pipe(map((b) => ({ ...data, name: b.name, entity: b })))
        break
      case 'manufacturerId':
        obs$ = this.manufacturersService
          .read$(String(data.value))
          .pipe(map((m) => ({ ...data, name: m.name, entity: m })))
        break
      case 'parentId':
      case 'kitIds':
        obs$ = this.productsService.read$(String(data.value)).pipe(
          map((p) => ({
            ...data,
            name: getCatalogValue(p.name) || 'Product',
            entity: p,
          })),
        )
        break
      case 'externalSKUs.channelId':
      case 'header.channel':
        obs$ = this.channelsService
          .read$(String(data.value))
          .pipe(map((c) => ({ ...data, name: c.name, entity: c })))
        break
      case 'categories':
        obs$ = this.categoriesService.read$(String(data.value)).pipe(
          map((c) => ({
            ...data,
            name: c.name.default || 'Category',
            entity: c,
          })),
        )
        break
      case 'suppliers.supplierId':
      case 'supplierId':
        obs$ = this.suppliersService
          .read$(String(data.value))
          .pipe(map((s) => ({ ...data, name: s.businessName, entity: s })))
        break
      case 'catalogCode':
        obs$ = this.catalogsService.readOne$({ code: String(data.value) }).pipe(
          map((c) => ({
            ...data,
            name: c?.name.default || 'Catalog',
            entity: c,
          })),
        )
        break
      case 'warehouseId':
      case 'assignedWarehouseId':
        obs$ = this.warehousesService
          .read$(String(data.value))
          .pipe(map((w) => ({ ...data, name: w.name, entity: w })))
        break
      case 'carrierId':
        obs$ = this.carriersService
          .read$(String(data.value))
          .pipe(map((c) => ({ ...data, name: c.name, entity: c })))
        break
      case 'header.paymentType':
        obs$ = this.paymentsService
          .read$(String(data.value))
          .pipe(map((p) => ({ ...data, name: p.name, entity: p })))
        break
    }

    return obs$ as Observable<ViewExternalData>
  }
}
