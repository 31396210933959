import { defaultsDeep, cloneDeep } from 'lodash'
import { DeepPartial } from '../../../models/util.model'
import { Tenant } from '../tenant.model'
import { initAddress } from '../../../libs/address.lib'

// Initial states

const TENANT_INITIAL_STATE: DeepPartial<Tenant> = {
  address: initAddress(),
  payments: [],
  defaults: {
    orderVat: 0,
    shippingVat: 0,
  },
  orders: {},
  simpleOrders: {
    active: false,
  },
  fastProcess: false,
  supplierOrders: {},
  goodsReceive: {},
  catalog: {
    classifications: {
      active: false,
    },
    mandatoryProductProperties: {
      barcodes: [],
    },
    batchManagement: {
      automaticExpiringDay: false,
    },
    incomingHistory: {
      active: false,
    },
    currencies: [],
    locales: [],
  },
  akite: {},
  shippyproSettings: {},
  gspedSettings: {},
  dropShipping: {
    active: false,
    createInvoice: false,
  },
  packing: {
    packageLabelPrint: {
      isActive: false,
    },
  },
}

// Reducers

export function initTenant(tenant: Partial<Tenant> = {}): Tenant {
  return defaultsDeep(cloneDeep(tenant), TENANT_INITIAL_STATE)
}
