import { BillingAddress, ShippingAddress } from '../../models/address.model'
import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { OrderTransport } from '../orders'
import { Promotion } from '../promotions'

export enum InvoiceType {
  invoice = 'INVOICE',
  creditNote = 'CREDIT_NOTE',
  receipt = 'RECEIPT',
  receiptFiscalCode = 'RECEIPT_FISCALCODE',
  return = 'RETURN',
  generic = 'GENERIC',
}

export interface Invoice extends Entity {
  // Ext keys
  userId?: string
  warehouseId?: string
  assignedWarehouseId?: string
  orderIds?: string[]
  productsListIds?: string[]

  documentType: InvoiceType

  // Counters
  productsCount: number
  rowsCount: number
  packagesCount: number

  // Carrier & shipment data
  carrierId?: string
  shipmentOptions: InvoiceShipmentOptions

  // Header & footer document data
  header: InvoiceHeader

  // Rows
  rows: InvoiceRow[]
}

export interface InvoiceShipmentOptions {
  service?: number
  insurance?: boolean
  callBeforeDelivery?: boolean
  deliveryDate?: Date
}

export interface InvoiceHeader {
  invoiceNumber?: number

  date?: Date
  paid?: boolean
  notes?: string
  importantNote?: boolean
  errors?: string[]
  currency?: string
  rifInvoice?: string
  externalrifInvoice?: string
  rifDate?: Date

  cashOnDelivery?: InvoiceCashOnDelivery
  gift?: InvoiceGift

  discount: InvoiceDiscount

  shippingCost?: number // Costo spedizione IVA esclusa
  shippingCostWithTaxes?: number // Costo spedizione IVA inclusa
  shippingTaxRate?: number // Percentuale IVA spese di spedizione
  shippingTaxes?: number // Importo IVA delle spese di spedizione
  bodyTaxes?: number // Totale tasse corpo
  subTotal?: number // Totale corpo iva esclusa
  subTotalWithTaxes?: number // Totale corpo iva inclusa
  totalInvoiceAmount?: number // Totale ordine IVA inclusa comprese spese di spedizione e costo di contrassegno.

  channel?: string
  transport?: OrderTransport
  paymentType?: string
  payments?: InvoicePayment[]
  reasonId?: string

  billingAddress: BillingAddress
  shippingAddress: ShippingAddress

  promotions?: Promotion[]
}

export interface InvoicePayment {
  date: Date
  code?: string
  amount?: number
  consignee?: string
}

export interface InvoiceCashOnDelivery {
  fee: number // Costo contrassegno IVA esclusa
  feeWithTaxes: number // Costo contrassegno IVA inclusa
  taxRate: number // Percentuale IVA costo contrassegno
  taxes: number // Importo IVA costo contrassegno
  amount: number // Importo che deve incassare il corriere
}

export interface InvoiceGift {
  taxRate: number
  cost: number
  costWithTaxes: number
}

export interface InvoiceDiscount {
  discountWithTaxes: number
  discountTaxRate: number
  discountWithoutTaxes: number
}

export interface InvoiceRow {
  // Quantities
  invoicedQty: number

  // Prices
  unitPrice: number // Prezzo unitario articolo IVA esclusa
  unitPriceWithTaxes: number // Prezzo unitario articolo IVA inclusa
  productTaxRate: number // Percentuale IVA
  discount: number // Sconto di riga IVA esclusa
  discountWithTaxes: number // Sconto di riga IVA inclusa
  totalTaxes: number // Totale IVA di riga calcolato sulla base di dei prezzi dei prodotti * qta ord - sconto di riga
  totalAmount: number // Totale di riga IVA esclusa scontato
  totalAmountWithTaxes: number // Totale di riga IVA inclusa scontato

  // Product info
  product: InvoiceRowProduct

  isDropShipped?: boolean
  productsListCode?: string
}

export interface InvoiceRowProduct {
  _id: string
  SKU: string
  name: string
  productType: 'SIMPLE' | 'KIT' | 'SERVICE'
  simpleProducts?: InvoiceRowProductSimple[]
}

export interface InvoiceRowProductSimple {
  _id: string
  SKU: string
  name: string
  quantity: number
  invoicedQty: number
}

export type InvoiceSearchField =
  | '_id'
  | 'header.invoiceNumber'
  | 'documentType'
  | 'header.date'
  | 'header.rifDate'
  | 'header.rifInvoice'
  | 'header.externalrifInvoice'
  | 'header.paymentType'
  | 'header.currency'

export type InvoiceSortField =
  | 'header.invoiceNumber'
  | 'header.date'
  | 'header.rifDate'

export interface InvoiceSearchParams
  extends QueryStringParams<InvoiceSearchField> {
  'header.invoiceNumber'?: number
  'header.invoiceNumber:ne'?: number
  'header.invoiceNumber:lt'?: number
  'header.invoiceNumber:le'?: number
  'header.invoiceNumber:gt'?: number
  'header.invoiceNumber:ge'?: number
  documentType?: InvoiceType
  'header.date'?: string
  'header.date:ne'?: string
  'header.date:lt'?: string
  'header.date:le'?: string
  'header.date:gt'?: string
  'header.date:ge'?: string
  'header.rifDate'?: string
  'header.rifDate:ne'?: string
  'header.rifDate:lt'?: string
  'header.rifDate:le'?: string
  'header.rifDate:gt'?: string
  'header.rifDate:ge'?: string
  'header.rifInvoice'?: string
  'header.externalrifInvoice'?: string
  'header.externalrifInvoice:ct'?: string
  'header.paymentType'?: string | string[]
  q?: string
}
