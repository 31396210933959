import { Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Carrier } from '../carrier.model'
import { uniq } from 'lodash'
import { CarriersService } from '../carriers.service'

@Pipe({
  name: 'carriers',
})
export class CarriersPipe implements PipeTransform {
  constructor(private carriersService: CarriersService) {}

  transform(carrierIds: string[]): Observable<Carrier[] | undefined> {
    carrierIds = uniq(carrierIds)
    carrierIds = carrierIds.filter((id) => !!id)
    if (!carrierIds.length) {
      return of(undefined)
    }

    return this.carriersService.list$({ _id: carrierIds }, true)
  }
}
