import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import {
  Customer,
  CustomerNotificationOptions,
  CustomerSearchField,
  CustomerSearchParams,
  CustomersListingPage,
} from './customer.model'
import { CustomerNotification } from './libs/customer-notification.lib'
import {
  EMPTY,
  Observable,
  combineLatest,
  from,
  last,
  map,
  of,
  switchMap,
  concatMap,
} from 'rxjs'
import { CustomersListingData } from './use-cases/customers-listing.use-case'
import { CustomersService } from './customers.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { HttpClient } from '@angular/common/http'
import { Page } from '../../models/util.model'
import { parseCustomerPageKeys } from './libs/customer.lib'
import { CountriesService, Country } from '../countries'

@Injectable({
  providedIn: 'root',
})
export class CustomersRepository extends CustomersService {
  private paginationLimit = 200

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
    private countriesService: CountriesService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {
    super(config, http)
  }

  /**
   * Show warehouse alert
   * @param options - the warehouse notification or notification options
   * @returns the observable for show the alert about warehouse
   */
  alert$(
    opts: CustomerNotification | CustomerNotificationOptions,
  ): Observable<boolean> {
    const notification = CustomerNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a packing event
   * @param notification - the packing notification
   */
  notify(opts: CustomerNotification | CustomerNotificationOptions): void {
    const notification = CustomerNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }

  /**
   * Search customers and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @param listingData - the already loaded listing data
   * @returns the observable for search customers
   */
  searchCustomers$(
    searchParams: CustomerSearchParams,
    fields: CustomerSearchField[],
    listingData?: CustomersListingData,
  ): Observable<CustomersListingPage> {
    return searchParams.limit !== undefined &&
      searchParams.limit > this.paginationLimit
      ? this._paginateCustomers$(searchParams, fields, listingData)
      : this._searchCustomers$(searchParams, fields, listingData)
  }

  private _searchCustomers$(
    searchParams: CustomerSearchParams,
    fields: CustomerSearchField[],
    listingData?: CustomersListingData,
  ): Observable<CustomersListingPage> {
    return this.search$(searchParams).pipe(
      switchMap((page) =>
        this._loadPageExtData$(page, fields, listingData).pipe(
          map((extData) => ({ ...page, extData })),
        ),
      ),
    )
  }

  /**
   * Load external page customers data
   * @param page - the customers page
   * @param fields - the customers fields
   * @returns the observable for load external data
   */
  private _loadPageExtData$(
    page: Page<Customer>,
    fields: CustomerSearchField[],
    listingData?: CustomersListingData,
  ): Observable<CustomersListingData> {
    const extDataKeys = parseCustomerPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}
    if (
      extDataKeys.countryCodes &&
      fields.includes('billingAddress.countryCode')
    ) {
      obs$['countries'] = this._loadCountries$(
        extDataKeys.countryCodes,
        listingData?.countries,
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  private _loadCountries$(
    countryCodes: string[],
    countries?: Country[],
  ): Observable<Country[] | undefined> {
    return this.countriesService.store$(
      countryCodes,
      countries || [],
      'alpha2Code',
    )
  }

  private _paginateCustomers$(
    searchParams: CustomerSearchParams,
    fields: CustomerSearchField[],
    listingData?: CustomersListingData,
  ): Observable<CustomersListingPage> {
    // Paginate requests
    const totalCount = searchParams.limit || this.paginationLimit
    const pagesCount = Math.ceil(totalCount / this.paginationLimit)

    const pages$ = from(
      Array.from({ length: pagesCount }, (_, i) => ({
        ...searchParams,
        limit: this.paginationLimit,
        offset: i * this.paginationLimit,
      })),
    )

    let response: CustomersListingPage = {
      totalCount: 0,
      data: [],
    }
    let extData = listingData

    return pages$.pipe(
      concatMap((pageParams) =>
        this.searchCustomers$(pageParams, fields, extData).pipe(
          map((res) => {
            response = {
              totalCount: (response.totalCount || 0) + (res.totalCount || 0),
              data: [...(response.data || []), ...(res.data || [])],
              extData: { ...response.extData, ...res.extData },
            }
            extData = response.extData

            return response
          }),
        ),
      ),
      last(),
    )
  }
}
