<ng-container *ngIf="type === 'form'">
  <div class="p-4">
    <!-- Photo -->
    <div *ngIf="showPhoto" class="text-center mb-5">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{
          'border-radius': '5px',
          height: '150px',
          width: '150px'
        }"
      >
      </ngx-skeleton-loader>
    </div>

    <!-- Form -->
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <ngx-skeleton-loader
          [count]="rowsCount"
          appearance="line"
          [theme]="{ height: '35px' }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'grid'">
  <ng-container *ngIf="showHeader">
    <ngx-skeleton-loader
      [count]="1"
      appearance="line"
      [theme]="{ height: '20px', marginBottom: '15px' }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [count]="1"
      appearance="line"
      [theme]="{ height: '20px', margin: '0' }"
    ></ngx-skeleton-loader>
    <hr />
  </ng-container>
  <ngx-skeleton-loader
    [count]="rowsCount"
    appearance="line"
    [theme]="{ height: rowsHeight, marginBottom: '10px' }"
  ></ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'full'">
  <ngx-skeleton-loader [theme]="customStyle"></ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="type === 'custom'">
  <ngx-skeleton-loader
    [count]="rowsCount"
    appearance="line"
    [theme]="{ height: '35px' }"
  ></ngx-skeleton-loader>
</ng-container>
