import { cloneDeep, defaultsDeep } from 'lodash'
import { ViewParam } from '../view.model'

// Initial states

const VIEW_PARAM_INITIAL_STATE: Partial<ViewParam> = {
  property: undefined,
  value: undefined,
}

/**
 * Initialize view param with default values
 * @param viewParam - the partial view param
 * @returns the complete view param
 */
export function initViewParam(viewParam: Partial<ViewParam> = {}): ViewParam {
  return defaultsDeep(cloneDeep(viewParam), VIEW_PARAM_INITIAL_STATE)
}

/**
 * Add view param
 * @param params - the view params
 * @param param - the param
 * @returns the params
 */
export function addViewParam(
  params: ViewParam[] | undefined,
  param: ViewParam
): ViewParam[] {
  const oldParams = (params || []).filter((p) => p.property !== param.property)
  return [...oldParams, param]
}

/**
 * Remove view param
 * @param params - the view params
 * @param param - the param
 * @returns the params
 */
export function removeViewParam(
  params: ViewParam[] | undefined,
  property: string
): ViewParam[] | undefined {
  if (!params) {
    return params
  }

  params = params.filter((f) => f.property !== property)
  return params.length ? params : undefined
}
