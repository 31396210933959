<dx-list
  #list
  [dataSource]="dataSource"
  [repaintChangesOnly]="true"
  [searchExpr]="searchField"
  [showSelectionControls]="selectionEnabled"
  [searchEnabled]="searchEnabled"
  [selectionMode]="selectionEnabled ? 'all' : 'none'"
  [keyExpr]="keyField"
  [displayExpr]="displayField"
  [id]="key"
  [selectedItems]="values || []"
  (selectedItemsChange)="values = checkValue($event)"
  (onSelectionChanged)="valuesChange.emit(values)"
  [disabled]="disabled"
  itemTemplate="customItem"
>
  <!-- Custom item template -->
  <ng-container *dxTemplate="let item of 'customItem'">
    <ng-container
      *ngIf="itemTemplate; else simpleItem"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
    <ng-template #simpleItem>
      {{ displayField ? (item | get: displayField) : item }}
    </ng-template>
  </ng-container>
</dx-list>
