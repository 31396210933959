import {
  defaultsDeep,
  isEqual,
  uniq,
  last,
  cloneDeep,
  merge,
  omitBy,
  isNil,
} from 'lodash'

import {
  ORDER_HEADER_INITIAL_STATE,
  updateOrderHeaderAmounts,
} from './order-header.lib'
import {
  updateOrderRowAmounts,
  countOrderRowsProducts,
  createOrderRows,
  applyOrderRowDiscount,
} from './order-rows.lib'
import {
  Order,
  OrderAdditionalPackage,
  OrderDetailKeys,
  OrderPackage,
  OrderRow,
  OrderShipmentOptions,
  OrderStatus,
  OrderTransport,
  OrderViewType,
} from '../order.model'
import { DeepPartial } from '../../../models/util.model'
import { ProductSelection, ProductType } from '../../products'
import { Carrier } from '../../carriers'
import {
  AddressRecipientType,
  BillingAddress,
  ShippingAddress,
} from '../../../models/address.model'
import { Warehouse } from '../../warehouses'
import { Tenant } from '../../tenants'
import { ORDER_FIELDS } from '../order.const'

/**
 * The order initial state
 */
const ORDER_INITIAL_STATE: DeepPartial<Order> = {
  type: 'CUSTOMER',
  status: OrderStatus.drafted,
  statusHistory: [],
  productsCount: 0,
  rowsCount: 0,
  tags: [],
  header: ORDER_HEADER_INITIAL_STATE,
  shipmentOptions: {},
  rows: [],
  packagesCount: 0,
  packages: [],
}

/**
 * Initialize a partial order
 * @param order - the partial order
 * @returns the order updated
 */
export function initOrder(order: Partial<Order> = {}): Order {
  return defaultsDeep(cloneDeep(order), ORDER_INITIAL_STATE)
}

/**
 * Set default params to an order
 * @param order - the order
 * @param defaults - the defaults params
 * @returns the order updated
 */
export function setOrderDefaults(
  order: Order,
  defaults: DeepPartial<Order>
): Order {
  return merge(cloneDeep(order), defaults)
}

/**
 * Check orders amounts
 * @param order - the order
 * @returns the flag that indicates if all amounts are ok
 */
export function checkOrderAmounts(order: Order): boolean {
  return (
    order.rows.every(
      (row) =>
        row.totalAmount >= 0 &&
        row.totalAmountWithTaxes >= 0 &&
        row.totalTaxes >= 0
    ) &&
    order.header.totalOrderAmount >= 0 &&
    order.header.subTotal >= 0 &&
    order.header.subTotalWithTaxes >= 0
  )
}

/**
 * Apply a discount to an order
 * @param order - the order
 * @param rows - the rows
 * @param discountToApply - the discount to apply
 * @param priceWithTaxes - the price taxes flag
 * @param discountWithPercentage - the discount percentage flag
 * @returns the order updated
 */
export function applyOrderDiscount(
  order: Order,
  rows: OrderRow[],
  discountToApply: number,
  priceWithTaxes = false,
  discountWithPercentage = false
): Order {
  return updateOrderAmounts(
    {
      ...order,
      rows: order.rows.map((r) => {
        const rowToApply = rows.find((_r) => isEqual(r, _r))
        if (!rowToApply) {
          return r
        }

        return applyOrderRowDiscount(
          rowToApply,
          discountToApply,
          priceWithTaxes,
          discountWithPercentage
        )
      }),
    },
    priceWithTaxes,
    discountWithPercentage
  )
}

/**
 * Update order amounts
 * @param order - the order to update
 * @param priceWithTaxes - the price taxes flag
 * @param discountWithPercentage - the discount percentage flag
 * @returns the order updated
 */
export function updateOrderAmounts(
  order: Order,
  priceWithTaxes = false,
  discountWithPercentage = false
): Order {
  const rows = order.rows.map((row) =>
    updateOrderRowAmounts(row, priceWithTaxes, discountWithPercentage)
  )
  const header = updateOrderHeaderAmounts(
    order.header,
    rows,
    priceWithTaxes,
    discountWithPercentage
  )

  return {
    ...order,
    header,
    rows,
    productsCount: countOrderRowsProducts(rows),
    rowsCount: rows.length,
    packagesCount: order.packages?.length || 0,
  }
}

/**
 * Add products to an order
 * @param order - the order
 * @param selection - the products selection
 * @param priceTaxes - the price taxes flag
 * @returns the order updated
 */
export function addOrderProducts(
  order: Order,
  selection: ProductSelection[],
  priceTaxes = false
): Order {
  return updateOrderAmounts(
    {
      ...order,
      rows: [...order.rows, ...createOrderRows(selection)],
    },
    priceTaxes
  )
}

/**
 * Get all simple product IDs of an order
 * @param order - the order
 * @returns the simple product IDs of an order
 */
export function getOrderSimpleProductIds(order: Order): string[] {
  return uniq(
    order.rows.reduce<string[]>((ids, row) => {
      row.product.productType !== ProductType.kit
        ? ids.push(row.product._id!)
        : (ids = ids.concat(row.product.simpleProducts!.map((pro) => pro._id!)))
      return ids
    }, [])
  )
}

/**
 * Set packages to an order
 * @param order - the order
 * @param packages - the packages to set
 * @param additionalPackages - the additional packages to set
 * @returns the order updated
 */
export function setOrderPackages(
  order: Order,
  packages: OrderPackage[],
  additionalPackages?: OrderAdditionalPackage[]
): Order {
  return {
    ...order,
    packages,
    additionalPackages,
  }
}

/**
 * Set packing date to an order
 * @param order - the order
 * @returns the order updated
 */
export function setOrderPackingDate(order: Order): Order {
  return {
    ...order,
    packingDate: new Date(),
  }
}

/**
 * Set a carrier to an order
 * @param order - the order to update
 * @param carrier - the carrier to set
 * @returns the order updated
 */
export function setOrderCarrier(order: Order, carrier: Carrier): Order {
  let shipmentOptions: OrderShipmentOptions

  if (carrier && carrier.services) {
    const defaultService = carrier.defaultService ?? carrier.services[0]
    shipmentOptions = { service: defaultService?.value }
  }

  return {
    ...order,
    shipmentOptions: shipmentOptions!,
    header: {
      ...order.header,
      automaticCarrier: false,
    },
  }
}

/**
 * Unset the carrier from an order
 * @param order - the order to update
 * @returns the order updated
 */
export function unsetOrderCarrier(order: Order): Order {
  return {
    ...order,
    carrierId: undefined,
    shipmentOptions: undefined,
  }
}

/**
 * Get order user IDs
 * @param order - the order
 * @returns the user IDs of the order
 */
export function getOrderUserIds(order: Order): string[] {
  const userIds: string[] = []

  // Creation & update
  if (order.createdBy) {
    userIds.push(order.createdBy)
  }

  if (order.updatedBy) {
    userIds.push(order.updatedBy)
  }

  // Meta
  if (order.statusHistory) {
    order.statusHistory.reduce<string[]>((acc, h) => {
      if (h.userId) {
        acc.push(h.userId)
      }

      return acc
    }, [])
  }

  return userIds
}

/**
 * Get order defaults data from the tenant and warehouse
 * @param tenant - the tenant
 * @param warehouse - the warehouse
 * @returns the order default params
 */
export function getOrderDefaults(
  tenant: Tenant,
  warehouse: Warehouse | undefined
): DeepPartial<Order> {
  // Default shipping address
  const shippingAddress: Partial<ShippingAddress> =
    tenant.orders?.shippingAddressType === AddressRecipientType.warehouse &&
    warehouse
      ? {
          ...warehouse.address,
          addressType: AddressRecipientType.warehouse,
        }
      : { addressType: tenant?.orders?.shippingAddressType }
  const billingAddress: Partial<BillingAddress> = {}

  return {
    warehouseId: warehouse?._id,
    assignedWarehouseId: warehouse?._id || tenant.defaults?.warehouseId,
    carrierId:
      tenant.defaults?.transport === OrderTransport.carrier && warehouse
        ? warehouse.defaultCarrier
        : undefined,
    header: {
      currency: tenant.defaults?.currency,
      channel: tenant.defaults?.channel,
      invoiceType: tenant.orders?.invoiceType,
      paymentType: tenant.defaults?.payment,
      transport: tenant.defaults?.transport,
      shippingAddress,
      billingAddress,
      shippingTaxRate: tenant.defaults?.shippingVat,
    },
  }
}

/**
 * Get order fields by view type
 * @param viewType - the view type
 * @returns the list of fields to show
 */
export function getOrderViewFields(viewType: OrderViewType): string[] {
  return ORDER_FIELDS.filter((f) => f.defaultViews?.includes(viewType)).map(
    (f) => f.field
  )
}

/**
 * Get userId that change the order status
 * @param order - the order
 * @param status - the status
 * @returns the ID of the user that change the order status
 */
export function getOrderUserIdByStatus(
  order: Order,
  status: OrderStatus
): string | undefined {
  const lastStatus = last(
    order.statusHistory.filter((s) => s.status === status)
  )
  return lastStatus?.userId
}

/**
 * Parse order keys
 * @param order - the order
 * @returns the external data keys
 */
export function parseOrderDetailKeys(order: Order): OrderDetailKeys {
  const userIds = getOrderUserIds(order)
  const keys: OrderDetailKeys = {
    warehouseId: order.assignedWarehouseId,
    bordereauId: order.bordereauId,
    paymentId: order.header.paymentType,
    channelId: order.header.channel,
    carrierId: order.carrierId,
    pickingListId: order.pickingListId,
    tagValues: order.tags?.length ? order.tags : undefined,
    userIds: userIds.length ? userIds : undefined,
  }

  return omitBy(keys, isNil)
}
