import { cloneDeep, defaultsDeep } from 'lodash'
import {
  Attribute,
  AttributeKind,
  AttributeOption,
  AttributeSearchField,
  AttributeSearchParams,
  AttributeSortField,
  AttributeField,
} from './attribute.model'
import { Filter } from '../../models/filter.model'
import { ATTRIBUTE_TYPES } from './attribute.const'
import { FILTER_TYPES } from '../../consts/filter.const'
import { FileUpdateMethod } from '../files/file.model'
import { LOCALE_DEFAULT_CODE } from '../../consts/locale.const'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'

// Initial states

const ATTRIBUTE_INITIAL_STATE: Partial<Attribute> = {
  code: undefined,
  name: {},
  kind: undefined,
  attributesGroupCode: undefined,
  locales: [],
  schema: {},
  options: [],
}

const OPTION_INITIAL_STATE: Partial<AttributeOption> = {
  value: undefined,
  label: {},
}

// Init

export function initAttribute(attribute: Partial<Attribute> = {}): Attribute {
  return defaultsDeep(cloneDeep(attribute), ATTRIBUTE_INITIAL_STATE)
}

export function initAttributeFilter(
  attribute: Attribute,
  filter: Filter
): Filter {
  const attributeModel = ATTRIBUTE_TYPES.find((a) => a.value === attribute.kind)
  return {
    ...filter,
    operator: filter.operator ? filter.operator : attributeModel?.filters[0],
    value: filter.operator
      ? filter.value
      : FILTER_TYPES.find((f) => f.operator === attributeModel?.filters[0])
          ?.initialValue,
  }
}

export function initAttributeOption(
  option: Partial<AttributeOption> = {}
): AttributeOption {
  return defaultsDeep(cloneDeep(option), OPTION_INITIAL_STATE)
}

// Options

export function addAttributeOption(
  attribute: Attribute,
  option: AttributeOption = initAttributeOption()
): Attribute {
  return {
    ...attribute,
    options: [...attribute.options, option],
  }
}

export function removeAttributeOption(
  attribute: Attribute,
  i: number
): Attribute {
  return {
    ...attribute,
    options: [...attribute.options.filter((attr, index) => index !== i)],
  }
}

// Fields

export function attributeToField(attribute: Attribute): AttributeField {
  let methods = [FileUpdateMethod.set, FileUpdateMethod.unset]

  if (
    [AttributeKind.simpleSelect, AttributeKind.multiSelect].includes(
      attribute.kind
    )
  ) {
    methods = [...methods, FileUpdateMethod.push, FileUpdateMethod.pull]
  }

  if (attribute.kind === AttributeKind.number) {
    methods = [
      ...methods,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ]
  }

  return {
    name: `[ATTRIBUTO] ${attribute.name.default}`,
    field: `attribute:${attribute.code}`,
    methods,
    attribute,
    valuePerCatalog: attribute.valuePerCatalog,
    isLocalizable: attribute.isLocalizable,
  }
}

export function attributesToFields(attributes: Attribute[]): AttributeField[] {
  return attributes.map((a) => attributeToField(a))
}

// Filters

export function attributeToFilter(attribute: Attribute): Filter {
  return {
    field: attribute.code,
    operator: undefined,
    value: undefined,
  }
}

/**
 * Parse attribute value based on its kind
 * @param attribute - the attribute to parse
 * @param value - the value to search
 * @return the value parsed
 */
export function parseAttributeValue(
  attribute: Attribute,
  value: any,
  locale = LOCALE_DEFAULT_CODE
): any {
  const optionValue = attribute.options.find((o) => o.value === value)
  return optionValue?.label[locale] || value
}

// Search

export function attributeSortParams(
  params: AttributeSearchParams,
  sort: QueryStringSort<AttributeSortField>
): AttributeSearchParams {
  const searchParams: AttributeSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function attributeFilterParams(
  params: AttributeSearchParams,
  filter: QueryStringFilter<AttributeSearchField>
): AttributeSearchParams {
  const searchParams: AttributeSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
    case 'kind':
      if (filter.value !== null) {
        searchParams.kind = filter.value
      }
      break
    case 'attributesGroupCode':
      if (filter.value !== null) {
        searchParams.attributesGroupCode = filter.value
      } else {
        searchParams['attributesGroupCode:ex'] = false
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
