<div class="row no-gutters" [ngClass]="searchClass || ''">
  <div *ngIf="!loaded" class="col-11">
    <op-loader
      type="full"
      [customStyle]="{ minHeight: '50px', margin: 0 }"
    ></op-loader>
  </div>
  <ng-container *ngIf="loaded">
    <div class="col-10">
      <input
        [id]="key"
        #inputSearch
        type="text"
        class="form-control form-control-xl"
        [(ngModel)]="searchTerm"
        [ngClass]="inputClass || ''"
        [placeholder]="placeholder"
        (keydown.enter)="search($event)"
        alt="Riferimento ordine, nome o email destinatario, nome o sku prodotto"
        [disabled]="!!disabled"
        [autocomplete]="autocomplete ? 'on' : 'off'"
      />
      <button
        *ngIf="!!searchTerm"
        type="button"
        class="close"
        aria-label="Close"
        (click)="reset()"
        [disabled]="disabled"
      >
        <span class="reset-search-icon" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-2 pl-2">
      <button
        class="btn btn-outline-primary btn-xl"
        [class.disabled]="disabled"
        type="button"
        [disabled]="inputSearch.value === '' || disabled"
        (click)="search()"
      >
        <i [class]="icon"></i>
      </button>
    </div>
  </ng-container>
</div>
