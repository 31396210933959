import { createSelector } from '@ngrx/store'
import {
  PermissionsLevel,
  PermissionsScope,
  Permissions,
  PermissionsCheckMode,
  checkPermission,
  checkPermissions,
} from '@evologi/shared/data-access-api'

import { AuthState } from './auth.state'
import { StoreState } from '../store.state'
import { decrypt } from '../../libs/crypto.lib'

export const selectAuth = (state: StoreState) => state.auth

export const selectAuthenticated = createSelector(
  selectAuth,
  (state: AuthState) => !!state.user && !!state.tenant,
)

export const selectAuthenticatedData = createSelector(
  selectAuth,
  (state: AuthState) => {
    if (!state.tenant || !state.user) {
      return undefined
    }

    return {
      tenant: state.tenant,
      user: state.user,
      warehouse: state.warehouse,
      settings: state.settings,
    }
  },
)

export const selectAuthUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user,
)
export const selectAuthWarehouse = createSelector(
  selectAuth,
  (state: AuthState) => state.warehouse,
)
export const selectAuthPickupPoint = createSelector(
  selectAuth,
  (state: AuthState) => state.pickupPoint,
)
export const selectAuthSupplier = createSelector(
  selectAuth,
  (state: AuthState) => state.supplier,
)
export const selectAuthTenant = createSelector(
  selectAuth,
  (state: AuthState) => state.tenant,
)

export const selectAuthSettings = createSelector(
  selectAuth,
  (state: AuthState) => state.settings,
)

export const selectAuthPermissions = createSelector(
  selectAuth,
  (state: AuthState) => state.permissions,
)

export const selectAuthPermissionCheck = (
  scope: PermissionsScope,
  level?: PermissionsLevel,
) =>
  createSelector(
    selectAuthPermissions,
    (permissions: Permissions | undefined) =>
      checkPermission(permissions, scope, level),
  )

export const selectAuthPermissionsCheck = (
  scopes: PermissionsScope[],
  level?: PermissionsLevel,
  mode?: PermissionsCheckMode,
) =>
  createSelector(
    selectAuthPermissions,
    (permissions: Permissions | undefined) =>
      checkPermissions(permissions, scopes, level, mode),
  )

export const selectAuthUserId = createSelector(
  selectAuth,
  (state: AuthState) => state.user?._id,
)
export const selectAuthUserEmail = createSelector(
  selectAuth,
  (state: AuthState) => state.user?.email,
)
export const selectAuthUserTenants = createSelector(
  selectAuth,
  (state: AuthState) => state.user?.tenants,
)

export const selectAuthTenantId = createSelector(
  selectAuth,
  (state: AuthState) => state.tenant?._id,
)

export const selectAuthShippyProIntegration = createSelector(
  selectAuth,
  (state: AuthState) =>
    !!state?.tenant?.shippyproSettings &&
    !!state.tenant.shippyproSettings.apiKey,
)

export const selectAuthWarehouseId = createSelector(
  selectAuth,
  (state: AuthState) => state.warehouse?._id,
)

export const selectAuthPrintingEnabled = createSelector(
  selectAuth,
  (state: AuthState) => !!state.isPrintingEnabled,
)

export const selectAuthIsReviving = createSelector(
  selectAuth,
  (state: AuthState) => !!state.isReviving,
)

export const selectAuthIsLoading = createSelector(
  selectAuth,
  (state: AuthState) => !!state.isLoading,
)

export const selectAuthNotification = createSelector(
  selectAuth,
  (state: AuthState) => state.notification,
)

export const selectAuthError = createSelector(
  selectAuth,
  (state: AuthState) => state.error,
)

export const selectAuthTokens = (privateKey: string) =>
  createSelector(selectAuth, (state: AuthState) => {
    const { at, rt } = state

    return {
      accessToken: at ? decrypt(at, privateKey) : undefined,
      refreshToken: rt ? decrypt(rt, privateKey) : undefined,
    }
  })

export const selectAuthAccessToken = (privateKey: string) =>
  createSelector(selectAuth, (state: AuthState) =>
    state.at ? decrypt(state.at, privateKey) : undefined,
  )

export const selectAuthRefreshToken = (privateKey: string) =>
  createSelector(selectAuth, (state: AuthState) =>
    state.rt ? decrypt(state.rt, privateKey) : undefined,
  )
