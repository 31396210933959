import { Pipe, PipeTransform } from '@angular/core'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'

@Pipe({
  name: 'customStorePagination',
})
export class CustomStorePaginationPipe implements PipeTransform {
  transform(
    elements?: CustomStore | DataSource | any[] | undefined | null,
  ): any {
    return {
      store: elements || [],
      reshapeOnPush: true,
      paginate: true,
      pageSize: 50,
    }
  }
}
