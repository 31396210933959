<form
  *ngIf="recoveryForm"
  class="form"
  [formGroup]="recoveryForm"
  (ngSubmit)="recovery()"
  (tkCapsLock)="capsOn = $event"
>
  <h5 class="title">Resetta la tua password</h5>
  <div class="form-group">
    <label for="username">Username</label>
    <input
      type="text"
      id="username"
      class="form-control form-control-lg"
      placeholder="La tua username"
      formControlName="username"
      required
    />
  </div>
  <p class="text-center text-info font-weight-bold" *ngIf="capsOn">
    <small>Caps Lock attivo <i class="fad fa-lock-alt"></i></small>
  </p>
  <button
    class="btn btn-primary btn-block btn-lg"
    type="submit"
    [disabled]="!recoveryForm.valid"
  >
    Richiedi
  </button>
  <a
    class="link-back text-secondary"
    data-test="link-back"
    [routerLink]="['/auth/login']"
  >
    Torna al login
  </a>
</form>
