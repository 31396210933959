import { EntityStatusAuth, EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import {
  PickingListAction,
  PickingListMissionStatus,
  PickingListOrderStatus,
  PickingListPermission,
  PickingListStatus,
  PickingListType,
} from './picking-list.model'

export const PICKING_LIST_SCOPE: PermissionsScope = 'pickingLists'

export const PICKING_LIST_STATUSES: EntityStatusDesc<PickingListStatus>[] = [
  {
    code: PickingListStatus.pending,
    name: 'Da prelevare',
    tooltip:
      'Picking list in attesa di essere presa in carico da parte del magazziniere',
  },
  {
    code: PickingListStatus.processing,
    name: 'In prelievo',
    tooltip: 'Picking list in prelievo',
  },
  {
    code: PickingListStatus.picked,
    name: 'Prelevata',
    tooltip: 'Picking list prelevata',
  },
  {
    code: PickingListStatus.closed,
    name: 'Chiusa',
    tooltip: 'Picking list imballata',
  },
]

export const PICKING_LIST_ACTIONS: EntityStatusAuth<
  PickingListAction,
  PickingListPermission
> = {
  PENDING: {
    permissions: [
      'addOrders',
      'editHeader',
      'editNotes',
      'printMissions',
      'savePicking',
    ],
    actions: [],
  },
  PROCESSING: {
    permissions: ['printMissions'],
    actions: [],
  },
  PICKED: {
    permissions: [],
    actions: [],
  },
  CLOSED: {
    permissions: [],
    actions: [],
  },
}

export const PICKING_LIST_MISSION_STATUSES: EntityStatusDesc<PickingListMissionStatus>[] =
  [
    {
      code: PickingListMissionStatus.pending,
      name: 'Da prelevare',
    },
    {
      code: PickingListMissionStatus.processing,
      name: 'In prelievo',
    },
    {
      code: PickingListMissionStatus.picked,
      name: 'Prelevata',
    },
    {
      code: PickingListMissionStatus.canceled,
      name: 'Cancellata',
    },
    {
      code: PickingListMissionStatus.skipped,
      name: 'Saltata',
    },
    {
      code: PickingListMissionStatus.repWaiting,
      name: 'Attesa trasferimento',
    },
    {
      code: PickingListMissionStatus.repCanceled,
      name: 'Trasferimento annullato',
    },
  ]

export const PICKING_LIST_ORDER_STATUSES: EntityStatusDesc<PickingListOrderStatus>[] =
  [
    {
      code: PickingListOrderStatus.processing,
      name: 'In prelievo',
    },
    {
      code: PickingListOrderStatus.picked,
      name: 'Prelevato',
    },
  ]

export const PICKING_LIST_TYPES: EntityStatusDesc<PickingListType>[] = [
  {
    code: PickingListType.pack,
    name: 'Prelievo per ordine',
  },
  {
    code: PickingListType.sort,
    name: 'Prelievo massivo',
  },
]

export const PICKING_LIST_FIELDS = [
  {
    field: 'pickingNumber',
    defaultVisibility: true,
  },
  {
    field: 'name',
    defaultVisibility: true,
  },
  {
    field: 'type',
    defaultVisibility: true,
  },
  {
    field: 'createdAt',
    defaultVisibility: true,
  },
  {
    field: 'statusHistory.1.date',
    defaultVisibility: true,
  },
  {
    field: 'statusHistory.2.date',
    defaultVisibility: true,
  },
  {
    field: 'ordersCount',
    defaultVisibility: true,
  },
  {
    field: 'itemsCount',
    defaultVisibility: true,
  },
  {
    field: 'missions',
    defaultVisibility: true,
  },
  {
    field: 'status',
    defaultVisibility: true,
  },
  {
    field: 'createdBy',
    defaultVisibility: false,
  },
  {
    field: 'statusHistory.1.userId',
    defaultVisibility: true,
  },
  {
    field: 'statusHistory.2.userId',
    defaultVisibility: true,
  },
  {
    field: 'statusHistory.3.userId',
    defaultVisibility: false,
  },
  {
    field: 'completion',
    defaultVisibility: true,
  },
  {
    field: 'estimatedVolume',
    defaultVisibility: false,
  },
  {
    field: 'estimatedWeight',
    defaultVisibility: false,
  },
  {
    field: 'isPartial',
    defaultVisibility: true,
  },
  {
    field: 'isUrgent',
    defaultVisibility: true,
  },
  {
    field: 'notes',
    defaultVisibility: true,
  },
]
