// Search

import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import {
  ExecutionSearchField,
  ExecutionSearchParams,
  ExecutionSortField,
} from './execution.model'

export function executionSortParams(
  params: ExecutionSearchParams,
  sort: QueryStringSort<ExecutionSortField>
): ExecutionSearchParams {
  const searchParams: ExecutionSearchParams = {}

  switch (sort.field) {
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'finishedAt':
      searchParams.sort = 'finishedAt'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function executionFilterParams(
  params: ExecutionSearchParams,
  filter: QueryStringFilter<ExecutionSearchField>
): ExecutionSearchParams {
  const searchParams: ExecutionSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams['_id'] = filter.value
      }
      break
    case 'activity._id':
      if (filter.value !== null) {
        searchParams['activity._id'] = filter.value
      }
      break
    case 'activity.code':
      if (filter.value !== null) {
        searchParams['activity.code'] = filter.value
      }
      break
    case 'context._id':
      if (filter.value !== null) {
        searchParams['context._id'] = filter.value
      }
      break
    case 'context.code':
      if (filter.value !== null) {
        searchParams['context.code'] = filter.value
      }
      break
    case 'isLast':
      searchParams.isLast = filter.value
      break
    case 'status':
      if (filter.value !== null) {
        searchParams.status = filter.value
      }
      break
    case 'type':
      if (filter.value !== null) {
        searchParams.type = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
