import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pickingListTime',
})
export class PickingListTimePipe implements PipeTransform {
  transform(column: any): string | undefined {
    if (
      column.data['statusHistory']?.length < 3 ||
      !column.data['statusHistory'][1].date ||
      !column.data['statusHistory'][2].date
    ) {
      return
    }
    return `${((new Date(column.data['statusHistory'][2].date).getTime() - new Date(column.data['statusHistory'][1].date).getTime()) / 60000).toFixed(1)}`
  }
}
