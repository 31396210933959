import {
  GoodsReceive,
  GoodsReceiveRow,
} from '../../goods-receives/goods-receive.model'
import { Product, ProductGoodsReceive } from '../product.model'

export function parseProductGoodsReceive(
  product: Product,
  goodsReceives: GoodsReceive[],
  warehouseId?: string
): ProductGoodsReceive[] {
  // Filter warehouse goods-receive
  const warehouseIncomings = goodsReceives.filter((incoming) =>
    warehouseId ? incoming.warehouseId === warehouseId : true
  )

  return warehouseIncomings.map((goodsReceive) => {
    const orderedQty = getOrderedQty(product, goodsReceive)
    const receivedQty = getReceivedQty(product, goodsReceive)
    const invoicedQty = getInvoicedQty(product, goodsReceive)

    return {
      ...goodsReceive,
      orderedQty,
      receivedQty,
      invoicedQty,
    }
  })
}

function getOrderedQty(product: Product, goodsReceive: GoodsReceive): number {
  const rows = goodsReceive.rows.filter((r) => r.product._id === product._id)
  return rows.reduce(
    (total: number, row: GoodsReceiveRow) => total + row.orderedQty,
    0
  )
}

function getReceivedQty(product: Product, goodsReceive: GoodsReceive): number {
  const rows = goodsReceive.rows.filter((r) => r.product._id === product._id)
  return rows.reduce(
    (total: number, row: GoodsReceiveRow) => total + row.receivedQty,
    0
  )
}

function getInvoicedQty(product: Product, goodsReceive: GoodsReceive): number {
  const rows = goodsReceive.rows.filter((r) => r.product._id === product._id)
  return rows.reduce(
    (total: number, row: GoodsReceiveRow) => total + row.invoicedQty,
    0
  )
}
