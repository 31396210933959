import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies'
import { PickupPointKind, PickupPointsTab } from './pickup-point.model'

export const PICKUP_POINT_SCOPE: PermissionsScope = 'pickupPoints'

export const PICKUP_POINT_KINDS = [
  {
    code: PickupPointKind.store,
    name: 'Negozio',
  },
]

export const PICKUP_POINTS_TABS: SelectionData<PickupPointsTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'additionalInfo',
    label: 'Info aggiuntive',
  },
]
