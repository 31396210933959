import { defaultsDeep, cloneDeep, uniq, groupBy } from 'lodash'
import moment from 'moment'

import { checkSupplier, getProductSupplierIds } from './supplier.lib'
import { getProductChannelIds } from './channel.lib'
import { parseProductDataAttributes } from './attribute.lib'
import {
  Product,
  ProductBulkUpdate,
  ProductConfiguration,
  ProductExportStockData,
  ProductMovement,
  ProductPageData,
  ProductProvisioningSettings,
  ProductSale,
  ProductSalesByYear,
  ProductScope,
} from '../product.model'
import {
  PRODUCT_BULK_UPDATE_INITIAL_STATE,
  PRODUCT_CONFIGURATION_INITIAL_STATE,
  PRODUCT_EXPORT_STOCK_DATA_INITIAL_STATE,
  PRODUCT_INITIAL_STATE,
  PRODUCT_MOVEMENT_INITIAL_STATE,
  PRODUCT_PROVISIONING_SETTINGS_INITIAL_STATE,
} from '../product.const'
import { User } from '../../users/user.model'
import { Page } from '../../../models/util.model'
import { ProductFamily } from '../../product-families/product-family.model'
import { Manufacturer } from '../../manufacturers/manufacturer.model'
import { Category } from '../../categories/category.model'
import { Supplier } from '../../suppliers/supplier.model'
import { Attribute } from '../../attributes/attribute.model'
import { Tag } from '../../tags/tag.model'
import { Channel } from '../../channels/channel.model'
import { Brand } from '../../brands/brand.model'
import { Location } from '../../locations'

// Initializators

export function initProduct(product: Partial<Product> = {}): Product {
  return defaultsDeep(cloneDeep(product), PRODUCT_INITIAL_STATE)
}

export function initProductMovement(
  movement: Partial<ProductMovement> = {},
): ProductMovement {
  return defaultsDeep(cloneDeep(movement), PRODUCT_MOVEMENT_INITIAL_STATE)
}

export function initProductBulkUpdate(
  bulkData: Partial<ProductBulkUpdate> = {},
): ProductBulkUpdate {
  return defaultsDeep(cloneDeep(bulkData), PRODUCT_BULK_UPDATE_INITIAL_STATE)
}

export function initProductConfiguration(
  conf: Partial<ProductConfiguration> = {},
): ProductConfiguration {
  return defaultsDeep(cloneDeep(conf), PRODUCT_CONFIGURATION_INITIAL_STATE)
}

export function initProductProvisioningSettings(
  settings: Partial<ProductProvisioningSettings> = {},
): ProductProvisioningSettings {
  return defaultsDeep(
    cloneDeep(settings),
    PRODUCT_PROVISIONING_SETTINGS_INITIAL_STATE,
  )
}

export function initProductExportData(
  exportData: Partial<ProductExportStockData> = {},
  user?: User,
  warehouseId?: string,
): ProductExportStockData {
  const newExportData = defaultsDeep(
    cloneDeep(exportData),
    PRODUCT_EXPORT_STOCK_DATA_INITIAL_STATE,
  )
  newExportData.to.setDate(newExportData.to.getDate() - 1)
  newExportData.to.setHours(23, 59, 59, 99)
  newExportData.from.setDate(newExportData.to.getDate() - 7)
  newExportData.from.setHours(0, 0, 0, 0)

  if (user) {
    newExportData.email = user.email
  }

  if (warehouseId) {
    newExportData.warehouseId = warehouseId
  }

  return newExportData
}

export function getProductUserIds(product: Product): string[] {
  const userIds: string[] = []

  // Creation & update
  if (product.createdBy) {
    userIds.push(product.createdBy)
  }

  if (product.updatedBy) {
    userIds.push(product.updatedBy)
  }

  return uniq(userIds)
}

// Checks

export function checkProduct(product: Product): Product {
  return {
    ...product,
    tags: (product.tags || []).filter((tag) => tag && tag !== ''),
    barcodes: product.barcodes || [],
    suppliers: (product.suppliers || []).map((s) => checkSupplier(s)),
  }
}

export function checkProductBarcode(
  product: Product,
  barcode: string,
): boolean {
  return (
    !!product.barcodes?.find((b) => b.value === barcode) ||
    product.SKU === barcode
  )
}

// Pagination

export function parseProductsPageBrands(
  page: Page<ProductPageData>,
  brands: Brand[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => ({
        ...p,
        brandName: brands.find((b) => b._id === p.brandId)?.name,
      })),
    ],
  }
}

export function parseProductsPageFamilies(
  page: Page<ProductPageData>,
  families: ProductFamily[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => ({
        ...p,
        familyName: families.find((f) => f.code === p.family?.code)?.name
          .default,
      })),
    ],
  }
}

export function parseProductsPageManufacturers(
  page: Page<ProductPageData>,
  manufacturers: Manufacturer[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => ({
        ...p,
        manufacturerName: manufacturers.find((m) => m._id === p.manufacturerId)
          ?.name,
      })),
    ],
  }
}

export function parseProductsPageParents(
  page: Page<ProductPageData>,
  parents: Product[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => ({
        ...p,
        parent: parents.find((m) => m._id === p.parentId),
      })),
    ],
  }
}

export function parseProductsPageKits(
  page: Page<ProductPageData>,
  products: Product[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        const kits = products.filter((k) => p.kitIds?.includes(k._id))
        return {
          ...p,
          kits,
        }
      }),
    ],
  }
}

export function getProductsPageCategories(
  page: Page<ProductPageData>,
  scope?: ProductScope,
): string[] {
  const categoryIds = page.data.reduce<string[]>((acc, p) => {
    const prodCategories = p.categories?.find(
      (c) => c.catalogCode === scope?.catalogCode,
    )

    return [...acc, ...(prodCategories?.ids || [])]
  }, [])

  return uniq(categoryIds)
}

export function parseProductsPageCategories(
  page: Page<ProductPageData>,
  categories: Category[],
  scope?: ProductScope,
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        const prodCategories = p.categories?.find(
          (c) => c.catalogCode === scope?.catalogCode,
        )

        if (!prodCategories) {
          return p
        }

        const categoriesData = categories.filter((c) =>
          prodCategories.ids.includes(c._id),
        )
        const primaryCategory = categories.find(
          (c) => c._id === prodCategories.primaryId,
        )

        return {
          ...p,
          primaryCategory,
          categoriesData,
        }
      }),
    ],
  }
}

export function parseProductsPageLocations(
  page: Page<ProductPageData>,
  locations: Location[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        if (!p.sites?.length) {
          return p
        }

        const locationIds = p.sites.map((s) => s.locationId)
        const locationsData = locations.filter((l) =>
          locationIds.includes(l._id),
        )
        return {
          ...p,
          locationsData,
        }
      }),
    ],
  }
}

export function parseProductsPageChannels(
  page: Page<ProductPageData>,
  channels: Channel[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        if (!p.externalSKUs?.length) {
          return p
        }

        const channelIds = getProductChannelIds(p)
        const channelsData = channels.filter((c) => channelIds.includes(c._id))
        return {
          ...p,
          channels: channelsData,
        }
      }),
    ],
  }
}

export function parseProductsPageSuppliers(
  page: Page<ProductPageData>,
  suppliers: Supplier[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        if (!p.suppliers?.length) {
          return p
        }

        const supplierIds = getProductSupplierIds(p)
        const suppliersData = suppliers.filter((s) =>
          supplierIds.includes(s._id),
        )
        return {
          ...p,
          suppliersData,
        }
      }),
    ],
  }
}

export function parseProductsPageTags(
  page: Page<ProductPageData>,
  tags: Tag[],
): Page<ProductPageData> {
  return {
    ...page,
    data: [
      ...page.data.map((p) => {
        if (!p.tags?.length) {
          return p
        }

        const tagsData = tags.filter((t) => p.tags?.includes(t.value))
        return {
          ...p,
          tagsData,
        }
      }),
    ],
  }
}

export function parseProductsPageAttributes(
  page: Page<ProductPageData>,
  scope: ProductScope | undefined,
  attributes: Attribute[] | undefined,
): Page<ProductPageData> {
  if (!scope || !attributes) {
    return page
  }

  return {
    ...page,
    data: page.data.map((p) =>
      parseProductDataAttributes(p, attributes, scope),
    ),
  }
}

// Sales

export function parseProductSales(
  product: Product,
): ProductSalesByYear | undefined {
  if (!product.sales?.length) {
    return undefined
  }

  const sales = product.sales.sort(
    (s: ProductSale, s1: ProductSale) =>
      new Date(s.soldTo).getTime() - new Date(s1.soldTo).getTime(),
  )
  return groupBy(sales, (sale: ProductSale) =>
    moment(sale.soldFrom).format('YYYY'),
  )
}
