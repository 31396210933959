import { PermissionsScope } from '../policies/policy.model'
import { CatalogLocales } from '../../models/util.model'

export const CATALOG_FRAGMENT = 'catalog'
export const CATALOG_SCOPE: PermissionsScope = 'catalogs'
export const CATALOG_DEFAULT_CODE = 'default'

export const CATALOG_DEFAULT_VALUE: CatalogLocales<string> = {
  catalogCode: CATALOG_DEFAULT_CODE,
  locales: {
    default: undefined,
  },
}

export const CATALOG_DEFAULT_EMPTY_STRING_VALUE: CatalogLocales<string> = {
  catalogCode: CATALOG_DEFAULT_CODE,
  locales: {
    default: '',
  },
}
