import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export enum LocationType {
  pick = 'PICK',
  tote = 'TOTE',
  stock = 'STOCK',
}
export interface Location extends Entity {
  /**
   * Internal use only.
   * Contains all Tenants that can read(-only) this Location.
   * The filter is scoped to Warehouse.
   */
  _tenantIds: string[]
  warehouseId: string
  /**
   * Exists only on PICK Locations (totes are not area-scoped).
   */
  areaId?: string
  pickingListId?: string
  type: LocationType
  label: string
  index: number
  code?: string
  singular?: string
  plural?: string
  path: string
  modelId?: string
  ancestors?: LocationAncestor[]
  size?: LocationSize
  isLeaf?: boolean
  isPlaced?: boolean
  /**
   * If `true`, this Location can contain only scrapped quantities.
   */
  forScraps?: boolean
}

export interface LocationSize {
  length?: number
  width?: number
  height?: number
  volume?: number
  offsetX?: number
  offsetY?: number
}

export interface LocationAncestor {
  _id: string
  type: LocationType
  label: string
  index: number
  path: string
  singular?: string
  plural?: string
  size?: LocationSize
}

export interface LocationSelection {
  type: 'default' | 'packaging' | 'area-start'
  location?: Location
}

export interface Vertex extends Entity {
  warehouseId: string
  areaId: string
  x: number
  y: number
  isAreaStart: boolean
  isWarehouseStart: boolean
  locations: VertexLocation[]
}

export interface VertexLocation {
  _id: string
  path: string
}

export interface Edge extends Entity {
  warehouseId: string
  areaId: string
  sourceId: string
  targetId: string
  distance: number
}

export type LocationSearchField =
  | 'warehouseId'
  | 'areaId'
  | 'pickingListId'
  | 'type'
  | 'code'
  | 'singular'
  | 'path'
  | 'modelId'
  | 'isLeaf'
  | 'isPlaced'
  | 'forScraps'
export type LocationSortField = 'path'

export interface LocationSearchParams
  extends QueryStringParams<LocationSortField> {
  _id?: string[]
  warehouseId?: string | string[]
  areaId?: string | string[]
  pickingListId?: string | string[]
  type?: LocationType[]
  code?: string | string[]
  'code:ex'?: boolean
  singular?: string | string[]
  path?: string
  'path:ne'?: string
  'path:ct'?: string
  'path:sw'?: string
  'path:ew'?: string
  modelId?: string | string[] | null
  'modelId:ne'?: string | string[] | null
  isLeaf?: boolean
  isPlaced?: boolean
  forScraps?: boolean
}

export interface LocationsBulkUpdate {
  warehouseId: string
  filter: LocationSearchParams
  actions: LocationsBulkUpdateAction[]
  count: number
}

export type LocationUpdateSubject =
  | 'IS_PLACED'
  | 'MODEL'
  | 'FOR_SCRAPS'
  | 'TYPE'

export interface LocationsBulkUpdateAction {
  subject: LocationUpdateSubject
  mode: BulkUpdateMethod
  value: boolean | string
}
