import { cloneDeep, defaultsDeep, isEqual } from 'lodash'
import { InvoiceRow, InvoiceRowProduct } from '../invoice.model'
import { getCatalogValue } from '../../catalogs'
import {
  Product,
  ProductSelection,
  ProductType,
  getProductSellingPrice,
  initProduct,
} from '../../products'
import { calculatePrices } from '../../../libs/dinero.lib'

/**
 * Invoice row initial state
 */
const INVOICE_ROW_INITIAL_STATE: Partial<InvoiceRow> = {
  invoicedQty: 1,
  unitPrice: 0,
  unitPriceWithTaxes: 0,
  productTaxRate: 0,
  discount: 0,
  discountWithTaxes: 0,
  totalTaxes: 0,
  totalAmount: 0,
  totalAmountWithTaxes: 0,
}

/**
 * Initialize an invoice row
 * @param row - the partial row
 * @returns the row updated
 */
export function initInvoiceRow(row: Partial<InvoiceRow> = {}): InvoiceRow {
  return defaultsDeep(cloneDeep(row), INVOICE_ROW_INITIAL_STATE)
}

/**
 * Initialize an invoice row product
 * @param product - the product
 * @returns the invoice row product updated
 */
export function initInvoiceRowProduct(product: Product): InvoiceRowProduct {
  let productRow: InvoiceRowProduct = {
    _id: product._id,
    SKU: product.SKU,
    name: getCatalogValue<string>(product.name) || 'Product',
    productType: 'SIMPLE',
  }

  if (product.productType === ProductType.kit) {
    productRow = {
      ...productRow,
      productType: 'KIT',
      simpleProducts:
        product.kitProducts?.map((p) => ({
          _id: p._id,
          SKU: p.SKU,
          name: '',
          quantity: p.quantity,
          invoicedQty: 1,
        })) || [],
    }
  } else if (product.productType === ProductType.service) {
    productRow = {
      ...productRow,
      productType: 'SERVICE',
    }
  }

  return productRow
}

/**
 * Update invoice row amounts
 * @param row - the invoice row
 * @param priceTaxes - the price taxes flag
 * @returns the row updated
 */
export function updateInvoiceRowAmounts(
  row: InvoiceRow,
  priceTaxes = false
): InvoiceRow {
  // Check ordered and taxRate
  const taxRate = !row.productTaxRate ? 0 : +row.productTaxRate
  const invoicedQty =
    !row.invoicedQty || row.invoicedQty <= 0 ? 1 : +row.invoicedQty

  const productPrices = calculatePrices({
    taxRate,
    price: priceTaxes ? undefined : row.unitPrice,
    total: priceTaxes ? row.unitPriceWithTaxes : undefined,
  })

  const discountPrices = calculatePrices({
    taxRate,
    price: priceTaxes ? undefined : row.discount,
    total: priceTaxes ? row.discountWithTaxes : undefined,
  })

  const totalAmount = productPrices.price
    .multiply(invoicedQty)
    .subtract(discountPrices.price)

  const totalTaxes = productPrices.taxes
    .multiply(invoicedQty)
    .subtract(discountPrices.taxes)

  const totalAmountWithTaxes = productPrices.total
    .multiply(invoicedQty)
    .subtract(discountPrices.total)

  return {
    ...row,
    productTaxRate: taxRate,
    invoicedQty,
    unitPrice: productPrices.price.toRoundedUnit(4),
    unitPriceWithTaxes: productPrices.total.toRoundedUnit(4),
    discount: discountPrices.price.toRoundedUnit(4),
    discountWithTaxes: discountPrices.total.toRoundedUnit(4),
    totalTaxes: totalTaxes.toRoundedUnit(4),
    totalAmount: totalAmount.toRoundedUnit(4),
    totalAmountWithTaxes: totalAmountWithTaxes.toRoundedUnit(4),
  }
}

/**
 * Count rows products invoiced
 * @param rows - the invoice rows
 * @returns the amount of products invoiced
 */
export function countInvoiceRowsProducts(rows: InvoiceRow[]): number {
  return rows.reduce((acc, row) => acc + row.invoicedQty, 0)
}

/**
 * Create invoice rows from products selection
 * @param products - the products selection
 * @returns the invoice rows
 */
export function createInvoiceRows(products: ProductSelection[]): InvoiceRow[] {
  return products.map((p) => createInvoiceRow(p.product, p.qty))
}

/**
 * Delete invoice row from invoice rows
 * @param rows - the invoice rows
 * @param row - the row to delete
 * @returns the invoice rows updated
 */
export function deleteInvoiceRow(rows: InvoiceRow[], row: InvoiceRow) {
  return rows.filter((_row) => !isEqual(_row, row))
}

/**
 * Create an invoice row from a product
 * @param product - the product data
 * @param qty - the qty
 * @param taxRate - the taxRate
 * @returns the invoice row
 */
export function createInvoiceRow(
  product: Product,
  qty: number | undefined,
  taxRate?: number
): InvoiceRow {
  const _product = initProduct(product)

  const invoicedQty = qty || 1
  const productTaxRate = taxRate
    ? taxRate
    : _product.taxRate
    ? _product.taxRate
    : 0
  const unitPriceWithTaxes = getProductSellingPrice(_product)
  const unitPrice = unitPriceWithTaxes / (productTaxRate / 100 + 1)

  return initInvoiceRow({
    product: initInvoiceRowProduct(_product),
    invoicedQty,
    productTaxRate,
    unitPriceWithTaxes,
    unitPrice,
  })
}
