import { routerReducer } from '@ngrx/router-store'

import { RouterEffects } from './router'
import { attributesReducers, AttributesEffects } from './attributes'
import { authReducers, AuthEffects } from './auth'
import { currenciesReducers, CurrenciesEffects } from './currencies'
import { localesReducers, LocalesEffects } from './locales'
import { productsReducers } from './products'
import { cacheReducers, CacheEffects } from './cache'

export const AppReducers = {
  auth: authReducers,
  attributes: attributesReducers,
  cache: cacheReducers,
  currencies: currenciesReducers,
  locales: localesReducers,
  products: productsReducers,
  router: routerReducer,
}

export const AppEffects = [
  AuthEffects,
  AttributesEffects,
  CacheEffects,
  CurrenciesEffects,
  LocalesEffects,
  RouterEffects,
]

export {
  StoreState,
  StoreConfiguration,
  STORE_CONFIGURATION,
} from './store.state'
export { metaReducers } from './storage'
