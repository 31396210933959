import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { Currency, CurrencySearchParams } from './currency.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'currencies'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a currency by ID
   * @param currencyId - The currency ID
   * @returns The observable<Currency> for read the currency
   */
  read$(currencyId: string): Observable<Currency> {
    return this._read$<Currency>(currencyId)
  }

  /**
   * Search countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Currency>> for search countries
   */
  search$(
    params?: CurrencySearchParams,
    returnAll = false,
  ): Observable<Page<Currency>> {
    return this._search$<Currency>(params, returnAll)
  }

  /**
   * List countries by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Currency[]> for list countries
   */
  list$(
    params?: CurrencySearchParams,
    returnAll = false,
  ): Observable<Currency[]> {
    return this._list$<Currency>(params, returnAll)
  }

  /**
   * Generate currencies store
   * @param currencyKeys - the currency keys to load
   * @param currencies - the currencies already loaded
   * @returns the Observable<Currency[]> as store
   */
  store$(
    currencyKeys: string[],
    currencies: Currency[],
    key: '_id' | 'code' = '_id',
  ): Observable<Currency[]> {
    currencyKeys = uniq(currencyKeys)
    currencyKeys = difference(
      currencyKeys,
      currencies.map((u) => u[key]),
    )

    if (currencyKeys.length === 0) {
      return of(currencies)
    }

    return this.list$({ [key]: currencyKeys }).pipe(
      map((crs) => [...currencies, ...crs]),
    )
  }
}
