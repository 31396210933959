import { uniq } from 'lodash'
import { ORDER_SYSTEM_TAGS } from '../order.const'
import { Product } from '../../products'
import { Order } from '../order.model'

/**
 * Assign tags to an order
 * @param order - the order
 * @param tags - the tags to assign
 * @returns the order updated
 */
export function assignOrderTags(order: Order, tags: string[]): Order {
  // Save system tags
  const systemTags = order.tags?.filter((t) => ORDER_SYSTEM_TAGS.includes(t))
  return {
    ...order,
    tags: uniq([...(systemTags || []), ...tags]),
  }
}

/**
 * Get all tags of order and its products
 * @param order - the order
 * @param products - the order products
 * @returns the tags
 */
export function getOrderProductsTags(
  order: Order,
  products: Product[]
): string[] {
  return uniq([
    ...(order.tags || []),
    ...products.reduce((acc, p) => [...acc, ...(p.tags || [])], [] as string[]),
  ])
}
