import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { Catalog, CatalogSearchParams } from './catalog.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'catalogs'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class CatalogsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new catalog
   * @param catalog - The catalog to create
   * @returns The observable<Catalog> to create the catalog
   */
  create$(catalog: Catalog): Observable<Catalog> {
    return this._create$<Catalog>(catalog)
  }

  /**
   * Read a catalog by ID
   * @param catalogId - The catalog ID
   * @returns The observable<Catalog> for read the catalog
   */
  read$(catalogId: string): Observable<Catalog> {
    return this._read$<Catalog>(catalogId)
  }

  /**
   * Update a catalog by ID
   * @param catalogId - The catalog ID
   * @param catalog - The catalog body to update
   * @returns The observable<Catalog> for update the catalog
   */
  update$(catalogId: string, catalog: Catalog): Observable<Catalog> {
    return this._update$<Catalog>(catalogId, catalog)
  }

  /**
   * Create or update a catalog by ID
   * @param catalogId - The catalog ID
   * @param catalog - The catalog body to update
   * @returns The observable<Catalog> for update the catalog
   */
  upsert$(catalog: Catalog): Observable<Catalog> {
    return this._upsert$<Catalog>(catalog, catalog._id)
  }

  /**
   * Delete a catalog by ID
   * @param catalogId - The catalog ID
   * @returns The observable<Catalog> for delete the catalog
   */
  delete$(catalogId: string): Observable<Catalog> {
    return this._delete$<Catalog>(catalogId)
  }

  /**
   * Search catalogs by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Catalog>> for search catalogs
   */
  search$(
    params?: CatalogSearchParams,
    returnAll = false,
  ): Observable<Page<Catalog>> {
    return this._search$<Catalog>(params, returnAll)
  }

  /**
   * List catalogs by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Catalog[]> for list catalogs
   */
  list$(
    params?: CatalogSearchParams,
    returnAll = false,
  ): Observable<Catalog[]> {
    return this._list$<Catalog>(params, returnAll)
  }

  /**
   * Find a catalog by params
   * @param params - The search params
   * @returns the observable<Catalog> for find a catalog
   */
  readOne$(params?: CatalogSearchParams): Observable<Catalog> {
    return this._readOne$<Catalog>(params)
  }
}
