import { AuthError } from './auth.model'

export const AUTH_BLANK_ERRORS: AuthError[] = ['SESSION_CONFLICT']

export const AUTH_ACCESS_ERRORS: AuthError[] = [
  'TOKEN_NOT_VALID',
  'REFRESH_TOKEN_EXPIRED',
  'REFRESH_TOKEN_INVALID',
  'REFRESH_TOKEN_PREMATURE_USAGE',
  'ACCESS_TOKEN_INVALID',
  'ACCESS_TOKEN_PREMATURE_USAGE',
  'SESSION_INVALIDATED',
  'NOT_AUTHENTICATED',
]

export const AUTH_REFRESH_ERRORS: AuthError[] = ['ACCESS_TOKEN_EXPIRED']
