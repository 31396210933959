import { uniq } from 'lodash'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { Page } from '../../../models/util.model'
import {
  PackingList,
  PackingListSearchField,
  PackingListSearchParams,
  PackingListSortField,
  PackingListsListingKeys,
} from '../packing-list.model'
import { getPackingUserIds } from './packing-users.lib'

/**
 * Parse packing-list sort params
 * @param params - The search params
 * @param sort - The sort params
 * @returns the params to use for search packing-list
 */
export function packingListSortParams(
  params: PackingListSearchParams,
  sort: QueryStringSort<PackingListSortField>
): PackingListSearchParams {
  const searchParams: PackingListSearchParams = {}

  switch (sort.field) {
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse packing-list search params
 * @param params - The search params
 * @param filter - The filter params
 * @returns the params to use for search packing-list
 */
export function packingListFilterParams(
  params: PackingListSearchParams,
  filter: QueryStringFilter<PackingListSearchField>
): PackingListSearchParams {
  const searchParams: PackingListSearchParams = {}

  switch (filter.field) {
    case 'pickingListId':
      if (filter.value !== null) {
        searchParams.pickingListId = filter.value
      }
      break
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams.warehouseId = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams.status = filter.value
      }
      break
    case 'type':
      if (filter.value !== null) {
        searchParams.type = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams.createdAt = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse packing-list page keys
 * @param page - the packing-list page
 * @returns the page updated
 */
export function parsePackingListPageKeys(
  page: Page<PackingList>
): PackingListsListingKeys {
  return page.data.reduce<PackingListsListingKeys>(
    (acc, r) => parsePackingListKeys(r, acc),
    {}
  )
}

/**
 * Parse packing-list keys
 * @param packingList - the packing-list
 * @param keys - the keys already loaded
 */
export function parsePackingListKeys(
  packingList: PackingList,
  keys: PackingListsListingKeys = {}
): PackingListsListingKeys {
  const userIds = uniq([
    ...(keys['userIds'] || []),
    ...getPackingUserIds(packingList),
  ])
  const pickingListIds = uniq([
    ...(keys['pickingListIds'] || []),
    ...(packingList.pickingListId ? [packingList.pickingListId] : []),
  ])
  const warehouseIds = uniq([
    ...(keys['warehouseIds'] || []),
    ...(packingList.warehouseId ? [packingList.warehouseId] : []),
  ])

  return {
    pickingListIds,
    userIds,
    warehouseIds,
  }
}
