import { Inject, Injectable, Optional } from '@angular/core'
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs'
import {
  PackingList,
  PackingListAction,
  PackingListAuthData,
  PackingListField,
  PackingListNotificationOptions,
  PackingListOrderData,
  PackingListPackData,
  PackingListPackages,
  PackingListPickData,
  PackingListSearchParams,
  PackingListStationData,
  PackingListsListingData,
  PackingListsListingPage,
  PrintingType,
} from './packing-list.model'
import { Page } from '../../models/util.model'
import { parsePackingListPageKeys } from './libs/packing-search.lib'
import { UsersService, User } from '../users'
import { PackingListsService } from './packing-lists.service'
import { WarehousesService } from '../warehouses'
import {
  PickingList,
  PickingListsService,
  checkPickingOrdersPackable,
  getPickingPickedByUser,
  getPickingStartingToteId,
} from '../picking-lists'
import { Location, LocationSearchParams, LocationsService } from '../locations'
import { LocalStorage } from '../storage'
import {
  Product,
  ProductStatus,
  ProductType,
  ProductsService,
  getProductBarcodeQty,
  getProductWrapperByBarcode,
} from '../products'
import {
  Order,
  OrderAdditionalPackage,
  OrderPackage,
  OrdersService,
  addOrderProductPackage,
  addOrderWrapperPackage,
} from '../orders'
import {
  getPackingActionToPack,
  getPackingOrderToPack,
} from './libs/packing-orders.lib'
import {
  getPackingActionWeightToPack,
  getPackingPackedAmount,
} from './libs/packing-products.lib'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { PackingListNotification } from './libs/packing-notification.lib'

const PACK_WAREHOUSE_ID = 'pk_whd'
const PACK_PACKAGE_LOCATION_ID = 'pk_pkld'
const PACK_COMPUTER_ID = 'pk_cpd'
const PACK_PRINTER_ID = 'pk_prd'
const PACK_PRINTER_A4_ID = 'pk_pr4d'

@Injectable({
  providedIn: 'root',
})
export class PackingListsRepository {
  constructor(
    private packingListsService: PackingListsService,
    private warehousesService: WarehousesService,
    private pickingListsService: PickingListsService,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private usersService: UsersService,
    private locationsService: LocationsService,
    private storage: LocalStorage,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Search packing-lists by params
   * @param searchParams - the search params
   * @param returnAll - the return all flag
   * @returns the observable for search brands
   */
  searchPackingLists$(
    searchParams: PackingListSearchParams,
    fields: PackingListField[],
    listingData?: PackingListsListingData,
  ): Observable<PackingListsListingPage> {
    return this.packingListsService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Load external page packing-lists data
   * @param page - the packing-lists page
   * @param fields - the packing-lists fields
   * @param listingData - the listing data already loaded
   * @returns the observable for load packing external data
   */
  loadExtData$(
    page: Page<PackingList>,
    fields: PackingListField[],
    listingData?: PackingListsListingData,
  ): Observable<PackingListsListingData> {
    const extDataKeys = parsePackingListPageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (
      (fields.includes('startedBy') ||
        fields.includes('packedBy') ||
        fields.includes('startedBy')) &&
      extDataKeys.userIds
    ) {
      obs$['users'] = this.usersService.store$(
        extDataKeys.userIds,
        listingData?.users || [],
      )
    }

    if (fields.includes('warehouseId') && extDataKeys.warehouseIds) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (fields.includes('pickingListId') && extDataKeys.pickingListIds) {
      obs$['pickingLists'] = this.pickingListsService.store$(
        extDataKeys.pickingListIds,
        listingData?.pickingLists || [],
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }

  /**
   * Load pick data by packing-list ID
   * @param packingListId - the packing-list ID
   * @returns the observable for load packing-list pick data
   */
  loadPackingDataById$(packingListId: string): Observable<PackingListPickData> {
    return this.packingListsService
      .read$(packingListId)
      .pipe(
        switchMap((packingList) =>
          this.loadPackingPickDataByPickingId$(packingList.pickingListId),
        ),
      )
  }

  /**
   * Load pick data by picking-list ID
   * @param pickingListId - the picking list ID
   * @returns the observable for load packing data by picking-list ID
   */
  loadPackingDataByPickingId$(
    pickingListId: string,
  ): Observable<PackingListPickData> {
    return this.packingListsService
      .findOne$({ pickingListId })
      .pipe(
        switchMap((packingList) =>
          this.loadPackingPickDataByPickingId$(pickingListId, packingList),
        ),
      )
  }

  /**
   * Load pick data by tote CODE
   * @param code - the location tote code
   * @param warehouseId - the warehouse ID to specify
   * @returns the observable for load packing-list pick data
   */
  loadPackingDataByToteCode$(
    code: string,
    warehouseId?: string,
  ): Observable<PackingListPickData> {
    const params: LocationSearchParams = { code }

    if (warehouseId) {
      params.warehouseId = warehouseId
    }

    return this.locationsService.list$(params).pipe(
      map((totes) => (totes.length ? totes[0] : undefined)),
      switchMap((tote) => {
        if (!tote) {
          return this.packingNotification$({ code: 'TOTE_NOT_FOUND' })
        }

        if (!tote.pickingListId) {
          return this.packingNotification$({ code: 'TOTE_EMPTY' })
        }

        return this.loadPackingPickDataByPickingId$(tote.pickingListId)
      }),
    )
  }

  /**
   * Load packing data by picking-list ID
   * @param pickingListId - the picking-list ID
   * @param packingList - the packing-list already loaded
   * @returns the observable for load packing data
   */
  loadPackingPickDataByPickingId$(
    pickingListId: string,
    packingList?: PackingList,
  ): Observable<PackingListPickData> {
    return this.pickingListsService
      .read$(pickingListId)
      .pipe(
        switchMap((pickingList) =>
          this.loadPackingPickDataByPicking$(pickingList, packingList),
        ),
      )
  }

  /**
   * Load packing station data
   * @returns the observable for load station data
   */
  loadPackingStationData$(
    authData?: PackingListAuthData,
  ): Observable<PackingListStationData> {
    // Get storage data
    const warehouseId =
      this.storage.get(PACK_WAREHOUSE_ID) || authData?.warehouse?._id
    const packLocationId =
      this.storage.get(PACK_PACKAGE_LOCATION_ID) ||
      authData?.settings?.packagingLocationId ||
      authData?.warehouse?.packagingLocationId
    const computerId =
      this.storage.get(PACK_COMPUTER_ID) || authData?.settings?.computerId
    const printerId =
      this.storage.get(PACK_PRINTER_ID) || authData?.settings?.printers?.label
    const A4PrinterId =
      this.storage.get(PACK_PRINTER_A4_ID) || authData?.settings?.printers?.a4

    return combineLatest({
      warehouseId: warehouseId
        ? of(warehouseId)
        : this.warehousesService
            .list$({ limit: 1 })
            .pipe(
              map((warehouses) =>
                warehouses.length ? warehouses[0]._id : undefined,
              ),
            ),
      packagingLocation: packLocationId
        ? this.locationsService.read$(packLocationId)
        : of(undefined),
      currentUserId: of(authData?.user?._id),
      packagingManagement: this.productsService.isPackagingManagementEnabled$(),
      printingType: of('manual' as PrintingType),
      computerId: of(computerId ? computerId.toString() : undefined),
      printerId: of(printerId ? printerId.toString() : undefined),
      A4PrinterId: of(A4PrinterId ? A4PrinterId.toString() : undefined),
    })
  }

  /**
   * Save packing station data
   * @param stationData - the packing list station data
   */
  savePackingStationData(stationData: PackingListStationData): void {
    const {
      warehouseId,
      packagingLocation,
      computerId,
      printerId,
      A4PrinterId,
    } = stationData

    warehouseId
      ? this.storage.set(PACK_WAREHOUSE_ID, warehouseId)
      : this.storage.remove(PACK_WAREHOUSE_ID)

    packagingLocation
      ? this.storage.set(PACK_PACKAGE_LOCATION_ID, packagingLocation._id)
      : this.storage.remove(PACK_PACKAGE_LOCATION_ID)

    computerId
      ? this.storage.set(PACK_COMPUTER_ID, computerId.toString())
      : this.storage.remove(PACK_COMPUTER_ID)

    printerId
      ? this.storage.set(PACK_PRINTER_ID, printerId.toString())
      : this.storage.remove(PACK_PRINTER_ID)

    A4PrinterId
      ? this.storage.set(PACK_PRINTER_A4_ID, A4PrinterId.toString())
      : this.storage.remove(PACK_PRINTER_A4_ID)
  }

  /**
   * Load packing data by picking-list
   * @param pickingList - the picking-list
   * @param packingList - the packing-list already loaded
   * @returns the observable for load packing data
   */
  loadPackingPickDataByPicking$(
    pickingList: PickingList,
    packingList?: PackingList,
  ): Observable<PackingListPickData> {
    return combineLatest({
      pickingList: of(pickingList),
      packingList: packingList
        ? of(packingList)
        : this.packingListsService
            .list$({
              pickingListId: pickingList._id,
            })
            .pipe(map((packings) => packings[0])),
      pickingUser: this.loadPickingUser$(pickingList),
      orders: this.loadPickingOrders$(pickingList),
      totes: this.loadPickingTotes$(pickingList),
    })
  }

  /**
   * Start a packing-list from a picking-list
   * @param pickingList - the picking-list
   * @param printers - the printers configuration
   * @param packagingLocationId - the packaging location ID
   * @returns the observable for start a new packing-list
   */
  startPacking$(
    pickingList: PickingList,
    orders: Order[],
    printerId: string,
    printerA4Id: string,
    packagingLocationId?: string,
  ): Observable<PackingListPackData> {
    return this.checkPickingPackable$(pickingList).pipe(
      switchMap((toteId) =>
        this.packingListsService
          .create$(
            toteId,
            { labels: printerId, a4: printerA4Id },
            packagingLocationId,
          )
          .pipe(
            switchMap((packingList) =>
              this.loadPackingPackData$(packingList, orders),
            ),
          ),
      ),
    )
  }

  /**
   * Restore a packing-list already started
   * @param packingList - the packing-list to restore
   * @param pickingList - the picking-list
   * @param currentUserId - the current user ID
   * @param alertComponent - the alert component to use
   * @returns the observable for restore the packing
   */
  restorePacking$(
    packingList: PackingList,
    pickingList: PickingList,
    orders: Order[],
    currentUserId: string,
    printerId: string,
    printerA4Id: string,
    packagingLocationId?: string,
  ): Observable<PackingListPackData> {
    if (currentUserId !== packingList.userId) {
      return this.usersService.findOne$({ _id: packingList.userId }).pipe(
        switchMap((user) =>
          this.packingNotification$({
            code: 'PACKED_BY_ANOTHER_USER',
            data: {
              pickingListName: pickingList.name,
              user,
            },
          }),
        ),
      )
    }

    return this.packingListsService
      .takeUp$(
        packingList._id,
        { labels: printerId, a4: printerA4Id },
        packagingLocationId,
      )
      .pipe(
        switchMap((packingList) =>
          this.loadPackingPackData$(packingList, orders),
        ),
      )
  }

  /**
   * Take-up packing started by another user
   * @param packingListId - the packing-list ID
   * @param orders - the orders of the packing
   * @param printerId - the printer ID
   * @param printerA4Id - the A4 printer ID
   * @param packagingLocationId - the packaging location ID
   * @returns the observable for take-up the packing
   */
  takeUpPacking$(
    packingListId: string,
    orders: Order[],
    printerId: string,
    printerA4Id: string,
    packagingLocationId?: string,
  ): Observable<PackingListPackData> {
    return this.packingListsService
      .takeUp$(
        packingListId,
        { labels: printerId, a4: printerA4Id },
        packagingLocationId,
      )
      .pipe(
        switchMap((packingList) =>
          this.loadPackingPackData$(packingList, orders),
        ),
      )
  }

  /**
   * Load packing pack data
   * @param packingList - the packing list
   * @param orders - the orders of the packing
   * @returns the observable for load packing pack data
   */
  loadPackingPackData$(
    packingList: PackingList,
    orders: Order[],
    lastOrder?: Order,
  ): Observable<PackingListPackData> {
    const orderToPack = getPackingOrderToPack(packingList)
    const order = orders.find((o) => o._id === orderToPack?._id)

    if (!order) {
      return of({
        packingList,
        lastOrder,
      })
    }

    return this.loadOrderData$(packingList, order).pipe(
      map((orderData) => ({
        packingList,
        lastOrder,
        ...orderData,
      })),
    )
  }

  /**
   * Load order packing data
   * @param packingList - the packing list,
   * @param order - the order
   * @returns the observable for load the packing order data
   */
  loadOrderData$(
    packingList: PackingList,
    order: Order,
  ): Observable<PackingListOrderData> {
    return combineLatest({
      order: of(order),
      action: of(getPackingActionToPack(packingList, order._id)),
      products: this.loadPackingOrderProducts$(packingList, order._id),
      packages: of(order.packages),
      additionalPackages: of(order.additionalPackages),
    })
  }

  /**
   * Check if a picking is packable and notify the user if not
   * @param pickingList - the picking list to check
   * @returns observable<boolean> to check if the picking is packable
   */
  checkPickingPackable$(pickingList: PickingList): Observable<string> {
    return this.loadPickingOrders$(pickingList).pipe(
      map((orders) => checkPickingOrdersPackable(orders)),
      switchMap((ordersPackable) => {
        if (!ordersPackable) {
          return this.packingNotification$({ code: 'PICKING_NOT_PACKABLE' })
        }

        const toteId = getPickingStartingToteId(pickingList)
        if (!toteId) {
          return this.packingNotification$({ code: 'PICKING_WITHOUT_TOTES' })
        }

        return of(toteId)
      }),
    )
  }

  /**
   * Scan a product of a packing-list
   * @param productCode - the scanned CODE
   * @param packingData - the packing-list pack data
   * @param orderData - the packing-list order data
   * @returns the observable for scan a product
   */
  scanProduct$(
    scannedCode: string,
    packingData: PackingListPickData,
    orderData: PackingListOrderData,
  ): Observable<PackingListPackData> {
    return this.productsService.readOne$({ code: scannedCode }).pipe(
      catchError(() =>
        this.packingNotification$({ code: 'PRODUCT_NOT_FOUND' }),
      ),
      switchMap((product) => {
        if (!packingData.packingList || !packingData.orders) {
          return EMPTY
        }

        const { packingList, orders } = packingData

        // Check product package
        if (product.productType === ProductType.package) {
          return this.addPackage$(product, packingList, orderData)
        }

        return this.packProduct$(
          product,
          packingList,
          orders,
          orderData,
          scannedCode,
          getProductBarcodeQty(product, scannedCode),
        )
      }),
    )
  }

  /**
   * Add a package to pack data
   * @param product - the product package
   * @param packData - the pack data
   * @returns the observable for adding a package to packing packages
   */
  addPackage$(
    product: Product,
    packingList: PackingList,
    orderData: PackingListOrderData,
  ): Observable<PackingListPackData> {
    if (product.status === ProductStatus.disabled) {
      return this.packingNotification$({ code: 'PACKAGING_DISABLED' })
    }

    if (!orderData.order) {
      return this.packingNotification$({ code: 'ORDER_NOT_SELECTED' })
    }

    const weightToPack = getPackingActionWeightToPack(
      packingList,
      orderData.order._id,
      orderData.packages || [],
    )

    return of({
      ...orderData,
      packingList,
      packages: addOrderProductPackage(
        orderData.packages || [],
        product,
        weightToPack,
      ),
    }).pipe(tap(() => this.notify({ code: 'PACKAGING_ADDED' })))
  }

  /**
   * Pack a product
   * @param product - the product to pack
   * @param packingList - the packing-list
   * @param orders - the orders of the packing-list
   * @param packData - the pack data
   * @param quantity - the quantity to pack
   * @returns the observable for pack a product
   */
  packProduct$(
    product: Product,
    packingList: PackingList,
    orders: Order[],
    packData: PackingListOrderData,
    barcode: string,
    quantity = 1,
  ): Observable<PackingListPackData> {
    // Check current order
    let action: PackingListAction | undefined
    let obs$: Observable<{ order: Order; products: Product[] }>

    const prodActions = packingList.actions.filter(
      (a) => a.productId === product._id,
    )

    if (!prodActions.length) {
      return this.packingNotification$({ code: 'PRODUCT_PACK_NOT_CONTAINED' })
    }

    if (packData.order && packData.products) {
      const orderActions = prodActions.filter(
        (a) => a.orderId === packData.order?._id,
      )
      if (!orderActions.length) {
        return this.packingNotification$({
          code: 'PRODUCT_ORDER_NOT_CONTAINED',
        })
      }

      action = orderActions.find((a) => a.qtyToPack !== a.qtyPacked)
      if (!action) {
        return this.packingNotification$({ code: 'PRODUCT_ALREADY_PACKED' })
      }

      obs$ = combineLatest({
        order: of(packData.order),
        products: of(packData.products),
      })
    } else {
      action = prodActions.find((a) => a.qtyToPack !== a.qtyPacked)
      if (!action) {
        return this.packingNotification$({ code: 'PRODUCT_ALREADY_PACKED' })
      }

      const order = orders.find((o) => o._id === action?.orderId)
      if (!order) {
        return this.packingNotification$({ code: 'ORDER_NOT_FOUND' })
      }

      obs$ = combineLatest({
        order: of(order),
        products: this.loadPackingOrderProducts$(packingList, order._id),
      })
    }

    return obs$.pipe(
      switchMap(({ order, products }) =>
        this.packingListsService
          .scanProduct$(packingList._id, order._id, {
            productId: product._id,
            quantity,
            lot: action?.lot,
            expirationDate: action?.expirationDate,
            serial: action?.serial,
          })
          .pipe(
            map((updatedPacking) => {
              // Check action
              let action = updatedPacking.actions.find(
                (a) => a.productId === product._id && a.orderId === order._id,
              )
              if (action?.qtyToPack === action?.qtyPacked) {
                action = getPackingActionToPack(updatedPacking, order._id)
              }

              return {
                ...packData,
                packingList: updatedPacking,
                packages: this.checkProductPackages(
                  updatedPacking,
                  product,
                  barcode,
                  packData.packages || [],
                  order._id,
                ),
                action,
                order,
                products,
              }
            }),
          ),
      ),
    )
  }

  /**
   * Pack a Tote
   * @param packingList - the packing-list
   * @param orderData - the packing-list order data
   * @returns the observable for pack a tote
   */
  packTote$(
    packingList: PackingList,
    orderData: PackingListOrderData,
  ): Observable<PackingListPackData> {
    if (!orderData.action?.toteId || !orderData.order) {
      return this.packingNotification$({ code: 'TOTE_NOT_FOUND' })
    }

    return this.packingListsService
      .scanTote$(packingList._id, orderData.action.toteId)
      .pipe(
        map((updatedPacking) => {
          const order = orderData.order
          const productId = orderData.action?.productId
          const action = updatedPacking.actions.find(
            (a) => a.productId === productId && a.orderId === order?._id,
          )
          return {
            ...orderData,
            packingList: updatedPacking,
            action,
          }
        }),
      )
  }

  /**
   * Edit order packages
   * @param packingList - the packing-list
   * @param orderData - the order data
   * @param packagingManagement - the packaging management
   */
  editOrderPackages$(
    packingList: PackingList,
    orderData: PackingListOrderData,
    packagesComponent: any,
  ): Observable<PackingListPackages | undefined> {
    const order = orderData.order
    const packages = orderData.packages || []
    if (!order || !this.modalManager) {
      return this.packingNotification$({ code: 'ORDER_NOT_SELECTED' })
    }

    return this.modalManager
      .show$<any, any>({
        component: packagesComponent,
        modalClass: 'modal-xl',
        initialState: {
          packages,
          additionalPackages: orderData.additionalPackages || [],
          weightToPack: getPackingActionWeightToPack(
            packingList,
            order._id,
            packages,
          ),
          itemsToPack: getPackingPackedAmount(packingList, order._id),
          orderNumber: order.header.orderNumber,
          orderRef: order.header.rifOrder,
          showPrintBarcode: true,
          btnClass: 'btn-xl',
          actions: [
            {
              type: 'print',
              class: 'btn-primary btn-xl',
              label: 'Stampa etichette',
            },
          ],
        },
      })
      .pipe(map((res) => res?.data))
  }

  /**
   * Print order of a packing-list
   * @param packingList - the packing-list
   * @param orderId - the order ID
   * @param orderPackages - the order packages
   * @param additionalPackages - the order additional packages
   * @param force - the force flag
   * @returns the observable for print the order
   */
  printOrder$(
    packingList: PackingList,
    orderId: string,
    orders: Order[],
    packages: OrderPackage[],
    additionalPackages?: OrderAdditionalPackage[],
    force = false,
  ): Observable<PackingListPackData> {
    return this.packingListsService
      .packOrder$(packingList._id, orderId, {
        packages,
        additionalPackages,
        force,
      })
      .pipe(
        switchMap((packingList) =>
          this.loadPackingPackData$(
            packingList,
            orders,
            orders.find((o) => o._id === orderId),
          ),
        ),
      )
  }

  /**
   * Show packing-list alert
   * @param options - the packing-list notification or notification options
   * @returns the observable for show the alert about packing-list
   */
  alert$(
    opts: PackingListNotification | PackingListNotificationOptions,
  ): Observable<boolean> {
    const notification = PackingListNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a packing event
   * @param notification - the packing notification
   */
  notify(opts: PackingListNotification | PackingListNotificationOptions): void {
    const notification = PackingListNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }

  // Utilities

  private packingNotification$(
    opts: PackingListNotificationOptions,
  ): Observable<never> {
    return throwError(() => PackingListNotification.from(opts))
  }

  private loadPickingTotes$(pickingList: PickingList): Observable<Location[]> {
    return this.locationsService.list$(
      {
        _id: pickingList.totes.map((t) => t._id),
      },
      true,
    )
  }

  private loadPickingOrders$(pickingList: PickingList): Observable<Order[]> {
    return this.ordersService.list$({ pickingListId: pickingList._id }, true)
  }

  private loadPickingUser$(
    pickingList: PickingList,
  ): Observable<User | undefined> {
    const userId = getPickingPickedByUser(pickingList)
    return userId ? this.usersService.read$(userId) : of(undefined)
  }

  private loadPackingOrderProducts$(
    packingList: PackingList,
    orderId: string,
  ): Observable<Product[]> {
    const productIds = packingList.actions
      .filter((a) => a.orderId === orderId)
      .map((a) => a.productId)

    if (!productIds.length) {
      return of([])
    }

    return this.productsService.list$({ _id: productIds }, true)
  }

  private checkProductPackages(
    packingList: PackingList,
    product: Product,
    barcode: string,
    packages: OrderPackage[],
    orderId: string,
  ): OrderPackage[] {
    const wrapper = getProductWrapperByBarcode(product, barcode)

    if (!wrapper?.isSelfShipping) {
      return packages
    }

    const weightToPack = getPackingActionWeightToPack(
      packingList,
      orderId,
      packages,
    )

    return addOrderWrapperPackage(packages, wrapper, weightToPack)
  }
}
