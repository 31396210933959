<ng-container *ngVar="tenant$ | ngrxPush as tenant">
  <ng-container *ngVar="user$ | ngrxPush as user">
    <!-- User -->
    <ng-container *ngIf="user; else noUser">
      <!-- Authenticated -->
      <ng-container *ngIf="tenant; else noTenant">
        <h1 class="title">
          Hai effettuato l'accesso ad <em>order<strong>pod</strong></em>
        </h1>
        <button
          class="btn btn-outline-secondary btn-choice btn-block btn-lg"
          type="button"
          [routerLink]="['/home']"
        >
          <i class="icon fad fa-store-alt"></i>
          <div class="data text-left">
            <h4 class="label">{{ tenant.name }}</h4>
          </div>
        </button>
      </ng-container>

      <!-- Not authenticated -->
      <ng-template #noTenant>
        <h1 class="title">
          Bentornato in <em>order<strong>pod</strong></em>
        </h1>
        <button
          class="btn btn-outline-secondary btn-choice btn-block btn-lg"
          type="button"
          [routerLink]="['/auth/tenant']"
        >
          <i class="icon fad fa-user-circle"></i>
          <div class="data text-left">
            <h4 class="label">{{ user.name }} {{ user.surname }}</h4>
            <p class="label" *ngIf="user.email">
              <small>{{ user.email }}</small>
            </p>
          </div>
        </button>
        <a
          id="logout-link"
          class="text-secondary"
          href
          (click)="logout(); (false)"
        >
          Accedi con un altro account
        </a>
      </ng-template>
    </ng-container>

    <!-- No user -->
    <ng-template #noUser>
      <form
        *ngIf="loginForm"
        [formGroup]="loginForm"
        (tkCapsLock)="capsOn = $event"
      >
        <h1 class="title">
          Benvenuto in <em>order<strong>pod</strong></em>
        </h1>
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="La tua username"
            formControlName="username"
            autocomplete="username"
            data-test="username"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control form-control-lg"
            [class.border]="capsOn"
            [class.border-info]="capsOn"
            placeholder="La tua password"
            formControlName="password"
            autocomplete="current-password"
            #passwordInput
            data-test="password"
            (keyup.enter)="login(); passwordInput.blur()"
          />
        </div>
        <input
          type="checkbox"
          class="d-none"
          formControlName="force"
          data-test="force"
        />
        <p class="text-center text-info font-weight-bold" *ngIf="capsOn">
          <small>Caps Lock attivo <i class="fad fa-lock-alt"></i></small>
        </p>
        <button
          id="btn-login"
          data-test="btn-login"
          class="btn btn-primary btn-block btn-lg"
          type="button"
          (click)="login()"
          [disabled]="!loginForm.valid"
        >
          Accedi
        </button>
        <a
          id="link-recovery"
          data-test="link-recovery"
          class="text-secondary"
          [routerLink]="['/auth/recovery']"
        >
          Password dimenticata?
        </a>
      </form>
    </ng-template>
  </ng-container>
</ng-container>
