import { Pipe, PipeTransform } from '@angular/core'
import { ProductWrapper } from '../product.model'

@Pipe({
  name: 'wrapperTotPieces',
})
export class WrapperTotalPiecesPipe implements PipeTransform {
  transform(wrapper: ProductWrapper, wrappers: ProductWrapper[]): string {
    if (!wrappers.length) {
      return ''
    }
    if (!wrapper.childBarcode) {
      return `${wrapper.multiplier}`
    }
    return `${this.calculateTotalPieces(wrapper, wrappers)}`
  }

  calculateTotalPieces(
    wrapper: ProductWrapper,
    wrappers: ProductWrapper[],
  ): number {
    let totPieces = 0
    const findWrapper = (
      barcode: string | undefined,
    ): ProductWrapper | undefined => {
      return !barcode
        ? undefined
        : wrappers.find((wr) => wr.barcode === barcode)
    }
    const calculate = (currentWrapper: ProductWrapper): number => {
      let pieces = currentWrapper.multiplier
      const childWrapper = findWrapper(currentWrapper.childBarcode)
      if (childWrapper) {
        pieces *= calculate(childWrapper)
      }
      return pieces
    }
    totPieces += calculate(wrapper)
    return totPieces
  }
}
