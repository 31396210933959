import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { ProductScope } from '@evologi/shared/data-access-api'

import { StoreState } from '../store.state'
import {
  selectProductsToModify,
  selectProductsScope,
  selectNextProductToModify,
} from './products.selectors'
import {
  setProductsScope,
  setProductsToModify,
  unsetProductsToModify,
} from './products.actions'
import { ProductToModify } from './products.state'

@Injectable({
  providedIn: 'root',
})
export class ProductsStoreService {
  constructor(private store: Store<StoreState>) {}

  /**
   * Get product scope
   * @returns the observable<ProductScope>
   */
  getScope$(): Observable<ProductScope | undefined> {
    return this.store.select(selectProductsScope)
  }

  /**
   * Set product scope
   * @param scope the scope to
   */
  setScope(scope: ProductScope): void {
    this.store.dispatch(setProductsScope({ scope }))
  }

  /**
   * Get products to modify
   * @returns the obsevable for get the product IDs to modify
   */
  getProductsToModify$(): Observable<ProductToModify[] | undefined> {
    return this.store.select(selectProductsToModify)
  }

  /**
   * Get next product to modify
   * @returns the observable for get the next product to modify
   */
  getNextProductToModify$(): Observable<ProductToModify | undefined> {
    return this.store.select(selectNextProductToModify)
  }

  /**
   * Set product IDs to modify
   * @returns the observable for set the product IDs to modify
   */
  setProductsToModify(ids: string[]) {
    this.store.dispatch(setProductsToModify({ ids }))
  }

  /**
   * Unset products to modify
   * @returns the observable for unset the products to modify
   */
  unsetProductsToModify() {
    this.store.dispatch(unsetProductsToModify())
  }
}
