import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'

import { Attribute } from '../../attributes'
import {
  View,
  ViewData,
  ViewExternalData,
  ViewTargetParam,
  ViewFilter,
  ViewTarget,
  ViewParam,
} from '../view.model'
import { ViewsService } from '../views.service'
import { ViewsRepository } from '../views.repository'
import {
  EMPTY,
  Observable,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import { getViewTargetParams } from '../libs/view.lib'
import { VIEW_DEFAULT_CODE } from '../view.const'

interface ViewManagementState {
  viewId?: string
  userId?: string
  target?: ViewTarget
  type?: string
  view?: View
  views?: View[]
  attributes?: Attribute[]
  targetParams?: ViewTargetParam[]
  extData?: ViewExternalData[]
  error?: HttpErrorResponse
  isLoading: boolean
  isInitialized: boolean
  isModified: boolean
}

interface InitData {
  target: ViewTarget
  type: string
  userId: string
  viewId?: string
}

const VIEW_MANAGEMENT_INITIAL_STATE: ViewManagementState = {
  isLoading: false,
  isInitialized: false,
  isModified: false,
}

@Injectable()
export class ViewsManagementUseCase extends ComponentStore<ViewManagementState> {
  constructor(
    private viewsRepository: ViewsRepository,
    private viewsService: ViewsService,
  ) {
    super(VIEW_MANAGEMENT_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectTarget$: Observable<ViewTarget> = this.select(
    (state) => state.target,
  ).pipe(
    filter((target) => !!target),
    map((target) => target!),
  )

  readonly selectType$: Observable<string> = this.select(
    (state) => state.type,
  ).pipe(
    filter((type) => !!type),
    map((type) => type!),
  )

  readonly selectView$: Observable<View | undefined> = this.select(
    (state) => state.view,
  )

  readonly selectAttributes$: Observable<Attribute[] | undefined> =
    this.selectState$.pipe(
      filter((state) => !!state?.isInitialized && !!state.view),
      map((state) => state.attributes),
    )

  readonly selectInitializedView$: Observable<View> = this.selectState$.pipe(
    filter((state) => !!state?.isInitialized && !!state.view),
    map((state) => state.view!),
  )

  readonly selectFilters$: Observable<ViewFilter[] | undefined> =
    this.selectInitializedView$.pipe(map((view) => view?.filters))

  readonly selectParams$: Observable<ViewParam[] | undefined> =
    this.selectInitializedView$.pipe(map((view) => view?.params))

  readonly selectFields$: Observable<string[] | undefined> =
    this.selectInitializedView$.pipe(map((view) => view?.fields))

  readonly selectTargetParams$: Observable<ViewTargetParam[] | undefined> =
    this.selectState$.pipe(
      filter((state) => !!state?.isInitialized && !!state.view),
      map((state) => state.targetParams),
    )

  readonly selectExternalData$: Observable<ViewExternalData[] | undefined> =
    this.selectState$.pipe(
      filter((state) => !!state?.isInitialized && !!state.view),
      map((state) => state.extData),
    )

  readonly selectParamsData$: Observable<{
    params?: ViewParam[]
    extData?: ViewExternalData[]
  }> = combineLatest({
    params: this.selectParams$,
    extData: this.selectExternalData$,
  })

  readonly selectUserId$: Observable<string | undefined> = this.select(
    (state) => state.userId,
  )

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsEditable$: Observable<boolean> = this.select(
    (state) => state.userId === state.view?.createdBy,
  )

  readonly selectViews$: Observable<View[] | undefined> = this.select(
    (state) => state.views,
  )

  readonly selectViewId$: Observable<string | undefined> = this.select(
    (state) => state.viewId,
  )

  // Effects

  readonly init$ = this.effect((initData$: Observable<InitData>) => {
    return initData$.pipe(
      tap((initData) => this.setInitData(initData)),
      switchMap(({ target, type, viewId, userId }) =>
        this.viewsRepository.loadViewsData$(viewId, target, userId, type).pipe(
          tapResponse(
            (data) => this.setViewsData(data),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly load$ = this.effect((viewId$: Observable<string>) => {
    return viewId$.pipe(
      tap((viewId) => this.setViewId(viewId)),
      withLatestFrom(this.selectTarget$, this.selectType$),
      switchMap(([viewId, target, type]) =>
        this.viewsRepository.loadViewData$(viewId, target, type).pipe(
          tapResponse(
            (data) => this.setViewData(data),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly create$ = this.effect((viewName$: Observable<string>) => {
    return viewName$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectView$),
      switchMap(([name, view]) => {
        if (!view) {
          return EMPTY
        }

        return this.viewsService.create$({ ...view, name }).pipe(
          tapResponse(
            (view) => this.addView(view),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  readonly update$ = this.effect((viewId$: Observable<string>) => {
    return viewId$.pipe(
      tap((viewId) => this.setViewId(viewId)),
      withLatestFrom(this.selectTarget$, this.selectUserId$, this.selectType$),
      switchMap(([viewId, target, userId, type]) => {
        if (!userId) {
          return EMPTY
        }

        return this.viewsRepository
          .loadViewsData$(viewId, target, userId, type)
          .pipe(
            tapResponse(
              (data) => this.setViewsData(data),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly save$ = this.effect((save$: Observable<void>) => {
    return save$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectView$),
      switchMap(([empty, view]) => {
        if (!view) {
          return EMPTY
        }

        return this.viewsService.upsert$(view).pipe(
          tapResponse(
            (view) => this.setView(view),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        )
      }),
    )
  })

  // Reducers

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): ViewManagementState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setViewId = this.updater(
    (state, viewId: string): ViewManagementState => {
      return {
        ...state,
        viewId,
        isLoading: true,
      }
    },
  )

  readonly addView = this.updater((state, view: View): ViewManagementState => {
    return {
      ...state,
      viewId: view._id,
      view,
      views: [...(state.views || []), view],
      isModified: false,
      isLoading: false,
    }
  })

  readonly setView = this.updater((state, view: View): ViewManagementState => {
    return {
      ...state,
      viewId: view._id,
      view,
      isModified: false,
      isLoading: false,
    }
  })

  readonly setFilters = this.updater(
    (state, filters: ViewFilter[] | undefined): ViewManagementState => {
      return {
        ...state,
        view: state.view
          ? {
              ...state.view,
              filters,
            }
          : undefined,
        isModified: true,
      }
    },
  )

  readonly setParams = this.updater(
    (state, params: ViewParam[] | undefined): ViewManagementState => {
      return {
        ...state,
        view: state.view
          ? {
              ...state.view,
              params,
            }
          : undefined,
        isModified: true,
      }
    },
  )

  readonly updateView = this.updater(
    (state, view: View): ViewManagementState => {
      return {
        ...state,
        view,
        isLoading: false,
        isModified: true,
      }
    },
  )

  readonly setInitData = this.updater(
    (state, initData: InitData): ViewManagementState => {
      return {
        target: initData.target,
        type: initData.type,
        userId: initData.userId,
        viewId: initData.viewId || VIEW_DEFAULT_CODE,
        isModified: false,
        isInitialized: false,
        isLoading: true,
      }
    },
  )

  readonly setViewsData = this.updater(
    (
      state,
      viewsData: { views: View[]; viewData: ViewData },
    ): ViewManagementState => {
      const { viewData, views } = viewsData
      return {
        ...state,
        views,
        view: viewData.view,
        attributes: viewData.attributes,
        targetParams: getViewTargetParams(viewData.view.target),
        extData: viewData.externalData,
        isModified: false,
        isInitialized: true,
        isLoading: false,
      }
    },
  )

  readonly setViewData = this.updater(
    (state, viewData: ViewData): ViewManagementState => {
      return {
        ...state,
        view: viewData.view,
        attributes: viewData.attributes,
        extData: viewData.externalData,
        isModified: false,
        isLoading: false,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ViewManagementState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly addAttribute = this.updater(
    (state, attribute: Attribute): ViewManagementState => {
      return {
        ...state,
        attributes: [...(state.attributes || []), attribute],
        isModified: true,
      }
    },
  )

  readonly addExternalData = this.updater(
    (state, extData: ViewExternalData): ViewManagementState => {
      return {
        ...state,
        extData: [...(state.extData || []), extData],
        isModified: true,
      }
    },
  )
}
