export enum PromotionType {
  coupon = 'COUPON',
  giftCard = 'GIFT_CARD',
}

export interface Promotion {
  type: PromotionType
  code: string
  description?: string
  amount: number
  secret?: string
}
