import moment from 'moment'
import { omitBy, isNil, isEmpty } from 'lodash'

import { removeEmptyScope } from './locale.lib'
import {
  Product,
  ProductDiscount,
  ProductPrice,
  ProductScope,
} from '../product.model'
import { getCatalogValue, setCatalogValue } from '../../catalogs/catalog.lib'
import { CatalogLocales, Price } from '../../../models/util.model'

/**
 * Get current product price
 * @param product - The product
 * @param type - The price type
 * @returns the price value
 */
export function getProductPrice(
  product: Product,
  type: 'purchase' | 'listing' | 'discount'
): number {
  if (!product.price) {
    return 0
  }

  const price = getCatalogValue<Price>(product.price[type] || [])
  return price?.value || 0
}

/**
 * Get current purchase price
 * @param product - The product
 * @returns the purchase price value
 */
export function getProductPurchasePrice(product: Product): number {
  return getProductPrice(product, 'purchase')
}

/**
 * Get current listing price
 * @param product - The product
 * @returns the listing price value
 */
export function getProductListingPrice(product: Product): number {
  return getProductPrice(product, 'listing')
}

/**
 * Get current discount price
 * @param product - The product
 * @returns the discount price value
 */
export function getProductDiscountPrice(product: Product): number {
  return getProductPrice(product, 'discount')
}

/**
 * Get active discount price
 * @param product - The product
 * @returns the discount active discount price value (0 if not more active)
 */
export function getProductDiscountActivePrice(product: Product): number {
  if (!product.price) {
    return 0
  }

  const discount = getCatalogValue<Price>(product.price.discount || [])
  return getDiscountAmount(discount)
}

/**
 * Get current purchasing price
 * @param product - The product
 * @returns the current purchasing price. Take care of current active discounts
 */
export function getProductPurchasingPrice(
  product: Product,
  supplierId?: string
): number {
  if (!product.price) {
    return 0
  }

  const supplier = product.suppliers?.find(
    (sup) => sup.supplierId === supplierId
  )
  const purchase = getProductPurchasePrice(product)

  if (!supplier || !supplier.price) {
    return purchase
  }

  const discount = getDiscountAmount(supplier.price?.promotion)
  return discount ? discount : supplier.price.net ? supplier.price.net : 0
}

/**
 * Get current selling price
 * @param product - The product
 * @returns the current selling price. Take care of current active discount
 */
export function getProductSellingPrice(product: Product): number {
  if (!product.price) {
    return 0
  }

  const discount = getProductDiscountActivePrice(product)
  const listing = getProductListingPrice(product)

  return discount || listing
}

function getDiscountAmount(discount?: ProductDiscount): number {
  if (!discount || !discount.value) {
    return 0
  }

  // Check start date and end date
  if (discount.startDate || discount.endDate) {
    const isBetween =
      discount.startDate &&
      discount.endDate &&
      moment().isBetween(discount.startDate, discount.endDate, 'day', '[]')
    const greaterThan =
      discount.startDate &&
      !discount.endDate &&
      moment() >= moment(discount.startDate)
    const lesserThan =
      !discount.startDate &&
      discount.endDate &&
      moment() <= moment(discount.endDate)

    return isBetween || greaterThan || lesserThan ? discount.value : 0
  }

  return discount.value
}

/**
 * Update product price
 * @param price - The product price
 * @param catalogCode - The catalog code
 * @param locale - The locale code
 * @returns the product price
 */
export function updatePrices(
  price: ProductPrice,
  catalogCode = 'default',
  locale = 'default'
): ProductPrice {
  const listing = getCatalogValue(price.listing || [], catalogCode, locale)
  const discount = getCatalogValue(price.discount || [], catalogCode, locale)

  if (!discount || !discount.percentage) {
    return price
  }

  const currency = discount.currency ? discount.currency : listing?.currency
  const value = +(
    !listing?.value || !discount.percentage
      ? 0
      : listing.value * (1 - discount.percentage / 100)
  ).toFixed(2)

  return {
    ...price,
    discount: setCatalogValue<ProductDiscount>(
      price.discount || [],
      { ...discount, currency, value },
      catalogCode,
      locale
    ),
  }
}

/**
 * Remove empty prices
 * @param price - The product price
 * @returns the product price or undefined if not valid
 */
export function removeEmptyPrices(
  price?: ProductPrice
): ProductPrice | undefined {
  if (!price) {
    return undefined
  }

  const newPrices = omitBy<ProductPrice>(
    {
      purchase: price.purchase
        ? removeEmptyScope<Price>(price.purchase)
        : undefined,
      listing: price.listing
        ? removeEmptyScope<Price>(price.listing)
        : undefined,
      discount: price.discount
        ? removeEmptyScope<Price>(price.discount)
        : undefined,
    },
    isNil
  )

  return isEmpty(newPrices) ? undefined : newPrices
}

/**
 * Update product prices
 * @param product - The product
 * @param price - The product price to update
 * @param scope - The scope to use
 * @returns the product updated
 */
export function updateProductPrices(
  product: Product,
  price: ProductPrice,
  scope: ProductScope
): Product {
  return {
    ...product,
    price: updatePrices(price, scope.catalogCode, scope.locale),
  }
}

/**
 * Get the catalog product price value
 * @param prices - The product prices
 * @param catalogCode - The catalog code
 * @param locale - The locale code
 * @returns the price value or undefined
 */
export function getCatalogPriceValue(
  prices: CatalogLocales<Price>[],
  catalogCode: string,
  locale: string
): number | undefined {
  const price = getCatalogValue(prices, catalogCode, locale)
  return price?.value
}
