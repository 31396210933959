import { Directive, Input, OnDestroy, Optional } from '@angular/core'
import {
  AsyncValidator,
  ValidationErrors,
  UntypedFormControl,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms'
import { Observable, of } from 'rxjs'
import {
  map,
  retry,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  filter,
  first,
} from 'rxjs/operators'
import { ProductsService } from '../modules/products'
import { SuppliersService } from '../modules/suppliers'
import { UsersService } from '../modules/users'
import { AttributesService } from '../modules/attributes'

/**
 * @deprecated This directive should be splitted into multiple specialized directives. Eg. UserValidator, ProductValidator etc.
 */
@Directive({
  selector: '[opValidateUnique]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: UniqueValidator, multi: true },
  ],
})
export class UniqueValidator implements AsyncValidator, OnDestroy {
  @Input('opValidateUnique') newElement!: boolean
  /* eslint-disable */
  @Input('validateField') field?: string
  @Input('validateElement') elementType?:
    | 'product'
    | 'supplier'
    | 'user'
    | 'attribute'
  /* eslint-enable */

  private initialValue?: string

  private userObservable$ = (
    field: string,
    word: string,
  ): Observable<boolean> =>
    this.usersService
      ?.check$(field as 'username', word)
      .pipe(map((res) => res.exists)) || of(false)
  private productObservable$ = (
    field: string,
    word: string,
  ): Observable<boolean> =>
    this.prodService
      ?.list$({ [field]: word })
      .pipe(map((res) => res.length > 0)) || of(false)
  private supplierObservable$ = (
    field: string,
    word: string,
  ): Observable<boolean> =>
    this.supService
      ?.list$({ [field]: word })
      .pipe(map((res) => res.length > 0)) || of(false)
  private attributeObservable$ = (
    field: string,
    word: string,
  ): Observable<boolean> =>
    this.attributeService
      ?.list$({ [field]: word })
      .pipe(map((res) => res.length > 0)) || of(false)

  constructor(
    @Optional() private prodService?: ProductsService,
    @Optional() private supService?: SuppliersService,
    @Optional() private usersService?: UsersService,
    @Optional() private attributeService?: AttributesService,
  ) {}

  // Lifecycle methods

  ngOnDestroy() {
    this.initialValue = undefined
  }

  // Directive methods

  validate(control: UntypedFormControl): Observable<ValidationErrors | null> {
    if (!this.newElement && !this.initialValue && !this.field) {
      this.initialValue = control.value
    }

    let observable$: (field: string, word: string) => Observable<boolean>
    switch (this.elementType) {
      case 'attribute':
        observable$ = this.attributeObservable$
        break
      case 'product':
        observable$ = this.productObservable$
        break
      case 'supplier':
        observable$ = this.supplierObservable$
        break
      case 'user':
        observable$ = this.userObservable$
        break
      default:
        return of(null)
    }

    return control.valueChanges.pipe(
      filter((value) => value && value.length >= 3),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((word) =>
        this.field ? observable$(this.field, word) : of(false),
      ),
      retry(2),
      map((notUnique) =>
        control.dirty && notUnique ? { notUnique: true } : null,
      ),
      first(),
    )
  }
}
