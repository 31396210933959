import { Pipe, PipeTransform } from '@angular/core'
import { ShippingAddress, BillingAddress } from '../models/address.model'

@Pipe({
  name: 'address',
  pure: false,
})
export class AddressPipe implements PipeTransform {
  transform(
    address: ShippingAddress & BillingAddress,
    configuration?: {
      includeName?: boolean
      includeEmail?: boolean
      flat?: boolean
    }
  ): any {
    const addressStack = []

    if (configuration?.includeName && address.name) {
      addressStack.push(address.name)
    }

    if (address.businessName) {
      addressStack[0]
        ? (addressStack[0] += ' - ' + address.businessName)
        : addressStack.push(address.businessName)
    }

    if (address.address1) {
      addressStack.push(address.address1)
    }

    if (address.address2) {
      addressStack.push(address.address2)
    }

    // City address line
    const cityRow = []
    let addressRow = ''

    if (address.postalCode) {
      cityRow.push(address.postalCode)
    }
    if (address.city) {
      cityRow.push(address.city)
    }
    if (cityRow.length > 0) {
      addressRow = cityRow.join(' - ')
    }
    if (address.province && address.province !== '') {
      addressRow += ' (' + address.province + ')'
    }
    if (addressRow !== '') {
      addressStack.push(addressRow)
    }

    if (address.countryCode) {
      addressStack.push(address.countryCode)
    }

    if (address.phone) {
      addressStack.push(
        `${configuration?.flat ? 'Tel.' : '<strong>&#9743;</strong>'} ${
          address.phone
        }`
      )
    }

    if (configuration?.includeEmail && address.email) {
      addressStack.push(
        `${configuration?.flat ? 'Email' : '<strong>&#64;</strong>'} ${
          address.email
        }`
      )
    }

    if (address.fiscalCode) {
      addressStack.push(`<strong>C.F.</strong> ${address.fiscalCode}`)
    }

    if (address.vat) {
      addressStack.push(`<strong>P.I.</strong> ${address.vat}`)
    }

    if (address.sdiCode) {
      addressStack.push(`<strong>SDI</strong> ${address.sdiCode}`)
    }

    if (address.pec) {
      addressStack.push(`<strong>PEC</strong> ${address.pec}`)
    }

    return configuration?.flat
      ? addressStack.join(' - ')
      : addressStack.join('<br />')
  }
}
