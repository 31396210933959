import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import {
  Reason,
  ReasonSearchParams,
  ReasonType,
  ReasonUsage,
} from './reason.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'
import { reasonParamsByUsage } from './reason.lib'

const MODEL = 'reasons'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ReasonsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new reason
   * @param reason - The reason to create
   * @returns The observable<Reason> to create the reason
   */
  create$(reason: Reason): Observable<Reason> {
    return this._create$<Reason>(reason)
  }

  /**
   * Read a reason by ID
   * @param reasonId - The reason ID
   * @returns The observable<Reason> for read the reason
   */
  read$(reasonId: string): Observable<Reason> {
    return this._read$<Reason>(reasonId)
  }

  /**
   * Update a reason by ID
   * @param reasonId - The reason ID
   * @param reason - The reason body to update
   * @returns The observable<Reason> for update the reason
   */
  update$(reasonId: string, reason: Reason): Observable<Reason> {
    return this._update$<Reason>(reasonId, reason)
  }

  /**
   * Create or update a reason by ID
   * @param reasonId - The reason ID
   * @param reason - The reason body to update
   * @returns The observable<Reason> for update the reason
   */
  upsert$(reason: Reason): Observable<Reason> {
    return this._upsert$<Reason>(reason, reason._id)
  }

  /**
   * Delete a reason by ID
   * @param reasonId - The reason ID
   * @returns The observable<Reason> for delete the reason
   */
  delete$(reasonId: string): Observable<Reason> {
    return this._delete$<Reason>(reasonId)
  }

  /**
   * Search reasons by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Reason>> for search reasons
   */
  search$(
    params?: ReasonSearchParams,
    returnAll = false,
  ): Observable<Page<Reason>> {
    return this._search$<Reason>(params, returnAll)
  }

  /**
   * List reasons by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Reason[]> for list reasons
   */
  list$(params?: ReasonSearchParams, returnAll = false): Observable<Reason[]> {
    return this._list$<Reason>(params, returnAll)
  }

  /**
   * Get default reason by usage and type
   * @param usage - the reason usage
   * @param type - the reason type
   */
  getDefaultByUsage$(
    usage: ReasonUsage,
    type?: ReasonType,
  ): Observable<Reason | undefined> {
    const params = reasonParamsByUsage(usage, type)
    return this.list$(params).pipe(
      map((reasons) => reasons.find((r) => r.isDefault)),
    )
  }

  /**
   * Generate reasons store
   * @param reasonIds - the reason IDs to load
   * @param reasons - the reasons already loaded
   * @returns the Observable<Payment[]> as store
   */
  store$(reasonIds: string[], reasons: Reason[]): Observable<Reason[]> {
    reasonIds = uniq(reasonIds)
    reasonIds = difference(
      reasonIds,
      reasons.map((u) => u._id),
    )

    if (reasonIds.length === 0) {
      return of(reasons)
    }

    return this.list$({ _id: reasonIds }).pipe(
      map((rsns) => [...reasons, ...rsns]),
    )
  }
}
