import { File } from '../../files/file.model'
import { Product } from '../product.model'

export function setProductFiles(product: Product, files?: File[]): Product {
  return {
    ...product,
    files: [
      ...(files || []).map((file) => {
        const { _id, path } = file
        return { _id, path }
      }),
    ],
  }
}
