import { DialogParams } from '../../../models/notification.model'
import { Tenant } from '../../tenants/tenant.model'
import { Product, ProductNotification } from '../product.model'
import { isBatchManageMentValid } from './batch-management.lib'
import { checkProductKitsValid } from './kit.lib'
import { checkMandatoryFields } from './mandatory-fields.lib'
import { checkProductVariantAttributes } from './variants-management.lib'

/**
 * Check a product notification data
 * @returns the product notification
 */
export function checkProductNotification(
  product: Product,
  tenant: Tenant,
): ProductNotification | undefined {
  // Strict errors
  if (!checkProductKitsValid(product)) {
    return {
      kind: 'MISSING_KIT_PRODUCTS',
      level: 'error',
    }
  } else if (!checkProductVariantAttributes(product)) {
    return {
      kind: 'MISSING_VARIANT_ATTRIBUTE',
      level: 'error',
    }
  } else if (!isBatchManageMentValid(product)) {
    return {
      kind: 'MISSING_VARIANT_ATTRIBUTE',
      level: 'error',
    }
  }

  // Quiet errors
  const fieldCheck = checkMandatoryFields(product, tenant)
  const strictCheck = fieldCheck.missings?.some((b) => !!b.strict)

  if (!fieldCheck.valid) {
    return {
      kind: strictCheck ? 'MISSING_MANDATORY_FIELDS' : 'MISSING_BARCODES',
      level: strictCheck ? 'error' : 'warning',
      data: { barcodes: fieldCheck.missings },
    }
  }

  return undefined
}

/**
 * Parse a product notification into notification data
 * @param notification - the product notification data
 * @returns the notification data
 */
export function parseProductNotification(
  notification: ProductNotification,
): DialogParams {
  switch (notification.kind) {
    case 'PRODUCT_SAVED':
      return {
        message: 'Prodotto salvato con successo',
        color: 'success',
      }
    case 'PRODUCT_INCOMPLETE':
      return {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
    case 'MISSING_BARCODES':
    case 'MISSING_MANDATORY_FIELDS': {
      const missingBarcodes = notification.data?.barcodes
        ?.map((b) => b.type)
        .join(', ')
      return {
        message: missingBarcodes
          ? `Barcode di tipologia ${missingBarcodes} mancanti.`
          : `Barcode mancanti`,
        color:
          notification.kind === 'MISSING_MANDATORY_FIELDS'
            ? 'danger'
            : 'warning',
      }
    }
    case 'LABEL_PRINTED':
      return {
        message: 'Etichetta prodotto stampata con successo',
        color: 'success',
      }
    case 'MISSING_KIT_PRODUCTS':
      return {
        message: 'Un prodotto di tipo KIT deve contenere almeno un prodotto',
        title: 'Attenzione',
        color: 'danger',
      }
    case 'MISSING_VARIANT_ATTRIBUTE':
      return {
        message:
          "E' necessario selezionare almeno un attributo nella gestione varianti",
        title: 'Attenzione',
        color: 'danger',
      }
    case 'BATCH_MANAGEMENT_NOT_VALID':
      return {
        message:
          "E' necessario selezionare almeno un attributo nella gestione varianti",
        title: 'Attenzione',
        color: 'danger',
      }
  }
}
