import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { Fs } from './fs.model'

const MODEL = 'fs'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class FsService {
  // Fs routes
  private apiUrl: string

  constructor(
    @Inject(SDK_CONFIGURATION) private config: SDKConfiguration,
    private http: HttpClient,
  ) {
    this.apiUrl = `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`
  }

  /**
   * Search static files by path
   * @param path - The path to search
   * @returns The Observable<Page<Fs>> for search files
   */
  search$(path = '/', limit?: string, offset?: string): Observable<Page<Fs>> {
    return this.http.get<any>(`${this.apiUrl}${path}`, {
      params: { ...(limit ? { limit } : {}), ...(offset ? { offset } : {}) },
    })
  }

  /**
   * Return static url
   * @returns The static url of files
   */
  get staticUrl(): string {
    return `${this.config.apiUrl}/${SDK_SETTINGS.staticPath}`
  }

  /**
   * Return the complete file static url
   * @param tenantId - The file tenant ID
   * @param path - The file path
   * @returns The url of the file
   */
  getFileUrl(tenantId: string, path: string): string {
    return `${this.staticUrl}/${tenantId}${path}`
  }
}
