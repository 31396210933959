<carousel [isAnimated]="true">
  <slide>
    <div class="carousel-caption d-none d-md-block">
      <h4>Una applicazione, infinite vetrine. Apriti al mondo con OrderPod.</h4>
    </div>
    <img src="assets/img/carousel/shop.png" alt="first slide" />
  </slide>
  <slide>
    <div class="carousel-caption d-none d-md-block">
      <h4>Negozio o magazzino che sia, efficienza è la parola d'ordine.</h4>
    </div>
    <img src="assets/img/carousel/warehouse.png" alt="second slide" />
  </slide>
  <slide>
    <div class="carousel-caption d-none d-md-block">
      <h4>I tuoi prodotti, direttamente online</h4>
    </div>
    <img src="assets/img/carousel/shop-online.png" alt="third slide" />
  </slide>
</carousel>
