import {
  ChangeDetectorRef,
  Directive,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { Subject, takeUntil } from 'rxjs'

import { AUTH_MANAGER, AuthManager } from '../auth.model'
import {
  PermissionsCheckMode,
  PermissionsLevel,
  PermissionsScope,
} from '../../policies'

@Directive({
  selector: '[opAuth]',
})
export class AuthDirective implements OnInit, OnDestroy {
  private scopes?: PermissionsScope[] | PermissionsScope
  private mode: PermissionsCheckMode = 'ONE'
  private level?: PermissionsLevel = 'READ_ONLY'

  // Observables
  private destroy$ = new Subject<boolean>()

  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
    private templateRef: TemplateRef<any>,
    private changes: ChangeDetectorRef,
    private viewContainer: ViewContainerRef,
  ) {}

  // Lifecycle

  ngOnInit() {
    this.checkElement()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  // Setters

  @Input() set opAuthMode(mode: PermissionsCheckMode | undefined) {
    if (mode) {
      this.mode = mode
    }
  }

  @Input() set opAuthLevel(level: PermissionsLevel | undefined) {
    if (level) {
      this.level = level
    }
  }

  @Input() set opAuth(
    scopes: PermissionsScope[] | PermissionsScope | undefined,
  ) {
    this.scopes = scopes
  }

  // Useful

  checkElement() {
    if (!this.scopes) {
      this.toggleElement(true)
      return
    }

    const selector$ = Array.isArray(this.scopes)
      ? this.authManager.isMultiAllowed$(this.scopes, this.level, this.mode)
      : this.authManager.isAllowed$(this.scopes, this.level)

    selector$
      .pipe(takeUntil(this.destroy$))
      .subscribe((canBeShown) => this.toggleElement(canBeShown))
  }

  toggleElement(canBeShown: boolean) {
    this.viewContainer.clear()

    if (canBeShown) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }

    this.changes.markForCheck()
  }
}
