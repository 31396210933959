import { defaultsDeep, uniq } from 'lodash'
import { OrderAdditionalPackage, OrderPackage } from '../order.model'
import { Product, ProductType, ProductWrapper } from '../../products'

/**
 * Order package initial state
 */
const ORDER_PACKAGE_INITIAL_STATE: Partial<OrderPackage> = {
  weight: 0,
  volume: 0,
  height: 0,
  length: 0,
  width: 0,
}

/**
 * Order additional package initial state
 */
const ORDER_ADDITIONAL_PACKAGE_INITIAL_STATE: Partial<OrderAdditionalPackage> =
  {
    quantity: 1,
    weight: 0,
    volume: 0,
    height: 0,
    length: 0,
    width: 0,
  }

/**
 * Initialize an order package
 * @param orderPackage - the partial order package
 * @returns the order package
 */
export function initOrderPackage(
  orderPackage: Partial<OrderPackage> = {}
): OrderPackage {
  return defaultsDeep(orderPackage, ORDER_PACKAGE_INITIAL_STATE)
}

/**
 * Initialize an order additional package
 * @param orderPackage - the partial order package
 * @returns the order additional package
 */
export function initOrderAdditionalPackage(
  orderPackage: Partial<OrderAdditionalPackage> = {}
): OrderAdditionalPackage {
  return defaultsDeep(orderPackage, ORDER_ADDITIONAL_PACKAGE_INITIAL_STATE)
}

/**
 * Check if a package is empty
 * @param pack - the order package to check
 * @returns the boolean that indicates if a package is empty
 */
export function isOrderPackageEmpty(pack: OrderPackage): boolean {
  return pack.weight === pack.packWeight
}

/**
 * Add a package to an order
 * @param packages - the order packages
 * @param pack - the package to add
 * @returns the packages updated
 */
export function addOrderPackage(
  packages: OrderPackage[],
  pack?: Partial<OrderPackage>
): OrderPackage[] {
  return [...packages, initOrderPackage(pack)]
}

/**
 * Remove a package from an order
 * @param packages - the packages
 * @param packIndex - the package index
 * @returns the packages updated
 */
export function removeOrderPackage(
  packages: OrderPackage[],
  packIndex?: number
): OrderPackage[] {
  const indexToRemove =
    packIndex !== undefined ? packIndex : packages.length - 1
  return packages.filter((p, i) => i !== indexToRemove)
}

/**
 * Add an additional package to an order
 * @param additionalPackages - the additional packages
 * @param pack - the package to add
 * @returns the additional packages updated
 */
export function addOrderAdditionalPackage(
  additionalPackages: OrderAdditionalPackage[],
  pack?: Partial<OrderAdditionalPackage>
): OrderAdditionalPackage[] {
  return [...additionalPackages, initOrderAdditionalPackage(pack)]
}

/**
 * Remove an additional package from an order
 * @param additionalPackages - the additional packages
 * @param packIndex - the package index
 * @returns the additional packages updated
 */
export function removeOrderAdditionalPackage(
  additionalPackages: OrderAdditionalPackage[],
  packIndex?: number
): OrderAdditionalPackage[] {
  const indexToRemove =
    packIndex !== undefined ? packIndex : additionalPackages.length - 1
  return additionalPackages.filter((p, i) => i !== indexToRemove)
}

/**
 * Update order packages volume
 * @param packages - the packages to update
 * @returns the packages updated
 */
export function updateOrderPackages<
  T extends OrderPackage | OrderAdditionalPackage
>(packages: T[]): T[] {
  return packages.filter((p) => !!p).map((p) => updateOrderPackageVolume<T>(p))
}

/**
 * Update order package volume
 * @param pack - the package to update
 * @returns the package updated
 */
export function updateOrderPackageVolume<
  T extends OrderPackage | OrderAdditionalPackage
>(pack: T): T {
  let volume = (pack.height / 100) * (pack.length / 100) * (pack.width / 100)
  volume = +volume.toFixed(6)
  return {
    ...pack,
    volume,
  }
}

/**
 * Get order packages product IDs used for packages
 * @param packages - the packages
 * @param additionalPackages - the additional packages
 * @returns the list of the products IDs
 */
export function getOrderPackagesProductIds(
  packages?: OrderPackage[],
  additionalPackages?: OrderAdditionalPackage[]
): string[] {
  const packagesProductIds = (packages || [])
    .filter((p) => p.packageId)
    .map((p) => p.packageId!)
  const additionalProductIds = (additionalPackages || [])
    .filter((p) => p.packageId)
    .map((p) => p.packageId!)

  return uniq([...packagesProductIds, ...additionalProductIds])
}

/**
 * Get order packages total volume
 * @param packages - the order package
 * @returns the total volume in cubic meters
 */
export function getOrderPackagesVolume(packages: OrderPackage[]): number {
  return packages ? packages.reduce((total, pack) => total + pack.volume, 0) : 0
}

/**
 * Get order packages total weight
 * @param packages - the order package
 * @returns the total weight in kilograms
 */
export function getOrderPackagesWeight(packages: OrderPackage[]): number {
  return packages ? packages.reduce((total, pack) => total + pack.weight, 0) : 0
}

/**
 * Get order packages total net weight
 * @param packages - the order package
 * @returns the total net weight in kilograms
 */
export function getOrderPackagesNetWeight(packages: OrderPackage[]): number {
  return packages
    ? packages.reduce(
        (total, pack) => total + (pack.weight - (pack.packWeight || 0)),
        0
      )
    : 0
}

/**
 * Add product package to order packages
 * @param packages - the actual packages
 * @param product - the product package
 * @param weightToPack - the weight of the products to pack
 * @returns the order packages updated
 */
export function addOrderProductPackage(
  packages: OrderPackage[],
  product: Product,
  weightToPack = 0
): OrderPackage[] {
  if (product.productType !== ProductType.package) {
    return packages
  }

  return [
    ...packages,
    initOrderPackage({
      packageId: product._id,
      weight: (product.grossWeight || 0) + weightToPack,
      packWeight: product.grossWeight,
      volume: product.volume?.total,
      height: product.volume?.h,
      length: product.volume?.l,
      width: product.volume?.w,
    }),
  ]
}

/**
 * Add product wrapper to order packages
 * @param packages - the actual packages
 * @param productWrapper - the product package
 * @param weightToPack - the weight of the products to pack
 * @returns the order packages updated
 */
export function addOrderWrapperPackage(
  packages: OrderPackage[],
  productWrapper: ProductWrapper,
  weightToPack = 0
): OrderPackage[] {
  if (!productWrapper.isSelfShipping) {
    return packages
  }

  return [
    ...packages,
    initOrderPackage({
      weight: (productWrapper.dimensions?.weight || 0) + weightToPack,
      packWeight: productWrapper.dimensions?.weight,
      volume: productWrapper.dimensions?.volume,
      height: productWrapper.dimensions?.height,
      length: productWrapper.dimensions?.length,
      width: productWrapper.dimensions?.width,
    }),
  ]
}

/**
 * Add product package to order additional packages
 * @param additionalPackages - the additional packages
 * @param product - the product pack
 * @param weightToPack - the weight to pack
 * @param quantity - the quantity of the packages
 * @returns the order additional packages updated
 */
export function addOrderProductAdditionalPackage(
  additionalPackages: OrderAdditionalPackage[],
  product: Product,
  weightToPack = 0,
  quantity = 1
): OrderAdditionalPackage[] {
  if (product.productType !== ProductType.package) {
    return additionalPackages
  }

  return [
    ...additionalPackages,
    initOrderAdditionalPackage({
      packageId: product._id,
      weight: (product.grossWeight || 0) + weightToPack,
      quantity,
      volume: product.volume?.total,
      height: product.volume?.h,
      length: product.volume?.l,
      width: product.volume?.w,
    }),
  ]
}

/**
 * Add order wrapper to additional packages
 * @param additionalPackages - the current additional packages
 * @param productWrapper - the product wrapper
 * @param weightToPack - the weight to pack
 * @param quantity - the quantity
 * @returns the order additional packages updated
 */
export function addOrderWrapperAdditionalPackage(
  additionalPackages: OrderAdditionalPackage[],
  productWrapper: ProductWrapper,
  weightToPack = 0,
  quantity = 1
): OrderAdditionalPackage[] {
  if (!productWrapper.isSelfShipping) {
    return additionalPackages
  }

  return [
    ...additionalPackages,
    initOrderAdditionalPackage({
      weight: (productWrapper.dimensions?.weight || 0) + weightToPack,
      quantity,
      volume: productWrapper.dimensions?.volume,
      height: productWrapper.dimensions?.height,
      length: productWrapper.dimensions?.length,
      width: productWrapper.dimensions?.width,
    }),
  ]
}

/**
 * Reset all packages weight
 * @param packages - the order packages
 * @returns the packages updated
 */
export function resetOrderPackagesWeight(
  packages: OrderPackage[]
): OrderPackage[] {
  return [
    ...packages.map((p) => ({
      ...p,
      weight: 0,
    })),
  ]
}
