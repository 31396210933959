import { Tenant, TenantCatalogBarcode } from '../../tenants/tenant.model'
import { Product, ProductBarcodeValidation } from '../product.model'

export function checkMandatoryFields(
  product: Product,
  tenant: Tenant
): ProductBarcodeValidation {
  const mandatoryFields = tenant.catalog?.mandatoryProductProperties
  if (!mandatoryFields) {
    return { valid: true }
  }

  // Check barcodes
  let missings: TenantCatalogBarcode[] = []
  if (mandatoryFields.barcodes?.length) {
    missings = mandatoryFields.barcodes.filter(
      (barcode) => !product.barcodes?.find((b) => b.type === barcode.type)
    )
  }

  return {
    valid: !missings.length,
    missings,
  }
}
