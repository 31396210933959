import { LoadOptions } from 'devextreme/data'
import {
  QueryStringParams,
  QueryStringFilter,
  QueryStringSort,
} from '@evologi/shared/data-access-api'

type FilterParser<T, F> = (params: T, filter: QueryStringFilter<F>) => T
type SortParser<T, S> = (params: T, sort: QueryStringSort<S>) => T
type TypedParams<T> = QueryStringParams<T> & T

export function parseLoadOptions<T, F = any, S = any>(
  loadOptions: LoadOptions,
  filterParser?: FilterParser<T, F>,
  sortParser?: SortParser<T, S>,
): T {
  let params = parseCommonLoadOption<T>(loadOptions)

  if (loadOptions.filter && filterParser) {
    for (const filter of destructFilters<F>(loadOptions.filter)) {
      params = filterParser(params, filter) as TypedParams<T>
    }
  }

  if (loadOptions.sort && sortParser) {
    const sort = (loadOptions.sort as any)[0]
    params = sortParser(params, {
      field: sort['selector'],
      order: sort['desc'] ? 'desc' : 'asc',
    }) as TypedParams<T>
  }

  return params
}

export function parseCommonLoadOption<T = any>(
  loadOptions: LoadOptions,
): TypedParams<T> {
  // Paging params
  const params: QueryStringParams = {}

  if (loadOptions.skip) {
    params.offset = loadOptions.skip
  }

  if (loadOptions.take) {
    params.limit = loadOptions.take
  }

  return params as TypedParams<T>
}

export function destructFilters<T>(
  loadFilters: any[],
  filters: QueryStringFilter<T>[] = [],
): QueryStringFilter<T>[] {
  const [field, operator, value] = loadFilters

  if (value === '') {
    return filters
  }

  // Check single or multi filters
  if (['and', 'or'].includes(operator) || operator instanceof Array) {
    for (const subFilter of loadFilters.filter((f) => f instanceof Array)) {
      filters = destructFilters(subFilter, filters)
    }
  } else {
    filters =
      filters.some(
        (f) => f.field === field && f.operator === operator && f.value !== null,
      ) && value !== null
        ? [
            ...filters.map((f) =>
              f.field === field
                ? { ...f, value: mergeFilterValues(f.value, value) }
                : f,
            ),
          ]
        : [
            ...filters,
            {
              field,
              operator,
              value,
            },
          ]
  }

  return filters
}

function mergeFilterValues<T = any>(filterValues: T[] | T, value: T): T[] {
  return Array.isArray(filterValues)
    ? [...filterValues, value]
    : [filterValues, value]
}
