import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import {
  Brand,
  BrandPageData,
  BrandProductsCount,
  BrandSearchField,
  BrandSearchParams,
  BrandSortField,
} from '../brand.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { Page } from '../../../models/util.model'

/**
 * The brand initial state
 */
const BRAND_INITIAL_STATE: Partial<Brand> = {}

/**
 * Initialize a partial brand
 * @param brand - the partial brand
 * @returns the brand updated
 */
export function initBrand(brand: Partial<Brand> = {}): Brand {
  return defaultsDeep(cloneDeep(brand), BRAND_INITIAL_STATE)
}

/**
 * Get all users that managed a brand
 * @param brand - the brand
 * @returns the user IDs of the brand
 */
export function getBrandUserIds(brand: Brand): string[] {
  const userIds: string[] = []

  // Creation & update
  if (brand.createdBy) {
    userIds.push(brand.createdBy)
  }

  if (brand.updatedBy) {
    userIds.push(brand.updatedBy)
  }

  return uniq(userIds)
}

/**
 * Parse sort params
 * @param params - the search params
 * @param sort - the sort param to parse
 * @returns the brand search params updated
 */
export function brandSortParams(
  params: BrandSearchParams,
  sort: QueryStringSort<BrandSortField>,
): BrandSearchParams {
  const searchParams: BrandSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the brand search params updated
 */
export function brandFilterParams(
  params: BrandSearchParams,
  filter: QueryStringFilter<BrandSearchField>,
): BrandSearchParams {
  const searchParams: BrandSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'externalId':
      if (filter.operator === '=') {
        searchParams.externalId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['externalId:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['externalId:ct'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse a brand page and product counts to a single page data
 * @param brandPage - the brand page
 * @param productsCounts - the product counts
 * @returns the brands page data
 */
export function parseBrandsPage(
  brandPage: Page<Brand>,
  productsCounts?: BrandProductsCount[],
): Page<BrandPageData> {
  const data = brandPage.data.map((b) => ({
    ...b,
    productsCount: productsCounts?.find((c) => c._id === b._id)?.count,
  }))
  return {
    totalCount: brandPage.totalCount,
    data,
  }
}
