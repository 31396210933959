<!-- Selection -->
<dx-select-box
  class="form-control {{ class }} {{ selectClasses }}"
  [ngClass]="{
    'is-invalid': required && selectModel.invalid && selectModel.touched
  }"
  [dataSource]="dataSource | customStorePagination"
  [valueExpr]="keyField"
  [searchEnabled]="true"
  [searchExpr]="searchField"
  [displayExpr]="displayParser || displayField"
  [id]="key"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit(checkValue($event))"
  name="{{ key }}"
  #selectModel="ngModel"
  [placeholder]="placeholder || ''"
  [acceptCustomValue]="false"
  [showClearButton]="clearButton"
  [stylingMode]="stylingMode"
  [required]="required"
  [disabled]="disabled"
  itemTemplate="customItem"
>
  <!-- Custom item template -->
  <ng-container *dxTemplate="let item of 'customItem'">
    <ng-container
      *ngIf="itemTemplate; else simpleItem"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
    <ng-template #simpleItem>
      {{ displayField ? (item | get: displayField) : item }}
    </ng-template>
  </ng-container>
</dx-select-box>

<!-- Errors -->
<div
  *ngIf="selectModel.errors && selectModel.errors['required']"
  class="invalid-feedback"
>
  {{ requiredError }}
</div>
