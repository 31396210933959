import { Pipe, PipeTransform } from '@angular/core'
import { parseViewField } from '../libs/view.lib'
import { ViewTarget } from '../view.model'
import { Attribute } from '../../attributes'

@Pipe({
  name: 'viewField',
})
export class ViewFieldPipe implements PipeTransform {
  transform(
    field: string,
    target?: ViewTarget,
    attributes?: Attribute[]
  ): string {
    if (!target) {
      return ''
    }

    return parseViewField(field, target, attributes) || ''
  }
}
