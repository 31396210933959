import { Attribute, AttributeGroup } from '@evologi/shared/data-access-api'
import { createAction, props } from '@ngrx/store'

export enum AttributesAction {
  loadGroups = '[ATTRIBUTES] load groups',
  setGroups = '[ATTRIBUTES] set groups',
  loadGroupAttributes = '[ATTRIBUTES] load group attributes',
  setGroupAttributes = '[ATTRIBUTES] set group attributes',
  loadAttributes = '[ATTRIBUTES] load attributes',
  loadAttributesByCodes = '[ATTRIBUTES] load attributes by codes',
  setAttributes = '[ATTRIBUTES] set attributes',
  setAttribute = '[ATTRIBUTES] set attribute',
  unsetAttribute = '[ATTRIBUTES] unset attribute',
  upsertAttribute = '[ATTRIBUTES] upsert attribute',
  setGroup = '[ATTRIBUTES] set group',
  unsetGroup = '[ATTRIBUTES] unset group',
  upsertGroup = '[ATTRIBUTES] upsert group',
  destroy = '[ATTRIBUTES] destroy',
}

export const loadGroups = createAction(
  AttributesAction.loadGroups,
  props<{ tenantId: string; groupIds?: string[] }>(),
)

export const setGroups = createAction(
  AttributesAction.setGroups,
  props<{ tenantId: string; groups: AttributeGroup[]; totalCount?: number }>(),
)

export const loadGroupAttributes = createAction(
  AttributesAction.loadGroupAttributes,
  props<{ tenantId: string; groupId: string }>(),
)

export const setGroupAttributes = createAction(
  AttributesAction.setGroupAttributes,
  props<{
    tenantId: string
    group: AttributeGroup
    attributes: Attribute[]
  }>(),
)

export const loadAttributes = createAction(
  AttributesAction.loadAttributes,
  props<{ tenantId: string; attributeIds?: string[] }>(),
)

export const loadAttributesByCodes = createAction(
  AttributesAction.loadAttributesByCodes,
  props<{ tenantId: string; attributeCodes: string[] }>(),
)

export const setAttributes = createAction(
  AttributesAction.setAttributes,
  props<{ tenantId: string; attributes: Attribute[]; totalCount?: number }>(),
)

export const upsertAttribute = createAction(
  AttributesAction.upsertAttribute,
  props<{ attribute: Attribute }>(),
)

export const setAttribute = createAction(
  AttributesAction.setAttribute,
  props<{ attribute: Attribute }>(),
)

export const unsetAttribute = createAction(
  AttributesAction.unsetAttribute,
  props<{ attribute: Attribute }>(),
)

export const upsertGroup = createAction(
  AttributesAction.upsertGroup,
  props<{ group: AttributeGroup }>(),
)

export const setGroup = createAction(
  AttributesAction.setGroup,
  props<{ group: AttributeGroup }>(),
)

export const unsetGroup = createAction(
  AttributesAction.unsetGroup,
  props<{ group: AttributeGroup }>(),
)

export const destroyAttributes = createAction(AttributesAction.destroy)
