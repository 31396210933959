import { createReducer, on } from '@ngrx/store'
import { Currency } from '@evologi/shared/data-access-api'

import * as CurrenciesActions from './currencies.actions'
import { CurrenciesState, CurrenciesTenant } from './currencies.state'
import { parseItems } from '../../libs/state.lib'

const initialState: CurrenciesState = {}

export const currenciesReducers = createReducer(
  initialState,

  // Lifecycle

  on(CurrenciesActions.destroyCurrencies, (): CurrenciesState => initialState),

  // Crud

  on(
    CurrenciesActions.setCurrencies,
    (
      state: CurrenciesState,
      action: { tenantId: string; currencies: Currency[] },
    ): CurrenciesState => {
      const tenantCurrencies: CurrenciesTenant = state[action.tenantId] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantCurrencies,
          currencies: parseItems<Currency>(action.currencies),
        },
      }
    },
  ),
)
