import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import {
  ChannelIntegrationStatus,
  ChannelKind,
  ChannelQaplaOrigin,
  ChannelShopifyNotification,
  ChannelTab,
} from './channel.model'

export const CHANNEL_FRAGMENT = 'channel'
export const CHANNEL_SCOPE: PermissionsScope = 'channels'

type ChannelAvailable = SelectionData<ChannelKind> & {
  payments?: { code: string; name: string }[]
}

export const CHANNEL_KINDS: ChannelAvailable[] = [
  {
    value: ChannelKind.generic,
    label: 'Generico',
    icon: 'fad fa-window',
  },
  {
    value: ChannelKind.shopify,
    label: 'Shopify',
    logo: 'assets/img/channels/shopify.png',
  },
  {
    value: ChannelKind.storeden,
    label: 'Storeden',
    logo: 'assets/img/channels/storeden.png',
    payments: [
      {
        code: 'paypal',
        name: 'Paypal xclick',
      },
      {
        code: 'stripe',
        name: 'Stripe Carta di credito',
      },
      {
        code: 'COD',
        name: 'Contrassegno',
      },
      {
        code: 'transfer',
        name: 'Bonifico Bancario',
      },
      {
        code: 'gestpay',
        name: 'Banca Sella',
      },
      {
        code: 'nets',
        name: 'Nets Gateway',
      },
      {
        code: 'unicredit',
        name: 'Unicredit checkout',
      },
      {
        code: 'findomesticfinancing',
        name: 'Finanziamento con Findomestic',
      },
      {
        code: 'cartasi',
        name: 'Nexi xPay',
      },
      {
        code: 'amazonpay',
        name: 'Amazon Pay Checkout',
      },
      {
        code: 'paypalcheckout',
        name: 'Paypal commerce platform',
      },
      {
        code: 'manual',
        name: 'Contanti',
      },
    ],
  },
]

export const CHANNEL_SHOPIFY_NOTIFICATIONS: SelectionData<ChannelShopifyNotification>[] =
  [
    {
      label: 'Sempre',
      value: 'ALWAYS',
    },
    {
      label: 'Mai',
      value: 'NEVER',
    },
    {
      label: 'Solo ordini a clienti',
      value: 'CUSTOMER_ORDERS',
    },
    {
      label: 'Solo ordini a pickup-point',
      value: 'PICKUP_POINT_ORDERS',
    },
  ]

export const CHANNEL_QAPLA_ORIGINS = [
  {
    code: ChannelQaplaOrigin.amazon,
    name: 'amazon',
  },
  {
    code: ChannelQaplaOrigin.carrefour_es,
    name: 'carrefour_es',
  },
  {
    code: ChannelQaplaOrigin.cdiscount,
    name: 'cdiscount',
  },
  {
    code: ChannelQaplaOrigin.ebay,
    name: 'ebay',
  },
  {
    code: ChannelQaplaOrigin.ecwid,
    name: 'ecwid',
  },
  {
    code: ChannelQaplaOrigin.eprice,
    name: 'eprice',
  },
  {
    code: ChannelQaplaOrigin.ibs,
    name: 'ibs',
  },
  {
    code: ChannelQaplaOrigin.magento,
    name: 'magento',
  },
  {
    code: ChannelQaplaOrigin.magento2,
    name: 'magento2',
  },
  {
    code: ChannelQaplaOrigin.manomano_de,
    name: 'manomano_de',
  },
  {
    code: ChannelQaplaOrigin.manomano_es,
    name: 'manomano_es',
  },
  {
    code: ChannelQaplaOrigin.manomano_fr,
    name: 'manomano_fr',
  },
  {
    code: ChannelQaplaOrigin.manomano_gb,
    name: 'manomano_gb',
  },
  {
    code: ChannelQaplaOrigin.manomano_it,
    name: 'manomano_it',
  },
  {
    code: ChannelQaplaOrigin.prestashop,
    name: 'prestashop',
  },
  {
    code: ChannelQaplaOrigin.privalia_es,
    name: 'privalia_es',
  },
  {
    code: ChannelQaplaOrigin.privalia_it,
    name: 'privalia_it',
  },
  {
    code: ChannelQaplaOrigin.shopify,
    name: 'shopify',
  },
  {
    code: ChannelQaplaOrigin.spartoo_be,
    name: 'spartoo_be',
  },
  {
    code: ChannelQaplaOrigin.spartoo_cn,
    name: 'spartoo_cn',
  },
  {
    code: ChannelQaplaOrigin.spartoo_de,
    name: 'spartoo_de',
  },
  {
    code: ChannelQaplaOrigin.spartoo_es,
    name: 'spartoo_es',
  },
  {
    code: ChannelQaplaOrigin.spartoo_fr,
    name: 'spartoo_fr',
  },
  {
    code: ChannelQaplaOrigin.spartoo_gb,
    name: 'spartoo_gb',
  },
  {
    code: ChannelQaplaOrigin.spartoo_gr,
    name: 'spartoo_gr',
  },
  {
    code: ChannelQaplaOrigin.spartoo_it,
    name: 'spartoo_it',
  },
  {
    code: ChannelQaplaOrigin.spartoo_nl,
    name: 'spartoo_nl',
  },
  {
    code: ChannelQaplaOrigin.spartoo_pl,
    name: 'spartoo_pl',
  },
  {
    code: ChannelQaplaOrigin.spartoo_pt,
    name: 'spartoo_pt',
  },
  {
    code: ChannelQaplaOrigin.storeden,
    name: 'storeden',
  },
  {
    code: ChannelQaplaOrigin.vtex,
    name: 'vtex',
  },
  {
    code: ChannelQaplaOrigin.woocommerce,
    name: 'woocommerce',
  },
  {
    code: ChannelQaplaOrigin.worten_es,
    name: 'worten_es',
  },
  {
    code: ChannelQaplaOrigin.worten_pt,
    name: 'worten_pt',
  },
]

export const CHANNEL_STATUSES: EntityStatusDesc<ChannelIntegrationStatus>[] = [
  {
    code: 'CONNECTED',
    name: 'Connesso',
  },
  {
    code: 'CONNECTING',
    name: 'In connessione',
  },
  {
    code: 'DISCONNECTED',
    name: 'Disconnesso',
  },
  {
    code: 'ERROR',
    name: 'Errore',
  },
]

export const CHANNEL_TABS: SelectionData<ChannelTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'orders',
    label: 'Ordini',
  },
  {
    value: 'products',
    label: 'Prodotti',
  },
]
