import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core'
import { ClipboardService } from 'ngx-clipboard'
import { NotificationService } from '../services/notification.service'

@Directive({
  selector: '[opClipboard]',
})
export class ClipboardDirective implements OnInit {
  @Input('opClipboard') textToCopy?: string | number
  @Input('opClipboardPosition') position: 'inside' | 'outside' = 'inside'

  private copyButton?: ElementRef
  private hoverElement = false
  private hoverButton = false

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private clipboardService: ClipboardService,
    private notificationService: NotificationService
  ) {}

  // Lifecycle methods

  ngOnInit() {
    this.createButton()
  }

  // Clipboard methods

  createButton() {
    if (this.copyButton) {
      return
    }

    // Get parent style
    this.renderer.addClass(this.element.nativeElement, 'clipboard')
    this.renderer.addClass(this.element.nativeElement, this.position)

    // Set button
    this.copyButton = this.renderer.createElement('button')
    this.renderer.addClass(this.copyButton, 'btn')
    this.renderer.addClass(this.copyButton, 'btn-sm')
    this.renderer.addClass(this.copyButton, 'btn-link')
    this.renderer.addClass(this.copyButton, 'btn-clipboard')
    this.renderer.addClass(this.copyButton, this.position)

    // Icon
    const icon = this.renderer.createElement('i')
    this.renderer.addClass(icon, 'fas')
    this.renderer.addClass(icon, 'fa-copy')
    this.renderer.appendChild(this.copyButton, icon)
    this.renderer.setAttribute(this.copyButton, 'alt', 'Copia')
    this.renderer.setAttribute(this.copyButton, 'type', 'button')

    // Insert button
    this.renderer.appendChild(this.element.nativeElement, this.copyButton)

    this.initEvent()
  }

  removeButton() {
    if (this.hoverButton && this.hoverElement) {
      return
    }

    this.renderer.removeChild(
      this.element.nativeElement.parentNode,
      this.copyButton
    )
    this.copyButton = undefined
  }

  initEvent() {
    this.renderer.listen(this.copyButton, 'click', this.copy)
  }

  copy = (evt: any) => {
    this.textToCopy
      ? this.clipboardService.copyFromContent(String(this.textToCopy))
      : this.clipboardService.copyFromContent(this.element.nativeElement.value)

    this.notificationService.success({
      message: 'Elemento copiato con successo',
    })
  }
}
