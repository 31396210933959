import { defaultsDeep, cloneDeep } from 'lodash'

import {
  PickupPointSearchParams,
  PickupPointSearchField,
  PickupPoint,
  PickupPointKind,
  PickupPointOpening,
} from './pickup-point.model'
import { DeepPartial } from '../../models/util.model'
import { QueryStringFilter } from '../../models/query-string.model'

// Initial states

const PICKUP_POINT_INITIAL_STATE: DeepPartial<PickupPoint> = {
  name: undefined,
  kind: PickupPointKind.store,
  channelIds: [],
  address: {
    address1: undefined,
    city: undefined,
    postalCode: undefined,
    countryCode: undefined,
    province: undefined,
  },
  isActive: false,
}

const PICKUP_POINT_OPENING_INITIAL_STATE: Partial<PickupPointOpening> = {
  day: undefined,
  from: undefined,
  to: undefined,
  from2: undefined,
  to2: undefined,
}

// Reducers

export function initPickupPoint(point: Partial<PickupPoint> = {}): PickupPoint {
  return defaultsDeep(cloneDeep(point), PICKUP_POINT_INITIAL_STATE)
}

export function addPickupPointOpening(
  point: PickupPoint,
  opening?: PickupPointOpening
): PickupPoint {
  return {
    ...point,
    openings: [
      ...(point.openings || []),
      { ...(opening || PICKUP_POINT_OPENING_INITIAL_STATE) },
    ],
  }
}

export function removePickupPointOpening(
  point: PickupPoint,
  i?: number
): PickupPoint {
  if (i === undefined) {
    i = point.openings?.length
  }

  const openings = [
    ...(point.openings?.filter((op, index) => index !== i) || []),
  ]

  return {
    ...point,
    openings: openings.length ? openings : undefined,
  }
}

export function addPickupPointLocation(point: PickupPoint): PickupPoint {
  return {
    ...point,
    location: {
      type: 'Point',
      coordinates: [],
    },
  }
}

export function removePickupPointLocation(point: PickupPoint): PickupPoint {
  return {
    ...point,
    location: undefined,
  }
}

// Checks

export function checkPickupPoint(point: PickupPoint): PickupPoint {
  return {
    ...point,
    location:
      !point.location?.coordinates || !point.location?.coordinates.length
        ? undefined
        : point.location,
  }
}

// Search

export function pickupPointFilterParams(
  params: PickupPointSearchParams,
  filter: QueryStringFilter<PickupPointSearchField>
): PickupPointSearchParams {
  const searchParams: PickupPointSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'externalId':
      if (filter.operator === '=') {
        searchParams.externalId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['externalId:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['externalId:ct'] = filter.value
      }
      break
    case 'channelIds':
      if (filter.value !== null) {
        searchParams.channelIds = filter.value
      } else {
        searchParams['channelIds:ex'] = false
      }
      break
    case 'bannerId':
      if (filter.value !== null) {
        searchParams.bannerId = filter.value
      } else {
        searchParams['bannerId:ex'] = false
      }
      break
    case 'address.address1':
      if (filter.operator === '=') {
        searchParams['address.address1'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.address1:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.address1:ct'] = filter.value
      }
      break
    case 'address.city':
      if (filter.operator === '=') {
        searchParams['address.city'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.city:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.city:ct'] = filter.value
      }
      break
    case 'address.countryCode':
      searchParams['address.countryCode'] = filter.value
      break
    case 'address.province':
      if (filter.operator === '=') {
        searchParams['address.province'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.province:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.province:ct'] = filter.value
      }
      break
    case 'isActive':
      searchParams.isActive = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
