import { InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'
import {
  PermissionsCheckMode,
  PermissionsLevel,
  PermissionsScope,
} from '../policies/policy.model'

export interface RefreshToken {
  /**
   * Token identifier.
   */
  _id: string
  /**
   * Raw JWT token.
   */
  raw: string
  /**
   * Session type.
   * - WEB: Standard web session
   * - APP: Native application session (native apps)
   * - PAT: Personal Access Token session (impersonate user)
   */
  type: 'APP' | 'PAT' | 'WEB'
  /**
   * Token subject identifier.
   */
  userId: string
}

export interface AccessToken {
  /**
   * Token identifier.
   */
  _id: string
  /**
   * Raw JWT token.
   */
  raw: string
  /**
   * Token domain identifier.
   */
  tenantId: string
  /**
   * Token subject identifier.
   */
  userId: string
}

export type AuthError =
  | 'ACCESS_TOKEN_EXPIRED'
  | 'ACCESS_TOKEN_INVALID'
  | 'ACCESS_TOKEN_PREMATURE_USAGE'
  | 'NOT_AUTHENTICATED'
  | 'REFRESH_TOKEN_EXPIRED'
  | 'REFRESH_TOKEN_INVALID'
  | 'REFRESH_TOKEN_PREMATURE_USAGE'
  | 'SESSION_CONFLICT'
  | 'SESSION_INVALIDATED'
  | 'TOKEN_NOT_VALID'

export type AuthNotificationCode =
  | 'PASSWORD_CHANGED'
  | 'PASSWORD_RECOVERED'
  | 'PASSWORD_UPDATED'
  | 'SESSION_CONFLICT'
  | 'SESSION_EXPIRED'
  | 'TENANT_SAVED'
  | 'TENANT_SELECTED'
  | 'USER_SAVED'

export interface AuthNotificationOptions {
  code: AuthNotificationCode
  data?: AuthNotificationData
}

export interface AuthNotificationData {
  error?: AuthError
}

/**
 * Modal manager base structure
 */
export declare interface AuthManager {
  authenticationRoute: string
  authenticatedRoute: string
  useAuthToken: boolean

  /**
   * Check if the user is authenticated
   * @returns the observable that indicates if the user is authenticated
   */
  isAuthenticated$: () => Observable<boolean>

  /**
   * Check if the user is allowed to do an action
   * @param scope - the scope
   * @param level - the level
   * @returns the observable that indicates if the user is allowed to do an action
   */
  isAllowed$: (
    scope: PermissionsScope,
    level?: PermissionsLevel,
  ) => Observable<boolean>

  /**
   * Check if the current user is allowed to access the scopes
   * @param scopes - the permission scopes
   * @param level - the permission level
   * @param mode - the permission mode
   * @returns the observable<boolean> that indicates if is allowed or not
   */
  isMultiAllowed$: (
    scopes: PermissionsScope[],
    level?: PermissionsLevel,
    mode?: PermissionsCheckMode,
  ) => Observable<boolean>

  /**
   * Check if the user is authenticated and can access the resource
   * @param scope - the scope
   * @param level - the level
   * @returns the observable that indicates if the user is authenticated and can access the resouce
   */
  isAccessible$: (
    scope: PermissionsScope,
    level?: PermissionsLevel,
  ) => Observable<boolean>

  /**
   * Check if the auth store is reviving
   * @returns the observable<boolean> that indicates if is reviving
   */
  isReviving$: () => Observable<boolean>

  /**
   * Get access token
   * @returns the observable for get access token
   */
  getAccessToken$: () => Observable<string | undefined>

  /**
   * Get refresh token
   * @returns the observable for get refresh token
   */
  getRefreshToken$: () => Observable<string | undefined>
}

export const AUTH_MANAGER = new InjectionToken<AuthManager>('AuthManager')
