import { Pipe, PipeTransform } from '@angular/core'
import { getPackingOrdersPrinted } from '../libs/packing-orders.lib'
import {
  getPackingProductsBulky,
  getPackingProductsHeavy,
} from '../libs/packing-products.lib'
import { PackingList } from '../packing-list.model'

@Pipe({
  name: 'packingList',
})
export class PackingListPipe implements PipeTransform {
  transform(
    packingList: PackingList | undefined,
    kind:
      | 'orders-count'
      | 'orders-printed'
      | 'products-heavy'
      | 'products-bulky',
    orderId?: string
  ): number | string {
    if (!packingList) {
      return ''
    }

    switch (kind) {
      case 'orders-count':
        return packingList.orders.length
      case 'orders-printed':
        return getPackingOrdersPrinted(packingList)
      case 'products-bulky':
        return getPackingProductsBulky(packingList, orderId)
      case 'products-heavy':
        return getPackingProductsHeavy(packingList, orderId)
      default:
        return ''
    }
  }
}
