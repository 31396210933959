import { HttpErrorResponse } from '@angular/common/http'
import {
  AUTH_ACCESS_ERRORS,
  AUTH_REFRESH_ERRORS,
  AUTH_BLANK_ERRORS,
} from '../auth.const'

/**
 * Indicates if the error must be skipped
 * @param httpError - The http error response
 * @returns The blank error flag
 */
export function isErrorBlank(httpError: HttpErrorResponse): boolean {
  return AUTH_BLANK_ERRORS.includes(httpError.error?.error)
}

/**
 * Indicates if the error concerns authentication
 * @param httpError - The http error response
 * @returns The access error flag
 */
export function isErrorAccess(httpError: HttpErrorResponse): boolean {
  return AUTH_ACCESS_ERRORS.includes(httpError.error?.error)
}

/**
 * Indicates if the error concerns session expiration
 * @param httpError - The http error response
 * @returns The refresh error flag
 */
export function isErrorRefresh(httpError: HttpErrorResponse): boolean {
  return AUTH_REFRESH_ERRORS.includes(httpError.error?.error)
}
