import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { initSentry } from '@evologi/admin/ui'

if (environment.production) {
  enableProdMode()
}

if (environment.sentryDsn) {
  initSentry(
    environment.sentryDsn,
    environment.production,
    environment.localhost
  )
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
