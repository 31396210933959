import { Pipe, PipeTransform } from '@angular/core'
import { CatalogsService } from '../catalogs.service'
import { EMPTY, Observable, catchError } from 'rxjs'
import { Catalog } from '../catalog.model'

@Pipe({
  name: 'catalog',
})
export class CatalogPipe implements PipeTransform {
  constructor(private catalogsService: CatalogsService) {}

  transform(
    catalogKey: string,
    catalogFieldKey: '_id' | 'code' = '_id',
  ): Observable<Catalog> {
    const obs$ =
      catalogFieldKey === '_id'
        ? this.catalogsService.read$(catalogKey)
        : this.catalogsService.readOne$({ [catalogFieldKey]: catalogKey })

    return obs$.pipe(catchError(() => EMPTY))
  }
}
