import { Injectable } from '@angular/core'
import { union, uniq, uniqBy } from 'lodash'
import { Observable, combineLatest, map, of, switchMap, tap } from 'rxjs'
import moment from 'moment'

import { OrderSearchParams, OrderStatus, OrdersService } from '../../orders'
import {
  SupplierOrder,
  SupplierOrderSearchParams,
  SupplierOrderStatus,
  SupplierOrdersService,
} from '../../supplier-orders'
import { LocationsService } from '../../locations'
import { Supplier, SuppliersService } from '../../suppliers'
import {
  GoodsReceive,
  GoodsReceiveSearchParams,
  GoodsReceiveStatus,
  GoodsReceivesService,
} from '../../goods-receives'
import {
  Product,
  ProductGoodsReceive,
  ProductIncomings,
  ProductLocation,
  ProductOrder,
  ProductPositions,
  ProductSupplierOrder,
} from '../product.model'
import { parseProductOrders } from '../libs/order.lib'
import { parseProductSupplierOrders } from '../libs/product-supplier-order.lib'
import { Tenant } from '../../tenants'
import { parseProductGoodsReceive } from '../libs/products-goods-receive.lib'
import { WarehousesService } from '../../warehouses'

@Injectable({
  providedIn: 'root',
})
export class ProductQuantitiesRepository {
  // Stores
  private suppliers: Supplier[] = []

  constructor(
    private ordersService: OrdersService,
    private supplierOrdersService: SupplierOrdersService,
    private warehousesService: WarehousesService,
    private locationsService: LocationsService,
    private suppliersService: SuppliersService,
    private goodsReceivesService: GoodsReceivesService,
  ) {}

  /**
   * Load product positions data
   * @param product - the product
   * @returns the positions data of the product
   */
  getPositions$(
    productLocations: ProductLocation[],
  ): Observable<ProductPositions> {
    if (!productLocations.length) {
      return of({ locations: [] })
    }

    const warehouseIds = uniq(productLocations.map((loc) => loc.warehouseId))
    const locationIds = uniq(productLocations.map((loc) => loc._id))

    return combineLatest({
      productLocations: of(productLocations),
      warehouses: this.warehousesService.list$({ _id: warehouseIds }),
      locations: this.locationsService.list$({
        _id: locationIds,
      }),
    })
  }

  /**
   * Load product outgoings data
   * @param product - the product
   * @param warehouseId - the warehouse ID to filter
   * @returns the observable for load product outgoings data
   */
  getOutgoings$(
    product: Product,
    warehouseId?: string,
  ): Observable<ProductOrder[]> {
    const status = [
      OrderStatus.pending,
      OrderStatus.confirmed,
      OrderStatus.processing,
    ]
    const params: OrderSearchParams = {
      'rows.product._id': product._id,
      status,
    }

    if (warehouseId) {
      params.assignedWarehouseId = warehouseId
    }

    return this.ordersService.list$(params, true).pipe(
      switchMap((orders) =>
        this.ordersService
          .list$({
            'rows.product.simpleProducts._id': product._id,
            status,
          })
          .pipe(map((kitOrders) => uniqBy(union(orders, kitOrders), '_id'))),
      ),
      map((orders) => parseProductOrders(product, orders, warehouseId)),
    )
  }

  /**
   * Load product incomings data
   * @param product - the product
   * @param warehouseId - the warehouseId to filter
   * @returns the observable for load incomings data
   */
  getIncomings$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<ProductIncomings> {
    const productLocations = product.warehouses.filter(
      (w) => w.incomingQty > 0 && (warehouseId ? w._id === warehouseId : true),
    )

    return combineLatest({
      productLocations: of(productLocations),
      supplierOrders: this._getSupplierOrders$(tenant, product, warehouseId),
      goodsReceives: this._getGoodsReceives$(tenant, product, warehouseId),
    }).pipe(
      switchMap((incomingData) =>
        this.getIncomingsSuppliers$([
          ...incomingData.goodsReceives,
          ...incomingData.supplierOrders,
        ]).pipe(map((suppliers) => ({ ...incomingData, suppliers }))),
      ),
    )
  }

  /**
   * Load incomings docs suppliers
   * @param docs - the incoming documents
   * @returns the observable for load incoming docs
   */
  getIncomingsSuppliers$(
    docs: (ProductSupplierOrder | ProductGoodsReceive)[],
  ): Observable<Supplier[]> {
    const supplierIds = this._parseIncomingsSupplierIds(docs)
    return this.suppliersService
      .store$(supplierIds, this.suppliers)
      .pipe(tap((suppliers) => (this.suppliers = suppliers)))
  }

  /**
   * Load product supplier orders
   * @param product - the product
   * @param warehouseId - the warehouse ID to filter
   * @returns the observable for load product supplier orders
   */
  getSupplierOrders$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<ProductIncomings> {
    return this._getSupplierOrders$(tenant, product, warehouseId).pipe(
      switchMap((supplierOrders) =>
        this.getIncomingsSuppliers$(supplierOrders).pipe(
          map((suppliers) => ({ supplierOrders, suppliers })),
        ),
      ),
    )
  }

  /**
   * Load product goods receives
   * @param product - the product
   * @param warehouseId - the warehouse ID to filter
   * @returns the observable for load goods receives
   */
  getGoodsReceives$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<ProductIncomings> {
    return this._getGoodsReceives$(tenant, product, warehouseId).pipe(
      switchMap((goodsReceives) =>
        this.getIncomingsSuppliers$(goodsReceives).pipe(
          map((suppliers) => ({ goodsReceives, suppliers })),
        ),
      ),
    )
  }

  // Utilities

  private _getSupplierOrders$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<ProductSupplierOrder[]> {
    return combineLatest({
      active: this._getSupplierOrdersActive$(product, warehouseId),
      archive: this._getSupplierOrdersArchive$(tenant, product, warehouseId),
    }).pipe(
      map((supplierOrders) =>
        parseProductSupplierOrders(product, [
          ...supplierOrders.active,
          ...supplierOrders.archive,
        ]),
      ),
    )
  }

  private _getSupplierOrdersActive$(
    product: Product,
    warehouseId?: string,
  ): Observable<SupplierOrder[]> {
    const params: SupplierOrderSearchParams = {
      'rows.product._id': product._id,
      fields: ['_id', 'supplierId', 'header', 'rows', 'status'],
      status: [
        SupplierOrderStatus.confirmed,
        SupplierOrderStatus.assigned,
        SupplierOrderStatus.received,
      ],
    }

    if (warehouseId) {
      params['warehouseId'] = warehouseId
    }

    return this.supplierOrdersService.list$(params)
  }

  private _getSupplierOrdersArchive$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<SupplierOrder[]> {
    if (!tenant?.catalog?.incomingHistory?.active) {
      return of([])
    }

    const params: SupplierOrderSearchParams = {
      'rows.product._id': product._id,
      fields: ['_id', 'supplierId', 'header', 'rows', 'status'],
      status: SupplierOrderStatus.closed,
      'createdAt:gt': moment()
        .subtract(tenant?.catalog.incomingHistory.daysPeriod || 30, 'd')
        .startOf('day')
        .toISOString(),
      'createdAt:le': moment().endOf('day').toISOString(),
    }

    if (warehouseId) {
      params['warehouseId'] = warehouseId
    }

    return this.supplierOrdersService.list$(params)
  }

  private _getGoodsReceives$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<ProductGoodsReceive[]> {
    return combineLatest({
      active: this._getGoodsReceiveActive$(product, warehouseId),
      archive: this._getGoodsReceiveArchive$(tenant, product, warehouseId),
    }).pipe(
      map((goodsReceives) =>
        parseProductGoodsReceive(product, [
          ...goodsReceives.active,
          ...goodsReceives.archive,
        ]),
      ),
    )
  }

  private _getGoodsReceiveActive$(
    product: Product,
    warehouseId?: string,
  ): Observable<GoodsReceive[]> {
    const params: GoodsReceiveSearchParams = {
      'rows.product._id': product._id,
      fields: ['_id', 'supplierId', 'header', 'rows', 'status'],
      status: [
        GoodsReceiveStatus.pending,
        GoodsReceiveStatus.confirmed,
        GoodsReceiveStatus.processing,
        GoodsReceiveStatus.processed,
      ],
    }

    if (warehouseId) {
      params.warehouseId = warehouseId
    }

    return this.goodsReceivesService.list$(params)
  }

  private _getGoodsReceiveArchive$(
    tenant: Tenant,
    product: Product,
    warehouseId?: string,
  ): Observable<GoodsReceive[]> {
    if (!tenant?.catalog?.incomingHistory?.active) {
      return of([])
    }

    const params: GoodsReceiveSearchParams = {
      'rows.product._id': product._id,
      fields: ['_id', 'supplierId', 'header', 'rows', 'status'],
      status: GoodsReceiveStatus.closed,
      'createdAt:gt': moment()
        .subtract(tenant?.catalog.incomingHistory.daysPeriod || 30, 'd')
        .startOf('day')
        .toISOString(),
      'createdAt:le': moment().endOf('day').toISOString(),
    }

    if (warehouseId) {
      params['warehouseId'] = warehouseId
    }

    return this.goodsReceivesService.list$(params)
  }

  private _parseIncomingsSupplierIds(
    docs: (SupplierOrder | GoodsReceive)[],
  ): string[] {
    // eslint-disable-next-line
    return uniq(docs.filter((s) => !!s.supplierId).map((s) => s.supplierId!))
  }
}
