import { Pipe, PipeTransform } from '@angular/core'
import { PickingList } from '../picking-list.model'

@Pipe({
  name: 'pickingProducts',
})
export class PickingProductsPipe implements PipeTransform {
  transform(pickingList: PickingList): string[] {
    const ids: string[] = []
    pickingList.missions.map((mission) => {
      if (!ids.includes(mission.product._id)) {
        ids.push(mission.product._id)
      }
    })
    pickingList.orders.map((order) => {
      order.rows.map((row) => {
        if (!ids.includes(row.productId)) {
          ids.push(row.productId)
        }
      })
    })
    return ids
  }
}
