import { HttpErrorResponse } from '@angular/common/http'
import {
  Stock,
  StockSearchParams,
  StocksField,
  StocksListingData,
  StocksListingPage,
} from '../stock.model'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Observable, filter, map, switchMap, tap, withLatestFrom } from 'rxjs'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Warehouse } from '../../warehouses'
import { StocksRepository } from '../stocks.repository'
import { STOCK_DEFAULT_FIELDS } from '../stock.const'

interface StocksListingState extends StocksListingPage {
  fields: StocksField[]
  filters?: StockSearchParams
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const STOCKS_INITIAL_STATE: StocksListingState = {
  fields: STOCK_DEFAULT_FIELDS,
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class StocksListingUseCase extends ComponentStore<StocksListingState> {
  constructor(private stocksRepository: StocksRepository) {
    super(STOCKS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<StocksListingState> = this.select(
    (state) => state,
  )

  readonly selectFilters$: Observable<StockSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {})),
  )

  readonly selectStocks$: Observable<Stock[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectExtData$: Observable<StocksListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectWarehouses$: Observable<Warehouse[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.warehouses))

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<StocksListingPage> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectTotalCount$,
          this.selectStocks$,
          this.selectExtData$,
          (totalCount, data, extData) => ({ totalCount, data, extData }),
          { debounce: true },
        ),
      ),
    )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<StockSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        switchMap(([searchParams, state]) =>
          this.stocksRepository
            .searchStocks$(searchParams, state.fields, state.extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<StockSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        map(([params, state]) => ({
          params: { ...params, limit: state.totalCount },
          fields: state.fields,
          extData: state.extData,
        })),
        switchMap(({ params, fields, extData }) =>
          this.stocksRepository.searchStocks$(params, fields, extData).pipe(
            tapResponse(
              (pageData) => this.setPage(pageData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  // Reducers

  readonly setFilters = this.updater(
    (state, filters?: StockSearchParams): StocksListingState => {
      return {
        filters,
        fields: state.fields,
        data: state.data,
        extData: state.extData,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: StocksListingPage): StocksListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): StocksListingState => ({
      ...state,
      data: [],
      extData: undefined,
      totalCount: 0,
      error,
      toReload: false,
      isLoading: false,
      isInitialized: true,
    }),
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): StocksListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setStocks = this.updater(
    (state, data: Stock[]): StocksListingState => ({
      ...state,
      data,
    }),
  )
}
