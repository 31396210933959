import { uniq } from 'lodash'
import { getOrderUserIds } from './order.lib'
import { Page } from '../../../models/util.model'
import { Order, OrdersListingKeys } from '../order.model'

/**
 * Parse order page keys
 * @param page - the order page
 * @returns the page listing keys
 */
export function parseOrderPageKeys(page: Page<Order>): OrdersListingKeys {
  return page.data.reduce<OrdersListingKeys>(
    (acc, r) => parseOrderKeys(r, acc),
    {}
  )
}

/**
 * Parse order keys
 * @param order - the order
 * @param keys - the keys already loaded
 * @returns the orders listing keys
 */
export function parseOrderKeys(
  order: Order,
  keys: OrdersListingKeys = {}
): OrdersListingKeys {
  const warehouseIds = uniq([
    ...(keys['warehouseIds'] || []),
    ...(order.warehouseId ? [order.warehouseId] : []),
    ...(order.assignedWarehouseId ? [order.assignedWarehouseId] : []),
  ])
  const currencies = uniq([
    ...(keys['currencies'] || []),
    ...(order.header.currency ? [order.header.currency] : []),
  ])
  const paymentIds = uniq([
    ...(keys['paymentIds'] || []),
    ...(order.header.paymentType ? [order.header.paymentType] : []),
  ])
  const channelIds = uniq([
    ...(keys['channelIds'] || []),
    ...(order.header.channel ? [order.header.channel] : []),
  ])
  const countryCodes = uniq([
    ...(keys['countryCodes'] || []),
    ...(order.header.shippingAddress?.countryCode
      ? [order.header.shippingAddress.countryCode]
      : []),
    ...(order.header.billingAddress?.countryCode
      ? [order.header.billingAddress.countryCode]
      : []),
  ])
  const carrierIds = uniq([
    ...(keys['carrierIds'] || []),
    ...(order.carrierId ? [order.carrierId] : []),
  ])
  const userIds = uniq([...(keys['userIds'] || []), ...getOrderUserIds(order)])
  const pickingListIds = uniq([
    ...(keys['pickingListIds'] || []),
    ...(order.pickingListId ? [order.pickingListId] : []),
  ])
  const tagValues = uniq([
    ...(keys['tagValues'] || []),
    ...(order.tags ? order.tags : []),
  ])

  return {
    warehouseIds,
    currencies,
    paymentIds,
    channelIds,
    countryCodes,
    carrierIds,
    userIds,
    pickingListIds,
    tagValues,
  }
}
