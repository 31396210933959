import { Injectable, OnDestroy, EventEmitter } from '@angular/core'
import { ModalAction } from '@evologi/shared/data-access-api'
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  public onHide: EventEmitter<any> = new EventEmitter()
  public onHidden: EventEmitter<any> = new EventEmitter()
  private modals: BsModalRef[] = []

  constructor(private modalService: BsModalService) {}

  // Lifecycle

  ngOnDestroy() {
    this.hideAll()
  }

  // Modals management

  show<ComponentType, ReturnType = ModalAction>(
    component: any,
    modalClass: string,
    initialState?: Partial<ComponentType>,
  ): Observable<ReturnType> | undefined {
    // Define modal options
    const modalOptions: ModalOptions<ComponentType> = {
      backdrop: 'static',
      class: modalClass,
      initialState,
    }

    // Show modal
    const modal: BsModalRef = this.modalService.show(component, modalOptions)
    this.pushModal(modal)

    // Init events
    modal.onHide?.pipe(take(1)).subscribe((res) => {
      this.onHide.emit(component.name)
    })
    modal.onHidden?.pipe(take(1)).subscribe((res) => {
      this.onHidden.emit(component.name)
      this.popModal(modal.id!)
    })

    return modal.content?.action
  }

  pushModal(modal: BsModalRef): void {
    this.modals.push(modal)
  }

  popModal(id: string | number): void {
    const index = this.modals.findIndex((m) => m.id === id)
    index >= 0 && this.modals.splice(index, 1)
  }

  hideAll(): void {
    this.modals.forEach((m) => m.hide())
  }
}
