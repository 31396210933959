import { SelectionData } from '../../models/util.model'
import { ViewField, ViewTarget } from './view.model'

export const VIEW_FRAGMENT = 'view'
export const VIEW_DEFAULT_CODE = 'default'

export const VIEW_DEFAULT_FIELDS: ViewField[] = ['target', 'name', 'isShared']

export const VIEW_TARGETS: SelectionData<ViewTarget>[] = [
  {
    label: 'Products',
    value: ViewTarget.products,
    icon: 'fad fa-list',
  },
  {
    label: 'Customer orders',
    value: ViewTarget.orders,
    icon: 'fad fa-dollar-sign',
  },
  {
    label: 'Supplier orders',
    value: ViewTarget.supplierOrders,
    icon: 'fad fa-file-invoice-dollar',
  },
  {
    label: 'Goods receives',
    value: ViewTarget.goodsReceives,
    icon: 'fad fa-truck-loading',
  },
  {
    label: 'Goods returns',
    value: ViewTarget.goodsReturns,
    icon: 'fad fa-parachute-box',
  },
]
