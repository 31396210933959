import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { UiColor, UiFill, UiSize } from '../../models/ui.model'

@Component({
  selector: 'op-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() icon?: string
  @Input() buttonClass?: string
  @Input() color?: UiColor
  @Input() fill: UiFill = 'solid'
  @Input() size?: UiSize = 'md'
  @Input() title?: string
  @Input() advice?: string

  @Input() disabled? = false
  @Input() faded? = false

  @Output() readonly buttonClick = new EventEmitter<void>()

  public btnClasses = ''

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['size'] ||
      changes['fill'] ||
      changes['color'] ||
      changes['faded']
    ) {
      this.parseClasses()
    }
  }

  parseClasses() {
    const btnClasses: string[] = ['btn']

    // Size
    btnClasses.push(`btn-${this.size}`)

    // Color
    if (this.color) {
      if (this.fill === 'solid') {
        btnClasses.push(`btn-${this.color}`)
      } else if (this.fill === 'outline') {
        btnClasses.push(`btn-outline-${this.color}`)
      } else if (this.fill === 'no-border') {
        btnClasses.push(`btn-link text-${this.color}`)
      }
    }

    this.btnClasses = btnClasses.join(' ')
  }
}
