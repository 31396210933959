import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import {
  PickingList,
  PickingListSearchParams,
  PickingListTest,
} from './picking-list.model'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { CrudService } from '../../services/crud.service'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { Order } from '../orders'
import { difference, uniq } from 'lodash'

const MODEL = 'picking-lists'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class PickingListsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new picking list
   * @param pickingList - The picking list to create
   * @returns The observable<PickingList> to create the picking list
   */
  create$(pickingList: PickingList): Observable<PickingList> {
    return this._create$<PickingList>(pickingList)
  }

  /**
   * Read a picking list by ID
   * @param pickingListId - The picking list ID
   * @returns The observable<PickingList> for read the picking list
   */
  read$(pickingListId: string): Observable<PickingList> {
    return this._read$<PickingList>(pickingListId)
  }

  /**
   * Update a picking list by ID
   * @param pickingListId - The picking list ID
   * @param pickingList - The picking list body to update
   * @returns The observable<PickingList> for update the picking list
   */
  update$(
    pickingListId: string,
    pickingList: PickingList,
  ): Observable<PickingList> {
    return this._update$<PickingList>(pickingListId, pickingList)
  }

  /**
   * Create or update a picking list by ID
   * @param pickingListId - The picking list ID
   * @param pickingList - The picking list body to update
   * @returns The observable<PickingList> for update the picking list
   */
  upsert$(pickingList: PickingList): Observable<PickingList> {
    return this._upsert$<PickingList>(pickingList, pickingList._id)
  }

  /**
   * Delete a picking list by ID
   * @param pickingListId - The picking list ID
   * @returns The observable<PickingList> for delete the picking list
   */
  delete$(pickingListId: string): Observable<PickingList> {
    return this._delete$<PickingList>(pickingListId)
  }

  /**
   * Search pickingLists by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<PickingList>> for search pickingLists
   */
  search$(
    params?: PickingListSearchParams,
    returnAll = false,
  ): Observable<Page<PickingList>> {
    return this._search$<PickingList>(params, returnAll)
  }

  /**
   * List pickingLists by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<PickingList[]> for list pickingLists
   */
  list$(
    params?: PickingListSearchParams,
    returnAll = false,
  ): Observable<PickingList[]> {
    return this._list$<PickingList>(params, returnAll)
  }

  /**
   * Simulate a picking list by warehouse ID and order IDs.
   * @param warehouseId - The warehouse ID
   * @param orders - The order IDs
   * @returns The observable<any> for get the products coverage
   */
  simulate$(
    warehouseId: string,
    orders: { _id: string }[],
  ): Observable<PickingListTest> {
    return this.http.post<PickingListTest>(`${this.apiUrl}/test`, {
      warehouseId,
      orders,
    })
  }

  /**
   * Check a picking list by orderIDs
   * @param pickingListId - The picking list ID
   * @param orders - The order IDs
   * @returns The observable<any> for get the product coverage
   */
  check$(
    pickingListId: string,
    orders: { _id: string }[],
  ): Observable<PickingListTest> {
    return this.http.post<PickingListTest>(
      `${this.apiUrl}/${pickingListId}/test`,
      {
        orders,
      },
    )
  }

  /**
   * Process a picking list by ID
   * @param pickingListId - The picking list ID
   * @returns The observable<PickingList> for process the picking list
   */
  process$(pickingListId: string): Observable<PickingList> {
    return this.http.post<PickingList>(
      `${this.apiUrl}/${pickingListId}/process`,
      {},
    )
  }

  /**
   * Close a picking list mission
   * @param pickingListId - The picking list ID
   * @param missionId - The mission ID
   * @returns The observable<PickingList> for close the picking list mission
   */
  closeMission$(
    pickingListId: string,
    missionId: string,
  ): Observable<PickingList> {
    return this.http.post<PickingList>(
      `${this.apiUrl}/${pickingListId}/missions/${missionId}/close`,
      {},
    )
  }

  /**
   * Pick a picking list mission
   * @param pickingListId - The picking list ID
   * @param missionId - The mission ID
   * @param params - The pick params
   * @returns The observable<PickingList> for pick the picking list mission
   */
  pickMission$(
    pickingListId: string,
    missionId: string,
    params: {
      productId: string
      toteCode: string
      qtyPicked: number
      lot?: string
      expirationDate?: string
      serial?: string
      leaveMissionOpen?: boolean
    },
  ): Observable<PickingList> {
    return this.http.post<PickingList>(
      `${this.apiUrl}/${pickingListId}/missions/${missionId}/pick`,
      params,
    )
  }

  /**
   * Skip a picking list mission
   * @param pickingListId - The picking list ID
   * @param missionId - The mission ID
   * @returns The observable<PickingList> for skip the picking list mission
   */
  skipMission$(
    pickingListId: string,
    missionId: string,
  ): Observable<PickingList> {
    return this.http.post<PickingList>(
      `${this.apiUrl}/${pickingListId}/missions/${missionId}/skip`,
      {},
    )
  }

  /**
   * Change the location of a picking list mission
   * @param pickingListId - The picking list ID
   * @param missionId - The mission ID
   * @param params - The change location params
   * @returns The observable<PickingList> for skip the picking list mission
   */
  changeMissionLocation$(
    pickingListId: string,
    missionId: string,
    params: {
      locationId: string
      lot?: string
      expirationDate?: string
      serial?: string
    },
  ): Observable<PickingList> {
    return this.http.post<PickingList>(
      `${this.apiUrl}/${pickingListId}/missions/${missionId}/change-location`,
      {
        missionId,
        ...params,
      },
    )
  }

  /**
   * Get the recommended order base on a warehouse and a channel
   * @param warehouseId - The warehouse ID
   * @param channelId - The channel ID
   * @param limit - The max number of orders
   * @returns The observable<Order[]> for get recommended orders
   */
  getRecommendedOrders$(
    warehouseId: string,
    channelId: string,
    limit = 6,
  ): Observable<Order[]> {
    return this.http.post<Order[]>(`${this.apiUrl}/recommend-orders`, {
      warehouseId,
      channelId,
      limit,
    })
  }

  /**
   * Print picking-list missions labels
   * @param pickingListId - The picking-list ID
   * @param printerId - The printer ID
   * @returns The observable<void> for print labels
   */
  printMissionsLabels$(
    pickingListId: string,
    printerId: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${pickingListId}/print-remained-missions`,
      {
        printerId,
      },
    )
  }

  /**
   * Print picking-list summary
   * @param pickingListId - The picking-list ID
   * @returns The observable<void> for print the picking-list summary
   */
  printSummary$(pickingListId: string, printerId: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${pickingListId}/files/summary/print`,
      {
        printerId,
      },
    )
  }

  /**
   * Download the picking-list summary
   * @param goodsReceiveId - The picking-list ID
   * @returns The observable<any> for download the picking-list summary
   */
  downloadSummary$(pickingListId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${pickingListId}/files/summary`, {
      responseType: 'blob',
    })
  }

  /**
   * Generate picking-lists store
   * @param pickingListIds - the picking-list IDs to load
   * @param pickingLists - the picking-lists already loaded
   * @returns the Observable<PickingList[]> as store
   */
  store$(
    pickingListIds: string[],
    pickingLists: PickingList[],
  ): Observable<PickingList[]> {
    pickingListIds = uniq(pickingListIds)
    pickingListIds = difference(
      pickingListIds,
      pickingLists.map((u) => u._id),
    )

    if (pickingListIds.length === 0) {
      return of(pickingLists)
    }

    return this.list$({ _id: pickingListIds }).pipe(
      map((picks) => [...pickingLists, ...picks]),
    )
  }
}
