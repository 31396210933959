import { Component, Input } from '@angular/core'

@Component({
  selector: 'op-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() type?: 'form' | 'grid' | 'full' | 'custom'
  @Input() showHeader = true
  @Input() showPhoto = false
  @Input() rowsCount = 3
  @Input() rowsHeight = '30px'
  @Input() customStyle: Record<string, string | number> = {}
}
