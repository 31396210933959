import { cloneDeep, defaultsDeep } from 'lodash'
import { GoodsReceiveHeader, GoodsReceiveRow } from '../goods-receive.model'
import { calculatePrices, dinero } from '../../../libs/dinero.lib'

// Initial states

export const GOODS_RECEIVE_HEADER_INITIAL_STATE: Partial<GoodsReceiveHeader> = {
  date: new Date(),
  shippingCost: 0,
  shippingCostWithTaxes: 0,
  shippingTaxRate: 0,
  shippingTaxes: 0,
  subTotal: 0,
  subTotalWithTaxes: 0,
  subTotalReceived: 0,
  subTotalReceivedWithTaxes: 0,
  totalOrderAmount: 0,
  totalReceivedAmount: 0,
  notCompliantQtyAmount: 0,
  notCompliantQualityAmount: 0,
  notCompliantPriceAmount: 0,
  notCompliantTotal: 0,
}

// Init

export function initGoodsReceiveHeader(
  header: Partial<GoodsReceiveHeader> = {}
): GoodsReceiveHeader {
  return defaultsDeep(cloneDeep(header), GOODS_RECEIVE_HEADER_INITIAL_STATE)
}

// Amounts

export function updateGoodsReceiveHeaderAmounts(
  header: GoodsReceiveHeader,
  rows: GoodsReceiveRow[]
): GoodsReceiveHeader {
  // Shipping prices
  const {
    shippingCostWithTaxes,
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
  } = updateShippingAmounts(header)

  // Not compliants
  const notCompliantQtyAmount = rows.reduce(
    (acc, row) =>
      acc.add(dinero(row.invoicedNetPrice).multiply(row.notCompliantQty)),
    dinero(0)
  )
  const notCompliantQualityAmount = rows.reduce(
    (acc, row) =>
      acc.add(dinero(row.invoicedNetPrice).multiply(row.notCompliantQuality)),
    dinero(0)
  )
  const notCompliantPriceAmount = rows.reduce(
    (acc, row) => acc.add(dinero(row.notCompliantPriceAmount)),
    dinero(0)
  )

  // Subtotals
  const subTotal = rows.reduce(
    (acc, row) =>
      acc.add(dinero(row.invoicedNetPrice).multiply(row.invoicedQty)),
    dinero(0)
  )
  const subTotalReceived = rows.reduce(
    (acc, row) => acc.add(dinero(row.netPrice).multiply(row.receivedQty)),
    dinero(0)
  )

  // Amounts
  const totalOrderAmount = subTotal
    .add(dinero(shippingCost))
    .add(dinero(shippingTaxes))
  const totalReceivedAmount = subTotalReceived
    .add(dinero(shippingCost))
    .add(dinero(shippingTaxes))
  const notCompliantTotal = notCompliantQtyAmount
    .add(notCompliantQualityAmount)
    .add(notCompliantPriceAmount)

  return {
    ...header,
    shippingCost,
    shippingCostWithTaxes,
    shippingTaxRate,
    shippingTaxes,
    subTotal: subTotal.toUnit(),
    subTotalReceived: subTotalReceived.toUnit(),
    totalOrderAmount: totalOrderAmount.toUnit(),
    totalReceivedAmount: totalReceivedAmount.toUnit(),
    notCompliantQtyAmount: notCompliantQtyAmount.toUnit(),
    notCompliantQualityAmount: notCompliantQualityAmount.toUnit(),
    notCompliantPriceAmount: notCompliantPriceAmount.toUnit(),
    notCompliantTotal: notCompliantTotal.toUnit(),
  }
}

function updateShippingAmounts(header: GoodsReceiveHeader) {
  const shippingPrices = calculatePrices({
    taxRate: header.shippingTaxRate,
    price: header.shippingCost,
  })

  return {
    shippingTaxRate: shippingPrices.taxRate,
    shippingCost: shippingPrices.price.toUnit(),
    shippingCostWithTaxes: shippingPrices.total.toUnit(),
    shippingTaxes: shippingPrices.taxes.toUnit(),
  }
}
