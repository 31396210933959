import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { Attachment } from '../files'
import { Supplier } from '../suppliers'
import { ViewField } from '../views/view.model'
import { Warehouse } from '../warehouses'

export enum SupplierOrderStatus {
  drafted = 'DRAFTED',
  confirmed = 'CONFIRMED',
  assigned = 'ASSIGNED',
  received = 'RECEIVED',
  canceled = 'CANCELED',
  closed = 'CLOSED',
}

export enum SupplierOrderRowStatus {
  drafted = 'DRAFTED',
  confirmed = 'CONFIRMED',
  assigned = 'ASSIGNED',
  received = 'RECEIVED',
  canceled = 'CANCELED',
  closed = 'CLOSED',
}

export interface SupplierOrder extends Entity {
  warehouseId?: string
  supplierId?: string
  status: SupplierOrderStatus
  isPartial?: boolean

  // Header & footer document data
  header: SupplierOrderHeader

  // Product rows
  rows: SupplierOrderRow[]

  // Counters
  productsCount: number
  rowsCount: number

  attachments?: Attachment[]
}

export interface SupplierOrderHeader {
  orderNumber?: number
  date?: Date
  currency?: string
  rifOrder?: string
  rifDate?: Date
  deliveryDate?: Date
  subTotal?: number
  subTotalWithTaxes?: number
  bodyTaxes?: number
  totalOrderAmount?: number
  notes?: string
  reasonId?: string
}

export interface SupplierOrderRow {
  _id?: string

  // Quantities
  orderedQty: number
  assignedQty: number
  receivedQty: number

  orderId?: string

  status: SupplierOrderRowStatus
  isPartial?: boolean

  // Prices
  unitPrice: number
  unitPriceWithTaxes: number
  productTaxRate: number
  totalTaxes: number
  totalAmount: number
  totalAmountWithTaxes: number

  // Product info
  product: SupplierOrderProduct

  // Goods receives
  receptions?: SupplierOrderReception[]
}

export interface SupplierOrderRowSelection {
  supplierOrderId: string
  row: SupplierOrderRow
  qty?: number
}

export interface SupplierOrderProduct {
  _id: string
  SKU: string
  supplierSKU?: string
  name: string
}

export interface SupplierOrderReception {
  _id: string
  assignedQty: number
  receivedQty: number
  closed: boolean
}

export interface SupplierOrdersListingPage {
  data?: SupplierOrder[]
  extData?: SupplierOrdersListingData
  totalCount?: number
}

export interface SupplierOrdersListingKeys {
  warehouseIds?: string[]
  supplierIds?: string[]
}

export interface SupplierOrdersListingData {
  warehouses?: Warehouse[]
  suppliers?: Supplier[]
}

export type SupplierOrderChangeStatusAction = 'confirm' | 'close' | 'cancel'

export type SupplierOrderPermission =
  | 'addProducts'
  | 'editHeader'
  | 'saveOrder'
  | 'deleteOrder'
  | 'cancelOrder'
  | 'closeOrder'
  | 'editNotes'

export interface SupplierOrderSearchParams
  extends QueryStringParams<SupplierOrderSortField> {
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  status?: SupplierOrderStatus | SupplierOrderStatus[]
  warehouseId?: string | string[]
  supplierId?: string | string[]
  isPartial?: boolean
  productsCount?: number
  'productsCount:ne'?: number
  'productsCount:lt'?: number
  'productsCount:le'?: number
  'productsCount:gt'?: number
  'productsCount:ge'?: number
  rowsCount?: number
  'rowsCount:ne'?: number
  'rowsCount:lt'?: number
  'rowsCount:le'?: number
  'rowsCount:gt'?: number
  'rowsCount:ge'?: number
  'header.orderNumber'?: number | number[]
  'header.orderNumber:ne'?: number
  'header.orderNumber:lt'?: number
  'header.orderNumber:le'?: number
  'header.orderNumber:gt'?: number
  'header.orderNumber:ge'?: number
  'header.date'?: string
  'header.date:ne'?: string
  'header.date:lt'?: string
  'header.date:le'?: string
  'header.date:gt'?: string
  'header.date:ge'?: string
  'header.rifDate'?: string
  'header.rifDate:ne'?: string
  'header.rifDate:lt'?: string
  'header.rifDate:le'?: string
  'header.rifDate:gt'?: string
  'header.rifDate:ge'?: string
  'header.rifOrder'?: string
  'header.rifOrder:ne'?: string
  'header.deliveryDate'?: string
  'header.deliveryDate:ne'?: string
  'header.deliveryDate:lt'?: string
  'header.deliveryDate:le'?: string
  'header.deliveryDate:gt'?: string
  'header.deliveryDate:ge'?: string
  'header.currency'?: string | string[]
  'rows.product._id'?: string | string[]
  'rows.product.SKU'?: string | string[]
  q?: string
}

export type SupplierOrderViewType = 'SUPPLIER_ORDERS_LISTING_MAIN'

export type SupplierOrderField =
  | '_id'
  | 'createdAt'
  | 'status'
  | 'warehouseId'
  | 'supplierId'
  | 'isPartial'
  | 'productsCount'
  | 'rowsCount'
  | 'header.orderNumber'
  | 'header.date'
  | 'header.rifDate'
  | 'header.rifOrder'
  | 'header.deliveryDate'
  | 'header.currency'
  | 'header.subTotal'
  | 'header.totalOrderAmount'
  | 'header.notes'
  | 'rows.product._id'
  | 'rows.product.SKU'
  | 'completion'
  | 'attachments'

export type SupplierOrderSearchField =
  | '_id'
  | 'createdAt'
  | 'status'
  | 'warehouseId'
  | 'supplierId'
  | 'isPartial'
  | 'productsCount'
  | 'rowsCount'
  | 'header.orderNumber'
  | 'header.date'
  | 'header.rifDate'
  | 'header.rifOrder'
  | 'header.deliveryDate'
  | 'header.currency'
  | 'rows.product._id'
  | 'rows.product.SKU'

export type SupplierOrderSortField =
  | 'createdAt'
  | 'header.orderNumber'
  | 'header.date'
  | 'header.rifDate'
  | 'header.deliveryDate'
