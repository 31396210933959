import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { MovementReason } from './movement.model'

export const MOVEMENT_SCOPE: PermissionsScope = 'movements'

export const MOVEMENT_FIELDS = [
  {
    field: 'createdAt',
    defaultVisibility: true,
  },
  {
    field: 'SKU',
    defaultVisibility: true,
  },
  {
    field: 'name',
    defaultVisibility: true,
  },
  {
    field: 'qty',
    defaultVisibility: true,
  },
  {
    field: 'onHandQty',
    defaultVisibility: true,
  },
  {
    field: 'sign',
    defaultVisibility: true,
  },
  {
    field: 'reason',
    defaultVisibility: true,
  },
  {
    field: 'details.code',
    defaultVisibility: true,
  },
  {
    field: 'details.name',
    defaultVisibility: true,
  },
  {
    field: 'warehouseId',
    defaultVisibility: true,
  },
  {
    field: 'location.path',
    defaultVisibility: true,
  },
  {
    field: 'location.code',
    defaultVisibility: true,
  },
  {
    field: 'pickingListName',
    defaultVisibility: true,
  },
  {
    field: 'goodsReceiveRef',
    defaultVisibility: true,
  },
  {
    field: 'order.orderNumber',
    defaultVisibility: true,
  },
  {
    field: 'order.rifOrder',
    defaultVisibility: true,
  },
  {
    field: 'userId',
    defaultVisibility: true,
  },
  {
    field: 'lot',
    defaultVisibility: false,
  },
  {
    field: 'expirationDate',
    defaultVisibility: false,
  },
  {
    field: 'serial',
    defaultVisibility: false,
  },
  {
    field: 'notes',
    defaultVisibility: true,
  },
]

export const MOVEMENT_REASONS: EntityStatusDesc<MovementReason>[] = [
  {
    code: 'INCREASE',
    name: 'Carico',
  },
  {
    code: 'DECREASE',
    name: 'Scarico',
  },
  {
    code: 'PACKING',
    name: 'Imballaggio',
  },
  {
    code: 'PICKING',
    name: 'Prelievo',
  },
  {
    code: 'TRANSFER',
    name: 'Trasferimento',
  },
  {
    code: 'RECEIVE',
    name: 'Accettazione',
  },
  {
    code: 'CANCELLATION',
    name: 'Annullamento',
  },
  {
    code: 'REPLENISH',
    name: 'Missione di abbassamento',
  },
]
