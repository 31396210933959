import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { Policy } from '../policies/policy.model'
import { Role } from '../roles'

export enum UserType {
  human = 'HUMAN',
  service = 'SERVICE',
}

export type UserSessionType = 'APP' | 'WEB'

export interface User extends Entity {
  /**
   * User type.
   */
  type: UserType
  /**
   * Sessions that this user can use.
   * Defaults to "all avilable sessions".
   */
  allowedSessions?: UserSessionType[]
  /**
   * Unique login username.
   */
  username: string
  /**
   * Hashed password. Service users don't have a password because they cannot login.
   */
  password?: string
  /**
   * The user's first name.
   */
  name?: string
  /**
   * The user's last name.
   */
  surname?: string
  /**
   * The user's email address.
   */
  email?: string
  /**
   * The user's personal settings
   */
  settings?: UserSettings
  /**
   * Associated security roles.
   */
  roles?: UserRole[]
  /**
   * Configured Personal Access Tokens (PATs).
   */
  tokens?: UserToken[]
  /**
   * Disable any user action.
   */
  isDisabled?: boolean
  /**
   * Time-based One-Time Password (TOTP) secret. If defined, the user has the 2FA enabled.
   */
  totpSecret?: string
  /**
   * Change password is required (anything else is blocked) when true.
   */
  forcePasswordChange?: boolean
  /**
   * Tenants this user can access.
   */
  tenants?: UserTenant[]
}

export interface UserSettings {
  showPriceWithTaxes?: boolean
  showDiscountPercentage?: boolean
  device?: UserDeviceSettings
  pickingLists?: UserPickingListsSettings
  goodsReceives?: UserGoodsReceivesSettings
  homeConfiguration?: HomeConfigurationSettings[]
}

export interface HomeConfigurationSettings {
  module: UserHomeModule
  statuses: string[]
}

export type UserHomeModule =
  | 'GOODS_RECEIVES'
  | 'PICKING_LISTS'
  | 'ORDERS'
  | 'PRODUCTS'
  | 'REPLENISHMENTS'
  | 'LOCATIONS'

export interface UserDeviceSettings {
  keepScreenActive?: boolean
  fullScreen?: boolean
  notificationsVolume?: number
  musicVolume?: number
}

export interface UserPickingListsSettings {
  autoLocationConfirmation?: boolean
  infoOnNextMission?: boolean
}

export interface UserGoodsReceivesSettings {
  packagesCounter?: boolean
}

export interface UserRole {
  /**
   * Role's identifier.
   */
  _id: string
  /**
   * Role's tenant (domain) identifier.
   */
  tenantId: string
}

export interface UserTenant {
  /**
   * Unique tenant identifier.
   */
  _id: string
  /**
   * Tenant's name.
   */
  name: string
  /**
   * Tenant access creation date.
   */
  createdAt: string
  /**
   * Tenant settings
   */
  settings?: UserTenantSettings
}

export interface UserTenantSettings {
  /**
   * PrintNode defaults
   */
  printDisabled?: boolean
  computerId?: string
  printers?: UserPrinters
  /**
   * Packaging default location
   */
  packagingLocationId?: string
  /**
   * The default views references
   * ORDERS | PRODUCTS | etc => viewId
   */
  defaultViews?: {
    [k: string]: string
  }

  warehouseId?: string
}

export interface UserToken {
  /**
   * Personal Access Token (PAT) identifier.
   */
  _id: string
  /**
   * JWT signature.
   */
  signature: string
  /**
   * Personal Access Token (PAT) creation date.
   */
  createdAt: string
}

export interface UserPrinters {
  label?: string
  a4?: string
  bluetooth?: BluetoothPrinter
}

export interface BluetoothPrinter {
  macAddress: string
  name: string
}

export enum UserTab {
  general = 'general',
  tenant = 'tenant',
  policies = 'policies',
  security = 'security',
}

export interface UsersListingData extends User {
  isGuest?: boolean
}

export interface UsersListingPage {
  data?: UsersListingData[]
  extData?: UsersListingExtData
  totalCount?: number
}

export interface UsersListingKeys {
  roleIds?: string[]
}

export interface UsersListingExtData {
  roles?: Role[]
}

export interface UserPolicy extends Policy {
  foreign: boolean
}

export type UserNotificationCode =
  | 'USER_INCOMPLETE'
  | 'USER_SAVED'
  | 'USER_DELETION'
  | 'USER_DELETED'
  | 'USER_EJECTION'
  | 'USER_EJECTED'
  | 'USER_INVITED'
  | 'USERS_ROLES_UPDATED'
  | 'TOKEN_CREATED'
  | 'TOKEN_DELETED'
  | 'PASSWORD_UPDATED'

export interface UserNotificationOptions {
  code: UserNotificationCode
  data?: UserNotificationData
}

export interface UserNotificationData {
  user?: User
  token?: string
}

export type UserField =
  | '_id'
  | 'type'
  | 'username'
  | 'name'
  | 'surname'
  | 'email'
  | 'tenants.settings.warehouseId'
  | 'tenants.settings.pickupPointId'
  | 'roles._id'
  | 'isGuest'
  | 'isDisabled'

export type UserSortField = 'name' | 'surname' | 'username'

export interface UserSearchParams extends QueryStringParams<UserSortField> {
  type?: UserType
  username?: string
  'username:ne'?: string
  'username:ct'?: string
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  surname?: string
  'surname:ne'?: string
  'surname:ct'?: string
  email?: string
  'email:ne'?: string
  'email:ct'?: string
  'tenants.settings.warehouseId'?: string | string[]
  'tenants.settings.warehouseId:ex'?: boolean
  'tenants.settings.pickupPointId'?: string | string[]
  'tenants.settings.pickupPointId:ex'?: boolean
  'roles._id'?: string | string[]
  'roles._id:ex'?: boolean
  isDisabled?: boolean
  isGuest?: boolean
}
