import { Injectable } from '@angular/core'
import {
  Currency,
  Tenant,
  TENANT_DEFAULT_CURRENCIES,
} from '@evologi/shared/data-access-api'
import { Store } from '@ngrx/store'

import { StoreState } from '../store.state'
import { AuthStoreService } from '../auth'
import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs'
import { selectCurrencies, selectCurrency } from './currencies.selectors'
import { loadCurrencies } from './currencies.actions'

@Injectable({
  providedIn: 'root',
})
export class CurrenciesStoreService {
  private authCheck$: Observable<boolean> = this.auth
    .isAuthenticated$()
    .pipe(take(1))
  private tenant$: Observable<Tenant> = this.authCheck$.pipe(
    switchMap((check) => (check ? this.auth.getTenant$() : of(undefined))),
    filter((tenant) => !!tenant),
    distinctUntilChanged(),
    // eslint-disable-next-line
    map((tenant) => tenant!),
  )

  constructor(
    private store: Store<StoreState>,
    private auth: AuthStoreService,
  ) {}

  /**
   * Get all currencies of a tenant
   * @returns the observable for load tenant currencies
   */
  getCurrencies$(): Observable<Currency[] | undefined> {
    return this.tenant$.pipe(
      switchMap((tenant) =>
        this.store.select(selectCurrencies(tenant._id)).pipe(
          tap(
            (currencies) =>
              !currencies &&
              this.store.dispatch(
                loadCurrencies({
                  tenantId: tenant._id,
                  params: {
                    code:
                      tenant?.catalog?.currencies &&
                      tenant?.catalog.currencies.length
                        ? tenant.catalog.currencies
                        : TENANT_DEFAULT_CURRENCIES,
                  },
                }),
              ),
          ),
        ),
      ),
    )
  }

  /**
   * Get a currency of a tenant
   * @param code - the currency code
   * @returns the observable for get a tenant currency
   */
  getCurrency$(code: string): Observable<Currency | undefined> {
    return this.tenant$.pipe(
      switchMap((tenant) =>
        this.store.select(selectCurrency(tenant._id, code)),
      ),
    )
  }
}
