import { EntityStatusAuth, EntityStatusDesc } from '../../models/entity.model'
import { Field } from '../../models/field.model'
import {
  FilterCollection,
  FilterOperator,
  FilterType,
} from '../../models/filter.model'
import { PermissionsScope } from '../policies/policy.model'
import {
  GoodsReceiveChangeStatusAction,
  GoodsReceiveField,
  GoodsReceivePermission,
  GoodsReceiveStatus,
  GoodsReceiveViewType,
} from './goods-receive.model'

export const GOODS_RECEIVE_SCOPE: PermissionsScope = 'goodsReceives'
export const GOODS_RECEIVE_PATH_KEY = 'goods-receive'

export const GOODS_RECEIVE_STATUSES_CLOSED = [GoodsReceiveStatus.closed]
export const GOODS_RECEIVE_STATUSES_ACTIVE = [
  GoodsReceiveStatus.confirmed,
  GoodsReceiveStatus.processing,
  GoodsReceiveStatus.processed,
]

export const GOODS_RECEIVE_STATUSES: EntityStatusDesc<GoodsReceiveStatus>[] = [
  {
    code: GoodsReceiveStatus.drafted,
    name: 'Bozza',
    primary: true,
    tooltip:
      'Arrivo merce in bozza, pronto per essere messo in attesa o confermato',
  },
  {
    code: GoodsReceiveStatus.pending,
    name: 'In attesa',
    primary: true,
    tooltip:
      "In attesa di CONFERMA da parte dell'ufficio, è ancora possibile effettuare delle modifiche.",
  },
  {
    code: GoodsReceiveStatus.confirmed,
    name: 'Confermato',
    primary: true,
    tooltip:
      'Arrivo merce confermato, non è possibile effettuare delle modifiche. Pronto per essere preso in carico dai magazzinieri.',
  },
  {
    code: GoodsReceiveStatus.processing,
    name: 'In lavorazione',
    primary: true,
    tooltip:
      'Arrivo merce preso in carico da parte del magazzino. Non può essere modificato.',
  },
  {
    code: GoodsReceiveStatus.processed,
    name: 'Processato',
    primary: true,
    tooltip:
      'Arrivo merce riscontrato da parte dei magazzinieri, le quantità sono state caricate a magazzino. ' +
      'Può essere rimesso in lavorazione o chiuso definitivamente',
  },
  {
    code: GoodsReceiveStatus.closed,
    name: 'Chiuso',
    primary: true,
    tooltip:
      'Arrivo merce definitivamente chiuso. Non è più possibile effettuare modifiche.',
  },
  {
    code: GoodsReceiveStatus.canceled,
    name: 'Annullato',
    primary: false,
    tooltip: 'Arrivo merce annullato.',
  },
]

export const GOODS_RECEIVE_ACTIONS: EntityStatusAuth<
  GoodsReceiveChangeStatusAction,
  GoodsReceivePermission
> = {
  DRAFTED: {
    permissions: [
      'addProducts',
      'editHeader',
      'editWarehouse',
      'checkInvoice',
      'saveOrder',
      'deleteOrder',
    ],
    actions: [
      {
        label: 'Metti in attesa',
        action: 'pend',
      },
      {
        label: 'Conferma arrivo merce',
        action: 'confirm',
      },
    ],
  },
  PENDING: {
    permissions: [
      'addProducts',
      'editHeader',
      'editWarehouse',
      'checkInvoice',
      'saveOrder',
      'cancelOrder',
    ],
    actions: [
      {
        label: 'Conferma arrivo merce',
        action: 'confirm',
      },
    ],
  },
  CONFIRMED: {
    permissions: ['cancelOrder'],
    actions: [
      {
        label: 'Metti in attesa',
        action: 'pend',
      },
    ],
  },
  PROCESSING: {
    permissions: ['editArrivalDate'],
    actions: [],
  },
  PROCESSED: {
    permissions: [
      'checkInvoice',
      'checkQuality',
      'refMandatory',
      'editShipping',
      'editArrival',
      'editArrivalDate',
      'saveOrder',
    ],
    actions: [
      {
        label: 'Rimetti in lavorazione',
        action: 'manage',
      },
      {
        label: 'Chiudi arrivo merce',
        action: 'close',
      },
    ],
  },
  CLOSED: {
    permissions: [
      'editArrivalDate',
      'editNotes',
      'saveOrder',
      'toggleAmountsEditable',
    ],
    actions: [],
  },
  CANCELED: {
    permissions: [],
    actions: [],
  },
}

export const GOODS_RECEIVE_FIELDS: Field<
  GoodsReceiveField,
  GoodsReceiveViewType
>[] = [
  {
    label: '# arrivo merce',
    field: 'header.orderNumber',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Data/Ora creazione',
    field: 'createdAt',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Data cons. prevista',
    field: 'header.deliveryDate',
  },
  {
    label: 'Magazzino',
    field: 'warehouseId',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.warehouses,
  },
  {
    label: 'Rif. ordine',
    field: 'header.rifDoc',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Rif. data',
    field: 'header.rifDate',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Presa in carico',
    field: 'header.arrivalDate',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Causale',
    field: 'header.reasonId',
  },
  {
    label: 'Fornitore',
    field: 'supplierId',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.suppliers,
  },
  {
    label: 'Pagamento',
    field: 'header.paymentType',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Totale dichiarato',
    field: 'header.totalOrderAmount',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Stato',
    field: 'status',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Righe',
    field: 'rowsCount',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Pezzi',
    field: 'productsCount',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Non conformità',
    field: 'rows.notCompliantQuality',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: '% Ord./Ric.',
    field: 'completionOrd',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: '% Dich./Ric.',
    field: 'completionInv',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Note',
    field: 'header.notes',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
  {
    label: 'Allegati',
    field: 'attachments',
    defaultViews: ['GOODS_RECEIVES_LISTING_MAIN'],
  },
]
