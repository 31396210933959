import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Context, ContextSearchParams } from './context.model'
import { Page } from '../../models/util.model'

const MODEL = 'contexts'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ContextsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Search contexts by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Context>> for search contexts
   */
  search$(
    params?: ContextSearchParams,
    returnAll = false,
  ): Observable<Page<Context>> {
    return this._search$<Context>(params, returnAll)
  }

  /**
   * List contexts by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Context[]> for list contexts
   */
  list$(
    params?: ContextSearchParams,
    returnAll = false,
  ): Observable<Context[]> {
    return this._list$<Context>(params, returnAll)
  }
}
