import { defaultsDeep, cloneDeep } from 'lodash'
import { Webhook } from './webhook.model'

// Initial states

const WEBHOOK_INITIAL_STATE: Partial<Webhook> = {
  event: undefined,
  url: undefined,
  secret: undefined,
}

// Init

export function initWebhook(webhook: Partial<Webhook> = {}): Webhook {
  return defaultsDeep(cloneDeep(webhook), WEBHOOK_INITIAL_STATE)
}
