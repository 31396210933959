import { Inject, Injectable, Optional } from '@angular/core'
import { MODAL_MANAGER, ModalManager } from '../../models/modal.model'
import {
  NOTIFICATION_MANAGER,
  NotificationManager,
} from '../../models/notification.model'
import { UserNotification } from './libs/user-notification.lib'
import {
  User,
  UserField,
  UserNotificationOptions,
  UserSearchParams,
  UsersListingExtData,
  UsersListingPage,
} from './user.model'
import {
  EMPTY,
  Observable,
  combineLatest,
  concat,
  concatMap,
  delay,
  from,
  last,
  map,
  of,
  switchMap,
} from 'rxjs'
import { UsersService } from './users.service'
import { RolesService } from '../roles'
import { Page } from '../../models/util.model'
import { parseUsersPageKeys } from './libs/user.lib'
import { AuthService } from '../auth'
import { difference } from 'lodash'
import { Permissions } from '../policies'

@Injectable({
  providedIn: 'root',
})
export class UsersRepository {
  private paginationLimit = 200

  constructor(
    private usersService: UsersService,
    private rolesService: RolesService,
    private authService: AuthService,
    @Inject(MODAL_MANAGER)
    @Optional()
    private modalManager?: ModalManager,
    @Inject(NOTIFICATION_MANAGER)
    @Optional()
    private notificationManager?: NotificationManager,
  ) {}

  /**
   * Search users and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search users and external data
   */
  searchUsers$(
    searchParams: UserSearchParams,
    fields: UserField[],
    tenantId: string,
    listingData?: UsersListingExtData,
  ): Observable<UsersListingPage> {
    return searchParams.limit !== undefined &&
      searchParams.limit > this.paginationLimit
      ? this._paginateUsers$(searchParams, fields, tenantId, listingData)
      : this._searchUsers$(searchParams, fields, tenantId, listingData)
  }

  /**
   * Invite a user and assign him roles
   * @param username - the username
   * @param roleIds - the role IDs
   * @returns the observable for invite and assign roles
   */
  inviteUser$(username: string, roleIds: string[]) {
    return this.authService
      .invite$(username)
      .pipe(switchMap((user) => this.assignRoles$(roleIds, [user._id])))
  }

  /**
   * Show user alert
   * @param options - the warehouse notification or notification options
   * @returns the observable for show the alert about warehouse
   */
  alert$(
    opts: UserNotification | UserNotificationOptions,
  ): Observable<boolean> {
    const notification = UserNotification.from(opts)
    return this.modalManager && notification.dialog
      ? this.modalManager.showDialog$(notification.dialog)
      : EMPTY
  }

  /**
   * Notify a message about a packing event
   * @param notification - the packing notification
   */
  notify(opts: UserNotification | UserNotificationOptions): void {
    const notification = UserNotification.from(opts)
    notification.dialog && this.notificationManager?.show(notification.dialog)
  }

  /**
   * Assign roles to users
   * @param roleIds - the roles IDs
   * @param userIds - the users IDs
   * @returns the observable to assign roles to users
   */
  assignRoles$(roleIds: string[], userIds: string[]): Observable<void | null> {
    if (!roleIds.length || !userIds.length) {
      return of(null)
    }

    return concat(
      ...roleIds.map((roleId) => this.rolesService.assign$(roleId, userIds)),
    ).pipe(last())
  }

  /**
   * Remove roles from users
   * @param roleIds - the roles IDs
   * @param userIds - the users IDs
   * @returns the observable to remove roles from users
   */
  removeRoles$(roleIds: string[], userIds: string[]): Observable<void | null> {
    if (!roleIds.length || !userIds.length) {
      return of(null)
    }

    return concat(
      ...roleIds.map((roleId) => this.rolesService.remove$(roleId, userIds)),
    ).pipe(last())
  }

  /**
   * Update user roles
   * @param user - the user to update
   * @param tenantId - the tenant ID
   * @param roleIds - the role IDs
   * @returns the observable for update the user roles
   */
  updateUserRoles$(
    user: User,
    tenantId: string,
    roleIds: string[],
  ): Observable<{ user: User; permissions: Permissions }> {
    const userRoles = (user.roles || [])
      .filter((r) => r.tenantId === tenantId)
      .map((r) => r._id)

    const roleToAssign = difference(roleIds, userRoles)
    const roleToRemove = difference(userRoles, roleIds)

    if (!roleToAssign.length && !roleToRemove.length) {
      return combineLatest({
        user: of(user),
        permissions: this.usersService.getPermissions$(user._id),
      })
    }

    return concat(
      ...roleToAssign.map((roleId) =>
        this.rolesService.assign$(roleId, [user._id]),
      ),
      ...roleToRemove.map((roleId) =>
        this.rolesService.remove$(roleId, [user._id]),
      ),
    ).pipe(
      last(),
      delay(6000),
      switchMap(() =>
        combineLatest({
          user: this.usersService.read$(user._id),
          permissions: this.usersService.getPermissions$(user._id),
        }),
      ),
    )
  }

  /**
   * Search users and external data
   * @param searchParams - the search params
   * @param fields - the user fields
   * @param tenantId - the tenant ID
   * @param listingData - the listing data
   * @returns the observable for load users and external data
   */
  private _searchUsers$(
    searchParams: UserSearchParams,
    fields: UserField[],
    tenantId: string,
    listingData?: UsersListingExtData,
  ) {
    return this.usersService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this._loadExtData$(page, fields, tenantId, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Paginate the user search
   * @param searchParams - the search params
   * @param fields - the fields needed
   * @param tenantId - the tenant ID
   * @param listingData - the listing data
   * @returns the observable for pagina the user search
   */
  private _paginateUsers$(
    searchParams: UserSearchParams,
    fields: UserField[],
    tenantId: string,
    listingData?: UsersListingExtData,
  ): Observable<UsersListingPage> {
    // Paginate requests
    const totalCount = searchParams.limit || this.paginationLimit
    const pagesCount = Math.ceil(totalCount / this.paginationLimit)

    const pages$ = from(
      Array.from({ length: pagesCount }, (_, i) => ({
        ...searchParams,
        limit: this.paginationLimit,
        offset: i * this.paginationLimit,
      })),
    )

    let response: UsersListingPage = {
      totalCount: 0,
      data: [],
    }
    let extData = listingData

    return pages$.pipe(
      concatMap((pageParams) =>
        this.searchUsers$(pageParams, fields, tenantId, extData).pipe(
          map((res) => {
            response = {
              totalCount: (response.totalCount || 0) + (res.totalCount || 0),
              data: [...(response.data || []), ...(res.data || [])],
              extData: { ...response.extData, ...res.extData },
            }
            extData = response.extData

            return response
          }),
        ),
      ),
      last(),
    )
  }

  /**
   * Load page external data
   * @param page - the users page
   * @param fields - the fields
   * @returns the observable for load external data
   */
  private _loadExtData$(
    page: Page<User>,
    fields: UserField[],
    tenantId: string,
    listingData?: UsersListingExtData,
  ): Observable<UsersListingExtData> {
    const extDataKeys = parseUsersPageKeys(page, tenantId)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('roles._id') && extDataKeys.roleIds) {
      obs$['roles'] = this.rolesService.store$(
        extDataKeys.roleIds,
        listingData?.roles,
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }
}
