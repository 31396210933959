import { QueryStringFilter } from '../../models/query-string.model'
import { ComputerSearchField, ComputerSearchParams } from './print-node.model'

export function computerFilterParams(
  params: ComputerSearchParams,
  filter: QueryStringFilter<ComputerSearchField>
): ComputerSearchParams {
  const searchParams: ComputerSearchParams = {}

  switch (filter.field) {
    case 'id':
      searchParams.id = filter.value
      break
    case 'name':
      searchParams.name = filter.value
      break
    case 'hostname':
      searchParams.hostname = filter.value
      break
    case 'createTimestamp':
      searchParams.createTimestamp = filter.value
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
