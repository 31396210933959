import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { ExecutionStatus, ExecutionType } from './execution.model'

export const EXECUTION_SCOPE: PermissionsScope = 'activities'

export const EXECUTION_TYPES: EntityStatusDesc<ExecutionType>[] = [
  {
    code: ExecutionType.manual,
    name: 'Manuale',
  },
  {
    code: ExecutionType.scheduled,
    name: 'Programmato',
  },
  {
    code: ExecutionType.internal,
    name: 'Una tantum',
  },
]

export const EXECUTION_STATUSES: EntityStatusDesc<ExecutionStatus>[] = [
  {
    code: ExecutionStatus.completed,
    name: 'Completato',
  },
  {
    code: ExecutionStatus.failed,
    name: 'Fallito',
  },
  {
    code: ExecutionStatus.pending,
    name: 'In attesa',
  },
  {
    code: ExecutionStatus.running,
    name: 'In lavorazione',
  },
  {
    code: ExecutionStatus.unknown,
    name: 'Senza risposta',
  },
]
