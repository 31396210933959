import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import {
  AttributeGroup,
  AttributeGroupSearchField,
  AttributeGroupSearchParams,
  AttributeGroupSortField,
} from './attribute-group.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../models/query-string.model'
import { Attribute } from '../attributes/attribute.model'

// Initial states

const GROUP_INITIAL_STATE: Partial<AttributeGroup> = {
  name: {},
  code: undefined,
  attributesOrdering: [],
  sortKey: 0,
}

// Init

export function initAttributeGroup(
  group: Partial<AttributeGroup> = {}
): AttributeGroup {
  return defaultsDeep(cloneDeep(group), GROUP_INITIAL_STATE)
}

// Attributes

export function checkAttributeGroupAttributes(
  group: AttributeGroup,
  attributes: Attribute[]
): AttributeGroup {
  const attrCodes = attributes.map((attr) => attr.code)
  return {
    ...group,
    attributesOrdering: uniq([
      ...group.attributesOrdering.filter((attr) => attrCodes.includes(attr)),
      ...attrCodes,
    ]),
  }
}

// Search

export function attributeGroupSortParams(
  params: AttributeGroupSearchParams,
  sort: QueryStringSort<AttributeGroupSortField>
): AttributeGroupSearchParams {
  const searchParams: AttributeGroupSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
    case 'sortKey':
      searchParams.sort = 'sortKey'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function attributeGroupFilterParams(
  params: AttributeGroupSearchParams,
  filter: QueryStringFilter<AttributeGroupSearchField>
): AttributeGroupSearchParams {
  const searchParams: AttributeGroupSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
    case 'sortKey':
      if (filter.operator === '=') {
        searchParams.sortKey = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
