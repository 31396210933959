import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable, from, timer, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { Porro } from 'porro'

@Injectable({
  providedIn: 'root',
})
export class ThrottleService {
  private bucket = new Porro({
    bucketSize: 10,
    interval: 500,
    tokensPerInterval: 2,
  })

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.bucket.throttle()).pipe(
      switchMap(() => next.handle(req)),
      catchError((error) => {
        // Check the throttle error
        if (error.message === 'Bucket overflow') {
          return timer(100).pipe(switchMap(() => this.intercept(req, next)))
        }

        return throwError(error)
      })
    )
  }
}
