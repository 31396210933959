import { createSelector } from '@ngrx/store'
import { StoreState } from '../store.state'

export const selectProductsState = (state: StoreState) => state.products

export const selectProductsScope = createSelector(
  selectProductsState,
  (state) => state.scope,
)

export const selectProductsToModify = createSelector(
  selectProductsState,
  (state) => state.productsToModify,
)

export const selectNextProductToModify = createSelector(
  selectProductsToModify,
  (productsToModify) => productsToModify?.find((p) => p.status === 'PENDING'),
)
