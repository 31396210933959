import { EntityStatusDesc } from '../../models/entity.model'
import { PermissionsScope } from '../policies/policy.model'
import { PackingListStatus } from './packing-list.model'

export const PACKING_LIST_SCOPE: PermissionsScope = 'packingLists'

export const PACKING_LIST_STATUSES: EntityStatusDesc<PackingListStatus>[] = [
  {
    code: PackingListStatus.processing,
    name: 'In lavorazione',
  },
  {
    code: PackingListStatus.packed,
    name: 'Imballata',
  },
  {
    code: PackingListStatus.printed,
    name: 'Chiusa',
  },
  {
    code: PackingListStatus.canceled,
    name: 'Annullata',
  },
]
