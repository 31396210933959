import { Pipe, PipeTransform } from '@angular/core'
import { uniq } from 'lodash'
import { Observable, of } from 'rxjs'
import { TagsService } from '../tags.service'
import { Tag } from '../tag.model'

@Pipe({
  name: 'tags',
})
export class TagsPipe implements PipeTransform {
  constructor(private tagsService: TagsService) {}

  transform(tags: string[]): Observable<Tag[] | undefined> {
    tags = uniq(tags)
    tags = tags.filter((t) => !!t && t.length)
    if (!tags.length) {
      return of(undefined)
    }

    return this.tagsService.list$({ value: tags }, true)
  }
}
