import { Pipe, PipeTransform } from '@angular/core'
import { uniq } from 'lodash'
import { Observable, of } from 'rxjs'
import { BrandsService } from '../brands.service'
import { Brand } from '../brand.model'

@Pipe({
  name: 'brands',
})
export class BrandsPipe implements PipeTransform {
  constructor(private brandsService: BrandsService) {}

  transform(brandIds: string[]): Observable<Brand[] | undefined> {
    brandIds = uniq(brandIds)
    brandIds = brandIds.filter((id) => !!id)
    if (!brandIds.length) {
      return of(undefined)
    }

    return this.brandsService.list$({ _id: brandIds }, true)
  }
}
