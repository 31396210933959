import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Devextreme libraries
import {
  DxBulletModule,
  DxChartModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxHtmlEditorModule,
  DxListModule,
  DxPieChartModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxTreeViewModule,
} from 'devextreme-angular'

// Pipes
import { CustomStorePaginationPipe } from './pipes/custom-store-pagination.pipe'
import { CustomStorePipe } from './pipes/custom-store.pipe'
import { DataGridFilterKeysPipe } from './pipes/datagrid-filter-keys.pipe'
import { DataGridIsFiltered } from './pipes/datagrid-is-filtered.pipe'
import { DataSourceIsLoaded } from './pipes/datasource-is-loaded.pipe'
import { DataSourcePipe } from './pipes/datasource.pipe'
import { DataSourceTotalCountPipe } from './pipes/datasource-totalcount.pipe'
import { DataSourceIsLoading } from './pipes/datasource-is-loading.pipe'

@NgModule({
  declarations: [
    // Pipes
    CustomStorePaginationPipe,
    CustomStorePipe,
    DataGridFilterKeysPipe,
    DataGridIsFiltered,
    DataSourceIsLoaded,
    DataSourceIsLoading,
    DataSourcePipe,
    DataSourceTotalCountPipe,
  ],
  imports: [
    CommonModule,

    // DevExtreme
    DxBulletModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxHtmlEditorModule,
    DxListModule,
    DxPieChartModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxTreeViewModule,
  ],
  exports: [
    // DevExtreme
    DxBulletModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxHtmlEditorModule,
    DxListModule,
    DxPieChartModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxTreeViewModule,

    // Pipes
    CustomStorePaginationPipe,
    CustomStorePipe,
    DataGridFilterKeysPipe,
    DataGridIsFiltered,
    DataSourceIsLoaded,
    DataSourceIsLoading,
    DataSourcePipe,
    DataSourceTotalCountPipe,
  ],
})
export class SharedUtilDevextremeModule {}
