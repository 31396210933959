import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export enum PickupPointKind {
  store = 'STORE',
}

export interface PickupPoint extends Entity {
  name: string
  kind: PickupPointKind
  channelIds?: string[]
  bannerId?: string
  address?: PickupPointAddress
  openings?: PickupPointOpening[]
  location?: PickupPointLocation
  notes?: string
  isActive: boolean
}

export interface PickupPointAddress {
  address1: string
  address2?: string
  city: string
  postalCode: string
  countryCode: string
  province?: string
  phone?: string
  email?: string
}

export interface PickupPointOpening {
  day?: string
  from?: string
  to?: string
  from2?: string
  to2?: string
}

export interface PickupPointCoordinates {
  lat: string
  lng: string
}

export interface PickupPointLocation {
  type: 'Point'
  coordinates: number[]
}

export type PickupPointSearchField =
  | '_id'
  | 'name'
  | 'externalId'
  | 'channelIds'
  | 'channelIds:ex'
  | 'bannerId'
  | 'bannerId:ex'
  | 'address.address1'
  | 'address.city'
  | 'address.countryCode'
  | 'address.province'
  | 'location.lat'
  | 'location.lng'
  | 'location.distance'
  | 'location.bounds'
  | 'isActive'

export interface PickupPointSearchParams extends QueryStringParams {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  externalId?: string
  'externalId:ne'?: string
  'externalId:ct'?: string
  channelIds?: string | string[]
  'channelIds:ex'?: boolean
  bannerId?: string | string[]
  'bannerId:ex'?: boolean
  'address.address1'?: string
  'address.address1:ne'?: string
  'address.address1:ct'?: string
  'address.city'?: string
  'address.city:ne'?: string
  'address.city:ct'?: string
  'address.countryCode'?: string[]
  'address.province'?: string
  'address.province:ne'?: string
  'address.province:ct'?: string
  'location.lat'?: number
  'location.lng'?: number
  'location.distance'?: number
  isActive?: boolean
}

export type PickupPointsTab = 'general' | 'additionalInfo'
