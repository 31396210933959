import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

// Configuration
import { SDKConfiguration, SDK_CONFIGURATION } from './models/config.model'

// Interceptors
import { ThrottleInterceptor } from './interceptors/throttle.interceptor'

// Pipes
import { AbcClassPipe } from './pipes/abc-class.pipe'
import { AddressPipe } from './pipes/address.pipe'
import { StatusTimePipe } from './pipes/status-time.pipe'

// Services
import { ThrottleService } from './services/throttle.service'

// Storage
import { SessionStorage, LocalStorage, CookieStorage } from './modules/storage'
import { StorageBrowser } from './modules/storage/storage.browser'
import { SessionBrowser } from './modules/storage/session.browser'
import { CookieBrowser } from './modules/storage/cookie.browser'

// Strategies
import { RouteTitleStrategy } from './strategies/route-title.strategy'
import { RouteUsageStrategy } from './strategies/route-usage.strategy'
import { RouteReuseStrategy, TitleStrategy } from '@angular/router'

@NgModule({
  imports: [CommonModule],
  declarations: [AbcClassPipe, AddressPipe, StatusTimePipe],
  exports: [AbcClassPipe, AddressPipe, StatusTimePipe],
})
export class SharedDataAccessApiModule {
  static forRoot(
    configuration: SDKConfiguration,
  ): ModuleWithProviders<SharedDataAccessApiModule> {
    return {
      ngModule: SharedDataAccessApiModule,
      providers: [
        ThrottleService,

        // Pipes
        AbcClassPipe,
        AddressPipe,
        StatusTimePipe,

        // Configuration
        {
          provide: SDK_CONFIGURATION,
          useValue: configuration,
        },

        // Interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ThrottleInterceptor,
          multi: true,
        },

        // Routing
        { provide: RouteReuseStrategy, useClass: RouteUsageStrategy },
        { provide: TitleStrategy, useClass: RouteTitleStrategy },

        // Storage
        { provide: SessionStorage, useClass: SessionBrowser },
        { provide: LocalStorage, useClass: StorageBrowser },
        { provide: CookieStorage, useClass: CookieBrowser },
      ],
    }
  }
}
