import { uniq } from 'lodash'
import { Page } from '../../../models/util.model'
import {
  SupplierOrder,
  SupplierOrdersListingKeys,
} from '../supplier-order.model'

/**
 * Parse supplier order page keys
 * @param page - the supplier order page
 * @returns the page updated
 */
export function parseSupplierOrderPageKeys(
  page: Page<SupplierOrder>
): SupplierOrdersListingKeys {
  return page.data.reduce<SupplierOrdersListingKeys>(
    (acc, r) => parseSupplierOrderKeys(r, acc),
    {}
  )
}

/**
 * Parse supplier order keys
 * @param goodsReceive - the supplier order
 * @param keys - the keys already loaded
 */
export function parseSupplierOrderKeys(
  goodsReceive: SupplierOrder,
  keys: SupplierOrdersListingKeys = {}
): SupplierOrdersListingKeys {
  const warehouseIds = uniq([
    ...(keys['warehouseIds'] || []),
    ...(goodsReceive.warehouseId ? [goodsReceive.warehouseId] : []),
  ])
  const supplierIds = uniq([
    ...(keys['supplierIds'] || []),
    ...(goodsReceive.supplierId ? [goodsReceive.supplierId] : []),
  ])

  return {
    warehouseIds,
    supplierIds,
  }
}
