import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import {
  GoodsReceiveField,
  GoodsReceiveSearchParams,
  GoodsReceiveSortField,
} from '../goods-receive.model'

export function goodsReceiveSortParams(
  params: GoodsReceiveSearchParams,
  sort: QueryStringSort<GoodsReceiveSortField>,
): GoodsReceiveSearchParams {
  const searchParams: GoodsReceiveSearchParams = {}

  switch (sort.field) {
    case 'header.orderNumber':
      searchParams.sort = 'header.orderNumber'
      searchParams.order = sort.order
      break
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'header.date':
      searchParams.sort = 'header.date'
      searchParams.order = sort.order
      break
    case 'header.rifDate':
      searchParams.sort = 'header.rifDate'
      searchParams.order = sort.order
      break
    case 'header.arrivalDate':
      searchParams.sort = 'header.arrivalDate'
      searchParams.order = sort.order
      break
    case 'header.deliveryDate':
      searchParams.sort = 'header.deliveryDate'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function goodsReceiveFilterParams(
  params: GoodsReceiveSearchParams,
  filter: QueryStringFilter<GoodsReceiveField>,
): GoodsReceiveSearchParams {
  const searchParams: GoodsReceiveSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams['status'] = filter.value
      }
      break
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams.warehouseId = filter.value
      }
      break
    case 'supplierId':
      if (filter.value !== null) {
        searchParams.supplierId = filter.value
      }
      break
    case 'locationId':
      if (filter.value !== null) {
        searchParams.locationId = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams['createdAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'header.orderNumber':
      if (filter.operator === '=') {
        searchParams['header.orderNumber'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.orderNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.orderNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.orderNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.orderNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.orderNumber:ne'] = filter.value
      }
      break
    case 'header.date':
      if (filter.operator === '=') {
        searchParams['header.date'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.date:ne'] = filter.value
      }
      break
    case 'header.transport':
      if (filter.value !== null) {
        searchParams['header.transport'] = filter.value
      }
      break
    case 'header.paymentType':
      if (filter.value !== null) {
        searchParams['header.paymentType'] = filter.value
      }
      break
    case 'header.currency':
      if (filter.value !== null) {
        searchParams['header.currency'] = filter.value
      }
      break
    case 'header.deliveryDate':
      if (filter.operator === '=') {
        searchParams['header.deliveryDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.deliveryDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.deliveryDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.deliveryDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.deliveryDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.deliveryDate:ne'] = filter.value
      }
      break
    case 'header.arrivalDate':
      if (filter.operator === '=') {
        searchParams['header.arrivalDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.arrivalDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.arrivalDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.arrivalDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.arrivalDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.arrivalDate:ne'] = filter.value
      }
      break
    case 'header.rifDate':
      if (filter.operator === '=') {
        searchParams['header.rifDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.rifDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.rifDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.rifDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.rifDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifDate:ne'] = filter.value
      }
      break
    case 'header.rifDoc':
      if (filter.operator === '=') {
        searchParams['header.rifDoc'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifDoc:ne'] = filter.value
      }
      break
    case 'header.reasonId':
      if (filter.value !== null) {
        searchParams['header.reasonId'] = filter.value
      }
      break
    case 'rows.notCompliantQuality':
      if (filter.value === true) {
        searchParams['rows.notCompliantQuality:gt'] = 0
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
