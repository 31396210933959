import { createReducer, on } from '@ngrx/store'
import { Attribute, AttributeGroup } from '@evologi/shared/data-access-api'

import * as AttributesActions from './attributes.actions'
import { AttributesState, AttributesTenant } from './attributes.state'
import { parseItems } from '../../libs/state.lib'

const initialState: AttributesState = {}

export const attributesReducers = createReducer(
  initialState,

  // Lifecycle

  on(AttributesActions.destroyAttributes, (): AttributesState => initialState),

  // Setters

  on(
    AttributesActions.setGroups,
    (
      state: AttributesState,
      action: {
        tenantId: string
        groups: AttributeGroup[]
        totalCount?: number
      },
    ): AttributesState => {
      const tenantAttributes: AttributesTenant = state[action.tenantId] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantAttributes,
          groups: parseItems<AttributeGroup>(
            action.groups,
            tenantAttributes.groups,
          ),
          groupsTotalCount: action.totalCount,
        },
      }
    },
  ),

  on(
    AttributesActions.setGroupAttributes,
    (
      state: AttributesState,
      action: {
        tenantId: string
        group: AttributeGroup
        attributes: Attribute[]
      },
    ): AttributesState => {
      const tenantAttributes: AttributesTenant = state[action.tenantId] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantAttributes,
          groups: {
            ...(tenantAttributes.groups || {}),
            [action.group._id]: action.group,
          },
          attributes: parseItems<Attribute>(
            action.attributes,
            tenantAttributes.attributes,
          ),
        },
      }
    },
  ),

  on(
    AttributesActions.setAttributes,
    (
      state: AttributesState,
      action: {
        tenantId: string
        attributes: Attribute[]
        totalCount?: number
      },
    ) => {
      const tenantAttributes: AttributesTenant = state[action.tenantId] || {}

      return {
        ...state,
        [action.tenantId]: {
          ...tenantAttributes,
          attributes: parseItems<Attribute>(
            action.attributes,
            tenantAttributes.attributes,
          ),
          attributesTotalCount: action.totalCount,
        },
      }
    },
  ),

  on(
    AttributesActions.setAttribute,
    (
      state: AttributesState,
      action: { attribute: Attribute },
    ): AttributesState => {
      const tenantId = action.attribute.tenantId
      const tenantAttributes: AttributesTenant = state[tenantId] || {}

      return {
        ...state,
        [tenantId]: {
          ...tenantAttributes,
          attributes: {
            ...(tenantAttributes.attributes || {}),
            [action.attribute._id]: action.attribute,
          },
        },
      }
    },
  ),

  on(
    AttributesActions.unsetAttribute,
    (
      state: AttributesState,
      action: { attribute: Attribute },
    ): AttributesState => {
      const tenantId = action.attribute.tenantId
      const tenantAttributes: AttributesTenant = state[tenantId] || {}
      const attributes = tenantAttributes.attributes

      if (!attributes) {
        return state
      }

      const newAttributes = Object.keys(attributes).reduce(
        (acc, id) =>
          id !== action.attribute._id ? { ...acc, [id]: attributes[id] } : acc,
        {},
      )

      return {
        ...state,
        [tenantId]: {
          ...tenantAttributes,
          attributes: newAttributes,
        },
      }
    },
  ),
)
