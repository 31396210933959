import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { SUPPLIER_ORDER_FIELDS } from '../supplier-order.const'
import {
  SupplierOrderSearchParams,
  SupplierOrderSearchField,
  SupplierOrderSortField,
  SupplierOrderViewType,
  SupplierOrderField,
} from '../supplier-order.model'

export function supplierOrderSortParams(
  params: SupplierOrderSearchParams,
  sort: QueryStringSort<SupplierOrderSortField>
): SupplierOrderSearchParams {
  const searchParams: SupplierOrderSearchParams = {}

  switch (sort.field) {
    case 'createdAt':
      searchParams.sort = 'createdAt'
      searchParams.order = sort.order
      break
    case 'header.orderNumber':
      searchParams.sort = 'header.orderNumber'
      searchParams.order = sort.order
      break
    case 'header.date':
      searchParams.sort = 'header.date'
      searchParams.order = sort.order
      break
    case 'header.rifDate':
      searchParams.sort = 'header.rifDate'
      searchParams.order = sort.order
      break
    case 'header.deliveryDate':
      searchParams.sort = 'header.deliveryDate'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function supplierOrderFilterParams(
  params: SupplierOrderSearchParams,
  filter: QueryStringFilter<SupplierOrderSearchField>
): SupplierOrderSearchParams {
  const searchParams: SupplierOrderSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'createdAt':
      if (filter.operator === '=') {
        searchParams['createdAt'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['createdAt:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['createdAt:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['createdAt:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['createdAt:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['createdAt:ne'] = filter.value
      }
      break
    case 'status':
      if (filter.value !== null) {
        searchParams['status'] = filter.value
      }
      break
    case 'warehouseId':
      if (filter.value !== null) {
        searchParams.warehouseId = filter.value
      }
      break
    case 'supplierId':
      if (filter.value !== null) {
        searchParams.supplierId = filter.value
      }
      break
    case 'isPartial':
      searchParams.isPartial = filter.value
      break
    case 'productsCount':
      if (filter.operator === '=') {
        searchParams['productsCount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['productsCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['productsCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['productsCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['productsCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['productsCount:ne'] = filter.value
      }
      break
    case 'rowsCount':
      if (filter.operator === '=') {
        searchParams['rowsCount'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['rowsCount:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['rowsCount:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['rowsCount:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['rowsCount:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['rowsCount:ne'] = filter.value
      }
      break
    case 'header.orderNumber':
      if (filter.operator === '=') {
        searchParams['header.orderNumber'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.orderNumber:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.orderNumber:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.orderNumber:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.orderNumber:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.orderNumber:ne'] = filter.value
      }
      break
    case 'header.date':
      if (filter.operator === '=') {
        searchParams['header.date'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.date:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.date:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.date:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.date:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.date:ne'] = filter.value
      }
      break
    case 'header.rifDate':
      if (filter.operator === '=') {
        searchParams['header.rifDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.rifDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.rifDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.rifDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.rifDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifDate:ne'] = filter.value
      }
      break
    case 'header.rifOrder':
      if (filter.operator === '=') {
        searchParams['header.rifOrder'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.rifOrder:ne'] = filter.value
      }
      break
    case 'header.deliveryDate':
      if (filter.operator === '=') {
        searchParams['header.deliveryDate'] = filter.value
      } else if (filter.operator === '<') {
        searchParams['header.deliveryDate:lt'] = filter.value
      } else if (filter.operator === '<=') {
        searchParams['header.deliveryDate:le'] = filter.value
      } else if (filter.operator === '>') {
        searchParams['header.deliveryDate:gt'] = filter.value
      } else if (filter.operator === '>=') {
        searchParams['header.deliveryDate:ge'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['header.deliveryDate:ne'] = filter.value
      }
      break
    case 'header.currency':
      if (filter.value !== null) {
        searchParams['header.currency'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function getSupplierOrdersListingFields(
  viewType: SupplierOrderViewType
): SupplierOrderField[] {
  return SUPPLIER_ORDER_FIELDS.filter((f) =>
    f.defaultViews?.includes(viewType)
  ).map((f) => f.field)
}
