export const LANG_EN_NAME = 'en'

export const LANG_EN_TRANS = {
  INVALID_OBJECT_ID: 'ID documento non valido',
  MISSING_OBJECT_ID: 'ID documento non presente',
  EMPTY_DOCUMENT: 'Documento vuoto',
  DOCUMENT_VALIDATION_ERROR: 'Documento non valido',
  MISSING_UNIQUE_KEY: 'Chiave univoca mancante',
  MISSING_AUTH_DATA: 'Autenticazione mancante',
  CREDENTIALS_NOT_VALID: 'Credenziali non valide',
  MISSING_MANDATORY_PRAMETER: 'Dati obbligatori mancanti',
  TOKEN_NOT_VALID: 'Autenticazione non valida',
  DOCUMENT_NOT_FOUND: 'Documento non trovato',
  INCOMPATIBLE_CHANGE_STATUS: 'Azione non valida',
  DUPLICATED_DOCUMENT: 'Documento duplicato',
  REMOTE_UNREACHABLE: 'Servizio momentaneamente non disponibile',
  REMOTE_API_ERROR: 'Errore API esterna',
  GENERIC_ERROR: 'Errore generico',
  MALFORMED_BODY: 'Richiesta non valida',
  ERROR_CALLING_EXTERNAL_API: 'Errore API corriere',
  'Something went wrong. Try again later.':
    'Ops, qualcosa è andato storto. Riprova più tardi',
  'A classification with this name already exists':
    'Classificazione già esistente',
  'Can add orders from closed bordereau':
    'Non è possibile aggiungere ordini ad un bordereau in stato SPEDITO',
  'Can remove orders from closed bordereau':
    'non è possibile rimuovere ordini da un bordereau in stato SPEDITO',
  'Cannot ship more quantity than ordererd':
    'Non puoi spedire più quantità di quella ordinata',
  'Cant add specified product or same location is already set':
    'Impossibile aggiungere prodotto o locazione già presente',
  'Cant change status': 'Impossibile cambiare stato',
  'Cant delete closed bordereau':
    'Non è possibile eliminare bordereau in stato SPEDITO',
  'Cant find generic carrier for this warehouse ':
    'Corriere generico non settato per questo magazzino',
  'Cant find shipment for the specified order':
    'Nessuna spedizione trovata per questo ordine',
  'Cant find specifed product': 'Prodotto non trovato',
  'Cant find specified location': 'Locazione non trovata',
  'Cant find specified order': 'Ordine non trovato',
  'Cant find specified payment type':
    'Impossibile trovare il pagamento specificato',
  'Cant find specified product': 'Prodotto non trovato',
  'Cant find valid user role': 'Ruole utente non valido',
  'Cant find valid user session': 'Sessione utente non valida',
  'Document is not unique': 'Il documento non è univoco',
  'Error during token decoding': 'Errore durante la decodifica del token',
  'ID list is not an array': 'ID list non è un array',
  'Invalid attribute ID': 'ID attributo non valido',
  'Invalid ID': 'ID non valido',
  'Invalid location label': 'Etichetta locazione non valida',
  'Invalid mission ID': 'ID missione non valido',
  'Invalid mission status update': 'Aggiornato stato missione non valido',
  'Invalid options': 'Opzione non valida',
  'Invalid order ID': 'ID ordine non valido',
  'Invalid picking list ID': 'ID picking list non trovato',
  'Invalid product ID': 'ID prodotto non valido',
  'Invalid reason ID': 'ID causale non valido',
  'Invalid request body': 'Invalid request body',
  'Invalid resource ID': 'ID risorsa non valido',
  'Invalid tag ID': 'Tag ID non valido',
  'Invalid value': 'Valore non valido',
  'Invalid value entry': 'Valore non valido',
  'KITs cant have location': 'KIT non possono avere locazioni',
  'Login failed': 'Login fallito',
  'Login failed: provide a username and password':
    'Login fallito: inserire nome utente e password',
  'Malformed mission': 'Missione malformata',
  'Malformed picking list data': 'Dati picking list malformati',
  'Malformed picking list orders data': 'Dati ordini picking list malformati',
  'Missing auth token': 'Token di autorizzazione mancante',
  'Missing brands id': 'ID marca non trovato',
  'Missing categories id': 'ID categoria mancante',
  'Missing mandatory param': 'Parametro obblgiatorio mancante',
  'Missing mandatory parameters': 'Manca un parametro obbligatorio',
  'Missing manufacturers id': 'Manca ID produttore',
  'Missing product ID': 'Manca ID del prodotto',
  'Missing query parameters': 'Parametro query mancante',
  'Missing tote code': 'Manca codice UDC',
  'Missing user': 'Utente mancante',
  'Missing value': 'Valore mancante',
  'Missing value label': 'Manca valore etichetta',
  'Mission product not found': 'Prodotto non trovato per la missione',
  'More than one product found': 'Prodotti multipli trovati',
  'Movements must be under transaction':
    'I movimenti devono essere contenuti in una transaction',
  'No active session found': 'Nessuna sessione attiva',
  'No available actions': 'Non ci sono azioni disponibili.',
  'No product found': 'Prodotto non trovato',
  'Order is not confirmed': "L'ordine non è in stato CONFERMATO",
  'Order not found': 'Ordine non trovato',
  'Packing list is not in processing status':
    'La packing list non è IN LAVORAZIONE',
  'Picking list not in processing status':
    'La picking list non è IN LAVORAZIONE',
  'Pickinglist orders property must be an array':
    'La proprietà orders nelle pickinglist deve essere un array',
  'Product not found': 'Prodotto non trovato',
  'Product to move does not exists': 'Prodotto da trasferire non esistente',
  'Provide +|- to use with $inc operator':
    "Utilizza +|-|= per usare l'operatore  $inc",
  'Provide +|-|= to use with $inc operator':
    "Utilizza +|-|= per usare l'operatore  $inc",
  'Provide a valid username': 'Inserisci un nome utente valido',
  'Qty to move is negative': 'Quantità da trasferire negativa',
  'Qty to move is not an integer':
    'La quantità da trasferiere non è un intero positivo',
  'Quantity must be a positive integer':
    'La quantità deve essere un numero intero positivo',
  'Quantity must me an integer':
    'Quantità deve essere un numero intero positivo',
  'Quantity must me positive': 'Quantità deve essere un numero positivo',
  'Quantity parameter is mandatory': 'La quantità è obbligatoria',
  'Reason not compatible with movement':
    'Causale non compatibile con il movimento',
  'Reason not found': 'Causale di movimentazione non trovata',
  'Select options not an array': '',
  'Some nodes are not reachable from the starting point':
    'Alcuni nodi non sono raggiungibili dal punto di partenza',
  'Source and target location are the same':
    'Stessa locazione di partenza e destinazione',
  'Source location does not exists': 'La locazione di partenza non esiste',
  'Source location not found': 'Locazione di partenza non trovata',
  'Starting location is mandatory for building pickpath ':
    'Locazione di partenza obbligatoria per generare il percorso di picking',
  'StartVertex is missing from graph': 'StartVertex mancante',
  'Target attribute not found': '',
  'Target classification not found': '',
  'Target location does not exists': 'La locazione di destinazione non esiste',
  'Target location not found': 'Locazione di destinazione non trovata',
  'Target order not found': '',
  'Target packing list not found': '',
  'Target picking list not found': 'Picking list non trovata',
  'Target product location has not picked items': '',
  'Target product not found': '',
  'Target tag not found': '',
  'Target warehouse not found': 'Magazzino di destinazione non trovato',
  'The attribute value is an empty string': 'Inserire un valore valido',
  'The attribute value is not a boolean': 'Il valore non è un booleano',
  'The attribute value is not a number':
    'Il valore inserito deve essere un numero',
  'The attribute value is not a string': 'Il valore non è una stringa',
  'The attribute value is not an integer':
    'Il valore inserito deve essere un intero',
  'The attribute value is too long': 'Valore attributo troppo lungo',
  'The attribute value is too short': 'Valore attributo troppo corto',
  'The attribute value must be a finite number':
    'Il valore deve essere un numero',
  'The old password provided is wrong':
    'La vecchia password inserita non è valida',
  'The parent location must be root':
    'La locazione padre deve essere la radice',
  'The picked qty must be an integer':
    'La quantità deve essere un  numero intero',
  'The picked qty must be positive or zero':
    'La quantità deve essere un numero intero positivo',
  'The picking list is not in PENDING status':
    'La picking list non è in stato DA PRELEVARE',
  'The source location does not contains this product':
    'Prodotto non presente nella locazione di partenza',
  'The source location does not have enough qty to move':
    'Quantità da trasferire maggiore della giacenza',
  'The source location is reserved':
    'Sono presenti prodotti riservati ad una picking list',
  'The value have to be an object': 'Il valore deve essere un oggetto',
  'There are attribute values': 'Sono presenti dei valori per questo attributo',
  'There are child entities connected to this classification':
    'Classificazioni figlie associate',
  'There are inventory items that are assigned to this warehouse':
    'Sono presenti prodotti assegnati a questo magazzino',
  'There are locations with connected products':
    'Il prodotto è presente in alcuni locazioni',
  'There are missing picking locations Check warehouse structure':
    'Locazioni picking mancanti. Controllare struttura magazzino',
  'There are orders that contains this product':
    'Il prodotto è presente in alcuni ordini',
  'There are orders with this payment type':
    'Sono presenti ordini con il metodo di pagamento specificato',
  'There are prodcuts that uses this brand':
    'Prodotti associati a questo brand',
  'There are prodcuts that uses this manufacturer':
    'Prodotti associati a questo produttore',
  'There are products connected to this classification':
    'Prodotti associati a questa classificazione',
  'There are users items that are assigned to this warehouse':
    'Sono presenti utenti assegnati a questo magazzino',
  'There is already a brand with that name': 'Marca già presente',
  'There is already a manufacturer with that name': 'Produttore già presente',
  'There is already an active session': 'Sessione utente già attiva',
  'There is already an attribute with that code':
    'È già presente un attributo con questo codice',
  'There is more than one shipment for this order':
    'È presente più di una spedizione per questo ordine',
  'There is no sufficient stock left': "Non c'è sufficiente giacenza",
  'There is not a currently active packing list':
    'Non ci sono packing list attive',
  'This action is not available': 'Azione non disponibile',
  'This order is inside a picking list':
    "L'ordine è inserito in una packing list",
  'This packing list already exists': 'Packing list già esistente',
  'This tenant doesnt have any ftp set':
    'Questo tenant non ha profili FTP associati',
  'This tenant hasnt any carriers':
    'Il tenant non ha nessun corriere impostato',
  'This tote is used by another picking list':
    "UDC utilizzata per un'altra picking list",
  'This value is already assigned': 'Valore già assegnato',
  'Token not valid.': 'Token non valido',
  'Too much qty packed': 'Quantità imballata maggiore della richiesta',
  'Tote used by another order': 'UDC utilizzata per un altro ordine',
  'Unable to a valid action': '',
  'Unable to delete this category': 'Impossibile eliminare categoria',
  'Unable to delete this tag': 'Non è possibile cancellare il tag',
  'Unable to fetch product data': 'Impossibile reperire informazioni prodotto',
  'Unable to fetch product info':
    'Impossibile ottenere informazioni del prodotto',
  'Unable to find a valid mission for the current order':
    "Impossibile trovare missioni valide per l'ordine corrente",
  'Unable to find a valid picking list':
    'Impossibile trovare una picking list valida',
  'Unable to find a valid picking list to update':
    'Impossibile trovare una picking list da aggiornare',
  'Unable to find Amazon and eBay channels for the current tenant':
    'Impossibile trovare canale Amazon e eBay per questo tenant',
  'Unable to find the current mission': 'Impossibil trovare missione corrente',
  'Unable to find the current order': 'Impossibile trovare ordine corrente',
  'Unable to find the Ecommerce channel for the current tenant':
    'Impossibile trovare canale Ecommerce per questo tenant',
  'Unable to find the packed order': "Impossibile trovare l'ordine imballato",
  'Unable to find the packing list to update':
    'Impossibile trovare packing list da aggiornare',
  'Unable to find the picking list': 'Picking list non trovata',
  'Unable to find the product': 'Prodotto non trovato',
  'Unable to find the target tote': 'Impossibile trovare UDC di destinazione',
  'Unable to find the tote inside product locations':
    'UDC non trovata nelle locazioni prodotto',
  'Unable to load tote information': 'Impossibile reperire informazioni UDC',
  'Unexpected quantity to pack': 'Quantità da imballare sbagliata',
  'Unexpected tenant': 'Unexpected tenant',
  'Unknown action': 'Azione sconosciuta',
  'User not found': 'Utente non trovato',
  'User permissions are too low to access this resource':
    'Permessi utenti insufficienti per accedere a questa risorsa',
  'Wrong password provided': 'Password errata',
  'Wrong product': 'Prodotto errato',
  'Wrong tote': 'UDC errata',
  'Xlsx file header doesnt match the template file':
    'Il file non corrisponde con il template di importazione',
  'You are not authorized to access this resource':
    'Accesso alla risorsa non autorizzato',
  'You can only test when the picking list is in PENDING status':
    'La simulazione evasione è attiva solo in stato DA PRELEVARE',
  'You cannot change the attribute code':
    'Non è possibile cambiare il codice attributo',
  'You cannot change the attribute kind':
    'Non è possibile cambiare il tipo di attributo',
  'You cannot make an existing attribute unique':
    'Non è possibile settare un attributo esistente come univoco',
  'You cannot skip the last mission': "Non puoi saltare l'ultima missione",
  'You cannot skip the last order': "Non puoi saltare l'ultimo ordine",
  'You have to close the current packing list':
    "Devi concludere l'imballaggio della packinglist corrente prima di iniziare con un'altra",
  'You have to specify a custom reason':
    'Specificare causale di movimentazione',
  'Not Found': 'Elemento non trovato',
  'There are orders that contains this product.':
    'Ci sono ordini che contengono questo prodotto',
  'Missing pickinglocation from the warehouse.':
    'Ubicazioni di prelievo mancanti per questo magazzino',
  'Missing required attribute': 'Configurazioni obbligatorie mancanti',
  'rcpt_prov: deve essere lungo al massimo 2 caratteri':
    'Provincia indirizzo di spedizione non valido',
  'dmo-availability': 'DMO export DISPONIBILITA',
  'dmo-bolced': 'DMO import BOLCED',
  'dmo-carced': 'DMO export CARCED',
  'dmo-invced': 'DMO export INVCED',
  'dmo-magart': 'DMO import MAGART',
  'dmo-mapping': 'DMO import MAPPINGSKU-CART',
  'dmo-ordcli': 'DMO Export ORDCLI',
  'dmo-orders': 'DMO import ORDINI Ecommerce',
  'dmo-tracking': 'DMO export TRACKING',
  'dmo-status': 'DMO export STATUS ordini',
  'trilab-dropship': 'Ordini Droshipping',
  'trilab-magento-shipments': 'Magento shipments',
  'trilab-store360-orders': 'Ordini Store360',
  'trilab-magento-orders': 'Ordini Magento',
  'trilab-store360-shipments': 'Store360 shipments',
  'trilab-magento-availability': 'Disponibilità ecommerce',
  'trilab-excel-availability': 'Catalogo Excel',
  'amica-category': 'Export CATEGORY',
  'amica-eavs': 'Export EAVS',
  'amica-ite': 'Export ITE',
  'amica-prices': 'Export PRICES',
  'amica-stock': 'Export STOCK',
  'bene-exp-legacy': 'Export prodotti',
  'bene-imp-legacy': 'Import ordini',
  'Unable to pack this product': 'Non è possibile imballare questo prodotto',
  ORDERS_FILE: 'File ordini',
  ROWS_FILE: 'File righe',
  FTP_HOST: 'Host',
  FTP_PORT: 'Porta',
  FTP_USERNAME: 'Username',
  FTP_PASSWORD: 'Password',
  FTP_ACTION: 'Azione FTP',
  SOURCE_FILE: 'Nome file:',
  SOURCE_DIRECTORY: 'Cartella sorgente',
  SOURCE_PATTERN: 'Pattern sorgente',
  TARGET_FILE: 'Nome file destinazione',
  TARGET_DIRECTORY: 'Cartella destinazione',
  TARGET_PATTERN: 'Pattern destinazione',
  RECEIVER_MAIL: 'Email',
  'Unable to close the order if there is at least one unopened goods arrival':
    "Non è possibile chiudere l'ordine a fornitore se sono presenti degli arrivi merce ancora in lavorazione",
  'Found a product location not castable':
    'Prodotto impegnato in pickinglist o in attesa di imballaggio',
  "Can't find location on the product.":
    'Ubicazione non trovata per questo prodotto',
  'Product is not managed by batches.': 'Il prodotto non è gestito a lotti',
  'This location has reservedQty > 0.':
    'Ubicazione con quantità riservate a picking list',
  'New signature is already present in the batches.':
    "Lotto già presente nell'ubicazione selezionata",
  'Invalid newQty: must be minor than old batch onHandQty.':
    "Quantità non valida: dev'essere minore alla giacenza",
  'Invalid newQty: must be > 0.':
    "Quantità non valida: dev'essere maggiore di 0",
  'Provide two different batches.':
    'É necessario indicare un lotto diverso da quello già presente',
  'Unable to reserve this batch':
    'Non è stato possibile riservare la quantità richiesta',
  'Tote already used by another picking list':
    "UDC già utilizzata da un'altra picking list",
  'Unable to retrieve the required entity': 'Elemento non trovato',
  'ERRORE CORRIERE PRODUZIONE ETICHETTA':
    'Controllare indirizzo spedizione ordine (lunghezza max 35 caratteri, indirizzo/provincia non valida, caratteri speciali, etc.)',
  WAREHOUSE_ID: 'Warehouse',
  FULL_CATALOG: 'Full catalog',
  Warehouse: 'Warehouse',
  INVALID_CREDENTIALS: 'Invalid credentials',
  'Invalid credentials': 'Invalid credentials',
  NOT_AUTHORIZED: 'Not authorized',
  "You haven't the authorization to perform this action":
    "You haven't the authorization to perform this action",
}
