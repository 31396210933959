import { Pipe, PipeTransform } from '@angular/core'
import { statusTransactionTime } from '../libs/status-history.lib'
import { StatusHistory } from '../models/util.model'

@Pipe({
  name: 'statusTime',
})
export class StatusTimePipe implements PipeTransform {
  transform(
    statusHistory: StatusHistory[],
    beginStatus: string,
    endStatus: string
  ): string | undefined {
    return statusTransactionTime(statusHistory, beginStatus, endStatus)
  }
}
