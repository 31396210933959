import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { omit } from 'lodash'
import {
  Observable,
  switchMap,
  tap,
  filter,
  map,
  withLatestFrom,
  mergeMap,
  take,
} from 'rxjs'

import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import {
  GoodsReturn,
  GoodsReturnField,
  GoodsReturnSearchParams,
  GoodsReturnsListingPage,
} from '../goods-return.model'
import { GoodsReturnsRepository } from '../goods-returns.repository'
import { GoodsReturnNotification } from '../libs/goods-return-notification.lib'
import { GoodsReturnsService } from '../goods-returns.service'

export interface GoodsReturnsListingState extends GoodsReturnsListingPage {
  searchString?: string
  selected?: GoodsReturn[]
  fields?: GoodsReturnField[]
  filters?: GoodsReturnSearchParams
  searchKeys?: GoodsReturnSearchParams
  customFilters?: GoodsReturnSearchParams
  notification?: GoodsReturnNotification
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
  canExport: boolean
}

export interface GoodsReturnsFilterData {
  params?: GoodsReturnSearchParams
  count?: number
}

const GOODS_RETURNS_INITIAL_STATE: GoodsReturnsListingState = {
  isLoading: false,
  isInitialized: false,
  toReload: false,
  canExport: false,
}

@Injectable()
export class GoodsReturnsListingUseCase extends ComponentStore<GoodsReturnsListingState> {
  constructor(
    private goodsReturnsRepository: GoodsReturnsRepository,
    private goodsReturnService: GoodsReturnsService,
  ) {
    super(GOODS_RETURNS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectSearchString$: Observable<string | undefined> = this.select(
    (state) => state.searchString,
  )

  readonly selectFilters$ = this.select((state) => state.filters)

  readonly selectCanExport$ = this.select((state) => state.canExport)

  readonly selectSearchFilters$ = this.selectFilters$.pipe(
    mergeMap((filters) =>
      this.selectSearchString$.pipe(
        map((q) => ({ ...(q ? { q } : {}), ...(filters ? filters : {}) })),
      ),
    ),
  )

  readonly selectFilterData$ = this.selectState$.pipe(
    map((state) => this.parseStateFilterData(state)),
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> =
    this.selectSearchFilters$.pipe(
      filter((filters) => !!filters),
      map((filters) => isQueryStringFiltered(filters || {})),
    )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectCustomFilters$ = this.select((state) => state.customFilters)

  readonly selectSearchKeys$ = this.select((state) => state.searchKeys)

  readonly selectGoodsReturns$ = this.select((state) => state.data || [])

  readonly selectGoodsReturnsSelected$ = this.select((state) => state.selected)

  readonly selectIsSelected$ = this.selectGoodsReturnsSelected$.pipe(
    map((selected) => !!selected?.length),
  )

  readonly selectTotalCount$ = this.select((state) => state.totalCount || 0)

  readonly selectFields$ = this.select((state) => state.fields)

  readonly selectPage$: Observable<Page<GoodsReturn>> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() =>
        this.select(
          this.selectGoodsReturns$,
          this.selectTotalCount$,
          (data, totalCount) => ({ totalCount, data }),
          { debounce: true },
        ),
      ),
    )

  readonly selectExtData$ = this.select((state) => state.extData)

  readonly selectChannels$ = this.selectExtData$.pipe(
    map((extData) => extData?.channels),
  )

  readonly selectReasons$ = this.selectExtData$.pipe(
    map((extData) => extData?.reasons),
  )

  readonly selectPayments$ = this.selectExtData$.pipe(
    map((extData) => extData?.payments),
  )

  readonly selectCountries$ = this.selectExtData$.pipe(
    map((extData) => extData?.countries),
  )

  readonly selectNotification$ = this.select((state) => state.notification)

  /**
   * EFFECTS
   */

  readonly search$ = this.effect(
    (searchParams$: Observable<GoodsReturnSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        filter(([, state]) => !!state.fields),
        switchMap(([, state]) =>
          this.goodsReturnsRepository
            .searchGoodsReturns$(
              this.parseStateFilterParams(state),
              state.fields || [],
              state.extData,
            )
            .pipe(
              tapResponse(
                (pageData) => pageData && this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<GoodsReturnSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectState$),
        filter(([params, state]) => !!state.fields),
        map(([params, state]) => ({
          params: {
            ...params,
            ...(state.searchString ? { q: state.searchString } : {}),
            limit: state.totalCount,
          },
          fields: state.fields || [],
          extData: state.extData,
        })),
        tap((searchParams) => this.setFilters(searchParams.params)),
        switchMap(({ params, fields, extData }) =>
          this.goodsReturnsRepository
            .searchGoodsReturns$(params, fields, extData)
            .pipe(
              tapResponse(
                (pageData) => this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly delete$ = this.effect((goodsReturn$: Observable<GoodsReturn>) => {
    return goodsReturn$.pipe(
      switchMap((goodsReturn) =>
        this.goodsReturnsRepository
          .alert$(
            GoodsReturnNotification.from({
              code: 'GOODS_RETURN_DELETION',
              data: { goodsReturn },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.goodsReturnService.delete$(goodsReturn._id).pipe(
                tapResponse(
                  () => this.setGoodsReturnDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  /**
   * REDUCERS
   */

  readonly setOptions = this.updater(
    (
      state,
      newState: Partial<GoodsReturnsListingState>,
    ): GoodsReturnsListingState => {
      return { ...state, ...newState }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): GoodsReturnsListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setSearchString = this.updater(
    (state, searchString: string | undefined): GoodsReturnsListingState => {
      return {
        ...state,
        searchString,
        toReload: true,
      }
    },
  )

  readonly setGoodsReturnSelected = this.updater(
    (
      state,
      goodsReturns: GoodsReturn[] | undefined,
    ): GoodsReturnsListingState => {
      return {
        ...state,
        selected: goodsReturns,
      }
    },
  )

  readonly setGoodsReturnDeleted = this.updater(
    (state): GoodsReturnsListingState => {
      return {
        ...state,
        notification: GoodsReturnNotification.from({
          code: 'GOODS_RETURN_DELETED',
        }),
        toReload: true,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: GoodsReturnsListingPage): GoodsReturnsListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): GoodsReturnsListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isInitialized: true,
        isLoading: false,
        toReload: false,
      }
    },
  )

  readonly setReload = this.updater(
    (state, toReload: boolean): GoodsReturnsListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: GoodsReturnField[]): GoodsReturnsListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setFilters = this.updater(
    (
      state,
      filters: GoodsReturnSearchParams = {},
    ): GoodsReturnsListingState => {
      return {
        ...state,
        filters,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setCustomFilters = this.updater(
    (
      state,
      customFilters?: GoodsReturnSearchParams,
    ): GoodsReturnsListingState => {
      return {
        ...state,
        customFilters,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setSearchKeys = this.updater(
    (state, searchKeys?: GoodsReturnSearchParams): GoodsReturnsListingState => {
      return {
        ...state,
        searchKeys,
        toReload: state.isInitialized,
      }
    },
  )

  readonly resetState = this.updater(
    (state): GoodsReturnsListingState => GOODS_RETURNS_INITIAL_STATE,
  )

  readonly resetError = this.updater((state): GoodsReturnsListingState => {
    return {
      ...state,
      error: undefined,
    }
  })

  readonly resetFilters = this.updater((state): GoodsReturnsListingState => {
    return {
      ...state,
      filters: undefined,
      searchString: undefined,
      error: undefined,
      toReload: true,
    }
  })

  /**
   * Utilities
   */

  private parseStateFilterParams(
    state: GoodsReturnsListingState,
  ): GoodsReturnSearchParams {
    let params: GoodsReturnSearchParams = state.filters || {}
    if (state.searchString) {
      params.q = state.searchString
    }
    if (state.customFilters) {
      params = { ...params, ...state.customFilters }
    }
    if (state.searchKeys) {
      params = { ...params, ...state.searchKeys }
    }

    return params
  }

  private parseStateFilterData(
    state: GoodsReturnsListingState,
  ): GoodsReturnsFilterData {
    if (state.selected?.length) {
      return {
        params: {
          _id: state.selected.map((o) => o._id),
        },
        count: state.selected.length,
      }
    }

    return {
      params: omit(this.parseStateFilterParams(state), [
        'limit',
        'offset',
        'sort',
        'order',
      ]),
      count: state.totalCount,
    }
  }

  readonly setCanExport = this.updater(
    (state, canExport: boolean): GoodsReturnsListingState => ({
      ...state,
      canExport: canExport,
    }),
  )
}
