import { createSelector } from '@ngrx/store'
import { StoreState } from '../store.state'
import { LocalesState } from './locales.state'

export const selectLocalesState = (state: StoreState) => state.locales

export const selectLocales = (tenantId: string) =>
  createSelector(selectLocalesState, (state: LocalesState) => {
    const tenantLocales = state[tenantId]

    if (!tenantLocales || !tenantLocales.currencies) {
      return undefined
    }

    return tenantLocales.currencies
      ? Object.values(tenantLocales.currencies)
      : undefined
  })
