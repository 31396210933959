import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  PackingList,
  PackingListPackParams,
  PackingListPrinters,
  PackingListScanParams,
  PackingListSearchParams,
} from './packing-list.model'
import { Page } from '../../models/util.model'

const MODEL = 'packing-lists'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class PackingListsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a packing list by ID
   * @param packingListId - The picking list ID
   * @returns The observable<PackingList> for read the packing list
   */
  read$(packingListId: string): Observable<PackingList> {
    return this._read$<PackingList>(packingListId)
  }

  /**
   * Create a packing list by tote ID and printers
   * @param toteId - The tote ID
   * @param printers - The printers IDs
   * @param locationId - The package location ID
   * @returns the observable<PackingList> for create a packing list
   */
  create$(
    toteId: string,
    printers: PackingListPrinters,
    locationId?: string,
  ): Observable<PackingList> {
    return this.http.post<PackingList>(`${this.apiUrl}`, {
      toteId,
      printers,
      locationId,
    })
  }

  /**
   * Search packing-lists by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<PackingList>> for search pickingLists
   */
  search$(
    params?: PackingListSearchParams,
    returnAll = false,
  ): Observable<Page<PackingList>> {
    return this._search$<PackingList>(params, returnAll)
  }

  /**
   * List packing-lists by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<PackingList[]> for list pickingLists
   */
  list$(
    params?: PackingListSearchParams,
    returnAll = false,
  ): Observable<PackingList[]> {
    return this._list$<PackingList>(params, returnAll)
  }

  /**
   * Scan a packing-list product
   * @param packingListId - The packing-list ID
   * @param params - The scan params
   * @returns the observable<PackingList> for scan a product
   */
  scanProduct$(
    packingListId: string,
    orderId: string,
    params: PackingListScanParams,
  ): Observable<PackingList> {
    return this.http.post<PackingList>(
      `${this.apiUrl}/${packingListId}/orders/${orderId}/scan`,
      params,
    )
  }

  /**
   * Pack a packing-list order
   * @param packingListId - The packing-list ID
   * @param orderId - The order ID
   * @param params - The pack params
   * @returns the observable<PackingList> for pack an order
   */
  packOrder$(
    packingListId: string,
    orderId: string,
    params: PackingListPackParams,
  ): Observable<PackingList> {
    return this.http.post<PackingList>(
      `${this.apiUrl}/${packingListId}/orders/${orderId}/pack`,
      params,
    )
  }

  /**
   * Scan a packing-list tote
   * @param packingListId - The packing-list ID
   * @param toteId - The tote ID
   * @returns the observable<PackingList> for scan the tote
   */
  scanTote$(packingListId: string, toteId: string): Observable<PackingList> {
    return this.http.post<PackingList>(
      `${this.apiUrl}/${packingListId}/totes/${toteId}/scan`,
      {},
    )
  }

  /**
   * Take charge of a packing-list
   * @param packingListId - The packing-list ID
   * @param printers - The printers configuration
   * @param locationId - The location ID
   * @returns the observable<PackingList> for take charge of a packing-list
   */
  takeUp$(
    packingListId: string,
    printers: PackingListPrinters,
    locationId?: string,
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}/${packingListId}/take`, {
      printers,
      locationId,
    })
  }

  /**
   * Read a packing-list by params
   * @param params - The search params
   * @returns the observable<PackingList> for find a packing-list
   */
  readOne$(params?: PackingListSearchParams): Observable<PackingList> {
    return this._readOne$<PackingList>(params)
  }

  /**
   * Find a packing-list by params
   * @param params - the search params
   * @returns the observable<PackingList> for list a packing-list
   */
  findOne$(
    params?: PackingListSearchParams,
  ): Observable<PackingList | undefined> {
    return this._findOne$<PackingList>(params)
  }
}
