import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import { DeepPartial } from '../../../models/util.model'
import { GoodsReturn, GoodsReturnStatus } from '../goods-return.model'
import {
  GOODS_RETURN_HEADER_INITIAL_STATE,
  updateGoodsReturnHeaderAmounts,
} from './goods-return-header.lib'
import {
  createGoodsReturnRows,
  parseGoodsReturnOrderRows,
  updateGoodsReturnRowAmounts,
} from './goods-return-rows.lib'
import {
  createGoodsReturnRecoveryOrder,
  createGoodsReturnReferenceOrder,
} from './goods-return-order.lib'
import { Order } from '../../orders/order.model'
import { ProductSelection } from '../../products'
import { Tenant } from '../../tenants/tenant.model'

// Initial states

const GOODS_RETURN_INITIAL_STATE: DeepPartial<GoodsReturn> = {
  warehouseId: undefined,
  header: GOODS_RETURN_HEADER_INITIAL_STATE,
  status: GoodsReturnStatus.returning,
  statusHistory: undefined,
  rows: [],
}

// Init functions

export function initGoodsReturn(
  goodsReturn: Partial<GoodsReturn> = {}
): GoodsReturn {
  return defaultsDeep(cloneDeep(goodsReturn), GOODS_RETURN_INITIAL_STATE)
}

export function setGoodsReturnDefaults(
  goodsReturn: GoodsReturn,
  defaults: DeepPartial<GoodsReturn>
): GoodsReturn {
  return defaultsDeep(cloneDeep(goodsReturn), defaults)
}

// Amounts

export function updateGoodsReturnAmounts(
  goodsReturn: GoodsReturn,
  priceTaxes = false
): GoodsReturn {
  const rows = goodsReturn.rows.map((row) =>
    updateGoodsReturnRowAmounts(row, priceTaxes)
  )
  const header = updateGoodsReturnHeaderAmounts(
    goodsReturn.header,
    rows,
    priceTaxes
  )

  return {
    ...goodsReturn,
    header,
    rows,
  }
}

// Orders

export function setGoodsReturnReferenceOrder(
  goodsReturn: GoodsReturn,
  order: Order
): GoodsReturn {
  return updateGoodsReturnAmounts({
    ...goodsReturn,
    warehouseId: order.assignedWarehouseId || order.warehouseId || '',
    referenceOrder: createGoodsReturnReferenceOrder(order),
    header: {
      ...goodsReturn.header,
      channelId: order.header.channel,
      paymentId: order.header.paymentType,
      currency: order.header.currency,
      shippingAddress: order.header.shippingAddress,
      billingAddress: order.header.billingAddress,
    },
    rows: parseGoodsReturnOrderRows(order.rows),
  })
}

export function removeGoodsReturnReferenceOrder(
  goodsReturn: GoodsReturn
): GoodsReturn {
  return {
    ...goodsReturn,
    referenceOrder: undefined,
  }
}

export function setGoodsReturnRecoveryOrder(
  goodsReturn: GoodsReturn,
  order: Order
): GoodsReturn {
  return {
    ...goodsReturn,
    recoveryOrder: createGoodsReturnRecoveryOrder(order),
  }
}

export function removeGoodsReturnRecoveryOrder(
  goodsReturn: GoodsReturn
): GoodsReturn {
  return {
    ...goodsReturn,
    recoveryOrder: undefined,
  }
}

// Rows

export function addGoodsReturnProducts(
  goodsReturn: GoodsReturn,
  selection: ProductSelection[],
  priceTaxes = false
): GoodsReturn {
  return updateGoodsReturnAmounts(
    {
      ...goodsReturn,
      rows: [...goodsReturn.rows, ...createGoodsReturnRows(selection)],
    },
    priceTaxes
  )
}

// Users

export function getGoodsReturnUserIds(goodsReturn: GoodsReturn): string[] {
  return uniq(
    [
      goodsReturn.createdBy,
      goodsReturn.updatedBy,
      ...goodsReturn.statusHistory.map((s) => s.userId!),
    ].filter((userId) => !!userId)
  )
}

// Defaults

export function getGoodsReturnDefaults(
  tenant: Tenant
): DeepPartial<GoodsReturn> {
  return {
    warehouseId: tenant.defaults?.warehouseId,
  }
}
