import { pick, defaultsDeep, cloneDeep } from 'lodash'
import {
  OrderCashOnDelivery,
  OrderDiscount,
  OrderGift,
  OrderHeader,
  OrderRow,
} from '../order.model'
import {
  Percentages,
  Prices,
  calculatePercentage,
  calculatePrices,
  dinero,
} from '../../../libs/dinero.lib'

/**
 * Order header discount initial state
 */
const ORDER_HEADER_DISCOUNT_INITIAL_STATE: OrderDiscount = {
  discountWithTaxes: 0,
  discountTaxRate: 0,
  discountWithoutTaxes: 0,
}

/**
 * Order header initial state
 */
export const ORDER_HEADER_INITIAL_STATE: Partial<OrderHeader> = {
  date: new Date().toISOString(),
  rifDate: new Date().toISOString(),
  currency: 'EUR',
  channel: undefined,
  discount: ORDER_HEADER_DISCOUNT_INITIAL_STATE,
  shippingCost: 0,
  shippingCostWithTaxes: 0,
  shippingTaxRate: 0,
  shippingTaxes: 0,
  subTotal: 0,
  subTotalWithTaxes: 0,
  bodyTaxes: 0,
  totalOrderAmount: 0,
}

/**
 * Initialize order header
 * @param header - the default order header params
 * @returns the header initialized
 */
export function initOrderHeader(
  header: Partial<OrderHeader> = {}
): OrderHeader {
  return defaultsDeep(cloneDeep(header), ORDER_HEADER_INITIAL_STATE)
}

/**
 * Update the order header amounts
 * @param header - the order header
 * @param rows - the order rows
 * @param priceTaxes - the price taxes flag
 * @param discountPercentage - the discount percentage flag
 * @returns the order header updated
 */
export function updateOrderHeaderAmounts(
  header: OrderHeader,
  rows: OrderRow[],
  priceTaxes = false,
  discountPercentage = false
): OrderHeader {
  // Shipping prices
  const {
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
  } = updateShippingAmounts(header, priceTaxes)

  // CashOnDelivery prices
  const cashOnDelivery = updateCodAmounts(header, priceTaxes)

  // Discount prices
  const discountSubTotal = rows.reduce(
    (acc, row) => acc.add(dinero(row.totalAmountWithTaxes)),
    dinero(0)
  )
  const discountPrices = updateDiscountAmounts(
    header,
    discountSubTotal.toUnit(),
    priceTaxes,
    discountPercentage
  )

  // Gift
  const gift = updateGiftAmounts(header, priceTaxes)

  // Footer amounts
  const subTotal = rows
    .reduce((acc, row) => acc.add(dinero(row.totalAmount)), dinero(0))
    .subtract(discountPrices.prices.price)

  const subTotalWithTaxes = rows
    .reduce((acc, row) => acc.add(dinero(row.totalAmountWithTaxes)), dinero(0))
    .subtract(discountPrices.prices.total)

  const bodyTaxes = rows
    .reduce((acc, row) => acc.add(dinero(row.totalTaxes)), dinero(0))
    .subtract(discountPrices.prices.taxes)

  const totalOrderAmount = subTotalWithTaxes
    .add(dinero(shippingCostWithTaxes))
    .add(dinero(gift.costWithTaxes))
    .add(dinero(cashOnDelivery.feeWithTaxes))

  return {
    ...header,
    subTotal: subTotal.toRoundedUnit(4),
    subTotalWithTaxes: subTotalWithTaxes.toRoundedUnit(4),
    bodyTaxes: bodyTaxes.toRoundedUnit(4),
    shippingTaxRate,
    shippingTaxes,
    shippingCost,
    shippingCostWithTaxes,
    cashOnDelivery,
    gift,
    discount: {
      discountPercentage:
        discountPrices.percentages.percentage.toRoundedUnit(4),
      discountTaxRate: discountPrices.prices.taxRate,
      discountWithoutTaxes: discountPrices.prices.price.toRoundedUnit(4),
      discountWithTaxes: discountPrices.prices.total.toRoundedUnit(4),
    },
    totalOrderAmount: totalOrderAmount.toRoundedUnit(4),
  }
}

/**
 * Update shipping amounts
 * @param header - the order header
 * @param priceTaxes - the price taxes flag
 * @returns the shipping amounts
 */
function updateShippingAmounts(header: OrderHeader, priceTaxes = false) {
  const shippingPrices = calculatePrices({
    taxRate: header.shippingTaxRate || 0,
    price: priceTaxes ? undefined : header.shippingCost,
    total: priceTaxes ? header.shippingCostWithTaxes : undefined,
  })

  return {
    shippingTaxRate: shippingPrices.taxRate,
    shippingCost: shippingPrices.price.toUnit(),
    shippingCostWithTaxes: shippingPrices.total.toUnit(),
    shippingTaxes: shippingPrices.taxes.toUnit(),
  }
}

/**
 * Update the cash-on-delivery amounts
 * @param header - the order header
 * @param priceTaxes - the price taxes flag
 * @returns the cod amounts updated
 */
function updateCodAmounts(
  header: OrderHeader,
  priceTaxes = false
): OrderCashOnDelivery {
  const codPrices = calculatePrices({
    taxRate: header.cashOnDelivery?.taxRate || 0,
    price: priceTaxes ? undefined : header.cashOnDelivery?.fee || 0,
    total: priceTaxes ? header.cashOnDelivery?.feeWithTaxes || 0 : undefined,
  })

  return {
    fee: codPrices.price.toUnit(),
    feeWithTaxes: codPrices.total.toUnit(),
    taxes: codPrices.taxes.toUnit(),
    taxRate: codPrices.taxRate,
    amount: header.cashOnDelivery?.amount || 0,
  }
}

/**
 * Update the gift amounts
 * @param header - the order header
 * @param priceTaxes - the price taxes flag
 * @returns the gift amounts updated
 */
function updateGiftAmounts(header: OrderHeader, priceTaxes = false): OrderGift {
  if (!header.gift?.isEnabled) {
    return {
      ...header.gift!,
      taxes: 0,
      cost: 0,
      costWithTaxes: 0,
    }
  }

  const giftPrices = calculatePrices({
    taxRate: header.gift?.taxRate || 0,
    price: priceTaxes ? undefined : header.gift?.cost || 0,
    total: priceTaxes ? header.gift?.costWithTaxes || 0 : undefined,
  })
  const taxes = giftPrices.total.subtract(giftPrices.price).toUnit()

  return {
    ...header.gift,
    taxes,
    taxRate: giftPrices.taxRate,
    cost: giftPrices.price.toUnit(),
    costWithTaxes: giftPrices.total.toUnit(),
  }
}

/**
 * Update order discount amounts
 * @param header - the order header
 * @param subTotalWithTaxes - the subtotal of the order
 * @param priceTaxes - the price taxes flag
 * @param discountPercentage - the discount percentage flag
 * @returns the discount amounts
 */
function updateDiscountAmounts(
  header: OrderHeader,
  subTotalWithTaxes: number,
  priceTaxes = false,
  discountPercentage = false
): { percentages: Percentages; prices: Prices } {
  let percentages: Percentages
  let prices: Prices

  if (!discountPercentage || header.discount.discountPercentage === undefined) {
    prices = calculatePrices({
      taxRate: header.discount?.discountTaxRate,
      price: priceTaxes ? undefined : header.discount?.discountWithoutTaxes,
      total: priceTaxes ? header.discount?.discountWithTaxes : undefined,
    })
    percentages = calculatePercentage({
      total: subTotalWithTaxes,
      value: prices.total.toUnit(),
    })
  } else {
    percentages = calculatePercentage({
      total: subTotalWithTaxes,
      percentage: header.discount.discountPercentage,
    })
    prices = calculatePrices({
      taxRate: header.discount?.discountTaxRate,
      total: percentages.value.toUnit(),
    })
  }

  return { prices, percentages }
}

/**
 * Merge order headers
 * @param headerFrom - the first order header to merge
 * @param headerTo - the second order header to merge
 * @returns the new order header merged
 */
export function mergeOrderHeaderInfo(
  headerFrom: OrderHeader,
  headerTo: OrderHeader
): OrderHeader {
  return {
    ...headerTo,
    ...pick(headerFrom, [
      'currency',
      'rifDate',
      'channel',
      'transport',
      'paymentType',
      'billingAddress',
      'shippingAddress',
    ]),
    rifOrder: headerFrom.rifOrder
      ? headerFrom.rifOrder
      : headerFrom.orderNumber!.toString(),
  }
}
