import { AddressRecipientType } from '../models/address.model'

export const ADDRESS_RECIPIENT_TYPES = [
  {
    code: AddressRecipientType.customer,
    name: 'Cliente',
  },
  {
    code: AddressRecipientType.warehouse,
    name: 'Magazzino',
  },
  {
    code: AddressRecipientType.pickupPoint,
    name: 'Pickup Point',
  },
]
