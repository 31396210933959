<dx-drop-down-box
  *ngIf="dataSource || [] as items"
  class="form-control"
  [value]="values || []"
  (valueChange)="values = $event"
  [id]="key"
  name="{{ key }}"
  [showClearButton]="false"
  [dataSource]="items | map: keyField"
  fieldTemplate="fieldTemplate"
  [disabled]="disabled"
>
  <div *dxTemplate="let data of 'fieldTemplate'">
    <dx-text-box
      [value]="items | filter: 'INCLUDES' : keyField : data | map: displayField"
      [readOnly]="true"
      [placeholder]="placeholder || ''"
    ></dx-text-box>
  </div>
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [dataSource]="items | map: keyField"
      [selectedItems]="values || []"
      (selectedItemsChange)="values = checkValue($event)"
      (onSelectionChanged)="valuesChange.emit(values)"
      [showSelectionControls]="true"
      [searchEnabled]="true"
      selectionMode="all"
      itemTemplate="listItem"
      [noDataText]="emptyLabel || ''"
    >
      <div *dxTemplate="let item of 'listItem'">
        {{ dataSource | find: keyField : item : displayField }}
      </div>
    </dx-list>
  </div>
</dx-drop-down-box>
