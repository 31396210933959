import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, concat, last } from 'rxjs'

import {
  SupplierOrderSearchParams,
  SupplierOrderChangeStatusAction,
  SupplierOrder,
} from './supplier-order.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { ProductProvisioningSettings } from '../products'
import { Page } from '../../models/util.model'

const MODEL = 'supplier-orders'
const VERSION = 'v3'
const OLD_VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class SupplierOrdersService extends CrudService {
  private deprecatedUrl: string

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
    this.deprecatedUrl = `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${OLD_VERSION}/${MODEL}`
  }

  /**
   * Create a new supplier-order
   * @param supplierOrder - The supplier-order to create
   * @returns The observable<SupplierOrder> to create the supplier-order
   */
  create$(supplierOrder: SupplierOrder): Observable<SupplierOrder> {
    return this._create$<SupplierOrder>(supplierOrder)
  }

  /**
   * Read a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @returns The observable<SupplierOrder> for read the supplier-order
   */
  read$(supplierOrderId: string): Observable<SupplierOrder> {
    return this._read$<SupplierOrder>(supplierOrderId)
  }

  /**
   * Update a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @param supplierOrder - The supplier-order body to update
   * @returns The observable<SupplierOrder> for update the supplier-order
   */
  update$(
    supplierOrderId: string,
    supplierOrder: SupplierOrder,
  ): Observable<SupplierOrder> {
    return this._update$<SupplierOrder>(supplierOrderId, supplierOrder)
  }

  /**
   * Create or update a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @param supplierOrder - The supplier-order body to update
   * @returns The observable<SupplierOrder> for update the supplier-order
   */
  upsert$(supplierOrder: SupplierOrder): Observable<SupplierOrder> {
    return this._upsert$<SupplierOrder>(supplierOrder, supplierOrder._id)
  }

  /**
   * Delete a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @returns The observable<SupplierOrder> for delete the supplier-order
   */
  delete$(supplierOrderId: string): Observable<SupplierOrder> {
    return this._delete$<SupplierOrder>(supplierOrderId)
  }

  /**
   * Search supplier-orders by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<SupplierOrder>> for search supplier-orders
   */
  search$(
    params?: SupplierOrderSearchParams,
    returnAll = false,
  ): Observable<Page<SupplierOrder>> {
    return this._search$<SupplierOrder>(params, returnAll)
  }

  /**
   * List supplier-orders by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<SupplierOrder[]> for list supplier-orders
   */
  list$(
    params?: SupplierOrderSearchParams,
    returnAll = false,
  ): Observable<SupplierOrder[]> {
    return this._list$<SupplierOrder>(params, returnAll)
  }

  /**
   * Confirm a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @returns The observable<SupplierOrder> for confirm the supplier-order
   */
  confirm$(supplierOrderId: string): Observable<SupplierOrder> {
    return this.http.post<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/confirm`,
      {},
    )
  }

  /**
   * Cancel a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @returns The observable<SupplierOrder> for cancel the order
   */
  cancel$(supplierOrderId: string): Observable<SupplierOrder> {
    return this.http.post<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/cancel`,
      {},
    )
  }

  /**
   * Close a supplier-order by ID
   * @param supplierOrderId - The supplier-order ID
   * @returns The observable<SupplierOrder> for close the order
   */
  close$(supplierOrderId: string): Observable<SupplierOrder> {
    return this.http.post<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/close`,
      {},
    )
  }

  /**
   * Change supplier-order status
   * @param supplierOrderId - The order ID
   * @param action - The change status action
   * @returns The Observable<SupplierOrder> for change the order status
   */
  changeStatus$(
    supplierOrderId: string,
    action: SupplierOrderChangeStatusAction,
  ): Observable<SupplierOrder> {
    switch (action) {
      case 'cancel':
        return this.cancel$(supplierOrderId)
      case 'confirm':
        return this.confirm$(supplierOrderId)
      case 'close':
        return this.close$(supplierOrderId)
    }
  }

  /**
   * Download the order summary
   * @param supplierOrderId - The order ID
   * @returns The observable<any> for download the order summary
   */
  downloadSummary$(supplierOrderId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${supplierOrderId}/files/summary`, {
      responseType: 'blob',
    })
  }

  /**
   * Print order summary
   * @param supplierOrderId - The order ID
   * @returns The observable<void> for print the order summary
   */
  printSummary$(supplierOrderId: string, printerId: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${supplierOrderId}/files/summary/print`,
      {
        printerId,
      },
    )
  }

  /**
   * Get supplier-order rows upload url
   * @deprecated
   * @param supplierOrderId - The order ID
   * @returns the url to upload the supplier-order rows by file
   */
  getUploadSupplierOrderRowsUrl$(supplierOrderId: string): string {
    return `${this.deprecatedUrl}/${supplierOrderId}/import-rows/`
  }

  /**
   * Download template for upload supplier-order rows
   * @deprecated
   * @returns the observable<Blob> for download the template
   */
  downloadSupplierOrderRowsUploadTemplate$(): Observable<Blob> {
    return this.http.get(`${this.deprecatedUrl}/template`, {
      responseType: 'blob',
    })
  }

  /**
   * Get under-provision products
   * @param supplierOrderId - The supplier order ID
   * @param settings  - The provisioning settings
   * @param supplierId - The supplier ID
   */
  addUnderProvisionProducts$(
    supplierOrderId: string,
    settings: ProductProvisioningSettings,
    supplierId?: string,
  ): Observable<SupplierOrder> {
    return this.http.post<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/push-underprovisions`,
      {
        ...settings,
        supplierId,
      },
    )
  }

  /**
   * Generates the path to which attachments for a supplier order must be uploaded
   * @param supplierOrderId - The supplier order ID
   * @returns the path
   */
  getSupplierOrderAttachmentsUploadUrl(supplierOrderId: string): string {
    return `${this.apiUrl}/${supplierOrderId}/attachments`
  }

  /**
   * Creates an attachment for a supplier order
   * @param supplierOrderId - The supplier order ID
   * @param attachment - The attachment as binary file
   * @returns the updated observable<Order>
   */
  createAttachment$(
    supplierOrderId: string,
    attachment: any,
  ): Observable<SupplierOrder> {
    return this.http.post<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/attachments`,
      {
        body: attachment,
      },
    )
  }

  /**
   * Creates multiple attachments for a supplier order
   * @param supplierOrderId - The supplier order ID
   * @param attachments - The list of attachments as binary files
   * @returns the updated observable<Order>
   */
  createAttachments$(
    supplierOrderId: string,
    attachments: [any],
  ): Observable<SupplierOrder> {
    return concat(
      ...attachments.map((attachment) =>
        this.createAttachment$(supplierOrderId, attachment),
      ),
    ).pipe(last())
  }

  /**
   * Deletes an attachment from a supplier order
   * @param supplierOrderId - The supplier order ID
   * @param attachmentId - The attachment ID
   */
  deleteAttachment$(
    supplierOrderId: string,
    attachmentId: string,
  ): Observable<SupplierOrder> {
    return this.http.delete<SupplierOrder>(
      `${this.apiUrl}/${supplierOrderId}/attachments/${attachmentId}`,
      { body: {} },
    )
  }

  /**
   * Downloads an attachment from a supplier order
   * @param productId - The product ID
   * @param attachmentId - The attachment ID
   * @returns the observable<Attachment>
   */
  downloadAttachment$(
    supplierOrderId: string,
    attachmentId: string,
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/${supplierOrderId}/attachments/${attachmentId}/content`,
      { responseType: 'blob' },
    )
  }
}
