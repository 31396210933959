import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Observable } from 'rxjs'

import { ThrottleService } from '../services/throttle.service'

@Injectable({
  providedIn: 'root',
})
export class ThrottleInterceptor implements HttpInterceptor {
  constructor(private throttleService: ThrottleService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.throttleService.intercept(request, next)
  }
}
