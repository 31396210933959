import { uniq } from 'lodash'
import { Page } from '../../../models/util.model'
import { GoodsReturn, GoodsReturnListingKeys } from '../goods-return.model'

/**
 * Parse goods-return page keys
 * @param page - the goods-returns page
 * @returns the page listing keys
 */
export function parseGoodsReturnPageKeys(
  page: Page<GoodsReturn>,
): GoodsReturnListingKeys {
  return page.data.reduce<GoodsReturnListingKeys>(
    (acc, r) => parseGoodsReturnKeys(r, acc),
    {},
  )
}

/**
 * Parse goods-return keys
 * @param goodsReturn - the goods-return
 * @param keys - the keys already loaded
 * @returns the goodsReturn listing keys
 */
export function parseGoodsReturnKeys(
  goodsReturn: GoodsReturn,
  keys: GoodsReturnListingKeys = {},
): GoodsReturnListingKeys {
  const channelIds = uniq([
    ...(keys['channelIds'] || []),
    ...(goodsReturn.header.channelId ? [goodsReturn.header.channelId] : []),
  ])
  const paymentIds = uniq([
    ...(keys['paymentIds'] || []),
    ...(goodsReturn.header.paymentId ? [goodsReturn.header.paymentId] : []),
  ])
  const countryCodes = uniq([
    ...(keys['countryCodes'] || []),
    ...(goodsReturn.header.shippingAddress?.countryCode
      ? [goodsReturn.header.shippingAddress.countryCode]
      : []),
    ...(goodsReturn.header.billingAddress?.countryCode
      ? [goodsReturn.header.billingAddress.countryCode]
      : []),
  ]).filter((c) => c.length === 2)
  const reasonIds = uniq([
    ...(keys['reasonIds'] || []),
    ...(goodsReturn.header.reasonId ? [goodsReturn.header.reasonId] : []),
  ])

  return {
    paymentIds,
    channelIds,
    countryCodes,
    reasonIds,
  }
}
