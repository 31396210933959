import { Inject, Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { map, Observable, take } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
    private router: Router,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authManager.isAuthenticated$().pipe(
      take(1),
      map(
        (authenticated) =>
          authenticated ||
          this.router.parseUrl(this.authManager.authenticationRoute),
      ),
    )
  }

  canLoad():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authManager.isAuthenticated$().pipe(
      take(1),
      map(
        (authenticated) =>
          authenticated ||
          this.router.parseUrl(this.authManager.authenticationRoute),
      ),
    )
  }
}
