import { isArray, isEmpty } from 'lodash'

import {
  Product,
  ProductCatalogAttribute,
  ProductPageData,
  ProductScope,
} from '../product.model'
import {
  Attribute,
  AttributeKind,
  AttributeField,
} from '../../attributes/attribute.model'
import { CATALOG_DEFAULT_CODE } from '../../catalogs/catalog.const'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'
import { removeEmptyLocale } from './locale.lib'

export function defineProductScopeAttributes(
  productAttributes: ProductCatalogAttribute[] = [],
  attributes: Attribute[],
  scope: ProductScope
): ProductCatalogAttribute[] {
  if (!attributes || !attributes.length) {
    return productAttributes
  }

  return attributes.reduce(
    (attrs, attr) => checkProductAttribute(attrs, attr, scope),
    productAttributes
  )
}

export function checkProductAttributeEmpty(
  productAttributes: ProductCatalogAttribute[] | undefined,
  attribute: Attribute | undefined,
  scope: ProductScope
): boolean {
  if (!productAttributes || !attribute) {
    return true
  }

  const attributeScope: ProductScope = {
    catalogCode: attribute.valuePerCatalog
      ? scope.catalogCode
      : CATALOG_DEFAULT_CODE,
    locale: attribute.isLocalizable ? scope.locale : LOCALE_DEFAULT_CODE,
  }
  const productAttribute = productAttributes.find(
    (attr) =>
      attr.attributeCode === attribute.code &&
      attr.catalogCode === attributeScope.catalogCode
  )
  const attributeValue =
    productAttributes && productAttribute
      ? productAttribute.locales[attributeScope.locale]
      : undefined

  if (
    [
      AttributeKind.date,
      AttributeKind.boolean,
      AttributeKind.number,
      AttributeKind.text,
      AttributeKind.textarea,
      AttributeKind.simpleSelect,
    ].includes(attribute.kind)
  ) {
    return attributeValue === undefined || attributeValue === ''
  }

  if (attribute.kind === AttributeKind.multiSelect) {
    return attributeValue === undefined || !attributeValue.length
  }

  if (attribute.kind === AttributeKind.price) {
    return attributeValue === undefined || attributeValue.value === undefined
  }

  return true
}

export function checkProductAttribute(
  productAttributes: ProductCatalogAttribute[] = [],
  attribute: Attribute,
  scope: ProductScope
): ProductCatalogAttribute[] {
  const attributeScope: ProductScope = {
    catalogCode: attribute.valuePerCatalog ? scope.catalogCode : 'default',
    locale: attribute.isLocalizable ? scope.locale : 'default',
  }

  const attr = productAttributes
    ? productAttributes.find(
        (a) =>
          a.attributeCode === attribute.code &&
          a.catalogCode === attributeScope.catalogCode
      )
    : undefined

  return !attr
    ? [...productAttributes, defineProductAttribute(attribute, attributeScope)]
    : [
        ...productAttributes.map((a) =>
          a.attributeCode === attribute.code
            ? checkProductAttributeLocale(a, attribute, attributeScope)
            : a
        ),
      ]
}

export function defineProductAttribute(
  attribute: Attribute,
  scope: ProductScope
): ProductCatalogAttribute {
  return {
    catalogCode: scope.catalogCode,
    attributeCode: attribute.code,
    locales: {
      [scope.locale]: defineProductAttributeLabel(attribute),
    },
  }
}

export function checkProductAttributeLocale(
  productAttribute: ProductCatalogAttribute,
  attribute: Attribute,
  scope: ProductScope
): ProductCatalogAttribute {
  const locales =
    productAttribute.locales[scope.locale] === undefined &&
    (attribute.kind === AttributeKind.price ||
      attribute.kind === AttributeKind.multiSelect)
      ? {
          ...productAttribute.locales,
          [scope.locale]: defineProductAttributeLabel(attribute),
        }
      : { ...productAttribute.locales }

  return {
    ...productAttribute,
    locales,
  }
}

export function defineProductAttributeLabel(attribute: Attribute) {
  if (
    [
      AttributeKind.date,
      AttributeKind.boolean,
      AttributeKind.number,
      AttributeKind.text,
      AttributeKind.textarea,
      AttributeKind.simpleSelect,
    ].includes(attribute.kind)
  ) {
    return undefined
  }

  if (attribute.kind === AttributeKind.multiSelect) {
    return []
  }

  if (attribute.kind === AttributeKind.price) {
    return { value: undefined, currency: undefined } as any
  }

  return undefined
}

export function removeProductEmptyAttributes(
  attributes?: ProductCatalogAttribute[]
): ProductCatalogAttribute[] | undefined {
  const newAttributes = attributes
    ? attributes.filter((att) => !isEmpty(removeEmptyLocale(att.locales)))
    : []
  return newAttributes.length ? [...newAttributes] : undefined
}

export function checkProductAttributeValue(
  productAttribute: ProductCatalogAttribute,
  attribute: Attribute,
  scope: ProductScope
): boolean {
  const attributeScope: ProductScope = {
    catalogCode: attribute.valuePerCatalog
      ? scope.catalogCode
      : CATALOG_DEFAULT_CODE,
    locale: attribute.isLocalizable ? scope.locale : LOCALE_DEFAULT_CODE,
  }

  return productAttribute
    ? !!productAttribute.locales[attributeScope.locale]
    : false
}

export function getProductAttributeByField(
  product: Product,
  field: AttributeField,
  catalogCode: string,
  locale: string
) {
  if (!product.attributes) {
    return undefined
  }

  const attributeScope = {
    catalogCode: field.valuePerCatalog ? catalogCode : CATALOG_DEFAULT_CODE,
    locale: field.isLocalizable ? locale : LOCALE_DEFAULT_CODE,
  }

  const catalogValue = product.attributes.find(
    (attr) =>
      attr.catalogCode === attributeScope.catalogCode &&
      attr.attributeCode === field.attribute?.code
  )

  const attributeValue =
    catalogValue && catalogValue.locales
      ? catalogValue.locales[attributeScope.locale]
      : undefined

  if (attributeValue === undefined) {
    return undefined
  }

  if (field.attribute?.kind === AttributeKind.boolean) {
    return attributeValue === true ? 1 : 0
  }

  if (field.attribute?.kind === AttributeKind.price) {
    return attributeValue.value
  }

  return attributeValue
}

export function getProductAttributeValue(
  product: Product,
  attribute: Attribute,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE
): any {
  const attributeScope = {
    catalogCode: attribute.valuePerCatalog ? catalogCode : CATALOG_DEFAULT_CODE,
    locale: attribute.isLocalizable ? locale : LOCALE_DEFAULT_CODE,
  }

  const productAttribute = product.attributes?.find(
    (attr) =>
      attr.catalogCode === attributeScope.catalogCode &&
      attr.attributeCode === attribute.code
  )

  const attributeValue = productAttribute?.locales
    ? productAttribute.locales[attributeScope.locale]
    : undefined

  return attributeValue
}

export function parseProductDataAttributes(
  productData: ProductPageData,
  attributes: Attribute[],
  scope: ProductScope
): ProductPageData {
  if (!productData.attributes) {
    return productData
  }

  return {
    ...productData,
    attributesData: parseProductAttributes(
      productData.attributes,
      attributes,
      scope
    ),
  }
}

export function parseProductAttributes(
  productAttributes: ProductCatalogAttribute[],
  attributes: Attribute[],
  scope: ProductScope
): Record<string, any> {
  return productAttributes.reduce<Record<string, any>>(
    (acc, productAttribute) => {
      const attribute = attributes.find(
        (a) => a.code === productAttribute.attributeCode
      )

      if (!attribute) {
        return acc
      }

      const attributeScope = {
        catalogCode: attribute.valuePerCatalog
          ? scope.catalogCode
          : CATALOG_DEFAULT_CODE,
        locale: attribute.isLocalizable ? scope?.locale : LOCALE_DEFAULT_CODE,
      }

      // Check attribute type
      if (!productAttribute.locales[attributeScope.locale]) {
        return acc
      }

      let attributeValue: any[] = isArray(
        productAttribute.locales[attributeScope.locale]
      )
        ? productAttribute.locales[attributeScope.locale]
        : [productAttribute.locales[attributeScope.locale]]

      if (
        attribute.kind === AttributeKind.simpleSelect ||
        attribute.kind === AttributeKind.multiSelect
      ) {
        attributeValue = attributeValue
          .map(
            (v) => attribute.options.find((o) => o.value === v)?.label.default
          )
          .filter((v) => !!v)
      }

      return { ...acc, [attribute.code]: attributeValue.join(', ') }
    },
    {}
  )
}
