import { Pipe, PipeTransform } from '@angular/core'
import { uniq } from 'lodash'
import { Observable, of } from 'rxjs'
import { ChannelsService } from '../channels.service'
import { Channel } from '../channel.model'

@Pipe({
  name: 'channels',
})
export class ChannelsPipe implements PipeTransform {
  constructor(private channelsService: ChannelsService) {}

  transform(channelIds: string[]): Observable<Channel[] | undefined> {
    channelIds = uniq(channelIds)
    channelIds = channelIds.filter((id) => !!id)
    if (!channelIds.length) {
      return of(undefined)
    }

    return this.channelsService.list$({ _id: channelIds }, true)
  }
}
