import { Component, EventEmitter, Input, Optional, Output } from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'

@Component({
  selector: 'op-select-multi-box',
  templateUrl: './select-multi-box.component.html',
  styleUrls: ['./select-multi-box.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class SelectMultiBoxComponent {
  @Input() placeholder?: string
  @Input() class?: string
  @Input() key?: string
  @Input() values?: any
  @Input() dataSource?: any
  @Input() emptyLabel?: string

  // Search params
  @Input() keyField = '_id'
  @Input() searchField = 'name'
  @Input() displayField = 'name'

  // Style
  @Input() clearButton = true
  @Input() disabled = false

  // Errors
  @Input() required = false
  @Input() requiredError?: string

  @Output() readonly valuesChange = new EventEmitter<any>()

  checkValue(value: any | null) {
    return value === null ? [] : value
  }
}
