import { Directive, HostListener, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  selector: '[tkLowercase]',
})
export class LowercaseDirective {
  @Input('tkLowercase') toLowercase = true

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange($event: any) {
    if (!this.toLowercase) {
      return
    }

    const newValue = (this.ngControl.value as string).toLowerCase()
    this.ngControl.control?.setValue(newValue)
  }
}
