import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'

import { Tag, TagSearchParams, TagTarget } from './tag.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'tags'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class TagsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new tag
   * @param tag - The tag to create
   * @returns The observable<Tag> to create the tag
   */
  create$(tag: Partial<Tag>): Observable<Tag> {
    return this._create$<Tag, Partial<Tag>>(tag)
  }

  /**
   * Read a tag by ID
   * @param tagId - The tag ID
   * @returns The observable<Tag> for read the tag
   */
  read$(tagId: string): Observable<Tag> {
    return this._read$<Tag>(tagId)
  }

  /**
   * Update a tag by ID
   * @param tagId - The tag ID
   * @param tag - The tag body to update
   * @returns The observable<Tag> for update the tag
   */
  update$(tagId: string, tag: Tag): Observable<Tag> {
    return this._update$<Tag>(tagId, tag)
  }

  /**
   * Create or update a tag by ID
   * @param tagId - The tag ID
   * @param tag - The tag body to update
   * @returns The observable<Tag> for update the tag
   */
  upsert$(tag: Tag): Observable<Tag> {
    return this._upsert$<Tag>(tag, tag._id)
  }

  /**
   * Delete a tag by ID
   * @param tagId - The tag ID
   * @returns The observable<Tag> for delete the tag
   */
  delete$(tagId: string, force?: boolean): Observable<Tag> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        force,
      },
    }

    return this._delete$<Tag>(tagId, options)
  }

  /**
   * Search tags by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Tag>> for search tags
   */
  search$(params?: TagSearchParams, returnAll = false): Observable<Page<Tag>> {
    return this._search$<Tag>(params, returnAll)
  }

  /**
   * List tags by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Tag[]> for list tags
   */
  list$(params?: TagSearchParams, returnAll = false): Observable<Tag[]> {
    return this._list$<Tag>(params, returnAll)
  }

  /**
   * Assign products to the tag
   * @param tagId - The tag ID
   * @param productIds - The product IDS to assign
   * @returns the observable<void> for assign products to the tag
   */
  assignProducts$(tagId: string, productIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${tagId}/assign-products`, {
      ids: productIds,
    })
  }

  /**
   * Assign orders to the tag
   * @param tagId - The tag ID
   * @param orderIds - The order IDS to assign
   * @returns the observable<void> for assign orders to the tag
   */
  assignOrders(tagId: string, orderIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${tagId}/assign-orders`, {
      ids: orderIds,
    })
  }

  /**
   * Generate tags store
   * @param tagKeys - the tag keys to load
   * @param tags - the tags already loaded
   * @returns the Observable<Tag[]> as store
   */
  store$(
    tagKeys: string[],
    tags: Tag[],
    key: '_id' | 'value' = '_id',
    target?: TagTarget,
  ): Observable<Tag[]> {
    tagKeys = uniq(tagKeys)
    tagKeys = difference(
      tagKeys,
      tags.map((u) => u[key]),
    )

    if (tagKeys.length === 0) {
      return of(tags)
    }

    return this.list$({ [key]: tagKeys, target }).pipe(
      map((tgs) => [...tags, ...tgs]),
    )
  }
}
