import { cloneDeep, defaultsDeep } from 'lodash'

import {
  QueryStringFilter,
  QueryStringSort,
  Manufacturer,
} from '@evologi/shared/data-access-api'
import {
  ManufacturerSearchField,
  ManufacturerSearchParams,
  ManufacturerSortField,
} from './manufacturer.model'

// Initial states

const MANUFACTURER_INITIAL_STATE: Partial<Manufacturer> = {
  name: undefined,
  externalId: undefined,
}

// Init

export function initManufacturer(
  manufacturer: Partial<Manufacturer> = {}
): Manufacturer {
  return defaultsDeep(cloneDeep(manufacturer), MANUFACTURER_INITIAL_STATE)
}

// Search

export function manufacturerSortParams(
  params: ManufacturerSearchParams,
  sort: QueryStringSort<ManufacturerSortField>
): ManufacturerSearchParams {
  const searchParams: ManufacturerSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function manufacturerFilterParams(
  params: ManufacturerSearchParams,
  filter: QueryStringFilter<ManufacturerSearchField>
): ManufacturerSearchParams {
  const searchParams: ManufacturerSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'externalId':
      if (filter.operator === '=') {
        searchParams.externalId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['externalId:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['externalId:ct'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
