import { cloneDeep, defaultsDeep } from 'lodash'
import { Payment } from './payment.model'

// Initial states

const PAYMENT_INITIAL_STATE: Partial<Payment> = {
  name: undefined,
  alternativeName: undefined,
  cashOnDelivery: false,
  enabled: true,
}

// Init functions

export function initPayment(payment: Partial<Payment> = {}): Payment {
  return defaultsDeep(cloneDeep(payment), PAYMENT_INITIAL_STATE)
}
