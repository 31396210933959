import { uniq } from 'lodash'
import {
  PickingList,
  PickingListMission,
  PickingListMissionStatus,
} from '../picking-list.model'

/**
 * Get the current mission of a picking-list
 * @param pickingList - The picking list
 * @returns the mission to pick if there is one
 */
export function getPickingMissionCurrent(
  pickingList: PickingList
): PickingListMission | undefined {
  return pickingList.missions.find(
    (mission) => mission.status === PickingListMissionStatus.processing
  )
}

/**
 * Get the current mission index inside a picking-list
 * @param pickingList - The picking list
 * @returns the mission to pick index if there is one
 */
export function getPickingMissionCurrentIndex(
  pickingList: PickingList
): number | undefined {
  return pickingList.missions.findIndex(
    (mission) => mission.status === PickingListMissionStatus.processing
  )
}

/**
 * Get the next mission of a picking-list
 * @param pickingList - The picking list
 * @returns the next mission to pick after the current one
 */
export function getPickingMissionNext(
  pickingList: PickingList
): PickingListMission | undefined {
  const currentIndex = getPickingMissionCurrentIndex(pickingList) || 0

  return (
    pickingList.missions.find(
      (m) => m.status === PickingListMissionStatus.pending
    ) ||
    pickingList.missions.find(
      (m, i) =>
        m.status === PickingListMissionStatus.skipped && i > currentIndex
    ) ||
    pickingList.missions.find(
      (m, i) =>
        m.status === PickingListMissionStatus.skipped && i < currentIndex
    )
  )
}

/**
 * Get the completed missions amount of a picking list
 * @param pickingList - The picking list
 * @returns the amount of completed missions of a picking list
 */
export function getPickingMissionsCompleted(pickingList: PickingList): number {
  return pickingList.missions.filter((m) =>
    [
      PickingListMissionStatus.canceled,
      PickingListMissionStatus.picked,
    ].includes(m.status)
  ).length
}

/**
 * Get picking list mission totes
 * @param mission - the picking mission
 * @returns the list of tote ids
 */
export function getPickingMissionTotes(mission: PickingListMission): string[] {
  let toteIds: string[] = []

  if (mission.toteId) {
    toteIds.push(mission.toteId)
  }

  if (mission.additionalToteIds) {
    toteIds = [...toteIds, ...mission.additionalToteIds]
  }

  return uniq(toteIds)
}
