import { cloneDeep, defaultsDeep, uniq } from 'lodash'
import {
  Warehouse,
  WarehouseAreaCreationParams,
  WarehouseAreaLevel,
  WarehouseSearchField,
  WarehouseSearchParams,
  WarehouseShippingInfo,
  WarehouseSortField,
  WarehouseTotes,
} from '../warehouse.model'
import { initAddress } from '../../../libs/address.lib'
import { Location } from '../../locations/location.model'
import { DeepPartial } from '../../../models/util.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'

// Initial states

const WAREHOUSE_INITIAL_STATE: Partial<Warehouse> = {
  address: initAddress(),
}

const AREA_INITIAL_STATE: Partial<WarehouseAreaCreationParams> = {}

const TOTES_INITIAL_STATE: Partial<WarehouseTotes> = {
  labelType: 'NUMBERS',
  labelPadding: 5,
}

const LEVEL_INITIAL_STATE: Partial<WarehouseAreaLevel> = {}

const SHIPPING_INFO_INITIAL_STATE: DeepPartial<WarehouseShippingInfo> = {
  address: {},
}

// Init

export function initWarehouse(warehouse: Partial<Warehouse> = {}): Warehouse {
  return defaultsDeep(cloneDeep(warehouse), WAREHOUSE_INITIAL_STATE)
}

export function initWarehouseArea(
  area: Partial<WarehouseAreaCreationParams> = {}
): WarehouseAreaCreationParams {
  return defaultsDeep(cloneDeep(area), AREA_INITIAL_STATE)
}

export function initWarehouseTotes(
  totes: Partial<WarehouseTotes> = {}
): WarehouseTotes {
  return defaultsDeep(cloneDeep(totes), TOTES_INITIAL_STATE)
}

export function initWarehouseLevel(
  level: Partial<WarehouseAreaLevel> = {}
): WarehouseAreaLevel {
  return defaultsDeep(cloneDeep(level), LEVEL_INITIAL_STATE)
}

export function initWarehouseShippingInfo(
  shippingInfo: Partial<WarehouseShippingInfo> = {}
): WarehouseShippingInfo {
  return defaultsDeep(shippingInfo, SHIPPING_INFO_INITIAL_STATE)
}

// Area

export function checkWarehouseAreas(
  warehouse: Warehouse,
  areas: Location[]
): Warehouse {
  const warehouseAreas = (warehouse.areas || []).map((a) => a._id)
  const areaIds = areas.map((a) => a._id)
  const areasToAdd = areas.filter((a) => !warehouseAreas.includes(a._id))

  return {
    ...warehouse,
    areas: [
      ...warehouse.areas.filter((a) => areaIds.includes(a._id)),
      ...areasToAdd.map((a) => ({
        _id: a._id,
        label: a.label,
      })),
    ],
  }
}

// Carriers

export function addWarehouseCarrier(
  warehouse: Warehouse,
  carrierId: string
): Warehouse {
  return {
    ...warehouse,
    carriers: uniq([...(warehouse.carriers || []), carrierId]),
  }
}

export function removeWarehouseCarrier(
  warehouse: Warehouse,
  carrierId: string
): Warehouse {
  return {
    ...warehouse,
    carriers: uniq((warehouse.carriers || []).filter((id) => id !== carrierId)),
  }
}

// Users

export function getWarehouseUserIds(warehouse: Warehouse): string[] {
  const userIds: string[] = []

  // Creation & update
  if (warehouse.createdBy) {
    userIds.push(warehouse.createdBy)
  }

  if (warehouse.updatedBy) {
    userIds.push(warehouse.updatedBy)
  }

  return uniq(userIds)
}

// Shipping info

export function addWarehouseShippingInfo(
  warehouse: Warehouse,
  shippingInfo?: Partial<WarehouseShippingInfo>
): Warehouse {
  return {
    ...warehouse,
    shippingInfo: initWarehouseShippingInfo(shippingInfo),
  }
}

export function removeWarehouseShippingInfo(warehouse: Warehouse): Warehouse {
  return {
    ...warehouse,
    shippingInfo: undefined,
  }
}

// Search

export function warehouseSortParams(
  params: WarehouseSearchParams,
  sort: QueryStringSort<WarehouseSortField>
): WarehouseSearchParams {
  const searchParams: WarehouseSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

export function warehouseFilterParams(
  params: WarehouseSearchParams,
  filter: QueryStringFilter<WarehouseSearchField>
): WarehouseSearchParams {
  const searchParams: WarehouseSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'externalId':
      if (filter.operator === '=') {
        searchParams.externalId = filter.value
      } else if (filter.operator === '<>') {
        searchParams['externalId:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['externalId:ct'] = filter.value
      }
      break
    case 'address.name':
      if (filter.operator === '=') {
        searchParams['address.name'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.name:ct'] = filter.value
      }
      break
    case 'address.address1':
      if (filter.operator === '=') {
        searchParams['address.address1'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.address1:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.address1:ct'] = filter.value
      }
      break
    case 'address.city':
      if (filter.operator === '=') {
        searchParams['address.city'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.city:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['address.city:ct'] = filter.value
      }
      break
    case 'address.province':
      if (filter.operator === '=') {
        searchParams['address.province'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.province:ne'] = filter.value
      }
      break
    case 'address.countryCode':
      if (filter.operator === '=') {
        searchParams['address.countryCode'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.countryCode:ne'] = filter.value
      }
      break
    case 'address.postalCode':
      if (filter.operator === '=') {
        searchParams['address.postalCode'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['address.postalCode:ne'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
