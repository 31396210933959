import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

// SDK
import { AUTH_ACCESS_ERRORS } from '@evologi/shared/data-access-api'

@Injectable({
  providedIn: 'root',
})
export class ModalInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // OP Error code
          const errorCode = err.error?.error

          // Close all modals
          if (AUTH_ACCESS_ERRORS.includes(errorCode)) {
            setTimeout(() => this.closeAllModals(), 100)
          }
        }

        return throwError(err)
      })
    )
  }

  closeAllModals() {
    // Check opened modals
    const openModals = document.querySelectorAll('.modal-dialog')

    if (openModals) {
      for (let i = 0; i < openModals.length; i++) {
        const modalHeader = openModals[i].getElementsByClassName('modal-header')

        if (modalHeader && modalHeader.length > 0) {
          const closeButton: any =
            modalHeader[0].getElementsByClassName('close')

          if (closeButton && closeButton.length > 0) {
            closeButton[0].click()
          }
        }
      }
    }
  }
}
