import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Observable, of, concat, throwError, timer } from 'rxjs'
import { catchError, last, map, retry, switchMap } from 'rxjs/operators'

import { Page } from '../models/util.model'
import { chunk } from 'lodash'
import { parseEntityHeaders } from '../libs/entity.lib'
import { SDKConfiguration } from '../models/config.model'

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  observe?: 'body'
  params?:
    | HttpParams
    | {
        [param: string]: string | string[]
      }
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
}

export abstract class CrudService {
  // Pagination
  protected defaultLimit = 50
  protected limit = 200

  constructor(
    protected config: SDKConfiguration,
    protected http: HttpClient,
    protected apiUrl: string,
    private extendedSearch?: boolean,
  ) {}

  /**
   * Create a new item
   * @param newItem - The item to create
   * @returns The observable<T> for create the item
   */
  protected _create$<T, B = T>(newItem: B): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}`, newItem).pipe(
      retry({
        count: 3,
        delay: (error: HttpErrorResponse) => {
          if (error.status === 503) {
            return timer(100)
          }
          return throwError(() => error)
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error)
      }),
    )
  }

  /**
   * Read an item by ID
   * @param itemId - The item ID
   * @returns The observable<T> for read the item
   */
  protected _read$<T>(itemId: string): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${itemId}`).pipe(
      retry({
        count: 3,
        delay: (error: HttpErrorResponse) => {
          if (error.status === 503) {
            return timer(100)
          }
          return throwError(() => error)
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error)
      }),
    )
  }

  /**
   * Update an item by ID
   * @param itemId - The item ID
   * @param item - The item body to update
   * @returns The observable<T> for update the item
   */
  protected _update$<T, B = T>(itemId: string, body: B): Observable<T> {
    return this.http
      .put<T>(`${this.apiUrl}/${itemId}`, body, {
        headers: parseEntityHeaders<B>(body),
      })
      .pipe(
        retry({
          count: 3,
          delay: (error: HttpErrorResponse) => {
            if (error.status === 503) {
              return timer(100)
            }
            return throwError(() => error)
          },
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error)
        }),
      )
  }

  /**
   * Create or update an item
   * @param item - The item body to update
   * @returns The observable<T> for update the item
   */
  protected _upsert$<T, B = T>(body: B, itemId?: string): Observable<T> {
    return itemId
      ? this._update$<T, B>(itemId, body).pipe(
          retry({
            count: 3,
            delay: (error: HttpErrorResponse) => {
              if (error.status === 503) {
                return timer(100)
              }
              return throwError(() => error)
            },
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error)
          }),
        )
      : this._create$<T, B>(body).pipe(
          retry({
            count: 3,
            delay: (error: HttpErrorResponse) => {
              if (error.status === 503) {
                return timer(100)
              }
              return throwError(() => error)
            },
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error)
          }),
        )
  }

  /**
   * Delete an item by ID
   * @param itemId - The item ID
   * @returns The observable<T> for delete the item
   */
  protected _delete$<T>(itemId: string, options?: HttpOptions): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}/${itemId}`, options).pipe(
      retry({
        count: 3,
        delay: (error: HttpErrorResponse) => {
          if (error.status === 503) {
            return timer(100)
          }
          return throwError(() => error)
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error)
      }),
    )
  }

  /**
   * Search items by params:
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<any> for search items:
   *   1 - Limitless parameters or within the limits returns limited items
   *   2 - Parameters with limit greater than allowed returns a concatenation
   *       of simple searches paginated by limit
   *   3 - Limitless parameters with returnAll flag returns a concatenation
   *       of simple searches paginated by limit
   */
  protected _search$<T>(params?: any, returnAll = false): Observable<Page<T>> {
    if (params?.limit > this.limit) {
      return this.__searchUnlimited$<T>(params).pipe(
        retry({
          count: 3,
          delay: (error: HttpErrorResponse) => {
            if (error.status === 503) {
              return timer(100)
            }
            return throwError(() => error)
          },
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error)
        }),
      )
    }

    // Search by KEY check
    if (
      !this.extendedSearch &&
      params &&
      params['limit'] === undefined &&
      params['_id'] &&
      Array.isArray(params['_id']) &&
      params['_id'].length > this.limit
    ) {
      return this.__searchByIds$(params)
    }

    const search$ = this.extendedSearch
      ? this.http.post<Page<T>>(`${this.apiUrl}/search`, params).pipe(
          retry({
            count: 3,
            delay: (error: HttpErrorResponse) => {
              if (error.status === 503) {
                return timer(100)
              }
              return throwError(() => error)
            },
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error)
          }),
        )
      : this.http.get<Page<T>>(`${this.apiUrl}`, { params }).pipe(
          retry({
            count: 3,
            delay: (error: HttpErrorResponse) => {
              if (error.status === 503) {
                return timer(100)
              }
              return throwError(() => error)
            },
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error)
          }),
        )

    return search$.pipe(
      switchMap((res) =>
        returnAll && res.totalCount > this.defaultLimit
          ? this.__searchUnlimited$<T>({ ...params, limit: res.totalCount })
          : of(res),
      ),
    )
  }

  /**
   * List items by params
   * @param params - The search params
   * @param returnAll - The returnAll flag
   * @returns The observable<T[]> for list items
   */
  protected _list$<T>(params?: any, returnAll = false): Observable<T[]> {
    return this._search$<T>(params, returnAll)
      .pipe(map((res) => res.data))
      .pipe(
        retry({
          count: 3,
          delay: (error: HttpErrorResponse) => {
            if (error.status === 503) {
              return timer(100)
            }
            return throwError(() => error)
          },
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error)
        }),
      )
  }

  /**
   * Search items without limit by params
   * @param params - the search params
   * @returns the observable for search items
   */
  private __searchUnlimited$<T>(params: any): Observable<Page<T>> {
    const pages$ = this.__searchPages$<T>(params, params.limit)
    let response: Page<T> = {
      totalCount: 0,
      data: [],
    }

    return concat(...pages$).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      }),
      last(),
    )
  }

  /**
   * Search items by IDs paginated
   * @param params - the search params
   * @returns the observable for search items
   */
  private __searchByIds$<T>(params: any): Observable<Page<T>> {
    const pages$ = this.__searchIdsPages$<T>(params)
    let response: Page<T> = {
      totalCount: 0,
      data: [],
    }

    return concat(...pages$).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      }),
      last(),
    )
  }

  private __searchPages$<T>(
    params: any,
    totalCount: number,
  ): Observable<Page<T>>[] {
    const observables$: Observable<Page<T>>[] = []
    let offset = 0

    while (offset <= totalCount) {
      observables$.push(this._search$({ ...params, limit: this.limit, offset }))
      offset += this.limit
    }

    return observables$
  }

  private __searchIdsPages$<T>(params: any): Observable<Page<T>>[] {
    const observables$: Observable<Page<T>>[] = []
    const ids = chunk(params['_id'], this.limit)

    for (const idsChunk of ids) {
      observables$.push(this._search$({ ...params, _id: idsChunk }))
    }

    return observables$
  }

  /**
   * Search item children by params
   * @param itemId  - The item ID
   * @param childModel - The children path
   * @param params - The search params
   * @param returnAll - The returnAll flag
   */
  protected _searchChildren$<T>(
    itemId: string,
    childModel: string,
    params?: any,
    returnAll = false,
  ): Observable<Page<T>> {
    if (params?.limit > this.limit) {
      return this.__searchChildrenUnlimited$<T>(itemId, childModel, params)
    }

    return this.http
      .get<Page<T>>(`${this.apiUrl}/${itemId}/${childModel}`, {
        params,
      })
      .pipe(
        switchMap((res) =>
          returnAll
            ? this.__searchChildrenUnlimited$<T>(itemId, childModel, {
                ...params,
                limit: res.totalCount,
              })
            : of(res),
        ),
      )
  }

  /**
   * List item children by params
   * @param itemId  - The item ID
   * @param childModel - The children path
   * @param params - The search params
   * @param returnAll - The returnAll flag
   * @returns The observable<T[]> for list items
   */
  protected _listChildren$<T>(
    itemId: string,
    childModel: string,
    params?: any,
    returnAll = false,
  ): Observable<T[]> {
    return this._searchChildren$<T>(itemId, childModel, params, returnAll).pipe(
      map((res) => res.data),
    )
  }

  /**
   * Find an item by params, throw error otherwise
   * @param params - The search params
   * @returns the observable<T> for read the item
   */
  protected _readOne$<T>(params?: any): Observable<T> {
    return this._list$<T>(params).pipe(
      map((items) => {
        if (!items.length) {
          throw new Error('ITEM_NOT_FOUND')
        }

        return items[0]
      }),
    )
  }

  /**
   * List an item by params
   * @param params - the search params
   * @returns the observable<T> for find the item
   */
  protected _findOne$<T>(params?: any): Observable<T | undefined> {
    return this._list$<T>(params).pipe(
      map((items) => {
        if (!items) {
          return undefined
        }

        return items[0]
      }),
    )
  }

  /**
   * Search item children without limit by params
   * @param itemId  - The item ID
   * @param childModel - The children path
   * @param params
   */
  protected __searchChildrenUnlimited$<T>(
    itemId: string,
    childModel: string,
    params: any,
  ): Observable<Page<T>> {
    const pages$ = this.__searchChildrenPages$<T>(
      itemId,
      childModel,
      params,
      params.limit,
    )
    let response: Page<T> = {
      totalCount: 0,
      data: [],
    }

    return concat(...pages$).pipe(
      map((res) => {
        response = {
          totalCount: response.totalCount + res.totalCount,
          data: [...response.data, ...res.data],
        }

        return response
      }),
      last(),
    )
  }

  protected __searchChildrenPages$<T>(
    itemId: string,
    childModel: string,
    params: any,
    totalCount: number,
  ): Observable<Page<T>>[] {
    const observables$: Observable<Page<T>>[] = []
    let offset = 0

    while (offset <= totalCount) {
      observables$.push(
        this._searchChildren$(itemId, childModel, {
          ...params,
          limit: this.limit,
          offset,
        }),
      )
      offset += this.limit
    }

    return observables$
  }
}
