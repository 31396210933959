import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Replenishment,
  ReplenishmentCreationParams,
  ReplenishmentMoveParams,
  ReplenishmentSearchParams,
  ReplenishmentUpdateParams,
} from './replenishment.model'
import { Page } from '../../models/util.model'

const MODEL = 'replenishments'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ReplenishmentsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new replenishment
   * @param params - the creation params
   * @returns the observable<Replenishment> for create the replenishment
   */
  create$(
    creationParams: ReplenishmentCreationParams,
  ): Observable<Replenishment> {
    return this._create$<Replenishment, ReplenishmentCreationParams>(
      creationParams,
    )
  }

  /**
   * Read a replenishment by ID
   * @param replenishmentId - the replenishment ID
   * @returns the obsevable<Replenishment> for read the replenishment
   */
  read$(replenishmentId: string): Observable<Replenishment> {
    return this._read$<Replenishment>(replenishmentId)
  }

  /**
   * Update a replenishment
   * @param replenishmentId - the replenishment ID
   * @returns the observable<Replenishment> for cancel the replenishment
   */
  update$(
    replenishmentId: string,
    updateParams: ReplenishmentUpdateParams,
  ): Observable<Replenishment> {
    return this._update$<Replenishment, ReplenishmentUpdateParams>(
      replenishmentId,
      updateParams,
    )
  }

  /**
   * Search replenishments by params
   * @param searchParams - the search params
   * @returns the observable
   */
  search$(
    searchParams?: ReplenishmentSearchParams,
  ): Observable<Page<Replenishment>> {
    return this._search$<Replenishment>(searchParams)
  }

  /**
   * List products by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Product[]> for list products
   */
  list$(params?: ReplenishmentSearchParams): Observable<Replenishment[]> {
    return this._list$<Replenishment>(params)
  }

  /**
   * Manage a replenishment
   * @param replenishmentId - the replenishment ID
   * @returns the observable<Replenishment> for manage the replenishment
   */
  manage$(replenishmentId: string): Observable<Replenishment> {
    return this.http.post<Replenishment>(
      `${this.apiUrl}/${replenishmentId}/manage`,
      {},
    )
  }

  /**
   * Move a replenishment
   * @param replenishmentId - the replenishment ID
   * @param params - the move params
   * @returns the observable<Replenishment> for move the replenishment
   */
  move$(
    replenishmentId: string,
    params: ReplenishmentMoveParams,
  ): Observable<Replenishment> {
    return this.http.post<Replenishment>(
      `${this.apiUrl}/${replenishmentId}/move`,
      params,
    )
  }

  /**
   * Change location of a replenishmnet
   * @param replenishmentId - the replenishment ID
   * @returns the observable<Replenishment> for cancel the replenishment
   */
  cancel$(replenishmentId: string): Observable<Replenishment> {
    return this.http.post<Replenishment>(
      `${this.apiUrl}/${replenishmentId}/cancel`,
      {},
    )
  }

  /**
   * Change location of a replenishmnet
   * @param replenishmentId - the replenishment ID
   * @returns the observable<Replenishment> for cancel the replenishment
   */
  upload$(
    file: any,
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[]
        }
      | undefined,
  ): any {
    return this.http.post<any>(this.getUploadReplenishmentsUrl(), file, {
      headers,
    })
  }

  /**
   * Get replenishments upload url
   * @returns the url to create and edit replenishments by file
   */
  getUploadReplenishmentsUrl(): string {
    return `${this.apiUrl}/blob`
  }
}
