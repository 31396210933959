import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import {
  CarrierAvailable,
  CarrierFreightType,
  CarrierIncoterm,
  CarrierParcelType,
  CarrierPaymentMethod,
  CarrierProvider,
  CarrierStatus,
  CarrierType,
  CarriersTab,
} from './carrier.model'

export const CARRIER_SCOPE: PermissionsScope = 'carriers'

export const CARRIER_STATUSES = [
  {
    code: CarrierStatus.inactive,
    name: 'Disattivo',
  },
  {
    code: CarrierStatus.active,
    name: 'Attivo',
  },
]

export const CARRIER_PROVIDERS: SelectionData<CarrierProvider>[] = [
  {
    value: CarrierProvider.evologi,
    label: 'OrderPod',
    logo: 'assets/img/logo-orderpod.png',
  },
  {
    value: CarrierProvider.gsped,
    label: 'Gsped',
    logo: 'assets/img/carriers/gsped.png',
  },
  {
    value: CarrierProvider.shippypro,
    label: 'ShippyPro',
    logo: 'assets/img/carriers/shippypro.png',
  },
]

export const CARRIER_COMMON_FIELDS = [
  {
    field: 'goodsDescription',
    label: 'Descrizione merce',
    type: 'text',
    providers: [
      CarrierProvider.evologi,
      CarrierProvider.gsped,
      CarrierProvider.shippypro,
    ],
    required: true,
  },
  {
    field: 'incoterm',
    label: 'Incoterm',
    type: 'enum',
    options: [
      {
        value: CarrierIncoterm.dap,
        label: 'DAP - Delivered At Place',
      },
      {
        value: CarrierIncoterm.ddp,
        label: 'DDP - Delivered Duty Paid',
      },
    ],
    providers: [
      CarrierProvider.evologi,
      CarrierProvider.gsped,
      CarrierProvider.shippypro,
    ],
  },
  {
    field: 'gspedClientId',
    label: 'ID Cliente GSPED',
    type: 'text',
    providers: [CarrierProvider.gsped],
    required: true,
  },
]

export const CARRIER_TYPES: CarrierAvailable[] = [
  {
    label: 'GLS Nazionale (Italy)',
    active: true,
    externalId: 101,
    logo: 'assets/img/carriers/GLS_logo.png',
    value: CarrierType.gls,
    providers: [CarrierProvider.evologi, CarrierProvider.gsped],
    trackingLink:
      'https://www.gls-italy.com/?option=com_gls&view=track_e_trace&mode=search&numero_spedizione=%s&tipo_codice=nazionale',
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'sedeGls',
        label: 'Sede',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'codiceClienteGls',
        label: 'Codice cliente',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'passwordClienteGls',
        label: 'Password',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'codiceContrattoGls',
        label: 'Codice contratto',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'weight',
        label: 'Peso reale',
        type: 'number',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'freightType',
        label: 'Tipologia porto',
        type: 'enum',
        options: [
          {
            value: CarrierFreightType.a,
            label: 'Porto assegnato',
          },
          {
            value: CarrierFreightType.f,
            label: 'Porto franco',
          },
        ],
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'parcelType',
        label: 'Tipologia collo',
        type: 'enum',
        options: [
          {
            value: CarrierParcelType.normal,
            label: 'Normale',
          },
          {
            value: CarrierParcelType.plus,
            label: 'Plus',
          },
        ],
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'paymentMethod',
        label: 'Modalità incasso',
        type: 'enum',
        options: [
          {
            value: CarrierPaymentMethod.ac,
            label: 'Assegno circolare',
          },
          {
            value: CarrierPaymentMethod.ab,
            label: 'Assegno bancario',
          },
          {
            value: CarrierPaymentMethod.ap,
            label: 'Assegno postale',
          },
          {
            value: CarrierPaymentMethod.cont,
            label: 'Contante',
          },
          {
            value: CarrierPaymentMethod.ass,
            label: 'Assegno CIRC/BANC/POST',
          },
          {
            value: CarrierPaymentMethod.abp,
            label: 'Assegno BANC/POST',
          },
          {
            value: CarrierPaymentMethod.asr,
            label: 'Assegno come rilasciato',
          },
          {
            value: CarrierPaymentMethod.arm,
            label: 'Assegno come rilasciato intestato al mittente',
          },
          {
            value: CarrierPaymentMethod.abc,
            label: 'Assegno banc/circ no postale',
          },
          {
            value: CarrierPaymentMethod.asrp,
            label: 'Assegno come rilasciato no postale',
          },
          {
            value: CarrierPaymentMethod.armp,
            label: 'Assegno come rilasciato intestato mittente no postale',
          },
        ],
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'volumeWeightRatio',
        label: 'Rapporto peso/volume',
        type: 'number',
        providers: [CarrierProvider.evologi],
      },
    ],
    services: [
      {
        value: 0,
        name: 'Espresso nazionale',
      },
      {
        value: 6,
        name: 'Parcel',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'GLS Internazionale',
    active: true,
    externalId: 130,
    logo: 'assets/img/carriers/gls-int_logo.png',
    value: CarrierType.glsInt,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Internazionale',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'FedEx',
    active: true,
    externalId: 102,
    logo: 'assets/img/carriers/fedex_logo.png',
    value: CarrierType.fdx,
    providers: [CarrierProvider.evologi, CarrierProvider.gsped],
    trackingLink:
      'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=%s',
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'accountNumber',
        label: 'Account number',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'metterNumber',
        label: 'Metter number',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'accessKey',
        label: 'Access key',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'login',
        property: 'accessKeyPassword',
        label: 'Access key password',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
    ],
    services: [
      {
        value: 0,
        name: 'Priority Overnight',
      },
      {
        value: 6,
        name: 'International Priority',
      },
      {
        value: 7,
        name: 'International First',
      },
      {
        value: 8,
        name: 'International Economy',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Generico',
    active: true,
    logo: 'assets/img/carriers/truck.png',
    value: CarrierType.generic,
    providers: [CarrierProvider.evologi],
  },
  {
    label: 'Automatico',
    active: true,
    externalId: 0,
    logo: 'assets/img/carriers/gsped.png',
    value: CarrierType.auto,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Nazionale - standard',
      },
      {
        value: 1,
        name: 'Nazionale - ore 12',
      },
      {
        value: 2,
        name: 'Nazionale - ore 10',
      },
      {
        value: 3,
        name: 'Nazionale - messaggeria',
      },
      {
        value: 6,
        name: 'Estero via aerea - standard',
      },
      {
        value: 7,
        name: 'Estero via aerea - ore 12',
      },
      {
        value: 8,
        name: 'Estero via terra - standard',
      },
      {
        value: 9,
        name: 'Estero via terra - ore 12',
      },
    ],
  },
  {
    label: 'BRT',
    active: true,
    externalId: 1,
    logo: 'assets/img/carriers/BRT_logo.png',
    value: CarrierType.brt,
    providers: [CarrierProvider.evologi],
    trackingLink: 'https://vas.brt.it/vas/sped_det_show.hsm?chisono=%s&ksu=%s',
    fields: [
      {
        field: 'brt',
        property: 'userId',
        label: 'ID utente',
        type: 'text',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'password',
        label: 'Password',
        type: 'password',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'senderCustomerCode',
        label: 'Codice numerico mittente',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'deliveryFreightTypeCode',
        label: 'Tipologia PORTO',
        type: 'enum',
        providers: [CarrierProvider.evologi],
        options: [
          {
            value: 'DAP',
            label: 'Porto franco',
          },
          {
            value: 'EXW',
            label: 'Porto assegnato',
          },
        ],
        required: true,
      },
      {
        field: 'brt',
        property: 'departureDepot',
        label: 'Num. mag. partenza',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'weight',
        label: 'Peso di default',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'codPaymentType',
        label: 'Metodo di pagamento contrassegno',
        type: 'enum',
        providers: [CarrierProvider.evologi],
        options: [
          {
            value: 'BM',
            label: 'Accettare assegno bancario intestato al mittente',
          },
          {
            value: 'CM',
            label: 'Accettare assegno circolare intestato al mittente',
          },
          {
            value: 'BB',
            label:
              'Accettare assegno bancario intestato al corriere con MANLEVA',
          },
          {
            value: 'OM',
            label: 'Accettare assegno intestato al mittente originale',
          },
          {
            value: 'OC',
            label:
              'Accettare assegno circolare intestato al mittente originale',
          },
          {
            value: 'TM',
            label: 'Accettare titoli come rilasciati',
          },
        ],
      },
      {
        field: 'brt',
        property: 'parcelsHandlingCode',
        label: 'Codice trattamento merce',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'brt',
        property: 'pricingConditionCode',
        label: 'Numero codice tariffa',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'brt',
        property: 'isAlertRequired',
        label: 'Notifica via email/telefono',
        type: 'checkbox',
        providers: [CarrierProvider.evologi],
      },
    ],
    services: [
      {
        value: 0,
        name: 'BRT Express',
      },
      {
        value: 1,
        name: 'BRT Priority',
      },
      {
        value: 2,
        name: 'BRT 10:30',
      },
    ],
  },
  {
    label: 'BRT',
    active: true,
    externalId: 1,
    logo: 'assets/img/carriers/BRT_logo.png',
    value: CarrierType.brt,
    providers: [CarrierProvider.gsped],
    trackingLink: 'https://vas.brt.it/vas/sped_det_show.hsm?chisono=%s&ksu=%s',
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'VABCCM',
        label: 'VABCCM',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'login',
        property: 'VABLNP',
        label: 'VABLNP',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'login',
        property: 'usernameFtp',
        label: 'Username FTP',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'login',
        property: 'passwordFtp',
        label: 'Password FTP',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'customSettings',
        property: 'codiceTariffaIT',
        label: 'Codice tariffa IT',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'customSettings',
        property: 'codiceTariffaEU',
        label: 'Codice tariffa EU',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
      {
        field: 'customSettings',
        property: 'emailPrenotazioneRitiri',
        label: 'Email prenotazione ritiri',
        type: 'text',
        providers: [CarrierProvider.gsped],
      },
    ],
    services: [
      {
        value: 0,
        name: 'BRT Express',
      },
      {
        value: 1,
        name: 'BRT Priority',
      },
      {
        value: 2,
        name: 'BRT 10:30',
      },
      {
        value: 3,
        name: 'BRT Messaggeria',
      },
      {
        value: 6,
        name: 'BRT ESTERO DPD',
      },
      {
        value: 7,
        name: 'BRT ESTERO EUROEXPRESS',
      },
      {
        value: 8,
        name: 'BRT ESTERO FEDEX',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Amazon Shipping',
    externalId: 12,
    active: true,
    logo: 'assets/img/carriers/amazon_shipping.png',
    value: CarrierType.amazon,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Servizio standard',
      },
    ],
    servicesConfiguration: false,
    trackingLink: 'https://track.amazon.it/tracking/',
  },
  {
    label: 'TNT',
    active: true,
    externalId: 2,
    logo: 'assets/img/carriers/TNT_logo.png',
    value: CarrierType.tnt,
    providers: [CarrierProvider.gsped],
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'codiceCliente',
        label: 'Codice cliente',
        type: 'text',
      },
      {
        field: 'login',
        property: 'username',
        label: 'Username',
        type: 'text',
      },
      {
        field: 'login',
        property: 'password',
        label: 'Password',
        type: 'text',
      },
      {
        field: 'login',
        property: 'codiceContratto',
        label: 'Codice contratto',
        type: 'text',
      },
    ],
    services: [
      {
        value: 0,
        name: 'Nazionale Express',
      },
    ],
    servicesConfiguration: false,
  },
  {
    label: 'UPS',
    active: true,
    externalId: 103,
    logo: 'assets/img/carriers/ups_logo.png',
    value: CarrierType.ups,
    providers: [CarrierProvider.gsped],
    trackingLink:
      'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=%s',
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'accountNumber',
        label: 'Numero contratto',
        type: 'text',
      },
      {
        field: 'login',
        property: 'userId',
        label: 'ID Utente',
        type: 'text',
      },
      {
        field: 'login',
        property: 'password',
        label: 'Password',
        type: 'text',
      },
      {
        field: 'login',
        property: 'accessKey',
        label: 'Chiave accesso',
        type: 'text',
      },
    ],
    services: [
      {
        value: 0,
        name: 'Nazionale Express Saver',
      },
      {
        value: 1,
        name: 'Nazionale Express',
      },
      {
        value: 2,
        name: 'Nazionale Express Plus',
      },
      {
        value: 3,
        name: 'Nazionale Standard',
      },
      {
        value: 6,
        name: 'Internazionale Express',
      },
      {
        value: 7,
        name: 'Internazionale Express Plus',
      },
      {
        value: 8,
        name: 'Internazionale Standard',
      },
      {
        value: 9,
        name: 'Internazionale Saver',
      },
      {
        value: 10,
        name: 'Expedited',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'DHL',
    active: true,
    externalId: 104,
    logo: 'assets/img/carriers/DHL-express_logo.png',
    value: CarrierType.dhl,
    providers: [CarrierProvider.gsped],
    trackingLink: 'http://www.dhl.com/cgi-bin/tracking.pl?AWB=%s',
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Domestic Express',
      },
      {
        value: 1,
        name: 'Domestic Express H12',
      },
      {
        value: 2,
        name: 'Domestic Express H10:30',
      },
      {
        value: 4,
        name: 'Domestic Express H9',
      },
      {
        value: 7,
        name: 'Express Worldwide H12',
      },
      {
        value: 8,
        name: 'Economy Select',
      },
      {
        value: 9,
        name: 'Express Worldwide',
      },
      {
        value: 10,
        name: 'Express Worldwide H9',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'GLS',
    active: true,
    value: CarrierType.glsItaly,
    providers: [CarrierProvider.shippypro],
    trackingLink:
      'https://www.gls-italy.com/?option=com_gls&view=track_e_trace&mode=search&numero_spedizione=%s&tipo_codice=nazionale',
  },
  {
    label: 'SpringXBS',
    active: true,
    value: CarrierType.spring,
    providers: [CarrierProvider.shippypro],
    trackingLink: 'https://www.mailingtechnology.com/tracking/?tn=%s',
  },
  {
    label: 'SDA',
    active: true,
    externalId: 105,
    logo: 'assets/img/carriers/SDA_logo.png',
    value: CarrierType.sda,
    providers: [CarrierProvider.gsped],
    trackingLink:
      'https://www.sda.it/wps/portal/Servizi_online/dettaglio-spedizione?locale=it&tracing.letteraVettura=%s',
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'codicePostazione',
        label: 'Codice postazione',
        type: 'text',
      },
      {
        field: 'login',
        property: 'codiceCliente',
        label: 'Codice cliente',
        type: 'text',
      },
      {
        field: 'login',
        property: 'usernameFtp',
        label: 'Username FTP',
        type: 'text',
      },
      {
        field: 'login',
        property: 'passwordFtp',
        label: 'Password FTP',
        type: 'password',
      },
    ],
    services: [
      {
        value: 0,
        name: 'Extralarge',
      },
      {
        value: 1,
        name: 'CRONO',
      },
      {
        value: 2,
        name: 'CRONO Express',
      },
      {
        value: 3,
        name: 'CRONO Economy',
      },
      {
        value: 6,
        name: 'EXPORT BOX',
      },
      {
        value: 7,
        name: 'ROAD EUROPE - EURODIS',
      },
      {
        value: 8,
        name: 'CRONO Internazionale',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Nexive',
    active: true,
    externalId: 114,
    logo: 'assets/img/carriers/nexive_logo.png',
    value: CarrierType.nexive,
    providers: [CarrierProvider.gsped],
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'username',
        label: 'Username',
        type: 'text',
      },
      {
        field: 'login',
        property: 'password',
        label: 'Password',
        type: 'text',
      },
      {
        field: 'login',
        property: 'userIdToImpersonate',
        label: 'User Id To Impersonate',
        type: 'text',
      },
    ],
    services: [
      {
        value: 0,
        name: 'Sistema Completo',
      },
      {
        value: 1,
        name: 'Sistema SLIM',
      },
      {
        value: 2,
        name: 'Sistema ECONOMY',
      },
      {
        value: 3,
        name: 'Sistema ESPRESSO',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Milkman',
    active: true,
    externalId: 129,
    logo: 'assets/img/carriers/milkman_logo.png',
    value: CarrierType.mil,
    providers: [CarrierProvider.gsped],
    fields: [
      ...CARRIER_COMMON_FIELDS,
      {
        field: 'login',
        property: 'apiKey',
        label: 'API Key',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'firstAvailabilityDay',
        label: 'Giorno disponibilità merce',
        type: 'enum',
        options: [
          {
            value: 'Oggi',
            label: 'Data odierna',
          },
          {
            value: 'GG lavorativo successivo',
            label: 'Giorno lavorativo successivo',
          },
        ],
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'firstAvailabilityHour',
        label: 'Ora disponibilità merce (HH)',
        type: 'number',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'customSettings',
        property: 'subsidyCost',
        label: 'Limite costo sussidiato',
        type: 'number',
        providers: [CarrierProvider.evologi],
      },
    ],
    services: [
      {
        value: 0,
        name: 'Servizio standard',
      },
      {
        value: 1,
        name: 'Servizio premium',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Fercam',
    active: true,
    externalId: 131,
    logo: 'assets/img/carriers/fercam_logo.png',
    value: CarrierType.fercam,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Servizio standard',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'SGT',
    active: true,
    externalId: 132,
    logo: 'assets/img/carriers/sgt_logo.png',
    value: CarrierType.sgt,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    services: [
      {
        value: 0,
        name: 'Servizio standard',
      },
      {
        value: 1,
        name: 'Servizio ore 12',
      },
      {
        value: 2,
        name: 'Servizio ore 10',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Poste Delivery Business',
    active: true,
    externalId: 166,
    logo: 'assets/img/carriers/poste_italiane_logo.png',
    value: CarrierType.poste,
    providers: [CarrierProvider.gsped],
    fields: [...CARRIER_COMMON_FIELDS],
    trackingLink:
      'https://www.poste.it/cerca/index.html#/risultati-spedizioni/%s',
    services: [
      {
        value: 0,
        name: 'Servizio standard',
      },
      {
        value: 1,
        name: 'Servizio express',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Artoni',
    active: false,
    externalId: 5,
    logo: 'assets/img/carriers/artoni_logo.png',
    value: CarrierType.artoni,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'DHL Freight',
    active: false,
    logo: 'assets/img/carriers/DHL-freight_logo.png',
    value: CarrierType.dhlFre,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'DMM Corriere',
    active: false,
    logo: 'assets/img/carriers/DMM_logo.png',
    value: CarrierType.dmm,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'Citi Dist.',
    active: false,
    logo: 'assets/img/carriers/citi_logo.png',
    value: CarrierType.ctd,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'DHL Paket',
    active: false,
    logo: 'assets/img/carriers/DHL-paket_logo.png',
    value: CarrierType.dhlPkt,
    providers: [CarrierProvider.gsped],
    services: [
      {
        value: 0,
        name: 'Paket',
      },
      {
        value: 6,
        name: 'WeltPaket',
      },
    ],
    servicesConfiguration: true,
  },
  {
    label: 'Sailpost',
    active: false,
    logo: 'assets/img/carriers/sailpost_logo.png',
    value: CarrierType.spt,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'DB Schenker',
    active: false,
    logo: 'assets/img/carriers/DB_Schenker_logo.png',
    value: CarrierType.dbs,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'Züst',
    active: false,
    logo: 'assets/img/carriers/zust_logo.png',
    value: CarrierType.zst,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'Tiesse',
    active: false,
    logo: 'assets/img/carriers/tiesse_logo.png',
    value: CarrierType.tss,
    providers: [CarrierProvider.gsped],
  },
  {
    label: 'DPD',
    active: true,
    logo: 'assets/img/carriers/DPD_logo.png',
    value: CarrierType.dpd,
    providers: [CarrierProvider.evologi],
    trackingLink: 'https://vas.brt.it/vas/sped_det_show.hsm?chisono=%s&ksu=%s',
    fields: [
      {
        field: 'brt',
        property: 'userId',
        label: 'ID utente',
        type: 'text',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'password',
        label: 'Password',
        type: 'password',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'senderCustomerCode',
        label: 'Codice numerico mittente',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'departureDepot',
        label: 'Num. mag. partenza',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'weight',
        label: 'Peso di default',
        type: 'number',
        providers: [CarrierProvider.evologi],
        required: true,
      },
      {
        field: 'brt',
        property: 'parcelsHandlingCode',
        label: 'Codice trattamento merce',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'brt',
        property: 'pricingConditionCode',
        label: 'Numero codice tariffa',
        type: 'text',
        providers: [CarrierProvider.evologi],
      },
      {
        field: 'brt',
        property: 'isAlertRequired',
        label: 'Notifica via email/telefono',
        type: 'checkbox',
        providers: [CarrierProvider.evologi],
      },
    ],
    services: [
      {
        value: 0,
        name: 'DPD Express',
      },
    ],
  },
]

export const CARRIERS_TABS: SelectionData<CarriersTab>[] = [
  {
    value: 'general',
    label: 'Generale',
  },
  {
    value: 'settings',
    label: 'Impostazioni',
  },
  { value: 'services', label: 'Servizi' },
]
