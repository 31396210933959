import {
  Order,
  OrderRow,
  initOrder,
  initOrderHeader,
  initOrderRow,
  updateOrderAmounts,
} from '../../orders'
import { ProductType } from '../../products'
import {
  GoodsReturn,
  GoodsReturnOrder,
  GoodsReturnRecoveryOrder,
  GoodsReturnReferenceOrder,
  GoodsReturnRow,
} from '../goods-return.model'

function createOrder(order: Order): GoodsReturnOrder {
  return {
    _id: order._id,
    number: order.header.orderNumber!,
    date: order.header.date!,
    refOrder: order.header.rifOrder,
    refDate: order.header.rifDate,
  }
}

export function createGoodsReturnReferenceOrder(
  order: Order
): GoodsReturnReferenceOrder {
  return {
    ...createOrder(order),
    type: 'CUSTOMER',
  }
}

export function createGoodsReturnRecoveryOrder(
  order: Order
): GoodsReturnRecoveryOrder {
  return createOrder(order)
}

export function createOrderByGoodsReturn(goodsReturn: GoodsReturn): Order {
  return updateOrderAmounts(
    initOrder({
      type: 'CUSTOMER',
      assignedWarehouseId: goodsReturn.warehouseId,
      header: initOrderHeader({
        channel: goodsReturn.header.channelId,
        currency: goodsReturn.header.currency,
        paymentType: goodsReturn.header.paymentId,
        shippingAddress: goodsReturn.header.shippingAddress,
        billingAddress: goodsReturn.header.billingAddress,
      }),
      rows: goodsReturn.rows.map((row) => createOrderRow(row)),
    })
  )
}

function createOrderRow(row: GoodsReturnRow): OrderRow {
  return initOrderRow({
    product: {
      _id: row.product._id,
      SKU: row.product.SKU,
      name: row.product.name,
      productType: ProductType.simple,
    },
    orderedQty: row.returnedQty,
    productTaxRate: row.productTaxRate,
    unitPrice: row.unitPrice,
    unitPriceWithTaxes: row.unitPriceWithTaxes,
  })
}
