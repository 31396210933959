import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Computer,
  ComputerSearchParams,
  Printer,
  PrinterSearchParams,
} from './print-node.model'
import { Page } from '../../models/util.model'

const MODEL = 'printnode'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class PrintNodeService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`
    )
  }

  /**
   * Search computers by params
   * @param params - The search params
   * @returns the observable<Page<Computer>> for search computers
   */
  searchComputers$(params?: ComputerSearchParams): Observable<Page<Computer>> {
    return this.http
      .get<Page<Computer>>(`${this.apiUrl}/computers`, {
        params: params as any,
      })
      .pipe(
        map((res) => ({
          ...res,
          data: res.data.map((c) => ({
            ...c,
            id: String(c.id),
          })),
        }))
      )
  }

  /**
   * List computers by params
   * @param params - The search params
   * @returns the observable<Computer[]> for list computers
   */
  listComputers$(params?: ComputerSearchParams): Observable<Computer[]> {
    return this.searchComputers$(params).pipe(map((res) => res.data))
  }

  /**
   * Search printers linked to a computer
   * @param computerId - The computer ID
   * @param params - The search params
   * @returns the observable<Page<Printer>> for search printers
   */
  searchComputerPrinters$(
    computerId: string,
    params?: PrinterSearchParams
  ): Observable<Page<Printer>> {
    return this.http
      .get<Page<Printer>>(`${this.apiUrl}/computers/${computerId}/printers`, {
        params: params as any,
      })
      .pipe(
        map((res) => ({
          ...res,
          data: res.data.map((p) => ({
            ...p,
            id: String(p.id),
          })),
        }))
      )
  }

  /**
   * List printers linked to a computer
   * @param computerId - The computer ID
   * @param params - The search params
   * @returns the observable<Printer[]> for list printers
   */
  listComputerPrinters$(
    computerId: string,
    params?: PrinterSearchParams
  ): Observable<Printer[]> {
    return this.searchComputerPrinters$(computerId, params).pipe(
      map((res) => res.data)
    )
  }
}
