import { Pipe, PipeTransform } from '@angular/core'
import { CatalogLocales } from '../../../models/util.model'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'
import { CATALOG_DEFAULT_CODE } from '../catalog.const'

@Pipe({
  name: 'catalogValue',
})
export class CatalogValuePipe implements PipeTransform {
  transform(
    values: CatalogLocales<any>[] | undefined,
    catalogCode = 'default',
    locale = 'default',
  ): any {
    if (!values) {
      return undefined
    }
    const catalogValue =
      values.find((val) => val.catalogCode === catalogCode) ||
      values.find((val) => val.catalogCode === CATALOG_DEFAULT_CODE)

    if (!catalogValue) {
      return undefined
    }

    return (
      catalogValue.locales[locale] || catalogValue.locales[LOCALE_DEFAULT_CODE]
    )
  }
}
