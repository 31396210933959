<div class="auth" [class.bg-dark]="!production">
  <div class="container">
    <div class="row">
      <!-- Auth navigation -->
      <div class="navigator-wrapper col-md-6" [class.text-white]="!production">
        <img class="logo" src="assets/img/logo-orderpod-mini.png" />
        <router-outlet></router-outlet>
      </div>

      <!-- Slider -->
      <div class="carousel-wrapper col-md-6 d-none d-md-block">
        <op-carousel></op-carousel>
      </div>
    </div>
  </div>

  <div class="version" [class.text-white]="!production">
    <span class="mr-2" *ngIf="!production">Development</span>
    <span class="mr-2 font-weight-bold" *ngIf="localhost">BE: Localhost</span>
    <span>v{{ version }}</span>
  </div>
</div>
