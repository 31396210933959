import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { View, ViewSearchParams } from './view.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'user-views'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ViewsService extends CrudService {
  protected model = MODEL

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new view
   * @param view - The view to create
   * @returns The observable<View> to create the view
   */
  create$(view: View): Observable<View> {
    return this._create$<View>(view)
  }

  /**
   * Read a view by ID
   * @param viewId - The view ID
   * @returns The observable<View> for read the view
   */
  read$(viewId: string): Observable<View> {
    return this._read$<View>(viewId)
  }

  /**
   * Update a view by ID
   * @param viewId - The view ID
   * @param view - The view body to update
   * @returns The observable<View> for update the view
   */
  update$(viewId: string, view: View): Observable<View> {
    return this._update$<View>(viewId, view)
  }

  /**
   * Create or update a view by ID
   * @param viewId - The view ID
   * @param view - The view body to update
   * @returns The observable<View> for update the view
   */
  upsert$(view: View): Observable<View> {
    return this._upsert$<View>(view, view._id)
  }

  /**
   * Delete a view by ID
   * @param viewId - The view ID
   * @returns The observable<View> for delete the view
   */
  delete$(viewId: string): Observable<View> {
    return this._delete$<View>(viewId)
  }

  /**
   * Search views by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<View>> for search views
   */
  search$(
    params?: ViewSearchParams,
    returnAll = false,
  ): Observable<Page<View>> {
    return this._search$<View>(params, returnAll)
  }

  /**
   * List views by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<View[]> for list views
   */
  list$(params?: ViewSearchParams, returnAll = false): Observable<View[]> {
    return this._list$<View>(params, returnAll)
  }
}
