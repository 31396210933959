import { Pipe, PipeTransform } from '@angular/core'
import { getOrderProductsTags } from '../libs/order-tag.lib'
import { Order } from '../order.model'
import { Product } from '../../products'

@Pipe({
  name: 'orderTags',
})
export class OrderTagsPipe implements PipeTransform {
  transform(
    order: Order | undefined,
    products: Product[] | undefined,
  ): string[] {
    if (!order) {
      return []
    }

    return getOrderProductsTags(order, products || [])
  }
}
