import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  Observable,
  switchMap,
  tap,
  map,
  filter,
  withLatestFrom,
  take,
} from 'rxjs'
import { Role, RoleSearchParams } from '../role.model'
import { RolesService } from '../roles.service'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { RolesRepository } from '../roles.repository'
import { RoleNotification } from '../libs/role-notification.lib'

interface RolesListingState {
  filters?: RoleSearchParams
  data?: Role[]
  totalCount?: number
  notification?: RoleNotification
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

const ROLES_LISTING_INITIAL_STATE: RolesListingState = {
  toReload: false,
  isLoading: false,
  isInitialized: false,
}

@Injectable()
export class RolesListingUseCase extends ComponentStore<RolesListingState> {
  constructor(
    private rolesService: RolesService,
    private rolesRepository: RolesRepository,
  ) {
    super(ROLES_LISTING_INITIAL_STATE)
  }

  // Selectors

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectFilters$: Observable<RoleSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> = this.selectFilters$.pipe(
    filter((filters) => !!filters),
    map((filters) => isQueryStringFiltered(filters || {}, ['_id'])),
  )

  readonly selectRoles$: Observable<Role[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectPage$: Observable<Page<Role>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectRoles$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectNotification$ = this.select((state) => state.notification)

  // Effects

  readonly search$ = this.effect(
    (searchParams$: Observable<RoleSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.rolesService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<RoleSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectTotalCount$),
        map(([params, limit]) => ({ ...params, limit })),
        tap((searchParams) => this.setFilters(searchParams)),
        switchMap((searchParams) =>
          this.rolesService.search$(searchParams).pipe(
            tapResponse(
              (page) => this.setPage(page),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly delete$ = this.effect((role$: Observable<Role>) => {
    return role$.pipe(
      switchMap((role) =>
        this.rolesRepository
          .alert$(
            RoleNotification.from({
              code: 'ROLE_DELETION',
              data: { role },
            }),
          )
          .pipe(
            take(1),
            filter((confirmation) => !!confirmation),
            tap(() => this.setIsLoading(true)),
            switchMap(() =>
              this.rolesService.delete$(role._id).pipe(
                tapResponse(
                  () => this.setRoleDeleted(),
                  (error: HttpErrorResponse) => this.setError(error),
                ),
              ),
            ),
          ),
      ),
    )
  })

  // Reducers

  readonly setFilters = this.updater(
    (state, filters?: RoleSearchParams): RolesListingState => {
      return {
        filters,
        totalCount: state.totalCount,
        toReload: false,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: Page<Role>): RolesListingState => {
      return {
        ...state,
        data: page.data,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): RolesListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setToReload = this.updater(
    (state, toReload: boolean): RolesListingState => {
      return {
        ...state,
        isLoading: true,
        toReload,
      }
    },
  )

  readonly setRoles = this.updater((state, data: Role[]): RolesListingState => {
    return {
      ...state,
      data,
    }
  })

  readonly setRoleDeleted = this.updater((state): RolesListingState => {
    return {
      ...state,
      notification: RoleNotification.from({ code: 'ROLE_DELETED' }),
      toReload: true,
    }
  })

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): RolesListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )
}
