import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, map, of } from 'rxjs'
import { difference, uniq } from 'lodash'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { User, UserPolicy, UserSearchParams, UserToken } from './user.model'
import { Page } from '../../models/util.model'
import { Permissions } from '../policies/policy.model'

const MODEL = 'users'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class UsersService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new user
   * @param user - The user to create
   * @returns The observable<User> to create the user
   */
  create$(user: User): Observable<User> {
    return this._create$<User>(user)
  }

  /**
   * Read a user by ID
   * @param userId - The user ID
   * @returns The observable<User> to read the user
   */
  read$(userId: string): Observable<User> {
    return this._read$<User>(userId)
  }

  /**
   * Update a user by ID
   * @param userId - The user ID
   * @param user - The user body to update
   * @returns The observable<User> to update the user
   */
  update$(userId: string, user: User): Observable<User> {
    return this._update$<User>(userId, user)
  }

  /**
   * Create or update a user by ID
   * @param user - The user body to update
   * @param userId - The user ID
   * @returns The observable<User> for update the user
   */
  upsert$(user: User): Observable<User> {
    return this._upsert$<User>(user, user._id)
  }

  /**
   * Delete a user by ID
   * @param userId - The user ID
   * @param force - Force flag for authenticated users
   * @returns The observable<User> to delete the user
   */
  delete$(userId: string, force?: boolean): Observable<User> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        force,
      },
    }

    return this._delete$<User>(userId, options)
  }

  /**
   * Search users by params
   * @param params - The search params
   * @returns The observable<Page<User>> to search users
   */
  search$(
    params?: UserSearchParams,
    returnAll = false,
  ): Observable<Page<User>> {
    return this._search$<User>(params, returnAll)
  }

  /**
   * List users by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<User[]> for list policies
   */
  list$(params?: UserSearchParams, returnAll = false): Observable<User[]> {
    return this._list$<User>(params, returnAll)
  }

  /**
   * Read a user by params
   * @param params - The search params
   * @returns the observable<User> for search a user
   */
  readOne$(params?: UserSearchParams): Observable<User> {
    return this._readOne$<User>(params)
  }

  /**
   * Find a user by params
   * @param params - the search params
   * @returns the observable<User | undefined> for list a user
   */
  findOne$(params?: UserSearchParams): Observable<User | undefined> {
    return this._findOne$<User>(params)
  }

  /**
   * Enable a user by ID
   * @param userId - The user ID
   * @returns The observable<User> to enable the user
   */
  enable$(userId: string): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/${userId}/enable`, {})
  }

  /**
   * Disable a user by ID
   * @param userId - The user ID
   * @returns The observable<User> to disable the user
   */
  disable$(userId: string): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/${userId}/disable`, {})
  }

  /**
   * Remove the user from tenant access
   * @param userId - The user ID
   * @returns The observable<User> to eject the user from tenant
   */
  eject$(userId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${userId}/eject`, {})
  }

  /**
   * Create a PAT by user ID
   * @param userId - The user ID
   * @returns The observable<string> to create the token
   */
  createToken$(userId: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(
      `${this.apiUrl}/${userId}/tokens`,
      {},
    )
  }

  /**
   * Delete a PAT by ID and user ID
   * @param userId - The user ID
   * @param tokenId - The token ID
   * @returns The observable<string> to delete the token
   */
  deleteToken$(userId: string, tokenId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${userId}/tokens/${tokenId}`)
  }

  /**
   * Search tokens by user ID
   * @param userId - The user ID
   * @returns The observable<Page<UserToken>> to search tokens
   */
  getTokens$(userId: string): Observable<Page<UserToken>> {
    return this.http.get<Page<UserToken>>(`${this.apiUrl}/${userId}/tokens`)
  }

  /**
   * Get all policies of an user by its ID
   * @param userId - The user ID
   * @returns The observable<UserPolicy[]> to get user policies
   */
  getPolicies$(userId: string): Observable<UserPolicy[]> {
    return this.http.get<UserPolicy[]>(`${this.apiUrl}/${userId}/policies`)
  }

  /**
   * Get all permissions of an user by its ID
   * @param userId - The user ID
   * @returns The observable<Permissions> to get user permissions
   */
  getPermissions$(userId: string): Observable<Permissions> {
    return this.http.get<Permissions>(`${this.apiUrl}/${userId}/permissions`)
  }

  /**
   * Set user permissions by its ID
   * @param userId - The user ID
   * @param permissions - The user permissions
   * @returns The observable<Permissions> to set user permissions
   */
  setPermissions$(
    userId: string,
    permissions: Permissions,
  ): Observable<Permissions> {
    return this.http.post<Permissions>(
      `${this.apiUrl}/${userId}/permissions`,
      permissions,
    )
  }

  /**
   * Set new password to user
   * @param userId - The user ID
   * @param password - The new password
   * @returns The observable<void> to set the new password
   */
  setPassword$(userId: string, password: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${userId}/set-password`, {
      password,
    })
  }

  /**
   * Check user uniqueness by field
   * @param field - The user field to check
   * @param value - The value to check
   * @returns The observable<{ exists: boolean }> to check uniqueness of the user
   */
  check$(field: 'username', value: string): Observable<{ exists: boolean }> {
    return this.http.get<{ exists: boolean }>(`${this.apiUrl}/check`, {
      params: { [field]: value },
    })
  }

  /**
   * Generate users store
   * @param userIds - the user IDs to load
   * @param users - the users already loaded
   * @returns the Observable<User[]> as store
   */
  store$(userIds: string[], users: User[]): Observable<User[]> {
    userIds = uniq(userIds)
    userIds = difference(
      userIds,
      users.map((u) => u._id),
    )

    if (userIds.length === 0) {
      return of(users)
    }

    return this.list$({ _id: userIds }).pipe(map((usrs) => [...users, ...usrs]))
  }
}
