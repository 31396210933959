import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { StatusHistory } from '../../models/util.model'

export enum ExecutionType {
  internal = 'INTERNAL',
  manual = 'MANUAL',
  scheduled = 'SCHEDULED',
}
export enum ExecutionStatus {
  pending = 'PENDING',
  running = 'RUNNING',
  completed = 'COMPLETED',
  failed = 'FAILED',
  unknown = 'UNKNOWN',
}

export interface Execution extends Entity {
  context: ExecutionContext
  activity: ExecutionActivity
  type: ExecutionType
  status: ExecutionStatus
  statusHistory: StatusHistory[]
  lastPing: Date
  finishedAt?: Date
  notify?: ExecutionNotify
}

export interface ExecutionContext {
  _id: string
  code: string
}

export interface ExecutionActivity {
  _id: string
  code: string
}

export interface ExecutionNotify {
  onComplete?: boolean
  onFail?: boolean
  email: string | string[]
}

export type ExecutionSearchField =
  | '_id'
  | 'type'
  | 'status'
  | 'activity._id'
  | 'activity.code'
  | 'context._id'
  | 'context.code'
  | 'isLast'
export type ExecutionSortField = 'createdAt' | 'finishedAt'

export interface ExecutionSearchParams
  extends QueryStringParams<ExecutionSortField> {
  'activity._id'?: string | string[]
  'activity.code'?: string | string[]
  'context._id'?: string | string[]
  'context.code'?: string | string[]
  status?: ExecutionStatus | ExecutionStatus[]
  type?: ExecutionType | ExecutionType[]
  isLast?: boolean
}
