import { EntityStatusDesc } from '../../models/entity.model'
import { TaskStatus, TaskType } from './task.model'

export const TASK_TYPES: EntityStatusDesc<TaskType>[] = [
  {
    code: 'ACTIVITY_EXECUTION',
    name: 'Esecuzione attività',
  },
  {
    code: 'CONTEXT_EXECUTION',
    name: 'Esecuzione raw',
  },
  {
    code: 'ORDERS_UPDATE',
    name: 'Aggiornamento ordini',
  },
  {
    code: 'PRODUCTS_UPDATE',
    name: 'Aggiornamento prodotti',
  },
  {
    code: 'WAREHOUSE_UPDATE',
    name: 'Aggiornamento magazzino',
  },
  {
    code: 'REPLENISHMENTS_IMPORT',
    name: 'Aggiornamento missioni di trasferimento',
  },
]

export const TASK_STATUSES: EntityStatusDesc<TaskStatus>[] = [
  {
    code: 'COMPLETED',
    name: 'Completed',
  },
  {
    code: 'FAILED',
    name: 'Failed',
  },
  {
    code: 'PENDING',
    name: 'Pending',
  },
  {
    code: 'RUNNING',
    name: 'Running',
  },
]
