import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core'
import { ControlContainer, NgForm, NgModel } from '@angular/forms'
import { UiSize } from '../../models/ui.model'

@Component({
  selector: 'op-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class InputComponent implements OnInit {
  @ViewChild('inputModel', { static: true }) inputModel!: NgModel
  @Input()
  type: 'text' | 'email' | 'number' | 'password' = 'text'
  @Input() size: UiSize = 'md'
  @Input() placeholder?: string
  @Input() class?: string
  @Input() key?: string
  @Input() value?: any
  @Input() step?: number | string
  @Input() slugify?: string

  // Style
  @Input() clearButton = false
  @Input() disabled = false
  @Input() markIfValid = false
  @Input() toLowercase = false

  // Group management
  @Input() group = false
  @Input() preContent = false
  @Input() preLabel?: string
  @Input() postContent = false
  @Input() postLabel?: string

  // Errors
  @Input() min?: number | string
  @Input() minError?: string

  @Input() minLength?: number | string
  @Input() minLengthError?: string

  @Input() max?: number | string
  @Input() maxError?: string

  @Input() maxLength?: number | string
  @Input() maxLengthError?: string

  @Input() required = false
  @Input() requiredError?: string

  @Input() formatValidator?: string
  @Input() formatValidatorError?: string

  @Input() validateUnique = false
  @Input() validateField?: string
  @Input() validateElement?: 'product' | 'supplier' | 'user' | 'attribute'
  @Input() validateUniqueError?: string

  @Input() validateEqual?: string
  @Input() validateEqualError?: string

  @Input() validateCoordinate?: 'lat' | 'long'
  @Input() validateCoordinateError?: string

  @Output() readonly valueChange = new EventEmitter<any>()
  @Output() readonly valueSubmit = new EventEmitter<any>()
  @Output() readonly focusOut = new EventEmitter<string>()

  markAsDirty() {
    this.inputModel.control.markAsTouched()
    this.inputModel.control.markAsDirty()
    this.inputModel.control.setErrors(null)
  }

  emitValue(value: any) {
    this.valueChange.emit(
      this.type === 'number' && typeof value === 'string' ? +value : value
    )
  }

  public inputClasses = ''

  ngOnInit() {
    const inputClasses: string[] = []

    // Size
    inputClasses.push(`form-control-${this.size}`)

    this.inputClasses = inputClasses.join(' ')
  }
}
