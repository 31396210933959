import { cloneDeep, defaultsDeep, isEmpty } from 'lodash'
import {
  Channel,
  ChannelCallOn,
  ChannelCarrier,
  ChannelKind,
  ChannelPayment,
  ChannelQapla,
  ChannelSearchField,
  ChannelSearchParams,
  ChannelSettings,
  ChannelShopify,
  ChannelSortField,
  ChannelStoreden,
  ChannelStoredenAttributesFiltersMapping,
  ChannelStoredenAttributesMapping,
  ChannelStoredenFieldsMapping,
  ChannelSync,
} from '../channel.model'
import { Tenant } from '../../tenants/tenant.model'
import { Carrier } from '../../carriers/carrier.model'
import {
  StoredenProductField,
  StoredenStore,
} from '../../storeden/storeden.model'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { DialogParams } from '../../../models/notification.model'

/**
 * Channel initial state
 */
const PAYMENT_INITIAL_STATE: Partial<ChannelPayment> = {
  _id: undefined,
  externalId: undefined,
}

const CARRIER_INITIAL_STATE: Partial<ChannelCarrier> = {
  _id: undefined,
  externalId: undefined,
}

const CHANNEL_INITIAL_STATE: Partial<Channel> = {
  name: undefined,
  kind: ChannelKind.generic,
  qapla: undefined,
  storeden: undefined,
  payments: [],
  productIdentifier: undefined,
}

const CALL_ON_INITIAL_STATE: ChannelCallOn = {
  processing: 'PROCESSING',
  shipped: 'SHIPPED',
}

const SHOPIFY_INITIAL_STATE: Partial<ChannelShopify> = {
  shopName: undefined,
  apiKey: undefined,
  password: undefined,
  sharedSecret: undefined,
  locationId: undefined,
}

const STOREDEN_INITIAL_STATE: Partial<ChannelStoreden> = {
  fieldsMapping: [],
  attributesMapping: [],
  attributesFiltersMapping: [],
  attributeIdVariantKey: undefined,
  sync: {
    orders: false,
    catalog: false,
    productQuantities: false,
  },
}

const STOREDEN_FIELD_STATE: Partial<ChannelStoredenFieldsMapping> = {
  field: undefined,
  externalField: undefined,
}

const SETTINGS_INITIAL_STATE: Partial<ChannelSettings> = {
  exchange: undefined,
  key: undefined,
  externalId: undefined,
  url: undefined,
  storeName: undefined,
  currency: undefined,
  locale: undefined,
}

const STOREDEN_ATTRIBUTE_STATE: Partial<ChannelStoredenAttributesMapping> = {
  _id: undefined,
  externalField: undefined,
}

const STOREDEN_FILTER_STATE: Partial<ChannelStoredenAttributesFiltersMapping> =
  {
    _id: undefined,
    externalFilterCode: undefined,
  }

const QAPLA_INITIAL_STATE: Partial<ChannelQapla> = {
  apikey: undefined,
  callOn: 'PACKING',
  reference: 'rifOrder',
  origin: undefined,
}

const SYNC_INITIAL_STATE: ChannelSync = {
  catalog: false,
  orders: false,
  quantities: false,
}

/**
 * Initialize a partial channel
 * @param channel - the partial channel
 * @returns the channel updated
 */
export function initChannel(channel: Partial<Channel> = {}): Channel {
  let newChannel: Channel = defaultsDeep(
    cloneDeep(channel),
    CHANNEL_INITIAL_STATE,
  )

  if (newChannel.kind === ChannelKind.storeden) {
    newChannel = {
      ...newChannel,
      customSettings: defaultsDeep(
        newChannel.customSettings,
        SETTINGS_INITIAL_STATE,
      ),
      storeden: defaultsDeep(newChannel.storeden, STOREDEN_INITIAL_STATE),
      callOn: defaultsDeep(newChannel.callOn, CALL_ON_INITIAL_STATE),
    }

    if (
      newChannel.storeden?.sync?.catalog &&
      !newChannel.storeden.fieldsMapping.length
    ) {
      newChannel = addChannelStoredenMappingField(newChannel)
    }
  } else if (newChannel.kind === ChannelKind.shopify) {
    newChannel = {
      ...newChannel,
      shopify: defaultsDeep(newChannel.shopify, SHOPIFY_INITIAL_STATE),
      sync: defaultsDeep(newChannel.sync, SYNC_INITIAL_STATE),
    }
  } else {
    newChannel = { ...newChannel, storeden: undefined, shopify: undefined }
  }

  return newChannel
}

/**
 * Initialize the channel payment
 * @param channelPayment - the channel payment
 * @returns the payment updated
 */
export function initChannelPayment(
  channelPayment: Partial<ChannelPayment> = {},
): ChannelPayment {
  return defaultsDeep(channelPayment, PAYMENT_INITIAL_STATE)
}

/**
 * Initialize channel carrier
 * @param channelCarrier - the channel carrier
 * @returns the carrier updated
 */
export function initChannelCarrier(
  channelCarrier: Partial<ChannelCarrier> = {},
): ChannelCarrier {
  return defaultsDeep(channelCarrier, CARRIER_INITIAL_STATE)
}

/**
 * Set tenant settings to the channel
 * @param channel - the channel to update
 * @param tenant - the tenant to set
 * @returns the channel updated
 */
export function setChannelTenantSettings(
  channel: Channel,
  tenant: Tenant,
): Channel {
  let qapla = undefined
  if (tenant.qaplaSettings?.active) {
    qapla = defaultsDeep(channel.qapla, QAPLA_INITIAL_STATE)
    qapla.callOn = qapla.callOn || tenant.qaplaSettings.callOn
    qapla.reference = qapla.reference || tenant.qaplaSettings.reference
  }

  return {
    ...channel,
    qapla,
  }
}

/**
 * Set a carrier to a channel
 * @param channel - the channel to update
 * @param carrier - the carrier to set
 * @returns the channel updated
 */
export function setChannelCarrier(
  channel: Channel,
  carrier?: Carrier,
): Channel {
  const { _id, name, externalId } = carrier || {}
  return {
    ...channel,
    defaultCarrier: { _id, name, externalId: externalId?.toString() },
    automaticCarrier: false,
  }
}

/**
 * Unset the carrier from a channel
 * @param channel - the channel to update
 * @returns the channel updated
 */
export function unsetChannelCarrier(channel: Channel): Channel {
  return {
    ...channel,
    defaultCarrier: {},
    automaticCarrier: true,
  }
}

/**
 * Set channel settings
 * @param channel - the channel to update
 * @param info - the additional info
 * @returns the channel updated
 */
export function setChannelSettings(channel: Channel, info: any): Channel {
  if (channel.kind === ChannelKind.storeden) {
    return setStoredenData(channel, info)
  }

  return { ...channel }
}

/**
 * Check channel data completion
 * @param channel - the channel to check
 * @returns the channel updated
 */
export function checkChannel(channel: Channel): Channel {
  return {
    ...channel,
    qapla: !channel.qapla || !channel.qapla.apikey ? undefined : channel.qapla,
    customSettings: isEmpty(channel.customSettings)
      ? undefined
      : channel.customSettings,
    defaultCarrier: isEmpty(channel.defaultCarrier)
      ? undefined
      : channel.defaultCarrier,
    payments: !channel.payments?.length ? undefined : channel.payments,
    callOn: isEmpty(channel.callOn) ? undefined : channel.callOn,
  }
}

/**
 * Add a payment to a channel
 * @param channel - the channel to update
 * @param payment - the payment to add
 * @returns the channel updated
 */
export function addChannelPayment(
  channel: Channel,
  payment?: ChannelPayment,
): Channel {
  return {
    ...channel,
    payments: [...(channel.payments || []), initChannelPayment(payment)],
  }
}

/**
 * Remove a payment from a channel
 * @param channel - the channel to update
 * @param i - the payment index to remove
 * @returns the channel updated
 */
export function removeChannelPayment(channel: Channel, i?: number): Channel {
  i === undefined && (i = channel.payments?.length)
  return {
    ...channel,
    payments: [
      ...(channel.payments?.filter((pay, index) => index !== i) || []),
    ],
  }
}

/**
 * Add a carrier to a channel
 * @param channel - the channel to update
 * @param carrier - the carrier to add
 * @returns the channel updated
 */
export function addChannelCarrier(
  channel: Channel,
  carrier?: ChannelCarrier,
): Channel {
  return {
    ...channel,
    carriers: [...(channel.carriers || []), initChannelCarrier(carrier)],
  }
}

/**
 * Remove a carrier from a channel
 * @param channel - the channel to update
 * @param i - the index of the carrier to remove
 * @returns the channel updated
 */
export function removeChannelCarrier(channel: Channel, i?: number): Channel {
  i === undefined && (i = channel.carriers?.length)
  return {
    ...channel,
    carriers: [
      ...(channel.carriers?.filter((car, index) => index !== i) || []),
    ],
  }
}

/**
 * Add a mapping field to channel storeden configuration
 * @param channel - the channel to update
 * @param map - the mapping to add
 * @returns the channel updated
 */
export function addChannelStoredenMappingField(
  channel: Channel,
  map?: ChannelStoredenFieldsMapping,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  const fieldMap = defaultsDeep(map, STOREDEN_FIELD_STATE)

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      fieldsMapping: [...(channel?.storeden?.fieldsMapping || []), fieldMap],
    },
  }
}

/**
 * Remove a mapping field from channel storeden configuration
 * @param channel - the channel to update
 * @param i - the mapping index
 * @returns the channel updated
 */
export function removeChannelStoredenMappingField(
  channel: Channel,
  i?: number,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  if (i === undefined) {
    i = channel.storeden?.fieldsMapping.length
  }

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      fieldsMapping: [
        ...channel.storeden.fieldsMapping.filter((t, index) => index !== i),
      ],
    },
  }
}

/**
 * Add a mapping attribute to a channel storeden configuration
 * @param channel - the channel to update
 * @param map - the mapping to add
 * @returns the channel updated
 */
export function addChannelStoredenMappingAttribute(
  channel: Channel,
  map?: ChannelStoredenAttributesMapping,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  const attributeMap = defaultsDeep(map, STOREDEN_ATTRIBUTE_STATE)

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      attributesMapping: [
        ...(channel.storeden?.attributesMapping || []),
        attributeMap,
      ],
    },
  }
}

/**
 * Remove a mapping from a channel storeden configuration
 * @param channel - the channel to update
 * @param i - the index of the mapping
 * @returns the channel updated
 */
export function removeChannelStoredenMappingAttribute(
  channel: Channel,
  i?: number,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  if (i === undefined) {
    i = channel.storeden?.attributesMapping.length
  }

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      attributesMapping: [
        ...channel.storeden.attributesMapping.filter((t, index) => index !== i),
      ],
    },
  }
}

/**
 * Add a filter to a channel mapping configuration
 * @param channel - the channel to update
 * @param filter - the filter to add
 * @returns the channel updated
 */
export function addChannelStoredenMappingAttributeFilter(
  channel: Channel,
  filter?: ChannelStoredenAttributesFiltersMapping,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  const filterMap = defaultsDeep(filter, STOREDEN_FILTER_STATE)

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      attributesFiltersMapping: [
        ...(channel.storeden?.attributesFiltersMapping || []),
        filterMap,
      ],
    },
  }
}

/**
 * Remove a mapping filter from a channel storeden configuration
 * @param channel - the channel to update
 * @param i - the mapping index
 * @returns the channel updated
 */
export function removeChannelStoredenMappingAttributeFilter(
  channel: Channel,
  i?: number,
): Channel {
  if (!channel.storeden) {
    return channel
  }

  if (i === undefined) {
    i = channel.storeden?.attributesFiltersMapping.length
  }

  return {
    ...channel,
    storeden: {
      ...channel.storeden,
      attributesFiltersMapping: [
        ...(channel.storeden?.attributesFiltersMapping.filter(
          (t, index) => index !== i,
        ) || []),
      ],
    },
  }
}

/**
 * Check manadatory storeden fields of a channel
 * @param channel - the channel to check
 * @returns the boolean validation check
 */
export function checkMandatoryStoredenFields(channel: Channel): boolean {
  if (
    channel.kind !== ChannelKind.storeden ||
    !channel.storeden?.sync?.catalog
  ) {
    return true
  }

  return (
    checkMandatoryStoredenField(channel, StoredenProductField.title) &&
    checkMandatoryStoredenField(channel, StoredenProductField.description) &&
    checkMandatoryStoredenField(channel, StoredenProductField.tax_profile) &&
    checkMandatoryStoredenField(channel, StoredenProductField.price)
  )
}

/**
 * Check a channel storeden field
 * @param channel - the channel
 * @param storedenField - the storeden field to check
 * @returns the boolean validation check
 */
export function checkMandatoryStoredenField(
  channel: Channel,
  storedenField: StoredenProductField,
): boolean {
  return (
    !!channel.storeden?.fieldsMapping
      .map((f) => f.externalField)
      .includes(storedenField) ||
    !!channel.storeden?.attributesMapping
      .map((f) => f.externalField)
      .includes(storedenField)
  )
}

/**
 * Set storeden data
 * @param channel - the channel to update
 * @param store - the storeden data
 * @returns the channel updated
 */
function setStoredenData(channel: Channel, store: StoredenStore): Channel {
  return {
    ...channel,
    customSettings: {
      ...channel.customSettings,
      externalId: store.uid.toString(),
      url: store.url,
      storeName: store.store_name,
      currency: store.currency,
      locale: store.locale,
    },
    storeden: defaultsDeep(channel.storeden, STOREDEN_INITIAL_STATE),
  }
}

/**
 * Parse channel sort params
 * @param params - the filter params
 * @param sort - the sort field
 * @returns the params updated
 */
export function channelSortParams(
  params: ChannelSearchParams,
  sort: QueryStringSort<ChannelSortField>,
): ChannelSearchParams {
  const searchParams: ChannelSearchParams = {}

  switch (sort.field) {
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse channels filter params
 * @param params the search params to parse
 * @param filter the filter field
 * @returns the search params
 */
export function channelFilterParams(
  params: ChannelSearchParams,
  filter: QueryStringFilter<ChannelSearchField>,
): ChannelSearchParams {
  const searchParams: ChannelSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'catalogCode':
      if (filter.value !== null) {
        searchParams.catalogCode = filter.value
      }
      break
    case 'kind':
      if (filter.value !== null) {
        searchParams.kind = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}
