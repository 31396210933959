import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, filter, map, take, tap } from 'rxjs'
import {
  AuthManager,
  Tenant,
  User,
  UserTenantSettings,
  PermissionsLevel,
  PermissionsScope,
  Printers,
  getUserDefaultView,
  getUserTenantSettings,
  setUserDefaultView,
  unsetUserDefaultView,
  UserTenant,
  Warehouse,
  Supplier,
  PickupPoint,
  PermissionsCheckMode,
  AuthNotification,
} from '@evologi/shared/data-access-api'

import {
  STORE_CONFIGURATION,
  StoreConfiguration,
  StoreState,
} from '../store.state'
import {
  selectAuth,
  selectAuthAccessToken,
  selectAuthIsLoading,
  selectAuthIsReviving,
  selectAuthNotification,
  selectAuthPermissionCheck,
  selectAuthPermissionsCheck,
  selectAuthPickupPoint,
  selectAuthPrintingEnabled,
  selectAuthRefreshToken,
  selectAuthShippyProIntegration,
  selectAuthSupplier,
  selectAuthTenant,
  selectAuthTenantId,
  selectAuthTokens,
  selectAuthUser,
  selectAuthUserEmail,
  selectAuthUserId,
  selectAuthUserTenants,
  selectAuthWarehouse,
  selectAuthWarehouseId,
  selectAuthenticated,
  selectAuthenticatedData,
} from './auth.selectors'
import { AuthState } from './auth.state'
import {
  tenantSaved,
  tenantSelected,
  userExpel,
  userLoggedIn,
  userLoggedOut,
  userPasswordChange,
  userPasswordRecovery,
  userPasswordUpdate,
  userRevive,
  userSaved,
} from './auth.actions'
import { storageAction } from '../storage'

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService implements AuthManager {
  public authenticationRoute = this.config.authenticationRoute
  public authenticatedRoute = this.config.authenticatedRoute
  public useAuthToken = this.config.useAuthToken

  constructor(
    @Inject(STORE_CONFIGURATION) public config: StoreConfiguration,
    private store: Store<StoreState>,
  ) {}

  /**
   * Check if the user is authenticated
   * @returns the observable<boolean> that indicates if the user is authenticated
   */
  isAuthenticated$(): Observable<boolean> {
    return this.store.select(selectAuthenticated)
  }

  /**
   * Check if the current user is allowed to access the scope
   * @param scope - the permission scope
   * @param level - the permission level
   * @returns the observable<boolean> that indicates if is allowed or not
   */
  isAllowed$(
    scope: PermissionsScope,
    level?: PermissionsLevel,
  ): Observable<boolean> {
    return this.store.select(selectAuthPermissionCheck(scope, level))
  }

  /**
   * Check if the current user is allowed to access the scopes
   * @param scopes - the permission scopes
   * @param level - the permission level
   * @param mode - the permission mode
   * @returns the observable<boolean> that indicates if is allowed or not
   */
  isMultiAllowed$(
    scopes: PermissionsScope[],
    level?: PermissionsLevel,
    mode?: PermissionsCheckMode,
  ): Observable<boolean> {
    return this.store.select(selectAuthPermissionsCheck(scopes, level, mode))
  }

  /**
   * Check if the current user is authenticated and allowed to access the scope
   * @param scope - the scope
   * @param level - the level
   * @returns the observable<boolean> that indicate if is accessible or not
   */
  isAccessible$(
    scope: PermissionsScope,
    level?: PermissionsLevel,
  ): Observable<boolean> {
    return combineLatest([
      this.isAuthenticated$(),
      this.isAllowed$(scope, level),
    ]).pipe(map(([authCheck, policyCheck]) => authCheck && policyCheck))
  }

  /**
   * Check if the current user is allowed to print
   * @returns the observable<boolean> that indicates if is allowed or not the printing
   */
  isPrintEnabled$(): Observable<boolean> {
    return this.store.select(selectAuthPrintingEnabled)
  }

  /**
   * Check if is loading
   * @returns the observable<boolean> that indicates if the store is loading
   */
  isLoading$(): Observable<boolean> {
    return this.store.select(selectAuthIsLoading)
  }

  /**
   * Check if the current tenant has ShippyPro integration active
   * @returns the observable<boolean> that indicates if ShippyPro is enabled
   */
  isShippyProEnabled$(): Observable<boolean> {
    return this.store.select(selectAuthShippyProIntegration)
  }

  /**
   * Check if the auth store is reviving
   * @returns the observable<boolean> that indicates if is reviving
   */
  isReviving$(): Observable<boolean> {
    return this.store.select(selectAuthIsReviving)
  }

  /**
   * Get authenticated user ID
   * @returns the observable<string> for get user ID
   */
  getUserId$(): Observable<string | undefined> {
    return this.store.select(selectAuthUserId)
  }

  /**
   * Get authenticated user
   * @returns the observable<User>
   */
  getUser$(): Observable<User | undefined> {
    return this.store.select(selectAuthUser)
  }

  /**
   * Get authenticated user email
   * @returns the observable<string>
   */
  getUserEmail$(): Observable<string | undefined> {
    return this.store.select(selectAuthUserEmail)
  }

  /**
   * Get enabled printing
   * @returns the observable<boolean> for printing
   */
  getUserPrintingEnabled$(): Observable<boolean> {
    return this.store.select(selectAuthPrintingEnabled)
  }

  /**
   * Get user default view
   * @param viewType - the view type
   * @returns the observable for get user default view
   */
  getUserDefaultView$(viewType: string): Observable<string | undefined> {
    return this.getUserSettings$().pipe(
      map((settings) =>
        settings ? getUserDefaultView(settings, viewType) : undefined,
      ),
    )
  }

  /**
   * Set user view as default
   * @param viewKey - the view key
   * @param viewId - the view ID
   * @returns the observable for set a user view as default
   */
  setUserViewAsDefault$(viewKey: string, viewId: string) {
    return this.getAuthenticatedData$().pipe(
      take(1),
      map((authData) => {
        if (!viewKey || !viewId) {
          return undefined
        }

        return setUserDefaultView(
          authData.user,
          authData.tenant._id,
          viewKey,
          viewId,
        )
      }),
      tap((user) => {
        if (user) {
          this.store.dispatch(
            userSaved({
              user,
            }),
          )
        }
      }),
    )
  }

  /**
   * Unset user view type as default
   * @param viewKey - the view key
   * @returns the observable for unset view type as default
   */
  unsetUserViewAsDefault$(viewKey: string) {
    return this.getAuthenticatedData$().pipe(
      take(1),
      tap((authData) => {
        if (viewKey) {
          this.store.dispatch(
            userSaved({
              user: unsetUserDefaultView(
                authData.user,
                authData.tenant._id,
                viewKey,
              ),
            }),
          )
        }
      }),
    )
  }

  /**
   * Get authenticated user settings
   * @returns the observable<UserSettings> for get user settings
   */
  getUserSettings$(): Observable<UserTenantSettings | undefined> {
    return this.store.select(selectAuth).pipe(
      // eslint-disable-next-line
      map((authData) =>
        authData?.user && authData?.tenant
          ? getUserTenantSettings(authData.user, authData.tenant._id)
          : undefined,
      ),
    )
  }

  /**
   * Get authenticated user printers
   * @returns the observable<Printers> for get user printers
   */
  getUserPrinters$(): Observable<Printers | undefined> {
    return this.getUserSettings$().pipe(
      map((settings) => {
        if (settings?.printers?.a4 && settings.printers.label) {
          return { labels: settings.printers.label, a4: settings.printers.a4 }
        }

        return undefined
      }),
    )
  }

  /**
   * Get authenticated user tenants
   * @returns the observable<UserTenant[]> for get user tenants
   */
  getUserTenants$(): Observable<UserTenant[] | undefined> {
    return this.store.select(selectAuthUserTenants)
  }

  /**
   * Get authenticated data
   * @returns the observable<AuthState> for get the auth data
   */
  getAuthData$(): Observable<AuthState | undefined> {
    return this.store.select(selectAuth)
  }

  /**
   * Get access token
   * @returns the observable for get access token
   */
  getAccessToken$() {
    return this.store.select(selectAuthAccessToken(this.config.privateKey))
  }

  /**
   * Get refresh token
   * @returns the observable for get refresh token
   */
  getRefreshToken$() {
    return this.store.select(selectAuthRefreshToken(this.config.privateKey))
  }

  /**
   * Get auth tokens
   * @returns the observable<tokens> for get auth tokens
   */
  getAuthTokens$() {
    return this.store.select(selectAuthTokens(this.config.privateKey))
  }

  /**
   * Get authenticated tenant and user
   * @returns the observable<{ user, tenant }> for get authenticated data
   */
  getAuthenticatedData$() {
    return this.store.select(selectAuthenticatedData).pipe(
      filter((authData) => !!authData),
      map((authData) => authData!),
    )
  }

  /**
   * Get authenticated tenant
   * @returns the observable<Tenant> for get the tenant
   */
  getTenant$(): Observable<Tenant | undefined> {
    return this.store.select(selectAuthTenant)
  }

  /**
   * Get authenticated tenant ID
   * @returns the observable<string> from get the tenant ID
   */
  getTenantId$(): Observable<string | undefined> {
    return this.store.select(selectAuthTenantId)
  }

  /**
   * Get warehouse ID
   * @returns the observable<string | undefined> for warehouse ID
   */
  getWarehouseId$(): Observable<string | undefined> {
    return this.store.select(selectAuthWarehouseId)
  }

  /**
   * Get current warehouse
   * @returns the observable<Warehouse | undefined> for get the warehouse
   */
  getWarehouse$(): Observable<Warehouse | undefined> {
    return this.store.select(selectAuthWarehouse)
  }

  /**
   * Get current pickup-point
   * @returns the observable<PickupPoint | undefined> for get the pickup-point
   */
  getPickupPoint$(): Observable<PickupPoint | undefined> {
    return this.store.select(selectAuthPickupPoint)
  }

  /**
   * Get current supplier
   * @returns the observable<Supplier | undefined> for get the supplier
   */
  getSupplier$(): Observable<Supplier | undefined> {
    return this.store.select(selectAuthSupplier)
  }

  /**
   * Get auth notification
   * @returns the observable<AuthNotification | undefined> for get the auth notification
   */
  getNotification$(): Observable<AuthNotification | undefined> {
    return this.store.select(selectAuthNotification)
  }

  /**
   * Authenticate by credentials
   * @param username - the username
   * @param password - the password
   * @param force - the force flag
   */
  login(username: string, password: string, force = false) {
    this.store.dispatch(
      userLoggedIn({
        username,
        password,
        force,
      }),
    )
  }

  /**
   * Exit from OrderPod
   */
  logout() {
    this.store.dispatch(userLoggedOut())
  }

  /**
   * Select a tenant to authenticate
   * @param tenantId - the tenant ID
   */
  selectTenant(tenantId: string, redirect?: boolean) {
    this.store.dispatch(tenantSelected({ tenantId, redirect }))
  }

  /**
   * Change user password
   * @param oldPassword - the old password
   * @param newPassword - the new password to set
   */
  changePassword(oldPassword: string, newPassword: string) {
    this.store.dispatch(userPasswordChange({ oldPassword, newPassword }))
  }

  /**
   * Update user password
   * @param oldPassword - the old password
   * @param newPassword - the new password to set
   */
  updatePassword(oldPassword: string, newPassword: string) {
    this.store.dispatch(userPasswordUpdate({ oldPassword, newPassword }))
  }

  /**
   * Recovery user passoword
   * @param username - the username
   */
  recoveryPassword(username: string) {
    this.store.dispatch(userPasswordRecovery({ username }))
  }

  /**
   * Sync local storage between tabs
   * @param key - the local storage key to sync
   */
  syncStore(key: string) {
    this.store.dispatch(storageAction({ payload: key }))
  }

  /**
   * Expel the current authenticated user
   */
  expel() {
    this.store.dispatch(userExpel())
  }

  /**
   * Revive the current user session
   */
  revive() {
    this.store.dispatch(userRevive())
  }

  /**
   * Save current user
   * @param user - the user to save
   */
  saveUser(user: User) {
    this.store.dispatch(userSaved({ user }))
  }

  /**
   * Save current tenant
   * @param tenant - the tenant to save
   */
  saveTenant(tenant: Tenant) {
    this.store.dispatch(tenantSaved({ tenant }))
  }
}
