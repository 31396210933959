import { cloneDeep } from 'lodash'
import { Entity } from '@evologi/shared/data-access-api'

// Reducers

export function parseItems<T extends Entity>(
  items: T[],
  currentItems: Record<string, T> = {},
  itemFieldKey = '_id' as keyof T,
): Record<string, T> {
  return items.reduce<Record<string, T>>((acc, item) => {
    acc[item[itemFieldKey] as string] = item
    return acc
  }, cloneDeep(currentItems))
}
