export enum FilterOperator {
  moreThan = 'MORE-THAN',
  lessThan = 'LESS-THAN',
  moreOrEqualThan = 'MORE-OR-EQUAL-THAN',
  lessOrEqualThan = 'LESS-OR-EQUAL-THAN',
  equal = 'EQUAL',
  notEqual = 'NOT-EQUAL',
  between = 'BETWEEN',
  beforeThan = 'BEFORE-THAN',
  beforeOrEqualThan = 'BEFORE-OR-EQUAL-THAN',
  afterThan = 'AFTER-THAN',
  afterOrEqualThan = 'AFTER-OR-EQUAL-THAN',
  exists = 'EXISTS',
  notExists = 'NOT-EXISTS',
  contains = 'CONTAINS',
}

export enum FilterType {
  text = 'TEXT',
  number = 'NUMBER',
  date = 'DATE',
  textarea = 'TEXTAREA',
  price = 'PRICE',
  boolean = 'BOOLEAN',
  simpleSelect = 'SIMPLE-SELECT',
  multiSelect = 'MULTI-SELECT',
}

export enum FilterCollection {
  brands = 'BRANDS',
  carriers = 'CARRIERS',
  catalogs = 'CATALOGS',
  categories = 'CATEGORIES',
  channels = 'CHANNELS',
  countries = 'COUNTRIES',
  families = 'FAMILIES',
  locales = 'LOCALES',
  manufacturers = 'MANUFACTURERS',
  payments = 'PAYMENTS',
  products = 'PRODUCTS',
  reasons = 'REASONS',
  suppliers = 'SUPPLIERS',
  tags = 'TAGS',
  warehouses = 'WAREHOUSES',
}

export interface FilterOption {
  value: string | number
  label: string
}

export interface FilterAvailable {
  name: string
  operator: FilterOperator
  initialValue: any
  placeholder?: string
  translation: string
}

export interface Filter {
  field: string
  operator?: FilterOperator
  value?: any
}
