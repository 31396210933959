import { Component, OnDestroy, OnInit } from '@angular/core'
import { Validators, FormControl, FormGroup } from '@angular/forms'

// Store
import { AuthStoreService } from '@evologi/shared/auth-store'
import {
  AuthNotification,
  AuthRepository,
} from '@evologi/shared/data-access-api'
import { Subject, distinctUntilChanged, filter, take, takeUntil } from 'rxjs'

interface LoginForm {
  username: FormControl<string>
  password: FormControl<string>
  force: FormControl<boolean>
}

@Component({
  selector: 'op-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm = new FormGroup<LoginForm>({
    username: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    force: new FormControl(false, {
      nonNullable: true,
    }),
  })
  public capsOn = false

  // Observables
  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private authStoreService: AuthStoreService,
    private authRepository: AuthRepository,
  ) {}

  // Observables
  public user$ = this.authStoreService.getUser$()
  public tenant$ = this.authStoreService.getTenant$()

  // Lifecycle

  ngOnInit(): void {
    // Notification
    this.authStoreService
      .getNotification$()
      .pipe(
        takeUntil(this.destroy$),
        filter((notification) => notification?.code === 'SESSION_CONFLICT'),
      )
      .subscribe(
        (notification) => notification && this.showSessionAlert(notification),
      )
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  // Auth methods

  login() {
    const { username, password, force } = this.loginForm.value

    if (username && password) {
      this.authStoreService.login(username.toLowerCase(), password, force)
    }
  }

  logout() {
    this.authStoreService.logout()
  }

  showSessionAlert(notification: AuthNotification) {
    this.authRepository
      .alert$(notification)
      .pipe(take(1), distinctUntilChanged())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.loginForm.get('force')?.setValue(true)
          this.login()
        }
      })
  }
}
