import { cloneDeep, defaultsDeep, isEqual, uniq } from 'lodash'

import {
  CustomerSearchParams,
  CustomerSearchField,
  CustomerSortField,
  Customer,
  CustomerShippingAddress,
  CustomersListingKeys,
} from '../customer.model'
import { initAddress } from '../../../libs/address.lib'
import {
  QueryStringFilter,
  QueryStringSort,
} from '../../../models/query-string.model'
import { Page } from '../../../models/util.model'

// Initial states

const CUSTOMER_INITIAL_STATE: Partial<Customer> = {
  billingAddress: initAddress(),
  shippingAddress: [],
}

// Init

/**
 * Initialize a partial customer
 * @param customer - the partial customer
 * @returns the customer updated
 */
export function initCustomer(customer: Partial<Customer> = {}): Customer {
  return defaultsDeep(cloneDeep(customer), CUSTOMER_INITIAL_STATE)
}

// Addresses

/**
 * Initialize a customer shipping address
 * @param customer - the customer
 * @returns the shipping address
 */
export function initCustomerShippingAddress(
  customer: Customer,
): CustomerShippingAddress {
  return {
    name: customer.name,
    businessName: customer.businessName,
    address1: customer.billingAddress.address1,
    address2: customer.billingAddress.address2,
    city: customer.billingAddress.city,
    province: customer.billingAddress.province,
    postalCode: customer.billingAddress.postalCode,
    countryCode: customer.billingAddress.countryCode,
    phone: customer.phone,
    email: customer.email,
    isDefault: true,
  }
}

/**
 * Checks whether a customer has a shipping address
 * @param customer - the customer
 * @returns the updated customer
 */
export function checkShippingAddress(customer: Customer): Customer {
  if (customer.shippingAddress?.length > 0) {
    return customer
  }

  return {
    ...customer,
    shippingAddress: [initCustomerShippingAddress(customer)],
  }
}

/**
 * Adds a new shipping address to a shipping addresses list
 * @param addresses - the list of addresses
 * @param address - the address to be added
 * @returns the list of shipping addresses
 */
export function addAddress(
  addresses: CustomerShippingAddress[],
  address: CustomerShippingAddress,
): CustomerShippingAddress[] {
  return [...addresses, address]
}

/**
 * Updates a shipping address to a shipping addresses list
 * @param addresses - the list of addresses
 * @param address - the updated address
 * @param addressIndex - the index at which the address to be updated will be found
 * @returns the list of shipping addresses
 */
export function updateAddress(
  addresses: CustomerShippingAddress[],
  address: CustomerShippingAddress,
  addressIndex: number,
): CustomerShippingAddress[] {
  return addresses.map((a, i) => (i === addressIndex ? address : a))
}

/**
 * Removes a shipping address from a shipping addresses list
 * @param addresses - the list of addresses
 * @param addressIndex - the index at which the address to be removed will be found
 * @returns the list of shipping addresses
 */
export function removeAddress(
  addresses: CustomerShippingAddress[],
  addressIndex: number,
): CustomerShippingAddress[] {
  return addresses.filter((a, i) => i !== addressIndex)
}

/**
 * Sets a shipping address as default
 * @param addresses - the list of addresses
 * @param addressUpdated - the updated address
 * @returns the list of shipping addresses
 */
export function setDefaultAddress(
  addresses: CustomerShippingAddress[],
  addressUpdated: CustomerShippingAddress,
): CustomerShippingAddress[] {
  if (!addressUpdated.isDefault) {
    return addresses
  }

  return addresses.map((a) =>
    isEqual(a, addressUpdated) ? addressUpdated : { ...a, isDefault: false },
  )
}

// Search

/**
 * Parse sort params
 * @param params - the search params
 * @param sort - the sort param to parse
 * @returns the customer search params updated
 */
export function customerSortParams(
  params: CustomerSearchParams,
  sort: QueryStringSort<CustomerSortField>,
): CustomerSearchParams {
  const searchParams: CustomerSearchParams = {}

  switch (sort.field) {
    case 'code':
      searchParams.sort = 'code'
      searchParams.order = sort.order
      break
    case 'name':
      searchParams.sort = 'name'
      searchParams.order = sort.order
      break
    case 'businessName':
      searchParams.sort = 'businessName'
      searchParams.order = sort.order
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse filter params
 * @param params - the search params
 * @param filter - the filter to parse
 * @returns the customer search params updated
 */
export function customerFilterParams(
  params: CustomerSearchParams,
  filter: QueryStringFilter<CustomerSearchField>,
): CustomerSearchParams {
  const searchParams: CustomerSearchParams = {}

  switch (filter.field) {
    case '_id':
      if (filter.operator === '=') {
        searchParams._id = filter.value
      }
      break
    case 'name':
      if (filter.operator === '=') {
        searchParams.name = filter.value
      } else if (filter.operator === '<>') {
        searchParams['name:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['name:ct'] = filter.value
      }
      break
    case 'businessName':
      if (filter.operator === '=') {
        searchParams.businessName = filter.value
      } else if (filter.operator === '<>') {
        searchParams['businessName:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['businessName:ct'] = filter.value
      }
      break
    case 'email':
      if (filter.operator === '=') {
        searchParams.email = filter.value
      } else if (filter.operator === '<>') {
        searchParams['email:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['email:ct'] = filter.value
      }
      break
    case 'billingAddress.address1':
      if (filter.operator === '=') {
        searchParams['billingAddress.address1'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['billingAddress.address1:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['billingAddress.address1:ct'] = filter.value
      }
      break
    case 'billingAddress.city':
      if (filter.operator === '=') {
        searchParams['billingAddress.city'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['billingAddress.city:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['billingAddress.city:ct'] = filter.value
      }
      break
    case 'billingAddress.province':
      if (filter.operator === '=') {
        searchParams['billingAddress.province'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['billingAddress.province:ne'] = filter.value
      }
      break
    case 'billingAddress.countryCode':
      if (filter.operator === '=') {
        searchParams['billingAddress.countryCode'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['billingAddress.countryCode:ne'] = filter.value
      }
      break
    case 'billingAddress.postalCode':
      if (filter.operator === '=') {
        searchParams['billingAddress.postalCode'] = filter.value
      } else if (filter.operator === '<>') {
        searchParams['billingAddress.postalCode:ne'] = filter.value
      }
      break
    case 'code':
      if (filter.operator === '=') {
        searchParams.code = filter.value
      } else if (filter.operator === '<>') {
        searchParams['code:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['code:ct'] = filter.value
      }
      break
    case 'alias':
      if (filter.operator === '=') {
        searchParams.alias = filter.value
      } else if (filter.operator === '<>') {
        searchParams['alias:ne'] = filter.value
      }
      break
    case 'priceList':
      if (filter.operator === '=') {
        searchParams.priceList = filter.value
      } else if (filter.operator === '<>') {
        searchParams['priceList:ne'] = filter.value
      } else if (filter.operator === 'contains') {
        searchParams['priceList:ct'] = filter.value
      }
      break
  }

  return {
    ...params,
    ...searchParams,
  }
}

/**
 * Parse a customer page and product counts to a single page data
 * @param customerPage - the customer page
 * @param productsCounts - the product counts
 * @returns the customers page data
 */
export function parseCustomersPage(
  customerPage: Page<Customer>,
): Page<Customer> {
  const data = customerPage.data.map((b) => ({
    ...b,
  }))
  return {
    totalCount: customerPage.totalCount,
    data,
  }
}

/**
 * Parse customer page keys
 * @param page - the customer page
 * @returns the page listing keys
 */
export function parseCustomerPageKeys(
  page: Page<Customer>,
): CustomersListingKeys {
  return page.data.reduce<CustomersListingKeys>(
    (acc, r) => parseCustomerKeys(r, acc),
    {},
  )
}

/**
 * Parse customer keys
 * @param customer - the customer
 * @param keys - the keys already loaded
 * @returns the customers listing keys
 */
export function parseCustomerKeys(
  customer: Customer,
  keys: CustomersListingKeys = {},
): CustomersListingKeys {
  const countryCodes = uniq([
    ...(keys['countryCodes'] || []),
    ...(customer.billingAddress?.countryCode
      ? [customer.billingAddress?.countryCode]
      : []),
  ])

  return {
    countryCodes,
  }
}

/**
 * Updates the given list of addresses
 * @param addresses - the list of addresses
 * @param addr - the Observable address
 * @param address - the original address to update
 * @returns the updated list of addresses
 */
export function updateAddresses(
  addresses: CustomerShippingAddress[],
  addr: CustomerShippingAddress,
  address?: CustomerShippingAddress,
): CustomerShippingAddress[] {
  const addressIndex = addresses.findIndex((a) => isEqual(a, address))
  return addressIndex >= 0
    ? updateAddress(addresses, addr, addressIndex)
    : addAddress(addresses, addr)
}

/**
 * Updates the given list of addresses
 * @param addresses - the list of addresses
 * @param address - the original address to delete
 * @returns the updated list of addresses
 */
export function deleteAddress(
  address: CustomerShippingAddress,
  addresses: CustomerShippingAddress[],
): CustomerShippingAddress[] {
  const addressIndex = addresses.findIndex((a) => isEqual(a, address))
  return removeAddress(addresses, addressIndex)
}
