import { RoutePath } from '../models/route.model'
import { MenuGroup } from '../models/menu.model'

export const MENU_GROUPS: MenuGroup[] = [
  {
    label: 'Home',
    description: 'Dashboard di OrderPod',
    url: RoutePath.home,
    icon: 'fad fa-home',
    isOpened: false,
  },
  {
    label: 'Catalogo',
    description: 'Sezione dedicata alla gestione prodotti',
    icon: 'fad fa-list',
    isOpened: false,
    scopes: [
      'products',
      'categories',
      'classifications',
      'brands',
      'manufacturers',
      'catalogs',
    ],
    items: [
      {
        label: 'Prodotti',
        url: RoutePath.products,
        scopes: ['products'],
      },
      {
        label: 'Categorie',
        url: RoutePath.categories,
        scopes: ['categories'],
        level: 'READ_WRITE',
      },
      {
        label: 'Classificazioni',
        url: RoutePath.classifications,
        scopes: ['classifications'],
        level: 'READ_WRITE',
      },
      {
        label: 'Brand',
        url: RoutePath.brands,
        scopes: ['brands'],
        level: 'READ_WRITE',
      },
      {
        label: 'Cataloghi',
        url: RoutePath.catalogs,
        scopes: ['catalogs'],
        level: 'READ_WRITE',
      },
      {
        label: 'Produttori',
        url: RoutePath.manufacturers,
        scopes: ['manufacturers'],
        level: 'READ_WRITE',
      },
      {
        label: 'Famiglie',
        url: RoutePath.productFamilies,
        scopes: ['products'],
        level: 'READ_WRITE',
      },
      {
        label: 'Attributi',
        url: RoutePath.attributes,
        scopes: ['products'],
        level: 'READ_WRITE',
      },
      {
        label: 'Gruppi di attributi',
        url: RoutePath.attributeGroups,
        scopes: ['products'],
        level: 'READ_WRITE',
      },
    ],
  },
  {
    label: 'Anagrafiche',
    description:
      'Sezione dedicata alla gestione delle anagrafiche di clienti e fornitori',
    icon: 'fad fa-users',
    isOpened: false,
    scopes: ['customers', 'suppliers'],
    items: [
      {
        label: 'Clienti',
        url: RoutePath.customers,
        scopes: ['customers'],
      },
      {
        label: 'Fornitori',
        url: RoutePath.suppliers,
        scopes: ['suppliers'],
      },
    ],
  },
  {
    label: 'Vendite',
    description: 'Sezione dedicata alla gestione di ordini clienti',
    icon: 'fad fa-sack-dollar',
    isOpened: false,
    scopes: ['orders', 'invoices', 'goodsReturns'],
    items: [
      {
        label: 'Ordini clienti',
        url: RoutePath.orders,
        scopes: ['orders'],
      },
      {
        label: 'Fatture',
        url: RoutePath.invoices,
        scopes: ['invoices'],
      },
      {
        label: 'Analisi del venduto',
        url: RoutePath.sales,
        scopes: ['orders', 'supplierOrders'],
        level: 'READ_WRITE',
      },
      {
        label: 'Gestione resi',
        url: RoutePath.goodsReturn,
        scopes: ['goodsReturns'],
      },
    ],
  },
  {
    label: 'Acquisti',
    description:
      'Sezione dedicata alla gestione degli acquisti come ordini fornitore e arrivi merce',
    isOpened: false,
    icon: 'fad fa-shopping-basket',
    scopes: ['supplierOrders', 'goodsReceives'],
    level: 'READ_WRITE',
    items: [
      {
        label: 'Ordini fornitore',
        url: RoutePath.supplierOrders,
        scopes: ['supplierOrders'],
        level: 'READ_WRITE',
      },
      {
        label: 'Arrivi merce',
        url: RoutePath.goodsReceive,
        scopes: ['goodsReceives'],
        level: 'READ_WRITE',
      },
    ],
  },
  {
    label: 'Logistica',
    description:
      'Sezione dedicata alla gestione degli acquisti come ordini fornitore e arrivi merce',
    isOpened: false,
    icon: 'fad fa-warehouse-alt',
    scopes: ['pickingLists', 'packingLists', 'bordereaus', 'movements'],
    items: [
      {
        label: 'Picking list',
        url: RoutePath.pickings,
        scopes: ['pickingLists'],
        level: 'READ_WRITE',
      },
      {
        label: 'Packing',
        url: RoutePath.packingLists,
        scopes: ['packingLists'],
        level: 'READ_WRITE',
      },
      {
        label: 'Distinte vettori',
        url: RoutePath.bordereau,
        scopes: ['bordereaus'],
        level: 'READ_WRITE',
      },
      {
        label: 'Movimenti',
        url: RoutePath.movements,
        scopes: ['movements'],
        level: 'READ_WRITE',
      },
      {
        label: 'Giacenze',
        url: RoutePath.stocks,
        scopes: ['movements'],
      },
      {
        label: 'Missioni di trasferimento',
        url: RoutePath.replenishments,
        scopes: ['replenishments'],
      },
    ],
  },
  {
    label: 'Import/Export',
    description:
      'Sezione dedicata alla gestione degli import/export di ordini su OrderPod',
    isOpened: false,
    icon: 'fad fa-exchange-alt',
    scopes: ['activities'],
    level: 'READ_WRITE',
    items: [
      {
        label: 'Attività',
        url: RoutePath.activities,
      },
      {
        label: 'Esecuzioni',
        url: RoutePath.executions,
      },
      {
        label: 'Tasks',
        url: RoutePath.tasks,
      },
    ],
  },
  {
    label: 'Strutture',
    description: 'Sezione dedicata alla gestione delle strutture di OrderPod',
    icon: 'fad fa-store-alt',
    isOpened: false,
    scopes: ['locations', 'banners', 'pickupPoints'],
    level: 'READ_WRITE',
    items: [
      {
        label: 'Magazzini',
        url: RoutePath.warehouses,
        scopes: ['locations'],
      },
      {
        label: 'Pickup point',
        url: RoutePath.pickupPoints,
        scopes: ['pickupPoints'],
      },
      {
        label: 'Insegne',
        url: RoutePath.banners,
        scopes: ['banners'],
      },
    ],
  },
  {
    label: 'E-commerce',
    description:
      'Sezione dedicata a tutte le funzionalità collegate a servizi esterni',
    icon: 'fad fa-sitemap',
    isOpened: false,
    scopes: ['channels', 'payments'],
    level: 'READ_WRITE',
    items: [
      {
        label: 'Canali di vendita',
        url: RoutePath.channels,
        scopes: ['payments'],
      },
      {
        label: 'Metodi di pagamento',
        url: RoutePath.payments,
        scopes: ['channels'],
      },
    ],
  },
  {
    label: 'Impostazioni',
    description:
      'Sezione dedicata alla gestione delle impostazioni di OrderPod',
    icon: 'fad fa-tools',
    isOpened: false,
    items: [
      {
        label: 'Generali',
        url: RoutePath.settings,
        scopes: ['tenant'],
        level: 'READ_WRITE',
      },
      {
        label: 'Profilo',
        url: RoutePath.usersProfile,
      },
      {
        label: 'Utenti',
        url: RoutePath.users,
        scopes: ['iam'],
        level: 'READ_WRITE',
      },
      {
        label: 'Viste personalizzate',
        url: RoutePath.views,
      },
      {
        label: 'Causali',
        url: RoutePath.reasons,
        scopes: ['movements'],
        level: 'READ_WRITE',
      },
      {
        label: 'Corrieri',
        url: RoutePath.carriers,
        scopes: ['carriers'],
        level: 'READ_WRITE',
      },
      {
        label: 'Webhook',
        url: RoutePath.webhooks,
        scopes: ['integrations'],
        level: 'READ_WRITE',
      },
      {
        label: 'Automazioni',
        url: RoutePath.automations,
        scopes: ['integrations'],
        level: 'READ_WRITE',
      },
      {
        label: 'Stampanti',
        url: RoutePath.printers,
      },
      {
        label: 'Tag',
        url: RoutePath.tags,
        scopes: ['products', 'orders'],
        mode: 'ONE',
        level: 'READ_WRITE',
      },
    ],
  },
]
