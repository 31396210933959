import { createAction, props } from '@ngrx/store'
import { Currency, CurrencySearchParams } from '@evologi/shared/data-access-api'

export enum CurrenciesAction {
  loadCurrencies = '[CURRENCIES] load currencies',
  setCurrencies = '[CURRENCIES] set currencies',
  destroy = '[CURRENCIES] destroy',
}

export const loadCurrencies = createAction(
  CurrenciesAction.loadCurrencies,
  props<{ tenantId: string; params?: CurrencySearchParams }>(),
)

export const setCurrencies = createAction(
  CurrenciesAction.setCurrencies,
  props<{ tenantId: string; currencies: Currency[] }>(),
)

export const destroyCurrencies = createAction(CurrenciesAction.destroy)
