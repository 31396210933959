import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, combineLatest } from 'rxjs'
import { paginateArray } from '@evologi/shared/util-toolkit'
import { map } from 'rxjs/operators'

import {
  Bordereau,
  BordereauOrderSearchParams,
  BordereauSearchParams,
} from './bordereau.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'bordereaus'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class BordereausService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new bordereau
   * @param bordereau - The bordereau to create
   * @returns The observable<Bordereau> to create the bordereau
   */
  create$(bordereau: Bordereau): Observable<Bordereau> {
    return this._create$<Bordereau>(bordereau)
  }

  /**
   * Read a bordereau by ID
   * @param bordereauId - The bordereau ID
   * @returns The observable<Bordereau> for read the bordereau
   */
  read$(bordereauId: string): Observable<Bordereau> {
    return this._read$<Bordereau>(bordereauId)
  }

  /**
   * Update a bordereau by ID
   * @param bordereauId - The bordereau ID
   * @param bordereau - The bordereau body to update
   * @returns The observable<Bordereau> for update the bordereau
   */
  update$(bordereauId: string, bordereau: Bordereau): Observable<Bordereau> {
    return this._update$<Bordereau>(bordereauId, bordereau)
  }

  /**
   * Create or update a bordereau by ID
   * @param bordereauId - The bordereau ID
   * @param bordereau - The bordereau body to update
   * @returns The observable<Bordereau> for update the bordereau
   */
  upsert$(bordereau: Bordereau): Observable<Bordereau> {
    return this._upsert$<Bordereau>(bordereau, bordereau._id)
  }

  /**
   * Delete a bordereau by ID
   * @param bordereauId - The bordereau ID
   * @returns The observable<Bordereau> for delete the bordereau
   */
  delete$(bordereauId: string): Observable<Bordereau> {
    return this._delete$<Bordereau>(bordereauId)
  }

  /**
   * Search bordereaus by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Bordereau>> for search bordereaus
   */
  search$(
    params?: BordereauSearchParams,
    returnAll = false,
  ): Observable<Page<Bordereau>> {
    return this._search$<Bordereau>(params, returnAll)
  }

  /**
   * List bordereaus by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Bordereau[]> for list bordereaus
   */
  list$(
    params?: BordereauSearchParams,
    returnAll = false,
  ): Observable<Bordereau[]> {
    return this._list$<Bordereau>(params, returnAll)
  }

  /**
   * Ship bordereau
   * @param bordereauId - The bordereau ID
   * @returns The observable<Bordereau> for ship the bordereau
   */
  ship$(bordereauId: string): Observable<Bordereau> {
    return this.http.post<Bordereau>(`${this.apiUrl}/${bordereauId}/ship`, {})
  }

  /**
   * Add orders to bordereau
   * @param bordereauId - The bordereau ID
   * @param orderIds - The order IDs to add
   * @returns The observable<Bordereau> for add orders to bordereau
   */
  addOrders$(bordereauId: string, orderIds: string[]): Observable<Bordereau> {
    const observables$: Observable<Bordereau>[] = []

    for (const page of paginateArray(orderIds, this.limit)) {
      observables$.push(
        this.http.post<Bordereau>(`${this.apiUrl}/${bordereauId}/orders/push`, {
          orderIds: orderIds.slice(page.start, page.end),
        }),
      )
    }

    return combineLatest(observables$).pipe(
      map(([bordereau, ...others]) => bordereau),
    )
  }

  /**
   * Get bordereau orders
   * @param bordereauId - The bordereau ID
   * @param params - The limit (default 50 max 200) and offset params for paginate the results
   * @returns The observable<Bordereau> for ship the bordereau
   */
  listOrders$(
    bordereauId: string,
    params?: BordereauOrderSearchParams,
    returnAll = false,
  ): Observable<any> {
    return this._listChildren$(bordereauId, 'orders', params, returnAll)
  }

  /**
   * Remove bordereau orders
   * @param bordereauId - The bordereau ID
   * @param orderIds - The order IDs to remove
   * @returns The observable<Bordereau> for remove orders from bordereau
   */
  removeOrders$(bordereauId: string, orderIds: string[]): Observable<any> {
    const observables$: Observable<Bordereau>[] = []

    for (const page of paginateArray(orderIds, this.limit)) {
      observables$.push(
        this.http.post<Bordereau>(`${this.apiUrl}/${bordereauId}/orders/pull`, {
          orderIds: orderIds.slice(page.start, page.end),
        }),
      )
    }

    return combineLatest(observables$).pipe(
      map(([bordereau, ...others]) => bordereau),
    )
  }

  /**
   * Print the bordereau summary
   * @param bordereauId - The bordereau ID
   * @param options - The print options
   * @returns The observable<void> for print the bordereau summary
   */
  print$(
    bordereauId: string,
    options: { printerId: string },
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${bordereauId}/files/summary/print`,
      options,
    )
  }

  /**
   * Download the bordereau summary
   * @param bordereauId - The bordereau ID
   * @returns The observable<Blob> to download the bordereau summary
   */
  download$(bordereauId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${bordereauId}/files/summary`, {
      responseType: 'blob',
    })
  }
}
