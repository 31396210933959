import { Pipe, PipeTransform } from '@angular/core'
import { Product } from '../product.model'

@Pipe({
  name: 'productPopover',
})
export class ProductPopoverPipe implements PipeTransform {
  transform(product?: Product, productName?: string): string {
    if (!product) {
      return 'Riepilogo quantità ordinate'
    }
    return `Riepilogo quantità ordinate: ${product.SKU} - ${productName}`
  }
}
