import Dinero from 'dinero.js'
import { isNil } from 'lodash'

export function dinero(value: any, precision = 6) {
  if (!Number.isFinite(value)) {
    throw new Error(`Invalid number: ${value}`)
  }
  return Dinero({
    amount: Math.round(value * Math.pow(10, precision)),
    precision,
  })
}

export interface Prices {
  taxRate: number
  price: Dinero.Dinero
  taxes: Dinero.Dinero
  total: Dinero.Dinero
}

export interface PricesValues {
  precision?: number
  taxRate?: number
  price?: number
  taxes?: number
  total?: number
}

export interface Percentages {
  total: Dinero.Dinero
  value: Dinero.Dinero
  percentage: Dinero.Dinero
}

export interface PercentagesValues {
  total: number
  value?: number
  percentage?: number
}

function fromPrice(taxRate: number, price: Dinero.Dinero): Prices {
  const taxes = price.percentage(taxRate)
  const total = price.add(taxes)

  return {
    taxRate,
    price,
    taxes,
    total,
  }
}

function fromTotal(taxRate: number, total: Dinero.Dinero): Prices {
  const taxes = total.multiply(taxRate).divide(100 + taxRate)
  const price = total.subtract(taxes)

  return {
    taxRate,
    price,
    taxes,
    total,
  }
}

function fromTaxes(taxRate: number, taxes: Dinero.Dinero): Prices {
  const price = taxes.multiply(taxRate)
  const total = price.add(taxes)
  return {
    taxRate,
    price,
    taxes,
    total,
  }
}

function asDinero(
  value: Dinero.Dinero | number,
  precision?: number
): Dinero.Dinero {
  return typeof value === 'number' ? dinero(value, precision) : value
}

export function calculatePrices(prices: PricesValues): Prices {
  const { taxRate, price, taxes, total, precision } = prices
  const taxRateCheck = taxRate || 0

  if (!isNil(total)) {
    return fromTotal(taxRateCheck, asDinero(total, precision))
  } else if (!isNil(price)) {
    return fromPrice(taxRateCheck, asDinero(price, precision))
  } else if (!isNil(taxes)) {
    return fromTaxes(taxRateCheck, asDinero(taxes, precision))
  } else {
    return fromPrice(taxRateCheck, asDinero(0, precision))
  }
}

function fromTotalValueToPercentage(
  totalRaw: number,
  valueRaw: number
): Percentages {
  const total = dinero(totalRaw)
  const value = dinero(valueRaw)
  const percentage =
    totalRaw > 0 ? value.divide(totalRaw).multiply(100) : dinero(0)

  return {
    total,
    value,
    percentage,
  }
}

function fromTotalPercentageToValue(
  totalRaw: number,
  percentageRaw: number
): Percentages {
  const total = dinero(totalRaw)
  const value = total.percentage(percentageRaw)
  const percentage = dinero(percentageRaw)

  return {
    total,
    value,
    percentage,
  }
}

function fromValuePercentageToTotal(
  valueRaw: number,
  percentageRaw: number
): Percentages {
  const value = dinero(valueRaw)
  const total = value.divide(percentageRaw).multiply(100)
  const percentage = dinero(percentageRaw)

  return {
    total,
    value,
    percentage,
  }
}

export function calculatePercentage(
  percentages: PercentagesValues
): Percentages {
  const { total, value, percentage } = percentages
  const percentageCheck = percentage || 0

  if (isNil(total)) {
    return fromValuePercentageToTotal(value!, percentageCheck)
  } else if (isNil(value)) {
    return fromTotalPercentageToValue(total, percentageCheck)
  } else {
    return fromTotalValueToPercentage(total, value)
  }
}
