import { InjectionToken } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { DialogParams } from './notification.model'

/**
 * Modal params
 */
export interface ModalParams<ComponentType> {
  component: any
  modalClass?: string
  initialState?: Partial<ComponentType>
}

/**
 * Modal reference
 */
export interface ModalRef<ModalResponseType = any> {
  ref: any
  subject: Subject<ModalResponseType | undefined>
}

/**
 * Modal alert management
 */

export type ModalAlertType = 'error' | 'info' | 'success'

export interface ModalAlert {
  visible: boolean
  type: ModalAlertType
  text: string
}

/**
 * Actions
 */
export interface ModalAction<T = string> extends Record<string, any | any[]> {
  type: T
}

/**
 * Modal info
 */
export interface ModalInfo {
  icon?: string
  label?: string
  explain?: string
  text: string
}

/**
 * Modal manager base structure
 */
export declare interface ModalManager {
  show$: <ComponentType, ModalResponseType = any>(
    params: ModalParams<ComponentType>
  ) => Observable<ModalResponseType | undefined>
  showDialog$: (
    params: DialogParams,
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  ) => Observable<boolean>
  hide: <ModalResponseType = any>(
    modalId: string,
    response?: ModalResponseType
  ) => void
  hideAll: () => void
}

export const MODAL_MANAGER = new InjectionToken<ModalManager>('ModalManager')

/***
 * Modal component base structure
 */
export declare interface ModalComponent {
  modalId: string
  title?: string
}
