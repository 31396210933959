import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Directives
import { AlphabetDirective } from './directives/alphabet.directive'
import { CapsLockDirective } from './directives/caps-lock.directive'
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive'
import { DropzoneDirective } from './directives/dropzone.directive'
import { NgVarDirective } from './directives/ng-var.directive'
import { PrintableCharsValidatorDirective } from './directives/printable-chars-validator.directive'
import { EqualValidator } from './directives/equal-validator.directive'
import { FileValidator } from './directives/file-validator.directive'
import { FormatValidatorDirective } from './directives/format-validator.directive'
import { LowercaseDirective } from './directives/lowercase.directive'
import { SlugifyDirective } from './directives/slugify.directive'
import { UppercaseDirective } from './directives/uppercase.directive'
import { UrlValidator } from './directives/url-validator.directive'
import { CoordinateValidatorDirective } from './directives/coordinate-validator.directive'

// Pipes
import { ArrayLengthPipe } from './pipes/array-length.pipe'
import { ColorNegativePipe } from './pipes/color-negative.pipe'
import { CurrencySimbolPipe } from './pipes/currency-simbol.pipe'
import { DuplicatedPipe } from './pipes/duplicated.pipe'
import { FileExtPipe } from './pipes/file-ext.pipe'
import { FileNamePipe } from './pipes/file-name.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { FindIndexPipe } from './pipes/find-index.pipe'
import { FindPipe } from './pipes/find.pipe'
import { GetPipe } from './pipes/get.pipe'
import { HtmlDecoderPipe } from './pipes/html-decoder.pipe'
import { HtmlStripPipe } from './pipes/html-strip.pipe'
import { IncludesPipe } from './pipes/includes.pipe'
import { JoinPipe } from './pipes/join.pipe'
import { MapPipe } from './pipes/map.pipe'
import { ObjectKeysPipe } from './pipes/object-keys.pipe'
import { OrderByPipe } from './pipes/order-by.pipe'
import { ParseNumberPipe } from './pipes/parse-number.pipe'
import { PercentagePipe } from './pipes/percentage.pipe'
import { ReducePipe } from './pipes/reduce.pipe'
import { SortPipe } from './pipes/sort.pipe'
import { SplitPipe } from './pipes/split.pipe'
import { StringStartsWithPipe } from './pipes/string-starts-with.pipe'
import { SumPipe } from './pipes/sum.pipe'
import { TemplatePipe } from './pipes/template.pipe'
import { TimePipe } from './pipes/time.pipe'
import { TimestampPipe } from './pipes/timestamp.pipe'
import { UnitConversionPipe } from './pipes/unit-conversion.pipe'
import { WrapPipe } from './pipes/wrap.pipe'
import { DifferencePipe } from './pipes/difference.pipe'
import { IntersectionPipe } from './pipes/intersection.pipe'
import { StatusHistoryTimePipe } from './pipes/status-history-time.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [
    // Directives
    AlphabetDirective,
    CapsLockDirective,
    ClickStopPropagationDirective,
    DropzoneDirective,
    NgVarDirective,
    PrintableCharsValidatorDirective,
    EqualValidator,
    FileValidator,
    FormatValidatorDirective,
    LowercaseDirective,
    SlugifyDirective,
    UppercaseDirective,
    UrlValidator,
    CoordinateValidatorDirective,

    // Pipes
    ArrayLengthPipe,
    ColorNegativePipe,
    CurrencySimbolPipe,
    DuplicatedPipe,
    FileExtPipe,
    FileNamePipe,
    FilterPipe,
    FindIndexPipe,
    FindPipe,
    StatusHistoryTimePipe,
    GetPipe,
    HtmlDecoderPipe,
    HtmlStripPipe,
    IncludesPipe,
    JoinPipe,
    MapPipe,
    ObjectKeysPipe,
    OrderByPipe,
    ParseNumberPipe,
    PercentagePipe,
    ReducePipe,
    SortPipe,
    SplitPipe,
    StringStartsWithPipe,
    SumPipe,
    TemplatePipe,
    TimePipe,
    TimestampPipe,
    UnitConversionPipe,
    WrapPipe,
    DifferencePipe,
    IntersectionPipe,
  ],
  exports: [
    // Directives
    AlphabetDirective,
    CapsLockDirective,
    ClickStopPropagationDirective,
    DropzoneDirective,
    NgVarDirective,
    PrintableCharsValidatorDirective,
    EqualValidator,
    FileValidator,
    FormatValidatorDirective,
    LowercaseDirective,
    SlugifyDirective,
    UppercaseDirective,
    UrlValidator,
    CoordinateValidatorDirective,

    // Pipes
    ArrayLengthPipe,
    ColorNegativePipe,
    CurrencySimbolPipe,
    DuplicatedPipe,
    FileExtPipe,
    FileNamePipe,
    FilterPipe,
    FindIndexPipe,
    FindPipe,
    StatusHistoryTimePipe,
    GetPipe,
    HtmlDecoderPipe,
    HtmlStripPipe,
    IncludesPipe,
    JoinPipe,
    MapPipe,
    ObjectKeysPipe,
    OrderByPipe,
    ParseNumberPipe,
    PercentagePipe,
    ReducePipe,
    SortPipe,
    SplitPipe,
    StringStartsWithPipe,
    SumPipe,
    TemplatePipe,
    TimePipe,
    TimestampPipe,
    UnitConversionPipe,
    WrapPipe,
    DifferencePipe,
    IntersectionPipe,
  ],
})
export class SharedUtilToolkitModule {}
