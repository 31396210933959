import { Product, ProductType } from '../../products'
import { Order, OrderPickingMission, OrderRow } from '../order.model'

/**
 * Get the quantity of products picked
 * @param order - the customer order
 * @returns the total of products picked
 */
export function getOrderPickedQty(order: Order): number {
  return order.rows.reduce((total, row) => total + getOrderRowPickedQty(row), 0)
}

/**
 * Get the quantities about a product inside an order
 * @param rows - the order rows
 * @param productId - the product ID
 * @returns the pick quantities about a product inside the order
 */
export function getOrderProductPickQuantities(
  rows: OrderRow[],
  productId: string
): { qtyToPick: number; qtyPicked: number } {
  return rows.reduce(
    (quantities, row) => {
      if (!checkOrderProductRow(row, productId)) {
        return quantities
      }

      if (row.product._id === productId) {
        quantities.qtyToPick += row.orderedQty
        quantities.qtyPicked += row.shippedQty || 0
      } else if (row.product.productType === 'KIT') {
        const simpleProducts = row.product.simpleProducts.filter(
          (p) => p._id === productId
        )

        for (const simpleProduct of simpleProducts) {
          quantities.qtyToPick += simpleProduct.quantity * row.orderedQty
          quantities.qtyPicked += simpleProduct.shippedQty || 0
        }
      }

      return quantities
    },
    { qtyToPick: 0, qtyPicked: 0 }
  )
}

/**
 * Check if an order is pickable
 * @param order - the customer order
 * @returns true if the order can be pickable, false otherwise
 */
export function checkOrderPickable(order: Order): boolean {
  return order.rows.some(
    (r) => r.product.productType === 'SIMPLE' || r.product.productType === 'KIT'
  )
}

/**
 * Check if an order is partially picked
 * @param order - the customer order
 * @returns true if the order has been partially picked, false otherwise
 */
export function checkOrderPicked(order: Order): boolean {
  return order.rows.some((row) => !!row.shippedQty)
}

/**
 * Check if an order is full picked
 * @param order - the customer order
 * @returns true if the order has been full picked, false otherwise
 */
export function checkOrderFullPicked(order: Order): boolean {
  return order.rows.every((row) => checkOrderRowFullPicked(row))
}

/**
 * Parse the products of an order into picking missions
 * @param order - the customer order
 * @param products - the pickable products
 * @returns an array of picking missions
 */
export function parseOrderProductMissions(
  order: Order,
  products: Product[]
): OrderPickingMission[] {
  return products.map((p) => parseOrderProductMission(order, p))
}

// Utilities

function getOrderRowQtyToPick(row: OrderRow): number {
  return row.product.productType === ProductType.kit
    ? row.product.simpleProducts!.reduce(
        (toPick, simpleProduct) =>
          toPick + row.orderedQty * simpleProduct.quantity,
        0
      )
    : row.orderedQty
}

function getOrderRowPickedQty(row: OrderRow): number {
  return row.product.productType === ProductType.kit
    ? row.product.simpleProducts!.reduce(
        (pickedQty, simpleProduct) =>
          pickedQty + (simpleProduct.shippedQty || 0),
        0
      )
    : row.shippedQty ?? 0
}

function checkOrderRowFullPicked(row: OrderRow): boolean {
  return getOrderRowQtyToPick(row) === getOrderRowPickedQty(row)
}

function parseOrderProductMission(
  order: Order,
  product: Product
): OrderPickingMission {
  const { qtyToPick, qtyPicked } = getOrderProductPickQuantities(
    order.rows,
    product._id!
  )
  const warehouseId = order.assignedWarehouseId
  const warehouse = product.warehouses.find((w) => w._id === warehouseId)
  const pickingLocations = product.locations.filter(
    (l) => l.warehouseId === warehouseId && l.onHandQty > 0
  )

  return {
    qtyToPick,
    qtyPicked,
    productId: product._id!,
    onHandQty: warehouse?.onHandQty || 0,
    availableQty: warehouse?.availableQty || 0,
    pickingLocations,
  }
}

function checkOrderProductRow(row: OrderRow, productId: string): boolean {
  if (row.product.productType === 'SIMPLE') {
    return row.product._id === productId
  } else if (row.product.productType === 'KIT') {
    return row.product.simpleProducts.some((p) => p._id === productId)
  }

  return false
}
