import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Activity, ActivitySearchParams } from './activity.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'activities'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new activity
   * @param activity - The activity to create
   * @returns The observable<Activity> to create the activity
   */
  create$(activity: Activity): Observable<Activity> {
    return this._create$<Activity>(activity)
  }

  /**
   * Read an activity by ID
   * @param activityId - The activity ID
   * @returns The observable<Activity> for read the activity
   */
  read$(activityId: string): Observable<Activity> {
    return this._read$<Activity>(activityId)
  }

  /**
   * Update an activity by ID
   * @param activityId - The activity ID
   * @param activity - The activity body to update
   * @returns The observable<Activity> for update the activity
   */
  update$(activityId: string, activity: Activity): Observable<Activity> {
    return this._update$<Activity>(activityId, activity)
  }

  /**
   * Create or update an activity by ID
   * @param activityId - The activity ID
   * @param activity - The activity body to update
   * @returns The observable<Activity> for update the activity
   */
  upsert$(activity: Activity): Observable<Activity> {
    return this._upsert$<Activity>(activity, activity._id)
  }

  /**
   * Delete an activity by ID
   * @param activityId - The activity ID
   * @returns The observable<Activity> for delete the activity
   */
  delete$(activityId: string): Observable<Activity> {
    return this._delete$<Activity>(activityId)
  }

  /**
   * Search activities by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Activity>> for search activities
   */
  search$(
    params?: ActivitySearchParams,
    returnAll = false,
  ): Observable<Page<Activity>> {
    return this._search$<Activity>(params, returnAll)
  }

  /**
   * List activities by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Activity[]> for list activities
   */
  list$(
    params?: ActivitySearchParams,
    returnAll = false,
  ): Observable<Activity[]> {
    return this._list$<Activity>(params, returnAll)
  }

  /**
   * Run the activity by ID
   * @param id - The activity ID to run
   * @returns The observable<void> for run the activity
   */
  run$(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/run`, {})
  }

  /**
   * Enable/disable the activity by ID
   * @param id - The activity ID to toggle
   * @returns The observable<void> for toggle the activity
   */
  toggle$(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/toggle`, {})
  }
}
