import { Directive, HostListener, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

import { slugify } from '../libs/string.lib'

@Directive({
  selector: '[tkSlugify]',
})
export class SlugifyDirective {
  @Input('tkSlugify') replacer: boolean | string = ''

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange($event: any) {
    if (typeof this.replacer === 'boolean') {
      return
    }

    const inputValue = this.ngControl.value

    if (!inputValue) {
      return
    }

    const newValue = slugify(inputValue, false, this.replacer)
    this.ngControl.control?.setValue(newValue)
  }
}
