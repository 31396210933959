import { Injectable } from '@angular/core'
import { GoodsReceivesService } from './goods-receives.service'
import {
  GoodsReceive,
  GoodsReceiveField,
  GoodsReceiveSearchParams,
  GoodsReceivesListingData,
  GoodsReceivesListingPage,
} from './goods-receive.model'
import { Observable, combineLatest, map, of, switchMap } from 'rxjs'
import { Page } from '../../models/util.model'
import { WarehousesService } from '../warehouses'
import { parseGoodsReceivePageKeys } from './libs/goods-receive-page.lib'
import { SuppliersService } from '../suppliers'
import { PaymentsService } from '../payments'
import { ReasonsService } from '../reasons'

@Injectable({
  providedIn: 'root',
})
export class GoodsReceivesRepository {
  constructor(
    private goodsReceiveService: GoodsReceivesService,
    private warehousesService: WarehousesService,
    private suppliersService: SuppliersService,
    private paymentsService: PaymentsService,
    private reasonsService: ReasonsService,
  ) {}

  /**
   * Search goods-receives and external data
   * @param searchParams - the search params
   * @param fields - the fields
   * @returns the observable for search goods-receives
   */
  searchGoodsReceives$(
    searchParams: GoodsReceiveSearchParams,
    fields: GoodsReceiveField[],
    listingData?: GoodsReceivesListingData,
  ): Observable<GoodsReceivesListingPage> {
    return this.goodsReceiveService
      .search$(searchParams)
      .pipe(
        switchMap((page) =>
          this.loadExtData$(page, fields, listingData).pipe(
            map((extData) => ({ ...page, extData })),
          ),
        ),
      )
  }

  /**
   * Load external page goods-receives data
   * @param page - the goods-receives page
   * @param fields - the goods-receives fields
   * @returns the observable for load external data
   */
  loadExtData$(
    page: Page<GoodsReceive>,
    fields: GoodsReceiveField[],
    listingData?: GoodsReceivesListingData,
  ): Observable<GoodsReceivesListingData> {
    const extDataKeys = parseGoodsReceivePageKeys(page)
    const obs$: { [obsKey: string]: Observable<any> } = {}

    if (fields.includes('warehouseId') && extDataKeys.warehouseIds) {
      obs$['warehouses'] = this.warehousesService.store$(
        extDataKeys.warehouseIds,
        listingData?.warehouses || [],
      )
    }

    if (fields.includes('supplierId') && extDataKeys.supplierIds) {
      obs$['suppliers'] = this.suppliersService.store$(
        extDataKeys.supplierIds,
        listingData?.suppliers || [],
      )
    }

    if (fields.includes('header.paymentType') && extDataKeys.paymentIds) {
      obs$['payments'] = this.paymentsService.store$(
        extDataKeys.paymentIds,
        listingData?.payments || [],
      )
    }

    if (fields.includes('header.reasonId') && extDataKeys.reasonIds) {
      obs$['reasons'] = this.reasonsService.store$(
        extDataKeys.reasonIds,
        listingData?.reasons || [],
      )
    }

    if (!Object.keys(obs$).length) {
      return of(obs$)
    }

    return combineLatest(obs$)
  }
}
