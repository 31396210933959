import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'

// SDK
import {
  parseServerError,
  isErrorAccess,
  isErrorRefresh,
  isErrorBlank,
} from '@evologi/shared/data-access-api'

// Store
import { AuthStoreService } from '@evologi/shared/auth-store'
import { NotificationService } from '@evologi/admin/ui'

@Injectable({
  providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authStoreService: AuthStoreService,
    private notificationService: NotificationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // Errors to skip
          if (isErrorBlank(err)) {
            return throwError(() => err)
          }

          // Unathorized
          if (isErrorAccess(err)) {
            return of(this.authStoreService.expel())
          }

          // Needs a refresh
          if (isErrorRefresh(err)) {
            return of(this.authStoreService.revive()).pipe(
              switchMap(() => next.handle(request.clone())),
            )
          }

          const { message, title } = parseServerError(err)
          this.notificationService.error({ message, title })
        }

        return throwError(() => err)
      }),
    )
  }
}
