import { sortBy } from 'lodash'

import { Product, ProductType } from '../product.model'

export function checkProductVariantAttributes(product: Product): boolean {
  if (product.productType !== ProductType.virtual) {
    return true
  }

  if (
    !product.variantsManagement?.childrenIds ||
    !product.variantsManagement?.childrenIds
  ) {
    return true
  }

  return (
    !!product.variantsManagement?.attributeIds &&
    !!product.variantsManagement?.attributeIds.length
  )
}

export function addProductVariantAttribute(product: Product): Product {
  return {
    ...product,
    variantsManagement: {
      ...product.variantsManagement,
      attributeIds: [...(product.variantsManagement?.attributeIds || []), ''],
    },
  }
}

export function removeProductVariantAttribute(
  product: Product,
  i?: number,
): Product {
  i === undefined && (i = product.variantsManagement?.attributeIds.length)
  return {
    ...product,
    variantsManagement: {
      ...product.variantsManagement,
      attributeIds: [
        ...(product.variantsManagement?.attributeIds.filter(
          (att, index) => index !== i,
        ) || []),
      ],
    },
  }
}

export function parseProductVariants(
  product: Product,
  children: Product[],
): Product[] | undefined {
  const childrenIds = product.variantsManagement?.childrenIds
  if (!childrenIds) {
    return children
  }

  return sortBy(children, (item) => childrenIds.indexOf(item._id))
}

/**
 * Set variants to product
 * @param product - the product to update
 * @param variants - the product variants to set
 * @returns the product updated
 */
export function setProductVariants(
  product: Product,
  variants: Product[],
): Product {
  const childrenIds = variants.map((v) => v._id)

  const variantsManagement = product.variantsManagement
    ? product.variantsManagement
    : { attributeIds: [] }

  return {
    ...product,
    variantsManagement: {
      ...variantsManagement,
      childrenIds,
    },
  }
}
