import { Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Carrier } from '../carrier.model'
import { CarriersService } from '../carriers.service'

@Pipe({
  name: 'carrier',
})
export class CarrierPipe implements PipeTransform {
  constructor(private carriersService: CarriersService) {}

  transform(carrierId: string | undefined): Observable<Carrier | undefined> {
    if (!carrierId) {
      return of(undefined)
    }

    return this.carriersService.read$(carrierId)
  }
}
