import { Inject, Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AUTH_MANAGER, AuthManager } from '../auth.model'

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  private routeCheck$ = (route: Route | ActivatedRouteSnapshot) => {
    if (!route.data || !route.data['scope']) {
      return true
    }

    return this.authManager.isAllowed$(route.data['scope'])
  }

  constructor(
    @Inject(AUTH_MANAGER)
    private authManager: AuthManager,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.routeCheck$(route)
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.routeCheck$(route)
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.routeCheck$(childRoute)
  }
}
