import { PermissionsScope } from '../policies/policy.model'
import { SelectionData } from '../../models/util.model'
import { ActivityTab } from './activity.model'

export const ACTIVITY_SCOPE: PermissionsScope = 'activities'

export const ACTIVITY_TABS: SelectionData<ActivityTab>[] = [
  {
    value: 'data',
    label: 'Dati',
  },
  {
    value: 'notification',
    label: 'Notifica',
  },
  {
    value: 'configuration',
    label: 'Configurazione',
  },
]
