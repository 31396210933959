import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { PickupPoint, PickupPointSearchParams } from './pickup-point.model'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import { Page } from '../../models/util.model'

const MODEL = 'pickup-points'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class PickupPointsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Create a new pickupPoint
   * @param pickupPoint - The pickupPoint to create
   * @returns The observable<PickupPoint> to create the user
   */
  create$(pickupPoint: PickupPoint): Observable<PickupPoint> {
    return this._create$<PickupPoint>(pickupPoint)
  }

  /**
   * Read a pickupPoint by ID
   * @param pickupPointId - The pickupPoint ID
   * @returns The observable<PickupPoint> for read the pickupPoint
   */
  read$(pickupPointId: string): Observable<PickupPoint> {
    return this._read$<PickupPoint>(pickupPointId)
  }

  /**
   * Update a pickupPoint by ID
   * @param pickupPointId - The pickupPoint ID
   * @param pickupPoint - The pickupPoint body to update
   * @returns The observable<PickupPoint> for update the pickupPoint
   */
  update$(
    pickupPointId: string,
    pickupPoint: PickupPoint,
  ): Observable<PickupPoint> {
    return this._update$<PickupPoint>(pickupPointId, pickupPoint)
  }

  /**
   * Create or update a pickupPoint by ID
   * @param pickupPointId - The pickupPoint ID
   * @param pickupPoint - The pickupPoint body to update
   * @returns The observable<PickupPoint> for update the pickupPoint
   */
  upsert$(pickupPoint: PickupPoint): Observable<PickupPoint> {
    return this._upsert$<PickupPoint>(pickupPoint, pickupPoint._id)
  }

  /**
   * Delete a pickupPoint by ID
   * @param pickupPointId - The pickupPoint ID
   * @returns The observable<PickupPoint> for delete the pickupPoint
   */
  delete$(pickupPointId: string): Observable<PickupPoint> {
    return this._delete$<PickupPoint>(pickupPointId)
  }

  /**
   * Search pickupPoints by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<PickupPoint>> for search pickupPoints
   */
  search$(
    params?: PickupPointSearchParams,
    returnAll = false,
  ): Observable<Page<PickupPoint>> {
    return this._search$<PickupPoint>(params, returnAll)
  }

  /**
   * List pickupPoints by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<PickupPoint[]> for list pickupPoints
   */
  list$(
    params?: PickupPointSearchParams,
    returnAll = false,
  ): Observable<PickupPoint[]> {
    return this._list$<PickupPoint>(params, returnAll)
  }
}
